import React, {useRef} from "react";

import {Button} from 'antd';
import {PrinterOutlined} from '@ant-design/icons';

import ReactToPrint from 'react-to-print';

import L from "leaflet";
import {LayersControl, MapContainer, TileLayer} from "react-leaflet";
import MarkerClusterGroup from 'react-leaflet-cluster';


// import {markers} from "../data/markers";
// import {polylineList} from "../data/polylines";
// import {polygonsList} from "../data/polygons";

import {heatingUsersList} from "../data/heating_users_v_02-08-2022";
import {boilerList} from "../data/boilerList_v_04-08-2022";
import {tkList} from "../data/tk_okp_v_08-08-2022";
import {tk_tec_list} from "../data/tk_tec_v_09-08-2022";
import {pipeMain} from "../data/pipe-main";
import {pipePlan} from "../data/pipe-plan";

/*Мережі 2022*/
//import {pipeIntra_2022} from "../data/pipe-intra_2022";
/*Мережі 2023*/
import {pipeIntra_2023} from "../data/pipe-intra_2023";


import {boltsList} from "../data/bolts_v_16-08-2022";
import {ctpList} from "../data/ctp";
import {hitGenList} from "../data/hitGen";
import {nefcoList} from "../data/nefco";
import {nefkoPipesList} from "../data/nefco_pipes";
import {mote_wb} from "../data/mote_wb"
/*Импорт данных для слоя повреждений труб*/
import {pipe_damage_data} from "../data/pipe-damage-data";
/*Импорт данных для слоя Схема границ раздела тепловых сетей*/
import {border_scheme_data} from "../data/border-scheme-data";




// import {LayerMarkers} from "../layers/LayerMarkers";
// import {LayerPolyline} from "../layers/LayerPolylines";
// import {LayerPolygons} from "../layers/LayerPolygons";
// import {LayerMarkersDefault} from "../layers/LayerMarkersDefault";

import {LayerHeatingUsers} from "../layers/LayerHeatingUsers";
import {LayerBoilers} from "../layers/LayerBoilers";
import {LayerTK} from "../layers/LayerTK";
import {LayerTkTec} from "../layers/LayerTkTec";
import {LayerPipeMain} from "../layers/LayerPipeMain";
import {LayerPipePlan} from "../layers/LayerPipePlan";
import {LayerPipeIntra} from "../layers/LayerPipeIntra";
import {LayerBolts} from "../layers/LayerBolts";
import {LayerCTP} from "../layers/LayerCTP";
import {LayerHitGen} from "../layers/LayerHitGen";
import {LayerNEFCO} from "../layers/LayerNEFCO";
import {LayerPipeNEFCO} from "../layers/LayerPipeNEFCO";
import {LayerMOTEWB} from "../layers/LayerMOTEWB";
/*Импорт слоя повреждений труб*/
import {LayerPipeDamage} from "../layers/LayerPipeDamage";
/*Импорт слоя границ раздела тепловых сетей*/
import {LayerBorderScheme} from "../layers/LayerBorderScheme";

import {Legend} from "../components/Legend";

export const Map = () => {
    const componentRef = useRef(null);
    const position: L.LatLngExpression = [46.95355352880178, 32.03006707746631];

    return (
        <>
            <Legend/>
            <div className={"map-wrapper"} ref={componentRef}>
                <MapContainer center={position}
                              zoom={12}
                              minZoom={12}
                              maxZoom={18}
                              scrollWheelZoom={true}
                              zoomControl={true}
                              className={"map-to-print"}
                >
                    <LayersControl position={"topright"}>
                        <LayersControl.BaseLayer checked name={"OSM Streets"}>
                            <TileLayer url="https://tile.openstreetmap.org.ua/styles/osm-bright/{z}/{x}/{y}.png" />
                            <ReactToPrint
                                trigger={() => <Button className={'btn-print'} type="default" icon={<PrinterOutlined/>}/>}
                                content={() => componentRef.current}
                            />
                        </LayersControl.BaseLayer>

                        {/*<LayerMarkers data={markers}/>*/}
                        {/*<LayerMarkersDefault data={markers}/>*/}
                        {/*<LayerPolyline data={polylineList}/>*/}
                        {/*<LayerPolygons data={polygonsList}/>*/}

                        <MarkerClusterGroup chunkedLoading>
                            <LayerHeatingUsers data={heatingUsersList}/>
                        </MarkerClusterGroup>
                        <LayerBoilers data={boilerList}/>
                        <LayerHitGen data={hitGenList}/>
                        <LayerTK data={tkList}/>
                        <LayerTkTec data={tk_tec_list}/>
                        {/*Мережі (магістральні)*/}
                        <LayerPipeMain data={pipeMain}/>
                        {/*Мережі*/}
                        <LayerPipeIntra data={pipeIntra_2023}/>

                        {/*Мережі (плануються)*/}
                        <LayerPipePlan data={pipePlan}/>
                        <LayerBolts data={boltsList}/>
                        <LayerCTP data={ctpList}/>
                        <LayerNEFCO data={nefcoList}/>
                        <LayerPipeNEFCO data={nefkoPipesList}/>
                        <LayerMOTEWB data={mote_wb}/>
                        {/*Слой повреждений*/}
                        <LayerPipeDamage data={pipe_damage_data}/>
                        {/*Слой Схема границ раздела тепловых сетей*/}
                        <LayerBorderScheme data={border_scheme_data}/>
                    </LayersControl>
                </MapContainer>
            </div>
        </>
    );
};
