export const nefkoPipesList = {
  type: "FeatureCollection",
  name: "nefco_pipes",
  crs: {
    type: "name",
    properties: {
      name: "urn:ogc:def:crs:OGC:1.3:CRS84"
    }
  },
  features: [
    {
      type: "Feature",
      properties: {
        id: 5
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.067701768823426,
              46.95392814187917
            ],
            [
              32.067185915438586,
              46.95336922400851
            ],
            [
              32.06803802849744,
              46.953017317474696
            ],
            [
              32.06854726845945,
              46.95358268354022
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.06793160076832,
              46.953578299987264
            ],
            [
              32.06748508992948,
              46.9530893399847
            ],
            [
              32.06787508524795,
              46.95293253135765
            ],
            [
              32.06748508992948,
              46.952338113087905
            ],
            [
              32.06769878599439,
              46.952250590821855
            ],
            [
              32.06819562934532,
              46.95269184745491
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.06769878599439,
              46.95225059082185
            ],
            [
              32.06751714433922,
              46.952020844192496
            ],
            [
              32.06746104912218,
              46.95204272486636
            ],
            [
              32.066798591320946,
              46.95129513010753
            ],
            [
              32.06542559410387,
              46.95180933055065
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.06607469590106,
              46.95135894958
            ],
            [
              32.0662248341108,
              46.95151000821001
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.06536415648522,
              46.951521233038605
            ],
            [
              32.06557661900433,
              46.95175277053892
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.066862146922965,
              46.95136685412512
            ],
            [
              32.06704968419722,
              46.9512896598635
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.06739694030271,
              46.95170357341625
            ],
            [
              32.06661427846496,
              46.95199714011905
            ],
            [
              32.066635648071454,
              46.952775722271184
            ],
            [
              32.06647804722358,
              46.95282859980519
            ],
            [
              32.066005244679964,
              46.952285235069304
            ],
            [
              32.0658927848838,
              46.952330674648735
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.06647804722358,
              46.95282859980519
            ],
            [
              32.06671311289498,
              46.95311851363208
            ],
            [
              32.06659023765765,
              46.95316592077512
            ],
            [
              32.06706304020127,
              46.95370015990699
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.06671211790142,
              46.951327515366756
            ],
            [
              32.06646803022054,
              46.951049424428106
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.053279605668635,
              46.957466455242056
            ],
            [
              32.05319412724268,
              46.95737894136309
            ],
            [
              32.05538718310884,
              46.95660224941527
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.05368562819197,
              46.95630688472988
            ],
            [
              32.05427892108605,
              46.95699475253959
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.054095643704926,
              46.9570596619525
            ],
            [
              32.05421986835426,
              46.95718750425385
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.05319412724268,
              46.95737894136309
            ],
            [
              32.05301515678831,
              46.957180211398054
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.05346926092625,
              46.95692678404162
            ],
            [
              32.0533650840946,
              46.95696689492619
            ],
            [
              32.0534826169303,
              46.957092697050925
            ],
            [
              32.051682227583406,
              46.957871205070475
            ],
            [
              32.05052025523045,
              46.95827412866053
            ],
            [
              32.050237107944426,
              46.95819390882116
            ],
            [
              32.050034096682765,
              46.95801159055712
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.05176503480857,
              46.95740993587826
            ],
            [
              32.05203076676154,
              46.957720493806825
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.050979701770004,
              46.95795142539366
            ],
            [
              32.05109438376595,
              46.95807504518883
            ]
          ]
        ]
      }
    }
  ]
}