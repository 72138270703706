import React from "react";
import {Card} from "antd";

// @ts-ignore
export const PopupStatisticsHitUsers = ({feature}) => {
    const {
        // oda,
        // city,
        // tec_okp,
        t_area,
        kot_name,
        address,
        // adrs_full,
        build_s,
        bld_floor,
        bld_porch,
        bld_apart,
        apart_ind,
        q_2021,
        q_tarif,
        itp,
        project,
        // elevator,
        // adrs_old,
        // rename_sol,
    } = feature.properties;
    return (
        <Card type="inner"
              className={"card-building"}
              title={address}
              extra={<a
                  href="https://docs.google.com/spreadsheets/d/1CitECX-zYB2iUbLV7PjM9qJnyM2S2I8FZ8CF317Ur60/edit#gid=340776157"
                  rel="noreferrer"
                  target={"_blank"}>Усі будинки</a>}
        >
            <div className={"line"}>
                <b>Тепловий р-н:</b>
                <span>{t_area}</span>
            </div>
            <div className={"line"}>
                <b>Котельня:</b>
                <span>{kot_name}</span>
            </div>
            <div className={"line"}>
                <b>Загальна площа:</b>
                <span>{build_s}</span>
            </div>
            <div className={"count-title"}>Кількість</div>
            <div className={"items-wrap"}>
                <div className={"item"}>
                    <b>Поверхів</b>
                    <span>{bld_floor}</span>
                </div>
                <div className={"item"}>
                    <b>Під'їздів</b>
                    <span>{bld_porch}</span>
                </div>
                <div className={"item"}>
                    <b>Квартир</b>
                    <span>{bld_apart}</span>
                </div>
                <div className={"item"}>
                    <b>Кв. з Інд. Опал-м</b>
                    <span>{apart_ind}</span>
                </div>
            </div>
            <div className={"line-bottom"}>
                <b>Споживання теплової енергії за 2021 р.:</b>
                <span>{q_2021}</span>
            </div>
            <div className={"line-bottom"}>
                <b>Тариф на центр. опалення на 01.01.22 р.:</b>
                <span>{q_tarif}</span>
            </div>
            <div className={"line-bottom"}>
                <b>Наявність ІТП:</b>
                <span>{itp}</span>
            </div>
            <div className={"line-bottom"}>
                <b>Наявність проекту утеплення:</b>
                <span>{project}</span>
            </div>
        </Card>
    )
}