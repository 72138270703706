export const heatingUsersList =  {
  type: "FeatureCollection",
  name: "boilerList-02082022",
  crs: {
    type: "name",
    properties: {
      name: "urn:ogc:def:crs:OGC:1.3:CRS84"
    }
  },
  features: [
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.2-а Екiпажна,4-и",
        address: "вул. 1 Екiпажна, 2/А",
        adrs_full: "м. Миколаїв, вул. 1 Екiпажна, 2/А",
        lat: 46.97776850187372,
        lon: 32.02037013929563,
        build_s: "3 339,40 м2",
        bld_floor: 6,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 5,
        q_2021: "272,091080 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02037013929563,
          46.97776850187372
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.2-а Екiпажна,4-и",
        address: "вул. 1 Екiпажна, 2/Б",
        adrs_full: "м. Миколаїв, вул. 1 Екiпажна, 2/Б",
        lat: 46.978338587134026,
        lon: 32.021158404389304,
        build_s: "3 428,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 50,
        apart_ind: 20,
        q_2021: "140,466066 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.021158404389304,
          46.978338587134026
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 1 Лінія, 11",
        adrs_full: "м. Миколаїв, вул. 1 Лінія, 11",
        lat: 46.9551786,
        lon: 32.0445783,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 5,
        q_2021: "155,972000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0445783,
          46.9551786
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 1 Лінія, 15",
        adrs_full: "м. Миколаїв, вул. 1 Лінія, 15",
        lat: 46.9541184,
        lon: 32.0437196,
        build_s: "?",
        bld_floor: 10,
        bld_porch: "?",
        bld_apart: 216,
        apart_ind: 18,
        q_2021: "844,770000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0437196,
          46.9541184
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 1 Лінія, 25",
        adrs_full: "м. Миколаїв, вул. 1 Лінія, 25",
        lat: 46.9530978,
        lon: 32.0428018,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 25,
        apart_ind: 1,
        q_2021: "180,922000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0428018,
          46.95309779999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 1 Лінія, 27",
        adrs_full: "м. Миколаїв, вул. 1 Лінія, 27",
        lat: 46.952527,
        lon: 32.0422669,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 39,
        apart_ind: 2,
        q_2021: "155,466000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0422669,
          46.952527
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 1 Лінія, 28/А",
        adrs_full: "м. Миколаїв, вул. 1 Лінія, 28/А",
        lat: 46.95454865925478,
        lon: 32.04580449090909,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 108,
        apart_ind: 2,
        q_2021: "320,791000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04580449090909,
          46.95454865925478
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 1 Лінія, 28/Б",
        adrs_full: "м. Миколаїв, вул. 1 Лінія, 28/Б",
        lat: 46.95488604481399,
        lon: 32.04522093457635,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 4,
        q_2021: "208,730000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04522093457635,
          46.95488604481399
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 1 Лінія, 34",
        adrs_full: "м. Миколаїв, вул. 1 Лінія, 34",
        lat: 46.9528164,
        lon: 32.0435007,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 71,
        apart_ind: 1,
        q_2021: "292,989000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0435007,
          46.9528164
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 1 Лінія, 34/А",
        adrs_full: "м. Миколаїв, вул. 1 Лінія, 34/А",
        lat: 46.9525067,
        lon: 32.0442115,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 107,
        apart_ind: 4,
        q_2021: "380,510000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0442115,
          46.9525067
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 1 Лінія, 36",
        adrs_full: "м. Миколаїв, вул. 1 Лінія, 36",
        lat: 46.9525134,
        lon: 32.0431206,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 71,
        apart_ind: 4,
        q_2021: "249,628000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0431206,
          46.9525134
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 1 Лінія, 38",
        adrs_full: "м. Миколаїв, вул. 1 Лінія, 38",
        lat: 46.952243,
        lon: 32.0426362,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 38,
        apart_ind: 2,
        q_2021: "166,010000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0426362,
          46.952243
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 1 Лінія, 42",
        adrs_full: "м. Миколаїв, вул. 1 Лінія, 42",
        lat: 46.9515925,
        lon: 32.0412759,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "63,932000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0412759,
          46.9515925
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 1 Лінія, 44",
        adrs_full: "м. Миколаїв, вул. 1 Лінія, 44",
        lat: 46.9514315,
        lon: 32.0407112,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "63,635000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0407112,
          46.9514315
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 1 Слобідська, 122/2",
        adrs_full: "м. Миколаїв, вул. 1 Слобідська, 122/2",
        lat: 46.950238873813966,
        lon: 32.00667853447216,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 11,
        apart_ind: 1,
        q_2021: "54,552000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00667853447216,
          46.950238873813966
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 1 Слобідська, 122/3",
        adrs_full: "м. Миколаїв, вул. 1 Слобідська, 122/3",
        lat: 46.95028056156315,
        lon: 32.006098378504085,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 13,
        apart_ind: 0,
        q_2021: "64,191000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.006098378504085,
          46.95028056156315
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 1 Слобідська, 13",
        adrs_full: "м. Миколаїв, вул. 1 Слобідська, 13",
        lat: 46.96695363995592,
        lon: 32.0068754234791,
        build_s: "1 313,80 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 6,
        q_2021: "96,579981 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0068754234791,
          46.96695363995592
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.1-яSLOB,13(САО)",
        address: "вул. 1 Слобідська, 43",
        adrs_full: "м. Миколаїв, вул. 1 Слобідська, 43",
        lat: 46.95977603835075,
        lon: 32.00621662727274,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 178,
        apart_ind: 11,
        q_2021: "654,840000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00621662727274,
          46.95977603835075
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. 11 Лiнiя, 115",
        adrs_full: "м. Миколаїв, вул. 11 Лiнiя, 115",
        lat: 46.947096578056865,
        lon: 32.05798730728024,
        build_s: "5 900,00 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 10,
        q_2021: "239,013921 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05798730728024,
          46.947096578056865
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 11 Поздовжня, 1/1",
        adrs_full: "м. Миколаїв, вул. 11 Поздовжня, 1/1",
        lat: 46.954212367172374,
        lon: 32.04009935454546,
        build_s: "657,50 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 2,
        q_2021: "50,630055 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04009935454546,
          46.954212367172374
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 11 Поздовжня, 1/2",
        adrs_full: "м. Миколаїв, вул. 11 Поздовжня, 1/2",
        lat: 46.95428534140784,
        lon: 32.04015853636365,
        build_s: "399,10 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 8,
        apart_ind: 1,
        q_2021: "47,079993 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04015853636365,
          46.95428534140784
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 11 Поздовжня, 1/3",
        adrs_full: "м. Миколаїв, вул. 11 Поздовжня, 1/3",
        lat: 46.95434398134637,
        lon: 32.04021008181819,
        build_s: "406,80 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "46,624263 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04021008181819,
          46.95434398134637
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 11 Поздовжня, 1/4",
        adrs_full: "м. Миколаїв, вул. 11 Поздовжня, 1/4",
        lat: 46.95441174297309,
        lon: 32.0402749909091,
        build_s: "628,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "59,939997 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0402749909091,
          46.95441174297309
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 11 Поздовжня, 1/5",
        adrs_full: "м. Миколаїв, вул. 11 Поздовжня, 1/5",
        lat: 46.95448341383106,
        lon: 32.04034753636365,
        build_s: "383,10 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 9,
        apart_ind: 4,
        q_2021: "28,132982 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04034753636365,
          46.95448341383106
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.11-а Повздовжня,1",
        address: "вул. 11 Поздовжня, 2",
        adrs_full: "м. Миколаїв, вул. 11 Поздовжня, 2",
        lat: 46.9539524,
        lon: 32.0424956,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 20,
        apart_ind: 1,
        q_2021: "86,355000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0424956,
          46.9539524
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.11-а Повздовжня,1",
        address: "вул. 11 Поздовжня, 3",
        adrs_full: "м. Миколаїв, вул. 11 Поздовжня, 3",
        lat: 46.9540914,
        lon: 32.0410375,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 29,
        apart_ind: 1,
        q_2021: "94,243000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0410375,
          46.9540914
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.11-а Повздовжня,1",
        address: "вул. 11 Поздовжня, 31/А",
        adrs_full: "м. Миколаїв, вул. 11 Поздовжня, 31/А",
        lat: 46.9517746,
        lon: 32.046837,
        build_s: "?",
        bld_floor: 10,
        bld_porch: "?",
        bld_apart: 238,
        apart_ind: 6,
        q_2021: "688,002000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.046837,
          46.9517746
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.11-а Повздовжня,1",
        address: "вул. 11 Поздовжня, 45",
        adrs_full: "м. Миколаїв, вул. 11 Поздовжня, 45",
        lat: 46.950551,
        lon: 32.049945,
        build_s: "7 924,85 м2",
        bld_floor: 5,
        bld_porch: 9,
        bld_apart: 130,
        apart_ind: 11,
        q_2021: "611,106669 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "9",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.049945,
          46.950551
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.11-а Повздовжня,1",
        address: "вул. 11 Поздовжня, 5/А",
        adrs_full: "м. Миколаїв, вул. 11 Поздовжня, 5/А",
        lat: 46.9534865,
        lon: 32.0421649,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 35,
        apart_ind: 0,
        q_2021: "94,775000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0421649,
          46.9534865
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. 11 Поздовжня, 7",
        adrs_full: "м. Миколаїв, вул. 11 Поздовжня, 7",
        lat: 46.95351,
        lon: 32.0426297,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 2,
        q_2021: "55,443000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0426297,
          46.95351
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. 12 Поздовжня, 1",
        adrs_full: "м. Миколаїв, вул. 12 Поздовжня, 1",
        lat: 46.94796676867611,
        lon: 32.05323145991411,
        build_s: "6 238,00 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 107,
        apart_ind: 5,
        q_2021: "522,199895 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05323145991411,
          46.94796676867611
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. 12 Поздовжня, 1/А",
        adrs_full: "м. Миколаїв, вул. 12 Поздовжня, 1/А",
        lat: 46.947412355395024,
        lon: 32.05281620799063,
        build_s: "4 724,00 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 119,
        apart_ind: 3,
        q_2021: "357,799864 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05281620799063,
          46.947412355395024
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. 12 Поздовжня, 3",
        adrs_full: "м. Миколаїв, вул. 12 Поздовжня, 3",
        lat: 46.946928179823395,
        lon: 32.05386719958455,
        build_s: "6 609,20 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 161,
        apart_ind: 2,
        q_2021: "447,889458 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05386719958455,
          46.946928179823395
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. 12 Поздовжня, 38",
        adrs_full: "м. Миколаїв, вул. 12 Поздовжня, 38",
        lat: 46.94995318924727,
        lon: 32.049240069516976,
        build_s: "3 585,80 м2",
        bld_floor: 5,
        bld_porch: 5,
        bld_apart: 64,
        apart_ind: 5,
        q_2021: "273,886948 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "5",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.049240069516976,
          46.94995318924726
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. 12 Поздовжня, 42",
        adrs_full: "м. Миколаїв, вул. 12 Поздовжня, 42",
        lat: 46.94908876957595,
        lon: 32.05170258912138,
        build_s: "4 049,30 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 4,
        q_2021: "176,625887 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05170258912138,
          46.94908876957595
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. 12 Поздовжня, 42/Б",
        adrs_full: "м. Миколаїв, вул. 12 Поздовжня, 42/Б",
        lat: 46.948722153329136,
        lon: 32.05083504939205,
        build_s: "5 084,60 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 60,
        apart_ind: 12,
        q_2021: "278,730066 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05083504939205,
          46.948722153329136
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. 12 Поздовжня, 44",
        adrs_full: "м. Миколаїв, вул. 12 Поздовжня, 44",
        lat: 46.94948113200807,
        lon: 32.052536002029086,
        build_s: "4 345,40 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 108,
        apart_ind: 5,
        q_2021: "256,250200 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.052536002029086,
          46.94948113200806
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. 12 Поздовжня, 44/А",
        adrs_full: "м. Миколаїв, вул. 12 Поздовжня, 44/А",
        lat: 46.9499249885421,
        lon: 32.05226657975288,
        build_s: "4 291,60 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 108,
        apart_ind: 1,
        q_2021: "269,750104 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05226657975288,
          46.94992498854211
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. 12 Поздовжня, 45",
        adrs_full: "м. Миколаїв, вул. 12 Поздовжня, 45",
        lat: 46.9462661275614,
        lon: 32.056929381279566,
        build_s: "5 900,00 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 6,
        q_2021: "235,459068 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.056929381279566,
          46.9462661275614
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. 12 Поздовжня, 47",
        adrs_full: "м. Миколаїв, вул. 12 Поздовжня, 47",
        lat: 46.94597183833148,
        lon: 32.05800707038435,
        build_s: "4 772,40 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 98,
        apart_ind: 5,
        q_2021: "332,250978 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05800707038435,
          46.94597183833148
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. 12 Поздовжня, 47/А",
        adrs_full: "м. Миколаїв, вул. 12 Поздовжня, 47/А",
        lat: 46.94530232430624,
        lon: 32.05694375046763,
        build_s: "5 045,70 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 160,
        apart_ind: 1,
        q_2021: "461,670818 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05694375046763,
          46.94530232430624
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. 12 Поздовжня, 5",
        adrs_full: "м. Миколаїв, вул. 12 Поздовжня, 5",
        lat: 46.947374725326206,
        lon: 32.05468300424836,
        build_s: "6 262,30 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 107,
        apart_ind: 5,
        q_2021: "462,820217 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05468300424836,
          46.947374725326206
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. 12 Поздовжня, 5/А",
        adrs_full: "м. Миколаїв, вул. 12 Поздовжня, 5/А",
        lat: 46.946747553618025,
        lon: 32.05485204485437,
        build_s: "6 433,20 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 107,
        apart_ind: 6,
        q_2021: "331,389705 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05485204485437,
          46.946747553618025
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.2-а Екiпажна,5/1",
        address: "вул. 2 Екiпажна, 3",
        adrs_full: "м. Миколаїв, вул. 2 Екiпажна, 3",
        lat: 46.9730204,
        lon: 32.0184879,
        build_s: "2 015,30 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 43,
        apart_ind: 4,
        q_2021: "167,742768 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0184879,
          46.9730204
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. 2 Поперечна, 32",
        adrs_full: "м. Миколаїв, вул. 2 Поперечна, 32",
        lat: 46.9715316,
        lon: 31.9683262,
        build_s: "2 726,20 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 2,
        q_2021: "229,952131 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9683262,
          46.9715316
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 2 Слобідська, 71",
        adrs_full: "м. Миколаїв, вул. 2 Слобідська, 71",
        lat: 46.95757193591743,
        lon: 32.00948811799794,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 65,
        apart_ind: 8,
        q_2021: "264,821000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00948811799794,
          46.957571935917436
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 2 Слобідська, 73",
        adrs_full: "м. Миколаїв, вул. 2 Слобідська, 73",
        lat: 46.956966881045474,
        lon: 32.009388412710486,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 73,
        apart_ind: 3,
        q_2021: "221,754000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.009388412710486,
          46.956966881045474
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 2 Слобідська, 75",
        adrs_full: "м. Миколаїв, вул. 2 Слобідська, 75",
        lat: 46.95645833270185,
        lon: 32.009364846006186,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 2,
        q_2021: "367,358000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.009364846006186,
          46.95645833270185
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 28 Армії, 10",
        adrs_full: "м. Миколаїв, вул. 28 Армії, 10",
        lat: 46.9394727,
        lon: 32.0428259,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 56,
        apart_ind: 1,
        q_2021: "241,205000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0428259,
          46.9394727
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 28 Армії, 12",
        adrs_full: "м. Миколаїв, вул. 28 Армії, 12",
        lat: 46.939636956036864,
        lon: 32.04135469738896,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 3,
        q_2021: "46,026000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04135469738896,
          46.939636956036864
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 28 Армії, 12/А",
        adrs_full: "м. Миколаїв, вул. 28 Армії, 12/А",
        lat: 46.9388932,
        lon: 32.0433318,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 51,
        apart_ind: 4,
        q_2021: "219,283000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0433318,
          46.9388932
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 28 Армії, 14",
        adrs_full: "м. Миколаїв, вул. 28 Армії, 14",
        lat: 46.9391531,
        lon: 32.0415545,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 6,
        q_2021: "39,460000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0415545,
          46.9391531
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 28 Армії, 15",
        adrs_full: "м. Миколаїв, вул. 28 Армії, 15",
        lat: 46.9393048,
        lon: 32.0422163,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 10,
        apart_ind: 7,
        q_2021: "22,816000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0422163,
          46.9393048
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 28 Армії, 2",
        adrs_full: "м. Миколаїв, вул. 28 Армії, 2",
        lat: 46.942077631826805,
        lon: 32.0422497213567,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 4,
        q_2021: "234,398000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0422497213567,
          46.942077631826805
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 28 Армії, 2/А",
        adrs_full: "м. Миколаїв, вул. 28 Армії, 2/А",
        lat: 46.942797046048106,
        lon: 32.04189182827897,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 79,
        apart_ind: 2,
        q_2021: "216,527000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04189182827897,
          46.942797046048106
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 28 Армії, 2/Б",
        adrs_full: "м. Миколаїв, вул. 28 Армії, 2/Б",
        lat: 46.94243139062419,
        lon: 32.04212906415683,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 1,
        q_2021: "251,377000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04212906415683,
          46.94243139062419
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 28 Армії, 4",
        adrs_full: "м. Миколаїв, вул. 28 Армії, 4",
        lat: 46.94170215506997,
        lon: 32.04248695723456,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 61,
        apart_ind: 2,
        q_2021: "226,951000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04248695723456,
          46.94170215506997
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "топ.295-їСтрiл.Дивiзiї,75/1-К",
        address: "вул. 295 Стрiлецької Дивiзiї, 75/1",
        adrs_full: "м. Миколаїв, вул. 295 Стрiлецької Дивiзiї, 75/1",
        lat: 46.9144316,
        lon: 32.0546558,
        build_s: "1 578,00 м2",
        bld_floor: 3,
        bld_porch: 3,
        bld_apart: 37,
        apart_ind: 1,
        q_2021: "112,509976 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0546558,
          46.9144316
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "топ.295-їСтрiл.Дивiзiї,75/1-К",
        address: "вул. 295 Стрiлецької Дивiзiї, 75/2",
        adrs_full: "м. Миколаїв, вул. 295 Стрiлецької Дивiзiї, 75/2",
        lat: 46.9149992,
        lon: 32.0539182,
        build_s: "1 577,50 м2",
        bld_floor: 3,
        bld_porch: 3,
        bld_apart: 38,
        apart_ind: 0,
        q_2021: "115,600111 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0539182,
          46.9149992
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.295-ї Стрiл.Дивiзiї,75-а",
        address: "вул. 295 Стрiлецької Дивiзiї, 75/А",
        adrs_full: "м. Миколаїв, вул. 295 Стрiлецької Дивiзiї, 75/А",
        lat: 46.9150181,
        lon: 32.0546669,
        build_s: "1 832,20 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 60,
        apart_ind: 0,
        q_2021: "196,860078 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0546669,
          46.9150181
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.8-а Повздовжня,11-в",
        address: "вул. 3 Лiнiя, 17",
        adrs_full: "м. Миколаїв, вул. 3 Лiнiя, 17",
        lat: 46.95523213592702,
        lon: 32.0481908523386,
        build_s: "4 527,00 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 79,
        apart_ind: 12,
        q_2021: "324,712623 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0481908523386,
          46.95523213592701
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.8-а Повздовжня,11-в",
        address: "вул. 3 Лiнiя, 17/Б",
        adrs_full: "м. Миколаїв, вул. 3 Лiнiя, 17/Б",
        lat: 46.9546025,
        lon: 32.0477736,
        build_s: "2 436,90 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 29,
        apart_ind: 15,
        q_2021: "92,330004 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0477736,
          46.9546025
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.8-а Повздовжня,11-в",
        address: "вул. 3 Лiнiя, 17/Пол",
        adrs_full: "м. Миколаїв, вул. 3 Лiнiя, 17/Пол",
        lat: 46.95608124981994,
        lon: 32.04882433137968,
        build_s: "695,50 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 11,
        apart_ind: 0,
        q_2021: "53,048391 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04882433137968,
          46.95608124981994
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. 3 Поздовжня, 21",
        adrs_full: "м. Миколаїв, вул. 3 Поздовжня, 21",
        lat: 46.95819329516402,
        lon: 32.06289506970777,
        build_s: "1 547,20 м2",
        bld_floor: 3,
        bld_porch: 3,
        bld_apart: 36,
        apart_ind: 3,
        q_2021: "111,862686 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06289506970777,
          46.95819329516402
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 107/1",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 107/1",
        lat: 46.9572989,
        lon: 32.0113673,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 108,
        apart_ind: 1,
        q_2021: "317,378000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0113673,
          46.9572989
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 107/2",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 107/2",
        lat: 46.9564347,
        lon: 32.0112994,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 43,
        apart_ind: 0,
        q_2021: "185,897000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0112994,
          46.9564347
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 107/3",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 107/3",
        lat: 46.9568561,
        lon: 32.0110298,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 30,
        apart_ind: 10,
        q_2021: "147,045000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0110298,
          46.9568561
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 107/4",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 107/4",
        lat: 46.9557612,
        lon: 32.0108905,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 53,
        apart_ind: 3,
        q_2021: "100,811000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0108905,
          46.9557612
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 107/5",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 107/5",
        lat: 46.955223,
        lon: 32.0107237,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 88,
        apart_ind: 4,
        q_2021: "152,346000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0107237,
          46.955223
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 125/А",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 125/А",
        lat: 46.9560924,
        lon: 32.0114506,
        build_s: "?",
        bld_floor: 1,
        bld_porch: "?",
        bld_apart: 0,
        apart_ind: 0,
        q_2021: "4,456000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0114506,
          46.9560924
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 19",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 19",
        lat: 46.967670576086626,
        lon: 32.01187160855667,
        build_s: "?",
        bld_floor: null,
        bld_porch: "?",
        bld_apart: null,
        apart_ind: null,
        q_2021: "496,842800 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01187160855667,
          46.967670576086626
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 24",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 24",
        lat: 46.96806598296983,
        lon: 32.01292887413336,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 73,
        apart_ind: 3,
        q_2021: "299,229000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01292887413336,
          46.96806598296983
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 26",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 26",
        lat: 46.967677514660345,
        lon: 32.01314040272764,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "351,030000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01314040272764,
          46.967677514660345
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 28",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 28",
        lat: 46.96728904352957,
        lon: 32.013342599178074,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 108,
        apart_ind: 4,
        q_2021: "347,482000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.013342599178074,
          46.96728904352957
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 30",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 30",
        lat: 46.9673519,
        lon: 32.0127972,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 5,
        apart_ind: 2,
        q_2021: "24,587000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0127972,
          46.9673519
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 49",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 49",
        lat: 46.9662893,
        lon: 32.0125535,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 169,
        apart_ind: 4,
        q_2021: "438,650000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0125535,
          46.9662893
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 50",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 50",
        lat: 46.96432043710106,
        lon: 32.01409788136341,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: null,
        q_2021: "166,272200 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01409788136341,
          46.96432043710106
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 51",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 51",
        lat: 46.9639181,
        lon: 32.0129907,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 202,
        apart_ind: 8,
        q_2021: "698,053000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0129907,
          46.9639181
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 51-А",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 51-А",
        lat: 46.9643506,
        lon: 32.0120096,
        build_s: "?",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: null,
        q_2021: "224,682200 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0120096,
          46.9643506
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 51/Б",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 51/Б",
        lat: 46.9638172,
        lon: 32.0120301,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 108,
        apart_ind: 12,
        q_2021: "365,436000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0120301,
          46.9638172
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 52",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 52",
        lat: 46.96393823971371,
        lon: 32.01399689195107,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: null,
        q_2021: "192,132100 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01399689195107,
          46.96393823971371
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 54",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 54",
        lat: 46.96356543799213,
        lon: 32.01399689195106,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: null,
        q_2021: "185,429300 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01399689195106,
          46.96356543799213
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 54-А",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 54-А",
        lat: 46.96354037559832,
        lon: 32.01504350949718,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: null,
        q_2021: "177,846200 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01504350949718,
          46.96354037559832
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 56",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 56",
        lat: 46.962807940957845,
        lon: 32.01358302494591,
        build_s: "?",
        bld_floor: 9,
        bld_porch: 5,
        bld_apart: 180,
        apart_ind: null,
        q_2021: "570,290400 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01358302494591,
          46.962807940957845
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 56-А",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 56-А",
        lat: 46.96412620598315,
        lon: 32.01614980260515,
        build_s: "?",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: null,
        q_2021: "228,338400 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01614980260515,
          46.96412620598315
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 3 Слобідська, 57",
        adrs_full: "м. Миколаїв, вул. 3 Слобідська, 57",
        lat: 46.9627657708214,
        lon: 32.01225188665723,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 90,
        apart_ind: null,
        q_2021: "386,268000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01225188665723,
          46.9627657708214
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. 4 Поздовжня, 70",
        adrs_full: "м. Миколаїв, вул. 4 Поздовжня, 70",
        lat: 46.954070866944946,
        lon: 32.06800719949226,
        build_s: "666,80 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 1,
        q_2021: "67,843010 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06800719949226,
          46.954070866944946
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. 4 Поздовжня, 72",
        adrs_full: "м. Миколаїв, вул. 4 Поздовжня, 72",
        lat: 46.95382513345378,
        lon: 32.06858478896599,
        build_s: "685,90 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "74,430006 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06858478896599,
          46.95382513345378
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. 4 Поздовжня, 85",
        adrs_full: "м. Миколаїв, вул. 4 Поздовжня, 85",
        lat: 46.95359772833142,
        lon: 32.06764914612121,
        build_s: "2 942,20 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "176,023805 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06764914612121,
          46.95359772833142
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. 4 Поздовжня, 87",
        adrs_full: "м. Миколаїв, вул. 4 Поздовжня, 87",
        lat: 46.953384657920864,
        lon: 32.0681495027551,
        build_s: "2 898,30 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "158,498973 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0681495027551,
          46.953384657920864
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 4 Слобідська, 88/3",
        adrs_full: "м. Миколаїв, вул. 4 Слобідська, 88/3",
        lat: 46.960370622746794,
        lon: 32.017332956072174,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 28,
        apart_ind: 3,
        q_2021: "82,627000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.017332956072174,
          46.960370622746794
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 4 Слобідська, 88/4",
        adrs_full: "м. Миколаїв, вул. 4 Слобідська, 88/4",
        lat: 46.960350320402554,
        lon: 32.01773453844554,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 30,
        apart_ind: 5,
        q_2021: "74,192000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01773453844554,
          46.960350320402554
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 4 Слобідська, 88/5",
        adrs_full: "м. Миколаїв, вул. 4 Слобідська, 88/5",
        lat: 46.959784675274626,
        lon: 32.01765915607217,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 34,
        apart_ind: 3,
        q_2021: "93,827000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01765915607217,
          46.959784675274626
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 6 Поперечна, 30",
        adrs_full: "м. Миколаїв, вул. 6 Поперечна, 30",
        lat: 46.9662771336247,
        lon: 31.970813548299784,
        build_s: "?",
        bld_floor: 10,
        bld_porch: "?",
        bld_apart: 27,
        apart_ind: 7,
        q_2021: "31,174000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.970813548299784,
          46.9662771336247
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 6 Поперечна, 32",
        adrs_full: "м. Миколаїв, вул. 6 Поперечна, 32",
        lat: 46.96613213272111,
        lon: 31.970178948993752,
        build_s: "?",
        bld_floor: 10,
        bld_porch: "?",
        bld_apart: 32,
        apart_ind: 4,
        q_2021: "41,006000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.970178948993752,
          46.96613213272111
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 6 Слобідська, 1",
        adrs_full: "м. Миколаїв, вул. 6 Слобідська, 1",
        lat: 46.96811389279531,
        lon: 32.02080666660369,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 73,
        apart_ind: 0,
        q_2021: "393,810000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02080666660369,
          46.96811389279531
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 6 Слобідська, 11",
        adrs_full: "м. Миколаїв, вул. 6 Слобідська, 11",
        lat: 46.966023143601525,
        lon: 32.02223628527295,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 160,
        apart_ind: 10,
        q_2021: "923,251000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02223628527295,
          46.966023143601525
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 6 Слобідська, 3",
        adrs_full: "м. Миколаїв, вул. 6 Слобідська, 3",
        lat: 46.96815220401609,
        lon: 32.02142910463677,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 105,
        apart_ind: 5,
        q_2021: "312,437000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02142910463677,
          46.96815220401609
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 6 Слобідська, 43",
        adrs_full: "м. Миколаїв, вул. 6 Слобідська, 43",
        lat: 46.96382239754016,
        lon: 32.02246010373489,
        build_s: "?",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: null,
        apart_ind: null,
        q_2021: "602,001000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02246010373489,
          46.96382239754016
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 6 Слобідська, 45",
        adrs_full: "м. Миколаїв, вул. 6 Слобідська, 45",
        lat: 46.96294718397003,
        lon: 32.02155113235446,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 0,
        q_2021: "232,472000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02155113235446,
          46.96294718397003
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 6 Слобідська, 46",
        adrs_full: "м. Миколаїв, вул. 6 Слобідська, 46",
        lat: 46.96257736027574,
        lon: 32.023557579661095,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 108,
        apart_ind: 7,
        q_2021: "337,322000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.023557579661095,
          46.96257736027574
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 6 Слобідська, 46/А",
        adrs_full: "м. Миколаїв, вул. 6 Слобідська, 46/А",
        lat: 46.9629507,
        lon: 32.0240005,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 8,
        q_2021: "208,980000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0240005,
          46.9629507
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 6 Слобідська, 47",
        adrs_full: "м. Миколаїв, вул. 6 Слобідська, 47",
        lat: 46.962867222306805,
        lon: 32.0222492393249,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "364,002000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0222492393249,
          46.962867222306805
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 6 Слобідська, 48",
        adrs_full: "м. Миколаїв, вул. 6 Слобідська, 48",
        lat: 46.9599088,
        lon: 32.0208386,
        build_s: "6 638,40 м2",
        bld_floor: 16,
        bld_porch: 1,
        bld_apart: 95,
        apart_ind: 0,
        q_2021: "424,470116 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0208386,
          46.9599088
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 6 Слобідська, 48/А",
        adrs_full: "м. Миколаїв, вул. 6 Слобідська, 48/А",
        lat: 46.96169271515426,
        lon: 32.02411973270828,
        build_s: "6 899,20 м2",
        bld_floor: 16,
        bld_porch: 1,
        bld_apart: 95,
        apart_ind: 0,
        q_2021: "401,719643 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02411973270828,
          46.96169271515426
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 6 Слобідська, 49",
        adrs_full: "м. Миколаїв, вул. 6 Слобідська, 49",
        lat: 46.96243289099342,
        lon: 32.0220146199685,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 71,
        apart_ind: 2,
        q_2021: "299,810000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0220146199685,
          46.96243289099341
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 6 Слобідська, 5",
        adrs_full: "м. Миколаїв, вул. 6 Слобідська, 5",
        lat: 46.967266402652456,
        lon: 32.02138966217904,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 107,
        apart_ind: 2,
        q_2021: "342,665000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02138966217904,
          46.967266402652456
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 6 Слобідська, 5/А",
        adrs_full: "м. Миколаїв, вул. 6 Слобідська, 5/А",
        lat: 46.967658862534925,
        lon: 32.02144065676613,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 108,
        apart_ind: 1,
        q_2021: "332,431000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02144065676613,
          46.967658862534925
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 6 Слобідська, 51",
        adrs_full: "м. Миколаїв, вул. 6 Слобідська, 51",
        lat: 46.96201343497205,
        lon: 32.02183286037474,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 7,
        q_2021: "386,647000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02183286037474,
          46.96201343497205
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 6 Слобідська, 7",
        adrs_full: "м. Миколаїв, вул. 6 Слобідська, 7",
        lat: 46.96697253918033,
        lon: 32.021772121582224,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 5,
        q_2021: "318,807000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.021772121582224,
          46.96697253918033
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.6SLOB,48(САО)",
        address: "вул. 6 Слобідська, 7/А",
        adrs_full: "м. Миколаїв, вул. 6 Слобідська, 7/А",
        lat: 46.96685780014374,
        lon: 32.021107193407346,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 6,
        q_2021: "318,122000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.021107193407346,
          46.96685780014374
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.6SLOB,48а(САО)",
        address: "вул. 6 Слобідська, 9",
        adrs_full: "м. Миколаїв, вул. 6 Слобідська, 9",
        lat: 46.96640969998639,
        lon: 32.02164420152671,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 2,
        q_2021: "251,626000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02164420152671,
          46.96640969998639
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. 8 Березня, 1",
        adrs_full: "м. Миколаїв, вул. 8 Березня, 1",
        lat: 46.97578884368211,
        lon: 31.965968226702454,
        build_s: "2 467,40 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "278,839827 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.965968226702458,
          46.97578884368211
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 8 Березня, 103",
        adrs_full: "м. Миколаїв, вул. 8 Березня, 103",
        lat: 46.96305444879468,
        lon: 31.973164276744946,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 0,
        q_2021: "229,122000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.973164276744942,
          46.96305444879468
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 8 Березня, 105",
        adrs_full: "м. Миколаїв, вул. 8 Березня, 105",
        lat: 46.96236621893625,
        lon: 31.973357925210166,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 53,
        apart_ind: 2,
        q_2021: "296,699000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97335792521017,
          46.96236621893625
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 8 Березня, 105/А",
        adrs_full: "м. Миколаїв, вул. 8 Березня, 105/А",
        lat: 46.962441026958444,
        lon: 31.972744096112883,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 4,
        q_2021: "266,368000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.972744096112883,
          46.962441026958444
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. 8 Березня, 12",
        adrs_full: "м. Миколаїв, вул. 8 Березня, 12",
        lat: 46.97468693658848,
        lon: 31.967853558929903,
        build_s: "6 143,10 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 108,
        apart_ind: 16,
        q_2021: "463,495709 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.967853558929903,
          46.97468693658848
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. 8 Березня, 14",
        adrs_full: "м. Миколаїв, вул. 8 Березня, 14",
        lat: 46.974243175734905,
        lon: 31.96813855101078,
        build_s: "6 046,80 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 108,
        apart_ind: 18,
        q_2021: "350,479771 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96813855101078,
          46.974243175734905
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. 8 Березня, 14/А",
        adrs_full: "м. Миколаїв, вул. 8 Березня, 14/А",
        lat: 46.97359747218534,
        lon: 31.968167780967796,
        build_s: "5 758,30 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 107,
        apart_ind: 7,
        q_2021: "364,558423 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.968167780967796,
          46.97359747218533
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. 8 Березня, 2",
        adrs_full: "м. Миколаїв, вул. 8 Березня, 2",
        lat: 46.97595088692787,
        lon: 31.967046081367375,
        build_s: "3 193,60 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 5,
        q_2021: "275,746527 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.967046081367375,
          46.97595088692787
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. 8 Березня, 3",
        adrs_full: "м. Миколаїв, вул. 8 Березня, 3",
        lat: 46.97521296294294,
        lon: 31.966359177377527,
        build_s: "2 584,00 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 3,
        q_2021: "212,767973 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.966359177377527,
          46.97521296294295
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пр.Центральний,22-А/2",
        address: "вул. 8 Березня, 34",
        adrs_full: "м. Миколаїв, вул. 8 Березня, 34",
        lat: 46.97123273452203,
        lon: 31.969685911860253,
        build_s: "1 693,80 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 9,
        q_2021: "124,085217 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.969685911860253,
          46.97123273452203
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. 8 Березня, 39",
        adrs_full: "м. Миколаїв, вул. 8 Березня, 39",
        lat: 46.970923483008896,
        lon: 31.96913887072986,
        build_s: "10 091,20 м2",
        bld_floor: 9,
        bld_porch: 5,
        bld_apart: 180,
        apart_ind: 23,
        q_2021: "679,062800 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96913887072986,
          46.970923483008896
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 8 Березня, 51",
        adrs_full: "м. Миколаїв, вул. 8 Березня, 51",
        lat: 46.97021440009562,
        lon: 31.969225702360355,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 107,
        apart_ind: 0,
        q_2021: "424,692000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.969225702360355,
          46.97021440009562
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 8 Березня, 69",
        adrs_full: "м. Миколаїв, вул. 8 Березня, 69",
        lat: 46.96711382739166,
        lon: 31.970972029968863,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 71,
        apart_ind: 5,
        q_2021: "269,982000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.970972029968863,
          46.96711382739166
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 8 Березня, 71",
        adrs_full: "м. Миколаїв, вул. 8 Березня, 71",
        lat: 46.96666252349257,
        lon: 31.97115106345557,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 143,
        apart_ind: 21,
        q_2021: "500,880000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.971151063455576,
          46.96666252349257
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 8 Поздовжня, 2",
        adrs_full: "м. Миколаїв, вул. 8 Поздовжня, 2",
        lat: 46.9555051,
        lon: 32.0454234,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 27,
        apart_ind: 2,
        q_2021: "169,291000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0454234,
          46.9555051
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 8 Поздовжня, 4",
        adrs_full: "м. Миколаїв, вул. 8 Поздовжня, 4",
        lat: 46.9554399,
        lon: 32.0456319,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 2,
        q_2021: "43,744000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0456319,
          46.95543989999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 8 Поперечна, 1",
        adrs_full: "м. Миколаїв, вул. 8 Поперечна, 1",
        lat: 46.9623224,
        lon: 31.970284,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 69,
        apart_ind: 2,
        q_2021: "253,752000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.970284,
          46.9623224
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 8 Поперечна, 9",
        adrs_full: "м. Миколаїв, вул. 8 Поперечна, 9",
        lat: 46.963298780272176,
        lon: 31.972772505650006,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 39,
        apart_ind: 0,
        q_2021: "162,580000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97277250565001,
          46.963298780272176
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 9 Поздовжня, 2",
        adrs_full: "м. Миколаїв, вул. 9 Поздовжня, 2",
        lat: 46.9548892,
        lon: 32.0433444,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 26,
        apart_ind: 2,
        q_2021: "147,691000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0433444,
          46.9548892
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 9 Поздовжня, 4",
        adrs_full: "м. Миколаїв, вул. 9 Поздовжня, 4",
        lat: 46.9551268,
        lon: 32.0434612,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 10,
        apart_ind: 2,
        q_2021: "58,970000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0434612,
          46.9551268
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. 9 Поздовжня, 6",
        adrs_full: "м. Миколаїв, вул. 9 Поздовжня, 6",
        lat: 46.9549733,
        lon: 32.0438853,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "59,979000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0438853,
          46.9549733
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот Абрикосова,5",
        address: "вул. Абрикосова, 5",
        adrs_full: "м. Миколаїв, вул. Абрикосова, 5",
        lat: 46.95990418455717,
        lon: 31.95687139956902,
        build_s: "3 200,40 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 80,
        apart_ind: 1,
        q_2021: "230,437097 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95687139956902,
          46.95990418455717
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Авангардна, 47",
        adrs_full: "м. Миколаїв, вул. Авангардна, 47",
        lat: 46.94987160619609,
        lon: 32.03070643709239,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 100,
        apart_ind: 3,
        q_2021: "350,815000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03070643709239,
          46.94987160619609
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Авангардна, 49",
        adrs_full: "м. Миколаїв, вул. Авангардна, 49",
        lat: 46.94966775964243,
        lon: 32.03145550607226,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "186,996000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03145550607226,
          46.94966775964243
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Авангардна, 51",
        adrs_full: "м. Миколаїв, вул. Авангардна, 51",
        lat: 46.949318800180706,
        lon: 32.03174399885504,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "171,823000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03174399885504,
          46.949318800180706
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Авангардна, 53",
        adrs_full: "м. Миколаїв, вул. Авангардна, 53",
        lat: 46.95017219207063,
        lon: 32.03198694014582,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 90,
        apart_ind: null,
        q_2021: "283,883800 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03198694014582,
          46.95017219207063
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Авангардна, 53-А",
        adrs_full: "м. Миколаїв, вул. Авангардна, 53-А",
        lat: 46.9496193891598,
        lon: 32.03251837421937,
        build_s: "?",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: null,
        q_2021: "247,644100 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03251837421937,
          46.9496193891598
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адмірала Макарова, 26",
        adrs_full: "м. Миколаїв, вул. Адмірала Макарова, 26",
        lat: 46.9718864,
        lon: 31.9796437,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 17,
        apart_ind: 4,
        q_2021: "18,378000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9796437,
          46.9718864
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адмірала Макарова, 28",
        adrs_full: "м. Миколаїв, вул. Адмірала Макарова, 28",
        lat: 46.9716676,
        lon: 31.9798048,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 13,
        apart_ind: 1,
        q_2021: "69,465000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9798048,
          46.9716676
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адмірала Макарова, 56",
        adrs_full: "м. Миколаїв, вул. Адмірала Макарова, 56",
        lat: 46.9685241,
        lon: 31.9893546,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 65,
        apart_ind: 7,
        q_2021: "257,199000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9893546,
          46.9685241
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адмірала Макарова, 58",
        adrs_full: "м. Миколаїв, вул. Адмірала Макарова, 58",
        lat: 46.968576,
        lon: 31.9899998,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 25,
        apart_ind: 8,
        q_2021: "101,935000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9899998,
          46.968576
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 1/1",
        adrs_full: "м. Миколаїв, вул. Адміральська, 1/1",
        lat: 46.97832091350847,
        lon: 31.982336163633214,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 7,
        apart_ind: 2,
        q_2021: "35,233000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98233616363321,
          46.97832091350847
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 1/2",
        adrs_full: "м. Миколаїв, вул. Адміральська, 1/2",
        lat: 46.97811561900183,
        lon: 31.98280078309887,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 1,
        q_2021: "57,125000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98280078309887,
          46.97811561900183
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 10",
        adrs_full: "м. Миколаїв, вул. Адміральська, 10",
        lat: 46.97715323422353,
        lon: 31.98858909195382,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 21,
        apart_ind: 12,
        q_2021: "66,086000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98858909195382,
          46.97715323422353
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 10/А",
        adrs_full: "м. Миколаїв, вул. Адміральська, 10/А",
        lat: 46.97714417693177,
        lon: 31.989044861524892,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 4,
        q_2021: "46,670000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.989044861524892,
          46.97714417693177
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 12",
        adrs_full: "м. Миколаїв, вул. Адміральська, 12",
        lat: 46.97690566769674,
        lon: 31.989982950350793,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 23,
        apart_ind: 3,
        q_2021: "111,445000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.989982950350793,
          46.97690566769674
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 16",
        adrs_full: "м. Миколаїв, вул. Адміральська, 16",
        lat: 46.97630486123767,
        lon: 31.99126176011818,
        build_s: "?",
        bld_floor: 1,
        bld_porch: "?",
        bld_apart: 36,
        apart_ind: 11,
        q_2021: "191,436000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99126176011818,
          46.97630486123767
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 17/А",
        adrs_full: "м. Миколаїв, вул. Адміральська, 17/А",
        lat: 46.97628958444868,
        lon: 31.988868801154354,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 11,
        apart_ind: 3,
        q_2021: "49,853000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.988868801154354,
          46.97628958444868
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 18",
        adrs_full: "м. Миколаїв, вул. Адміральська, 18",
        lat: 46.976092220315635,
        lon: 31.992009612051973,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 57,
        apart_ind: 32,
        q_2021: "121,950000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.992009612051973,
          46.976092220315635
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 19",
        adrs_full: "м. Миколаїв, вул. Адміральська, 19",
        lat: 46.97615692293312,
        lon: 31.989540455621594,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 107,
        apart_ind: 5,
        q_2021: "368,271000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98954045562159,
          46.97615692293312
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 2/1",
        adrs_full: "м. Миколаїв, вул. Адміральська, 2/1",
        lat: 46.97928095676879,
        lon: 31.98238041310613,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 0,
        q_2021: "107,182000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98238041310613,
          46.97928095676879
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 2/2",
        adrs_full: "м. Миколаїв, вул. Адміральська, 2/2",
        lat: 46.97914510263751,
        lon: 31.98275653362595,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 25,
        apart_ind: 4,
        q_2021: "78,536000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98275653362595,
          46.97914510263751
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 2/3",
        adrs_full: "м. Миколаїв, вул. Адміральська, 2/3",
        lat: 46.979036419083954,
        lon: 31.983137079093066,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 2,
        q_2021: "112,688000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.983137079093062,
          46.979036419083954
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 2/4",
        adrs_full: "м. Миколаїв, вул. Адміральська, 2/4",
        lat: 46.978912640323315,
        lon: 31.983491074876422,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 25,
        apart_ind: 7,
        q_2021: "93,909000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.983491074876422,
          46.978912640323315
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 2/5",
        adrs_full: "м. Миколаїв, вул. Адміральська, 2/5",
        lat: 46.97853224526551,
        lon: 31.984123842339176,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 26,
        apart_ind: 4,
        q_2021: "116,357000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.984123842339176,
          46.97853224526551
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 2/6",
        adrs_full: "м. Миколаїв, вул. Адміральська, 2/6",
        lat: 46.97857451151669,
        lon: 31.9847743095911,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 4,
        q_2021: "133,935000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9847743095911,
          46.97857451151669
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 2/7",
        adrs_full: "м. Миколаїв, вул. Адміральська, 2/7",
        lat: 46.9790177,
        lon: 31.9843655,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 4,
        q_2021: "134,759000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9843655,
          46.9790177
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 2/А",
        adrs_full: "м. Миколаїв, вул. Адміральська, 2/А",
        lat: 46.97931114652887,
        lon: 31.984579611910252,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 92,
        apart_ind: 14,
        q_2021: "364,125000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.984579611910252,
          46.97931114652887
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 21",
        adrs_full: "м. Миколаїв, вул. Адміральська, 21",
        lat: 46.97609352067731,
        lon: 31.990403320343532,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 5,
        q_2021: "218,664000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.990403320343532,
          46.97609352067731
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 23",
        adrs_full: "м. Миколаїв, вул. Адміральська, 23",
        lat: 46.9757585,
        lon: 31.9913075,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 41,
        apart_ind: 3,
        q_2021: "221,144000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9913075,
          46.9757585
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 37",
        adrs_full: "м. Миколаїв, вул. Адміральська, 37",
        lat: 46.97252713546161,
        lon: 32.00066916384405,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 41,
        apart_ind: 15,
        q_2021: "151,589000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00066916384405,
          46.972527135461604
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 41",
        adrs_full: "м. Миколаїв, вул. Адміральська, 41",
        lat: 46.97239391068942,
        lon: 32.00204839617047,
        build_s: "?",
        bld_floor: 1,
        bld_porch: "?",
        bld_apart: 11,
        apart_ind: 8,
        q_2021: "9,843000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00204839617047,
          46.97239391068941
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Адміральська, 7/А",
        adrs_full: "м. Миколаїв, вул. Адміральська, 7/А",
        lat: 46.97810172412962,
        lon: 31.98385621163031,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 19,
        apart_ind: 6,
        q_2021: "82,055000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98385621163031,
          46.97810172412962
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Адмiрала Макарова, 2",
        adrs_full: "м. Миколаїв, вул. Адмiрала Макарова, 2",
        lat: 46.9754549,
        lon: 31.9731266,
        build_s: "4 395,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 69,
        apart_ind: 2,
        q_2021: "270,118992 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9731266,
          46.9754549
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Адмiрала Макарова, 8",
        adrs_full: "м. Миколаїв, вул. Адмiрала Макарова, 8",
        lat: 46.974215260507414,
        lon: 31.97515243299791,
        build_s: "1 563,90 м2",
        bld_floor: 3,
        bld_porch: 3,
        bld_apart: 27,
        apart_ind: 9,
        q_2021: "133,931024 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97515243299791,
          46.974215260507414
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.пл.Адмiралтейська,2(САО)",
        address: "площа Адмiралтейська, 2",
        adrs_full: "м. Миколаїв, площа Адмiралтейська, 2",
        lat: 46.9726441016706,
        lon: 32.01436474258429,
        build_s: "2 465,40 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 48,
        apart_ind: 1,
        q_2021: "218,999974 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01436474258429,
          46.972644101670596
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Набережна,25/1",
        address: "вул. Адмiральська, 28",
        adrs_full: "м. Миколаїв, вул. Адмiральська, 28",
        lat: 46.973027973728435,
        lon: 32.0015837767048,
        build_s: "2 841,50 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 72,
        apart_ind: 14,
        q_2021: "179,754049 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0015837767048,
          46.973027973728435
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Адмiральська,31-К",
        address: "вул. Адмiральська, 29",
        adrs_full: "м. Миколаїв, вул. Адмiральська, 29",
        lat: 46.97465129220959,
        lon: 31.995023158164383,
        build_s: "2 916,80 м2",
        bld_floor: 4,
        bld_porch: 5,
        bld_apart: 53,
        apart_ind: 13,
        q_2021: "277,328589 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "5",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.995023158164383,
          46.97465129220959
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Набережна,25/1",
        address: "вул. Адмiральська, 32",
        adrs_full: "м. Миколаїв, вул. Адмiральська, 32",
        lat: 46.97280454284864,
        lon: 32.002322742902564,
        build_s: "1 303,40 м2",
        bld_floor: 3,
        bld_porch: 3,
        bld_apart: 18,
        apart_ind: 9,
        q_2021: "81,194426 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.002322742902564,
          46.97280454284864
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Набережна,25/1",
        address: "вул. Адмiральська, 36",
        adrs_full: "м. Миколаїв, вул. Адмiральська, 36",
        lat: 46.97255695618886,
        lon: 32.00352632856599,
        build_s: "2 042,60 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 4,
        q_2021: "146,579118 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00352632856599,
          46.97255695618887
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Айвазовського, 11/А",
        adrs_full: "м. Миколаїв, вул. Айвазовського, 11/А",
        lat: 46.85287412256627,
        lon: 32.00355354042696,
        build_s: "4 796,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 108,
        apart_ind: 0,
        q_2021: "310,940139 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00355354042696,
          46.85287412256627
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Айвазовського, 11/Б",
        adrs_full: "м. Миколаїв, вул. Айвазовського, 11/Б",
        lat: 46.8526768973816,
        lon: 32.00304981317331,
        build_s: "5 468,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 108,
        apart_ind: 0,
        q_2021: "299,979733 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00304981317331,
          46.8526768973816
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Айвазовського, 11/В",
        adrs_full: "м. Миколаїв, вул. Айвазовського, 11/В",
        lat: 46.85247504267954,
        lon: 32.00256780379665,
        build_s: "5 324,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 108,
        apart_ind: 0,
        q_2021: "286,299928 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00256780379665,
          46.85247504267954
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Айвазовського, 13",
        adrs_full: "м. Миколаїв, вул. Айвазовського, 13",
        lat: 46.85231400114938,
        lon: 32.0034650997641,
        build_s: "5 152,00 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 171,
        apart_ind: 1,
        q_2021: "331,616404 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0034650997641,
          46.85231400114939
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Айвазовського, 2",
        adrs_full: "м. Миколаїв, вул. Айвазовського, 2",
        lat: 46.85846712726715,
        lon: 32.004457173286546,
        build_s: "3 082,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 4,
        q_2021: "243,610948 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.004457173286546,
          46.85846712726715
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Айвазовського, 3",
        adrs_full: "м. Миколаїв, вул. Айвазовського, 3",
        lat: 46.858548249437895,
        lon: 32.00302976562866,
        build_s: "5 717,30 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 94,
        apart_ind: 1,
        q_2021: "393,570084 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00302976562866,
          46.858548249437895
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Айвазовського, 3/Пр",
        adrs_full: "м. Миколаїв, вул. Айвазовського, 3/Пр",
        lat: 46.85883745850564,
        lon: 32.003215544742574,
        build_s: "1 755,41 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 18,
        apart_ind: 0,
        q_2021: "86,792300 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.003215544742574,
          46.85883745850564
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Айвазовського, 4",
        adrs_full: "м. Миколаїв, вул. Айвазовського, 4",
        lat: 46.85454911041868,
        lon: 32.00460956098167,
        build_s: "4 864,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 2,
        q_2021: "322,820084 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00460956098167,
          46.85454911041868
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Айвазовського, 5/А",
        adrs_full: "м. Миколаїв, вул. Айвазовського, 5/А",
        lat: 46.85395156452864,
        lon: 32.003256878608994,
        build_s: "8 230,60 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 140,
        apart_ind: 1,
        q_2021: "495,569700 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.003256878608994,
          46.85395156452864
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Айвазовського, 6",
        adrs_full: "м. Миколаїв, вул. Айвазовського, 6",
        lat: 46.852925,
        lon: 32.0018309,
        build_s: "5 355,30 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 128,
        apart_ind: 1,
        q_2021: "348,949839 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0018309,
          46.852925
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Айвазовського, 6/А",
        adrs_full: "м. Миколаїв, вул. Айвазовського, 6/А",
        lat: 46.85471924715075,
        lon: 32.00271139950858,
        build_s: "3 947,70 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 387,
        apart_ind: 0,
        q_2021: "118,469700 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00271139950858,
          46.85471924715074
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Айвазовського, 7",
        adrs_full: "м. Миколаїв, вул. Айвазовського, 7",
        lat: 46.85563021378446,
        lon: 32.00363908517364,
        build_s: "6 763,00 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 198,
        apart_ind: 4,
        q_2021: "380,499990 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00363908517364,
          46.85563021378446
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Пеpедова,69-к",
        address: "вул. Паркова, 1",
        adrs_full: "м. Миколаїв, вул. Паркова, 1",
        lat: 46.942514560926384,
        lon: 32.02674387632782,
        build_s: "2 992,50 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 61,
        apart_ind: 5,
        q_2021: "264,100075 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: "вул. Анатолiя Олiйника, 1",
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02674387632782,
          46.942514560926384
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Пеpедова,69-к",
        address: "вул. Паркова, 3",
        adrs_full: "м. Миколаїв, вул. Паркова, 3",
        lat: 46.942835895068605,
        lon: 32.02721453715053,
        build_s: "2 950,90 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 3,
        q_2021: "209,079898 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: "вул. Анатолiя Олiйника, 3",
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02721453715053,
          46.94283589506861
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Пеpедова,69-к",
        address: "вул. Паркова, 3/А",
        adrs_full: "м. Миколаїв, вул. Паркова, 3/А",
        lat: 46.942415099967675,
        lon: 32.027528311032334,
        build_s: "2 957,10 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 12,
        q_2021: "222,060057 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: "вул. Анатолiя Олiйника, 3/А",
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.027528311032334,
          46.94241509996767
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Паркова, 38",
        adrs_full: "м. Миколаїв, вул. Паркова, 38",
        lat: 46.946151104719384,
        lon: 32.041674801273444,
        build_s: "5 941,70 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 119,
        apart_ind: 11,
        q_2021: "337,939670 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: "вул. Анатолiя Олiйника, 38",
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.041674801273444,
          46.946151104719384
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Паркова, 40",
        adrs_full: "м. Миколаїв, вул. Паркова, 40",
        lat: 46.94645741469326,
        lon: 32.04106938995423,
        build_s: "2 945,20 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 80,
        apart_ind: 0,
        q_2021: "198,159895 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: "вул. Анатолiя Олiйника, 40",
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04106938995423,
          46.94645741469326
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Паркова, 42",
        adrs_full: "м. Миколаїв, вул. Паркова, 42",
        lat: 46.94686696304887,
        lon: 32.04085744443029,
        build_s: "2 903,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 80,
        apart_ind: 1,
        q_2021: "189,375021 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: "вул. Анатолiя Олiйника, 42",
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04085744443029,
          46.94686696304887
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Пеpедова,69-к",
        address: "вул. Паркова, 5",
        adrs_full: "м. Миколаїв, вул. Паркова, 5",
        lat: 46.9428408625765,
        lon: 32.02809260653458,
        build_s: "2 954,10 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 11,
        q_2021: "159,590149 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: "вул. Анатолiя Олiйника, 5",
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02809260653458,
          46.9428408625765
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "вул. Артилерійська, 1",
        adrs_full: "м. Миколаїв, вул. Артилерійська, 1",
        lat: 46.98022435808876,
        lon: 31.981714899454616,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 85,
        apart_ind: 7,
        q_2021: "332,510000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.981714899454616,
          46.98022435808876
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Артилерійська, 10",
        adrs_full: "м. Миколаїв, вул. Артилерійська, 10",
        lat: 46.9769746,
        lon: 31.9809739,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 63,
        apart_ind: 5,
        q_2021: "264,494000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9809739,
          46.9769746
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Артилерійська, 2",
        adrs_full: "м. Миколаїв, вул. Артилерійська, 2",
        lat: 46.97976320934293,
        lon: 31.983177415883286,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 2,
        q_2021: "259,419000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.983177415883286,
          46.97976320934293
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Артилерійська, 3",
        adrs_full: "м. Миколаїв, вул. Артилерійська, 3",
        lat: 46.9798243698421,
        lon: 31.98182729469463,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 16,
        apart_ind: 3,
        q_2021: "83,567000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98182729469463,
          46.9798243698421
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Артилерійська, 5",
        adrs_full: "м. Миколаїв, вул. Артилерійська, 5",
        lat: 46.97952851229822,
        lon: 31.98161047227732,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 13,
        apart_ind: 2,
        q_2021: "8,433000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98161047227732,
          46.97952851229822
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Артилерійська, 8",
        adrs_full: "м. Миколаїв, вул. Артилерійська, 8",
        lat: 46.9783437,
        lon: 31.9819263,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 7,
        apart_ind: 3,
        q_2021: "12,098000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9819263,
          46.9783437
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Архiтектора Старова, 10",
        adrs_full: "м. Миколаїв, вул. Архiтектора Старова, 10",
        lat: 47.0165833,
        lon: 32.0073272,
        build_s: "5 308,52 м2",
        bld_floor: 13,
        bld_porch: 1,
        bld_apart: 77,
        apart_ind: 0,
        q_2021: "312,800132 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0073272,
          47.0165833
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Малко-Тирнiвська,1-А",
        address: "вул. Архiтектора Старова, 10/Г",
        adrs_full: "м. Миколаїв, вул. Архiтектора Старова, 10/Г",
        lat: 47.01441499782821,
        lon: 32.00671599178661,
        build_s: "8 362,00 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 143,
        apart_ind: 3,
        q_2021: "498,152400 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00671599178661,
          47.014414997828204
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Арх.Старова,10-г",
        address: "вул. Архiтектора Старова, 2/А",
        adrs_full: "м. Миколаїв, вул. Архiтектора Старова, 2/А",
        lat: 47.0177346,
        lon: 32.004059,
        build_s: "4 396,70 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 19,
        q_2021: "206,657331 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.004059,
          47.0177346
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Малко-Тирнiвська,1-А",
        address: "вул. Архiтектора Старова, 2/Б",
        adrs_full: "м. Миколаїв, вул. Архiтектора Старова, 2/Б",
        lat: 47.01696734525046,
        lon: 32.00352624096981,
        build_s: "3 153,43 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 11,
        q_2021: "226,029871 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00352624096981,
          47.01696734525046
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Малко-Тирнiвська,1-А",
        address: "вул. Архiтектора Старова, 2/В",
        adrs_full: "м. Миколаїв, вул. Архiтектора Старова, 2/В",
        lat: 47.018182,
        lon: 32.0041114,
        build_s: "522,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 8,
        apart_ind: 7,
        q_2021: "7,312600 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0041114,
          47.018182
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Малко-Тирнiвська,1-А",
        address: "вул. Архiтектора Старова, 3",
        adrs_full: "м. Миколаїв, вул. Архiтектора Старова, 3",
        lat: 47.01945966359975,
        lon: 32.002587042748075,
        build_s: "1 587,20 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 30,
        apart_ind: 11,
        q_2021: "75,810064 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.002587042748075,
          47.01945966359975
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Малко-Тирнiвська,1-А",
        address: "вул. Архiтектора Старова, 4",
        adrs_full: "м. Миколаїв, вул. Архiтектора Старова, 4",
        lat: 47.01753374928778,
        lon: 32.00474091897188,
        build_s: "5 180,70 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 78,
        apart_ind: 3,
        q_2021: "299,566618 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00474091897188,
          47.01753374928778
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Малко-Тирнiвська,1-А",
        address: "вул. Архiтектора Старова, 4/А",
        adrs_full: "м. Миколаїв, вул. Архiтектора Старова, 4/А",
        lat: 47.0168207261569,
        lon: 32.004153477524284,
        build_s: "4 625,00 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 107,
        apart_ind: 22,
        q_2021: "335,168271 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.004153477524284,
          47.0168207261569
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Малко-Тирнiвська,1-А",
        address: "вул. Архiтектора Старова, 4/Б",
        adrs_full: "м. Миколаїв, вул. Архiтектора Старова, 4/Б",
        lat: 47.016232584775075,
        lon: 32.00450004366484,
        build_s: "6 368,70 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 79,
        apart_ind: 4,
        q_2021: "258,126292 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00450004366484,
          47.016232584775075
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Малко-Тирнiвська,1-А",
        address: "вул. Архiтектора Старова, 4/В",
        adrs_full: "м. Миколаїв, вул. Архiтектора Старова, 4/В",
        lat: 47.01576697312741,
        lon: 32.002618039892305,
        build_s: "8 433,10 м2",
        bld_floor: 10,
        bld_porch: 4,
        bld_apart: 159,
        apart_ind: 9,
        q_2021: "508,560697 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.002618039892305,
          47.01576697312742
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Малко-Тирнiвська,1-А",
        address: "вул. Архiтектора Старова, 4/Г",
        adrs_full: "м. Миколаїв, вул. Архiтектора Старова, 4/Г",
        lat: 47.0156913,
        lon: 32.0034865,
        build_s: "4 282,80 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 79,
        apart_ind: 11,
        q_2021: "232,689135 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0034865,
          47.0156913
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Малко-Тирнiвська,1-А",
        address: "вул. Архiтектора Старова, 4/Д",
        adrs_full: "м. Миколаїв, вул. Архiтектора Старова, 4/Д",
        lat: 47.0152312,
        lon: 32.0027284,
        build_s: "6 245,40 м2",
        bld_floor: 10,
        bld_porch: 3,
        bld_apart: 119,
        apart_ind: 5,
        q_2021: "422,477062 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0027284,
          47.0152312
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Малко-Тирнiвська,1-А",
        address: "вул. Архiтектора Старова, 4/Е",
        adrs_full: "м. Миколаїв, вул. Архiтектора Старова, 4/Е",
        lat: 47.01419176971443,
        lon: 32.00129818779577,
        build_s: "6 554,00 м2",
        bld_floor: 10,
        bld_porch: 3,
        bld_apart: 113,
        apart_ind: 1,
        q_2021: "442,917808 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00129818779577,
          47.01419176971443
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Архiтек.Старова,4-Е(САО)",
        address: "вул. Архiтектора Старова, 4/Ж",
        adrs_full: "м. Миколаїв, вул. Архiтектора Старова, 4/Ж",
        lat: 47.013747797545626,
        lon: 32.000694676037476,
        build_s: "6 694,70 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 108,
        apart_ind: 1,
        q_2021: "379,438636 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.000694676037476,
          47.013747797545626
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Архiтек.Старова 4-Ж(САО)",
        address: "вул. Архiтектора Старова, 6",
        adrs_full: "м. Миколаїв, вул. Архiтектора Старова, 6",
        lat: 47.01760413037555,
        lon: 32.00562501075814,
        build_s: "9 002,20 м2",
        bld_floor: 10,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 13,
        q_2021: "435,332950 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00562501075814,
          47.01760413037555
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Малко-Тирнiвська,1-А",
        address: "вул. Архiтектора Старова, 6/А",
        adrs_full: "м. Миколаїв, вул. Архiтектора Старова, 6/А",
        lat: 47.01702844644654,
        lon: 32.00567000944188,
        build_s: "5 485,00 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 79,
        apart_ind: 6,
        q_2021: "257,999842 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00567000944188,
          47.01702844644654
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Малко-Тирнiвська,1-А",
        address: "вул. Архiтектора Старова, 6/Б",
        adrs_full: "м. Миколаїв, вул. Архiтектора Старова, 6/Б",
        lat: 47.01692377597445,
        lon: 32.00504002786963,
        build_s: "5 273,00 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 79,
        apart_ind: 5,
        q_2021: "226,849770 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00504002786963,
          47.01692377597445
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Малко-Тирнiвська,1-А",
        address: "вул. Архiтектора Старова, 6/В",
        adrs_full: "м. Миколаїв, вул. Архiтектора Старова, 6/В",
        lat: 47.01634086597172,
        lon: 32.00515649505105,
        build_s: "6 086,40 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 79,
        apart_ind: 8,
        q_2021: "257,103281 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00515649505105,
          47.01634086597172
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Малко-Тирнiвська,1-А",
        address: "вул. Архiтектора Старова, 8/А",
        adrs_full: "м. Миколаїв, вул. Архiтектора Старова, 8/А",
        lat: 47.0159380901822,
        lon: 32.005688630618565,
        build_s: "4 630,00 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 79,
        apart_ind: 7,
        q_2021: "235,197501 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.005688630618565,
          47.015938090182196
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Малко-Тирнiвська,1-А",
        address: "вул. Архiтектора Старова, 8/Б",
        adrs_full: "м. Миколаїв, вул. Архiтектора Старова, 8/Б",
        lat: 47.01527021546057,
        lon: 32.00575433394908,
        build_s: "9 280,00 м2",
        bld_floor: 10,
        bld_porch: 4,
        bld_apart: 159,
        apart_ind: 18,
        q_2021: "467,304685 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00575433394908,
          47.01527021546057
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Малко-Тирнiвська,1-А",
        address: "вул. Безiменна, 101",
        adrs_full: "м. Миколаїв, вул. Безiменна, 101",
        lat: 46.993459135215275,
        lon: 32.02266634756572,
        build_s: "731,80 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 3,
        q_2021: "48,220032 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02266634756572,
          46.993459135215275
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Безiменна,91-в",
        address: "вул. Безiменна, 74",
        adrs_full: "м. Миколаїв, вул. Безiменна, 74",
        lat: 46.99270147989108,
        lon: 32.02412315746604,
        build_s: "637,60 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "69,440009 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02412315746604,
          46.99270147989108
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Безiменна,91-в",
        address: "вул. Безiменна, 76",
        adrs_full: "м. Миколаїв, вул. Безiменна, 76",
        lat: 46.99298895502154,
        lon: 32.02382782617391,
        build_s: "609,00 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 20,
        apart_ind: 1,
        q_2021: "54,640014 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02382782617391,
          46.99298895502154
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Безiменна,91-в",
        address: "вул. Безiменна, 78",
        adrs_full: "м. Миколаїв, вул. Безiменна, 78",
        lat: 46.99317360976416,
        lon: 32.023732458777495,
        build_s: "629,10 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "89,180012 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.023732458777495,
          46.993173609764156
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Безiменна,91-в",
        address: "вул. Безiменна, 87",
        adrs_full: "м. Миколаїв, вул. Безiменна, 87",
        lat: 46.99011929129851,
        lon: 32.025077422516716,
        build_s: "2 802,40 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 78,
        apart_ind: 10,
        q_2021: "189,110016 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.025077422516716,
          46.99011929129851
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Безiменна,91-в",
        address: "вул. Безiменна, 89",
        adrs_full: "м. Миколаїв, вул. Безiменна, 89",
        lat: 46.990539496135305,
        lon: 32.024880329513024,
        build_s: "719,20 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 5,
        q_2021: "53,999984 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.024880329513024,
          46.990539496135305
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Безiменна,91-в",
        address: "вул. Безiменна, 93",
        adrs_full: "м. Миколаїв, вул. Безiменна, 93",
        lat: 46.991299111989605,
        lon: 32.0239788278818,
        build_s: "640,40 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "70,877720 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0239788278818,
          46.991299111989605
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Безiменна,91-в",
        address: "вул. Безiменна, 95",
        adrs_full: "м. Миколаїв, вул. Безiменна, 95",
        lat: 46.99166263861435,
        lon: 32.02375522012423,
        build_s: "718,40 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "63,240067 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02375522012423,
          46.99166263861435
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Безiменна,91-в",
        address: "вул. Безiменна, 97",
        adrs_full: "м. Миколаїв, вул. Безiменна, 97",
        lat: 46.99204605705179,
        lon: 32.02359613160108,
        build_s: "736,30 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 3,
        q_2021: "73,419975 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02359613160108,
          46.99204605705179
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Безiменна,91-в",
        address: "вул. Безiменна, 99",
        adrs_full: "м. Миколаїв, вул. Безiменна, 99",
        lat: 46.9927097985046,
        lon: 32.023095002753124,
        build_s: "706,60 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 3,
        q_2021: "66,610015 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.023095002753124,
          46.9927097985046
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Безiменна,91-в",
        address: "вул. Будівельників, 10",
        adrs_full: "м. Миколаїв, вул. Будівельників, 10",
        lat: 46.95396559561216,
        lon: 32.039936316308115,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 58,
        apart_ind: 3,
        q_2021: "174,873000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.039936316308115,
          46.95396559561216
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Будівельників, 12",
        adrs_full: "м. Миколаїв, вул. Будівельників, 12",
        lat: 46.95335837743926,
        lon: 32.038688944465655,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 0,
        q_2021: "214,080000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.038688944465655,
          46.95335837743926
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Будівельників, 14",
        adrs_full: "м. Миколаїв, вул. Будівельників, 14",
        lat: 46.9531253,
        lon: 32.0376491,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 119,
        apart_ind: 5,
        q_2021: "291,195000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0376491,
          46.9531253
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Будівельників, 14/А",
        adrs_full: "м. Миколаїв, вул. Будівельників, 14/А",
        lat: 46.95263928307664,
        lon: 32.03752820574254,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 3,
        q_2021: "231,717000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03752820574254,
          46.95263928307664
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Будівельників, 14/Б",
        adrs_full: "м. Миколаїв, вул. Будівельників, 14/Б",
        lat: 46.9522419469026,
        lon: 32.03776393403887,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 2,
        q_2021: "292,637000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03776393403887,
          46.9522419469026
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Будівельників, 16",
        adrs_full: "м. Миколаїв, вул. Будівельників, 16",
        lat: 46.951657821602325,
        lon: 32.036800214469125,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 2,
        q_2021: "225,473000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.036800214469125,
          46.951657821602325
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Будівельників, 16/А",
        adrs_full: "м. Миколаїв, вул. Будівельників, 16/А",
        lat: 46.951450526933975,
        lon: 32.03772136686329,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "248,061000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03772136686329,
          46.951450526933975
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Будівельників, 18",
        adrs_full: "м. Миколаїв, вул. Будівельників, 18",
        lat: 46.95244553401959,
        lon: 32.03482631648164,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 123,
        apart_ind: 8,
        q_2021: "384,158000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03482631648164,
          46.95244553401959
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Будівельників, 18/А",
        adrs_full: "м. Миколаїв, вул. Будівельників, 18/А",
        lat: 46.95202,
        lon: 32.0352469,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 3,
        q_2021: "279,355000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0352469,
          46.95202
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Будівельників, 18/Б",
        adrs_full: "м. Миколаїв, вул. Будівельників, 18/Б",
        lat: 46.95166818631466,
        lon: 32.035565262907724,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 81,
        apart_ind: 4,
        q_2021: "255,435000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.035565262907724,
          46.95166818631466
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Будівельників, 18/В",
        adrs_full: "м. Миколаїв, вул. Будівельників, 18/В",
        lat: 46.95126741597574,
        lon: 32.03593473612077,
        build_s: "?",
        bld_floor: 10,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 4,
        q_2021: "359,635000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03593473612077,
          46.95126741597574
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Будівельників, 22",
        adrs_full: "м. Миколаїв, вул. Будівельників, 22",
        lat: 46.9520581,
        lon: 32.0367456,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 49,
        apart_ind: 9,
        q_2021: "167,407000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0367456,
          46.9520581
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Будівельників, 6",
        adrs_full: "м. Миколаїв, вул. Будівельників, 6",
        lat: 46.9564582,
        lon: 32.0418304,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 25,
        apart_ind: 1,
        q_2021: "160,110000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0418304,
          46.9564582
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Бузника, 10",
        adrs_full: "м. Миколаїв, вул. Бузника, 10",
        lat: 46.9683336,
        lon: 31.9623143,
        build_s: "2 080,70 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 42,
        apart_ind: 6,
        q_2021: "117,006080 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9623143,
          46.9683336
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пров.Транспортний,6/2",
        address: "вул. Бузника, 12",
        adrs_full: "м. Миколаїв, вул. Бузника, 12",
        lat: 46.9675345,
        lon: 31.9622748,
        build_s: "1 954,50 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 6,
        q_2021: "136,439010 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9622748,
          46.9675345
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пров.Транспортний,6/2",
        address: "вул. Бузника, 14",
        adrs_full: "м. Миколаїв, вул. Бузника, 14",
        lat: 46.9669798,
        lon: 31.9621608,
        build_s: "2 241,30 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 36,
        apart_ind: 3,
        q_2021: "107,758930 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9621608,
          46.9669798
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пров.Транспортний,6/2",
        address: "вул. Бузника, 16",
        adrs_full: "м. Миколаїв, вул. Бузника, 16",
        lat: 46.9664089,
        lon: 31.9620989,
        build_s: "1 979,30 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 6,
        q_2021: "124,409925 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9620989,
          46.9664089
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пров.Транспортний,6/2",
        address: "вул. Бузника, 18",
        adrs_full: "м. Миколаїв, вул. Бузника, 18",
        lat: 46.96601935815989,
        lon: 31.962341774392787,
        build_s: "1 928,80 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 1,
        q_2021: "165,547101 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.962341774392787,
          46.96601935815989
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пров.Транспортний,6/2",
        address: "вул. Бузника, 2",
        adrs_full: "м. Миколаїв, вул. Бузника, 2",
        lat: 46.9708505,
        lon: 31.9627269,
        build_s: "2 495,70 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 3,
        q_2021: "218,663955 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9627269,
          46.9708505
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Бузника, 2/А",
        adrs_full: "м. Миколаїв, вул. Бузника, 2/А",
        lat: 46.9705745,
        lon: 31.9636136,
        build_s: "3 242,60 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 4,
        q_2021: "242,701746 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9636136,
          46.9705745
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Бузника, 4",
        adrs_full: "м. Миколаїв, вул. Бузника, 4",
        lat: 46.9701025,
        lon: 31.9626246,
        build_s: "1 498,10 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 36,
        apart_ind: 5,
        q_2021: "115,160005 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9626246,
          46.9701025
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Бузника, 4/А",
        adrs_full: "м. Миколаїв, вул. Бузника, 4/А",
        lat: 46.9699034,
        lon: 31.9635351,
        build_s: "4 369,10 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 100,
        apart_ind: 4,
        q_2021: "336,500152 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9635351,
          46.9699034
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Бузника, 6",
        adrs_full: "м. Миколаїв, вул. Бузника, 6",
        lat: 46.9696783,
        lon: 31.9625651,
        build_s: "1 836,60 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 2,
        q_2021: "160,370069 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9625651,
          46.9696783
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Бузника, 8",
        adrs_full: "м. Миколаїв, вул. Бузника, 8",
        lat: 46.9692006,
        lon: 31.9624748,
        build_s: "1 756,30 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 3,
        q_2021: "157,300225 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9624748,
          46.9692006
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Бузький Бульвар, 1",
        adrs_full: "м. Миколаїв, вул. Бузький Бульвар, 1",
        lat: 46.9774959,
        lon: 31.9643355,
        build_s: "3 657,10 м2",
        bld_floor: 3,
        bld_porch: 4,
        bld_apart: 41,
        apart_ind: 12,
        q_2021: "159,109884 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9643355,
          46.9774959
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Бузький Бульвар, 1/А",
        adrs_full: "м. Миколаїв, вул. Бузький Бульвар, 1/А",
        lat: 46.9768352,
        lon: 31.9651036,
        build_s: "3 657,10 м2",
        bld_floor: 4,
        bld_porch: 4,
        bld_apart: 63,
        apart_ind: 11,
        q_2021: "261,575350 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9651036,
          46.9768352
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Бузький Бульвар, 1/Б",
        adrs_full: "м. Миколаїв, вул. Бузький Бульвар, 1/Б",
        lat: 46.9773575,
        lon: 31.9653221,
        build_s: "92,80 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 2,
        apart_ind: 0,
        q_2021: "13,712200 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9653221,
          46.9773575
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Бузький Бульвар, 1/В",
        adrs_full: "м. Миколаїв, вул. Бузький Бульвар, 1/В",
        lat: 46.9774959,
        lon: 31.9643355,
        build_s: "2 483,40 м2",
        bld_floor: 10,
        bld_porch: 1,
        bld_apart: 60,
        apart_ind: 1,
        q_2021: "224,444136 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9643355,
          46.9774959
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Бузький Бульвар, 1/Г",
        adrs_full: "м. Миколаїв, вул. Бузький Бульвар, 1/Г",
        lat: 46.9773157021198,
        lon: 31.966296281686805,
        build_s: "2 380,00 м2",
        bld_floor: 10,
        bld_porch: 1,
        bld_apart: 58,
        apart_ind: 9,
        q_2021: "138,164817 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.966296281686805,
          46.9773157021198
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Бузький Бульвар, 11",
        adrs_full: "м. Миколаїв, вул. Бузький Бульвар, 11",
        lat: 46.9800083,
        lon: 31.9679136,
        build_s: "2 014,50 м2",
        bld_floor: 3,
        bld_porch: 4,
        bld_apart: 39,
        apart_ind: 8,
        q_2021: "137,556433 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9679136,
          46.98000829999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Бузький Бульвар, 13",
        adrs_full: "м. Миколаїв, вул. Бузький Бульвар, 13",
        lat: 46.9804331,
        lon: 31.9684452,
        build_s: "2 518,50 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 64,
        apart_ind: 4,
        q_2021: "184,686067 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9684452,
          46.98043310000001
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Бузький Бульвар, 15",
        adrs_full: "м. Миколаїв, вул. Бузький Бульвар, 15",
        lat: 46.9809469,
        lon: 31.969233,
        build_s: "2 549,40 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 64,
        apart_ind: 2,
        q_2021: "209,111661 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.969233,
          46.9809469
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Бузький Бульвар, 15/А",
        adrs_full: "м. Миколаїв, вул. Бузький Бульвар, 15/А",
        lat: 46.9805981,
        lon: 31.9698116,
        build_s: "4 228,80 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 90,
        apart_ind: 5,
        q_2021: "335,508067 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9698116,
          46.98059809999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Бузький Бульвар, 17/А",
        adrs_full: "м. Миколаїв, вул. Бузький Бульвар, 17/А",
        lat: 46.9822043,
        lon: 31.9719664,
        build_s: "3 141,50 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 2,
        q_2021: "206,179108 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9719664,
          46.9822043
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Бузький Бульвар, 3",
        adrs_full: "м. Миколаїв, вул. Бузький Бульвар, 3",
        lat: 46.9781544,
        lon: 31.9652679,
        build_s: "1 262,60 м2",
        bld_floor: 3,
        bld_porch: 3,
        bld_apart: 27,
        apart_ind: 4,
        q_2021: "135,849979 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9652679,
          46.9781544
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Бузький Бульвар, 3/Б",
        adrs_full: "м. Миколаїв, вул. Бузький Бульвар, 3/Б",
        lat: 46.9777499,
        lon: 31.9659579,
        build_s: "2 442,70 м2",
        bld_floor: 10,
        bld_porch: 1,
        bld_apart: 60,
        apart_ind: 1,
        q_2021: "237,219959 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9659579,
          46.9777499
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Бузький Бульвар, 5",
        adrs_full: "м. Миколаїв, вул. Бузький Бульвар, 5",
        lat: 46.9786166,
        lon: 31.9658825,
        build_s: "1 605,70 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 27,
        apart_ind: 4,
        q_2021: "138,832083 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9658825,
          46.9786166
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Бузький Бульвар, 9",
        adrs_full: "м. Миколаїв, вул. Бузький Бульвар, 9",
        lat: 46.9794338,
        lon: 31.9670499,
        build_s: "1 628,30 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 26,
        apart_ind: 11,
        q_2021: "104,299973 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9670499,
          46.9794338
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Бiла, 32",
        adrs_full: "м. Миколаїв, вул. Бiла, 32",
        lat: 46.95989104309045,
        lon: 31.955662146591465,
        build_s: "1 636,70 м2",
        bld_floor: 3,
        bld_porch: 5,
        bld_apart: 40,
        apart_ind: 4,
        q_2021: "142,377500 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "5",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.955662146591465,
          46.95989104309045
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот Абрикосова,5",
        address: "вул. Бiла, 59",
        adrs_full: "м. Миколаїв, вул. Бiла, 59",
        lat: 46.95903685304526,
        lon: 31.952110856163625,
        build_s: "3 759,90 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 80,
        apart_ind: 4,
        q_2021: "273,449652 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.952110856163625,
          46.95903685304526
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Бiла, 61",
        adrs_full: "м. Миколаїв, вул. Бiла, 61",
        lat: 46.9586605090181,
        lon: 31.951128769506393,
        build_s: "3 774,40 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 80,
        apart_ind: 1,
        q_2021: "267,985123 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.951128769506393,
          46.9586605090181
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Бiла, 61/А",
        adrs_full: "м. Миколаїв, вул. Бiла, 61/А",
        lat: 46.959336059789706,
        lon: 31.950635033615043,
        build_s: "4 747,20 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 100,
        apart_ind: 9,
        q_2021: "306,546083 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.950635033615043,
          46.959336059789706
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Бiла, 63",
        adrs_full: "м. Миколаїв, вул. Бiла, 63",
        lat: 46.95827043842503,
        lon: 31.95014641794995,
        build_s: "3 757,70 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 80,
        apart_ind: 3,
        q_2021: "290,880038 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95014641794995,
          46.95827043842503
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Бiла, 65",
        adrs_full: "м. Миколаїв, вул. Бiла, 65",
        lat: 46.9578015752591,
        lon: 31.949368358986813,
        build_s: "2 667,60 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 1,
        q_2021: "205,939980 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.949368358986813,
          46.9578015752591
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "Кот.Генерала Карпенка,8-3",
        address: "вул. Бiла, 67",
        adrs_full: "м. Миколаїв, вул. Бiла, 67",
        lat: 46.95723008500474,
        lon: 31.94748078778625,
        build_s: "2 692,90 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 56,
        apart_ind: 6,
        q_2021: "213,178156 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94748078778625,
          46.95723008500474
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Бiла, 82",
        adrs_full: "м. Миколаїв, вул. Бiла, 82",
        lat: 46.956171601540404,
        lon: 31.94647670520472,
        build_s: "4 567,00 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 100,
        apart_ind: 2,
        q_2021: "246,409301 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94647670520472,
          46.956171601540404
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. В'Ячеслава Чорновола, 11",
        adrs_full: "м. Миколаїв, вул. В'Ячеслава Чорновола, 11",
        lat: 46.953788874827225,
        lon: 32.06915635329632,
        build_s: "2 083,50 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 48,
        apart_ind: 2,
        q_2021: "176,510030 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06915635329632,
          46.953788874827225
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. В'Ячеслава Чорновола, 13",
        adrs_full: "м. Миколаїв, вул. В'Ячеслава Чорновола, 13",
        lat: 46.9543441,
        lon: 32.069658,
        build_s: "2 113,20 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 48,
        apart_ind: 1,
        q_2021: "187,748069 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.069658,
          46.9543441
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. В'Ячеслава Чорновола, 15",
        adrs_full: "м. Миколаїв, вул. В'Ячеслава Чорновола, 15",
        lat: 46.9601369,
        lon: 32.0746718,
        build_s: "2 133,00 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 48,
        apart_ind: 0,
        q_2021: "158,860082 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0746718,
          46.96013689999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. В'Ячеслава Чорновола, 3",
        adrs_full: "м. Миколаїв, вул. В'Ячеслава Чорновола, 3",
        lat: 46.95123446648108,
        lon: 32.0670129590033,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 32,
        apart_ind: 2,
        q_2021: "118,550000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0670129590033,
          46.95123446648108
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. В'Ячеслава Чорновола, 5",
        adrs_full: "м. Миколаїв, вул. В'Ячеслава Чорновола, 5",
        lat: 46.95164643044341,
        lon: 32.0673290811407,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 32,
        apart_ind: 3,
        q_2021: "132,294000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0673290811407,
          46.95164643044341
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. В'Ячеслава Чорновола, 7",
        adrs_full: "м. Миколаїв, вул. В'Ячеслава Чорновола, 7",
        lat: 46.9521824,
        lon: 32.0676688,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "222,175000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0676688,
          46.9521824
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. В'Ячеслава Чорновола, 9",
        adrs_full: "м. Миколаїв, вул. В'Ячеслава Чорновола, 9",
        lat: 46.95298044848737,
        lon: 32.06846624227564,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 100,
        apart_ind: 2,
        q_2021: "380,111000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06846624227564,
          46.95298044848737
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Велика Морська, 1",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 1",
        lat: 46.9812743,
        lon: 31.9700776,
        build_s: "1 173,20 м2",
        bld_floor: 8,
        bld_porch: 1,
        bld_apart: 28,
        apart_ind: 1,
        q_2021: "113,389305 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9700776,
          46.9812743
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 11",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 11",
        lat: 46.9800458,
        lon: 31.9715855,
        build_s: "1 853,30 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 2,
        q_2021: "162,071972 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9715855,
          46.9800458
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 13",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 13",
        lat: 46.9797859,
        lon: 31.9724203,
        build_s: "3 579,10 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 80,
        apart_ind: 2,
        q_2021: "308,148961 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9724203,
          46.9797859
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 13/А",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 13/А",
        lat: 46.9792397,
        lon: 31.9717368,
        build_s: "1 835,40 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 3,
        q_2021: "163,959976 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9717368,
          46.9792397
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 15",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 15",
        lat: 46.9794371,
        lon: 31.9729988,
        build_s: "915,20 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 12,
        apart_ind: 1,
        q_2021: "90,797030 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9729988,
          46.97943710000001
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 17",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 17",
        lat: 46.97904851032196,
        lon: 31.97342169188368,
        build_s: "921,10 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 12,
        apart_ind: 6,
        q_2021: "65,810024 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97342169188368,
          46.97904851032196
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 17/А",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 17/А",
        lat: 46.97877429888866,
        lon: 31.97272193454215,
        build_s: "1 066,90 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 18,
        apart_ind: 2,
        q_2021: "104,824955 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97272193454215,
          46.97877429888865
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 19",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 19",
        lat: 46.97852701772951,
        lon: 31.97417168821383,
        build_s: "2 539,00 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 6,
        q_2021: "191,620110 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97417168821383,
          46.97852701772951
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 2",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 2",
        lat: 46.9820308,
        lon: 31.9711693,
        build_s: "1 163,80 м2",
        bld_floor: 8,
        bld_porch: 1,
        bld_apart: 28,
        apart_ind: 0,
        q_2021: "120,599950 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9711693,
          46.9820308
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 20",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 20",
        lat: 46.979255630289494,
        lon: 31.975687201589288,
        build_s: "3 166,90 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 80,
        apart_ind: 3,
        q_2021: "236,588889 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.975687201589288,
          46.979255630289494
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 21",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 21",
        lat: 46.978456015997246,
        lon: 31.974910919046422,
        build_s: "2 518,10 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 1,
        q_2021: "193,749772 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.974910919046422,
          46.978456015997246
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 22",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 22",
        lat: 46.97881592035745,
        lon: 31.975320007953773,
        build_s: "1 799,90 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 3,
        q_2021: "120,168327 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.975320007953773,
          46.97881592035745
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 24",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 24",
        lat: 46.97844622264746,
        lon: 31.975836751836752,
        build_s: "2 405,30 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 6,
        q_2021: "168,259977 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.975836751836752,
          46.97844622264746
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 3",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 3",
        lat: 46.9810512,
        lon: 31.9705234,
        build_s: "1 772,80 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 2,
        q_2021: "120,884538 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9705234,
          46.9810512
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 30",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 30",
        lat: 46.97740322062786,
        lon: 31.977365452490556,
        build_s: "469,70 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 10,
        apart_ind: 1,
        q_2021: "61,265700 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.977365452490556,
          46.97740322062786
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Нiкольськ,9-а",
        address: "вул. Велика Морська, 34",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 34",
        lat: 46.9768201,
        lon: 31.978424,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 3,
        q_2021: "240,588000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.978424,
          46.9768201
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Велика Морська, 4",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 4",
        lat: 46.9816581,
        lon: 31.9712829,
        build_s: "1 916,40 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 42,
        apart_ind: 1,
        q_2021: "147,235119 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9712829,
          46.9816581
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 4/6",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 4/6",
        lat: 46.9816581,
        lon: 31.9712829,
        build_s: "1 761,40 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 0,
        q_2021: "165,274086 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9712829,
          46.9816581
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 41",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 41",
        lat: 46.9743133,
        lon: 31.9826817,
        build_s: "355,10 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 6,
        apart_ind: 1,
        q_2021: "25,858788 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9826817,
          46.9743133
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.В.Морська,45-а",
        address: "вул. Велика Морська, 41/1",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 41/1",
        lat: 46.9743133,
        lon: 31.9826817,
        build_s: "77,10 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 4,
        apart_ind: 1,
        q_2021: "11,462737 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9826817,
          46.9743133
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.В.Морська,45-а",
        address: "вул. Велика Морська, 43",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 43",
        lat: 46.974049,
        lon: 31.983801,
        build_s: "1 967,40 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 13,
        q_2021: "67,740042 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.983801,
          46.974049
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.В.Морська,45-а",
        address: "вул. Велика Морська, 5",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 5",
        lat: 46.9805853,
        lon: 31.9706655,
        build_s: "2 529,90 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 4,
        q_2021: "200,716356 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9706655,
          46.9805853
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 5/А",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 5/А",
        lat: 46.9802211,
        lon: 31.9702098,
        build_s: "2 017,40 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 54,
        apart_ind: 2,
        q_2021: "178,369966 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9702098,
          46.9802211
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 58",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 58",
        lat: 46.9736374,
        lon: 31.986476,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 25,
        apart_ind: 5,
        q_2021: "99,711000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.986476,
          46.9736374
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Велика Морська, 6",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 6",
        lat: 46.9815563,
        lon: 31.9718806,
        build_s: "3 190,35 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 80,
        apart_ind: 0,
        q_2021: "253,641883 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9718806,
          46.9815563
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 6/А",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 6/А",
        lat: 46.9822846,
        lon: 31.972792,
        build_s: "3 050,20 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 63,
        apart_ind: 3,
        q_2021: "203,509931 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.972792,
          46.9822846
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 65",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 65",
        lat: 46.9716113,
        lon: 31.9916254,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 69,
        apart_ind: 16,
        q_2021: "309,517000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9916254,
          46.9716113
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Велика Морська, 67",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 67",
        lat: 46.9711975,
        lon: 31.9924124,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 38,
        apart_ind: 10,
        q_2021: "184,825000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9924124,
          46.9711975
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Велика Морська, 7",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 7",
        lat: 46.9804553,
        lon: 31.9710829,
        build_s: "1 844,90 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 1,
        q_2021: "156,899940 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9710829,
          46.9804553
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Велика Морська, 9",
        adrs_full: "м. Миколаїв, вул. Велика Морська, 9",
        lat: 46.9802365,
        lon: 31.971244,
        build_s: "2 056,20 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 41,
        apart_ind: 3,
        q_2021: "163,004667 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.971244,
          46.9802365
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Водопровідна, 19/1",
        adrs_full: "м. Миколаїв, вул. Водопровідна, 19/1",
        lat: 46.9673498,
        lon: 31.9668631,
        build_s: "?",
        bld_floor: 4,
        bld_porch: 1,
        bld_apart: null,
        apart_ind: null,
        q_2021: "237,351700 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9668631,
          46.9673498
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Водопровiдна, 15",
        adrs_full: "м. Миколаїв, вул. Водопровiдна, 15",
        lat: 46.96898188050666,
        lon: 31.96599504861208,
        build_s: "1 804,20 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 1,
        q_2021: "198,810082 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96599504861208,
          46.96898188050666
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Водопровiдна, 3",
        adrs_full: "м. Миколаїв, вул. Водопровiдна, 3",
        lat: 46.9707769,
        lon: 31.9650627,
        build_s: "2 548,80 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 50,
        apart_ind: 3,
        q_2021: "186,229992 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9650627,
          46.9707769
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Вокзальна, 49",
        adrs_full: "м. Миколаїв, вул. Вокзальна, 49",
        lat: 46.9128242,
        lon: 32.0777698,
        build_s: "5 236,20 м2",
        bld_floor: 10,
        bld_porch: 3,
        bld_apart: 120,
        apart_ind: 5,
        q_2021: "426,774200 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0777698,
          46.9128242
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Вокзальна, 51",
        adrs_full: "м. Миколаїв, вул. Вокзальна, 51",
        lat: 46.9128022,
        lon: 32.0778252,
        build_s: "3 165,50 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "236,976954 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0778252,
          46.91280219999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Вокзальна, 53",
        adrs_full: "м. Миколаїв, вул. Вокзальна, 53",
        lat: 46.9116408,
        lon: 32.0803026,
        build_s: "3 450,80 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 70,
        apart_ind: 3,
        q_2021: "236,719898 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0803026,
          46.9116408
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Вокзальна, 55",
        adrs_full: "м. Миколаїв, вул. Вокзальна, 55",
        lat: 46.9112546,
        lon: 32.081268,
        build_s: "3 453,70 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 70,
        apart_ind: 3,
        q_2021: "240,586188 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.081268,
          46.9112546
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Вокзальна, 57",
        adrs_full: "м. Миколаїв, вул. Вокзальна, 57",
        lat: 46.9112546,
        lon: 32.081268,
        build_s: "3 460,90 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 9,
        q_2021: "219,881944 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.081268,
          46.9112546
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Вокзальна, 59",
        adrs_full: "м. Миколаїв, вул. Вокзальна, 59",
        lat: 46.9107381,
        lon: 32.0826499,
        build_s: "7 313,70 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 11,
        q_2021: "540,417181 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0826499,
          46.9107381
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Вокзальна, 61",
        adrs_full: "м. Миколаїв, вул. Вокзальна, 61",
        lat: 46.910445,
        lon: 32.0835872,
        build_s: "3 448,40 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 5,
        q_2021: "262,563028 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0835872,
          46.910445
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Вiнграновського, 39",
        adrs_full: "м. Миколаїв, вул. Вiнграновського, 39",
        lat: 46.96456409687598,
        lon: 32.071402197640516,
        build_s: "4 712,80 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 90,
        apart_ind: 3,
        q_2021: "307,919853 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.071402197640516,
          46.96456409687598
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Вiнграновського, 41",
        adrs_full: "м. Миколаїв, вул. Вiнграновського, 41",
        lat: 46.96423,
        lon: 32.0717662,
        build_s: "4 732,40 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 90,
        apart_ind: 4,
        q_2021: "254,333640 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0717662,
          46.96423
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Вiнграновського, 43",
        adrs_full: "м. Миколаїв, вул. Вiнграновського, 43",
        lat: 46.96374890874702,
        lon: 32.07193620206362,
        build_s: "4 719,90 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 90,
        apart_ind: 1,
        q_2021: "282,981827 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07193620206362,
          46.96374890874702
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Вiнграновського, 43/А",
        adrs_full: "м. Миколаїв, вул. Вiнграновського, 43/А",
        lat: 46.96462657522104,
        lon: 32.072848645505665,
        build_s: "6 581,40 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 125,
        apart_ind: 0,
        q_2021: "421,975800 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.072848645505665,
          46.96462657522104
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Вiнграновського, 45",
        adrs_full: "м. Миколаїв, вул. Вiнграновського, 45",
        lat: 46.9608071,
        lon: 32.0778874,
        build_s: "6 266,70 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 119,
        apart_ind: 3,
        q_2021: "426,705037 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0778874,
          46.9608071
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Вiнграновського, 56",
        adrs_full: "м. Миколаїв, вул. Вiнграновського, 56",
        lat: 46.961330299739984,
        lon: 32.07145446921857,
        build_s: "9 380,90 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 143,
        apart_ind: 16,
        q_2021: "580,180999 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07145446921857,
          46.961330299739984
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Галини Петрової, 1",
        adrs_full: "м. Миколаїв, вул. Галини Петрової, 1",
        lat: 46.9712033,
        lon: 31.9634218,
        build_s: "2 945,90 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 63,
        apart_ind: 2,
        q_2021: "228,404251 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9634218,
          46.97120330000001
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Галини Петрової, 16",
        adrs_full: "м. Миколаїв, вул. Галини Петрової, 16",
        lat: 46.9733453,
        lon: 31.9690008,
        build_s: "4 050,30 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 7,
        q_2021: "227,973124 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9690008,
          46.9733453
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Галини Петрової, 17",
        adrs_full: "м. Миколаїв, вул. Галини Петрової, 17",
        lat: 46.9728062,
        lon: 31.9688199,
        build_s: "2 555,80 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 50,
        apart_ind: 13,
        q_2021: "199,267703 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9688199,
          46.9728062
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Галини Петрової, 18",
        adrs_full: "м. Миколаїв, вул. Галини Петрової, 18",
        lat: 46.9738323,
        lon: 31.968994,
        build_s: "5 961,00 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 108,
        apart_ind: 13,
        q_2021: "418,701759 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.968994,
          46.9738323
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Галини Петрової, 2",
        adrs_full: "м. Миколаїв, вул. Галини Петрової, 2",
        lat: 46.9722547,
        lon: 31.9654618,
        build_s: "3 892,00 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 35,
        apart_ind: 11,
        q_2021: "89,442021 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9654618,
          46.9722547
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Галини Петрової, 3",
        adrs_full: "м. Миколаїв, вул. Галини Петрової, 3",
        lat: 46.9713769,
        lon: 31.9642187,
        build_s: "6 772,40 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 9,
        q_2021: "533,569800 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9642187,
          46.9713769
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Галини Петрової, 3/А",
        adrs_full: "м. Миколаїв, вул. Галини Петрової, 3/А",
        lat: 46.9707502,
        lon: 31.9642682,
        build_s: "2 073,60 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 40,
        apart_ind: 2,
        q_2021: "228,218955 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9642682,
          46.9707502
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Галини Петрової, 5",
        adrs_full: "м. Миколаїв, вул. Галини Петрової, 5",
        lat: 46.971551,
        lon: 31.9654876,
        build_s: "1 698,80 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 36,
        apart_ind: 3,
        q_2021: "127,045441 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9654876,
          46.971551
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Галини Петрової, 6/А",
        adrs_full: "м. Миколаїв, вул. Галини Петрової, 6/А",
        lat: 46.9727556,
        lon: 31.9671032,
        build_s: "3 150,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 79,
        apart_ind: 3,
        q_2021: "295,227234 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9671032,
          46.9727556
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Генерала Карпенка, 1/А",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 1/А",
        lat: 46.952515445623376,
        lon: 31.95326400430256,
        build_s: "2 720,10 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 108,
        apart_ind: 1,
        q_2021: "294,245216 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95326400430256,
          46.952515445623376
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Генерала Карпенка, 12/А",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 12/А",
        lat: 46.9628751,
        lon: 31.9498186,
        build_s: "3 514,90 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 10,
        q_2021: "241,273907 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9498186,
          46.9628751
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Генерала Карпенка, 12/Б",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 12/Б",
        lat: 46.962446,
        lon: 31.9495719,
        build_s: "3 400,70 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 11,
        q_2021: "181,860471 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9495719,
          46.962446
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Генерала Карпенка, 12/В",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 12/В",
        lat: 46.96363068834523,
        lon: 31.950280138931287,
        build_s: "4 915,20 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 95,
        apart_ind: 11,
        q_2021: "318,900186 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.950280138931287,
          46.96363068834523
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Генерала Карпенка, 16",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 16",
        lat: 46.964364,
        lon: 31.9515353,
        build_s: "1 010,00 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 18,
        apart_ind: 6,
        q_2021: "76,430098 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9515353,
          46.964364
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Генерала Карпенка, 18",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 18",
        lat: 46.9645419,
        lon: 31.9520475,
        build_s: "2 903,20 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 64,
        apart_ind: 0,
        q_2021: "45,144400 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9520475,
          46.9645419
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Генерала Карпенка, 2",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 2",
        lat: 46.9578223,
        lon: 31.9482462,
        build_s: "3 502,70 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 80,
        apart_ind: 1,
        q_2021: "254,262243 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9482462,
          46.9578223
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "Кот.Генерала Карпенка,8-3",
        address: "вул. Генерала Карпенка, 2/1",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 2/1",
        lat: 46.95604550919443,
        lon: 31.948208901067122,
        build_s: "11 216,20 м2",
        bld_floor: 9,
        bld_porch: 6,
        bld_apart: 192,
        apart_ind: 9,
        q_2021: "837,825406 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.948208901067122,
          46.95604550919443
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Генерала Карпенка, 2/2",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 2/2",
        lat: 46.955514694826974,
        lon: 31.948841931492684,
        build_s: "4 068,90 м2",
        bld_floor: 16,
        bld_porch: 1,
        bld_apart: 84,
        apart_ind: 0,
        q_2021: "317,069983 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.948841931492684,
          46.955514694826974
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Генерала Карпенка, 2/А",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 2/А",
        lat: 46.95738034207147,
        lon: 31.9467381368494,
        build_s: "3 424,90 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 7,
        q_2021: "271,935338 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9467381368494,
          46.95738034207147
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Генерала Карпенка, 20",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 20",
        lat: 46.9649907,
        lon: 31.9530434,
        build_s: "2 585,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 64,
        apart_ind: 1,
        q_2021: "233,799920 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9530434,
          46.9649907
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Генерала Карпенка, 22",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 22",
        lat: 46.9652575,
        lon: 31.9538116,
        build_s: "2 461,50 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 3,
        q_2021: "207,074637 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9538116,
          46.9652575
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Генерала Карпенка, 24",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 24",
        lat: 46.9656625,
        lon: 31.9535936,
        build_s: "2 471,30 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 0,
        q_2021: "242,220008 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9535936,
          46.9656625
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Генерала Карпенка, 25",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 25",
        lat: 46.95794730496028,
        lon: 31.94891746377515,
        build_s: "2 015,30 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 2,
        q_2021: "120,638624 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.948917463775146,
          46.95794730496028
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "Кот.Генерала Карпенка,8-3",
        address: "вул. Генерала Карпенка, 26",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 26",
        lat: 46.9659786,
        lon: 31.9531195,
        build_s: "2 951,00 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 117,
        apart_ind: 0,
        q_2021: "430,249700 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9531195,
          46.9659786
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Генерала Карпенка, 27",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 27",
        lat: 46.95836129849594,
        lon: 31.949060781767233,
        build_s: "2 222,10 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 36,
        apart_ind: 2,
        q_2021: "99,915385 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.949060781767233,
          46.95836129849594
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "Кот.Генерала Карпенка,8-3",
        address: "вул. Генерала Карпенка, 28",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 28",
        lat: 46.9665333,
        lon: 31.9532334,
        build_s: "5 188,60 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 120,
        apart_ind: 5,
        q_2021: "420,340300 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9532334,
          46.96653329999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Генерала Карпенка, 29",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 29",
        lat: 46.958729436859386,
        lon: 31.949551957545452,
        build_s: "3 337,60 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 80,
        apart_ind: 3,
        q_2021: "236,817975 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.949551957545452,
          46.958729436859386
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 3",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 3",
        lat: 46.9529229,
        lon: 31.9520071,
        build_s: "4 021,50 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 2,
        q_2021: "306,814880 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9520071,
          46.9529229
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Генерала Карпенка, 30",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 30",
        lat: 46.9663696,
        lon: 31.9543691,
        build_s: "5 609,00 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 129,
        apart_ind: 6,
        q_2021: "348,259427 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9543691,
          46.9663696
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Генерала Карпенка, 31",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 31",
        lat: 46.9594106,
        lon: 31.9495075,
        build_s: "3 258,24 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 64,
        apart_ind: 2,
        q_2021: "199,831539 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9495075,
          46.9594106
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 32",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 32",
        lat: 46.9655849,
        lon: 31.9546558,
        build_s: "2 651,50 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "229,902508 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9546558,
          46.9655849
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Генерала Карпенка, 33",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 33",
        lat: 46.9598707,
        lon: 31.9502638,
        build_s: "673,30 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "71,836945 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9502638,
          46.9598707
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 33/А",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 33/А",
        lat: 46.95898515746964,
        lon: 31.950169105189904,
        build_s: "3 071,20 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 60,
        apart_ind: 1,
        q_2021: "172,678946 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.950169105189904,
          46.95898515746964
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 35",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 35",
        lat: 46.95994349223539,
        lon: 31.949714917733566,
        build_s: "700,90 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 1,
        q_2021: "55,084930 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.949714917733562,
          46.95994349223539
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 36",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 36",
        lat: 46.9670626,
        lon: 31.9550545,
        build_s: "5 869,10 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 213,
        apart_ind: 0,
        q_2021: "302,636700 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9550545,
          46.9670626
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Генерала Карпенка, 37",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 37",
        lat: 46.9603194,
        lon: 31.949702,
        build_s: "674,20 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 1,
        q_2021: "63,410027 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.949702,
          46.9603194
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 37/А",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 37/А",
        lat: 46.9607667,
        lon: 31.9497542,
        build_s: "1 650,10 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 37,
        apart_ind: 1,
        q_2021: "136,777008 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9497542,
          46.9607667
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 37/Б",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 37/Б",
        lat: 46.9605184,
        lon: 31.9503492,
        build_s: "1 839,80 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 60,
        apart_ind: 0,
        q_2021: "172,029962 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9503492,
          46.9605184
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 38",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 38",
        lat: 46.967222,
        lon: 31.9542033,
        build_s: "1 514,60 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 20,
        apart_ind: 2,
        q_2021: "108,837953 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9542033,
          46.967222
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Генерала Карпенка, 39",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 39",
        lat: 46.96153544257214,
        lon: 31.950081214573213,
        build_s: "683,70 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 15,
        apart_ind: 1,
        q_2021: "37,394667 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.950081214573213,
          46.96153544257214
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 39/А",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 39/А",
        lat: 46.961776196367126,
        lon: 31.95017935058987,
        build_s: "665,60 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 10,
        apart_ind: 3,
        q_2021: "32,023003 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95017935058987,
          46.961776196367126
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 4",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 4",
        lat: 46.9585145,
        lon: 31.9484596,
        build_s: "2 556,20 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 1,
        q_2021: "189,154013 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9484596,
          46.9585145
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "Кот.Генерала Карпенка,8-3",
        address: "вул. Генерала Карпенка, 40",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 40",
        lat: 46.9675446,
        lon: 31.956418,
        build_s: "4 175,40 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 13,
        q_2021: "312,523820 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.956418,
          46.9675446
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Генерала Карпенка, 41",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 41",
        lat: 46.96198889148766,
        lon: 31.95021913546148,
        build_s: "510,80 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "23,534489 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95021913546148,
          46.96198889148766
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 41/А",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 41/А",
        lat: 46.96182597615198,
        lon: 31.950603722553794,
        build_s: "386,20 м2",
        bld_floor: 2,
        bld_porch: 4,
        bld_apart: 8,
        apart_ind: 4,
        q_2021: "19,585995 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95060372255379,
          46.96182597615198
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 42",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 42",
        lat: 46.9666815,
        lon: 31.9557373,
        build_s: "4 162,80 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "351,059071 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9557373,
          46.9666815
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Генерала Карпенка, 43",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 43",
        lat: 46.9624263,
        lon: 31.9503804,
        build_s: "676,30 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "42,732951 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9503804,
          46.9624263
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 43/А",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 43/А",
        lat: 46.9621794,
        lon: 31.9503614,
        build_s: "677,80 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 10,
        apart_ind: 1,
        q_2021: "37,429996 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9503614,
          46.9621794
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 45",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 45",
        lat: 46.963022487225835,
        lon: 31.95090078292852,
        build_s: "3 368,30 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 64,
        apart_ind: 0,
        q_2021: "200,075044 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95090078292852,
          46.96302248722583
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 47",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 47",
        lat: 46.9627609,
        lon: 31.9512695,
        build_s: "3 336,70 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 3,
        q_2021: "214,799908 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9512695,
          46.9627609
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 49/1",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 49/1",
        lat: 46.962605,
        lon: 31.9529219,
        build_s: "4 831,40 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 90,
        apart_ind: 4,
        q_2021: "350,399704 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9529219,
          46.962605
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 5",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 5",
        lat: 46.953418988274706,
        lon: 31.95146581927626,
        build_s: "3 146,20 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 56,
        apart_ind: 6,
        q_2021: "268,588957 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95146581927626,
          46.953418988274706
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Генерала Карпенка, 51",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 51",
        lat: 46.963578,
        lon: 31.9524361,
        build_s: "3 397,50 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 4,
        q_2021: "224,829786 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9524361,
          46.963578
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 53",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 53",
        lat: 46.963873,
        lon: 31.9533845,
        build_s: "2 657,70 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 57,
        apart_ind: 2,
        q_2021: "196,090761 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9533845,
          46.963873
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 53/А",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 53/А",
        lat: 46.9640184,
        lon: 31.954001,
        build_s: "4 266,20 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "338,469900 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.954001,
          46.9640184
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 55",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 55",
        lat: 46.9635287,
        lon: 31.9536784,
        build_s: "2 661,80 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 56,
        apart_ind: 2,
        q_2021: "229,879955 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9536784,
          46.9635287
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 57",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 57",
        lat: 46.9628492,
        lon: 31.954169,
        build_s: "5 958,70 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 120,
        apart_ind: 4,
        q_2021: "322,140300 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.954169,
          46.9628492
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 57/1",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 57/1",
        lat: 46.96274553621068,
        lon: 31.95543095364333,
        build_s: "784,70 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "66,470987 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95543095364333,
          46.96274553621068
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 59",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 59",
        lat: 46.9640664,
        lon: 31.9549304,
        build_s: "4 153,00 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 87,
        apart_ind: 0,
        q_2021: "284,644752 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9549304,
          46.9640664
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 59/А",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 59/А",
        lat: 46.9642118,
        lon: 31.9555469,
        build_s: "4 274,80 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 7,
        q_2021: "302,209827 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9555469,
          46.9642118
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 6",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 6",
        lat: 46.9592066,
        lon: 31.948673,
        build_s: "3 586,30 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 80,
        apart_ind: 5,
        q_2021: "271,492468 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.948673,
          46.95920659999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "Кот.Генерала Карпенка,8-3",
        address: "вул. Генерала Карпенка, 61",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 61",
        lat: 46.9637827,
        lon: 31.9553002,
        build_s: "5 816,60 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 87,
        apart_ind: 1,
        q_2021: "279,483090 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9553002,
          46.9637827
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 63",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 63",
        lat: 46.9647008,
        lon: 31.9553977,
        build_s: "2 974,80 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 3,
        q_2021: "143,329966 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9553977,
          46.9647008
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 65",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 65",
        lat: 46.9635231,
        lon: 31.9561347,
        build_s: "4 777,90 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 90,
        apart_ind: 6,
        q_2021: "313,958961 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9561347,
          46.9635231
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 67",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 67",
        lat: 46.96335193035715,
        lon: 31.95669080791122,
        build_s: "7 723,60 м2",
        bld_floor: 5,
        bld_porch: 9,
        bld_apart: 150,
        apart_ind: 7,
        q_2021: "448,720074 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "9",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95669080791122,
          46.96335193035715
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Генерала Карпенка, 7",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 7",
        lat: 46.955103860136425,
        lon: 31.950016335565525,
        build_s: "1 910,30 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 48,
        apart_ind: 1,
        q_2021: "173,669962 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.950016335565525,
          46.955103860136425
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Генерала Карпенка, 7/1",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 7/1",
        lat: 46.95556238190802,
        lon: 31.950285757841723,
        build_s: "1 543,90 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 32,
        apart_ind: 3,
        q_2021: "112,979985 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.950285757841723,
          46.95556238190802
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Генерала Карпенка, 75",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 75",
        lat: 46.9669637,
        lon: 31.9590972,
        build_s: "6 694,30 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 107,
        apart_ind: 22,
        q_2021: "381,087617 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9590972,
          46.9669637
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Генерала Карпенка, 77",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 77",
        lat: 46.967023,
        lon: 31.9582294,
        build_s: "6 690,20 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 107,
        apart_ind: 5,
        q_2021: "447,210222 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9582294,
          46.967023
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Генерала Карпенка, 8",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 8",
        lat: 46.959862033898126,
        lon: 31.948952906713135,
        build_s: "2 452,70 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "198,373989 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.948952906713135,
          46.959862033898126
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "Кот.Генерала Карпенка,8-3",
        address: "вул. Генерала Карпенка, 9",
        adrs_full: "м. Миколаїв, вул. Генерала Карпенка, 9",
        lat: 46.9556537,
        lon: 31.9496634,
        build_s: "1 466,20 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 32,
        apart_ind: 0,
        q_2021: "126,501973 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9496634,
          46.9556537
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Генерала Попеля, 162",
        adrs_full: "м. Миколаїв, вул. Генерала Попеля, 162",
        lat: 46.8548512,
        lon: 32.0188551,
        build_s: "4 392,10 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 77,
        apart_ind: 2,
        q_2021: "225,978603 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0188551,
          46.8548512
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Генерала Попеля, 170",
        adrs_full: "м. Миколаїв, вул. Генерала Попеля, 170",
        lat: 46.8554439,
        lon: 32.0189107,
        build_s: "4 963,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 67,
        apart_ind: 0,
        q_2021: "269,891904 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0189107,
          46.8554439
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Генерала Свиридова, 37",
        adrs_full: "м. Миколаїв, вул. Генерала Свиридова, 37",
        lat: 46.9490072711746,
        lon: 32.04919923362245,
        build_s: "6 414,80 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 130,
        apart_ind: 6,
        q_2021: "437,037549 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04919923362245,
          46.9490072711746
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Генерала Свиридова, 40/1",
        adrs_full: "м. Миколаїв, вул. Генерала Свиридова, 40/1",
        lat: 46.949119422994535,
        lon: 32.05054048103674,
        build_s: "7 430,00 м2",
        bld_floor: 10,
        bld_porch: 3,
        bld_apart: 115,
        apart_ind: 11,
        q_2021: "459,981749 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05054048103674,
          46.949119422994535
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Георгія Гонгадзе, 26/2",
        adrs_full: "м. Миколаїв, вул. Георгія Гонгадзе, 26/2",
        lat: 46.966768,
        lon: 31.965186,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 104,
        apart_ind: 0,
        q_2021: "93,652000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.965186,
          46.966768
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Георгія Гонгадзе, 26/3",
        adrs_full: "м. Миколаїв, вул. Георгія Гонгадзе, 26/3",
        lat: 46.96741432496032,
        lon: 31.965868295593346,
        build_s: "?",
        bld_floor: 4,
        bld_porch: 1,
        bld_apart: null,
        apart_ind: null,
        q_2021: "240,625800 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.965868295593342,
          46.967414324960316
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Георгія Гонгадзе, 26/3-6",
        adrs_full: "м. Миколаїв, вул. Георгія Гонгадзе, 26/3-6",
        lat: 46.96756372501321,
        lon: 31.96573466360167,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 73,
        apart_ind: 0,
        q_2021: "299,099800 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96573466360167,
          46.96756372501321
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Георгія Гонгадзе, 28",
        adrs_full: "м. Миколаїв, вул. Георгія Гонгадзе, 28",
        lat: 46.9651264,
        lon: 31.9662193,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 110,
        apart_ind: 7,
        q_2021: "528,497000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9662193,
          46.9651264
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Георгія Гонгадзе, 30",
        adrs_full: "м. Миколаїв, вул. Георгія Гонгадзе, 30",
        lat: 46.9651271,
        lon: 31.9662207,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 145,
        apart_ind: 13,
        q_2021: "763,385000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9662207,
          46.9651271
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Георгiя Гонгадзе, 5",
        adrs_full: "м. Миколаїв, вул. Георгiя Гонгадзе, 5",
        lat: 46.9666035,
        lon: 31.9645887,
        build_s: "3 044,70 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 80,
        apart_ind: 0,
        q_2021: "143,790700 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9645887,
          46.9666035
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пров.Транспортний,6/2",
        address: "вул. Георгiя Гонгадзе, 7",
        adrs_full: "м. Миколаїв, вул. Георгiя Гонгадзе, 7",
        lat: 46.96574268638097,
        lon: 31.96506327124296,
        build_s: "2 683,90 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 62,
        apart_ind: 3,
        q_2021: "190,750093 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96506327124296,
          46.96574268638097
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пров.Транспортний,6/2",
        address: "вул. Глинки, 2",
        adrs_full: "м. Миколаїв, вул. Глинки, 2",
        lat: 46.8650845,
        lon: 32.0117466,
        build_s: "3 352,40 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 68,
        apart_ind: 3,
        q_2021: "211,295769 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0117466,
          46.86508449999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Глинки, 2/А",
        adrs_full: "м. Миколаїв, вул. Глинки, 2/А",
        lat: 46.864601818911275,
        lon: 32.0120759,
        build_s: "2 282,47 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 12,
        q_2021: "120,419942 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01207590000001,
          46.86460181891127
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Глинки, 3",
        adrs_full: "м. Миколаїв, вул. Глинки, 3",
        lat: 46.8659392,
        lon: 32.0108277,
        build_s: "8 177,58 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 18,
        q_2021: "562,222990 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0108277,
          46.8659392
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Глинки, 4",
        adrs_full: "м. Миколаїв, вул. Глинки, 4",
        lat: 46.8658493,
        lon: 32.0116557,
        build_s: "3 750,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 7,
        q_2021: "223,002115 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01165570000001,
          46.8658493
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Глинки, 5",
        adrs_full: "м. Миколаїв, вул. Глинки, 5",
        lat: 46.86674506674148,
        lon: 32.00965899090908,
        build_s: "5 861,42 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 160,
        apart_ind: 4,
        q_2021: "462,330170 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00965899090908,
          46.86674506674148
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Глинки, 6",
        adrs_full: "м. Миколаїв, вул. Глинки, 6",
        lat: 46.86666936273401,
        lon: 32.01173226363637,
        build_s: "4 024,59 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 0,
        q_2021: "347,192942 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01173226363637,
          46.86666936273401
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Глинки, 6/А",
        adrs_full: "м. Миколаїв, вул. Глинки, 6/А",
        lat: 46.86636654563649,
        lon: 32.01206062727274,
        build_s: "4 043,14 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 73,
        apart_ind: 2,
        q_2021: "330,176002 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01206062727274,
          46.86636654563649
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Глинки, 7",
        adrs_full: "м. Миколаїв, вул. Глинки, 7",
        lat: 46.86605850580183,
        lon: 32.009823172727266,
        build_s: "3 955,87 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 108,
        apart_ind: 1,
        q_2021: "258,000187 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.009823172727266,
          46.86605850580183
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Глинки, 8",
        adrs_full: "м. Миколаїв, вул. Глинки, 8",
        lat: 46.86675811914577,
        lon: 32.0129540818182,
        build_s: "3 921,98 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "230,124952 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0129540818182,
          46.866758119145764
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Глинки, 9",
        adrs_full: "м. Миколаїв, вул. Глинки, 9",
        lat: 46.867179106411754,
        lon: 32.01022925714611,
        build_s: "3 608,20 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 4,
        q_2021: "166,591000 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01022925714611,
          46.867179106411754
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Громадянська, 42",
        adrs_full: "м. Миколаїв, вул. Громадянська, 42",
        lat: 46.9564705,
        lon: 31.9974595,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 32,
        apart_ind: 3,
        q_2021: "105,015000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974595,
          46.95647049999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Громадянська, 42/Б",
        adrs_full: "м. Миколаїв, вул. Громадянська, 42/Б",
        lat: 46.9565682,
        lon: 31.9996481,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 79,
        apart_ind: 4,
        q_2021: "246,984000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9996481,
          46.9565682
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Громадянська, 44",
        adrs_full: "м. Миколаїв, вул. Громадянська, 44",
        lat: 46.9557579,
        lon: 31.9975819,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 32,
        apart_ind: 1,
        q_2021: "108,477000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9975819,
          46.95575789999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Громадянська, 44-А",
        adrs_full: "м. Миколаїв, вул. Громадянська, 44-А",
        lat: 46.9562799,
        lon: 31.9978007,
        build_s: "?",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: null,
        apart_ind: null,
        q_2021: "113,920100 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9978007,
          46.9562799
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Громадянська, 46",
        adrs_full: "м. Миколаїв, вул. Громадянська, 46",
        lat: 46.9551964,
        lon: 31.9974223,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: null,
        apart_ind: null,
        q_2021: "184,539300 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974223,
          46.95519640000001
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Даля, 1",
        adrs_full: "м. Миколаїв, вул. Даля, 1",
        lat: 46.9628329,
        lon: 31.9916533,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 10,
        q_2021: "276,016000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9916533,
          46.9628329
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Даля, 28",
        adrs_full: "м. Миколаїв, вул. Даля, 28",
        lat: 46.9580609,
        lon: 31.9904623,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 50,
        apart_ind: 5,
        q_2021: "173,108000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9904623,
          46.9580609
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Дачна, 1",
        adrs_full: "м. Миколаїв, вул. Дачна, 1",
        lat: 46.95602315144899,
        lon: 31.94958257751563,
        build_s: "992,00 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 0,
        q_2021: "82,598086 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94958257751563,
          46.956023151448996
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Дачна, 11",
        adrs_full: "м. Миколаїв, вул. Дачна, 11",
        lat: 46.9574163,
        lon: 31.9537504,
        build_s: "1 880,40 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 2,
        q_2021: "144,300046 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9537504,
          46.9574163
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Дачна, 11/А",
        adrs_full: "м. Миколаїв, вул. Дачна, 11/А",
        lat: 46.95690479412261,
        lon: 31.953603501873744,
        build_s: "1 148,10 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 1,
        q_2021: "107,202977 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.953603501873744,
          46.95690479412261
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Дачна, 13",
        adrs_full: "м. Миколаїв, вул. Дачна, 13",
        lat: 46.9573267,
        lon: 31.9545801,
        build_s: "3 176,40 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "338,158055 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9545801,
          46.9573267
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Дачна, 13/А",
        adrs_full: "м. Миколаїв, вул. Дачна, 13/А",
        lat: 46.95707523068844,
        lon: 31.954197769292087,
        build_s: "1 874,30 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 2,
        q_2021: "137,409120 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.954197769292087,
          46.95707523068844
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Дачна, 28",
        adrs_full: "м. Миколаїв, вул. Дачна, 28",
        lat: 46.95791676441891,
        lon: 31.954490704510803,
        build_s: "735,00 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 18,
        apart_ind: 1,
        q_2021: "80,151996 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.954490704510803,
          46.95791676441891
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Дачна, 3",
        adrs_full: "м. Миколаїв, вул. Дачна, 3",
        lat: 46.956251257517245,
        lon: 31.950269529632234,
        build_s: "963,20 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 0,
        q_2021: "91,061861 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95026952963223,
          46.95625125751725
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Дачна, 30",
        adrs_full: "м. Миколаїв, вул. Дачна, 30",
        lat: 46.9580633,
        lon: 31.9549217,
        build_s: "473,60 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "61,472503 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9549217,
          46.9580633
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Дачна, 32",
        adrs_full: "м. Миколаїв, вул. Дачна, 32",
        lat: 46.958229946339166,
        lon: 31.955424322831426,
        build_s: "642,30 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "64,879971 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.955424322831426,
          46.958229946339166
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Дачна, 34",
        adrs_full: "м. Миколаїв, вул. Дачна, 34",
        lat: 46.95860022589296,
        lon: 31.955226055947243,
        build_s: "1 398,10 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 49,
        apart_ind: 0,
        q_2021: "89,909997 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.955226055947243,
          46.95860022589296
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Дачна, 36",
        adrs_full: "м. Миколаїв, вул. Дачна, 36",
        lat: 46.95868481923563,
        lon: 31.95575625036205,
        build_s: "406,80 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 8,
        apart_ind: 2,
        q_2021: "44,665733 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.955756250362054,
          46.95868481923563
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Дачна, 38",
        adrs_full: "м. Миколаїв, вул. Дачна, 38",
        lat: 46.95849526730038,
        lon: 31.956112008519177,
        build_s: "493,10 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "56,930951 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.956112008519177,
          46.95849526730038
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Дачна, 40",
        adrs_full: "м. Миколаїв, вул. Дачна, 40",
        lat: 46.95928480151311,
        lon: 31.956658269431408,
        build_s: "485,30 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 2,
        q_2021: "44,994337 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.956658269431408,
          46.95928480151311
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот Абрикосова,5",
        address: "вул. Дачна, 42",
        adrs_full: "м. Миколаїв, вул. Дачна, 42",
        lat: 46.959062354560565,
        lon: 31.95707140793646,
        build_s: "970,70 м2",
        bld_floor: 2,
        bld_porch: 4,
        bld_apart: 24,
        apart_ind: 2,
        q_2021: "92,065200 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95707140793646,
          46.959062354560565
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот Абрикосова,5",
        address: "вул. Дачна, 44",
        adrs_full: "м. Миколаїв, вул. Дачна, 44",
        lat: 46.9594321,
        lon: 31.9574302,
        build_s: "480,70 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 2,
        q_2021: "40,806162 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9574302,
          46.9594321
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот Абрикосова,5",
        address: "вул. Дачна, 5",
        adrs_full: "м. Миколаїв, вул. Дачна, 5",
        lat: 46.95655177672596,
        lon: 31.951232323525364,
        build_s: "2 556,50 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 64,
        apart_ind: 4,
        q_2021: "229,283962 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.951232323525364,
          46.95655177672596
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Дачна, 7",
        adrs_full: "м. Миколаїв, вул. Дачна, 7",
        lat: 46.95682513911862,
        lon: 31.952120852324818,
        build_s: "941,40 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 0,
        q_2021: "88,800057 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.952120852324818,
          46.95682513911863
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Дачна, 9",
        adrs_full: "м. Миколаїв, вул. Дачна, 9",
        lat: 46.95708401744803,
        lon: 31.952892678834186,
        build_s: "2 564,30 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 64,
        apart_ind: 1,
        q_2021: "214,985653 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.952892678834182,
          46.95708401744803
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Дачна, 9/А",
        adrs_full: "м. Миколаїв, вул. Дачна, 9/А",
        lat: 46.95668031153486,
        lon: 31.95279454281753,
        build_s: "665,10 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "54,697045 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95279454281753,
          46.95668031153487
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Декабристів, 2",
        adrs_full: "м. Миколаїв, вул. Декабристів, 2",
        lat: 46.9348401,
        lon: 31.8989888,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 2,
        q_2021: "99,167000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.8989888,
          46.9348401
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Декабристів, 21",
        adrs_full: "м. Миколаїв, вул. Декабристів, 21",
        lat: 46.9331835,
        lon: 31.9027979,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 37,
        apart_ind: 8,
        q_2021: "170,156000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9027979,
          46.9331835
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Декабристів, 23/А",
        adrs_full: "м. Миколаїв, вул. Декабристів, 23/А",
        lat: 46.9328901,
        lon: 31.9033359,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 29,
        q_2021: "287,318000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9033359,
          46.93289009999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Декабристів, 25",
        adrs_full: "м. Миколаїв, вул. Декабристів, 25",
        lat: 46.9660408,
        lon: 31.9834542,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 96,
        apart_ind: 4,
        q_2021: "352,854000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9834542,
          46.96604079999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Декабристів, 38/1",
        adrs_full: "м. Миколаїв, вул. Декабристів, 38/1",
        lat: 46.9653741,
        lon: 31.9851206,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 97,
        apart_ind: 22,
        q_2021: "445,461000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9851206,
          46.9653741
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Декабристів, 38/2",
        adrs_full: "м. Миколаїв, вул. Декабристів, 38/2",
        lat: 46.9661193,
        lon: 31.9848937,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 26,
        apart_ind: 15,
        q_2021: "76,422000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9848937,
          46.9661193
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Декабристів, 4",
        adrs_full: "м. Миколаїв, вул. Декабристів, 4",
        lat: 46.9745836,
        lon: 31.9908152,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 40,
        apart_ind: 9,
        q_2021: "177,122000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9908152,
          46.9745836
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Декабристів, 67",
        adrs_full: "м. Миколаїв, вул. Декабристів, 67",
        lat: 46.95768342979246,
        lon: 31.978349642088826,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 73,
        apart_ind: 3,
        q_2021: "301,750000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.978349642088826,
          46.95768342979246
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Декабристів, 69",
        adrs_full: "м. Миколаїв, вул. Декабристів, 69",
        lat: 46.95786732050562,
        lon: 31.97760603660653,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 74,
        apart_ind: 6,
        q_2021: "335,778000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97760603660653,
          46.95786732050563
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Дмитра Яворницького, 2",
        adrs_full: "м. Миколаїв, вул. Дмитра Яворницького, 2",
        lat: 46.9318429,
        lon: 32.0578748,
        build_s: "1 701,90 м2",
        bld_floor: 3,
        bld_porch: 3,
        bld_apart: 27,
        apart_ind: 2,
        q_2021: "99,800035 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0578748,
          46.9318429
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Дмитра Яворницького,2-В",
        address: "вул. Дмитра Яворницького, 2/А",
        adrs_full: "м. Миколаїв, вул. Дмитра Яворницького, 2/А",
        lat: 46.93283484332952,
        lon: 32.05961969922772,
        build_s: "1 258,80 м2",
        bld_floor: 3,
        bld_porch: 3,
        bld_apart: 24,
        apart_ind: 4,
        q_2021: "97,999995 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05961969922772,
          46.93283484332952
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Дмитра Яворницького,2-В",
        address: "вул. Дмитра Яворницького, 2/Б",
        adrs_full: "м. Миколаїв, вул. Дмитра Яворницького, 2/Б",
        lat: 46.93284333999976,
        lon: 32.058882459862325,
        build_s: "3 470,00 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 113,
        apart_ind: 2,
        q_2021: "290,022120 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.058882459862325,
          46.932843339999756
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Дмитра Яворницького,2-В",
        address: "вул. Дмитра Яворницького, 4",
        adrs_full: "м. Миколаїв, вул. Дмитра Яворницького, 4",
        lat: 46.9336131,
        lon: 32.0606413,
        build_s: "1 732,30 м2",
        bld_floor: 3,
        bld_porch: 3,
        bld_apart: 27,
        apart_ind: 3,
        q_2021: "96,809944 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0606413,
          46.9336131
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Дмитра Яворницького,2-В",
        address: "вул. Дмитра Яворницького, 6",
        adrs_full: "м. Миколаїв, вул. Дмитра Яворницького, 6",
        lat: 46.9341813,
        lon: 32.0613185,
        build_s: "928,20 м2",
        bld_floor: 3,
        bld_porch: 1,
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "77,289976 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0613185,
          46.9341813
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Дмитра Яворницького,2-В",
        address: "вул. Дмитрієва, 12",
        adrs_full: "м. Миколаїв, вул. Дмитрієва, 12",
        lat: 46.955256,
        lon: 31.9859356,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 32,
        apart_ind: 1,
        q_2021: "116,920000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9859356,
          46.955256
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Дунаєва, 36",
        adrs_full: "м. Миколаїв, вул. Дунаєва, 36",
        lat: 46.9629689,
        lon: 31.9903374,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 0,
        q_2021: "71,690000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9903374,
          46.9629689
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Дунаєва, 39",
        adrs_full: "м. Миколаїв, вул. Дунаєва, 39",
        lat: 46.96413,
        lon: 31.9832262,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 107,
        apart_ind: 18,
        q_2021: "380,116000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9832262,
          46.96413
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Дунаєва, 45",
        adrs_full: "м. Миколаїв, вул. Дунаєва, 45",
        lat: 46.9630886,
        lon: 31.9870438,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 35,
        apart_ind: 0,
        q_2021: "156,187000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9870438,
          46.9630886
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Дунаєва, 45",
        adrs_full: "м. Миколаїв, вул. Дунаєва, 45",
        lat: 46.9630886,
        lon: 31.9870438,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 35,
        apart_ind: 0,
        q_2021: "156,187000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9870438,
          46.9630886
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Електронна, 56",
        adrs_full: "м. Миколаїв, вул. Електронна, 56",
        lat: 46.97043758726418,
        lon: 32.07154599541984,
        build_s: "6 731,20 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 162,
        apart_ind: 1,
        q_2021: "450,730626 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07154599541984,
          46.97043758726418
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Електронна, 56/А",
        adrs_full: "м. Миколаїв, вул. Електронна, 56/А",
        lat: 46.96626423024385,
        lon: 32.07350548754329,
        build_s: "7 426,80 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 143,
        apart_ind: 8,
        q_2021: "476,300529 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07350548754329,
          46.966264230243844
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Електронна, 61",
        adrs_full: "м. Миколаїв, вул. Електронна, 61",
        lat: 46.970649114042025,
        lon: 32.071407317270975,
        build_s: "9 508,60 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 141,
        apart_ind: 8,
        q_2021: "540,463800 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.071407317270975,
          46.970649114042025
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Електронна, 68",
        adrs_full: "м. Миколаїв, вул. Електронна, 68",
        lat: 46.96675329969825,
        lon: 32.07416826634277,
        build_s: "8 604,80 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 4,
        q_2021: "571,800562 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07416826634277,
          46.96675329969825
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Електронна, 70",
        adrs_full: "м. Миколаїв, вул. Електронна, 70",
        lat: 46.966316937192055,
        lon: 32.075285470714725,
        build_s: "8 608,60 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 0,
        q_2021: "526,100300 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.075285470714725,
          46.966316937192055
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "Заводська Площа, 3",
        adrs_full: "м. Миколаїв, Заводська Площа, 3",
        lat: 46.94908141275295,
        lon: 31.992723358639516,
        build_s: "3 015,40 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 0,
        q_2021: "67,779100 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.992723358639516,
          46.94908141275295
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Заводська, 1/1",
        adrs_full: "м. Миколаїв, вул. Заводська, 1/1",
        lat: 46.94917705137402,
        lon: 31.991236147674925,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "77,937000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.991236147674925,
          46.94917705137402
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 1/2",
        adrs_full: "м. Миколаїв, вул. Заводська, 1/2",
        lat: 46.94876997298207,
        lon: 31.99130799361524,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "74,291000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99130799361524,
          46.94876997298207
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 13/1",
        adrs_full: "м. Миколаїв, вул. Заводська, 13/1",
        lat: 46.949177051374015,
        lon: 31.99779298780324,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 4,
        apart_ind: 1,
        q_2021: "11,783000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99779298780324,
          46.949177051374015
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 13/2",
        adrs_full: "м. Миколаїв, вул. Заводська, 13/2",
        lat: 46.949358519057064,
        lon: 31.999326898629068,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 0,
        q_2021: "116,067000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.999326898629068,
          46.949358519057064
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 13/3",
        adrs_full: "м. Миколаїв, вул. Заводська, 13/3",
        lat: 46.94938181553938,
        lon: 32.00003817343821,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 0,
        q_2021: "81,317000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00003817343821,
          46.94938181553938
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 13/5",
        adrs_full: "м. Миколаїв, вул. Заводська, 13/5",
        lat: 46.949722678700006,
        lon: 31.998752131106514,
        build_s: "?",
        bld_floor: 1,
        bld_porch: "?",
        bld_apart: 9,
        apart_ind: 4,
        q_2021: "17,804000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99875213110651,
          46.949722678700006
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 13/6",
        adrs_full: "м. Миколаїв, вул. Заводська, 13/6",
        lat: 46.94977049745899,
        lon: 31.99936102545071,
        build_s: "?",
        bld_floor: 1,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "38,674000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.999361025450714,
          46.94977049745899
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 15/1",
        adrs_full: "м. Миколаїв, вул. Заводська, 15/1",
        lat: 46.94981218557298,
        lon: 32.00007409640838,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 25,
        apart_ind: 3,
        q_2021: "96,970000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00007409640838,
          46.94981218557299
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 15/2",
        adrs_full: "м. Миколаїв, вул. Заводська, 15/2",
        lat: 46.94988575275378,
        lon: 32.000648863930934,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 10,
        apart_ind: 1,
        q_2021: "65,819000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.000648863930934,
          46.94988575275377
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 19",
        adrs_full: "м. Миколаїв, вул. Заводська, 19",
        lat: 46.949513011329394,
        lon: 32.00209296733132,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 96,
        apart_ind: 3,
        q_2021: "413,013000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00209296733132,
          46.949513011329394
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 2",
        adrs_full: "м. Миколаїв, вул. Заводська, 2",
        lat: 46.94920157406984,
        lon: 31.990711672310592,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 28,
        apart_ind: 0,
        q_2021: "109,940000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.990711672310592,
          46.94920157406984
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 2/А",
        adrs_full: "м. Миколаїв, вул. Заводська, 2/А",
        lat: 46.94886315987515,
        lon: 31.99047458070754,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 31,
        apart_ind: 0,
        q_2021: "103,853000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99047458070754,
          46.94886315987515
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 2/Б",
        adrs_full: "м. Миколаїв, вул. Заводська, 2/Б",
        lat: 46.949380589408975,
        lon: 31.990209648802615,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 9,
        apart_ind: 0,
        q_2021: "52,155000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.990209648802615,
          46.949380589408975
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 2/В",
        adrs_full: "м. Миколаїв, вул. Заводська, 2/В",
        lat: 46.949821994536265,
        lon: 31.990292271633987,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 19,
        apart_ind: 8,
        q_2021: "71,442000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.990292271633987,
          46.949821994536265
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 2/Г",
        adrs_full: "м. Миколаїв, вул. Заводська, 2/Г",
        lat: 46.94975333175556,
        lon: 31.99079878551323,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 18,
        apart_ind: 1,
        q_2021: "119,921000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99079878551323,
          46.94975333175556
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 21/1",
        adrs_full: "м. Миколаїв, вул. Заводська, 21/1",
        lat: 46.94971654808679,
        lon: 32.00169422236255,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 1,
        q_2021: "117,903000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00169422236255,
          46.94971654808679
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 21/2",
        adrs_full: "м. Миколаїв, вул. Заводська, 21/2",
        lat: 46.94974352277969,
        lon: 32.002592296616534,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 25,
        apart_ind: 4,
        q_2021: "105,972000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.002592296616534,
          46.94974352277969
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 21/3",
        adrs_full: "м. Миколаїв, вул. Заводська, 21/3",
        lat: 46.94970919135001,
        lon: 32.003246094673436,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 27,
        apart_ind: 2,
        q_2021: "132,836000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.003246094673436,
          46.94970919135001
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 27/1",
        adrs_full: "м. Миколаїв, вул. Заводська, 27/1",
        lat: 46.94961845817984,
        lon: 32.00656178481915,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 10,
        apart_ind: 2,
        q_2021: "40,920000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00656178481915,
          46.94961845817984
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 27/2",
        adrs_full: "м. Миколаїв, вул. Заводська, 27/2",
        lat: 46.9495816744184,
        lon: 32.00699286046106,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "46,391000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00699286046106,
          46.9495816744184
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 27/3",
        adrs_full: "м. Миколаїв, вул. Заводська, 27/3",
        lat: 46.949490941032074,
        lon: 32.00748859744926,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 1,
        q_2021: "40,871000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00748859744926,
          46.949490941032074
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 27/4",
        adrs_full: "м. Миколаїв, вул. Заводська, 27/4",
        lat: 46.9491334,
        lon: 32.0063919,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 1,
        q_2021: "46,011000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0063919,
          46.9491334
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 27/5",
        adrs_full: "м. Миколаїв, вул. Заводська, 27/5",
        lat: 46.94908386502741,
        lon: 32.00703596802525,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "65,090000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00703596802525,
          46.949083865027404
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 27/6",
        adrs_full: "м. Миколаїв, вул. Заводська, 27/6",
        lat: 46.949002939910585,
        lon: 32.00764665851796,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "43,962000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00764665851796,
          46.949002939910585
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 27/7",
        adrs_full: "м. Миколаїв, вул. Заводська, 27/7",
        lat: 46.9489814,
        lon: 32.0082314,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "64,933000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0082314,
          46.9489814
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 3/1",
        adrs_full: "м. Миколаїв, вул. Заводська, 3/1",
        lat: 46.94921873995023,
        lon: 31.99184683816763,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "66,857000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99184683816763,
          46.94921873995023
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 3/2",
        adrs_full: "м. Миколаїв, вул. Заводська, 3/2",
        lat: 46.94877978213635,
        lon: 31.99236412893792,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 50,
        apart_ind: 5,
        q_2021: "305,505000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.992364128937922,
          46.94877978213635
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 35/1",
        adrs_full: "м. Миколаїв, вул. Заводська, 35/1",
        lat: 46.9492168,
        lon: 32.0109913,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "46,698000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0109913,
          46.94921679999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 35/3",
        adrs_full: "м. Миколаїв, вул. Заводська, 35/3",
        lat: 46.9491425,
        lon: 32.011297,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 1,
        q_2021: "39,255000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.011297,
          46.9491425
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 35/5",
        adrs_full: "м. Миколаїв, вул. Заводська, 35/5",
        lat: 46.9486018,
        lon: 32.0108006,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 28,
        apart_ind: 0,
        q_2021: "143,448000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0108006,
          46.9486018
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 5",
        adrs_full: "м. Миколаїв, вул. Заводська, 5",
        lat: 46.9495074,
        lon: 31.9981616,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "53,158000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9981616,
          46.94950739999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Заводська, 7",
        adrs_full: "м. Миколаїв, вул. Заводська, 7",
        lat: 46.94905443772637,
        lon: 31.993431041151656,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 9,
        apart_ind: 0,
        q_2021: "44,623000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.993431041151656,
          46.94905443772637
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Защука, 25",
        adrs_full: "м. Миколаїв, вул. Защука, 25",
        lat: 46.96139582398053,
        lon: 31.982014592220764,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 153,
        apart_ind: 1,
        q_2021: "508,156000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.982014592220764,
          46.96139582398053
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Защука, 27",
        adrs_full: "м. Миколаїв, вул. Защука, 27",
        lat: 46.961557174369695,
        lon: 31.98279227087624,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 39,
        apart_ind: 0,
        q_2021: "165,419000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98279227087624,
          46.961557174369695
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Знаменська, 39",
        adrs_full: "м. Миколаїв, вул. Знаменська, 39",
        lat: 46.9146265,
        lon: 32.0813339,
        build_s: "8 597,30 м2",
        bld_floor: 10,
        bld_porch: 4,
        bld_apart: 159,
        apart_ind: 11,
        q_2021: "578,784200 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0813339,
          46.9146265
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Знаменська, 41",
        adrs_full: "м. Миколаїв, вул. Знаменська, 41",
        lat: 46.9132186,
        lon: 32.081995,
        build_s: "3 069,70 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 68,
        apart_ind: 0,
        q_2021: "232,277648 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.081995,
          46.9132186
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Знаменська, 43",
        adrs_full: "м. Миколаїв, вул. Знаменська, 43",
        lat: 46.912623,
        lon: 32.0825521,
        build_s: "3 394,50 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 7,
        q_2021: "221,620153 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0825521,
          46.912623
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Знаменська, 45",
        adrs_full: "м. Миколаїв, вул. Знаменська, 45",
        lat: 46.9123391,
        lon: 32.0829208,
        build_s: "3 366,10 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 7,
        q_2021: "223,037282 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0829208,
          46.9123391
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Знаменська, 47",
        adrs_full: "м. Миколаїв, вул. Знаменська, 47",
        lat: 46.9122647,
        lon: 32.0836977,
        build_s: "2 862,80 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "170,921965 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0836977,
          46.91226469999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Знаменська, 49",
        adrs_full: "м. Миколаїв, вул. Знаменська, 49",
        lat: 46.9119808,
        lon: 32.0840664,
        build_s: "2 867,70 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 3,
        q_2021: "174,300085 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0840664,
          46.91198079999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Знаменська, 51",
        adrs_full: "м. Миколаїв, вул. Знаменська, 51",
        lat: 46.9117574,
        lon: 32.0845111,
        build_s: "3 191,20 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 3,
        q_2021: "233,428223 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0845111,
          46.9117574
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Казарського, 1/1",
        adrs_full: "м. Миколаїв, вул. Казарського, 1/1",
        lat: 46.9836881,
        lon: 32.0518717,
        build_s: "857,70 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 18,
        apart_ind: 2,
        q_2021: "73,539998 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0518717,
          46.98368809999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Казарського,2-К",
        address: "вул. Казарського, 1/2",
        adrs_full: "м. Миколаїв, вул. Казарського, 1/2",
        lat: 46.9831433,
        lon: 32.0504576,
        build_s: "1 195,10 м2",
        bld_floor: 3,
        bld_porch: 3,
        bld_apart: 27,
        apart_ind: 1,
        q_2021: "116,059909 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0504576,
          46.98314329999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Казарського,2-К",
        address: "вул. Казарського, 1/3",
        adrs_full: "м. Миколаїв, вул. Казарського, 1/3",
        lat: 46.9871173,
        lon: 32.051379,
        build_s: "413,40 м2",
        bld_floor: 3,
        bld_porch: 1,
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "41,170031 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.051379,
          46.9871173
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Казарського,2-К",
        address: "вул. Казарського, 1/4",
        adrs_full: "м. Миколаїв, вул. Казарського, 1/4",
        lat: 46.9836881,
        lon: 32.0518717,
        build_s: "429,70 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "35,779992 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0518717,
          46.98368809999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Казарського,2-К",
        address: "вул. Казарського, 1/5",
        adrs_full: "м. Миколаїв, вул. Казарського, 1/5",
        lat: 46.9836881,
        lon: 32.0518717,
        build_s: "683,00 м2",
        bld_floor: 3,
        bld_porch: 1,
        bld_apart: 38,
        apart_ind: 0,
        q_2021: "54,203864 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0518717,
          46.98368809999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Казарського,2-К",
        address: "вул. Казарського, 1/6",
        adrs_full: "м. Миколаїв, вул. Казарського, 1/6",
        lat: 46.9826958,
        lon: 32.0504042,
        build_s: "1 548,50 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 48,
        apart_ind: 0,
        q_2021: "96,943216 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0504042,
          46.98269579999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Казарського,2-К",
        address: "вул. Казарського, 1/А",
        adrs_full: "м. Миколаїв, вул. Казарського, 1/А",
        lat: 46.9844675,
        lon: 32.0517567,
        build_s: "817,00 м2",
        bld_floor: 3,
        bld_porch: 1,
        bld_apart: 24,
        apart_ind: 0,
        q_2021: "49,767160 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0517567,
          46.9844675
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Казарського,2-К",
        address: "вул. Казарського, 1/Б",
        adrs_full: "м. Миколаїв, вул. Казарського, 1/Б",
        lat: 46.9849983,
        lon: 32.0518355,
        build_s: "4 127,80 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "328,194010 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0518355,
          46.9849983
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Казарського,2-К",
        address: "вул. Казарського, 1/Г",
        adrs_full: "м. Миколаїв, вул. Казарського, 1/Г",
        lat: 46.9836961,
        lon: 32.0516598,
        build_s: "4 171,70 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 70,
        apart_ind: 4,
        q_2021: "310,301922 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0516598,
          46.9836961
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Казарського,2-К",
        address: "вул. Казарського, 3/А",
        adrs_full: "м. Миколаїв, вул. Казарського, 3/А",
        lat: 46.9821599,
        lon: 32.0505666,
        build_s: "8 891,50 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 198,
        apart_ind: 2,
        q_2021: "635,084000 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0505666,
          46.9821599
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Казарського,2-К",
        address: "вул. Казарського, 5",
        adrs_full: "м. Миколаїв, вул. Казарського, 5",
        lat: 46.9860363,
        lon: 32.0512115,
        build_s: "5 288,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 10,
        apart_ind: 10,
        q_2021: "263,108091 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0512115,
          46.98603629999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Казарського,2-К",
        address: "вул. Казарського, 5/А",
        adrs_full: "м. Миколаїв, вул. Казарського, 5/А",
        lat: 46.985548,
        lon: 32.0511787,
        build_s: "4 377,20 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 108,
        apart_ind: 2,
        q_2021: "272,089228 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0511787,
          46.985548
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Казарського,2-К",
        address: "вул. Казарського, 5/Б",
        adrs_full: "м. Миколаїв, вул. Казарського, 5/Б",
        lat: 46.9866093,
        lon: 32.051412,
        build_s: "10 416,40 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 143,
        apart_ind: 20,
        q_2021: "395,586200 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.051412,
          46.9866093
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Казарського,2-К",
        address: "вул. Казарського, 8",
        adrs_full: "м. Миколаїв, вул. Казарського, 8",
        lat: 46.9838984,
        lon: 32.0506066,
        build_s: "7 840,90 м2",
        bld_floor: 10,
        bld_porch: 3,
        bld_apart: 119,
        apart_ind: 4,
        q_2021: "482,944897 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0506066,
          46.9838984
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Казарського,2-К",
        address: "вул. Квiтнева, 4/А",
        adrs_full: "м. Миколаїв, вул. Квiтнева, 4/А",
        lat: 46.966803,
        lon: 32.0578625,
        build_s: "6 301,00 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 104,
        apart_ind: 12,
        q_2021: "419,746531 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0578625,
          46.966803
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Листопадова,1-к",
        address: "вул. Квiтнева, 52",
        adrs_full: "м. Миколаїв, вул. Квiтнева, 52",
        lat: 46.9624072,
        lon: 32.0664635,
        build_s: "4 455,70 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 81,
        apart_ind: 9,
        q_2021: "258,542615 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0664635,
          46.9624072
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Листопадова,1-к",
        address: "вул. Київська, 2",
        adrs_full: "м. Миколаїв, вул. Київська, 2",
        lat: 46.955751595338,
        lon: 31.945378642748086,
        build_s: "4 854,30 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 126,
        apart_ind: 1,
        q_2021: "261,009226 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.945378642748082,
          46.955751595338
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Київська, 4",
        adrs_full: "м. Миколаїв, вул. Київська, 4",
        lat: 47.0011206,
        lon: 31.9989877,
        build_s: "3 981,70 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "289,958809 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9989877,
          47.0011206
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Київська, 6",
        adrs_full: "м. Миколаїв, вул. Київська, 6",
        lat: 46.9553026,
        lon: 31.945712,
        build_s: "3 931,60 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 7,
        q_2021: "242,999900 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.945712,
          46.9553026
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Київська, 8",
        adrs_full: "м. Миколаїв, вул. Київська, 8",
        lat: 46.954561965704734,
        lon: 31.945784609680754,
        build_s: "11 608,40 м2",
        bld_floor: 9,
        bld_porch: 6,
        bld_apart: 216,
        apart_ind: 9,
        q_2021: "844,548887 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94578460968075,
          46.954561965704734
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Київська, 8/А",
        adrs_full: "м. Миколаїв, вул. Київська, 8/А",
        lat: 46.95459629402158,
        lon: 31.9470562828244,
        build_s: "6 298,40 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 108,
        apart_ind: 14,
        q_2021: "353,786089 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9470562828244,
          46.95459629402158
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Київська, 8/Б",
        adrs_full: "м. Миколаїв, вул. Київська, 8/Б",
        lat: 46.95518232403372,
        lon: 31.947767557633554,
        build_s: "6 052,00 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 105,
        apart_ind: 3,
        q_2021: "396,822731 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.947767557633558,
          46.95518232403372
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Китобоїв, 12",
        adrs_full: "м. Миколаїв, вул. Китобоїв, 12",
        lat: 46.9454162,
        lon: 32.0561249,
        build_s: "4 474,90 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 79,
        apart_ind: 4,
        q_2021: "215,522124 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0561249,
          46.9454162
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "вул. Китобоїв, 14",
        adrs_full: "м. Миколаїв, вул. Китобоїв, 14",
        lat: 46.94488050164621,
        lon: 32.057453856643896,
        build_s: "3 068,10 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 3,
        q_2021: "227,009872 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.057453856643896,
          46.94488050164621
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Китобоїв, 14/А",
        adrs_full: "м. Миколаїв, вул. Китобоїв, 14/А",
        lat: 46.94541268433732,
        lon: 32.057795124860405,
        build_s: "3 376,60 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 2,
        q_2021: "222,549940 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.057795124860405,
          46.94541268433732
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Китобоїв, 2",
        adrs_full: "м. Миколаїв, вул. Китобоїв, 2",
        lat: 46.94325129523329,
        lon: 32.05944271818182,
        build_s: "5 756,90 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 90,
        apart_ind: 4,
        q_2021: "262,598670 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05944271818182,
          46.94325129523329
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Китобоїв, 2/А",
        adrs_full: "м. Миколаїв, вул. Китобоїв, 2/А",
        lat: 46.9471472,
        lon: 32.052203,
        build_s: "5 768,86 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 119,
        apart_ind: 1,
        q_2021: "348,426913 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.052203,
          46.9471472
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Китобоїв, 4",
        adrs_full: "м. Миколаїв, вул. Китобоїв, 4",
        lat: 46.94671494048822,
        lon: 32.05296319982195,
        build_s: "6 999,30 м2",
        bld_floor: 10,
        bld_porch: 3,
        bld_apart: 119,
        apart_ind: 10,
        q_2021: "376,514798 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05296319982195,
          46.94671494048823
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Китобоїв, 6",
        adrs_full: "м. Миколаїв, вул. Китобоїв, 6",
        lat: 46.94624831968567,
        lon: 32.054131784880916,
        build_s: "4 008,90 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 4,
        q_2021: "310,540160 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.054131784880916,
          46.94624831968567
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Китобоїв, 7",
        adrs_full: "м. Миколаїв, вул. Китобоїв, 7",
        lat: 46.94180452486185,
        lon: 32.06247626363639,
        build_s: "7 387,20 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 129,
        apart_ind: 7,
        q_2021: "480,629884 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06247626363639,
          46.94180452486185
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Колодязна, 10",
        adrs_full: "м. Миколаїв, вул. Колодязна, 10",
        lat: 46.9684194,
        lon: 32.0162026,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 215,
        apart_ind: 9,
        q_2021: "921,337000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0162026,
          46.96841939999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Колодязна, 11",
        adrs_full: "м. Миколаїв, вул. Колодязна, 11",
        lat: 46.96634226600614,
        lon: 32.017579392493104,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "324,908000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.017579392493104,
          46.96634226600614
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 13",
        adrs_full: "м. Миколаїв, вул. Колодязна, 13",
        lat: 46.96651633846727,
        lon: 32.018702360471615,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 108,
        apart_ind: 0,
        q_2021: "387,557000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.018702360471615,
          46.96651633846727
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 13/А",
        adrs_full: "м. Миколаїв, вул. Колодязна, 13/А",
        lat: 46.96622550976968,
        lon: 32.01907253551162,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 1,
        q_2021: "371,911000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01907253551162,
          46.96622550976968
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 14",
        adrs_full: "м. Миколаїв, вул. Колодязна, 14",
        lat: 46.9682253,
        lon: 32.0182445,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 1,
        q_2021: "274,998000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0182445,
          46.9682253
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 15",
        adrs_full: "м. Миколаїв, вул. Колодязна, 15",
        lat: 46.96641656529663,
        lon: 32.01961068914122,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 108,
        apart_ind: 3,
        q_2021: "349,387000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01961068914122,
          46.96641656529663
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 15/А",
        adrs_full: "м. Миколаїв, вул. Колодязна, 15/А",
        lat: 46.966117244668176,
        lon: 32.02000886061282,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 1,
        q_2021: "357,254000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02000886061282,
          46.966117244668176
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 16",
        adrs_full: "м. Миколаїв, вул. Колодязна, 16",
        lat: 46.9687285,
        lon: 32.0181861,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 1,
        q_2021: "261,538000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0181861,
          46.9687285
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 17",
        adrs_full: "м. Миколаїв, вул. Колодязна, 17",
        lat: 46.96635749983575,
        lon: 32.02063564191534,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 108,
        apart_ind: 2,
        q_2021: "309,897000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02063564191534,
          46.96635749983575
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 17/А",
        adrs_full: "м. Миколаїв, вул. Колодязна, 17/А",
        lat: 46.96606169801987,
        lon: 32.021015268285915,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 1,
        q_2021: "311,058000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.021015268285915,
          46.96606169801987
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 18",
        adrs_full: "м. Миколаїв, вул. Колодязна, 18",
        lat: 46.9690136,
        lon: 32.0192326,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 107,
        apart_ind: 12,
        q_2021: "479,479000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0192326,
          46.9690136
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 20",
        adrs_full: "м. Миколаїв, вул. Колодязна, 20",
        lat: 46.9684593,
        lon: 32.0195898,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 143,
        apart_ind: 12,
        q_2021: "561,450000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0195898,
          46.9684593
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 3",
        adrs_full: "м. Миколаїв, вул. Колодязна, 3",
        lat: 46.966668456677745,
        lon: 32.015087505651614,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 145,
        apart_ind: 5,
        q_2021: "634,914000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.015087505651614,
          46.966668456677745
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 3/А",
        adrs_full: "м. Миколаїв, вул. Колодязна, 3/А",
        lat: 46.96627061286783,
        lon: 32.01503701094544,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 107,
        apart_ind: 1,
        q_2021: "445,964000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01503701094544,
          46.96627061286783
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 35",
        adrs_full: "м. Миколаїв, вул. Колодязна, 35",
        lat: 46.9677835,
        lon: 32.0193229,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 71,
        apart_ind: 3,
        q_2021: "276,831000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0193229,
          46.9677835
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 35/А",
        adrs_full: "м. Миколаїв, вул. Колодязна, 35/А",
        lat: 46.9671983,
        lon: 32.018698,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 107,
        apart_ind: 8,
        q_2021: "409,850000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.018698,
          46.9671983
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 37",
        adrs_full: "м. Миколаїв, вул. Колодязна, 37",
        lat: 46.9673994,
        lon: 32.0196756,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 142,
        apart_ind: 13,
        q_2021: "601,346000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0196756,
          46.9673994
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 39",
        adrs_full: "м. Миколаїв, вул. Колодязна, 39",
        lat: 46.9680216,
        lon: 32.0199116,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 71,
        apart_ind: 3,
        q_2021: "300,031000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0199116,
          46.9680216
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 4",
        adrs_full: "м. Миколаїв, вул. Колодязна, 4",
        lat: 46.96749510612742,
        lon: 32.01428053741458,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 143,
        apart_ind: 5,
        q_2021: "588,857000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01428053741458,
          46.96749510612742
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 5",
        adrs_full: "м. Миколаїв, вул. Колодязна, 5",
        lat: 46.96677809815251,
        lon: 32.01594132522871,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "323,536000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01594132522871,
          46.96677809815251
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 5/А",
        adrs_full: "м. Миколаїв, вул. Колодязна, 5/А",
        lat: 46.966549417107835,
        lon: 32.016813506517146,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 144,
        apart_ind: 4,
        q_2021: "665,479000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.016813506517146,
          46.966549417107835
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 5/Б",
        adrs_full: "м. Миколаїв, вул. Колодязна, 5/Б",
        lat: 46.966308204535515,
        lon: 32.01612494234207,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 107,
        apart_ind: 0,
        q_2021: "335,485000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01612494234207,
          46.966308204535515
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 6",
        adrs_full: "м. Миколаїв, вул. Колодязна, 6",
        lat: 46.96761101103131,
        lon: 32.01517567084218,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 0,
        q_2021: "287,020000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01517567084218,
          46.96761101103131
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 7",
        adrs_full: "м. Миколаїв, вул. Колодязна, 7",
        lat: 46.96707882790115,
        lon: 32.01558327185767,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 0,
        q_2021: "325,639000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01558327185767,
          46.96707882790115
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Колодязна, 8",
        adrs_full: "м. Миколаїв, вул. Колодязна, 8",
        lat: 46.96773004823874,
        lon: 32.01572652218225,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 0,
        q_2021: "307,144000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01572652218225,
          46.96773004823874
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Корабелів, 12",
        adrs_full: "м. Миколаїв, вул. Корабелів, 12",
        lat: 46.9591868,
        lon: 31.985022,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 44,
        apart_ind: 8,
        q_2021: "200,091000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.985022,
          46.9591868
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Корабелів, 2",
        adrs_full: "м. Миколаїв, вул. Корабелів, 2",
        lat: 46.9626223,
        lon: 31.9867137,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 40,
        apart_ind: 1,
        q_2021: "176,202000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9867137,
          46.9626223
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Корабелів, 20",
        adrs_full: "м. Миколаїв, вул. Корабелів, 20",
        lat: 46.9574734,
        lon: 31.9859212,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 10,
        apart_ind: 4,
        q_2021: "24,854000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9859212,
          46.9574734
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Корабелів, 22/1",
        adrs_full: "м. Миколаїв, вул. Корабелів, 22/1",
        lat: 46.956672,
        lon: 31.9857877,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 1,
        q_2021: "45,933000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9857877,
          46.956672
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Корабелів, 22/2",
        adrs_full: "м. Миколаїв, вул. Корабелів, 22/2",
        lat: 46.956672,
        lon: 31.9857877,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 1,
        q_2021: "44,229000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9857877,
          46.956672
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Корабелів, 22/3",
        adrs_full: "м. Миколаїв, вул. Корабелів, 22/3",
        lat: 46.956672,
        lon: 31.9857877,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 3,
        q_2021: "29,328000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9857877,
          46.956672
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Корабелів, 25",
        adrs_full: "м. Миколаїв, вул. Корабелів, 25",
        lat: 46.9570532,
        lon: 31.9851052,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 17,
        apart_ind: 1,
        q_2021: "88,900000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9851052,
          46.9570532
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Коротка, 20",
        adrs_full: "м. Миколаїв, вул. Коротка, 20",
        lat: 46.8730104,
        lon: 32.0491915,
        build_s: "1 210,30 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 0,
        q_2021: "115,500058 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0491915,
          46.87301040000001
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Коротка, 24/А",
        adrs_full: "м. Миколаїв, вул. Коротка, 24/А",
        lat: 46.8723685,
        lon: 32.0488206,
        build_s: "3 564,40 м2",
        bld_floor: 5,
        bld_porch: 5,
        bld_apart: 69,
        apart_ind: 1,
        q_2021: "295,749900 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "5",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0488206,
          46.8723685
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "вул. Коротка, 24/Б",
        adrs_full: "м. Миколаїв, вул. Коротка, 24/Б",
        lat: 46.8730844,
        lon: 32.0484154,
        build_s: "2 852,55 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 65,
        apart_ind: 0,
        q_2021: "230,519866 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0484154,
          46.8730844
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "вул. Космонавтів, 100",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 100",
        lat: 46.9503134,
        lon: 32.0628539,
        build_s: "4 901,00 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 145,
        apart_ind: 1,
        q_2021: "304,596067 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0628539,
          46.9503134
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "вул. Космонавтів, 102",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 102",
        lat: 46.9524797,
        lon: 32.0645582,
        build_s: "4 586,10 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 90,
        apart_ind: 7,
        q_2021: "254,452183 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0645582,
          46.9524797
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Космонавтів, 104",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 104",
        lat: 46.9538449,
        lon: 32.0661275,
        build_s: "6 187,80 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 160,
        apart_ind: 6,
        q_2021: "411,579800 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0661275,
          46.9538449
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Космонавтів, 104/А",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 104/А",
        lat: 46.9529643,
        lon: 32.0651667,
        build_s: "4 239,90 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 68,
        apart_ind: 2,
        q_2021: "275,983964 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0651667,
          46.9529643
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Космонавтів, 106",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 106",
        lat: 46.9546741,
        lon: 32.066443,
        build_s: "1 821,30 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 39,
        apart_ind: 3,
        q_2021: "132,777454 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.066443,
          46.9546741
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Космонавтів, 108",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 108",
        lat: 46.9549118,
        lon: 32.0670319,
        build_s: "2 744,70 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 66,
        apart_ind: 0,
        q_2021: "199,700017 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0670319,
          46.9549118
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 108/А",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 108/А",
        lat: 46.955297567674215,
        lon: 32.06669324456888,
        build_s: "1 482,00 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 30,
        apart_ind: 1,
        q_2021: "107,999837 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06669324456888,
          46.955297567674215
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 110",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 110",
        lat: 46.95545694676819,
        lon: 32.06725005060636,
        build_s: "1 884,00 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 75,
        apart_ind: 0,
        q_2021: "157,260018 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06725005060636,
          46.95545694676818
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 110/А",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 110/А",
        lat: 46.9557646696758,
        lon: 32.067102766428704,
        build_s: "1 943,00 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 54,
        apart_ind: 0,
        q_2021: "180,089801 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.067102766428704,
          46.9557646696758
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 112",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 112",
        lat: 46.95587010420924,
        lon: 32.067607484159446,
        build_s: "1 907,00 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 65,
        apart_ind: 0,
        q_2021: "175,480012 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.067607484159446,
          46.95587010420924
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 116",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 116",
        lat: 46.9563752063019,
        lon: 32.06820200931558,
        build_s: "2 674,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 64,
        apart_ind: 2,
        q_2021: "211,653968 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06820200931558,
          46.9563752063019
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 118",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 118",
        lat: 46.95694160152143,
        lon: 32.068545073680596,
        build_s: "1 733,00 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 62,
        apart_ind: 2,
        q_2021: "147,579982 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.068545073680596,
          46.956941601521436
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 118/А",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 118/А",
        lat: 46.95686314020373,
        lon: 32.06807987121703,
        build_s: "2 839,60 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 1,
        q_2021: "144,860079 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06807987121703,
          46.95686314020373
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 120",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 120",
        lat: 46.9573299,
        lon: 32.0689437,
        build_s: "1 747,00 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 68,
        apart_ind: 1,
        q_2021: "144,231485 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0689437,
          46.9573299
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 122",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 122",
        lat: 46.95774950121093,
        lon: 32.06948805164728,
        build_s: "3 399,10 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 8,
        q_2021: "209,782915 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06948805164728,
          46.95774950121093
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 122/А",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 122/А",
        lat: 46.9577864,
        lon: 32.0689,
        build_s: "3 350,70 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 2,
        q_2021: "239,939777 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0689,
          46.9577864
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 122/Б",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 122/Б",
        lat: 46.95823987443613,
        lon: 32.06948805164728,
        build_s: "4 352,70 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 4,
        q_2021: "206,200300 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06948805164728,
          46.958239874436124
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 124",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 124",
        lat: 46.9585739,
        lon: 32.0698891,
        build_s: "3 930,90 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "282,230334 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0698891,
          46.9585739
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 124/А",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 124/А",
        lat: 46.95923041464335,
        lon: 32.06901746073818,
        build_s: "5 178,20 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 78,
        apart_ind: 4,
        q_2021: "240,570347 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06901746073818,
          46.95923041464335
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 126/1",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 126/1",
        lat: 46.95961779914217,
        lon: 32.070008934714586,
        build_s: "8 456,70 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 5,
        q_2021: "525,291700 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.070008934714586,
          46.95961779914218
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 126/2",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 126/2",
        lat: 46.95963250989067,
        lon: 32.06919707558898,
        build_s: "5 026,50 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 108,
        apart_ind: 2,
        q_2021: "343,200200 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06919707558898,
          46.95963250989067
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 126/3",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 126/3",
        lat: 46.959149504868634,
        lon: 32.07038253360424,
        build_s: "3 839,50 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 0,
        q_2021: "304,716036 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07038253360424,
          46.959149504868634
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 128",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 128",
        lat: 46.96215782769065,
        lon: 32.07273894791085,
        build_s: "6 575,50 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 132,
        apart_ind: 0,
        q_2021: "282,789200 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07273894791085,
          46.96215782769065
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 130",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 130",
        lat: 46.9630637180057,
        lon: 32.073823405829245,
        build_s: "5 659,10 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 112,
        apart_ind: 6,
        q_2021: "461,687174 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.073823405829245,
          46.9630637180057
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 130/А",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 130/А",
        lat: 46.96397935888004,
        lon: 32.07354555112676,
        build_s: "4 048,20 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 2,
        q_2021: "274,667986 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07354555112676,
          46.96397935888004
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 132",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 132",
        lat: 46.96428580698723,
        lon: 32.074346633361316,
        build_s: "12 238,20 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 144,
        apart_ind: 8,
        q_2021: "392,669981 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.074346633361316,
          46.96428580698722
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 132/А",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 132/А",
        lat: 46.96476141097317,
        lon: 32.07390118853134,
        build_s: "3 602,00 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 101,
        apart_ind: 4,
        q_2021: "248,728637 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07390118853134,
          46.96476141097317
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 132/Б",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 132/Б",
        lat: 46.9652173984532,
        lon: 32.07355992031482,
        build_s: "902,00 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 65,
        apart_ind: 4,
        q_2021: "146,350747 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07355992031482,
          46.9652173984532
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 132/В",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 132/В",
        lat: 46.96508627768995,
        lon: 32.072972031436464,
        build_s: "2 948,10 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 75,
        apart_ind: 1,
        q_2021: "131,107087 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.072972031436464,
          46.96508627768995
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 134",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 134",
        lat: 46.964912217984995,
        lon: 32.075378870437156,
        build_s: "8 621,20 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 146,
        apart_ind: 6,
        q_2021: "415,192234 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.075378870437156,
          46.96491221798499
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 134/А",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 134/А",
        lat: 46.9652368,
        lon: 32.0744832,
        build_s: "4 718,40 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 90,
        apart_ind: 2,
        q_2021: "314,760243 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0744832,
          46.9652368
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 136",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 136",
        lat: 46.96564645191011,
        lon: 32.07593388032606,
        build_s: "8 587,00 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 5,
        q_2021: "501,222638 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07593388032606,
          46.96564645191011
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 138/Б",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 138/Б",
        lat: 46.9673266,
        lon: 32.0756927,
        build_s: "5 915,70 м2",
        bld_floor: 12,
        bld_porch: 1,
        bld_apart: 96,
        apart_ind: 1,
        q_2021: "450,251953 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0756927,
          46.9673266
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 138/В",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 138/В",
        lat: 46.96770936240852,
        lon: 32.075206440180374,
        build_s: "5 917,00 м2",
        bld_floor: 12,
        bld_porch: 1,
        bld_apart: 96,
        apart_ind: 1,
        q_2021: "456,080302 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.075206440180374,
          46.96770936240852
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 138/Г",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 138/Г",
        lat: 46.96717494997422,
        lon: 32.07504837911167,
        build_s: "5 906,20 м2",
        bld_floor: 12,
        bld_porch: 1,
        bld_apart: 96,
        apart_ind: 1,
        q_2021: "520,729973 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07504837911167,
          46.96717494997422
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 140",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 140",
        lat: 46.967677493926836,
        lon: 32.07708880381673,
        build_s: "4 117,60 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 68,
        apart_ind: 2,
        q_2021: "268,272900 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07708880381673,
          46.967677493926836
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 140/А",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 140/А",
        lat: 46.967806193447885,
        lon: 32.07649427866055,
        build_s: "4 381,90 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 5,
        q_2021: "303,610012 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07649427866055,
          46.96780619344789
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 140/Б",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 140/Б",
        lat: 46.96792998983803,
        lon: 32.07587819972235,
        build_s: "4 323,80 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "293,999806 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07587819972235,
          46.96792998983803
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 140/В",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 140/В",
        lat: 46.968773268515285,
        lon: 32.07588538431638,
        build_s: "12 865,10 м2",
        bld_floor: 9,
        bld_porch: 6,
        bld_apart: 216,
        apart_ind: 6,
        q_2021: "710,880642 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07588538431638,
          46.968773268515285
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 140/Г",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 140/Г",
        lat: 46.969106654092755,
        lon: 32.07680501235246,
        build_s: "4 672,20 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 108,
        apart_ind: 5,
        q_2021: "303,485405 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07680501235246,
          46.969106654092755
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 142",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 142",
        lat: 46.96805991445409,
        lon: 32.077915032130385,
        build_s: "11 015,50 м2",
        bld_floor: 9,
        bld_porch: 5,
        bld_apart: 160,
        apart_ind: 3,
        q_2021: "719,505735 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.077915032130385,
          46.96805991445409
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 142/А",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 142/А",
        lat: 46.96823641531375,
        lon: 32.07665054358077,
        build_s: "4 653,20 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 108,
        apart_ind: 4,
        q_2021: "303,356398 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07665054358077,
          46.96823641531375
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 142/Б",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 142/Б",
        lat: 46.968601672187454,
        lon: 32.076941519639064,
        build_s: "4 665,50 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 108,
        apart_ind: 0,
        q_2021: "315,508481 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.076941519639064,
          46.968601672187454
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 142/В",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 142/В",
        lat: 46.968660505276155,
        lon: 32.07763124066613,
        build_s: "4 382,20 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "294,269920 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07763124066613,
          46.968660505276155
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 144",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 144",
        lat: 46.96928070014951,
        lon: 32.07902864420532,
        build_s: "10 362,20 м2",
        bld_floor: 9,
        bld_porch: 5,
        bld_apart: 160,
        apart_ind: 8,
        q_2021: "495,728338 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07902864420532,
          46.96928070014951
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 146",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 146",
        lat: 46.970160727173386,
        lon: 32.079429185322596,
        build_s: "3 950,90 м2",
        bld_floor: 14,
        bld_porch: 1,
        bld_apart: 83,
        apart_ind: 0,
        q_2021: "370,424633 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.079429185322596,
          46.970160727173386
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 146/А",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 146/А",
        lat: 46.96994256026384,
        lon: 32.07851674188054,
        build_s: "4 379,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "317,210063 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07851674188054,
          46.96994256026384
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 146/Б",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 146/Б",
        lat: 46.97007738261638,
        lon: 32.07795454739756,
        build_s: "4 320,20 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 5,
        q_2021: "259,900218 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07795454739756,
          46.97007738261638
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 146/В",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 146/В",
        lat: 46.97070001234459,
        lon: 32.07792401287291,
        build_s: "8 619,90 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 8,
        q_2021: "555,132294 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07792401287291,
          46.97070001234459
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 146/Г",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 146/Г",
        lat: 46.970470816811165,
        lon: 32.07849518809842,
        build_s: "2 402,60 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 45,
        apart_ind: 1,
        q_2021: "178,501939 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07849518809842,
          46.970470816811165
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 148",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 148",
        lat: 46.97058235058918,
        lon: 32.07941661228303,
        build_s: "4 371,40 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 5,
        q_2021: "251,739689 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07941661228303,
          46.97058235058918
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 148/Б",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 148/Б",
        lat: 46.970904694156445,
        lon: 32.07876640652315,
        build_s: "4 737,80 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 108,
        apart_ind: 2,
        q_2021: "308,633878 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07876640652315,
          46.970904694156445
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 148/Г",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 148/Г",
        lat: 46.97146725869401,
        lon: 32.077868332269155,
        build_s: "4 717,40 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 108,
        apart_ind: 1,
        q_2021: "343,250198 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.077868332269155,
          46.97146725869402
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 150",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 150",
        lat: 46.97099729804563,
        lon: 32.079628868225186,
        build_s: "4 390,30 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "360,155107 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.079628868225186,
          46.97099729804563
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 152",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 152",
        lat: 46.971792681122864,
        lon: 32.07846772986642,
        build_s: "8 978,30 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 143,
        apart_ind: 4,
        q_2021: "517,639008 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07846772986642,
          46.971792681122864
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 154",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 154",
        lat: 46.97155060108522,
        lon: 32.07967166537116,
        build_s: "4 811,30 м2",
        bld_floor: 14,
        bld_porch: 1,
        bld_apart: 84,
        apart_ind: 0,
        q_2021: "388,792706 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07967166537116,
          46.97155060108521
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 49",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 49",
        lat: 46.94048245096405,
        lon: 32.05321469174188,
        build_s: "4 656,10 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "258,093522 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05321469174188,
          46.94048245096405
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 49/1",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 49/1",
        lat: 46.94021003840402,
        lon: 32.05376464764056,
        build_s: "4 685,40 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 10,
        q_2021: "233,282646 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05376464764056,
          46.94021003840402
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 51",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 51",
        lat: 46.940808853377334,
        lon: 32.05385450971551,
        build_s: "4 480,80 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "315,115376 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05385450971551,
          46.940808853377334
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 51/А",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 51/А",
        lat: 46.94041373441296,
        lon: 32.05446916630815,
        build_s: "9 200,90 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 8,
        q_2021: "486,289569 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05446916630815,
          46.94041373441296
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 53",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 53",
        lat: 46.940838303121275,
        lon: 32.055418109819605,
        build_s: "9 446,00 м2",
        bld_floor: 12,
        bld_porch: 4,
        bld_apart: 192,
        apart_ind: 1,
        q_2021: "868,478512 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.055418109819605,
          46.940838303121275
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Космонавтів, 53/1",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 53/1",
        lat: 46.9408294,
        lon: 32.0570417,
        build_s: "15 857,00 м2",
        bld_floor: 9,
        bld_porch: 5,
        bld_apart: 160,
        apart_ind: 11,
        q_2021: "383,936277 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0570417,
          46.9408294
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Космонавтів, 54",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 54",
        lat: 46.93740440176118,
        lon: 32.03734487320076,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 59,
        apart_ind: 2,
        q_2021: "220,617000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03734487320076,
          46.93740440176118
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Космонавтів, 55",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 55",
        lat: 46.9420403,
        lon: 32.0576188,
        build_s: "7 441,80 м2",
        bld_floor: 15,
        bld_porch: 1,
        bld_apart: 78,
        apart_ind: 2,
        q_2021: "387,924315 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0576188,
          46.9420403
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Космонавтів, 56/А",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 56/А",
        lat: 46.93833027278307,
        lon: 32.03773001715817,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 54,
        apart_ind: 3,
        q_2021: "193,397000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03773001715817,
          46.93833027278307
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Космонавтів, 56/Б",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 56/Б",
        lat: 46.93767095449115,
        lon: 32.038317274652954,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 54,
        apart_ind: 0,
        q_2021: "181,983000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.038317274652954,
          46.93767095449115
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Космонавтів, 57",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 57",
        lat: 46.9431095,
        lon: 32.058064,
        build_s: "3 724,50 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 56,
        apart_ind: 2,
        q_2021: "142,658712 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.058064,
          46.9431095
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Космонавтів, 57/А",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 57/А",
        lat: 46.94253182512479,
        lon: 32.05832971818182,
        build_s: "4 313,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 71,
        apart_ind: 1,
        q_2021: "228,799756 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05832971818182,
          46.942531825124796
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Космонавтів, 58",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 58",
        lat: 46.9378957,
        lon: 32.040688,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 58,
        apart_ind: 0,
        q_2021: "213,625000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.040688,
          46.9378957
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Космонавтів, 59",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 59",
        lat: 46.9436084199209,
        lon: 32.05849199090908,
        build_s: "4 174,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 67,
        apart_ind: 2,
        q_2021: "222,750023 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05849199090908,
          46.94360841992089
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Космонавтів, 59/А",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 59/А",
        lat: 46.9428589766808,
        lon: 32.05896544545454,
        build_s: "3 806,50 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 5,
        q_2021: "235,230269 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05896544545454,
          46.9428589766808
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Космонавтів, 60",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 60",
        lat: 46.9384377,
        lon: 32.0404031,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 38,
        apart_ind: 6,
        q_2021: "186,957000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0404031,
          46.93843769999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Космонавтів, 62",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 62",
        lat: 46.9384197,
        lon: 32.0415409,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 4,
        q_2021: "132,862000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0415409,
          46.9384197
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Космонавтів, 64",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 64",
        lat: 46.9386249,
        lon: 32.0422336,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 25,
        apart_ind: 5,
        q_2021: "121,638000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0422336,
          46.9386249
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Космонавтів, 66",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 66",
        lat: 46.9393808,
        lon: 32.0452129,
        build_s: "?",
        bld_floor: null,
        bld_porch: "?",
        bld_apart: null,
        apart_ind: null,
        q_2021: "120,768700 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0452129,
          46.9393808
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Космонавтів, 66",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 66",
        lat: 46.9393808,
        lon: 32.0452129,
        build_s: "?",
        bld_floor: null,
        bld_porch: "?",
        bld_apart: null,
        apart_ind: null,
        q_2021: "134,566500 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0452129,
          46.9393808
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Космонавтів, 67",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 67",
        lat: 46.95186712411881,
        lon: 32.065439532910325,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 32,
        apart_ind: 2,
        q_2021: "127,695000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.065439532910325,
          46.95186712411881
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Космонавтів, 68",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 68",
        lat: 46.93961489803457,
        lon: 32.045961025052044,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 68,
        apart_ind: 2,
        q_2021: "232,038000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.045961025052044,
          46.93961489803457
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Космонавтів, 68/А",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 68/А",
        lat: 46.93994130573675,
        lon: 32.04705374788342,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 65,
        apart_ind: 3,
        q_2021: "279,597000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04705374788342,
          46.93994130573675
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Космонавтів, 69",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 69",
        lat: 46.9522355,
        lon: 32.065812,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 31,
        apart_ind: 0,
        q_2021: "127,369000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.065812,
          46.9522355
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Космонавтів, 71",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 71",
        lat: 46.95280184334654,
        lon: 32.066446454028736,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 1,
        q_2021: "214,129000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.066446454028736,
          46.95280184334654
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Космонавтів, 73",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 73",
        lat: 46.95384213158126,
        lon: 32.067162560770825,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 36,
        apart_ind: 1,
        q_2021: "141,981000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.067162560770825,
          46.95384213158126
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Космонавтів, 73/А",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 73/А",
        lat: 46.95336272415398,
        lon: 32.06675860312144,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 32,
        apart_ind: 5,
        q_2021: "110,802000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06675860312144,
          46.95336272415398
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Космонавтів, 74",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 74",
        lat: 46.94166043526774,
        lon: 32.0536963524636,
        build_s: "11 267,20 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 216,
        apart_ind: 6,
        q_2021: "788,940222 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0536963524636,
          46.94166043526774
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Космонавтів, 74/А",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 74/А",
        lat: 46.94184940117078,
        lon: 32.05261800756422,
        build_s: "3 983,80 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 1,
        q_2021: "317,620270 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05261800756422,
          46.94184940117078
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Космонавтів, 75",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 75",
        lat: 46.9543927,
        lon: 32.0676917,
        build_s: "1 287,70 м2",
        bld_floor: 3,
        bld_porch: 3,
        bld_apart: 27,
        apart_ind: 3,
        q_2021: "110,930061 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0676917,
          46.9543927
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Космонавтів, 77",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 77",
        lat: 46.9550472,
        lon: 32.0682181,
        build_s: "1 597,90 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 36,
        apart_ind: 2,
        q_2021: "131,450031 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0682181,
          46.9550472
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Космонавтів, 77/А",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 77/А",
        lat: 46.9546002,
        lon: 32.0691086,
        build_s: "5 996,50 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 119,
        apart_ind: 7,
        q_2021: "370,134759 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0691086,
          46.95460019999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Космонавтів, 79",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 79",
        lat: 46.9555643,
        lon: 32.0687225,
        build_s: "1 812,40 м2",
        bld_floor: 4,
        bld_porch: 4,
        bld_apart: 33,
        apart_ind: 6,
        q_2021: "151,441360 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0687225,
          46.95556430000001
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Космонавтів, 80",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 80",
        lat: 46.94399160945443,
        lon: 32.057350354545434,
        build_s: "8 480,10 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 8,
        q_2021: "484,109135 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.057350354545434,
          46.94399160945443
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 82",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 82",
        lat: 46.94515762969855,
        lon: 32.05848125359046,
        build_s: "12 708,90 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 216,
        apart_ind: 4,
        q_2021: "890,240076 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05848125359046,
          46.94515762969855
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 84",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 84",
        lat: 46.946040505963175,
        lon: 32.05904883651897,
        build_s: "4 564,40 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 2,
        q_2021: "344,485808 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05904883651897,
          46.946040505963175
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 86",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 86",
        lat: 46.94652853409358,
        lon: 32.05946554297282,
        build_s: "4 593,90 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "401,970759 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05946554297282,
          46.94652853409359
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Космонавтів, 88",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 88",
        lat: 46.94708767643213,
        lon: 32.05988584172369,
        build_s: "3 540,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "229,756505 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05988584172369,
          46.94708767643213
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Космонавтів, 90",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 90",
        lat: 46.94763209888655,
        lon: 32.06037798641486,
        build_s: "3 642,10 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 3,
        q_2021: "238,233447 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06037798641486,
          46.94763209888655
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Космонавтів, 92",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 92",
        lat: 46.9481191,
        lon: 32.0609695,
        build_s: "4 582,40 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "352,570313 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0609695,
          46.9481191
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Космонавтів, 96",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 96",
        lat: 46.9485757,
        lon: 32.0613977,
        build_s: "4 558,30 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 1,
        q_2021: "304,310144 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0613977,
          46.9485757
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Космонавтів, 98",
        adrs_full: "м. Миколаїв, вул. Космонавтів, 98",
        lat: 46.9493071,
        lon: 32.0620258,
        build_s: "6 664,00 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 240,
        apart_ind: 3,
        q_2021: "734,346100 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0620258,
          46.9493071
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Крилова, 1",
        adrs_full: "м. Миколаїв, вул. Крилова, 1",
        lat: 46.9609234,
        lon: 31.9501312,
        build_s: "1 171,00 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 1,
        q_2021: "95,850026 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9501312,
          46.9609234
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Крилова, 11",
        adrs_full: "м. Миколаїв, вул. Крилова, 11",
        lat: 46.95594734100316,
        lon: 31.958077450069297,
        build_s: "5 964,10 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 129,
        apart_ind: 2,
        q_2021: "409,795648 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.958077450069297,
          46.95594734100316
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Крилова, 11/1",
        adrs_full: "м. Миколаїв, вул. Крилова, 11/1",
        lat: 46.95534058656767,
        lon: 31.95681935914058,
        build_s: "3 106,80 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "207,036134 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95681935914058,
          46.95534058656767
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Крилова, 12",
        adrs_full: "м. Миколаїв, вул. Крилова, 12",
        lat: 46.959499414949946,
        lon: 31.955182446882826,
        build_s: "489,70 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "45,340971 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.955182446882826,
          46.959499414949946
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Крилова, 12/1",
        adrs_full: "м. Миколаїв, вул. Крилова, 12/1",
        lat: 46.9611817,
        lon: 31.9514683,
        build_s: "2 758,60 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "206,779830 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9514683,
          46.9611817
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Крилова, 12/2",
        adrs_full: "м. Миколаїв, вул. Крилова, 12/2",
        lat: 46.9612015,
        lon: 31.9522175,
        build_s: "2 781,60 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "214,580072 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9522175,
          46.9612015
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 12/3",
        adrs_full: "м. Миколаїв, вул. Крилова, 12/3",
        lat: 46.9608248,
        lon: 31.9526157,
        build_s: "2 820,70 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 4,
        q_2021: "180,460166 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9526157,
          46.9608248
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 12/4",
        adrs_full: "м. Миколаїв, вул. Крилова, 12/4",
        lat: 46.9621936,
        lon: 31.9520091,
        build_s: "7 188,30 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 160,
        apart_ind: 3,
        q_2021: "547,460000 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9520091,
          46.9621936
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот Абрикосова,5",
        address: "вул. Крилова, 12/5",
        adrs_full: "м. Миколаїв, вул. Крилова, 12/5",
        lat: 46.9609659,
        lon: 31.9535166,
        build_s: "1 970,90 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 18,
        apart_ind: 11,
        q_2021: "68,068600 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9535166,
          46.9609659
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Крилова, 13",
        adrs_full: "м. Миколаїв, вул. Крилова, 13",
        lat: 46.955498630453,
        lon: 31.958555225572415,
        build_s: "5 978,80 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 129,
        apart_ind: 4,
        q_2021: "348,697400 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.958555225572415,
          46.955498630453
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Крилова, 13/1",
        adrs_full: "м. Миколаїв, вул. Крилова, 13/1",
        lat: 46.95463565465485,
        lon: 31.956564280541585,
        build_s: "4 555,20 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 100,
        apart_ind: 1,
        q_2021: "296,560036 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.956564280541585,
          46.95463565465485
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Крилова, 14",
        adrs_full: "м. Миколаїв, вул. Крилова, 14",
        lat: 46.95922840659857,
        lon: 31.955563452393037,
        build_s: "472,20 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "48,703847 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.955563452393037,
          46.95922840659857
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Крилова, 14/А",
        adrs_full: "м. Миколаїв, вул. Крилова, 14/А",
        lat: 46.95941795593661,
        lon: 31.95610971330526,
        build_s: "530,30 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 2,
        q_2021: "47,058696 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95610971330526,
          46.95941795593661
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Крилова, 15",
        adrs_full: "м. Миколаїв, вул. Крилова, 15",
        lat: 46.955030300128215,
        lon: 31.958979116620295,
        build_s: "6 025,80 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 129,
        apart_ind: 3,
        q_2021: "426,042766 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.958979116620295,
          46.955030300128215
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 15/А",
        adrs_full: "м. Миколаїв, вул. Крилова, 15/А",
        lat: 46.9548097652077,
        lon: 31.957453944923454,
        build_s: "3 135,40 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 0,
        q_2021: "188,725731 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.957453944923454,
          46.9548097652077
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 16",
        adrs_full: "м. Миколаїв, вул. Крилова, 16",
        lat: 46.959018491672026,
        lon: 31.956031676032094,
        build_s: "478,60 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 1,
        q_2021: "44,789168 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95603167603209,
          46.959018491672026
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот Абрикосова,5",
        address: "вул. Крилова, 17",
        adrs_full: "м. Миколаїв, вул. Крилова, 17",
        lat: 46.95467721068129,
        lon: 31.959589807113005,
        build_s: "4 464,30 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 100,
        apart_ind: 3,
        q_2021: "289,239910 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.959589807113005,
          46.95467721068129
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот Абрикосова,5",
        address: "вул. Крилова, 18",
        adrs_full: "м. Миколаїв, вул. Крилова, 18",
        lat: 46.958813275537295,
        lon: 31.95653203266598,
        build_s: "474,30 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 1,
        q_2021: "51,523262 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95653203266598,
          46.958813275537295
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 19",
        adrs_full: "м. Миколаїв, вул. Крилова, 19",
        lat: 46.954325903146405,
        lon: 31.96089739500357,
        build_s: "3 529,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 50,
        apart_ind: 5,
        q_2021: "198,186064 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96089739500357,
          46.954325903146405
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 19/1",
        adrs_full: "м. Миколаїв, вул. Крилова, 19/1",
        lat: 46.953753805188335,
        lon: 31.96126872047196,
        build_s: "2 004,70 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 30,
        apart_ind: 0,
        q_2021: "126,436969 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96126872047196,
          46.953753805188335
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот Абрикосова,5",
        address: "вул. Крилова, 19/А",
        adrs_full: "м. Миколаїв, вул. Крилова, 19/А",
        lat: 46.952453888990966,
        lon: 31.95806471214438,
        build_s: "5 190,90 м2",
        bld_floor: 5,
        bld_porch: 7,
        bld_apart: 114,
        apart_ind: 0,
        q_2021: "335,209973 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "7",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95806471214438,
          46.952453888990966
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 19/Б",
        adrs_full: "м. Миколаїв, вул. Крилова, 19/Б",
        lat: 46.95323785667812,
        lon: 31.957592489972146,
        build_s: "5 252,90 м2",
        bld_floor: 5,
        bld_porch: 7,
        bld_apart: 114,
        apart_ind: 4,
        q_2021: "352,939984 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "7",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95759248997215,
          46.95323785667812
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот Абрикосова,5",
        address: "вул. Крилова, 19/В",
        adrs_full: "м. Миколаїв, вул. Крилова, 19/В",
        lat: 46.95298707570748,
        lon: 31.95709528743935,
        build_s: "4 187,10 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 156,
        apart_ind: 0,
        q_2021: "334,735947 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95709528743935,
          46.952987075707476
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 19/Г",
        adrs_full: "м. Миколаїв, вул. Крилова, 19/Г",
        lat: 46.9525343,
        lon: 31.9568448,
        build_s: "883,00 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 13,
        apart_ind: 7,
        q_2021: "70,940037 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9568448,
          46.9525343
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 2",
        adrs_full: "м. Миколаїв, вул. Крилова, 2",
        lat: 46.9611753,
        lon: 31.9503375,
        build_s: "685,90 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "61,981335 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9503375,
          46.9611753
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 21",
        adrs_full: "м. Миколаїв, вул. Крилова, 21",
        lat: 46.954130407843074,
        lon: 31.9604663275849,
        build_s: "3 112,40 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 3,
        q_2021: "220,709968 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9604663275849,
          46.954130407843074
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 23",
        adrs_full: "м. Миколаїв, вул. Крилова, 23",
        lat: 46.95410343536116,
        lon: 31.959826898716063,
        build_s: "3 114,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "237,350117 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.959826898716063,
          46.95410343536116
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 25",
        adrs_full: "м. Миколаїв, вул. Крилова, 25",
        lat: 46.953963668646274,
        lon: 31.959345530915925,
        build_s: "3 333,90 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 0,
        q_2021: "255,678501 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.959345530915925,
          46.953963668646274
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 27",
        adrs_full: "м. Миколаїв, вул. Крилова, 27",
        lat: 46.95327584524305,
        lon: 31.959157469951467,
        build_s: "6 181,40 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 129,
        apart_ind: 7,
        q_2021: "415,369335 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.959157469951467,
          46.95327584524305
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Крилова, 29",
        adrs_full: "м. Миколаїв, вул. Крилова, 29",
        lat: 46.95345055458164,
        lon: 31.960591051492123,
        build_s: "1 441,10 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 30,
        apart_ind: 0,
        q_2021: "124,645062 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.960591051492123,
          46.95345055458164
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 3",
        adrs_full: "м. Миколаїв, вул. Крилова, 3",
        lat: 46.9605707,
        lon: 31.9509941,
        build_s: "1 166,20 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 2,
        q_2021: "98,184004 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9509941,
          46.9605707
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 3/А",
        adrs_full: "м. Миколаїв, вул. Крилова, 3/А",
        lat: 46.9602348,
        lon: 31.950719,
        build_s: "705,90 м2",
        bld_floor: 1,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 1,
        q_2021: "80,812009 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.950719,
          46.96023479999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 31",
        adrs_full: "м. Миколаїв, вул. Крилова, 31",
        lat: 46.9576086,
        lon: 31.958025,
        build_s: "3 289,50 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 2,
        q_2021: "236,924981 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.958025,
          46.9576086
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 33",
        adrs_full: "м. Миколаїв, вул. Крилова, 33",
        lat: 46.95245318401749,
        lon: 31.959144362283038,
        build_s: "6 257,40 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 129,
        apart_ind: 3,
        q_2021: "355,667213 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.959144362283038,
          46.95245318401749
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 35",
        adrs_full: "м. Миколаїв, вул. Крилова, 35",
        lat: 46.9518676,
        lon: 31.9580392,
        build_s: "4 315,70 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 160,
        apart_ind: 1,
        q_2021: "330,849790 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9580392,
          46.9518676
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Крилова, 38",
        adrs_full: "м. Миколаїв, вул. Крилова, 38",
        lat: 46.95651375932896,
        lon: 31.959624279111825,
        build_s: "4 113,70 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 5,
        q_2021: "278,383000 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.959624279111825,
          46.95651375932896
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Крилова, 38/1",
        adrs_full: "м. Миколаїв, вул. Крилова, 38/1",
        lat: 46.9569407,
        lon: 31.9597478,
        build_s: "5 243,80 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 96,
        apart_ind: 0,
        q_2021: "460,951781 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9597478,
          46.9569407
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 38/Б",
        adrs_full: "м. Миколаїв, вул. Крилова, 38/Б",
        lat: 46.95717272372037,
        lon: 31.960884133379697,
        build_s: "4 063,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 108,
        apart_ind: 1,
        q_2021: "383,431914 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.960884133379697,
          46.95717272372037
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 38/В",
        adrs_full: "м. Миколаїв, вул. Крилова, 38/В",
        lat: 46.957004362710855,
        lon: 31.961563128521966,
        build_s: "6 087,10 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 161,
        apart_ind: 0,
        q_2021: "455,950080 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.961563128521966,
          46.957004362710855
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 4",
        adrs_full: "м. Миколаїв, вул. Крилова, 4",
        lat: 46.9610434,
        lon: 31.950897,
        build_s: "2 651,20 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 56,
        apart_ind: 1,
        q_2021: "221,839112 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.950897,
          46.9610434
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 40",
        adrs_full: "м. Миколаїв, вул. Крилова, 40",
        lat: 46.9561936,
        lon: 31.9601173,
        build_s: "4 161,10 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 1,
        q_2021: "311,394200 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9601173,
          46.95619360000001
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 40/1",
        adrs_full: "м. Миколаїв, вул. Крилова, 40/1",
        lat: 46.956367120544634,
        lon: 31.96088148105493,
        build_s: "6 064,80 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 162,
        apart_ind: 5,
        q_2021: "482,232611 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.960881481054926,
          46.956367120544634
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 44",
        adrs_full: "м. Миколаїв, вул. Крилова, 44",
        lat: 46.9571544,
        lon: 31.9625144,
        build_s: "5 951,20 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 108,
        apart_ind: 7,
        q_2021: "404,534886 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9625144,
          46.9571544
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 44/А",
        adrs_full: "м. Миколаїв, вул. Крилова, 44/А",
        lat: 46.957167,
        lon: 31.9632185,
        build_s: "3 910,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 5,
        q_2021: "246,329898 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9632185,
          46.957167
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Крилова, 46",
        adrs_full: "м. Миколаїв, вул. Крилова, 46",
        lat: 46.95728334784252,
        lon: 31.964051770636527,
        build_s: "4 025,30 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 1,
        q_2021: "263,973673 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.964051770636527,
          46.95728334784252
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 46/А",
        adrs_full: "м. Миколаїв, вул. Крилова, 46/А",
        lat: 46.95722814467065,
        lon: 31.964633474270823,
        build_s: "4 004,30 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 2,
        q_2021: "262,769885 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.964633474270823,
          46.957228144670644
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 48",
        adrs_full: "м. Миколаїв, вул. Крилова, 48",
        lat: 46.95678169088466,
        lon: 31.964523122970295,
        build_s: "7 904,00 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 1,
        q_2021: "521,449953 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.964523122970295,
          46.95678169088466
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 5",
        adrs_full: "м. Миколаїв, вул. Крилова, 5",
        lat: 46.9604409,
        lon: 31.9514113,
        build_s: "1 524,80 м2",
        bld_floor: 3,
        bld_porch: 3,
        bld_apart: 36,
        apart_ind: 2,
        q_2021: "149,474935 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9514113,
          46.96044089999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 5/А",
        adrs_full: "м. Миколаїв, вул. Крилова, 5/А",
        lat: 46.9597649,
        lon: 31.9511457,
        build_s: "3 755,40 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 80,
        apart_ind: 2,
        q_2021: "266,576114 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9511457,
          46.9597649
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 50",
        adrs_full: "м. Миколаїв, вул. Крилова, 50",
        lat: 46.95653597698956,
        lon: 31.962144902573296,
        build_s: "8 849,10 м2",
        bld_floor: 10,
        bld_porch: 4,
        bld_apart: 160,
        apart_ind: 4,
        q_2021: "621,600200 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.962144902573296,
          46.956535976989564
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 50/А",
        adrs_full: "м. Миколаїв, вул. Крилова, 50/А",
        lat: 46.956607897595994,
        lon: 31.963064344344318,
        build_s: "6 634,30 м2",
        bld_floor: 10,
        bld_porch: 3,
        bld_apart: 120,
        apart_ind: 4,
        q_2021: "469,159100 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.963064344344318,
          46.956607897595994
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 52",
        adrs_full: "м. Миколаїв, вул. Крилова, 52",
        lat: 46.955724439651135,
        lon: 31.963425060513654,
        build_s: "9 293,00 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 143,
        apart_ind: 8,
        q_2021: "506,520200 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.963425060513654,
          46.955724439651135
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Крилова, 54",
        adrs_full: "м. Миколаїв, вул. Крилова, 54",
        lat: 46.95534787937243,
        lon: 31.962475528244386,
        build_s: "15 836,90 м2",
        bld_floor: 10,
        bld_porch: 7,
        bld_apart: 279,
        apart_ind: 11,
        q_2021: "929,389100 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96247552824439,
          46.95534787937243
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Крилова, 56",
        adrs_full: "м. Миколаїв, вул. Крилова, 56",
        lat: 46.954402090828445,
        lon: 31.963389188422056,
        build_s: "4 008,50 м2",
        bld_floor: 14,
        bld_porch: 2,
        bld_apart: 64,
        apart_ind: 1,
        q_2021: "381,237186 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.963389188422056,
          46.954402090828445
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 6",
        adrs_full: "м. Миколаїв, вул. Крилова, 6",
        lat: 46.9607577,
        lon: 31.951409,
        build_s: "668,20 м2",
        bld_floor: 1,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "72,290575 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.951409,
          46.96075769999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 8",
        adrs_full: "м. Миколаїв, вул. Крилова, 8",
        lat: 46.960424,
        lon: 31.9525492,
        build_s: "6 797,00 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 144,
        apart_ind: 3,
        q_2021: "453,592400 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9525492,
          46.960424
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 8/1",
        adrs_full: "м. Миколаїв, вул. Крилова, 8/1",
        lat: 46.9614978,
        lon: 31.9509942,
        build_s: "4 055,70 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 80,
        apart_ind: 3,
        q_2021: "264,029722 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9509942,
          46.9614978
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Крилова, 9",
        adrs_full: "м. Миколаїв, вул. Крилова, 9",
        lat: 46.9592204,
        lon: 31.9545213,
        build_s: "7 455,20 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 145,
        apart_ind: 0,
        q_2021: "491,830818 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9545213,
          46.9592204
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Кузнецька, 1",
        adrs_full: "м. Миколаїв, вул. Кузнецька, 1",
        lat: 46.95346676365431,
        lon: 31.98552814438622,
        build_s: "?",
        bld_floor: 1,
        bld_porch: "?",
        bld_apart: 9,
        apart_ind: 5,
        q_2021: "28,830000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98552814438622,
          46.95346676365431
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Кузнецька, 130/10",
        adrs_full: "м. Миколаїв, вул. Кузнецька, 130/10",
        lat: 46.9555356,
        lon: 32.0139797,
        build_s: "352,90 м2",
        bld_floor: 1,
        bld_porch: 2,
        bld_apart: 10,
        apart_ind: 3,
        q_2021: "39,408600 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0139797,
          46.9555356
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Кузнецька, 130/13",
        adrs_full: "м. Миколаїв, вул. Кузнецька, 130/13",
        lat: 46.9555356,
        lon: 32.0139797,
        build_s: "439,80 м2",
        bld_floor: 1,
        bld_porch: 2,
        bld_apart: 10,
        apart_ind: 2,
        q_2021: "52,463500 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0139797,
          46.9555356
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.18 мкр. Ген.Карпенко,51-а",
        address: "вул. Кузнецька, 130/8",
        adrs_full: "м. Миколаїв, вул. Кузнецька, 130/8",
        lat: 46.9555356,
        lon: 32.0139797,
        build_s: "362,00 м2",
        bld_floor: 1,
        bld_porch: 2,
        bld_apart: 10,
        apart_ind: 3,
        q_2021: "33,262800 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0139797,
          46.9555356
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Кузнецька, 130/9",
        adrs_full: "м. Миколаїв, вул. Кузнецька, 130/9",
        lat: 46.9555356,
        lon: 32.0139797,
        build_s: "1 037,60 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 30,
        apart_ind: 0,
        q_2021: "80,629991 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0139797,
          46.9555356
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Кузнецька, 50",
        adrs_full: "м. Миколаїв, вул. Кузнецька, 50",
        lat: 46.9547009,
        lon: 31.9979978,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "259,725000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9979978,
          46.9547009
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Кузнецька,130/10",
        address: "вул. Кузнецька, 52",
        adrs_full: "м. Миколаїв, вул. Кузнецька, 52",
        lat: 46.9554416,
        lon: 31.9980557,
        build_s: "?",
        bld_floor: 3,
        bld_porch: 1,
        bld_apart: null,
        apart_ind: null,
        q_2021: "154,480200 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9980557,
          46.9554416
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Кузнецька,130/10",
        address: "вул. Кузнецька, 54",
        adrs_full: "м. Миколаїв, вул. Кузнецька, 54",
        lat: 46.955102,
        lon: 31.9985365,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: null,
        apart_ind: null,
        q_2021: "116,022000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9985365,
          46.955102
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Кузнецька,130/10",
        address: "вул. Кузнецька, 58",
        adrs_full: "м. Миколаїв, вул. Кузнецька, 58",
        lat: 46.9547678,
        lon: 31.9996765,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 36,
        apart_ind: 0,
        q_2021: "106,966000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9996765,
          46.9547678
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Кузнецька,130/10",
        address: "вул. Кузнецька, 58/А",
        adrs_full: "м. Миколаїв, вул. Кузнецька, 58/А",
        lat: 46.9550727,
        lon: 31.9994422,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "66,345000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9994422,
          46.9550727
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Кузнецька, 58/Б",
        adrs_full: "м. Миколаїв, вул. Кузнецька, 58/Б",
        lat: 46.9556737,
        lon: 31.9995426,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 3,
        q_2021: "78,769000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9995426,
          46.9556737
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Курортна, 10",
        adrs_full: "м. Миколаїв, вул. Курортна, 10",
        lat: 46.955995995899904,
        lon: 31.953937694795336,
        build_s: "1 403,00 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 32,
        apart_ind: 1,
        q_2021: "108,335109 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.953937694795336,
          46.955995995899904
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Курортна, 10/А",
        adrs_full: "м. Миколаїв, вул. Курортна, 10/А",
        lat: 46.956432293387266,
        lon: 31.953929737821014,
        build_s: "1 156,10 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 2,
        q_2021: "90,000931 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95392973782101,
          46.956432293387266
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Курортна, 11",
        adrs_full: "м. Миколаїв, вул. Курортна, 11",
        lat: 46.9535516,
        lon: 31.9549299,
        build_s: "2 095,80 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 60,
        apart_ind: 0,
        q_2021: "161,508349 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9549299,
          46.9535516
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Курортна, 12",
        adrs_full: "м. Миколаїв, вул. Курортна, 12",
        lat: 46.95621142954365,
        lon: 31.95459547133941,
        build_s: "1 448,60 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 32,
        apart_ind: 1,
        q_2021: "94,703969 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.954595471339406,
          46.95621142954365
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Курортна, 13/13А",
        adrs_full: "м. Миколаїв, вул. Курортна, 13/13А",
        lat: 46.95646960592159,
        lon: 31.95699257637048,
        build_s: "2 107,30 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 85,
        apart_ind: 1,
        q_2021: "162,642587 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95699257637048,
          46.95646960592159
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Курортна, 14",
        adrs_full: "м. Миколаїв, вул. Курортна, 14",
        lat: 46.95666763910262,
        lon: 31.95506228049971,
        build_s: "2 714,30 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 56,
        apart_ind: 2,
        q_2021: "190,049865 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.955062280499714,
          46.95666763910262
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Курортна, 15",
        adrs_full: "м. Миколаїв, вул. Курортна, 15",
        lat: 46.9567255701969,
        lon: 31.95742682803617,
        build_s: "2 107,10 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 67,
        apart_ind: 1,
        q_2021: "161,949719 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95742682803617,
          46.9567255701969
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Курортна, 17",
        adrs_full: "м. Миколаїв, вул. Курортна, 17",
        lat: 46.95680613052061,
        lon: 31.957991773213138,
        build_s: "1 994,90 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 54,
        apart_ind: 0,
        q_2021: "164,413105 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.957991773213134,
          46.95680613052061
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Курортна, 19",
        adrs_full: "м. Миколаїв, вул. Курортна, 19",
        lat: 46.95671832881355,
        lon: 31.958318009160394,
        build_s: "1 981,90 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 54,
        apart_ind: 1,
        q_2021: "180,609183 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95831800916039,
          46.95671832881355
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Курортна, 19/А",
        adrs_full: "м. Миколаїв, вул. Курортна, 19/А",
        lat: 46.95662419074079,
        lon: 31.95861639569752,
        build_s: "1 903,10 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 54,
        apart_ind: 1,
        q_2021: "169,974195 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.958616395697522,
          46.95662419074079
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Курортна, 2",
        adrs_full: "м. Миколаїв, вул. Курортна, 2",
        lat: 46.9524113,
        lon: 31.9439173,
        build_s: "1 950,10 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 48,
        apart_ind: 4,
        q_2021: "144,686693 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9439173,
          46.9524113
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Курортна, 2/А",
        adrs_full: "м. Миколаїв, вул. Курортна, 2/А",
        lat: 46.954679662699384,
        lon: 31.94856863986811,
        build_s: "1 866,40 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 0,
        q_2021: "165,541191 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94856863986811,
          46.954679662699384
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Курортна, 2/Б",
        adrs_full: "м. Миколаїв, вул. Курортна, 2/Б",
        lat: 46.953842595153176,
        lon: 31.946950617002575,
        build_s: "8 338,00 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 18,
        q_2021: "502,121876 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.946950617002575,
          46.953842595153176
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Курортна, 3",
        adrs_full: "м. Миколаїв, вул. Курортна, 3",
        lat: 46.952203,
        lon: 31.945847,
        build_s: "3 653,30 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 80,
        apart_ind: 3,
        q_2021: "287,736968 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.945847,
          46.952203
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Курортна, 3/А",
        adrs_full: "м. Миколаїв, вул. Курортна, 3/А",
        lat: 46.95408996303271,
        lon: 31.95210662648838,
        build_s: "6 140,30 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 129,
        apart_ind: 9,
        q_2021: "446,015814 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95210662648838,
          46.95408996303271
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Курортна, 3/Б",
        adrs_full: "м. Миколаїв, вул. Курортна, 3/Б",
        lat: 46.95407297643377,
        lon: 31.952853197997637,
        build_s: "5 222,00 м2",
        bld_floor: 5,
        bld_porch: 7,
        bld_apart: 114,
        apart_ind: 4,
        q_2021: "394,219830 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "7",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.952853197997637,
          46.954072976433764
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Курортна, 4",
        adrs_full: "м. Миколаїв, вул. Курортна, 4",
        lat: 46.955233824534425,
        lon: 31.951586408882758,
        build_s: "1 900,40 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 47,
        apart_ind: 4,
        q_2021: "138,869090 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.951586408882754,
          46.955233824534425
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Курортна, 5",
        adrs_full: "м. Миколаїв, вул. Курортна, 5",
        lat: 46.9552285,
        lon: 31.9533327,
        build_s: "3 975,20 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "283,418853 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9533327,
          46.9552285
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Курортна, 6",
        adrs_full: "м. Миколаїв, вул. Курортна, 6",
        lat: 46.95551262482042,
        lon: 31.952456371408783,
        build_s: "2 066,30 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 48,
        apart_ind: 2,
        q_2021: "195,120010 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.952456371408783,
          46.95551262482042
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Курортна, 8",
        adrs_full: "м. Миколаїв, вул. Курортна, 8",
        lat: 46.95579142365388,
        lon: 31.953246764191583,
        build_s: "1 452,90 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 32,
        apart_ind: 3,
        q_2021: "94,337728 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.953246764191583,
          46.95579142365388
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Курортна, 8/А",
        adrs_full: "м. Миколаїв, вул. Курортна, 8/А",
        lat: 46.95616979117592,
        lon: 31.953157911311635,
        build_s: "1 141,50 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 1,
        q_2021: "69,930011 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.953157911311635,
          46.956169791175924
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Курортна, 9",
        adrs_full: "м. Миколаїв, вул. Курортна, 9",
        lat: 46.95567026881272,
        lon: 31.954542629805612,
        build_s: "2 258,60 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 62,
        apart_ind: 0,
        q_2021: "138,912225 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.954542629805612,
          46.95567026881272
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Курортна, 9/А",
        adrs_full: "м. Миколаїв, вул. Курортна, 9/А",
        lat: 46.9555044,
        lon: 31.955561,
        build_s: "3 123,20 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 70,
        apart_ind: 2,
        q_2021: "198,969031 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.955561,
          46.9555044
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Лагерне Поле, 5/1Б",
        adrs_full: "м. Миколаїв, вул. Лагерне Поле, 5/1Б",
        lat: 46.9826596,
        lon: 31.9776003,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 11,
        apart_ind: 8,
        q_2021: "-38,693000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9776003,
          46.9826596
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Лагерне Поле, 5/2Б",
        adrs_full: "м. Миколаїв, вул. Лагерне Поле, 5/2Б",
        lat: 46.9826596,
        lon: 31.9776003,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "44,023000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9776003,
          46.9826596
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Лазурна, 10/А",
        adrs_full: "м. Миколаїв, вул. Лазурна, 10/А",
        lat: 46.95237318250899,
        lon: 31.937391095523953,
        build_s: "3 947,20 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "251,350117 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.937391095523953,
          46.95237318250899
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Дачна,7-Б",
        address: "вул. Лазурна, 10/Б",
        adrs_full: "м. Миколаїв, вул. Лазурна, 10/Б",
        lat: 46.9518189996821,
        lon: 31.936726520576006,
        build_s: "4 021,30 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 4,
        q_2021: "301,389939 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.936726520576006,
          46.9518189996821
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Лазурна, 10/В",
        adrs_full: "м. Миколаїв, вул. Лазурна, 10/В",
        lat: 46.95235111339108,
        lon: 31.9364319522207,
        build_s: "8 158,50 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 143,
        apart_ind: 6,
        q_2021: "531,669531 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9364319522207,
          46.95235111339108
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Лазурна, 14",
        adrs_full: "м. Миколаїв, вул. Лазурна, 14",
        lat: 46.9519538,
        lon: 31.93758,
        build_s: "4 005,20 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 108,
        apart_ind: 1,
        q_2021: "292,369482 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93758,
          46.9519538
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Лазурна, 14/А",
        adrs_full: "м. Миколаїв, вул. Лазурна, 14/А",
        lat: 46.9514042,
        lon: 31.9376535,
        build_s: "7 435,50 м2",
        bld_floor: 14,
        bld_porch: 2,
        bld_apart: 129,
        apart_ind: 3,
        q_2021: "736,657620 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9376535,
          46.9514042
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Лазурна, 14/Б",
        adrs_full: "м. Миколаїв, вул. Лазурна, 14/Б",
        lat: 46.9505338,
        lon: 31.9380139,
        build_s: "4 637,70 м2",
        bld_floor: 14,
        bld_porch: 1,
        bld_apart: 65,
        apart_ind: 0,
        q_2021: "365,349988 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9380139,
          46.9505338
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 16",
        adrs_full: "м. Миколаїв, вул. Лазурна, 16",
        lat: 46.95121755334546,
        lon: 31.936518956046527,
        build_s: "4 526,10 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 79,
        apart_ind: 4,
        q_2021: "299,180211 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.936518956046527,
          46.95121755334546
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 16/А",
        adrs_full: "м. Миколаїв, вул. Лазурна, 16/А",
        lat: 46.95099579256203,
        lon: 31.93601876545762,
        build_s: "4 699,20 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 118,
        apart_ind: 1,
        q_2021: "295,999928 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93601876545762,
          46.95099579256203
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 16/Б",
        adrs_full: "м. Миколаїв, вул. Лазурна, 16/Б",
        lat: 46.95052058778915,
        lon: 31.9358950069614,
        build_s: "4 721,70 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 119,
        apart_ind: 3,
        q_2021: "336,040175 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9358950069614,
          46.95052058778915
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 16/В",
        adrs_full: "м. Миколаїв, вул. Лазурна, 16/В",
        lat: 46.95006649928627,
        lon: 31.93576609186117,
        build_s: "4 622,30 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 118,
        apart_ind: 3,
        q_2021: "286,273715 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93576609186117,
          46.95006649928627
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 16/Г",
        adrs_full: "м. Миколаїв, вул. Лазурна, 16/Г",
        lat: 46.94966872867258,
        lon: 31.936075488101725,
        build_s: "4 327,90 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 79,
        apart_ind: 1,
        q_2021: "290,366187 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.936075488101725,
          46.94966872867258
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 18",
        adrs_full: "м. Миколаїв, вул. Лазурна, 18",
        lat: 46.95102204529162,
        lon: 31.93718992689106,
        build_s: "5 688,90 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 2,
        q_2021: "418,844500 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93718992689106,
          46.95102204529162
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 18/А",
        adrs_full: "м. Миколаїв, вул. Лазурна, 18/А",
        lat: 46.95020546125325,
        lon: 31.937200703782125,
        build_s: "4 265,30 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 107,
        apart_ind: 3,
        q_2021: "279,061215 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.937200703782125,
          46.95020546125325
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 18/Б",
        adrs_full: "м. Миколаїв, вул. Лазурна, 18/Б",
        lat: 46.94950166861753,
        lon: 31.93695283528802,
        build_s: "8 500,60 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 213,
        apart_ind: 3,
        q_2021: "567,577131 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93695283528802,
          46.94950166861753
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 2/Б",
        adrs_full: "м. Миколаїв, вул. Лазурна, 2/Б",
        lat: 46.95433974958877,
        lon: 31.938325092748094,
        build_s: "3 985,10 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "356,289852 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.938325092748094,
          46.95433974958877
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 20",
        adrs_full: "м. Миколаїв, вул. Лазурна, 20",
        lat: 46.946899762535125,
        lon: 31.940746300936848,
        build_s: "3 868,80 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 2,
        q_2021: "269,674013 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.940746300936848,
          46.946899762535125
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 20/А",
        adrs_full: "м. Миколаїв, вул. Лазурна, 20/А",
        lat: 46.94730440279469,
        lon: 31.940857662144342,
        build_s: "3 938,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "272,550180 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.940857662144346,
          46.94730440279469
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 24",
        adrs_full: "м. Миколаїв, вул. Лазурна, 24",
        lat: 46.94799596270245,
        lon: 31.94098339253991,
        build_s: "3 943,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 2,
        q_2021: "282,500143 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94098339253991,
          46.94799596270245
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 24/А",
        adrs_full: "м. Миколаїв, вул. Лазурна, 24/А",
        lat: 46.94773356336383,
        lon: 31.94141806047884,
        build_s: "3 987,60 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "321,094842 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94141806047884,
          46.94773356336383
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 24/Б",
        adrs_full: "м. Миколаїв, вул. Лазурна, 24/Б",
        lat: 46.9475034,
        lon: 31.9418651,
        build_s: "3 996,30 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "296,999937 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9418651,
          46.9475034
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 26",
        adrs_full: "м. Миколаїв, вул. Лазурна, 26",
        lat: 46.94778996706852,
        lon: 31.940243379354627,
        build_s: "2 898,50 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 78,
        apart_ind: 3,
        q_2021: "243,448353 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.940243379354627,
          46.947789967068516
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 26/А",
        adrs_full: "м. Миколаїв, вул. Лазурна, 26/А",
        lat: 46.94784882304482,
        lon: 31.93975482696046,
        build_s: "2 986,10 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 1,
        q_2021: "222,846566 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93975482696046,
          46.94784882304482
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 28",
        adrs_full: "м. Миколаїв, вул. Лазурна, 28",
        lat: 46.94650738118042,
        lon: 31.93996318018737,
        build_s: "7 683,50 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 8,
        q_2021: "501,460199 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93996318018737,
          46.94650738118042
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 28/А",
        adrs_full: "м. Миколаїв, вул. Лазурна, 28/А",
        lat: 46.946642262595525,
        lon: 31.93900403688412,
        build_s: "4 360,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 1,
        q_2021: "236,538757 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93900403688412,
          46.946642262595525
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 28/Б",
        adrs_full: "м. Миколаїв, вул. Лазурна, 28/Б",
        lat: 46.94640438059819,
        lon: 31.938181400867467,
        build_s: "7 699,30 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 6,
        q_2021: "470,854536 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.938181400867467,
          46.946404380598196
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 30",
        adrs_full: "м. Миколаїв, вул. Лазурна, 30",
        lat: 46.947146512576985,
        lon: 31.93887552407878,
        build_s: "7 673,50 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 8,
        q_2021: "524,424600 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93887552407878,
          46.947146512576985
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 30/А",
        adrs_full: "м. Миколаїв, вул. Лазурна, 30/А",
        lat: 46.9470448,
        lon: 31.9378531,
        build_s: "7 692,10 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 10,
        q_2021: "538,539695 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9378531,
          46.9470448
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 30/Б",
        adrs_full: "м. Миколаїв, вул. Лазурна, 30/Б",
        lat: 46.946438714147625,
        lon: 31.937197111485105,
        build_s: "3 862,90 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "266,523623 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.937197111485105,
          46.946438714147625
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 32",
        adrs_full: "м. Миколаїв, вул. Лазурна, 32",
        lat: 46.947159713598666,
        lon: 31.93972249628731,
        build_s: "3 034,90 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 0,
        q_2021: "218,141273 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.939722496287306,
          46.947159713598666
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 32/А",
        adrs_full: "м. Миколаїв, вул. Лазурна, 32/А",
        lat: 46.94747606743525,
        lon: 31.939467443199185,
        build_s: "2 933,50 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 1,
        q_2021: "211,690067 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.939467443199185,
          46.94747606743525
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 34",
        adrs_full: "м. Миколаїв, вул. Лазурна, 34",
        lat: 46.9493554382321,
        lon: 31.935039010695856,
        build_s: "4 182,00 м2",
        bld_floor: 13,
        bld_porch: 1,
        bld_apart: 77,
        apart_ind: 3,
        q_2021: "354,930129 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.935039010695856,
          46.9493554382321
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 36",
        adrs_full: "м. Миколаїв, вул. Лазурна, 36",
        lat: 46.94878315300027,
        lon: 31.935785338757835,
        build_s: "4 322,40 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 1,
        q_2021: "257,315060 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.935785338757835,
          46.94878315300027
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 36/А",
        adrs_full: "м. Миколаїв, вул. Лазурна, 36/А",
        lat: 46.94875863011274,
        lon: 31.935077656245703,
        build_s: "4 563,20 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 4,
        q_2021: "296,399868 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.935077656245703,
          46.94875863011274
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 36/Б",
        adrs_full: "м. Миколаїв, вул. Лазурна, 36/Б",
        lat: 46.9482756,
        lon: 31.9345044,
        build_s: "9 343,30 м2",
        bld_floor: 10,
        bld_porch: 4,
        bld_apart: 160,
        apart_ind: 16,
        q_2021: "491,162235 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9345044,
          46.9482756
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 38",
        adrs_full: "м. Миколаїв, вул. Лазурна, 38",
        lat: 46.9485281144208,
        lon: 31.936672636120775,
        build_s: "4 213,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 108,
        apart_ind: 1,
        q_2021: "275,660139 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.936672636120775,
          46.9485281144208
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 38/А",
        adrs_full: "м. Миколаїв, вул. Лазурна, 38/А",
        lat: 46.948091603263,
        lon: 31.93616252994451,
        build_s: "4 787,20 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 2,
        q_2021: "247,830132 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93616252994451,
          46.948091603263
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 38/Б",
        adrs_full: "м. Миколаїв, вул. Лазурна, 38/Б",
        lat: 46.947885607997144,
        lon: 31.935515916481645,
        build_s: "6 379,30 м2",
        bld_floor: 10,
        bld_porch: 3,
        bld_apart: 119,
        apart_ind: 5,
        q_2021: "402,297662 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.935515916481645,
          46.947885607997144
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 4",
        adrs_full: "м. Миколаїв, вул. Лазурна, 4",
        lat: 46.954599664519165,
        lon: 31.937700033067323,
        build_s: "3 981,50 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 7,
        q_2021: "319,142783 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.937700033067323,
          46.954599664519165
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 4/А",
        adrs_full: "м. Миколаїв, вул. Лазурна, 4/А",
        lat: 46.954187723289635,
        lon: 31.937161188514935,
        build_s: "7 891,90 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 9,
        q_2021: "561,670230 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.937161188514935,
          46.954187723289635
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 4/Б",
        adrs_full: "м. Миколаїв, вул. Лазурна, 4/Б",
        lat: 46.95369486086636,
        lon: 31.936758851249152,
        build_s: "4 004,20 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 6,
        q_2021: "292,759819 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.936758851249152,
          46.95369486086636
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 4/В",
        adrs_full: "м. Миколаїв, вул. Лазурна, 4/В",
        lat: 46.95340061247959,
        lon: 31.937060604198493,
        build_s: "3 984,60 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 5,
        q_2021: "245,288487 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.937060604198493,
          46.95340061247959
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 4/Г",
        adrs_full: "м. Миколаїв, вул. Лазурна, 4/Г",
        lat: 46.953054868557835,
        lon: 31.93655409031923,
        build_s: "4 172,90 м2",
        bld_floor: 13,
        bld_porch: 1,
        bld_apart: 77,
        apart_ind: 0,
        q_2021: "384,220594 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.936554090319234,
          46.953054868557835
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 40",
        adrs_full: "м. Миколаїв, вул. Лазурна, 40",
        lat: 46.94688837552349,
        lon: 31.934123159429824,
        build_s: "9 635,20 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 181,
        apart_ind: 83,
        q_2021: "388,970129 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.934123159429824,
          46.9468883755235
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 42",
        adrs_full: "м. Миколаїв, вул. Лазурна, 42",
        lat: 46.946469459390954,
        lon: 31.934244339624048,
        build_s: "4 872,20 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 1,
        q_2021: "310,599957 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.934244339624048,
          46.946469459390954
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 42/А",
        adrs_full: "м. Миколаїв, вул. Лазурна, 42/А",
        lat: 46.94648178050047,
        lon: 31.93513643211766,
        build_s: "9 496,00 м2",
        bld_floor: 10,
        bld_porch: 4,
        bld_apart: 160,
        apart_ind: 8,
        q_2021: "775,384111 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93513643211766,
          46.94648178050047
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 42/Б",
        adrs_full: "м. Миколаїв, вул. Лазурна, 42/Б",
        lat: 46.947009825385905,
        lon: 31.93586093498097,
        build_s: "4 634,40 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 3,
        q_2021: "285,808691 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93586093498097,
          46.947009825385905
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 42/В",
        adrs_full: "м. Миколаїв, вул. Лазурна, 42/В",
        lat: 46.94737769357752,
        lon: 31.935345274580033,
        build_s: "6 717,90 м2",
        bld_floor: 10,
        bld_porch: 3,
        bld_apart: 120,
        apart_ind: 6,
        q_2021: "428,667808 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.935345274580033,
          46.94737769357752
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 50",
        adrs_full: "м. Миколаїв, вул. Лазурна, 50",
        lat: 46.945951366591,
        lon: 31.938447525946003,
        build_s: "8 199,40 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 12,
        q_2021: "466,353931 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.938447525946003,
          46.945951366591
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 50/А",
        adrs_full: "м. Миколаїв, вул. Лазурна, 50/А",
        lat: 46.945609798016555,
        lon: 31.937452164573227,
        build_s: "8 187,80 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 13,
        q_2021: "442,830100 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.937452164573227,
          46.94560979801655
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 52",
        adrs_full: "м. Миколаїв, вул. Лазурна, 52",
        lat: 46.94512486955786,
        lon: 31.936150525494917,
        build_s: "8 014,50 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 139,
        apart_ind: 14,
        q_2021: "496,455937 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.936150525494917,
          46.94512486955786
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 52/А",
        adrs_full: "м. Миколаїв, вул. Лазурна, 52/А",
        lat: 46.94532474035967,
        lon: 31.935178239004102,
        build_s: "8 682,10 м2",
        bld_floor: 10,
        bld_porch: 5,
        bld_apart: 184,
        apart_ind: 9,
        q_2021: "563,076212 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.935178239004106,
          46.94532474035967
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 52/Б",
        adrs_full: "м. Миколаїв, вул. Лазурна, 52/Б",
        lat: 46.94608219698237,
        lon: 31.935722585739132,
        build_s: "10 244,40 м2",
        bld_floor: 9,
        bld_porch: 5,
        bld_apart: 180,
        apart_ind: 8,
        q_2021: "684,626195 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.935722585739132,
          46.94608219698237
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 6",
        adrs_full: "м. Миколаїв, вул. Лазурна, 6",
        lat: 46.95390818993507,
        lon: 31.93805207817489,
        build_s: "3 737,80 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 70,
        apart_ind: 2,
        q_2021: "308,116799 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93805207817489,
          46.95390818993507
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 6/А",
        adrs_full: "м. Миколаїв, вул. Лазурна, 6/А",
        lat: 46.953708435811066,
        lon: 31.93888741413579,
        build_s: "7 935,50 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 8,
        q_2021: "490,880582 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93888741413579,
          46.953708435811066
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 6/Б",
        adrs_full: "м. Миколаїв, вул. Лазурна, 6/Б",
        lat: 46.9533055,
        lon: 31.9381107,
        build_s: "7 966,10 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 4,
        q_2021: "645,062574 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9381107,
          46.9533055
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Лазурна, 6/В",
        adrs_full: "м. Миколаїв, вул. Лазурна, 6/В",
        lat: 46.952657628103026,
        lon: 31.93784013265094,
        build_s: "4 011,50 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "300,976873 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93784013265094,
          46.952657628103026
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Ламбертiвська, 51/А",
        adrs_full: "м. Миколаїв, вул. Ламбертiвська, 51/А",
        lat: 47.000126304206525,
        lon: 31.94786456887356,
        build_s: "789,20 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 15,
        apart_ind: 0,
        q_2021: "54,370003 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94786456887356,
          47.000126304206525
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Леваневцiв, 10",
        adrs_full: "м. Миколаїв, вул. Леваневцiв, 10",
        lat: 46.96331572791446,
        lon: 31.94949505079804,
        build_s: "9 310,20 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 9,
        q_2021: "358,615509 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.949495050798042,
          46.96331572791446
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Леваневцiв, 25/178",
        adrs_full: "м. Миколаїв, вул. Леваневцiв, 25/178",
        lat: 46.9635660512685,
        lon: 31.94515046603776,
        build_s: "3 162,00 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 124,
        apart_ind: 0,
        q_2021: "255,478100 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94515046603776,
          46.9635660512685
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Леваневцiв, 25/2",
        adrs_full: "м. Миколаїв, вул. Леваневцiв, 25/2",
        lat: 46.96342895336159,
        lon: 31.946855552331275,
        build_s: "1 177,40 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 18,
        apart_ind: 7,
        q_2021: "104,507981 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.946855552331275,
          46.96342895336159
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Леваневцiв, 25/20",
        adrs_full: "м. Миколаїв, вул. Леваневцiв, 25/20",
        lat: 46.963927185536754,
        lon: 31.947747292864086,
        build_s: "742,20 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 24,
        apart_ind: 3,
        q_2021: "72,276277 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94774729286409,
          46.963927185536754
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Леваневцiв, 25/21",
        adrs_full: "м. Миколаїв, вул. Леваневцiв, 25/21",
        lat: 46.96347576732053,
        lon: 31.94760030266637,
        build_s: "564,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 1,
        q_2021: "92,894700 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94760030266637,
          46.963475767320524
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Гастелло,14/1",
        address: "вул. Леваневцiв, 25/24",
        adrs_full: "м. Миколаїв, вул. Леваневцiв, 25/24",
        lat: 46.96378005705508,
        lon: 31.94760030266637,
        build_s: "583,80 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 2,
        q_2021: "78,988500 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94760030266637,
          46.96378005705508
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Леваневцiв,10(САО)",
        address: "вул. Леваневцiв, 25/29",
        adrs_full: "м. Миколаїв, вул. Леваневцiв, 25/29",
        lat: 46.9641512213112,
        lon: 31.947272024558135,
        build_s: "551,10 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 7,
        q_2021: "45,889800 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.947272024558135,
          46.9641512213112
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Леваневцiв, 25/3",
        adrs_full: "м. Миколаїв, вул. Леваневцiв, 25/3",
        lat: 46.96250269732178,
        lon: 31.94773259384432,
        build_s: "1 052,50 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 18,
        apart_ind: 5,
        q_2021: "98,230008 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94773259384432,
          46.96250269732178
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Леваневцiв, 25/30",
        adrs_full: "м. Миколаїв, вул. Леваневцiв, 25/30",
        lat: 46.9644086941022,
        lon: 31.94759540299312,
        build_s: "521,20 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "77,413900 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94759540299312,
          46.964408694102204
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Леваневцiв, 25/4",
        adrs_full: "м. Миколаїв, вул. Леваневцiв, 25/4",
        lat: 46.96375330637858,
        lon: 31.947183830439506,
        build_s: "995,70 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 18,
        apart_ind: 5,
        q_2021: "97,204248 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94718383043951,
          46.96375330637858
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Леваневцiв, 25/5",
        adrs_full: "м. Миколаїв, вул. Леваневцiв, 25/5",
        lat: 46.96402415636027,
        lon: 31.947022141222018,
        build_s: "3 264,20 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 6,
        q_2021: "273,479859 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.947022141222018,
          46.96402415636026
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Леваневцiв, 25/6",
        adrs_full: "м. Миколаїв, вул. Леваневцiв, 25/6",
        lat: 46.963469079614605,
        lon: 31.947134833706937,
        build_s: "2 843,30 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 5,
        q_2021: "214,220039 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.947134833706937,
          46.96346907961461
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Леваневцiв, 25/9",
        adrs_full: "м. Миколаїв, вул. Леваневцiв, 25/9",
        lat: 46.96318150746954,
        lon: 31.94822256117005,
        build_s: "7 737,20 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 140,
        apart_ind: 13,
        q_2021: "495,055736 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94822256117005,
          46.96318150746954
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Леваневцiв, 8",
        adrs_full: "м. Миколаїв, вул. Леваневцiв, 8",
        lat: 46.964584,
        lon: 31.9492024,
        build_s: "5 440,71 м2",
        bld_floor: 12,
        bld_porch: 1,
        bld_apart: 49,
        apart_ind: 14,
        q_2021: "164,050076 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9492024,
          46.964584
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Листопадова, 1/А",
        adrs_full: "м. Миколаїв, вул. Листопадова, 1/А",
        lat: 46.9643403,
        lon: 32.0649343,
        build_s: "3 417,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 5,
        q_2021: "234,057008 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0649343,
          46.9643403
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Листопадова, 1/Б",
        adrs_full: "м. Миколаїв, вул. Листопадова, 1/Б",
        lat: 46.9644753,
        lon: 32.0642325,
        build_s: "3 741,20 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 6,
        q_2021: "242,021246 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0642325,
          46.9644753
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Листопадова, 28",
        adrs_full: "м. Миколаїв, вул. Листопадова, 28",
        lat: 46.96551529567121,
        lon: 32.071226175086736,
        build_s: "7 612,40 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 143,
        apart_ind: 8,
        q_2021: "416,879550 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.071226175086736,
          46.96551529567121
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Логовенка, 6",
        adrs_full: "м. Миколаїв, вул. Логовенка, 6",
        lat: 46.95867351948193,
        lon: 32.026814870610686,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 18,
        apart_ind: 2,
        q_2021: "112,401000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.026814870610686,
          46.95867351948193
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Генерала Карпенка,20-А",
        address: "вул. Лягіна, 26",
        adrs_full: "м. Миколаїв, вул. Лягіна, 26",
        lat: 46.96600858993511,
        lon: 31.986804454007626,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 30,
        apart_ind: 5,
        q_2021: "142,103000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.986804454007626,
          46.96600858993511
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Леваневцiв,8(САО)",
        address: "вул. Лягіна, 26/А",
        adrs_full: "м. Миколаїв, вул. Лягіна, 26/А",
        lat: 46.96546080356275,
        lon: 31.98648384522899,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 36,
        apart_ind: 8,
        q_2021: "170,488000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98648384522899,
          46.96546080356275
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Листопадова,1-к",
        address: "вул. Лягіна, 29/А",
        adrs_full: "м. Миколаїв, вул. Лягіна, 29/А",
        lat: 46.96619878264865,
        lon: 31.986308421183207,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 109,
        apart_ind: 11,
        q_2021: "433,146000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.986308421183207,
          46.96619878264865
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Листопадова,1-к",
        address: "вул. Лiсова, 5",
        adrs_full: "м. Миколаїв, вул. Лiсова, 5",
        lat: 47.01515615306913,
        lon: 31.913946747929938,
        build_s: "2 788,19 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 6,
        q_2021: "211,986317 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.913946747929938,
          47.01515615306913
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Лiсова, 7",
        adrs_full: "м. Миколаїв, вул. Лiсова, 7",
        lat: 47.015740096616,
        lon: 31.914122049202895,
        build_s: "2 790,50 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 13,
        q_2021: "208,260020 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.914122049202895,
          47.015740096616
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Листопадова,1-к",
        address: "вул. Мала Морська, 11",
        adrs_full: "м. Миколаїв, вул. Мала Морська, 11",
        lat: 46.97086890768959,
        lon: 31.99736484847328,
        build_s: "432,30 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 10,
        apart_ind: 2,
        q_2021: "62,829900 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.997364848473282,
          46.97086890768959
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Мала Морська, 23",
        adrs_full: "м. Миколаїв, вул. Мала Морська, 23",
        lat: 46.9672303,
        lon: 31.9951303,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 58,
        apart_ind: 4,
        q_2021: "225,681000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9951303,
          46.9672303
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Мала Морська, 25",
        adrs_full: "м. Миколаїв, вул. Мала Морська, 25",
        lat: 46.9668902112846,
        lon: 31.99463301946564,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 11,
        apart_ind: 6,
        q_2021: "15,029000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99463301946564,
          46.9668902112846
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Мала Морська, 34",
        adrs_full: "м. Миколаїв, вул. Мала Морська, 34",
        lat: 46.96254587493454,
        lon: 31.99488871946564,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 23,
        apart_ind: 10,
        q_2021: "115,288000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99488871946564,
          46.96254587493454
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Малко-Тирнiвська, 1",
        adrs_full: "м. Миколаїв, вул. Малко-Тирнiвська, 1",
        lat: 47.0171006,
        lon: 31.9980906,
        build_s: "4 147,20 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 64,
        apart_ind: 7,
        q_2021: "256,725765 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9980906,
          47.0171006
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Лiсова,1-Б",
        address: "вул. Малко-Тирнiвська, 75",
        adrs_full: "м. Миколаїв, вул. Малко-Тирнiвська, 75",
        lat: 47.01994774227483,
        lon: 32.00195293893129,
        build_s: "2 336,00 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 13,
        q_2021: "114,640980 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00195293893129,
          47.01994774227483
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Лiсова,1-Б",
        address: "вул. Маршала Василевського, 44/А",
        adrs_full: "м. Миколаїв, вул. Маршала Василевського, 44/А",
        lat: 46.95888361833579,
        lon: 32.03017118435114,
        build_s: "883,40 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 26,
        apart_ind: 1,
        q_2021: "75,070017 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03017118435114,
          46.95888361833579
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Спаська,55",
        address: "вул. Маршала Василевського, 46",
        adrs_full: "м. Миколаїв, вул. Маршала Василевського, 46",
        lat: 46.9584186,
        lon: 32.0287275,
        build_s: "968,40 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 32,
        apart_ind: 0,
        q_2021: "74,934999 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0287275,
          46.95841859999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Маршала Василевського, 46/А",
        adrs_full: "м. Миколаїв, вул. Маршала Василевського, 46/А",
        lat: 46.958614868752825,
        lon: 32.029312075954195,
        build_s: "832,60 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 25,
        apart_ind: 0,
        q_2021: "81,220585 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.029312075954195,
          46.958614868752825
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Маршала Василевського, 55/А",
        adrs_full: "м. Миколаїв, вул. Маршала Василевського, 55/А",
        lat: 46.9600182,
        lon: 32.0277229,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 19,
        apart_ind: 0,
        q_2021: "74,647000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0277229,
          46.9600182
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Маршала Василевського, 57",
        adrs_full: "м. Миколаїв, вул. Маршала Василевського, 57",
        lat: 46.9592047199637,
        lon: 32.0282535316794,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 26,
        apart_ind: 0,
        q_2021: "64,567000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0282535316794,
          46.95920471996371
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Малко-Тирнiвська,1-А",
        address: "вул. Маршала Василевського, 59",
        adrs_full: "м. Миколаїв, вул. Маршала Василевського, 59",
        lat: 46.9585976,
        lon: 32.028154,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 46,
        apart_ind: 0,
        q_2021: "95,625000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.028154,
          46.9585976
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Малко-Тирнiвська,1-А",
        address: "вул. Млинна, 31",
        adrs_full: "м. Миколаїв, вул. Млинна, 31",
        lat: 46.95111094861718,
        lon: 32.012702778744305,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 49,
        apart_ind: 5,
        q_2021: "163,426000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.012702778744305,
          46.95111094861718
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,242-А/1",
        address: "вул. Металургiв, 10",
        adrs_full: "м. Миколаїв, вул. Металургiв, 10",
        lat: 46.86746130553476,
        lon: 32.00959957399682,
        build_s: "4 153,52 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 4,
        q_2021: "245,169972 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00959957399682,
          46.86746130553476
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,242-А/1",
        address: "вул. Металургiв, 26/А",
        adrs_full: "м. Миколаїв, вул. Металургiв, 26/А",
        lat: 46.86738202036914,
        lon: 32.01095335454545,
        build_s: "3 990,00 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 90,
        apart_ind: 7,
        q_2021: "247,427947 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01095335454545,
          46.86738202036914
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,242-А/1",
        address: "вул. Металургiв, 28",
        adrs_full: "м. Миколаїв, вул. Металургiв, 28",
        lat: 46.86769984173997,
        lon: 32.01189167272727,
        build_s: "5 169,00 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 176,
        apart_ind: 0,
        q_2021: "353,731698 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01189167272727,
          46.86769984173997
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Металургiв, 32",
        adrs_full: "м. Миколаїв, вул. Металургiв, 32",
        lat: 46.86759281380881,
        lon: 32.01327385454547,
        build_s: "4 955,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 2,
        q_2021: "324,000065 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01327385454547,
          46.86759281380881
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Металургiв, 34",
        adrs_full: "м. Миколаїв, вул. Металургiв, 34",
        lat: 46.8675640989617,
        lon: 32.014258945454564,
        build_s: "3 908,86 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 2,
        q_2021: "321,164529 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.014258945454564,
          46.8675640989617
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Металургiв, 34/А",
        adrs_full: "м. Миколаїв, вул. Металургiв, 34/А",
        lat: 46.86709433090343,
        lon: 32.01416565460378,
        build_s: "3 531,00 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 90,
        apart_ind: 0,
        q_2021: "286,809923 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01416565460378,
          46.86709433090343
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Металургiв, 36",
        adrs_full: "м. Миколаїв, вул. Металургiв, 36",
        lat: 46.86748317521903,
        lon: 32.01506076363639,
        build_s: "3 990,08 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "284,456940 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01506076363639,
          46.86748317521903
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Металургiв, 6",
        adrs_full: "м. Миколаїв, вул. Металургiв, 6",
        lat: 46.86781227917602,
        lon: 32.00870940950098,
        build_s: "6 583,90 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 158,
        apart_ind: 1,
        q_2021: "505,086235 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00870940950098,
          46.86781227917602
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Металургiв, 72",
        adrs_full: "м. Миколаїв, вул. Металургiв, 72",
        lat: 46.867326941003896,
        lon: 32.01837709506654,
        build_s: "4 306,20 м2",
        bld_floor: 14,
        bld_porch: 1,
        bld_apart: 69,
        apart_ind: 0,
        q_2021: "337,570160 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01837709506654,
          46.867326941003896
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Металургiв, 8",
        adrs_full: "м. Миколаїв, вул. Металургiв, 8",
        lat: 46.86800591434094,
        lon: 32.010285172727265,
        build_s: "9 111,91 м2",
        bld_floor: 9,
        bld_porch: 5,
        bld_apart: 168,
        apart_ind: 11,
        q_2021: "631,574318 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.010285172727265,
          46.86800591434095
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Миколаївська, 10",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 10",
        lat: 46.9497505,
        lon: 32.0415039,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "49,807000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0415039,
          46.9497505
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Миколаївська, 10/А",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 10/А",
        lat: 46.950054656858775,
        lon: 32.04217299708579,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 1,
        q_2021: "50,417000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04217299708579,
          46.950054656858775
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Миколаївська, 11",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 11",
        lat: 46.94701296166905,
        lon: 32.04231926314059,
        build_s: "5 848,40 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 119,
        apart_ind: 5,
        q_2021: "316,930245 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04231926314059,
          46.94701296166905
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Миколаївська, 12",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 12",
        lat: 46.94937211324754,
        lon: 32.04168953867489,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 1,
        q_2021: "56,887000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04168953867489,
          46.94937211324754
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Миколаївська, 12/А",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 12/А",
        lat: 46.94969056859268,
        lon: 32.04200893990427,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "53,353000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04200893990427,
          46.94969056859268
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Металургiв,72/1",
        address: "вул. Миколаївська, 15",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 15",
        lat: 46.9438858,
        lon: 32.0440836,
        build_s: "3 818,90 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 148,
        apart_ind: 0,
        q_2021: "205,286700 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0440836,
          46.9438858
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Миколаївська, 15/А",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 15/А",
        lat: 46.94355619933533,
        lon: 32.043071060721715,
        build_s: "4 239,00 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 60,
        apart_ind: 7,
        q_2021: "196,220963 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.043071060721715,
          46.94355619933533
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Миколаївська, 17/А",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 17/А",
        lat: 46.9430422,
        lon: 32.042735,
        build_s: "1 975,00 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 116,
        apart_ind: 5,
        q_2021: "177,541168 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.042735,
          46.9430422
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Миколаївська, 19",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 19",
        lat: 46.9427938,
        lon: 32.044273,
        build_s: "3 380,40 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 59,
        apart_ind: 1,
        q_2021: "208,885065 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.044273,
          46.9427938
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Миколаївська, 19/А",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 19/А",
        lat: 46.9426537,
        lon: 32.0433718,
        build_s: "4 174,40 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 127,
        apart_ind: 0,
        q_2021: "255,323860 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0433718,
          46.9426537
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Миколаївська, 20",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 20",
        lat: 46.94277847357022,
        lon: 32.04616296915171,
        build_s: "3 115,10 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 2,
        q_2021: "252,632538 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04616296915171,
          46.94277847357022
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Миколаївська, 22",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 22",
        lat: 46.94225915425334,
        lon: 32.0456967648327,
        build_s: "4 447,40 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 100,
        apart_ind: 4,
        q_2021: "384,395509 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0456967648327,
          46.94225915425334
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Миколаївська, 24",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 24",
        lat: 46.9414139,
        lon: 32.0460595,
        build_s: "4 204,90 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 80,
        apart_ind: 0,
        q_2021: "177,369847 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0460595,
          46.9414139
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Миколаївська, 25",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 25",
        lat: 46.94017690705219,
        lon: 32.046065265059,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 66,
        apart_ind: 0,
        q_2021: "227,873000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.046065265059,
          46.94017690705219
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Миколаївська, 25/А",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 25/А",
        lat: 46.940293693363856,
        lon: 32.045384065937206,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 40,
        apart_ind: 1,
        q_2021: "147,315000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.045384065937206,
          46.940293693363856
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Миколаївська, 26",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 26",
        lat: 46.94110703128637,
        lon: 32.04664038233865,
        build_s: "3 054,70 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 56,
        apart_ind: 3,
        q_2021: "204,450314 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04664038233865,
          46.94110703128637
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Миколаївська, 28",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 28",
        lat: 46.941428521657,
        lon: 32.04743476308119,
        build_s: "3 188,70 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 0,
        q_2021: "249,256998 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04743476308119,
          46.941428521657
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Миколаївська, 3",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 3",
        lat: 46.95068798049745,
        lon: 32.0404494112769,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 7,
        q_2021: "215,550000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0404494112769,
          46.95068798049745
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Миколаївська, 30",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 30",
        lat: 46.94188130443926,
        lon: 32.04770794378905,
        build_s: "3 236,80 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "271,272732 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04770794378905,
          46.94188130443926
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Миколаївська, 30/А",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 30/А",
        lat: 46.94224450954011,
        lon: 32.04903071353228,
        build_s: "4 278,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 107,
        apart_ind: 1,
        q_2021: "277,999559 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04903071353228,
          46.94224450954011
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Миколаївська, 32",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 32",
        lat: 46.94234990417809,
        lon: 32.0481960720091,
        build_s: "3 238,70 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 5,
        q_2021: "220,800068 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0481960720091,
          46.94234990417809
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Миколаївська, 34",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 34",
        lat: 46.94283103149383,
        lon: 32.04864250936851,
        build_s: "3 193,50 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 0,
        q_2021: "252,470112 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04864250936851,
          46.94283103149383
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Миколаївська, 34/А",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 34/А",
        lat: 46.94281876122589,
        lon: 32.049882606002804,
        build_s: "7 803,70 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 5,
        q_2021: "443,872700 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.049882606002804,
          46.94281876122589
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Миколаївська, 34/Б",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 34/Б",
        lat: 46.94325067296503,
        lon: 32.04914573698822,
        build_s: "5 758,40 м2",
        bld_floor: 15,
        bld_porch: 1,
        bld_apart: 116,
        apart_ind: 0,
        q_2021: "443,534245 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04914573698822,
          46.94325067296503
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Миколаївська, 36",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 36",
        lat: 46.94322120725869,
        lon: 32.047834995168024,
        build_s: "4 488,10 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 100,
        apart_ind: 2,
        q_2021: "324,045236 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.047834995168024,
          46.94322120725869
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Миколаївська, 38",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 38",
        lat: 46.94264684933516,
        lon: 32.047218063136846,
        build_s: "3 176,60 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "221,610017 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.047218063136846,
          46.94264684933516
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Миколаївська, 4",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 4",
        lat: 46.95111890277522,
        lon: 32.04074821855034,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 1,
        q_2021: "49,237000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04074821855034,
          46.95111890277522
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Миколаївська, 4/А",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 4/А",
        lat: 46.951056057746825,
        lon: 32.04125457115086,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "54,715000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04125457115086,
          46.951056057746825
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Миколаївська, 40",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 40",
        lat: 46.940699643768845,
        lon: 32.047258633414295,
        build_s: "4 625,55 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 6,
        q_2021: "247,975300 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.047258633414295,
          46.94069964376885
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Миколаївська, 5",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 5",
        lat: 46.94954944621226,
        lon: 32.04107042175256,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 2,
        q_2021: "240,539000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04107042175256,
          46.94954944621226
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Миколаївська, 6",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 6",
        lat: 46.95073055327496,
        lon: 32.040976058049964,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "52,259000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.040976058049964,
          46.95073055327496
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Миколаївська, 6/А",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 6/А",
        lat: 46.9508126,
        lon: 32.0417483,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "58,520000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0417483,
          46.9508126
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Миколаївська, 8",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 8",
        lat: 46.95025846913194,
        lon: 32.041218823668046,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 1,
        q_2021: "99,109000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.041218823668046,
          46.95025846913194
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Миколаївська, 8/А",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 8/А",
        lat: 46.9504075,
        lon: 32.0419655,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 2,
        q_2021: "51,530000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0419655,
          46.9504075
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Миколаївська, 9",
        adrs_full: "м. Миколаїв, вул. Миколаївська, 9",
        lat: 46.948216,
        lon: 32.0418275,
        build_s: "5 813,10 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 119,
        apart_ind: 4,
        q_2021: "323,471842 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0418275,
          46.948216
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Молодогвардійська, 28",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 28",
        lat: 46.94007675424652,
        lon: 32.03578300237679,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 40,
        apart_ind: 2,
        q_2021: "154,935000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03578300237679,
          46.94007675424652
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 30",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 30",
        lat: 46.94038909102101,
        lon: 32.03617985277634,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 1,
        q_2021: "230,882000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03617985277634,
          46.94038909102101
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 32",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 32",
        lat: 46.9404822,
        lon: 32.0366222,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 1,
        q_2021: "266,695000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0366222,
          46.9404822
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 32/А",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 32/А",
        lat: 46.94107671379119,
        lon: 32.03639857503873,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 32,
        apart_ind: 1,
        q_2021: "128,931000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03639857503873,
          46.94107671379119
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 34",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 34",
        lat: 46.9405826,
        lon: 32.0371107,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 1,
        q_2021: "223,028000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0371107,
          46.9405826
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 34/А",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 34/А",
        lat: 46.94122966507624,
        lon: 32.03704916141031,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 32,
        apart_ind: 0,
        q_2021: "119,782000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03704916141031,
          46.94122966507624
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Молодогвардійська, 36",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 36",
        lat: 46.94057176192578,
        lon: 32.037801018301984,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 38,
        apart_ind: 0,
        q_2021: "160,475000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.037801018301984,
          46.94057176192578
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 40",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 40",
        lat: 46.94114997858502,
        lon: 32.040154137768894,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 1,
        q_2021: "61,270000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.040154137768894,
          46.94114997858502
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 40-А",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 40-А",
        lat: 46.94164477946042,
        lon: 32.03935646099714,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: null,
        apart_ind: null,
        q_2021: "157,852500 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03935646099714,
          46.94164477946042
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 42",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 42",
        lat: 46.94134385453293,
        lon: 32.04060344814363,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 1,
        q_2021: "88,667000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04060344814363,
          46.941343854532924
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 51",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 51",
        lat: 46.93968111410631,
        lon: 32.03613082907703,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 1,
        q_2021: "234,639000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03613082907703,
          46.93968111410631
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 53",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 53",
        lat: 46.93988300256382,
        lon: 32.03693386941356,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 59,
        apart_ind: 3,
        q_2021: "201,809000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03693386941356,
          46.93988300256382
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 53/А",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 53/А",
        lat: 46.939360945929096,
        lon: 32.03676439241842,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 40,
        apart_ind: 2,
        q_2021: "168,829000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03676439241842,
          46.939360945929096
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 53/Б",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 53/Б",
        lat: 46.939355563875424,
        lon: 32.03728464738025,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 40,
        apart_ind: 2,
        q_2021: "150,295000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03728464738025,
          46.939355563875424
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 53/В",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 53/В",
        lat: 46.93961390184139,
        lon: 32.03771425185631,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 32,
        apart_ind: 0,
        q_2021: "136,224000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03771425185631,
          46.93961390184139
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 55",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 55",
        lat: 46.940141337984926,
        lon: 32.037931024757064,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 57,
        apart_ind: 3,
        q_2021: "207,369000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.037931024757064,
          46.940141337984926
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 57",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 57",
        lat: 46.940616781047694,
        lon: 32.03976063995451,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 1,
        q_2021: "57,634000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03976063995451,
          46.940616781047694
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 57-А",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 57-А",
        lat: 46.9400711008638,
        lon: 32.04039574863809,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: null,
        q_2021: "171,251200 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04039574863809,
          46.9400711008638
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 57/Б",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 57/Б",
        lat: 46.93975663955685,
        lon: 32.04057447743754,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 44,
        apart_ind: 4,
        q_2021: "209,324000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04057447743754,
          46.93975663955685
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 59",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 59",
        lat: 46.94075506697804,
        lon: 32.040300749395065,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 2,
        q_2021: "55,083000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.040300749395065,
          46.94075506697804
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 61",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 61",
        lat: 46.94078230507374,
        lon: 32.04085313405017,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 1,
        q_2021: "95,054000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04085313405017,
          46.94078230507373
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 63",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 63",
        lat: 46.94040785402143,
        lon: 32.041023017982305,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 2,
        q_2021: "51,536000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.041023017982305,
          46.94040785402143
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардійська, 65",
        adrs_full: "м. Миколаїв, вул. Молодогвардійська, 65",
        lat: 46.940397293850054,
        lon: 32.04151624072693,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 4,
        q_2021: "59,047000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04151624072693,
          46.940397293850054
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Молодогвардійський, 6",
        adrs_full: "м. Миколаїв, пров. Молодогвардійський, 6",
        lat: 46.93802247825841,
        lon: 32.03470698720137,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 16,
        apart_ind: 1,
        q_2021: "75,946000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03470698720137,
          46.93802247825841
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Молодогвардійський, 8",
        adrs_full: "м. Миколаїв, пров. Молодогвардійський, 8",
        lat: 46.937744238518896,
        lon: 32.034996283661215,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 16,
        apart_ind: 3,
        q_2021: "67,145000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.034996283661215,
          46.937744238518896
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардiйська, 26/А",
        adrs_full: "м. Миколаїв, вул. Молодогвардiйська, 26/А",
        lat: 46.93926137784862,
        lon: 32.03255899814364,
        build_s: "3 483,00 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 56,
        apart_ind: 13,
        q_2021: "177,759981 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03255899814364,
          46.93926137784862
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардiйська, 28/А",
        adrs_full: "м. Миколаїв, вул. Молодогвардiйська, 28/А",
        lat: 46.93958430067937,
        lon: 32.03384781157181,
        build_s: "4 904,80 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 101,
        apart_ind: 2,
        q_2021: "322,879831 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03384781157181,
          46.939584300679364
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Молодогвардiйська, 30/А",
        adrs_full: "м. Миколаїв, вул. Молодогвардiйська, 30/А",
        lat: 46.940973,
        lon: 32.03586,
        build_s: "3 224,10 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 7,
        q_2021: "247,170115 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03586,
          46.940973
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Молодiжний, 2",
        adrs_full: "м. Миколаїв, пров. Молодiжний, 2",
        lat: 46.918240721581554,
        lon: 32.05368063711786,
        build_s: "715,48 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "78,089962 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05368063711786,
          46.918240721581554
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Молодiжний, 4",
        adrs_full: "м. Миколаїв, пров. Молодiжний, 4",
        lat: 46.91827220600144,
        lon: 32.053927242556995,
        build_s: "735,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 1,
        q_2021: "69,460043 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.053927242556995,
          46.91827220600144
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Молодiжний, 6",
        adrs_full: "м. Миколаїв, пров. Молодiжний, 6",
        lat: 46.91829424508433,
        lon: 32.05417615271987,
        build_s: "1 083,15 м2",
        bld_floor: 2,
        bld_porch: 4,
        bld_apart: 20,
        apart_ind: 1,
        q_2021: "127,539990 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05417615271987,
          46.91829424508434
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Морехідна, 9",
        adrs_full: "м. Миколаїв, вул. Морехідна, 9",
        lat: 46.966195855556805,
        lon: 31.965809049617118,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 2,
        q_2021: "168,117000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.965809049617118,
          46.966195855556805
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Полярний,2-к",
        address: "вул. Морехiдна, 1",
        adrs_full: "м. Миколаїв, вул. Морехiдна, 1",
        lat: 46.96531107327811,
        lon: 31.96240285120323,
        build_s: "1 593,38 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 32,
        apart_ind: 1,
        q_2021: "122,865968 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.962402851203226,
          46.965311073278116
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Полярний,2-к",
        address: "вул. Морехiдна, 10",
        adrs_full: "м. Миколаїв, вул. Морехiдна, 10",
        lat: 46.966020501423806,
        lon: 31.96296832871362,
        build_s: "1 914,20 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 38,
        apart_ind: 2,
        q_2021: "141,111055 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96296832871362,
          46.966020501423806
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Полярний,2-к",
        address: "вул. Морехiдна, 3",
        adrs_full: "м. Миколаїв, вул. Морехiдна, 3",
        lat: 46.9655832,
        lon: 31.9630588,
        build_s: "1 560,50 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 32,
        apart_ind: 4,
        q_2021: "90,987099 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9630588,
          46.9655832
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Вiльна,38-к",
        address: "вул. Морехiдна, 5",
        adrs_full: "м. Миколаїв, вул. Морехiдна, 5",
        lat: 46.96573354022143,
        lon: 31.9637481772808,
        build_s: "1 553,20 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 32,
        apart_ind: 3,
        q_2021: "106,500038 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9637481772808,
          46.96573354022143
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Вiльна,38-к",
        address: "вул. Морехiдна, 5/А",
        adrs_full: "м. Миколаїв, вул. Морехiдна, 5/А",
        lat: 46.965407707267865,
        lon: 31.964289292376066,
        build_s: "1 962,40 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 4,
        q_2021: "138,356089 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.964289292376066,
          46.965407707267865
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Вiльна,38-к",
        address: "вул. Морехiдна, 7",
        adrs_full: "м. Миколаїв, вул. Морехiдна, 7",
        lat: 46.96588598742304,
        lon: 31.964411377510388,
        build_s: "1 904,70 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 3,
        q_2021: "154,415999 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.964411377510388,
          46.96588598742304
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Московська, 13",
        adrs_full: "м. Миколаїв, вул. Московська, 13",
        lat: 46.97059658305923,
        lon: 31.993407765907488,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 100,
        apart_ind: 32,
        q_2021: "367,801000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.993407765907488,
          46.97059658305923
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пров.Транспортний,6/2",
        address: "вул. Московська, 16",
        adrs_full: "м. Миколаїв, вул. Московська, 16",
        lat: 46.97134479121763,
        lon: 31.99447701133229,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 8,
        q_2021: "-2,208000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99447701133229,
          46.97134479121763
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пров.Транспортний,6/2",
        address: "вул. Московська, 31",
        adrs_full: "м. Миколаїв, вул. Московська, 31",
        lat: 46.96962148028054,
        lon: 31.992817787551953,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 23,
        apart_ind: 5,
        q_2021: "136,215000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.992817787551953,
          46.96962148028054
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пров.Транспортний,6/2",
        address: "вул. Московська, 33",
        adrs_full: "м. Миколаїв, вул. Московська, 33",
        lat: 46.96901680089093,
        lon: 31.99232907827614,
        build_s: "?",
        bld_floor: 1,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 6,
        q_2021: "36,807000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99232907827614,
          46.96901680089093
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пров.Транспортний,6/2",
        address: "вул. Московська, 36",
        adrs_full: "м. Миколаїв, вул. Московська, 36",
        lat: 46.96774368325428,
        lon: 31.99221891356375,
        build_s: "?",
        bld_floor: 1,
        bld_porch: "?",
        bld_apart: 27,
        apart_ind: 4,
        q_2021: "144,460000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.992218913563754,
          46.96774368325428
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пров.Транспортний,6/2",
        address: "вул. Московська, 4/А",
        adrs_full: "м. Миколаїв, вул. Московська, 4/А",
        lat: 46.973804960269874,
        lon: 31.99612131133229,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 30,
        apart_ind: 5,
        q_2021: "112,142000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99612131133229,
          46.973804960269874
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пров.Транспортний,6/2",
        address: "вул. Московська, 42",
        adrs_full: "м. Миколаїв, вул. Московська, 42",
        lat: 46.965981894014405,
        lon: 31.99141241813844,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 42,
        apart_ind: 27,
        q_2021: "113,566000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.991412418138438,
          46.965981894014405
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Московська, 5",
        adrs_full: "м. Миколаїв, вул. Московська, 5",
        lat: 46.97364372556353,
        lon: 31.995273054412554,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 14,
        apart_ind: 1,
        q_2021: "36,489000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.995273054412554,
          46.97364372556353
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Московська, 54/А",
        adrs_full: "м. Миколаїв, вул. Московська, 54/А",
        lat: 46.96316094677731,
        lon: 31.99084726505899,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 1,
        q_2021: "260,950000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99084726505899,
          46.96316094677731
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Московська, 56",
        adrs_full: "м. Миколаїв, вул. Московська, 56",
        lat: 46.96220131467958,
        lon: 31.990250808431636,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 4,
        q_2021: "20,750000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.990250808431636,
          46.96220131467958
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Московська, 7",
        adrs_full: "м. Миколаїв, вул. Московська, 7",
        lat: 46.96353567298869,
        lon: 31.989915857702982,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 38,
        apart_ind: 6,
        q_2021: "130,189000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.989915857702982,
          46.96353567298869
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Мостобудiвникiв, 1",
        adrs_full: "м. Миколаїв, вул. Мостобудiвникiв, 1",
        lat: 46.9825023832697,
        lon: 31.97374289424959,
        build_s: "533,80 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 2,
        q_2021: "50,763983 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97374289424959,
          46.9825023832697
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Мостобудiвникiв, 1/А",
        adrs_full: "м. Миколаїв, вул. Мостобудiвникiв, 1/А",
        lat: 46.98263703145651,
        lon: 31.973308685847925,
        build_s: "659,50 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 2,
        q_2021: "62,755603 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.973308685847925,
          46.98263703145651
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Мостобудiвникiв, 10",
        adrs_full: "м. Миколаїв, вул. Мостобудiвникiв, 10",
        lat: 46.98266396105317,
        lon: 31.974887625490354,
        build_s: "467,30 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "47,050012 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.974887625490354,
          46.98266396105317
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Мостобудiвникiв, 3",
        adrs_full: "м. Миколаїв, вул. Мостобудiвникiв, 3",
        lat: 46.98237997553288,
        lon: 31.974184279649627,
        build_s: "536,70 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 4,
        q_2021: "53,120782 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.974184279649627,
          46.98237997553288
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Мостобудiвникiв, 4",
        adrs_full: "м. Миколаїв, вул. Мостобудiвникiв, 4",
        lat: 46.983111969625874,
        lon: 31.97358500028535,
        build_s: "486,70 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 2,
        q_2021: "46,630002 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97358500028535,
          46.983111969625874
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Мостобудiвникiв, 5",
        adrs_full: "м. Миколаїв, вул. Мостобудiвникiв, 5",
        lat: 46.9822497,
        lon: 31.9745972,
        build_s: "531,70 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 15,
        apart_ind: 0,
        q_2021: "66,049094 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9745972,
          46.9822497
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Мостобудiвникiв, 6",
        adrs_full: "м. Миколаїв, вул. Мостобудiвникiв, 6",
        lat: 46.98292346374405,
        lon: 31.974022797186198,
        build_s: "462,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 2,
        q_2021: "36,170020 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.974022797186198,
          46.98292346374405
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Мостобудiвникiв, 8",
        adrs_full: "м. Миколаїв, вул. Мостобудiвникiв, 8",
        lat: 46.982788816278656,
        lon: 31.974467771085433,
        build_s: "461,70 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 2,
        q_2021: "39,250008 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.974467771085433,
          46.982788816278656
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Набережна, 17/3",
        adrs_full: "м. Миколаїв, вул. Набережна, 17/3",
        lat: 46.97478313861387,
        lon: 31.99893694627888,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 17,
        apart_ind: 4,
        q_2021: "69,582000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99893694627888,
          46.97478313861387
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Набережна, 17/4",
        adrs_full: "м. Миколаїв, вул. Набережна, 17/4",
        lat: 46.974596741580534,
        lon: 31.999548008388537,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 16,
        apart_ind: 1,
        q_2021: "68,284000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.999548008388537,
          46.974596741580534
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Набережна, 17/5",
        adrs_full: "м. Миколаїв, вул. Набережна, 17/5",
        lat: 46.97436129176766,
        lon: 32.000295660852096,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 17,
        apart_ind: 2,
        q_2021: "54,269000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.000295660852096,
          46.97436129176766
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Набережна, 17/6",
        adrs_full: "м. Миколаїв, вул. Набережна, 17/6",
        lat: 46.974179798496046,
        lon: 32.00086358916577,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 16,
        apart_ind: 6,
        q_2021: "43,913000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00086358916577,
          46.974179798496046
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Набережна, 25/1",
        adrs_full: "м. Миколаїв, вул. Набережна, 25/1",
        lat: 46.97316440585709,
        lon: 32.00274709825669,
        build_s: "422,85 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 12,
        apart_ind: 1,
        q_2021: "40,519986 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00274709825669,
          46.97316440585709
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Набережна, 25/2",
        adrs_full: "м. Миколаїв, вул. Набережна, 25/2",
        lat: 46.97327232289858,
        lon: 32.0023660830589,
        build_s: "438,80 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 13,
        apart_ind: 0,
        q_2021: "46,569992 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0023660830589,
          46.97327232289859
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Набережна, 25/3",
        adrs_full: "м. Миколаїв, вул. Набережна, 25/3",
        lat: 46.97351268279925,
        lon: 32.002797421018656,
        build_s: "3 933,60 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 106,
        apart_ind: 0,
        q_2021: "143,971400 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.002797421018656,
          46.97351268279925
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "вул. Набережна, 27",
        adrs_full: "м. Миколаїв, вул. Набережна, 27",
        lat: 46.97306629926674,
        lon: 32.00336534933233,
        build_s: "2 510,50 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "194,419882 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00336534933233,
          46.97306629926675
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Набережна, 3",
        adrs_full: "м. Миколаїв, вул. Набережна, 3",
        lat: 47.0356555,
        lon: 32.0201571,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 37,
        apart_ind: 27,
        q_2021: "26,569000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0201571,
          47.0356555
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Набережна, 5",
        adrs_full: "м. Миколаїв, вул. Набережна, 5",
        lat: 47.0354149,
        lon: 32.01971,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 21,
        apart_ind: 14,
        q_2021: "64,770000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01971,
          47.0354149
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Набережна, 5/11",
        adrs_full: "м. Миколаїв, вул. Набережна, 5/11",
        lat: 47.03473747843722,
        lon: 32.020567218623064,
        build_s: "2 750,50 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 42,
        apart_ind: 17,
        q_2021: "163,553676 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.020567218623064,
          47.03473747843722
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Набережна, 7",
        adrs_full: "м. Миколаїв, вул. Набережна, 7",
        lat: 47.035270320535645,
        lon: 32.019580298963255,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 40,
        apart_ind: 9,
        q_2021: "157,951000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.019580298963255,
          47.03527032053565
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Набережна,25/1",
        address: "вул. Наваринська, 15",
        adrs_full: "м. Миколаїв, вул. Наваринська, 15",
        lat: 46.97062709630792,
        lon: 31.97971494446359,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 8,
        q_2021: "233,084000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97971494446359,
          46.97062709630792
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Набережна,25/1",
        address: "вул. Наваринська, 15/А",
        adrs_full: "м. Миколаїв, вул. Наваринська, 15/А",
        lat: 46.9704061310184,
        lon: 31.979934421214935,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 16,
        apart_ind: 2,
        q_2021: "56,417000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.979934421214935,
          46.9704061310184
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Набережна,25/1",
        address: "вул. Наваринська, 17",
        adrs_full: "м. Миколаїв, вул. Наваринська, 17",
        lat: 46.9701389064748,
        lon: 31.980005129296806,
        build_s: "?",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: null,
        apart_ind: null,
        q_2021: "207,890000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.980005129296806,
          46.9701389064748
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Набережна,25/1",
        address: "вул. Наваринська, 17/А",
        adrs_full: "м. Миколаїв, вул. Наваринська, 17/А",
        lat: 46.96992635620067,
        lon: 31.979448088369683,
        build_s: "?",
        bld_floor: 10,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 2,
        q_2021: "241,927000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.979448088369683,
          46.96992635620067
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Нагiрна, 11",
        adrs_full: "м. Миколаїв, вул. Нагiрна, 11",
        lat: 46.96618500168611,
        lon: 32.05738066966643,
        build_s: "4 766,30 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 3,
        q_2021: "275,403116 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05738066966643,
          46.96618500168611
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Нагiрна, 87",
        adrs_full: "м. Миколаїв, вул. Нагiрна, 87",
        lat: 46.9606083509549,
        lon: 32.068932285567925,
        build_s: "4 748,60 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 108,
        apart_ind: 1,
        q_2021: "242,700243 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.068932285567925,
          46.9606083509549
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот. Набережна,5/11(САО)",
        address: "вул. Нагiрна, 87/1",
        adrs_full: "м. Миколаїв, вул. Нагiрна, 87/1",
        lat: 46.96050275898944,
        lon: 32.06920573201222,
        build_s: "4 404,80 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "275,290143 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06920573201222,
          46.96050275898944
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Нагiрна, 89",
        adrs_full: "м. Миколаїв, вул. Нагiрна, 89",
        lat: 46.96056908588427,
        lon: 32.07023345327807,
        build_s: "4 306,90 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 5,
        q_2021: "261,610059 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07023345327807,
          46.96056908588427
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Нагiрна, 91",
        adrs_full: "м. Миколаїв, вул. Нагiрна, 91",
        lat: 46.960976693389,
        lon: 32.07027073395315,
        build_s: "3 995,28 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 5,
        q_2021: "227,160000 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07027073395315,
          46.960976693389
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Нацiональної Гвардiї, 2",
        adrs_full: "м. Миколаїв, вул. Нацiональної Гвардiї, 2",
        lat: 46.97953593796061,
        lon: 31.990308815436343,
        build_s: "93,60 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 3,
        apart_ind: 0,
        q_2021: "18,248500 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.990308815436343,
          46.97953593796061
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Нікольська, 13",
        adrs_full: "м. Миколаїв, вул. Нікольська, 13",
        lat: 46.97822198935694,
        lon: 31.979376383917412,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 23,
        apart_ind: 3,
        q_2021: "124,826000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.979376383917412,
          46.97822198935694
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Нікольська, 15",
        adrs_full: "м. Миколаїв, вул. Нікольська, 15",
        lat: 46.9780337,
        lon: 31.979943,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 22,
        apart_ind: 8,
        q_2021: "106,237000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.979943,
          46.9780337
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Листопадова,1-к",
        address: "вул. Нікольська, 17",
        adrs_full: "м. Миколаїв, вул. Нікольська, 17",
        lat: 46.97727835282527,
        lon: 31.981520934549486,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 2,
        q_2021: "339,464000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.981520934549486,
          46.97727835282527
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Нікольська, 23",
        adrs_full: "м. Миколаїв, вул. Нікольська, 23",
        lat: 46.976686650301446,
        lon: 31.98315672723804,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 18,
        apart_ind: 2,
        q_2021: "79,227000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98315672723804,
          46.976686650301446
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Нікольська, 40",
        adrs_full: "м. Миколаїв, вул. Нікольська, 40",
        lat: 46.975802,
        lon: 31.9884611,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "87,840000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9884611,
          46.975802
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Нікольська, 44",
        adrs_full: "м. Миколаїв, вул. Нікольська, 44",
        lat: 46.97546768512592,
        lon: 31.989477681158913,
        build_s: "?",
        bld_floor: 1,
        bld_porch: "?",
        bld_apart: 6,
        apart_ind: 5,
        q_2021: "9,076000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.989477681158913,
          46.97546768512592
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Нікольська, 48",
        adrs_full: "м. Миколаїв, вул. Нікольська, 48",
        lat: 46.9749497,
        lon: 31.9911287,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 13,
        apart_ind: 2,
        q_2021: "79,521000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9911287,
          46.9749497
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Нікольська, 49",
        adrs_full: "м. Миколаїв, вул. Нікольська, 49",
        lat: 46.97345259320532,
        lon: 31.994090750208187,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 31,
        apart_ind: 7,
        q_2021: "157,792000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.994090750208187,
          46.97345259320532
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Нікольська, 51",
        adrs_full: "м. Миколаїв, вул. Нікольська, 51",
        lat: 46.97342072814554,
        lon: 31.994554156523243,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 35,
        apart_ind: 7,
        q_2021: "137,584000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.994554156523243,
          46.97342072814554
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Нікольська, 53",
        adrs_full: "м. Миколаїв, вул. Нікольська, 53",
        lat: 46.97308953263567,
        lon: 31.99500001169327,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 35,
        apart_ind: 7,
        q_2021: "164,259000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99500001169327,
          46.973089532635676
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Нікольська, 54",
        adrs_full: "м. Миколаїв, вул. Нікольська, 54",
        lat: 46.97397312444026,
        lon: 31.994216674818016,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 32,
        apart_ind: 5,
        q_2021: "148,897000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.994216674818016,
          46.97397312444026
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Нікольська, 56",
        adrs_full: "м. Миколаїв, вул. Нікольська, 56",
        lat: 46.97385686624486,
        lon: 31.994801659217146,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 44,
        apart_ind: 5,
        q_2021: "239,837000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.994801659217142,
          46.97385686624486
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Нікольська, 80",
        adrs_full: "м. Миколаїв, вул. Нікольська, 80",
        lat: 46.97199855993985,
        lon: 32.00110501939625,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 8,
        q_2021: "330,250000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00110501939625,
          46.97199855993985
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Новобудiвна, 1",
        adrs_full: "м. Миколаїв, вул. Новобудiвна, 1",
        lat: 47.03676762168734,
        lon: 31.99790206549271,
        build_s: "8 257,32 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 7,
        q_2021: "417,501186 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99790206549271,
          47.03676762168734
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Новобудiвна, 2",
        adrs_full: "м. Миколаїв, вул. Новобудiвна, 2",
        lat: 47.03686236758362,
        lon: 31.998330992921545,
        build_s: "378,68 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 8,
        apart_ind: 2,
        q_2021: "39,471488 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.998330992921545,
          47.03686236758362
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Новобудiвна, 3",
        adrs_full: "м. Миколаїв, вул. Новобудiвна, 3",
        lat: 47.03655000769482,
        lon: 31.997823746131157,
        build_s: "5 893,06 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 103,
        apart_ind: 6,
        q_2021: "467,530746 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.997823746131157,
          47.03655000769482
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Новобудiвна, 4",
        adrs_full: "м. Миколаїв, вул. Новобудiвна, 4",
        lat: 47.03658585864546,
        lon: 31.998272233726546,
        build_s: "377,01 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 9,
        apart_ind: 2,
        q_2021: "42,389381 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.998272233726546,
          47.03658585864545
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Новобудiвна, 5",
        adrs_full: "м. Миколаїв, вул. Новобудiвна, 5",
        lat: 47.036383717158955,
        lon: 31.997853868962522,
        build_s: "6 007,63 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 106,
        apart_ind: 6,
        q_2021: "498,499419 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.997853868962522,
          47.036383717158955
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Новобудiвна, 6",
        adrs_full: "м. Миколаїв, вул. Новобудiвна, 6",
        lat: 47.03644415517586,
        lon: 31.99821250106061,
        build_s: "419,80 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "57,039363 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.998212501060614,
          47.03644415517586
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Новобудiвна, 7",
        adrs_full: "м. Миколаїв, вул. Новобудiвна, 7",
        lat: 47.03607987632286,
        lon: 31.997766512751554,
        build_s: "355,50 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "44,325009 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.997766512751554,
          47.03607987632286
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Новобудiвна, 7/1",
        adrs_full: "м. Миколаїв, вул. Новобудiвна, 7/1",
        lat: 46.8623666,
        lon: 32.0139845,
        build_s: "516,80 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "49,716496 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0139845,
          46.8623666
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Новобудiвна, 8",
        adrs_full: "м. Миколаїв, вул. Новобудiвна, 8",
        lat: 47.0362737,
        lon: 31.9982638,
        build_s: "400,98 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "61,229792 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9982638,
          47.0362737
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Новобудiвна, 9",
        adrs_full: "м. Миколаїв, вул. Новобудiвна, 9",
        lat: 47.03590859050327,
        lon: 31.99771268818542,
        build_s: "1 751,20 м2",
        bld_floor: 3,
        bld_porch: 4,
        bld_apart: 36,
        apart_ind: 4,
        q_2021: "140,084240 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99771268818542,
          47.035908590503276
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Новобузька, 101",
        adrs_full: "м. Миколаїв, вул. Новобузька, 101",
        lat: 46.9722308,
        lon: 32.0758624,
        build_s: "7 335,90 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 144,
        apart_ind: 4,
        q_2021: "417,422080 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0758624,
          46.9722308
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Новобузька, 120",
        adrs_full: "м. Миколаїв, вул. Новобузька, 120",
        lat: 46.97105361028773,
        lon: 32.07535012738549,
        build_s: "8 000,00 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 116,
        apart_ind: 18,
        q_2021: "435,726131 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07535012738549,
          46.97105361028774
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Новобузька, 128",
        adrs_full: "м. Миколаїв, вул. Новобузька, 128",
        lat: 46.9709472,
        lon: 32.0773366,
        build_s: "5 763,30 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 79,
        apart_ind: 12,
        q_2021: "297,681739 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0773366,
          46.9709472
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Новобузька, 89",
        adrs_full: "м. Миколаїв, вул. Новобузька, 89",
        lat: 46.970693645888666,
        lon: 32.07302250982824,
        build_s: "5 272,00 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 100,
        apart_ind: 4,
        q_2021: "354,541900 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07302250982824,
          46.970693645888666
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Новобузька, 91",
        adrs_full: "м. Миколаїв, вул. Новобузька, 91",
        lat: 46.97125400179002,
        lon: 32.07287023578244,
        build_s: "7 071,70 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 107,
        apart_ind: 3,
        q_2021: "445,800302 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07287023578244,
          46.97125400179002
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Новобузька, 91/А",
        adrs_full: "м. Миколаїв, вул. Новобузька, 91/А",
        lat: 46.9716770480514,
        lon: 32.07338144150763,
        build_s: "9 279,60 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 143,
        apart_ind: 9,
        q_2021: "557,349800 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07338144150763,
          46.9716770480514
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Новобузька, 93",
        adrs_full: "м. Миколаїв, вул. Новобузька, 93",
        lat: 46.970986812953456,
        lon: 32.07387089379771,
        build_s: "3 213,40 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 2,
        q_2021: "214,221018 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07387089379771,
          46.970986812953456
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Новобузька, 99",
        adrs_full: "м. Миколаїв, вул. Новобузька, 99",
        lat: 46.9716461,
        lon: 32.0761809,
        build_s: "6 907,10 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 107,
        apart_ind: 9,
        q_2021: "356,829841 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0761809,
          46.9716461
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Новозаводська, 10",
        adrs_full: "м. Миколаїв, вул. Новозаводська, 10",
        lat: 46.94200785711317,
        lon: 32.06342699090912,
        build_s: "4 833,10 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "281,240172 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06342699090912,
          46.94200785711317
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Новозаводська, 2",
        adrs_full: "м. Миколаїв, вул. Новозаводська, 2",
        lat: 46.93810670111255,
        lon: 32.05965674112597,
        build_s: "9 889,76 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 128,
        apart_ind: 7,
        q_2021: "572,475451 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05965674112597,
          46.93810670111255
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Новозаводська, 2/А",
        adrs_full: "м. Миколаїв, вул. Новозаводська, 2/А",
        lat: 46.93731948714875,
        lon: 32.05885186402673,
        build_s: "5 192,80 м2",
        bld_floor: 12,
        bld_porch: 1,
        bld_apart: 96,
        apart_ind: 0,
        q_2021: "386,803856 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05885186402673,
          46.93731948714875
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Новозаводська, 4",
        adrs_full: "м. Миколаїв, вул. Новозаводська, 4",
        lat: 46.94046000063017,
        lon: 32.06184098444656,
        build_s: "12 247,00 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 216,
        apart_ind: 7,
        q_2021: "597,563520 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06184098444656,
          46.94046000063017
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Новозаводська, 6",
        adrs_full: "м. Миколаїв, вул. Новозаводська, 6",
        lat: 46.94109024903752,
        lon: 32.062655718181844,
        build_s: "4 820,70 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 0,
        q_2021: "357,589704 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.062655718181844,
          46.94109024903752
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Новозаводська, 8",
        adrs_full: "м. Миколаїв, вул. Новозаводська, 8",
        lat: 46.94150995241009,
        lon: 32.06302226363639,
        build_s: "4 729,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 5,
        q_2021: "345,910175 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06302226363639,
          46.94150995241009
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Новоодеська, 38",
        adrs_full: "м. Миколаїв, вул. Новоодеська, 38",
        lat: 46.9967468,
        lon: 31.9963027,
        build_s: "2 591,90 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 70,
        apart_ind: 4,
        q_2021: "187,120178 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9963027,
          46.9967468
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Новосільська, 5",
        adrs_full: "м. Миколаїв, вул. Новосільська, 5",
        lat: 46.95608507316818,
        lon: 31.985207154871485,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 22,
        apart_ind: 5,
        q_2021: "98,732000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.985207154871485,
          46.95608507316818
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Новосільська, 4",
        adrs_full: "м. Миколаїв, вул. Новосільська, 4",
        lat: 46.9564857,
        lon: 31.9838149,
        build_s: "?",
        bld_floor: 1,
        bld_porch: "?",
        bld_apart: 3,
        apart_ind: 1,
        q_2021: "9,113000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9838149,
          46.95648569999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Нiкольська, 12/А",
        adrs_full: "м. Миколаїв, вул. Нiкольська, 12/А",
        lat: 46.97789779219698,
        lon: 31.97394202426584,
        build_s: "179,30 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 4,
        apart_ind: 2,
        q_2021: "6,786755 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.973942024265842,
          46.97789779219698
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Нiкольська, 14",
        adrs_full: "м. Миколаїв, вул. Нiкольська, 14",
        lat: 46.978015313481336,
        lon: 31.974770967578117,
        build_s: "1 371,30 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 7,
        q_2021: "112,458206 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.974770967578117,
          46.978015313481336
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Нiкольська, 16",
        adrs_full: "м. Миколаїв, вул. Нiкольська, 16",
        lat: 46.9783091155622,
        lon: 31.97632837622542,
        build_s: "974,70 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 2,
        q_2021: "52,458008 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97632837622542,
          46.9783091155622
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Нiкольська, 18/Г",
        adrs_full: "м. Миколаїв, вул. Нiкольська, 18/Г",
        lat: 46.97929480308485,
        lon: 31.977144132259347,
        build_s: "998,90 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 18,
        apart_ind: 7,
        q_2021: "56,939996 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.977144132259347,
          46.97929480308485
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "вул. Нiкольська, 2",
        adrs_full: "м. Миколаїв, вул. Нiкольська, 2",
        lat: 46.97655176576754,
        lon: 31.96709188413582,
        build_s: "3 033,30 м2",
        bld_floor: 3,
        bld_porch: 4,
        bld_apart: 40,
        apart_ind: 14,
        q_2021: "199,199941 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96709188413582,
          46.97655176576754
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "вул. Нiкольська, 4",
        adrs_full: "м. Миколаїв, вул. Нiкольська, 4",
        lat: 46.97685540292489,
        lon: 31.96820536325447,
        build_s: "3 102,90 м2",
        bld_floor: 3,
        bld_porch: 5,
        bld_apart: 55,
        apart_ind: 10,
        q_2021: "272,029850 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "5",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96820536325447,
          46.97685540292489
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Нiкольська, 52/1",
        adrs_full: "м. Миколаїв, вул. Нiкольська, 52/1",
        lat: 46.97429864604284,
        lon: 31.993200051444788,
        build_s: "1 991,00 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 8,
        q_2021: "142,555641 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.993200051444788,
          46.97429864604284
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Нiкольська, 67",
        adrs_full: "м. Миколаїв, вул. Нiкольська, 67",
        lat: 46.9717968,
        lon: 31.9996899,
        build_s: "816,60 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 6,
        apart_ind: 4,
        q_2021: "15,648400 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9996899,
          46.9717968
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Нiкольська, 8/1",
        adrs_full: "м. Миколаїв, вул. Нiкольська, 8/1",
        lat: 46.97787948865433,
        lon: 31.97266317310028,
        build_s: "1 205,70 м2",
        bld_floor: 3,
        bld_porch: 4,
        bld_apart: 24,
        apart_ind: 4,
        q_2021: "152,812017 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97266317310028,
          46.97787948865433
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Нiкольська, 8/2",
        adrs_full: "м. Миколаїв, вул. Нiкольська, 8/2",
        lat: 46.97796409798328,
        lon: 31.972131189616583,
        build_s: "767,10 м2",
        bld_floor: 3,
        bld_porch: 3,
        bld_apart: 18,
        apart_ind: 2,
        q_2021: "83,819992 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.972131189616583,
          46.97796409798328
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Нiкольська, 8/3",
        adrs_full: "м. Миколаїв, вул. Нiкольська, 8/3",
        lat: 46.977639657861474,
        lon: 31.97199263172276,
        build_s: "980,40 м2",
        bld_floor: 3,
        bld_porch: 3,
        bld_apart: 18,
        apart_ind: 1,
        q_2021: "111,626993 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97199263172276,
          46.977639657861474
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Нiкольська, 8/4",
        adrs_full: "м. Миколаїв, вул. Нiкольська, 8/4",
        lat: 46.97732819488262,
        lon: 31.971936917236295,
        build_s: "781,40 м2",
        bld_floor: 3,
        bld_porch: 4,
        bld_apart: 17,
        apart_ind: 2,
        q_2021: "81,433611 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.971936917236295,
          46.97732819488262
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Нiкольська, 8/5",
        adrs_full: "м. Миколаїв, вул. Нiкольська, 8/5",
        lat: 46.9774589,
        lon: 31.9714297,
        build_s: "1 096,30 м2",
        bld_floor: 3,
        bld_porch: 4,
        bld_apart: 21,
        apart_ind: 4,
        q_2021: "108,568923 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9714297,
          46.97745889999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Нiкольська, 8/6",
        adrs_full: "м. Миколаїв, вул. Нiкольська, 8/6",
        lat: 46.97763486411355,
        lon: 31.970911195827544,
        build_s: "923,40 м2",
        bld_floor: 3,
        bld_porch: 3,
        bld_apart: 18,
        apart_ind: 3,
        q_2021: "104,990036 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.970911195827544,
          46.97763486411355
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Адмiральська,27-1",
        address: "вул. Нiкольська, 8/7",
        adrs_full: "м. Миколаїв, вул. Нiкольська, 8/7",
        lat: 46.977214266001944,
        lon: 31.970824928235594,
        build_s: "1 289,20 м2",
        bld_floor: 3,
        bld_porch: 3,
        bld_apart: 17,
        apart_ind: 6,
        q_2021: "112,929956 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.970824928235594,
          46.977214266001944
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Спаська,66-к",
        address: "вул. Нiкольська, 9",
        adrs_full: "м. Миколаїв, вул. Нiкольська, 9",
        lat: 46.97802265855303,
        lon: 31.977692005916605,
        build_s: "406,90 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 9,
        apart_ind: 5,
        q_2021: "23,020400 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.977692005916605,
          46.97802265855303
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Нiкольська, 9/А",
        adrs_full: "м. Миколаїв, вул. Нiкольська, 9/А",
        lat: 46.977963897951206,
        lon: 31.977229089521437,
        build_s: "1 617,70 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 32,
        apart_ind: 4,
        q_2021: "76,228122 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.977229089521437,
          46.977963897951206
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Оберегова, 6",
        adrs_full: "м. Миколаїв, вул. Оберегова, 6",
        lat: 47.00165372385523,
        lon: 31.99401209559333,
        build_s: "3 988,44 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 9,
        q_2021: "262,577107 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.994012095593334,
          47.00165372385522
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Образцова, 1",
        adrs_full: "м. Миколаїв, вул. Образцова, 1",
        lat: 46.95840427777115,
        lon: 31.978162842644,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 90,
        apart_ind: 4,
        q_2021: "352,148000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.978162842644,
          46.95840427777115
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Образцова, 2/А",
        adrs_full: "м. Миколаїв, вул. Образцова, 2/А",
        lat: 46.959357163278376,
        lon: 31.977220111832064,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 1,
        q_2021: "120,079000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.977220111832064,
          46.959357163278376
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Образцова, 3",
        adrs_full: "м. Миколаїв, вул. Образцова, 3",
        lat: 46.958546484728906,
        lon: 31.979165093511444,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 99,
        apart_ind: 2,
        q_2021: "307,308000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.979165093511444,
          46.958546484728906
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Образцова, 4/А",
        adrs_full: "м. Миколаїв, вул. Образцова, 4/А",
        lat: 46.95921741028534,
        lon: 31.97807867081887,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 108,
        apart_ind: 11,
        q_2021: "408,083000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97807867081887,
          46.95921741028534
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Обсерваторна, 1/7",
        adrs_full: "м. Миколаїв, вул. Обсерваторна, 1/7",
        lat: 46.972169459802195,
        lon: 31.971625680881335,
        build_s: "448,60 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 8,
        apart_ind: 1,
        q_2021: "45,969118 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.971625680881335,
          46.97216945980219
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Нiкольськ,9-а",
        address: "вул. Обсерваторна, 1/8",
        adrs_full: "м. Миколаїв, вул. Обсерваторна, 1/8",
        lat: 46.9725403,
        lon: 31.9716542,
        build_s: "403,80 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 7,
        apart_ind: 1,
        q_2021: "35,151911 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9716542,
          46.9725403
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Нiкольськ,9-а",
        address: "вул. Обсерваторна, 1/9",
        adrs_full: "м. Миколаїв, вул. Обсерваторна, 1/9",
        lat: 46.9727803,
        lon: 31.9716282,
        build_s: "835,50 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 13,
        apart_ind: 4,
        q_2021: "55,538146 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9716282,
          46.9727803
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "вул. Обсерваторна, 2",
        adrs_full: "м. Миколаїв, вул. Обсерваторна, 2",
        lat: 46.9744665,
        lon: 31.9746082,
        build_s: "1 112,70 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 2,
        q_2021: "118,799988 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9746082,
          46.9744665
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Обсерваторна, 3",
        adrs_full: "м. Миколаїв, вул. Обсерваторна, 3",
        lat: 46.97452042442015,
        lon: 31.97597686516308,
        build_s: "2 780,80 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 38,
        apart_ind: 9,
        q_2021: "154,190073 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97597686516308,
          46.974520424420156
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Озерна, 1",
        adrs_full: "м. Миколаїв, вул. Озерна, 1",
        lat: 46.95434710568905,
        lon: 31.93962550426787,
        build_s: "3 991,20 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 1,
        q_2021: "242,071044 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93962550426787,
          46.95434710568906
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Озерна, 1/А",
        adrs_full: "м. Миколаїв, вул. Озерна, 1/А",
        lat: 46.9541117099781,
        lon: 31.93923394389313,
        build_s: "3 978,40 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "269,972294 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93923394389313,
          46.95411170997811
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Озерна, 10",
        adrs_full: "м. Миколаїв, вул. Озерна, 10",
        lat: 46.95059139964599,
        lon: 31.94479158074254,
        build_s: "3 787,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "263,689996 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94479158074254,
          46.95059139964599
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Озерна, 11",
        adrs_full: "м. Миколаїв, вул. Озерна, 11",
        lat: 46.952020075530406,
        lon: 31.9408971774115,
        build_s: "12 961,60 м2",
        bld_floor: 9,
        bld_porch: 7,
        bld_apart: 332,
        apart_ind: 9,
        q_2021: "919,901000 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9408971774115,
          46.952020075530406
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Озерна, 11/А",
        adrs_full: "м. Миколаїв, вул. Озерна, 11/А",
        lat: 46.95117650669847,
        lon: 31.940340444999286,
        build_s: "5 922,30 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 108,
        apart_ind: 3,
        q_2021: "341,113970 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.940340444999286,
          46.95117650669847
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Озерна, 11/Б",
        adrs_full: "м. Миколаїв, вул. Озерна, 11/Б",
        lat: 46.951068988141245,
        lon: 31.941130268679935,
        build_s: "5 875,90 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 108,
        apart_ind: 10,
        q_2021: "356,445603 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.941130268679935,
          46.951068988141245
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Озерна, 11/В",
        adrs_full: "м. Миколаїв, вул. Озерна, 11/В",
        lat: 46.95182390398007,
        lon: 31.940088910582926,
        build_s: "1 915,40 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 32,
        apart_ind: 1,
        q_2021: "111,619982 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.940088910582922,
          46.95182390398007
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Озерна, 12",
        adrs_full: "м. Миколаїв, вул. Озерна, 12",
        lat: 46.951035586909626,
        lon: 31.94528372317164,
        build_s: "6 868,30 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 133,
        apart_ind: 1,
        q_2021: "483,679749 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.945283723171638,
          46.951035586909626
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 13",
        adrs_full: "м. Миколаїв, вул. Озерна, 13",
        lat: 46.95079797739464,
        lon: 31.942537173178344,
        build_s: "5 692,30 м2",
        bld_floor: 10,
        bld_porch: 3,
        bld_apart: 96,
        apart_ind: 12,
        q_2021: "344,669671 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.942537173178344,
          46.950797977394636
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 13/А",
        adrs_full: "м. Миколаїв, вул. Озерна, 13/А",
        lat: 46.95024714902845,
        lon: 31.941055238480207,
        build_s: "5 864,20 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 108,
        apart_ind: 6,
        q_2021: "402,219845 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.941055238480207,
          46.95024714902845
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 13/Б",
        adrs_full: "м. Миколаїв, вул. Озерна, 13/Б",
        lat: 46.95014170341659,
        lon: 31.941845543823717,
        build_s: "5 948,70 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 108,
        apart_ind: 6,
        q_2021: "363,768565 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.941845543823717,
          46.95014170341659
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 15",
        adrs_full: "м. Миколаїв, вул. Озерна, 15",
        lat: 46.94978612947312,
        lon: 31.942470603504486,
        build_s: "3 953,50 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 6,
        q_2021: "275,040052 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.942470603504486,
          46.94978612947312
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 15/А",
        adrs_full: "м. Миколаїв, вул. Озерна, 15/А",
        lat: 46.9491929,
        lon: 31.9425287,
        build_s: "3 952,50 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 8,
        q_2021: "281,752936 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9425287,
          46.9491929
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 15/Б",
        adrs_full: "м. Миколаїв, вул. Озерна, 15/Б",
        lat: 46.948992,
        lon: 31.943615,
        build_s: "5 948,70 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 1,
        q_2021: "285,200068 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.943615,
          46.948992
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 15/В",
        adrs_full: "м. Миколаїв, вул. Озерна, 15/В",
        lat: 46.94995533392011,
        lon: 31.943084886294216,
        build_s: "5 951,60 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 107,
        apart_ind: 7,
        q_2021: "449,957667 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.943084886294216,
          46.94995533392011
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 17",
        adrs_full: "м. Миколаїв, вул. Озерна, 17",
        lat: 46.95017113011986,
        lon: 31.94329323952114,
        build_s: "6 315,40 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 107,
        apart_ind: 12,
        q_2021: "395,233600 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94329323952114,
          46.95017113011986
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 17/А",
        adrs_full: "м. Миколаїв, вул. Озерна, 17/А",
        lat: 46.94955561820631,
        lon: 31.943756645836196,
        build_s: "3 856,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 2,
        q_2021: "244,680821 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.943756645836196,
          46.94955561820631
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 17/Б",
        adrs_full: "м. Миколаїв, вул. Озерна, 17/Б",
        lat: 46.948192148283425,
        lon: 31.944744527515567,
        build_s: "5 216,00 м2",
        bld_floor: 16,
        bld_porch: 1,
        bld_apart: 60,
        apart_ind: 1,
        q_2021: "398,098163 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.944744527515567,
          46.948192148283425
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 19",
        adrs_full: "м. Миколаїв, вул. Озерна, 19",
        lat: 46.94781449039983,
        lon: 31.943386639243567,
        build_s: "8 184,00 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 8,
        q_2021: "460,799315 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.943386639243567,
          46.947814490399836
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 19/А",
        adrs_full: "м. Миколаїв, вул. Озерна, 19/А",
        lat: 46.94825100381751,
        lon: 31.94235565,
        build_s: "7 931,90 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 5,
        q_2021: "537,414239 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94235565,
          46.94825100381751
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 19/Б",
        adrs_full: "м. Миколаїв, вул. Озерна, 19/Б",
        lat: 46.94877824842366,
        lon: 31.942423903643284,
        build_s: "4 030,80 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "241,010757 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.942423903643284,
          46.94877824842366
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 19/В",
        adrs_full: "м. Миколаїв, вул. Озерна, 19/В",
        lat: 46.948501139115365,
        lon: 31.941647967487853,
        build_s: "4 006,30 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 6,
        q_2021: "273,952159 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.941647967487853,
          46.948501139115365
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 2",
        adrs_full: "м. Миколаїв, вул. Озерна, 2",
        lat: 46.9520247,
        lon: 31.9437872,
        build_s: "3 896,90 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "284,711000 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9437872,
          46.9520247
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 21",
        adrs_full: "м. Миколаїв, вул. Озерна, 21",
        lat: 46.947387782831726,
        lon: 31.94248497269257,
        build_s: "3 777,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 64,
        apart_ind: 5,
        q_2021: "225,405511 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94248497269257,
          46.947387782831726
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 25",
        adrs_full: "м. Миколаїв, вул. Озерна, 25",
        lat: 46.94670847698402,
        lon: 31.945746778383015,
        build_s: "7 025,30 м2",
        bld_floor: 10,
        bld_porch: 3,
        bld_apart: 118,
        apart_ind: 10,
        q_2021: "440,259913 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.945746778383015,
          46.94670847698402
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 27",
        adrs_full: "м. Миколаїв, вул. Озерна, 27",
        lat: 46.94700521453384,
        lon: 31.945071426544015,
        build_s: "4 677,10 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 6,
        q_2021: "279,919752 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.945071426544015,
          46.94700521453384
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 29",
        adrs_full: "м. Миколаїв, вул. Озерна, 29",
        lat: 46.94686297690532,
        lon: 31.943814122588446,
        build_s: "9 181,20 м2",
        bld_floor: 10,
        bld_porch: 4,
        bld_apart: 160,
        apart_ind: 22,
        q_2021: "510,250225 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.943814122588446,
          46.94686297690532
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 3",
        adrs_full: "м. Миколаїв, вул. Озерна, 3",
        lat: 46.9537503397987,
        lon: 31.93993994243585,
        build_s: "7 904,80 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 143,
        apart_ind: 8,
        q_2021: "558,085132 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93993994243585,
          46.9537503397987
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 3/А",
        adrs_full: "м. Миколаїв, вул. Озерна, 3/А",
        lat: 46.95324521292989,
        lon: 31.940259656870268,
        build_s: "4 175,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 5,
        q_2021: "314,149852 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.940259656870268,
          46.95324521292989
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 31",
        adrs_full: "м. Миколаїв, вул. Озерна, 31",
        lat: 46.946154235501986,
        lon: 31.94442481308115,
        build_s: "9 410,90 м2",
        bld_floor: 10,
        bld_porch: 4,
        bld_apart: 158,
        apart_ind: 19,
        q_2021: "608,439848 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94442481308115,
          46.946154235501986
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 33",
        adrs_full: "м. Миколаїв, вул. Озерна, 33",
        lat: 46.945774110814014,
        lon: 31.94518278775152,
        build_s: "4 686,70 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 9,
        q_2021: "310,049707 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94518278775152,
          46.945774110814014
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 35",
        adrs_full: "м. Миколаїв, вул. Озерна, 35",
        lat: 46.946244974544555,
        lon: 31.943005855759854,
        build_s: "7 048,30 м2",
        bld_floor: 10,
        bld_porch: 3,
        bld_apart: 118,
        apart_ind: 9,
        q_2021: "403,430216 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.943005855759854,
          46.94624497454455
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Озерна,45-а",
        address: "вул. Озерна, 37",
        adrs_full: "м. Миколаїв, вул. Озерна, 37",
        lat: 46.94555584445066,
        lon: 31.943127993858393,
        build_s: "7 031,60 м2",
        bld_floor: 10,
        bld_porch: 3,
        bld_apart: 119,
        apart_ind: 13,
        q_2021: "476,989900 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.943127993858393,
          46.94555584445065
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Озерна,45-а",
        address: "вул. Озерна, 39",
        adrs_full: "м. Миколаїв, вул. Озерна, 39",
        lat: 46.94525174151685,
        lon: 31.943839268667546,
        build_s: "4 672,00 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 3,
        q_2021: "307,190051 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.943839268667546,
          46.94525174151685
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Озерна,45-а",
        address: "вул. Озерна, 4",
        adrs_full: "м. Миколаїв, вул. Озерна, 4",
        lat: 46.951604743758175,
        lon: 31.94351068566967,
        build_s: "4 178,70 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 108,
        apart_ind: 6,
        q_2021: "301,790258 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94351068566967,
          46.951604743758175
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 45",
        adrs_full: "м. Миколаїв, вул. Озерна, 45",
        lat: 46.94628421300179,
        lon: 31.946411353330966,
        build_s: "4 519,50 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 120,
        apart_ind: 3,
        q_2021: "336,949663 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.946411353330966,
          46.94628421300179
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Озерна, 47",
        adrs_full: "м. Миколаїв, вул. Озерна, 47",
        lat: 46.946387213815235,
        lon: 31.947115443546085,
        build_s: "4 545,60 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 120,
        apart_ind: 2,
        q_2021: "308,769824 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.947115443546085,
          46.946387213815235
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Озерна,45-а",
        address: "вул. Озерна, 6",
        adrs_full: "м. Миколаїв, вул. Озерна, 6",
        lat: 46.951261440409056,
        lon: 31.943762146460784,
        build_s: "4 080,80 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 108,
        apart_ind: 4,
        q_2021: "302,529904 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.943762146460784,
          46.951261440409056
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Озерна,45-а",
        address: "вул. Озерна, 8",
        adrs_full: "м. Миколаїв, вул. Озерна, 8",
        lat: 46.95074588835968,
        lon: 31.94430662064539,
        build_s: "3 882,90 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 5,
        q_2021: "254,539676 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94430662064539,
          46.95074588835968
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Озерна,45-а",
        address: "вул. Озерна, 9/А",
        adrs_full: "м. Миколаїв, вул. Озерна, 9/А",
        lat: 46.9524628,
        lon: 31.9397367,
        build_s: "3 978,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 5,
        q_2021: "283,619884 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9397367,
          46.9524628
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Озерна,45-а",
        address: "вул. Озерна, 9/Б",
        adrs_full: "м. Миколаїв, вул. Озерна, 9/Б",
        lat: 46.9520945,
        lon: 31.9395661,
        build_s: "3 978,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 8,
        q_2021: "284,260000 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9395661,
          46.9520945
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Озерна,45-а",
        address: "вул. Океанiвська, 1",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 1",
        lat: 46.85937318755678,
        lon: 32.00534895321836,
        build_s: "4 065,00 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 119,
        apart_ind: 1,
        q_2021: "238,211443 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00534895321836,
          46.85937318755678
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Океанiвська, 1/А",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 1/А",
        lat: 46.8598112,
        lon: 32.0057189,
        build_s: "4 781,00 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 68,
        apart_ind: 0,
        q_2021: "153,889782 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0057189,
          46.8598112
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Озерна,45-а",
        address: "вул. Океанiвська, 1/Б",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 1/Б",
        lat: 46.8602348,
        lon: 32.0062495,
        build_s: "4 875,00 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 116,
        apart_ind: 0,
        q_2021: "201,479731 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0062495,
          46.8602348
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Озерна,45-а",
        address: "вул. Океанiвська, 10",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 10",
        lat: 46.8580764,
        lon: 32.0097303,
        build_s: "4 509,10 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 100,
        apart_ind: 5,
        q_2021: "388,290178 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0097303,
          46.85807639999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Океанiвська, 14",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 14",
        lat: 46.8580136,
        lon: 32.0122921,
        build_s: "1 417,06 м2",
        bld_floor: 3,
        bld_porch: 3,
        bld_apart: 36,
        apart_ind: 4,
        q_2021: "125,617539 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0122921,
          46.8580136
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Океанiвська, 14/1",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 14/1",
        lat: 46.8580136,
        lon: 32.0122921,
        build_s: "615,12 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 1,
        q_2021: "81,154463 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0122921,
          46.8580136
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Океанiвська, 14/2",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 14/2",
        lat: 46.8573345,
        lon: 32.011839,
        build_s: "582,38 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 1,
        q_2021: "75,655536 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.011839,
          46.8573345
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.Намив Бiла,71",
        address: "вул. Океанiвська, 16",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 16",
        lat: 46.857875,
        lon: 32.0132759,
        build_s: "1 517,68 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 36,
        apart_ind: 1,
        q_2021: "206,363387 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0132759,
          46.857875
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 16/1",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 16/1",
        lat: 46.857875,
        lon: 32.0132759,
        build_s: "573,80 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 1,
        q_2021: "40,953967 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0132759,
          46.857875
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 16/2",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 16/2",
        lat: 46.8572099,
        lon: 32.0129128,
        build_s: "628,30 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 2,
        q_2021: "63,967468 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0129128,
          46.8572099
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 18",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 18",
        lat: 46.8577689,
        lon: 32.0141557,
        build_s: "517,48 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 2,
        q_2021: "54,620038 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0141557,
          46.8577689
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 18/1",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 18/1",
        lat: 46.8575014,
        lon: 32.0140015,
        build_s: "675,80 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "67,930184 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0140015,
          46.8575014
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 18/2",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 18/2",
        lat: 46.8573769,
        lon: 32.0150752,
        build_s: "534,50 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 5,
        q_2021: "38,959798 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0150752,
          46.8573769
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 2",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 2",
        lat: 46.8588624,
        lon: 32.0052537,
        build_s: "6 132,20 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 129,
        apart_ind: 3,
        q_2021: "562,939797 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0052537,
          46.8588624
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 20",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 20",
        lat: 46.8577045,
        lon: 32.0151785,
        build_s: "621,30 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 2,
        q_2021: "39,906000 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0151785,
          46.8577045
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 20/1",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 20/1",
        lat: 46.8575181,
        lon: 32.0144202,
        build_s: "635,60 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 1,
        q_2021: "64,631004 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0144202,
          46.8575181
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 22",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 22",
        lat: 46.856856094769384,
        lon: 32.017619031278386,
        build_s: "6 733,00 м2",
        bld_floor: 5,
        bld_porch: 10,
        bld_apart: 115,
        apart_ind: 1,
        q_2021: "567,859860 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "10",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.017619031278386,
          46.856856094769384
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 24",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 24",
        lat: 46.85699127179994,
        lon: 32.018876486346926,
        build_s: "7 059,00 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 159,
        apart_ind: 0,
        q_2021: "496,309500 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.018876486346926,
          46.85699127179994
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 26",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 26",
        lat: 46.85677461928086,
        lon: 32.01994371318839,
        build_s: "16 357,00 м2",
        bld_floor: 5,
        bld_porch: 9,
        bld_apart: 105,
        apart_ind: 2,
        q_2021: "546,167440 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "9",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01994371318839,
          46.85677461928086
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 28",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 28",
        lat: 46.8556166,
        lon: 32.0197059,
        build_s: "15 191,00 м2",
        bld_floor: 9,
        bld_porch: 6,
        bld_apart: 209,
        apart_ind: 2,
        q_2021: "914,942858 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0197059,
          46.8556166
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 30",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 30",
        lat: 46.856785327210346,
        lon: 32.021748400091965,
        build_s: "7 839,60 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 1,
        q_2021: "547,080163 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.021748400091965,
          46.856785327210346
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 30/А",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 30/А",
        lat: 46.85630411836291,
        lon: 32.02062813773062,
        build_s: "7 495,40 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 127,
        apart_ind: 4,
        q_2021: "456,411000 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02062813773062,
          46.85630411836291
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 31",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 31",
        lat: 46.8583854,
        lon: 32.0121792,
        build_s: "927,78 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 16,
        apart_ind: 3,
        q_2021: "64,691361 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0121792,
          46.8583854
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 31/А",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 31/А",
        lat: 46.8583854,
        lon: 32.0121791,
        build_s: "1 526,00 м2",
        bld_floor: 3,
        bld_porch: 1,
        bld_apart: 29,
        apart_ind: 0,
        q_2021: "81,289700 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0121791,
          46.8583854
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 32",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 32",
        lat: 46.855496,
        lon: 32.0229914,
        build_s: "9 816,00 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 143,
        apart_ind: 0,
        q_2021: "581,276522 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0229914,
          46.855496
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 32/А",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 32/А",
        lat: 46.8548674,
        lon: 32.022584,
        build_s: "9 137,00 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 141,
        apart_ind: 1,
        q_2021: "542,899391 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.022584,
          46.8548674
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 32/Б",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 32/Б",
        lat: 46.854844,
        lon: 32.0223495,
        build_s: "2 293,00 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 33,
        apart_ind: 0,
        q_2021: "165,526835 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0223495,
          46.854844
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 32/В",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 32/В",
        lat: 46.8548674,
        lon: 32.022584,
        build_s: "4 984,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 69,
        apart_ind: 0,
        q_2021: "233,269730 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.022584,
          46.8548674
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 33",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 33",
        lat: 46.8583442,
        lon: 32.012851,
        build_s: "1 129,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 27,
        apart_ind: 1,
        q_2021: "83,290088 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.012851,
          46.8583442
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 34",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 34",
        lat: 46.856223,
        lon: 32.0223471,
        build_s: "7 578,00 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 106,
        apart_ind: 1,
        q_2021: "428,190200 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0223471,
          46.856223
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 35",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 35",
        lat: 46.8581964,
        lon: 32.0139318,
        build_s: "3 153,68 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "231,210121 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0139318,
          46.8581964
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 36",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 36",
        lat: 46.856429287409945,
        lon: 32.024163783648376,
        build_s: "10 329,00 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 141,
        apart_ind: 5,
        q_2021: "648,240400 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.024163783648376,
          46.856429287409945
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 37",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 37",
        lat: 46.8581354,
        lon: 32.0144988,
        build_s: "1 144,00 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 28,
        apart_ind: 3,
        q_2021: "56,304297 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0144988,
          46.85813539999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 38",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 38",
        lat: 46.85606521419543,
        lon: 32.02533428556631,
        build_s: "10 994,00 м2",
        bld_floor: 9,
        bld_porch: 5,
        bld_apart: 175,
        apart_ind: 2,
        q_2021: "665,761200 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02533428556631,
          46.85606521419543
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 38/А",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 38/А",
        lat: 46.85575467920894,
        lon: 32.024379093365724,
        build_s: "6 832,00 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 108,
        apart_ind: 1,
        q_2021: "414,652140 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.024379093365724,
          46.85575467920894
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 38/Б",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 38/Б",
        lat: 46.8554617,
        lon: 32.0237081,
        build_s: "5 076,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 69,
        apart_ind: 2,
        q_2021: "244,599935 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0237081,
          46.8554617
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 39",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 39",
        lat: 46.8579893,
        lon: 32.0149815,
        build_s: "689,14 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 17,
        apart_ind: 3,
        q_2021: "62,758035 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0149815,
          46.8579893
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 4",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 4",
        lat: 46.8585012,
        lon: 32.0066819,
        build_s: "4 443,90 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 100,
        apart_ind: 0,
        q_2021: "301,149882 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0066819,
          46.8585012
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 40",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 40",
        lat: 46.8548996,
        lon: 32.0241611,
        build_s: "3 663,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 0,
        q_2021: "302,659918 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0241611,
          46.8548996
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 40/А",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 40/А",
        lat: 46.8545901,
        lon: 32.023737,
        build_s: "7 670,00 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 142,
        apart_ind: 3,
        q_2021: "482,759722 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.023737,
          46.8545901
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 40/Б",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 40/Б",
        lat: 46.8538948,
        lon: 32.0233357,
        build_s: "5 018,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 61,
        apart_ind: 0,
        q_2021: "226,126120 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0233357,
          46.8538948
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 40/В",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 40/В",
        lat: 46.8534581,
        lon: 32.0236565,
        build_s: "3 962,00 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 66,
        apart_ind: 0,
        q_2021: "229,570134 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0236565,
          46.8534581
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 45",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 45",
        lat: 46.8574756,
        lon: 32.0250749,
        build_s: "3 987,30 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 67,
        apart_ind: 2,
        q_2021: "318,880016 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0250749,
          46.8574756
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 46",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 46",
        lat: 46.85678532721034,
        lon: 32.02507591390549,
        build_s: "5 466,00 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 100,
        apart_ind: 0,
        q_2021: "249,459977 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02507591390549,
          46.85678532721034
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 47",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 47",
        lat: 46.857337,
        lon: 32.0255686,
        build_s: "7 905,60 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 107,
        apart_ind: 2,
        q_2021: "384,533041 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0255686,
          46.857337
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 48",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 48",
        lat: 46.857337,
        lon: 32.0255686,
        build_s: "2 519,00 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 33,
        apart_ind: 1,
        q_2021: "157,959921 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0255686,
          46.857337
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 50",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 50",
        lat: 46.85683886682577,
        lon: 32.02621118332422,
        build_s: "9 137,00 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 143,
        apart_ind: 2,
        q_2021: "482,459613 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02621118332422,
          46.85683886682577
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 52",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 52",
        lat: 46.85631685329833,
        lon: 32.02682970942132,
        build_s: "6 303,00 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 106,
        apart_ind: 0,
        q_2021: "428,850493 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02682970942132,
          46.856316853298324
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 54",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 54",
        lat: 46.85563421256695,
        lon: 32.02672009720158,
        build_s: "8 468,70 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 136,
        apart_ind: 1,
        q_2021: "530,940094 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02672009720158,
          46.85563421256695
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 56",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 56",
        lat: 46.854651730086005,
        lon: 32.02689625969759,
        build_s: "43 840,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 0,
        q_2021: "334,189924 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02689625969759,
          46.85465173008601
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 56/А",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 56/А",
        lat: 46.8546583,
        lon: 32.0262566,
        build_s: "6 536,00 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 75,
        apart_ind: 1,
        q_2021: "288,610293 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0262566,
          46.8546583
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 58",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 58",
        lat: 46.8543935,
        lon: 32.0249737,
        build_s: "4 603,40 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 79,
        apart_ind: 0,
        q_2021: "329,080348 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0249737,
          46.8543935
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 58/А",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 58/А",
        lat: 46.8540376,
        lon: 32.0245637,
        build_s: "4 738,49 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 119,
        apart_ind: 0,
        q_2021: "302,379857 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0245637,
          46.8540376
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 60",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 60",
        lat: 46.8539571,
        lon: 32.0257652,
        build_s: "4 743,30 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 1,
        q_2021: "335,900412 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0257652,
          46.8539571
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 60/А",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 60/А",
        lat: 46.8539571,
        lon: 32.0257652,
        build_s: "4 636,04 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 75,
        apart_ind: 2,
        q_2021: "295,399709 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0257652,
          46.8539571
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 62",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 62",
        lat: 46.8542045,
        lon: 32.0271969,
        build_s: "9 226,00 м2",
        bld_floor: 10,
        bld_porch: 4,
        bld_apart: 157,
        apart_ind: 0,
        q_2021: "579,270515 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0271969,
          46.85420449999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 62/А",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 62/А",
        lat: 46.8535742,
        lon: 32.0265876,
        build_s: "5 701,00 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 78,
        apart_ind: 3,
        q_2021: "298,978318 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0265876,
          46.8535742
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 64",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 64",
        lat: 46.8534965,
        lon: 32.0251516,
        build_s: "6 962,62 м2",
        bld_floor: 10,
        bld_porch: 3,
        bld_apart: 118,
        apart_ind: 2,
        q_2021: "437,733061 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0251516,
          46.8534965
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Океанiвська, 8",
        adrs_full: "м. Миколаїв, вул. Океанiвська, 8",
        lat: 46.8580706,
        lon: 32.0086019,
        build_s: "3 188,10 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 2,
        q_2021: "294,390100 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0086019,
          46.8580706
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Олега Григор'єва, 10",
        adrs_full: "м. Миколаїв, вул. Олега Григор'єва, 10",
        lat: 46.9730707,
        lon: 31.9641271,
        build_s: "1 842,10 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 5,
        q_2021: "127,982403 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9641271,
          46.9730707
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Олега Григор'єва, 10/А",
        adrs_full: "м. Миколаїв, вул. Олега Григор'єва, 10/А",
        lat: 46.973535,
        lon: 31.9645992,
        build_s: "2 958,90 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 63,
        apart_ind: 5,
        q_2021: "263,669866 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9645992,
          46.973535
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Олега Григор'єва, 10/Б",
        adrs_full: "м. Миколаїв, вул. Олега Григор'єва, 10/Б",
        lat: 46.973587,
        lon: 31.964584,
        build_s: "1 670,80 м2",
        bld_floor: 10,
        bld_porch: 1,
        bld_apart: 55,
        apart_ind: 14,
        q_2021: "152,274865 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.964584,
          46.973587
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Олега Григор'єва, 12",
        adrs_full: "м. Миколаїв, вул. Олега Григор'єва, 12",
        lat: 46.9726472,
        lon: 31.9645395,
        build_s: "1 636,80 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 36,
        apart_ind: 4,
        q_2021: "145,837082 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9645395,
          46.9726472
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Олега Григор'єва, 12/А",
        adrs_full: "м. Миколаїв, вул. Олега Григор'єва, 12/А",
        lat: 46.9727297,
        lon: 31.9652225,
        build_s: "2 569,70 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "254,009898 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9652225,
          46.9727297
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Олега Григор'єва, 14",
        adrs_full: "м. Миколаїв, вул. Олега Григор'єва, 14",
        lat: 46.972163,
        lon: 31.964876,
        build_s: "1 080,90 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 0,
        q_2021: "118,156060 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.964876,
          46.972163
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Олега Григор'єва, 2",
        adrs_full: "м. Миколаїв, вул. Олега Григор'єва, 2",
        lat: 46.9749121,
        lon: 31.9629516,
        build_s: "1 814,80 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 2,
        q_2021: "149,559957 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9629516,
          46.9749121
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Олега Григор'єва, 2/А",
        adrs_full: "м. Миколаїв, вул. Олега Григор'єва, 2/А",
        lat: 46.9748993,
        lon: 31.9638054,
        build_s: "1 838,70 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 3,
        q_2021: "152,240245 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9638054,
          46.9748993
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Олега Григор'єва, 2/Б",
        adrs_full: "м. Миколаїв, вул. Олега Григор'єва, 2/Б",
        lat: 46.9748993,
        lon: 31.9638054,
        build_s: "2 514,10 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "234,020052 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9638054,
          46.9748993
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Олега Григор'єва, 4",
        adrs_full: "м. Миколаїв, вул. Олега Григор'єва, 4",
        lat: 46.9743905,
        lon: 31.9632051,
        build_s: "1 290,20 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 5,
        q_2021: "105,769026 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9632051,
          46.9743905
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Олега Григор'єва, 6",
        adrs_full: "м. Миколаїв, вул. Олега Григор'єва, 6",
        lat: 46.9739762,
        lon: 31.9635204,
        build_s: "1 312,60 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 0,
        q_2021: "114,749962 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9635204,
          46.9739762
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Олега Григор'єва, 6/А",
        adrs_full: "м. Миколаїв, вул. Олега Григор'єва, 6/А",
        lat: 46.9740658,
        lon: 31.9642485,
        build_s: "1 957,60 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 48,
        apart_ind: 2,
        q_2021: "202,432447 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9642485,
          46.9740658
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Олега Григор'єва, 8",
        adrs_full: "м. Миколаїв, вул. Олега Григор'єва, 8",
        lat: 46.9737016,
        lon: 31.963793,
        build_s: "1 459,60 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 32,
        apart_ind: 4,
        q_2021: "121,470087 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.963793,
          46.9737016
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Олега Кошового, 1",
        adrs_full: "м. Миколаїв, вул. Олега Кошового, 1",
        lat: 46.94339055272448,
        lon: 32.04072925504856,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 36,
        apart_ind: 0,
        q_2021: "139,081000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04072925504856,
          46.94339055272448
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Олега Кошового, 2",
        adrs_full: "м. Миколаїв, вул. Олега Кошового, 2",
        lat: 46.942946371875145,
        lon: 32.04020086604786,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 36,
        apart_ind: 2,
        q_2021: "131,648000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04020086604786,
          46.942946371875145
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Олега Кошового, 2/А",
        adrs_full: "м. Миколаїв, вул. Олега Кошового, 2/А",
        lat: 46.94310343071819,
        lon: 32.03948915841428,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 36,
        apart_ind: 0,
        q_2021: "167,837000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03948915841428,
          46.94310343071819
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Олега Кошового, 3",
        adrs_full: "м. Миколаїв, вул. Олега Кошового, 3",
        lat: 46.94281139906379,
        lon: 32.04103838058638,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 36,
        apart_ind: 1,
        q_2021: "140,886000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04103838058638,
          46.94281139906379
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Олега Кошового, 4",
        adrs_full: "м. Миколаїв, вул. Олега Кошового, 4",
        lat: 46.94238930007583,
        lon: 32.04054593641567,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 36,
        apart_ind: 4,
        q_2021: "135,994000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04054593641567,
          46.94238930007583
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Олега Кошового, 4/А",
        adrs_full: "м. Миколаїв, вул. Олега Кошового, 4/А",
        lat: 46.9424864,
        lon: 32.0397892,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 36,
        apart_ind: 3,
        q_2021: "145,730000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0397892,
          46.9424864
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Олега Кошового, 5",
        adrs_full: "м. Миколаїв, вул. Олега Кошового, 5",
        lat: 46.942281320730395,
        lon: 32.04130077784524,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 36,
        apart_ind: 4,
        q_2021: "135,053000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04130077784524,
          46.942281320730395
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Олега Кошового, 6",
        adrs_full: "м. Миколаїв, вул. Олега Кошового, 6",
        lat: 46.9419607,
        lon: 32.0407979,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 0,
        q_2021: "99,357000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0407979,
          46.9419607
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Олега Кошового, 6/А",
        adrs_full: "м. Миколаїв, вул. Олега Кошового, 6/А",
        lat: 46.9419395,
        lon: 32.0401909,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 36,
        apart_ind: 1,
        q_2021: "134,764000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0401909,
          46.9419395
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Олега Ольжича, 1/А",
        adrs_full: "м. Миколаїв, вул. Олега Ольжича, 1/А",
        lat: 46.851672352535104,
        lon: 32.002361514101544,
        build_s: "5 327,00 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 108,
        apart_ind: 0,
        q_2021: "252,780410 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.002361514101544,
          46.851672352535104
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Олега Ольжича, 1/Б",
        adrs_full: "м. Миколаїв, вул. Олега Ольжича, 1/Б",
        lat: 46.85155401486323,
        lon: 32.00323438499144,
        build_s: "6 797,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 107,
        apart_ind: 0,
        q_2021: "302,929912 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00323438499144,
          46.85155401486323
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Олега Ольжича, 1/В",
        adrs_full: "м. Миколаїв, вул. Олега Ольжича, 1/В",
        lat: 46.8504291,
        lon: 32.006732,
        build_s: "5 440,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 107,
        apart_ind: 0,
        q_2021: "270,030106 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.006732,
          46.8504291
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Олега Ольжича, 3/А",
        adrs_full: "м. Миколаїв, вул. Олега Ольжича, 3/А",
        lat: 46.8519827,
        lon: 32.0054599,
        build_s: "4 341,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 0,
        q_2021: "330,110052 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0054599,
          46.8519827
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Олега Ольжича, 3/Б",
        adrs_full: "м. Миколаїв, вул. Олега Ольжича, 3/Б",
        lat: 46.8528204,
        lon: 32.005632,
        build_s: "4 787,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 0,
        q_2021: "301,490077 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.005632,
          46.85282040000001
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Олега Ольжича, 3/В",
        adrs_full: "м. Миколаїв, вул. Олега Ольжича, 3/В",
        lat: 46.8503607,
        lon: 32.0069176,
        build_s: "4 797,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 0,
        q_2021: "299,080066 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0069176,
          46.8503607
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Олега Ольжича, 3/Г",
        adrs_full: "м. Миколаїв, вул. Олега Ольжича, 3/Г",
        lat: 46.8523461,
        lon: 32.0063853,
        build_s: "5 928,00 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 161,
        apart_ind: 0,
        q_2021: "465,190141 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0063853,
          46.8523461
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Олега Ольжича, 3/Д",
        adrs_full: "м. Миколаїв, вул. Олега Ольжича, 3/Д",
        lat: 46.8476347,
        lon: 32.0181345,
        build_s: "10 074,30 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 176,
        apart_ind: 6,
        q_2021: "699,090410 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0181345,
          46.8476347
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Олега Ольжича, 5/А",
        adrs_full: "м. Миколаїв, вул. Олега Ольжича, 5/А",
        lat: 46.851062,
        lon: 32.0051745,
        build_s: "8 497,75 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 136,
        apart_ind: 1,
        q_2021: "494,889948 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0051745,
          46.851062
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Олега Ольжича, 5/Б",
        adrs_full: "м. Миколаїв, вул. Олега Ольжича, 5/Б",
        lat: 46.851062,
        lon: 32.0051745,
        build_s: "4 173,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 66,
        apart_ind: 0,
        q_2021: "233,570164 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0051745,
          46.851062
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Олега Ольжича, 5/В",
        adrs_full: "м. Миколаїв, вул. Олега Ольжича, 5/В",
        lat: 46.8502204,
        lon: 32.0072662,
        build_s: "2 317,70 м2",
        bld_floor: 10,
        bld_porch: 1,
        bld_apart: 34,
        apart_ind: 1,
        q_2021: "122,319962 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0072662,
          46.8502204
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Олега Ольжича, 7/А",
        adrs_full: "м. Миколаїв, вул. Олега Ольжича, 7/А",
        lat: 46.8515391,
        lon: 32.0082311,
        build_s: "14 158,70 м2",
        bld_floor: 9,
        bld_porch: 7,
        bld_apart: 238,
        apart_ind: 2,
        q_2021: "928,670669 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0082311,
          46.8515391
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Олеся Бердника,26",
        adrs_full: "м. Миколаїв, вул. Олеся Бердника,26",
        lat: 46.94948118753997,
        lon: 32.03874880607217,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 4,
        q_2021: "268,961000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03874880607217,
          46.94948118753997
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Ольшанцiв, 282",
        adrs_full: "м. Миколаїв, вул. Ольшанцiв, 282",
        lat: 46.8678278,
        lon: 32.048735,
        build_s: "235,50 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 4,
        apart_ind: 1,
        q_2021: "25,955900 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.048735,
          46.8678278
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Ольшанцiв, 79",
        adrs_full: "м. Миколаїв, вул. Ольшанцiв, 79",
        lat: 46.8706212,
        lon: 32.0194407,
        build_s: "1 102,50 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 23,
        apart_ind: 2,
        q_2021: "108,490079 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0194407,
          46.8706212
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Остапа Вишнi, 91",
        adrs_full: "м. Миколаїв, вул. Остапа Вишнi, 91",
        lat: 46.8631529,
        lon: 32.029334,
        build_s: "381,70 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "48,860009 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.029334,
          46.8631529
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Остапа Вишнi, 93",
        adrs_full: "м. Миколаїв, вул. Остапа Вишнi, 93",
        lat: 46.8624824,
        lon: 32.0295609,
        build_s: "287,70 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "48,112200 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0295609,
          46.8624824
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Остапа Вишнi, 93/1",
        adrs_full: "м. Миколаїв, вул. Остапа Вишнi, 93/1",
        lat: 46.8624862,
        lon: 32.0300542,
        build_s: "1 769,10 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 0,
        q_2021: "140,170110 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0300542,
          46.8624862
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Остапа Вишнi, 93/А",
        adrs_full: "м. Миколаїв, вул. Остапа Вишнi, 93/А",
        lat: 46.8631299,
        lon: 32.0298119,
        build_s: "368,35 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "50,069968 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0298119,
          46.8631299
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Павла Ходченка, 58",
        adrs_full: "м. Миколаїв, вул. Павла Ходченка, 58",
        lat: 46.9997431,
        lon: 31.9467761,
        build_s: "384,90 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "25,639994 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9467761,
          46.9997431
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Павла Ходченка, 58/А",
        adrs_full: "м. Миколаїв, вул. Павла Ходченка, 58/А",
        lat: 47.0001837,
        lon: 31.9467832,
        build_s: "581,10 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 18,
        apart_ind: 0,
        q_2021: "33,629969 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9467832,
          47.0001837
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Паркова, 24/1",
        adrs_full: "м. Миколаїв, вул. Паркова, 24/1",
        lat: 46.9440103,
        lon: 32.0298428,
        build_s: "?",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: null,
        apart_ind: null,
        q_2021: "230,477300 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0298428,
          46.9440103
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Паркова, 24/2",
        adrs_full: "м. Миколаїв, вул. Паркова, 24/2",
        lat: 46.944141,
        lon: 32.0303694,
        build_s: "?",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: null,
        apart_ind: null,
        q_2021: "223,275100 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0303694,
          46.944141
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Паркова, 24/3",
        adrs_full: "м. Миколаїв, вул. Паркова, 24/3",
        lat: 46.9442998,
        lon: 32.0310762,
        build_s: "?",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: null,
        apart_ind: null,
        q_2021: "187,865000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0310762,
          46.9442998
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Паркова, 24/4",
        adrs_full: "м. Миколаїв, вул. Паркова, 24/4",
        lat: 46.9443795,
        lon: 32.0319014,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: null,
        apart_ind: null,
        q_2021: "256,110300 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0319014,
          46.9443795
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Паркова, 26",
        adrs_full: "м. Миколаїв, вул. Паркова, 26",
        lat: 46.944152113995386,
        lon: 32.03364411202228,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 38,
        apart_ind: 1,
        q_2021: "177,924000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03364411202228,
          46.944152113995386
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Паркова, 28",
        adrs_full: "м. Миколаїв, вул. Паркова, 28",
        lat: 46.94472354352888,
        lon: 32.0343913098016,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "265,371000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0343913098016,
          46.94472354352888
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Гастелло,14/1",
        address: "вул. Паркова, 28/А",
        adrs_full: "м. Миколаїв, вул. Паркова, 28/А",
        lat: 46.9446183,
        lon: 32.0339052,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 1,
        q_2021: "321,285000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0339052,
          46.94461829999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Гастелло,14/1",
        address: "вул. Паркова, 28/Б",
        adrs_full: "м. Миколаїв, вул. Паркова, 28/Б",
        lat: 46.94521403611758,
        lon: 32.03370158877454,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 30,
        apart_ind: 4,
        q_2021: "95,207000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03370158877454,
          46.94521403611758
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Паркова, 30",
        adrs_full: "м. Миколаїв, вул. Паркова, 30",
        lat: 46.944875596712016,
        lon: 32.03486908530472,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 2,
        q_2021: "298,878000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03486908530472,
          46.944875596712016
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Паркова, 30/А",
        adrs_full: "м. Миколаїв, вул. Паркова, 30/А",
        lat: 46.9452848,
        lon: 32.0347412,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 30,
        apart_ind: 6,
        q_2021: "117,915000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0347412,
          46.9452848
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Паркова, 32",
        adrs_full: "м. Миколаїв, вул. Паркова, 32",
        lat: 46.94461808703531,
        lon: 32.035504921876544,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 37,
        apart_ind: 2,
        q_2021: "132,576000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.035504921876544,
          46.94461808703531
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Паркова, 34",
        adrs_full: "м. Миколаїв, вул. Паркова, 34",
        lat: 46.94525082288057,
        lon: 32.038059045054865,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 119,
        apart_ind: 7,
        q_2021: "487,498000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.038059045054865,
          46.94525082288057
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Паркова, 34/А",
        adrs_full: "м. Миколаїв, вул. Паркова, 34/А",
        lat: 46.9459111,
        lon: 32.0386094,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 1,
        q_2021: "261,470000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0386094,
          46.9459111
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Передова, 52/А",
        adrs_full: "м. Миколаїв, вул. Передова, 52/А",
        lat: 46.941186790527816,
        lon: 32.02771902383756,
        build_s: "2 263,30 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 46,
        apart_ind: 6,
        q_2021: "173,080093 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02771902383756,
          46.941186790527816
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Передова, 52/Б",
        adrs_full: "м. Миколаїв, вул. Передова, 52/Б",
        lat: 46.94194020273108,
        lon: 32.02734160312279,
        build_s: "2 235,90 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 45,
        apart_ind: 1,
        q_2021: "185,439984 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02734160312279,
          46.94194020273108
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Передова, 52/В",
        adrs_full: "м. Миколаїв, вул. Передова, 52/В",
        lat: 46.93961475750083,
        lon: 32.02825125298028,
        build_s: "2 979,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 4,
        q_2021: "204,509760 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02825125298028,
          46.93961475750083
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Передова, 52/Г",
        adrs_full: "м. Миколаїв, вул. Передова, 52/Г",
        lat: 46.9413735,
        lon: 32.0285667,
        build_s: "2 956,10 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "214,589899 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0285667,
          46.9413735
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Передова, 52/Д",
        adrs_full: "м. Миколаїв, вул. Передова, 52/Д",
        lat: 46.93973794348938,
        lon: 32.02806461010297,
        build_s: "2 951,80 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 3,
        q_2021: "191,240060 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02806461010297,
          46.93973794348938
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Першотравнева, 109",
        adrs_full: "м. Миколаїв, вул. Першотравнева, 109",
        lat: 46.97001119714032,
        lon: 32.07534653976398,
        build_s: "5 689,00 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 5,
        q_2021: "446,900464 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07534653976398,
          46.970011197140316
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Першотравнева, 111",
        adrs_full: "м. Миколаїв, вул. Першотравнева, 111",
        lat: 46.96992049839198,
        lon: 32.075935676474586,
        build_s: "6 505,00 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 101,
        apart_ind: 3,
        q_2021: "428,347900 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.075935676474586,
          46.96992049839198
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 27",
        adrs_full: "м. Миколаїв, вул. Південна, 27",
        lat: 46.94569067704289,
        lon: 32.02967524550659,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: null,
        q_2021: "243,412500 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02967524550659,
          46.94569067704289
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Пеpедова,69-к",
        address: "вул. Південна, 27-А",
        adrs_full: "м. Миколаїв, вул. Південна, 27-А",
        lat: 46.94516329556832,
        lon: 32.03046745192574,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 90,
        apart_ind: null,
        q_2021: "341,629600 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03046745192574,
          46.94516329556832
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Пеpедова,69-к",
        address: "вул. Південна, 29",
        adrs_full: "м. Миколаїв, вул. Південна, 29",
        lat: 46.945911,
        lon: 32.0307762,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 5,
        q_2021: "217,727000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0307762,
          46.945911
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Пеpедова,69-к",
        address: "вул. Південна, 31/А",
        adrs_full: "м. Миколаїв, вул. Південна, 31/А",
        lat: 46.946397471088865,
        lon: 32.032391842297095,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 102,
        apart_ind: 6,
        q_2021: "268,154000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.032391842297095,
          46.946397471088865
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Пеpедова,69-к",
        address: "вул. Південна, 31/Б",
        adrs_full: "м. Миколаїв, вул. Південна, 31/Б",
        lat: 46.94598655283142,
        lon: 32.03263467656081,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 100,
        apart_ind: 1,
        q_2021: "245,296000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03263467656081,
          46.94598655283142
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Пеpедова,69-к",
        address: "вул. Південна, 33",
        adrs_full: "м. Миколаїв, вул. Південна, 33",
        lat: 46.9464251,
        lon: 32.0334516,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 68,
        apart_ind: 2,
        q_2021: "163,486000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0334516,
          46.9464251
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Південна, 33/А",
        adrs_full: "м. Миколаїв, вул. Південна, 33/А",
        lat: 46.94562114155309,
        lon: 32.03412907211944,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "322,278000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03412907211944,
          46.94562114155309
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Південна, 33/Б",
        adrs_full: "м. Миколаїв, вул. Південна, 33/Б",
        lat: 46.94591788513052,
        lon: 32.03354712000286,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 30,
        apart_ind: 0,
        q_2021: "110,997000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03354712000286,
          46.94591788513052
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Південна, 35",
        adrs_full: "м. Миколаїв, вул. Південна, 35",
        lat: 46.94648212450018,
        lon: 32.03403169600978,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 112,
        apart_ind: 6,
        q_2021: "292,875000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03403169600978,
          46.94648212450018
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Південна, 35/А",
        adrs_full: "м. Миколаїв, вул. Південна, 35/А",
        lat: 46.94606231137063,
        lon: 32.03442141433038,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 30,
        apart_ind: 2,
        q_2021: "124,967000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03442141433038,
          46.94606231137063
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 37",
        adrs_full: "м. Миколаїв, вул. Південна, 37",
        lat: 46.946881677302734,
        lon: 32.03434820223741,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 17,
        apart_ind: 1,
        q_2021: "62,609000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03434820223741,
          46.946881677302734
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 39",
        adrs_full: "м. Миколаїв, вул. Південна, 39",
        lat: 46.947307,
        lon: 32.0362986,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 57,
        apart_ind: 4,
        q_2021: "179,716000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0362986,
          46.947307
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 39/А",
        adrs_full: "м. Миколаїв, вул. Південна, 39/А",
        lat: 46.947548719230106,
        lon: 32.03726155511733,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "237,021000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03726155511733,
          46.947548719230106
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 41",
        adrs_full: "м. Миколаїв, вул. Південна, 41",
        lat: 46.94716492590131,
        lon: 32.03703524040533,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 61,
        apart_ind: 0,
        q_2021: "195,837000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03703524040533,
          46.94716492590131
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 43",
        adrs_full: "м. Миколаїв, вул. Південна, 43",
        lat: 46.9470144,
        lon: 32.0377086,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 0,
        q_2021: "225,240000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0377086,
          46.9470144
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 45",
        adrs_full: "м. Миколаїв, вул. Південна, 45",
        lat: 46.94751193404626,
        lon: 32.03848293610275,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 3,
        q_2021: "261,406000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03848293610275,
          46.94751193404626
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 47",
        adrs_full: "м. Миколаїв, вул. Південна, 47",
        lat: 46.9478489,
        lon: 32.0391545,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 63,
        apart_ind: 2,
        q_2021: "234,939000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0391545,
          46.9478489
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 48",
        adrs_full: "м. Миколаїв, вул. Південна, 48",
        lat: 46.9467504,
        lon: 32.0324091,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 89,
        apart_ind: 1,
        q_2021: "273,434000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0324091,
          46.9467504
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 49",
        adrs_full: "м. Миколаїв, вул. Південна, 49",
        lat: 46.9481339,
        lon: 32.0406726,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 90,
        apart_ind: 2,
        q_2021: "261,723000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0406726,
          46.94813389999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 49/А",
        adrs_full: "м. Миколаїв, вул. Південна, 49/А",
        lat: 46.94729612712518,
        lon: 32.04002043922558,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 90,
        apart_ind: 6,
        q_2021: "283,911000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04002043922558,
          46.94729612712518
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 50",
        adrs_full: "м. Миколаїв, вул. Південна, 50",
        lat: 46.94713427136339,
        lon: 32.033841688358166,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 76,
        apart_ind: 3,
        q_2021: "229,508000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.033841688358166,
          46.947134271363396
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 51",
        adrs_full: "м. Миколаїв, вул. Південна, 51",
        lat: 46.948483340408345,
        lon: 32.04092528782747,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 59,
        apart_ind: 1,
        q_2021: "194,308000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04092528782747,
          46.948483340408345
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 52",
        adrs_full: "м. Миколаїв, вул. Південна, 52",
        lat: 46.9478524,
        lon: 32.0369827,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 120,
        apart_ind: 3,
        q_2021: "406,970000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0369827,
          46.9478524
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 54",
        adrs_full: "м. Миколаїв, вул. Південна, 54",
        lat: 46.94821732432237,
        lon: 32.03842108248156,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 90,
        apart_ind: 5,
        q_2021: "260,827000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03842108248156,
          46.94821732432237
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 54/1",
        adrs_full: "м. Миколаїв, вул. Південна, 54/1",
        lat: 46.94859469558667,
        lon: 32.03807973724142,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 81,
        apart_ind: 3,
        q_2021: "261,734000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03807973724142,
          46.94859469558667
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 54/2",
        adrs_full: "м. Миколаїв, вул. Південна, 54/2",
        lat: 46.94895256305124,
        lon: 32.03788332772381,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 4,
        q_2021: "265,260000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03788332772381,
          46.948952563051236
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 54/3",
        adrs_full: "м. Миколаїв, вул. Південна, 54/3",
        lat: 46.94935335072394,
        lon: 32.037655570263716,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 69,
        apart_ind: 7,
        q_2021: "351,597000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.037655570263716,
          46.94935335072393
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 54/4",
        adrs_full: "м. Миколаїв, вул. Південна, 54/4",
        lat: 46.9495659503751,
        lon: 32.03752693919763,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 36,
        apart_ind: 2,
        q_2021: "174,302000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03752693919763,
          46.9495659503751
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 70",
        adrs_full: "м. Миколаїв, вул. Південна, 70",
        lat: 46.94889782795398,
        lon: 32.04090716330143,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 30,
        apart_ind: 1,
        q_2021: "144,739000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04090716330143,
          46.94889782795398
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 72",
        adrs_full: "м. Миколаїв, вул. Південна, 72",
        lat: 46.949198896497045,
        lon: 32.04210942352782,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 3,
        q_2021: "115,711000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04210942352782,
          46.949198896497045
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 74",
        adrs_full: "м. Миколаїв, вул. Південна, 74",
        lat: 46.9494252,
        lon: 32.0430183,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 2,
        q_2021: "44,728000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0430183,
          46.9494252
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 74/А",
        adrs_full: "м. Миколаїв, вул. Південна, 74/А",
        lat: 46.9496113,
        lon: 32.04249,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "49,315000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04249,
          46.9496113
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 76",
        adrs_full: "м. Миколаїв, вул. Південна, 76",
        lat: 46.949598,
        lon: 32.0438154,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 4,
        q_2021: "106,800000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0438154,
          46.949598
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Південна, 76/А",
        adrs_full: "м. Миколаїв, вул. Південна, 76/А",
        lat: 46.9498003,
        lon: 32.043235,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 13,
        apart_ind: 0,
        q_2021: "82,581000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.043235,
          46.9498003
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 13",
        adrs_full: "м. Миколаїв, вул. Погранична, 13",
        lat: 46.958929,
        lon: 31.9877437,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 23,
        apart_ind: 6,
        q_2021: "86,509000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9877437,
          46.958929
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 131/А",
        adrs_full: "м. Миколаїв, вул. Погранична, 131/А",
        lat: 46.9575816,
        lon: 32.018031,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 0,
        q_2021: "87,673000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.018031,
          46.9575816
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 150/1",
        adrs_full: "м. Миколаїв, вул. Погранична, 150/1",
        lat: 46.959253,
        lon: 32.0138888,
        build_s: "434,00 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 10,
        apart_ind: 4,
        q_2021: "35,395465 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0138888,
          46.959253
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 150/10",
        adrs_full: "м. Миколаїв, вул. Погранична, 150/10",
        lat: 46.9597151,
        lon: 32.0149757,
        build_s: "440,00 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 11,
        apart_ind: 4,
        q_2021: "50,384037 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0149757,
          46.9597151
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 150/2",
        adrs_full: "м. Миколаїв, вул. Погранична, 150/2",
        lat: 46.9592161,
        lon: 32.0142776,
        build_s: "425,00 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 7,
        apart_ind: 4,
        q_2021: "36,038008 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0142776,
          46.9592161
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 150/3",
        adrs_full: "м. Миколаїв, вул. Погранична, 150/3",
        lat: 46.9591954,
        lon: 32.0146143,
        build_s: "457,00 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 12,
        apart_ind: 5,
        q_2021: "36,544998 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0146143,
          46.9591954
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 150/4",
        adrs_full: "м. Миколаїв, вул. Погранична, 150/4",
        lat: 46.9592072,
        lon: 32.0148468,
        build_s: "464,00 м2",
        bld_floor: 1,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 3,
        q_2021: "43,293279 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0148468,
          46.95920719999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 150/5",
        adrs_full: "м. Миколаїв, вул. Погранична, 150/5",
        lat: 46.9603923,
        lon: 32.0141563,
        build_s: "221,00 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 5,
        apart_ind: 0,
        q_2021: "30,845300 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0141563,
          46.9603923
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 150/6",
        adrs_full: "м. Миколаїв, вул. Погранична, 150/6",
        lat: 46.9604344,
        lon: 32.0144267,
        build_s: "441,00 м2",
        bld_floor: 1,
        bld_porch: 2,
        bld_apart: 11,
        apart_ind: 1,
        q_2021: "42,254000 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0144267,
          46.9604344
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 150/7",
        adrs_full: "м. Миколаїв, вул. Погранична, 150/7",
        lat: 46.9604138,
        lon: 32.0147634,
        build_s: "449,00 м2",
        bld_floor: 1,
        bld_porch: 3,
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "44,587034 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0147634,
          46.9604138
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 150/8",
        adrs_full: "м. Миколаїв, вул. Погранична, 150/8",
        lat: 46.9604093,
        lon: 32.015048,
        build_s: "423,00 м2",
        bld_floor: 1,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 4,
        q_2021: "32,062940 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.015048,
          46.96040929999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 150/9",
        adrs_full: "м. Миколаїв, вул. Погранична, 150/9",
        lat: 46.9597195,
        lon: 32.0146911,
        build_s: "310,00 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 9,
        apart_ind: 2,
        q_2021: "36,361021 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0146911,
          46.9597195
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 20",
        adrs_full: "м. Миколаїв, вул. Погранична, 20",
        lat: 46.96088629324117,
        lon: 31.98203947793774,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 98,
        apart_ind: 0,
        q_2021: "294,869000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98203947793774,
          46.96088629324117
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,150/9-А",
        address: "вул. Погранична, 22",
        adrs_full: "м. Миколаїв, вул. Погранична, 22",
        lat: 46.96124508830889,
        lon: 31.98310645305305,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 136,
        apart_ind: 11,
        q_2021: "636,608000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.983106453053054,
          46.9612450883089
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,150/9-А",
        address: "вул. Погранична, 232",
        adrs_full: "м. Миколаїв, вул. Погранична, 232",
        lat: 46.9578833,
        lon: 32.0259163,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 6,
        q_2021: "321,977000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0259163,
          46.9578833
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,150/9-А",
        address: "вул. Погранична, 234",
        adrs_full: "м. Миколаїв, вул. Погранична, 234",
        lat: 46.9578629,
        lon: 32.0267249,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 22,
        apart_ind: 7,
        q_2021: "127,079000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0267249,
          46.9578629
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,150/9-А",
        address: "вул. Погранична, 236",
        adrs_full: "м. Миколаїв, вул. Погранична, 236",
        lat: 46.9577171,
        lon: 32.028138,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 22,
        apart_ind: 8,
        q_2021: "95,969000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.028138,
          46.9577171
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,150/9-А",
        address: "вул. Погранична, 238",
        adrs_full: "м. Миколаїв, вул. Погранична, 238",
        lat: 46.9575918,
        lon: 32.0287424,
        build_s: "1 288,40 м2",
        bld_floor: 2,
        bld_porch: 5,
        bld_apart: 23,
        apart_ind: 5,
        q_2021: "105,460021 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "5",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0287424,
          46.9575918
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,150/9-А",
        address: "вул. Погранична, 238/А",
        adrs_full: "м. Миколаїв, вул. Погранична, 238/А",
        lat: 46.9580046,
        lon: 32.029514,
        build_s: "1 026,00 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 29,
        apart_ind: 5,
        q_2021: "70,069975 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.029514,
          46.9580046
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,150/9-А",
        address: "вул. Погранична, 240",
        adrs_full: "м. Миколаїв, вул. Погранична, 240",
        lat: 46.957504,
        lon: 32.0299019,
        build_s: "952,00 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 29,
        apart_ind: 5,
        q_2021: "55,206357 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0299019,
          46.957504
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,150/9-А",
        address: "вул. Погранична, 240/А",
        adrs_full: "м. Миколаїв, вул. Погранична, 240/А",
        lat: 46.9580399,
        lon: 32.0302113,
        build_s: "966,60 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 31,
        apart_ind: 1,
        q_2021: "76,960006 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0302113,
          46.9580399
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,150/9-А",
        address: "вул. Погранична, 242",
        adrs_full: "м. Миколаїв, вул. Погранична, 242",
        lat: 46.9574348,
        lon: 32.0308669,
        build_s: "603,70 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 33,
        apart_ind: 9,
        q_2021: "41,658494 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0308669,
          46.95743479999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,150/9-А",
        address: "вул. Погранична, 242/А",
        adrs_full: "м. Миколаїв, вул. Погранична, 242/А",
        lat: 46.9579005,
        lon: 32.0307256,
        build_s: "890,40 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 32,
        apart_ind: 1,
        q_2021: "124,476600 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0307256,
          46.9579005
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 242/Б",
        adrs_full: "м. Миколаїв, вул. Погранична, 242/Б",
        lat: 46.95856862274691,
        lon: 32.03103924179388,
        build_s: "892,00 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 30,
        apart_ind: 0,
        q_2021: "72,569935 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03103924179388,
          46.95856862274691
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 244",
        adrs_full: "м. Миколаїв, вул. Погранична, 244",
        lat: 46.9573888,
        lon: 32.0318249,
        build_s: "725,40 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 29,
        apart_ind: 5,
        q_2021: "84,438500 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0318249,
          46.9573888
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 244/А",
        adrs_full: "м. Миколаїв, вул. Погранична, 244/А",
        lat: 46.9579432,
        lon: 32.0319399,
        build_s: "1 002,20 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 31,
        apart_ind: 1,
        q_2021: "94,190086 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0319399,
          46.9579432
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 244/Б",
        adrs_full: "м. Миколаїв, вул. Погранична, 244/Б",
        lat: 46.95852586697002,
        lon: 32.03184465591602,
        build_s: "895,00 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 29,
        apart_ind: 2,
        q_2021: "83,630080 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03184465591602,
          46.95852586697002
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 246/А",
        adrs_full: "м. Миколаїв, вул. Погранична, 246/А",
        lat: 46.9581416,
        lon: 32.0325877,
        build_s: "1 032,20 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 28,
        apart_ind: 2,
        q_2021: "79,489984 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0325877,
          46.9581416
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,242-А/1",
        address: "вул. Погранична, 246/Б",
        adrs_full: "м. Миколаїв, вул. Погранична, 246/Б",
        lat: 46.9587286,
        lon: 32.0325985,
        build_s: "997,40 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 27,
        apart_ind: 2,
        q_2021: "66,169946 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0325985,
          46.9587286
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,242-А/1",
        address: "вул. Погранична, 248",
        adrs_full: "м. Миколаїв, вул. Погранична, 248",
        lat: 46.9581349,
        lon: 32.0330146,
        build_s: "784,30 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 18,
        apart_ind: 10,
        q_2021: "24,861900 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0330146,
          46.9581349
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,242-А/1",
        address: "вул. Погранична, 248/А",
        adrs_full: "м. Миколаїв, вул. Погранична, 248/А",
        lat: 46.9587382,
        lon: 32.0329732,
        build_s: "764,00 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 25,
        apart_ind: 7,
        q_2021: "52,056525 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0329732,
          46.9587382
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,242-А/1",
        address: "вул. Погранична, 41",
        adrs_full: "м. Миколаїв, вул. Погранична, 41",
        lat: 46.9576833,
        lon: 31.9989786,
        build_s: "?",
        bld_floor: null,
        bld_porch: "?",
        bld_apart: null,
        apart_ind: null,
        q_2021: "247,279400 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9989786,
          46.9576833
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,242-А/1",
        address: "вул. Погранична, 43",
        adrs_full: "м. Миколаїв, вул. Погранична, 43",
        lat: 46.9589633,
        lon: 32.0002157,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 2,
        q_2021: "226,089000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0002157,
          46.9589633
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,242-А/1",
        address: "вул. Погранична, 43/А",
        adrs_full: "м. Миколаїв, вул. Погранична, 43/А",
        lat: 46.9582782,
        lon: 31.9999776,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 0,
        q_2021: "175,163000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9999776,
          46.9582782
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,242-А/1",
        address: "вул. Погранична, 47",
        adrs_full: "м. Миколаїв, вул. Погранична, 47",
        lat: 46.9577261,
        lon: 32.0047239,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "250,494000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0047239,
          46.9577261
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,242-А/1",
        address: "вул. Погранична, 47/А",
        adrs_full: "м. Миколаїв, вул. Погранична, 47/А",
        lat: 46.9570904,
        lon: 32.0048699,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 90,
        apart_ind: 1,
        q_2021: "259,548000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0048699,
          46.9570904
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,242-А/1",
        address: "вул. Погранична, 52",
        adrs_full: "м. Миколаїв, вул. Погранична, 52",
        lat: 46.96008589557323,
        lon: 31.99099833604885,
        build_s: "?",
        bld_floor: 1,
        bld_porch: "?",
        bld_apart: 13,
        apart_ind: 1,
        q_2021: "59,203000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.990998336048854,
          46.96008589557323
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,242-А/1",
        address: "вул. Погранична, 54",
        adrs_full: "м. Миколаїв, вул. Погранична, 54",
        lat: 46.96004555691229,
        lon: 31.991620478973235,
        build_s: "?",
        bld_floor: 1,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 1,
        q_2021: "59,645000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.991620478973235,
          46.960045556912284
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,242-А/1",
        address: "вул. Погранична, 69",
        adrs_full: "м. Миколаїв, вул. Погранична, 69",
        lat: 46.9580262,
        lon: 32.0093055,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 36,
        apart_ind: 5,
        q_2021: "141,423000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0093055,
          46.9580262
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,242-А/1",
        address: "вул. Погранична, 69/А",
        adrs_full: "м. Миколаїв, вул. Погранична, 69/А",
        lat: 46.9581773,
        lon: 32.0090235,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 6,
        q_2021: "143,322000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0090235,
          46.9581773
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,242-А/1",
        address: "вул. Погранична, 72",
        adrs_full: "м. Миколаїв, вул. Погранична, 72",
        lat: 46.9598763,
        lon: 31.9955267,
        build_s: "?",
        bld_floor: 1,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 2,
        q_2021: "30,732000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9955267,
          46.9598763
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,242-А/1",
        address: "вул. Погранична, 78",
        adrs_full: "м. Миколаїв, вул. Погранична, 78",
        lat: 46.95952964398791,
        lon: 31.999207511936085,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 3,
        q_2021: "249,200000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.999207511936085,
          46.95952964398791
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 78/А",
        adrs_full: "м. Миколаїв, вул. Погранична, 78/А",
        lat: 46.959926663839965,
        lon: 31.99924795122617,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "225,571000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.999247951226174,
          46.959926663839965
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 78/Б",
        adrs_full: "м. Миколаїв, вул. Погранична, 78/Б",
        lat: 46.96059543393516,
        lon: 31.998967986910195,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 100,
        apart_ind: 3,
        q_2021: "350,400000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.998967986910195,
          46.96059543393516
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 78/В",
        adrs_full: "м. Миколаїв, вул. Погранична, 78/В",
        lat: 46.961187766181475,
        lon: 31.999412819101117,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "249,428000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.999412819101117,
          46.961187766181475
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 78/Г",
        adrs_full: "м. Миколаїв, вул. Погранична, 78/Г",
        lat: 46.96055297259035,
        lon: 31.99969589413172,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 100,
        apart_ind: 8,
        q_2021: "290,841000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99969589413172,
          46.96055297259035
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 80",
        adrs_full: "м. Миколаїв, вул. Погранична, 80",
        lat: 46.959565736823464,
        lon: 32.00056378351124,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 144,
        apart_ind: 4,
        q_2021: "541,193000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00056378351124,
          46.959565736823464
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 80/А",
        adrs_full: "м. Миколаїв, вул. Погранична, 80/А",
        lat: 46.9600674,
        lon: 32.0001887,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 71,
        apart_ind: 0,
        q_2021: "238,091000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0001887,
          46.9600674
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 82",
        adrs_full: "м. Миколаїв, вул. Погранична, 82",
        lat: 46.9593399,
        lon: 32.0017788,
        build_s: "?",
        bld_floor: null,
        bld_porch: "?",
        bld_apart: null,
        apart_ind: null,
        q_2021: "306,714000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0017788,
          46.9593399
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Погранична, 96",
        adrs_full: "м. Миколаїв, вул. Погранична, 96",
        lat: 46.9592503,
        lon: 32.0055821,
        build_s: "6 981,40 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 104,
        apart_ind: 8,
        q_2021: "355,272748 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0055821,
          46.9592503
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Потьомкінська, 12",
        adrs_full: "м. Миколаїв, вул. Потьомкінська, 12",
        lat: 46.9752377,
        lon: 31.9782922,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 28,
        apart_ind: 2,
        q_2021: "140,777000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9782922,
          46.9752377
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Потьомкінська, 131",
        adrs_full: "м. Миколаїв, вул. Потьомкінська, 131",
        lat: 46.96757033413389,
        lon: 32.01088925700023,
        build_s: "?",
        bld_floor: null,
        bld_porch: "?",
        bld_apart: null,
        apart_ind: null,
        q_2021: "412,546000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01088925700023,
          46.96757033413389
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Потьомкінська, 131/Б",
        adrs_full: "м. Миколаїв, вул. Потьомкінська, 131/Б",
        lat: 46.9671482,
        lon: 32.0089878,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 198,
        apart_ind: 1,
        q_2021: "470,236000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0089878,
          46.9671482
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Потьомкінська, 131/В",
        adrs_full: "м. Миколаїв, вул. Потьомкінська, 131/В",
        lat: 46.96705659117766,
        lon: 32.011495193474296,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 106,
        apart_ind: 0,
        q_2021: "225,144000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.011495193474296,
          46.96705659117766
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Потьомкінська, 131/В6",
        adrs_full: "м. Миколаїв, вул. Потьомкінська, 131/В6",
        lat: 46.96689369603931,
        lon: 32.012096539520535,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 97,
        apart_ind: 1,
        q_2021: "244,167000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.012096539520535,
          46.96689369603931
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Потьомкінська, 141",
        adrs_full: "м. Миколаїв, вул. Потьомкінська, 141",
        lat: 46.96826764573389,
        lon: 32.013712774218085,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 144,
        apart_ind: 2,
        q_2021: "637,266000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.013712774218085,
          46.96826764573389
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Потьомкінська, 143",
        adrs_full: "м. Миколаїв, вул. Потьомкінська, 143",
        lat: 46.96807871958794,
        lon: 32.014664652892385,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 108,
        apart_ind: 2,
        q_2021: "307,023000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.014664652892385,
          46.96807871958794
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Потьомкінська, 143/А",
        adrs_full: "м. Миколаїв, вул. Потьомкінська, 143/А",
        lat: 46.9683628,
        lon: 32.01537,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 102,
        apart_ind: 1,
        q_2021: "338,632000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01537,
          46.9683628
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Потьомкінська, 147",
        adrs_full: "м. Миколаїв, вул. Потьомкінська, 147",
        lat: 46.9690213,
        lon: 32.0172473,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 1,
        q_2021: "306,601000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0172473,
          46.9690213
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Потьомкінська, 149",
        adrs_full: "м. Миколаїв, вул. Потьомкінська, 149",
        lat: 46.9691382,
        lon: 32.0176839,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "265,499000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0176839,
          46.9691382
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Погранична,96(САО)",
        address: "вул. Потьомкінська, 153",
        adrs_full: "м. Миколаїв, вул. Потьомкінська, 153",
        lat: 46.9693393,
        lon: 32.0186616,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 71,
        apart_ind: 5,
        q_2021: "286,460000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0186616,
          46.96933929999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Полярний,2-к",
        address: "вул. Потьомкінська, 155",
        adrs_full: "м. Миколаїв, вул. Потьомкінська, 155",
        lat: 46.9692006,
        lon: 32.0201201,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 107,
        apart_ind: 7,
        q_2021: "438,808000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0201201,
          46.9692006
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Полярний,2-к",
        address: "вул. Потьомкінська, 17",
        adrs_full: "м. Миколаїв, вул. Потьомкінська, 17",
        lat: 46.9747621,
        lon: 31.9780594,
        build_s: "?",
        bld_floor: 10,
        bld_porch: "?",
        bld_apart: 106,
        apart_ind: 8,
        q_2021: "410,169000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9780594,
          46.9747621
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Полярний,2-к",
        address: "вул. Потьомкінська, 17/А",
        adrs_full: "м. Миколаїв, вул. Потьомкінська, 17/А",
        lat: 46.9694594,
        lon: 31.9949113,
        build_s: "?",
        bld_floor: 10,
        bld_porch: "?",
        bld_apart: 34,
        apart_ind: 5,
        q_2021: "136,933000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9949113,
          46.9694594
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Потьомкінська, 52",
        adrs_full: "м. Миколаїв, вул. Потьомкінська, 52",
        lat: 46.9704608,
        lon: 31.99207,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 30,
        apart_ind: 17,
        q_2021: "126,802000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99207,
          46.9704608
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Потьомкiнська, 24",
        adrs_full: "м. Миколаїв, вул. Потьомкiнська, 24",
        lat: 46.9734748,
        lon: 31.9829371,
        build_s: "90,50 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 7,
        apart_ind: 4,
        q_2021: "18,197900 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9829371,
          46.9734748
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Потьомкiнська, 26",
        adrs_full: "м. Миколаїв, вул. Потьомкiнська, 26",
        lat: 46.9733703,
        lon: 31.983205,
        build_s: "79,50 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 3,
        apart_ind: 1,
        q_2021: "11,632300 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.983205,
          46.97337030000001
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Потьомкiнська, 28",
        adrs_full: "м. Миколаїв, вул. Потьомкiнська, 28",
        lat: 46.973126,
        lon: 31.9835155,
        build_s: "1 829,40 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 41,
        apart_ind: 5,
        q_2021: "113,963389 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9835155,
          46.973126
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Потьомкiнська, 28",
        adrs_full: "м. Миколаїв, вул. Потьомкiнська, 28",
        lat: 46.973126,
        lon: 31.9835155,
        build_s: "?",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 41,
        apart_ind: 3,
        q_2021: "44,233900 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9835155,
          46.973126
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Потьомкiнська, 5",
        adrs_full: "м. Миколаїв, вул. Потьомкiнська, 5",
        lat: 46.976746,
        lon: 31.9751412,
        build_s: "4 101,20 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "237,909874 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9751412,
          46.976746
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Потьомкiнська, 81/83",
        adrs_full: "м. Миколаїв, вул. Потьомкiнська, 81/83",
        lat: 46.9683308,
        lon: 31.9959285,
        build_s: "7 849,20 м2",
        bld_floor: 5,
        bld_porch: 9,
        bld_apart: 101,
        apart_ind: 35,
        q_2021: "552,328378 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "9",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9959285,
          46.9683308
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Потьомкiнська, 95",
        adrs_full: "м. Миколаїв, вул. Потьомкiнська, 95",
        lat: 46.9679144,
        lon: 31.9988878,
        build_s: "1 466,80 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 32,
        apart_ind: 3,
        q_2021: "117,564935 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9988878,
          46.9679144
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Привiльна, 43/А",
        adrs_full: "м. Миколаїв, вул. Привiльна, 43/А",
        lat: 46.9919707,
        lon: 32.020147,
        build_s: "3 787,20 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 70,
        apart_ind: 14,
        q_2021: "160,949683 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.020147,
          46.9919707
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Привiльна, 71/А",
        adrs_full: "м. Миколаїв, вул. Привiльна, 71/А",
        lat: 46.9944032,
        lon: 32.0188412,
        build_s: "3 942,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 68,
        apart_ind: 3,
        q_2021: "264,110033 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0188412,
          46.9944032
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Привiльна, 71/Б",
        adrs_full: "м. Миколаїв, вул. Привiльна, 71/Б",
        lat: 46.9937788,
        lon: 32.0192199,
        build_s: "1 844,88 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 35,
        apart_ind: 5,
        q_2021: "115,778949 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0192199,
          46.9937788
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Привiльна, 73/А",
        adrs_full: "м. Миколаїв, вул. Привiльна, 73/А",
        lat: 46.994597,
        lon: 32.019302,
        build_s: "3 804,50 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 70,
        apart_ind: 18,
        q_2021: "237,400026 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.019302,
          46.994597
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Пушкінська, 14",
        adrs_full: "м. Миколаїв, вул. Пушкінська, 14",
        lat: 46.9718736,
        lon: 31.9845578,
        build_s: "?",
        bld_floor: 1,
        bld_porch: "?",
        bld_apart: 9,
        apart_ind: 7,
        q_2021: "-82,505000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9845578,
          46.9718736
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Пушкінська, 17",
        adrs_full: "м. Миколаїв, вул. Пушкінська, 17",
        lat: 46.9723064,
        lon: 31.9840483,
        build_s: "?",
        bld_floor: 1,
        bld_porch: "?",
        bld_apart: 13,
        apart_ind: 11,
        q_2021: "-74,717000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9840483,
          46.9723064
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Пушкінська, 2",
        adrs_full: "м. Миколаїв, вул. Пушкінська, 2",
        lat: 46.9777781,
        lon: 31.988615,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 4,
        q_2021: "85,739000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.988615,
          46.97777809999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.В.Морська,45-а",
        address: "вул. Пушкінська, 66",
        adrs_full: "м. Миколаїв, вул. Пушкінська, 66",
        lat: 46.95972921583259,
        lon: 31.976533513056935,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 62,
        apart_ind: 6,
        q_2021: "291,489000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.976533513056935,
          46.959729215832596
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.В.Морська,45-а",
        address: "вул. Пушкінська, 66/А",
        adrs_full: "м. Миколаїв, вул. Пушкінська, 66/А",
        lat: 46.960238757591355,
        lon: 31.97683836308989,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 73,
        apart_ind: 7,
        q_2021: "357,622000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97683836308989,
          46.96023875759135
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.В.Морська,45-а",
        address: "вул. Радісна, 27",
        adrs_full: "м. Миколаїв, вул. Радісна, 27",
        lat: 46.9578506,
        lon: 31.9941134,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 13,
        q_2021: "273,813000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9941134,
          46.9578506
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.В.Морська,45-а",
        address: "вул. Райдужна, 30",
        adrs_full: "м. Миколаїв, вул. Райдужна, 30",
        lat: 46.913479,
        lon: 32.0792756,
        build_s: "3 200,24 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 4,
        q_2021: "274,050165 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0792756,
          46.913479
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Райдужна, 32",
        adrs_full: "м. Миколаїв, вул. Райдужна, 32",
        lat: 46.9133162,
        lon: 32.0797964,
        build_s: "3 201,30 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 5,
        q_2021: "267,595275 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0797964,
          46.9133162
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Потьомкiнська,81/83",
        address: "вул. Райдужна, 34",
        adrs_full: "м. Миколаїв, вул. Райдужна, 34",
        lat: 46.9130928,
        lon: 32.0802411,
        build_s: "2 186,00 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 82,
        apart_ind: 0,
        q_2021: "181,873828 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0802411,
          46.9130928
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.пр.Центральний,96/7",
        address: "вул. Райдужна, 36",
        adrs_full: "м. Миколаїв, вул. Райдужна, 36",
        lat: 46.9126322,
        lon: 32.0810404,
        build_s: "4 136,00 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 106,
        apart_ind: 0,
        q_2021: "213,001443 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0810404,
          46.9126322
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Безiменна,91-в",
        address: "вул. Райдужна, 43",
        adrs_full: "м. Миколаїв, вул. Райдужна, 43",
        lat: 46.9128088,
        lon: 32.0787237,
        build_s: "2 538,80 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "204,875932 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0787237,
          46.9128088
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Безiменна,91-в",
        address: "вул. Райдужна, 45",
        adrs_full: "м. Миколаїв, вул. Райдужна, 45",
        lat: 46.9126739,
        lon: 32.0794246,
        build_s: "1 999,70 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 49,
        apart_ind: 4,
        q_2021: "157,530024 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0794246,
          46.91267389999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Безiменна,91-в",
        address: "вул. Райдужна, 47",
        adrs_full: "м. Миколаїв, вул. Райдужна, 47",
        lat: 46.9124831,
        lon: 32.0797652,
        build_s: "1 969,80 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 48,
        apart_ind: 0,
        q_2021: "126,730017 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0797652,
          46.9124831
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Безiменна,91-в",
        address: "вул. Райдужна, 49",
        adrs_full: "м. Миколаїв, вул. Райдужна, 49",
        lat: 46.9122598,
        lon: 32.0802099,
        build_s: "1 983,40 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 48,
        apart_ind: 0,
        q_2021: "167,179982 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0802099,
          46.9122598
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Райдужна, 51",
        adrs_full: "м. Миколаїв, вул. Райдужна, 51",
        lat: 46.912069,
        lon: 32.0805504,
        build_s: "1 547,90 м2",
        bld_floor: 3,
        bld_porch: 4,
        bld_apart: 25,
        apart_ind: 4,
        q_2021: "131,497056 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0805504,
          46.912069
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Райдужна, 53",
        adrs_full: "м. Миколаїв, вул. Райдужна, 53",
        lat: 46.9119061,
        lon: 32.0810711,
        build_s: "1 548,60 м2",
        bld_floor: 3,
        bld_porch: 4,
        bld_apart: 24,
        apart_ind: 5,
        q_2021: "132,360007 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0810711,
          46.9119061
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Райдужна, 55",
        adrs_full: "м. Миколаїв, вул. Райдужна, 55",
        lat: 46.9117712,
        lon: 32.081772,
        build_s: "3 143,20 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "249,460007 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.081772,
          46.9117712
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Райдужна, 57",
        adrs_full: "м. Миколаїв, вул. Райдужна, 57",
        lat: 46.9115479,
        lon: 32.0822167,
        build_s: "3 168,80 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 69,
        apart_ind: 5,
        q_2021: "205,859873 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0822167,
          46.9115479
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Райдужна, 59",
        adrs_full: "м. Миколаїв, вул. Райдужна, 59",
        lat: 46.911413,
        lon: 32.0829176,
        build_s: "3 164,80 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 3,
        q_2021: "253,511007 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0829176,
          46.911413
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Райдужна, 61",
        adrs_full: "м. Миколаїв, вул. Райдужна, 61",
        lat: 46.9111896,
        lon: 32.0833623,
        build_s: "3 184,80 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 4,
        q_2021: "236,942126 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0833623,
          46.9111896
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Райдужна, 63",
        adrs_full: "м. Миколаїв, вул. Райдужна, 63",
        lat: 46.9115596,
        lon: 32.0836666,
        build_s: "4 089,40 м2",
        bld_floor: 5,
        bld_porch: 9,
        bld_apart: 160,
        apart_ind: 1,
        q_2021: "350,999924 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "9",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0836666,
          46.9115596
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Рибна, 1/2",
        adrs_full: "м. Миколаїв, вул. Рибна, 1/2",
        lat: 46.8619353,
        lon: 32.0099713,
        build_s: "14 606,65 м2",
        bld_floor: 10,
        bld_porch: 6,
        bld_apart: 240,
        apart_ind: 29,
        q_2021: "1 036,555700 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0099713,
          46.8619353
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Рибна, 1/А",
        adrs_full: "м. Миколаїв, вул. Рибна, 1/А",
        lat: 46.8631525,
        lon: 32.0090366,
        build_s: "742,50 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 4,
        q_2021: "46,303003 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0090366,
          46.86315250000001
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Рибна, 5",
        adrs_full: "м. Миколаїв, вул. Рибна, 5",
        lat: 46.86369920142013,
        lon: 32.01311349090911,
        build_s: "3 177,56 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 4,
        q_2021: "219,980223 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01311349090911,
          46.86369920142013
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Рибна, 7",
        adrs_full: "м. Миколаїв, вул. Рибна, 7",
        lat: 46.86528121946306,
        lon: 32.01289203636365,
        build_s: "4 526,02 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 100,
        apart_ind: 0,
        q_2021: "378,194927 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01289203636365,
          46.86528121946306
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Робоча, 1",
        adrs_full: "м. Миколаїв, вул. Робоча, 1",
        lat: 46.9761403,
        lon: 31.9681486,
        build_s: "4 203,30 м2",
        bld_floor: 4,
        bld_porch: 7,
        bld_apart: 74,
        apart_ind: 12,
        q_2021: "271,819371 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "7",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9681486,
          46.9761403
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Робоча, 11",
        adrs_full: "м. Миколаїв, вул. Робоча, 11",
        lat: 46.9731077,
        lon: 31.9704694,
        build_s: "5 950,20 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 106,
        apart_ind: 13,
        q_2021: "315,190483 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9704694,
          46.9731077
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Робоча, 3",
        adrs_full: "м. Миколаїв, вул. Робоча, 3",
        lat: 46.9755163,
        lon: 31.9685277,
        build_s: "3 129,90 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "252,561272 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9685277,
          46.9755163
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Робоча, 5",
        adrs_full: "м. Миколаїв, вул. Робоча, 5",
        lat: 46.9752044,
        lon: 31.9687173,
        build_s: "3 129,70 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 69,
        apart_ind: 3,
        q_2021: "272,518028 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9687173,
          46.9752044
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Робоча, 7",
        adrs_full: "м. Миколаїв, вул. Робоча, 7",
        lat: 46.9748599,
        lon: 31.9690112,
        build_s: "3 135,40 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 3,
        q_2021: "215,410057 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9690112,
          46.9748599
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Робоча, 9",
        adrs_full: "м. Миколаїв, вул. Робоча, 9",
        lat: 46.9744872,
        lon: 31.9691248,
        build_s: "3 329,95 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "243,003108 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9691248,
          46.9744872
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Рюміна, 15",
        adrs_full: "м. Миколаїв, вул. Рюміна, 15",
        lat: 46.9684449,
        lon: 31.975735,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 38,
        apart_ind: 5,
        q_2021: "146,500000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.975735,
          46.96844489999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Рюміна, 19",
        adrs_full: "м. Миколаїв, вул. Рюміна, 19",
        lat: 46.9670069,
        lon: 31.9752762,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 30,
        apart_ind: 3,
        q_2021: "129,075000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9752762,
          46.9670069
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Рюміна, 2",
        adrs_full: "м. Миколаїв, вул. Рюміна, 2",
        lat: 46.9691953,
        lon: 31.9777252,
        build_s: "?",
        bld_floor: 1,
        bld_porch: "?",
        bld_apart: 17,
        apart_ind: 7,
        q_2021: "68,065000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9777252,
          46.9691953
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Рюміна, 2/А",
        adrs_full: "м. Миколаїв, вул. Рюміна, 2/А",
        lat: 46.968655,
        lon: 31.976874,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 71,
        apart_ind: 3,
        q_2021: "287,429000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.976874,
          46.968655
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Знаменська,2-К",
        address: "вул. Рюміна, 2/Б",
        adrs_full: "м. Миколаїв, вул. Рюміна, 2/Б",
        lat: 46.968655,
        lon: 31.976874,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 25,
        apart_ind: 2,
        q_2021: "102,786000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.976874,
          46.968655
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Садова, 11",
        adrs_full: "м. Миколаїв, вул. Садова, 11",
        lat: 46.9643721,
        lon: 32.0022401,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 71,
        apart_ind: 2,
        q_2021: "260,791000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0022401,
          46.9643721
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Садова, 13",
        adrs_full: "м. Миколаїв, вул. Садова, 13",
        lat: 46.9641173,
        lon: 32.0025841,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 71,
        apart_ind: 4,
        q_2021: "219,277000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0025841,
          46.9641173
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Садова, 15",
        adrs_full: "м. Миколаїв, вул. Садова, 15",
        lat: 46.96350183288959,
        lon: 32.00253908729616,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 106,
        apart_ind: 9,
        q_2021: "325,138000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00253908729616,
          46.96350183288959
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Садова, 16",
        adrs_full: "м. Миколаїв, вул. Садова, 16",
        lat: 46.9647955,
        lon: 32.0042268,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 2,
        q_2021: "247,730000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0042268,
          46.9647955
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Садова, 18",
        adrs_full: "м. Миколаїв, вул. Садова, 18",
        lat: 46.96348697223007,
        lon: 32.00387358386895,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 125,
        apart_ind: 5,
        q_2021: "366,773000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00387358386895,
          46.96348697223007
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Садова, 2",
        adrs_full: "м. Миколаїв, вул. Садова, 2",
        lat: 46.9706649,
        lon: 32.0049444,
        build_s: "3 336,11 м2",
        bld_floor: 4,
        bld_porch: 6,
        bld_apart: 59,
        apart_ind: 16,
        q_2021: "239,107283 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0049444,
          46.9706649
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Садова, 29/А",
        adrs_full: "м. Миколаїв, вул. Садова, 29/А",
        lat: 46.959703,
        lon: 32.002236,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 139,
        apart_ind: 3,
        q_2021: "406,277000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.002236,
          46.959703
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Садова, 31/2",
        adrs_full: "м. Миколаїв, вул. Садова, 31/2",
        lat: 46.9560222,
        lon: 32.0009941,
        build_s: "?",
        bld_floor: null,
        bld_porch: "?",
        bld_apart: null,
        apart_ind: null,
        q_2021: "177,042200 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0009941,
          46.9560222
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Садова, 31/А",
        adrs_full: "м. Миколаїв, вул. Садова, 31/А",
        lat: 46.9565354,
        lon: 32.001782,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 19,
        apart_ind: 4,
        q_2021: "81,067000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.001782,
          46.9565354
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Садова, 46/1",
        adrs_full: "м. Миколаїв, вул. Садова, 46/1",
        lat: 46.9553259,
        lon: 32.0035656,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 25,
        apart_ind: 3,
        q_2021: "115,741000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0035656,
          46.9553259
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Садова, 46/2",
        adrs_full: "м. Миколаїв, вул. Садова, 46/2",
        lat: 46.9558479,
        lon: 32.0037845,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 26,
        apart_ind: 6,
        q_2021: "120,056000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0037845,
          46.95584789999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Садова, 46/3",
        adrs_full: "м. Миколаїв, вул. Садова, 46/3",
        lat: 46.955597,
        lon: 32.0045216,
        build_s: "?",
        bld_floor: null,
        bld_porch: "?",
        bld_apart: null,
        apart_ind: null,
        q_2021: "88,337300 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0045216,
          46.955597
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Садова, 46/4",
        adrs_full: "м. Миколаїв, вул. Садова, 46/4",
        lat: 46.9562679,
        lon: 32.0046008,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 42,
        apart_ind: 1,
        q_2021: "134,808000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0046008,
          46.9562679
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Садова, 46/5",
        adrs_full: "м. Миколаїв, вул. Садова, 46/5",
        lat: 46.9561229,
        lon: 32.0039841,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 28,
        apart_ind: 5,
        q_2021: "117,720000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0039841,
          46.9561229
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Садова, 46/6",
        adrs_full: "м. Миколаїв, вул. Садова, 46/6",
        lat: 46.9564955,
        lon: 32.0038708,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 28,
        apart_ind: 6,
        q_2021: "113,503000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0038708,
          46.9564955
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Садова, 48",
        adrs_full: "м. Миколаїв, вул. Садова, 48",
        lat: 46.9558004,
        lon: 32.002855,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 2,
        q_2021: "221,275000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.002855,
          46.9558004
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Садова, 50",
        adrs_full: "м. Миколаїв, вул. Садова, 50",
        lat: 46.9551761,
        lon: 32.0027617,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 140,
        apart_ind: 6,
        q_2021: "488,511000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0027617,
          46.9551761
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Садова, 9",
        adrs_full: "м. Миколаїв, вул. Садова, 9",
        lat: 46.9647964,
        lon: 32.0026686,
        build_s: "?",
        bld_floor: 14,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 0,
        q_2021: "279,708000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0026686,
          46.9647964
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Доктора Самойловича, 24",
        adrs_full: "м. Миколаїв, вул. Доктора Самойловича, 24",
        lat: 46.85962538993232,
        lon: 32.012453029914575,
        build_s: "947,14 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 17,
        apart_ind: 2,
        q_2021: "76,905150 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.012453029914575,
          46.85962538993232
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Доктора Самойловича, 27",
        adrs_full: "м. Миколаїв, вул. Доктора Самойловича, 27",
        lat: 46.859394026864216,
        lon: 32.01480516354675,
        build_s: "1 460,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 29,
        apart_ind: 0,
        q_2021: "75,941041 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01480516354675,
          46.859394026864216
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот. Садова,2",
        address: "вул. Доктора Самойловича, 30",
        adrs_full: "м. Миколаїв, вул. Доктора Самойловича, 30",
        lat: 46.85872228593031,
        lon: 32.02419490062114,
        build_s: "4 596,60 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 0,
        q_2021: "210,660050 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02419490062114,
          46.85872228593031
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Доктора Самойловича, 30/А",
        adrs_full: "м. Миколаїв, вул. Доктора Самойловича, 30/А",
        lat: 46.85810372471217,
        lon: 32.024991747379616,
        build_s: "6 136,61 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 106,
        apart_ind: 0,
        q_2021: "382,259630 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.024991747379616,
          46.85810372471218
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Доктора Самойловича, 30/Б",
        adrs_full: "м. Миколаїв, вул. Доктора Самойловича, 30/Б",
        lat: 46.8581412015284,
        lon: 32.02621118332422,
        build_s: "8 520,80 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 137,
        apart_ind: 5,
        q_2021: "535,929881 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02621118332422,
          46.8581412015284
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Свиридова, 7",
        adrs_full: "м. Миколаїв, вул. Свиридова, 7",
        lat: 46.9550212,
        lon: 32.0545708,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 192,
        apart_ind: 5,
        q_2021: "669,578000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0545708,
          46.9550212
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Свиридова, 7/1",
        adrs_full: "м. Миколаїв, вул. Свиридова, 7/1",
        lat: 46.9550212,
        lon: 32.0545708,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 153,
        apart_ind: 4,
        q_2021: "582,705000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0545708,
          46.9550212
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Свiтла, 14",
        adrs_full: "м. Миколаїв, вул. Свiтла, 14",
        lat: 46.95869108540385,
        lon: 31.95444568321549,
        build_s: "3 944,70 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "230,976430 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95444568321549,
          46.95869108540385
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Севастопольська, 2",
        adrs_full: "м. Миколаїв, вул. Севастопольська, 2",
        lat: 46.965575,
        lon: 31.9896858,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 13,
        apart_ind: 12,
        q_2021: "3,981000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9896858,
          46.965575
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Севастопольська, 3",
        adrs_full: "м. Миколаїв, вул. Севастопольська, 3",
        lat: 46.9648416,
        lon: 31.990145,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 23,
        apart_ind: 15,
        q_2021: "-23,352000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.990145,
          46.9648416
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Севастопольська, 41",
        adrs_full: "м. Миколаїв, вул. Севастопольська, 41",
        lat: 46.9644676,
        lon: 31.9999362,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 19,
        apart_ind: 3,
        q_2021: "69,175000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9999362,
          46.96446760000001
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Севастопольська, 43/1",
        adrs_full: "м. Миколаїв, вул. Севастопольська, 43/1",
        lat: 46.9638269,
        lon: 31.999895,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 5,
        q_2021: "239,916000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.999895,
          46.9638269
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Севастопольська, 47",
        adrs_full: "м. Миколаїв, вул. Севастопольська, 47",
        lat: 46.9646596,
        lon: 32.0010108,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 0,
        q_2021: "206,311000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0010108,
          46.9646596
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Севастопольська, 47/А",
        adrs_full: "м. Миколаїв, вул. Севастопольська, 47/А",
        lat: 46.9643006,
        lon: 32.0007423,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 56,
        apart_ind: 0,
        q_2021: "113,033000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0007423,
          46.9643006
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Севастопольська, 61",
        adrs_full: "м. Миколаїв, вул. Севастопольська, 61",
        lat: 46.96423212307168,
        lon: 32.004417958927796,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 107,
        apart_ind: 2,
        q_2021: "291,382000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.004417958927796,
          46.96423212307168
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Севастопольська, 61/А",
        adrs_full: "м. Миколаїв, вул. Севастопольська, 61/А",
        lat: 46.964083518319285,
        lon: 32.00394513030526,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 1,
        q_2021: "257,678000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00394513030526,
          46.964083518319285
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Севастопольська, 65",
        adrs_full: "м. Миколаїв, вул. Севастопольська, 65",
        lat: 46.9640265,
        lon: 32.0064904,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 220,
        apart_ind: 0,
        q_2021: "535,585000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0064904,
          46.9640265
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Севастопольська, 66",
        adrs_full: "м. Миколаїв, вул. Севастопольська, 66",
        lat: 46.9646576,
        lon: 32.006157,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 71,
        apart_ind: 9,
        q_2021: "240,571000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.006157,
          46.9646576
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Севастопольська, 68",
        adrs_full: "м. Миколаїв, вул. Севастопольська, 68",
        lat: 46.9646769,
        lon: 32.0069064,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 71,
        apart_ind: 4,
        q_2021: "259,841000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0069064,
          46.9646769
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Силiкатна, 267",
        adrs_full: "м. Миколаїв, вул. Силiкатна, 267",
        lat: 47.009343,
        lon: 31.914263,
        build_s: "2 127,47 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 52,
        apart_ind: 3,
        q_2021: "126,093027 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.914263,
          47.009343
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Сінна, 31",
        adrs_full: "м. Миколаїв, вул. Сінна, 31",
        lat: 46.9633982,
        lon: 31.9809074,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 104,
        apart_ind: 3,
        q_2021: "372,965000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9809074,
          46.9633982
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Сінна, 33/Б",
        adrs_full: "м. Миколаїв, вул. Сінна, 33/Б",
        lat: 46.9630966,
        lon: 31.9819433,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 28,
        apart_ind: 5,
        q_2021: "102,150000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9819433,
          46.9630966
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-8",
        kot_name: "кот.25 мкр. Курортна,11-а",
        address: "вул. Сінна, 44",
        adrs_full: "м. Миколаїв, вул. Сінна, 44",
        lat: 46.963072,
        lon: 31.983681,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 92,
        apart_ind: 18,
        q_2021: "318,240000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.983681,
          46.963072
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Скульптора Iзмалкова, 132",
        adrs_full: "м. Миколаїв, вул. Скульптора Iзмалкова, 132",
        lat: 46.947725938273024,
        lon: 32.05189015945333,
        build_s: "9 675,60 м2",
        bld_floor: 10,
        bld_porch: 4,
        bld_apart: 159,
        apart_ind: 10,
        q_2021: "686,112841 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05189015945333,
          46.947725938273024
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Скульптора Iзмалкова, 7",
        adrs_full: "м. Миколаїв, вул. Скульптора Iзмалкова, 7",
        lat: 46.9499467,
        lon: 32.0529597,
        build_s: "3 675,62 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "237,329905 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0529597,
          46.9499467
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Соборна, 1",
        adrs_full: "м. Миколаїв, вул. Соборна, 1",
        lat: 46.974887680096415,
        lon: 31.993921342888363,
        build_s: "1 350,20 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 25,
        apart_ind: 8,
        q_2021: "79,513568 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.993921342888363,
          46.974887680096415
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Соборна, 12",
        adrs_full: "м. Миколаїв, вул. Соборна, 12",
        lat: 46.9645769,
        lon: 31.9887622,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 129,
        apart_ind: 27,
        q_2021: "449,150000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9887622,
          46.9645769
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Соборна, 13",
        adrs_full: "м. Миколаїв, вул. Соборна, 13",
        lat: 46.9654564,
        lon: 31.9878336,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 123,
        apart_ind: 22,
        q_2021: "361,381000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9878336,
          46.9654564
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Соборна, 2",
        adrs_full: "м. Миколаїв, вул. Соборна, 2",
        lat: 46.9722981,
        lon: 31.9932106,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 9,
        q_2021: "249,823000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9932106,
          46.9722981
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Соборна, 3/А",
        adrs_full: "м. Миколаїв, вул. Соборна, 3/А",
        lat: 46.970749,
        lon: 31.9914157,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 36,
        apart_ind: 5,
        q_2021: "188,028000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9914157,
          46.970749
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Соборна, 9",
        adrs_full: "м. Миколаїв, вул. Соборна, 9",
        lat: 46.9674517,
        lon: 31.9887369,
        build_s: "?",
        bld_floor: 16,
        bld_porch: "?",
        bld_apart: 107,
        apart_ind: 0,
        q_2021: "596,863000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9887369,
          46.9674517
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Спаська, 14",
        adrs_full: "м. Миколаїв, вул. Спаська, 14",
        lat: 46.97627205459598,
        lon: 31.98283073675286,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 104,
        apart_ind: 7,
        q_2021: "433,847000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98283073675286,
          46.97627205459598
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Спаська, 35",
        adrs_full: "м. Миколаїв, вул. Спаська, 35",
        lat: 46.9743372,
        lon: 31.9872072,
        build_s: "?",
        bld_floor: 1,
        bld_porch: "?",
        bld_apart: 1,
        apart_ind: 1,
        q_2021: "39,297000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9872072,
          46.9743372
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Спаська, 46",
        adrs_full: "м. Миколаїв, вул. Спаська, 46",
        lat: 46.9732385,
        lon: 31.9923578,
        build_s: "2 288,20 м2",
        bld_floor: 4,
        bld_porch: 4,
        bld_apart: 40,
        apart_ind: 4,
        q_2021: "167,373540 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9923578,
          46.9732385
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Силiкатна,267",
        address: "вул. Спаська, 48",
        adrs_full: "м. Миколаїв, вул. Спаська, 48",
        lat: 46.9731972,
        lon: 31.9930314,
        build_s: "3 023,30 м2",
        bld_floor: 4,
        bld_porch: 7,
        bld_apart: 50,
        apart_ind: 16,
        q_2021: "188,670110 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "7",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9930314,
          46.9731972
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Спаська, 50",
        adrs_full: "м. Миколаїв, вул. Спаська, 50",
        lat: 46.973075113585,
        lon: 31.99358423632894,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 45,
        apart_ind: 20,
        q_2021: "128,875000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99358423632894,
          46.973075113585
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Спаська, 52",
        adrs_full: "м. Миколаїв, вул. Спаська, 52",
        lat: 46.97255027155865,
        lon: 31.994400098091614,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 46,
        apart_ind: 10,
        q_2021: "199,131000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99440009809161,
          46.97255027155865
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Спаська, 53",
        adrs_full: "м. Миколаїв, вул. Спаська, 53",
        lat: 46.9712626,
        lon: 31.9967362,
        build_s: "486,22 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 16,
        apart_ind: 2,
        q_2021: "66,307400 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9967362,
          46.9712626
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Спаська, 55",
        adrs_full: "м. Миколаїв, вул. Спаська, 55",
        lat: 46.971189937384,
        lon: 31.99727791488549,
        build_s: "1 335,30 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 5,
        q_2021: "147,844800 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.997277914885494,
          46.971189937384
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Спаська, 57",
        adrs_full: "м. Миколаїв, вул. Спаська, 57",
        lat: 46.97101895441703,
        lon: 31.997681900381675,
        build_s: "640,60 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 15,
        apart_ind: 2,
        q_2021: "70,187500 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.997681900381675,
          46.97101895441703
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Адмiральська,27-1",
        address: "вул. Спаська, 66",
        adrs_full: "м. Миколаїв, вул. Спаська, 66",
        lat: 46.9709416,
        lon: 31.9995251,
        build_s: "701,70 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 12,
        apart_ind: 7,
        q_2021: "27,238100 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9995251,
          46.9709416
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Станiславського, 80",
        adrs_full: "м. Миколаїв, вул. Станiславського, 80",
        lat: 46.8636155,
        lon: 32.0293351,
        build_s: "612,54 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 14,
        apart_ind: 2,
        q_2021: "67,979949 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0293351,
          46.86361549999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Станiславського, 80/А",
        adrs_full: "м. Миколаїв, вул. Станiславського, 80/А",
        lat: 46.8636021,
        lon: 32.0301868,
        build_s: "6 299,15 м2",
        bld_floor: 5,
        bld_porch: 10,
        bld_apart: 113,
        apart_ind: 1,
        q_2021: "522,780097 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "10",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0301868,
          46.8636021
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Театральна, 2",
        adrs_full: "м. Миколаїв, вул. Театральна, 2",
        lat: 46.94055362158469,
        lon: 32.02777653556553,
        build_s: "4 272,40 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 3,
        q_2021: "240,230000 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02777653556553,
          46.94055362158469
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Театральна, 2/А",
        adrs_full: "м. Миколаїв, вул. Театральна, 2/А",
        lat: 46.94053644247803,
        lon: 32.028362436294195,
        build_s: "1 466,30 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 30,
        apart_ind: 3,
        q_2021: "113,961044 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.028362436294195,
          46.94053644247802
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Театральна, 25/А",
        adrs_full: "м. Миколаїв, вул. Театральна, 25/А",
        lat: 46.941188543379674,
        lon: 32.03315684993906,
        build_s: "2 754,20 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 56,
        apart_ind: 5,
        q_2021: "235,059864 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03315684993906,
          46.941188543379674
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Театральна, 25/В",
        adrs_full: "м. Миколаїв, вул. Театральна, 25/В",
        lat: 46.94080412761697,
        lon: 32.03339015353571,
        build_s: "3 373,30 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 73,
        apart_ind: 3,
        q_2021: "243,273478 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03339015353571,
          46.94080412761697
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Театральна, 27",
        adrs_full: "м. Миколаїв, вул. Театральна, 27",
        lat: 46.9422994,
        lon: 32.0345141,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: null,
        apart_ind: null,
        q_2021: "186,913000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0345141,
          46.9422994
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Спаська,46",
        address: "вул. Театральна, 29",
        adrs_full: "м. Миколаїв, вул. Театральна, 29",
        lat: 46.9421741,
        lon: 32.0351183,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 0,
        q_2021: "186,104000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0351183,
          46.9421741
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Спаська,46",
        address: "вул. Театральна, 31",
        adrs_full: "м. Миколаїв, вул. Театральна, 31",
        lat: 46.9422442,
        lon: 32.0355689,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 3,
        q_2021: "173,963000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0355689,
          46.9422442
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Театральна, 31/А",
        adrs_full: "м. Миколаїв, вул. Театральна, 31/А",
        lat: 46.9418114,
        lon: 32.0360776,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 32,
        apart_ind: 3,
        q_2021: "121,094000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0360776,
          46.9418114
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Театральна, 33",
        adrs_full: "м. Миколаїв, вул. Театральна, 33",
        lat: 46.9423771,
        lon: 32.0359532,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "203,811000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0359532,
          46.94237709999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Спаська,55",
        address: "вул. Театральна, 33/А",
        adrs_full: "м. Миколаїв, вул. Театральна, 33/А",
        lat: 46.9419794,
        lon: 32.0366872,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 32,
        apart_ind: 0,
        q_2021: "136,670000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0366872,
          46.9419794
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Спаська,55",
        address: "вул. Театральна, 35",
        adrs_full: "м. Миколаїв, вул. Театральна, 35",
        lat: 46.9430664,
        lon: 32.0367047,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 35,
        apart_ind: 4,
        q_2021: "138,361000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0367047,
          46.9430664
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Спаська,55",
        address: "вул. Театральна, 35/А",
        adrs_full: "м. Миколаїв, вул. Театральна, 35/А",
        lat: 46.9428223,
        lon: 32.0366204,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 37,
        apart_ind: 3,
        q_2021: "164,544000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0366204,
          46.9428223
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.Спаська,66-к",
        address: "вул. Театральна, 37",
        adrs_full: "м. Миколаїв, вул. Театральна, 37",
        lat: 46.94355200202194,
        lon: 32.039409302706446,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 26,
        apart_ind: 6,
        q_2021: "125,195000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.039409302706446,
          46.94355200202194
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Театральна, 37/А",
        adrs_full: "м. Миколаїв, вул. Театральна, 37/А",
        lat: 46.94251363593522,
        lon: 32.039249872484376,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 37,
        apart_ind: 2,
        q_2021: "147,995000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.039249872484376,
          46.94251363593522
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Театральна, 39",
        adrs_full: "м. Миколаїв, вул. Театральна, 39",
        lat: 46.94374550562211,
        lon: 32.04021151509367,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 25,
        apart_ind: 6,
        q_2021: "128,591000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04021151509367,
          46.94374550562211
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Пеpедова,69-к",
        address: "вул. Театральна, 4",
        adrs_full: "м. Миколаїв, вул. Театральна, 4",
        lat: 46.94063951703536,
        lon: 32.029279029458664,
        build_s: "7 947,60 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 68,
        apart_ind: 9,
        q_2021: "269,200143 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.029279029458664,
          46.94063951703536
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Пеpедова,69-к",
        address: "вул. Театральна, 4/А",
        adrs_full: "м. Миколаїв, вул. Театральна, 4/А",
        lat: 46.941103350087715,
        lon: 32.028959120471846,
        build_s: "6 033,10 м2",
        bld_floor: 5,
        bld_porch: 9,
        bld_apart: 110,
        apart_ind: 21,
        q_2021: "445,306221 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "9",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.028959120471846,
          46.94110335008771
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Полярний,2-к",
        address: "вул. Театральна, 45",
        adrs_full: "м. Миколаїв, вул. Театральна, 45",
        lat: 46.9442859,
        lon: 32.0432074,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 208,
        apart_ind: 0,
        q_2021: "350,515000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0432074,
          46.9442859
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Полярний,2-к",
        address: "вул. Театральна, 47/А",
        adrs_full: "м. Миколаїв, вул. Театральна, 47/А",
        lat: 46.9444475,
        lon: 32.0451875,
        build_s: "1 868,00 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 65,
        apart_ind: 0,
        q_2021: "160,575843 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0451875,
          46.9444475
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Театральна, 49",
        adrs_full: "м. Миколаїв, вул. Театральна, 49",
        lat: 46.945245,
        lon: 32.047494,
        build_s: "3 858,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 70,
        apart_ind: 3,
        q_2021: "259,379759 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.047494,
          46.945245
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Театральна, 49/1",
        adrs_full: "м. Миколаїв, вул. Театральна, 49/1",
        lat: 46.9446516,
        lon: 32.0483816,
        build_s: "4 425,80 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 90,
        apart_ind: 4,
        q_2021: "339,699998 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0483816,
          46.9446516
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Театральна, 49/2",
        adrs_full: "м. Миколаїв, вул. Театральна, 49/2",
        lat: 46.9442305,
        lon: 32.0491226,
        build_s: "2 755,70 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "220,680111 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0491226,
          46.9442305
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Театральна, 51",
        adrs_full: "м. Миколаїв, вул. Театральна, 51",
        lat: 46.9457812,
        lon: 32.0487472,
        build_s: "11 128,40 м2",
        bld_floor: 9,
        bld_porch: 6,
        bld_apart: 216,
        apart_ind: 11,
        q_2021: "806,291374 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0487472,
          46.9457812
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Театральна, 51/1",
        adrs_full: "м. Миколаїв, вул. Театральна, 51/1",
        lat: 46.94465,
        lon: 32.0499393,
        build_s: "4 404,80 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 90,
        apart_ind: 3,
        q_2021: "336,698179 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0499393,
          46.94465
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Театральна, 6",
        adrs_full: "м. Миколаїв, вул. Театральна, 6",
        lat: 46.94095119365628,
        lon: 32.03004824882022,
        build_s: "2 331,10 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 45,
        apart_ind: 1,
        q_2021: "187,130019 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03004824882022,
          46.94095119365628
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Театральна, 8",
        adrs_full: "м. Миколаїв, вул. Театральна, 8",
        lat: 46.9450519,
        lon: 32.0431168,
        build_s: "2 800,00 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 90,
        apart_ind: 0,
        q_2021: "226,750105 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0431168,
          46.9450519
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Театральна, 8/А",
        adrs_full: "м. Миколаїв, вул. Театральна, 8/А",
        lat: 46.94165471041722,
        lon: 32.02982563341078,
        build_s: "1 871,40 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 60,
        apart_ind: 1,
        q_2021: "197,030152 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02982563341078,
          46.94165471041722
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Терасна, 1",
        adrs_full: "м. Миколаїв, вул. Терасна, 1",
        lat: 46.9771531,
        lon: 31.9729217,
        build_s: "885,80 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 23,
        apart_ind: 2,
        q_2021: "82,677707 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9729217,
          46.9771531
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Терасна, 1/А",
        adrs_full: "м. Миколаїв, вул. Терасна, 1/А",
        lat: 46.9790526,
        lon: 31.9677328,
        build_s: "1 566,50 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 27,
        apart_ind: 9,
        q_2021: "107,163062 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9677328,
          46.9790526
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Терасна, 10",
        adrs_full: "м. Миколаїв, вул. Терасна, 10",
        lat: 46.979727,
        lon: 31.9701716,
        build_s: "2 202,00 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 6,
        q_2021: "130,977961 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9701716,
          46.979727
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Пеpедова,69-к",
        address: "вул. Терасна, 11",
        adrs_full: "м. Миколаїв, вул. Терасна, 11",
        lat: 46.9787015,
        lon: 31.970012,
        build_s: "990,90 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 26,
        apart_ind: 0,
        q_2021: "52,265600 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.970012,
          46.9787015
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Пеpедова,69-к",
        address: "вул. Терасна, 12",
        adrs_full: "м. Миколаїв, вул. Терасна, 12",
        lat: 46.9794475,
        lon: 31.9702568,
        build_s: "1 536,10 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 48,
        apart_ind: 1,
        q_2021: "102,101000 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9702568,
          46.9794475
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Терасна, 14",
        adrs_full: "м. Миколаїв, вул. Терасна, 14",
        lat: 46.9789865,
        lon: 31.9705862,
        build_s: "1 587,20 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 1,
        q_2021: "142,429966 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9705862,
          46.9789865
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Терасна, 16",
        adrs_full: "м. Миколаїв, вул. Терасна, 16",
        lat: 46.9788848,
        lon: 31.9711839,
        build_s: "1 983,00 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 36,
        apart_ind: 4,
        q_2021: "99,179995 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9711839,
          46.9788848
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Терасна, 3",
        adrs_full: "м. Миколаїв, вул. Терасна, 3",
        lat: 46.9771531,
        lon: 31.9729217,
        build_s: "870,00 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 18,
        apart_ind: 4,
        q_2021: "82,529972 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9729217,
          46.9771531
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Терасна, 5",
        adrs_full: "м. Миколаїв, вул. Терасна, 5",
        lat: 46.9792176,
        lon: 31.9690991,
        build_s: "829,70 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 15,
        apart_ind: 4,
        q_2021: "92,847039 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9690991,
          46.9792176
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Терасна, 5/А",
        adrs_full: "м. Миколаїв, вул. Терасна, 5/А",
        lat: 46.9787363,
        lon: 31.968207,
        build_s: "1 632,40 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 27,
        apart_ind: 9,
        q_2021: "155,897400 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.968207,
          46.9787363
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Терасна, 7",
        adrs_full: "м. Миколаїв, вул. Терасна, 7",
        lat: 46.9790269,
        lon: 31.9694406,
        build_s: "893,90 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 13,
        apart_ind: 0,
        q_2021: "86,490037 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9694406,
          46.9790269
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "вул. Терасна, 8",
        adrs_full: "м. Миколаїв, вул. Терасна, 8",
        lat: 46.9798288,
        lon: 31.9695739,
        build_s: "1 499,10 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 48,
        apart_ind: 4,
        q_2021: "110,349023 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9695739,
          46.9798288
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Полярний,2-к",
        address: "вул. Тернопiльська, 79/А",
        adrs_full: "м. Миколаїв, вул. Тернопiльська, 79/А",
        lat: 46.84945969108975,
        lon: 32.02068924152614,
        build_s: "8 523,00 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 6,
        q_2021: "562,720121 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02068924152614,
          46.849459691089756
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Полярний,2-к",
        address: "вул. Тернопiльська, 79/Б",
        adrs_full: "м. Миколаїв, вул. Тернопiльська, 79/Б",
        lat: 46.85086934046594,
        lon: 32.021741359254364,
        build_s: "2 861,40 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 45,
        apart_ind: 5,
        q_2021: "217,779061 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.021741359254364,
          46.85086934046594
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Полярний,2-к",
        address: "вул. Торгова, 203",
        adrs_full: "м. Миколаїв, вул. Торгова, 203",
        lat: 46.8719396,
        lon: 32.0466893,
        build_s: "745,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "64,199993 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0466893,
          46.8719396
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Торгова, 203/А",
        adrs_full: "м. Миколаїв, вул. Торгова, 203/А",
        lat: 46.8724001,
        lon: 32.0468325,
        build_s: "2 869,47 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 90,
        apart_ind: 0,
        q_2021: "278,205200 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0468325,
          46.8724001
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Торгова, 205",
        adrs_full: "м. Миколаїв, вул. Торгова, 205",
        lat: 46.8718656,
        lon: 32.0474654,
        build_s: "749,50 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "65,499963 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0474654,
          46.8718656
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Торгова, 254",
        adrs_full: "м. Миколаїв, вул. Торгова, 254",
        lat: 46.8715591,
        lon: 32.0469867,
        build_s: "511,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "53,240010 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0469867,
          46.87155910000001
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Традицiйна, 22/1",
        adrs_full: "м. Миколаїв, вул. Традицiйна, 22/1",
        lat: 46.96814279789771,
        lon: 32.0636529972527,
        build_s: "6 572,10 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 161,
        apart_ind: 4,
        q_2021: "484,849683 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: "м. Миколаїв, вул. Андрія Шептицького, 22/1",
        rename_sol: "https://mkrada.gov.ua/documents/26221.html"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0636529972527,
          46.96814279789771
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Традицiйна, 22/2",
        adrs_full: "м. Миколаїв, вул. Традицiйна, 22/2",
        lat: 46.96810710930416,
        lon: 32.06380835381784,
        build_s: "4 415,50 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 1,
        q_2021: "284,881803 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: "м. Миколаїв, вул. Андрія Шептицького, 22/2",
        rename_sol: "https://mkrada.gov.ua/documents/26221.html"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06380835381784,
          46.96810710930416
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Троїцька, 220",
        adrs_full: "м. Миколаїв, вул. Троїцька, 220",
        lat: 46.972205079466235,
        lon: 32.07326480364325,
        build_s: "4 664,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 5,
        q_2021: "200,120065 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07326480364325,
          46.97220507946623
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Троїцька, 222",
        adrs_full: "м. Миколаїв, вул. Троїцька, 222",
        lat: 46.972379115440475,
        lon: 32.074076662768846,
        build_s: "7 072,10 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 108,
        apart_ind: 15,
        q_2021: "384,580420 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.074076662768846,
          46.972379115440475
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Фалеєвська, 1",
        adrs_full: "м. Миколаїв, вул. Фалеєвська, 1",
        lat: 46.97712304324504,
        lon: 31.989500631095968,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 36,
        apart_ind: 12,
        q_2021: "150,459000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.989500631095968,
          46.97712304324504
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Фалеєвська, 15",
        adrs_full: "м. Миколаїв, вул. Фалеєвська, 15",
        lat: 46.97063,
        lon: 31.985031,
        build_s: "?",
        bld_floor: 1,
        bld_porch: "?",
        bld_apart: 22,
        apart_ind: 20,
        q_2021: "-245,809000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.985031,
          46.97063
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Фалеєвська, 24-А",
        adrs_full: "м. Миколаїв, вул. Фалеєвська, 24-А",
        lat: 46.9687841,
        lon: 31.98446,
        build_s: "?",
        bld_floor: null,
        bld_porch: "?",
        bld_apart: null,
        apart_ind: null,
        q_2021: "390,564400 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98446,
          46.9687841
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Фалеєвська, 3",
        adrs_full: "м. Миколаїв, вул. Фалеєвська, 3",
        lat: 46.9747901,
        lon: 31.9879193,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 37,
        apart_ind: 9,
        q_2021: "156,224000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9879193,
          46.9747901
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Фалеєвська, 43",
        adrs_full: "м. Миколаїв, вул. Фалеєвська, 43",
        lat: 46.9655191,
        lon: 31.9816776,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: null,
        apart_ind: null,
        q_2021: "201,912200 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9816776,
          46.96551909999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Дунайської флотилiї,5-А",
        address: "вул. Фалеєвська, 9/А",
        adrs_full: "м. Миколаїв, вул. Фалеєвська, 9/А",
        lat: 46.9726863,
        lon: 31.9865837,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 6,
        apart_ind: 5,
        q_2021: "9,002000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9865837,
          46.9726863
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Дунайської флотилiї,5-А",
        address: "вул. Фалеєвська, 9/Б",
        adrs_full: "м. Миколаїв, вул. Фалеєвська, 9/Б",
        lat: 46.9729852,
        lon: 31.9866746,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 46,
        apart_ind: 22,
        q_2021: "119,555000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9866746,
          46.9729852
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Фалеєвська, 91",
        adrs_full: "м. Миколаїв, вул. Фалеєвська, 91",
        lat: 46.9586887,
        lon: 31.9776218,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 183,
        apart_ind: 26,
        q_2021: "843,688000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9776218,
          46.9586887
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Херсонське Шосе, 1/Б",
        adrs_full: "м. Миколаїв, вул. Херсонське Шосе, 1/Б",
        lat: 46.9585005,
        lon: 32.045554,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 1,
        q_2021: "88,756000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.045554,
          46.9585005
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Херсонське Шосе, 30",
        adrs_full: "м. Миколаїв, вул. Херсонське Шосе, 30",
        lat: 46.9578736,
        lon: 32.0499923,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 3,
        q_2021: "304,992000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0499923,
          46.9578736
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Херсонське Шосе, 32",
        adrs_full: "м. Миколаїв, вул. Херсонське Шосе, 32",
        lat: 46.957964629488266,
        lon: 32.051086758075016,
        build_s: "?",
        bld_floor: 10,
        bld_porch: "?",
        bld_apart: 159,
        apart_ind: 4,
        q_2021: "586,178000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.051086758075016,
          46.957964629488266
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Листопадова,1-к",
        address: "вул. Херсонське Шосе, 38",
        adrs_full: "м. Миколаїв, вул. Херсонське Шосе, 38",
        lat: 46.95730413983818,
        lon: 32.05167996847402,
        build_s: "?",
        bld_floor: 10,
        bld_porch: "?",
        bld_apart: 79,
        apart_ind: 0,
        q_2021: "299,065000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05167996847402,
          46.95730413983818
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.Листопадова,1-к",
        address: "вул. Херсонське Шосе, 40",
        adrs_full: "м. Миколаїв, вул. Херсонське Шосе, 40",
        lat: 46.95732223555366,
        lon: 32.05268743194498,
        build_s: "?",
        bld_floor: 10,
        bld_porch: "?",
        bld_apart: 161,
        apart_ind: 7,
        q_2021: "536,983000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05268743194498,
          46.95732223555366
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Херсонське Шосе, 46",
        adrs_full: "м. Миколаїв, вул. Херсонське Шосе, 46",
        lat: 46.95666399996599,
        lon: 32.053283956368574,
        build_s: "?",
        bld_floor: 10,
        bld_porch: "?",
        bld_apart: 79,
        apart_ind: 0,
        q_2021: "249,567000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.053283956368574,
          46.95666399996599
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот.135 мкр Новозаводська,48",
        address: "вул. Херсонське Шосе, 46/1",
        adrs_full: "м. Миколаїв, вул. Херсонське Шосе, 46/1",
        lat: 46.95648304030984,
        lon: 32.054215197274296,
        build_s: "?",
        bld_floor: 10,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 4,
        q_2021: "316,851000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.054215197274296,
          46.95648304030984
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Херсонське Шосе, 48",
        adrs_full: "м. Миколаїв, вул. Херсонське Шосе, 48",
        lat: 46.95615052534632,
        lon: 32.054102520438725,
        build_s: "?",
        bld_floor: 10,
        bld_porch: "?",
        bld_apart: 144,
        apart_ind: 4,
        q_2021: "502,212000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.054102520438725,
          46.95615052534632
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Херсонське Шосе, 92",
        adrs_full: "м. Миколаїв, вул. Херсонське Шосе, 92",
        lat: 46.951509109474976,
        lon: 32.06533176399984,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 32,
        apart_ind: 2,
        q_2021: "132,330000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06533176399984,
          46.951509109474976
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Херсонське Шосе, 94",
        adrs_full: "м. Миколаїв, вул. Херсонське Шосе, 94",
        lat: 46.951361979474946,
        lon: 32.066043038809,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 2,
        q_2021: "136,732000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.066043038809,
          46.951361979474946
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Херсонське Шосе, 96",
        adrs_full: "м. Миколаїв, вул. Херсонське Шосе, 96",
        lat: 46.94969938236597,
        lon: 32.06987242742798,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 32,
        apart_ind: 2,
        q_2021: "115,609000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06987242742798,
          46.94969938236597
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Ходорєва, 12",
        adrs_full: "м. Миколаїв, вул. Ходорєва, 12",
        lat: 46.87205215306779,
        lon: 32.04611164542293,
        build_s: "762,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "63,150015 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04611164542293,
          46.87205215306779
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Ходорєва, 14",
        adrs_full: "м. Миколаїв, вул. Ходорєва, 14",
        lat: 46.87260639789774,
        lon: 32.04612145279041,
        build_s: "746,59 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 15,
        apart_ind: 0,
        q_2021: "70,729983 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04612145279041,
          46.87260639789775
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Ходорєва, 16",
        adrs_full: "м. Миколаїв, вул. Ходорєва, 16",
        lat: 46.87318075041222,
        lon: 32.04618846980142,
        build_s: "414,00 м2",
        bld_floor: 3,
        bld_porch: 1,
        bld_apart: 26,
        apart_ind: 1,
        q_2021: "54,415978 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04618846980142,
          46.87318075041222
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чайковського, 10",
        adrs_full: "м. Миколаїв, вул. Чайковського, 10",
        lat: 46.9471737,
        lon: 32.0315257,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 100,
        apart_ind: 7,
        q_2021: "276,990000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0315257,
          46.9471737
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чайковського, 18",
        adrs_full: "м. Миколаїв, вул. Чайковського, 18",
        lat: 46.9443900060015,
        lon: 32.03301905234151,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 1,
        q_2021: "258,210000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03301905234151,
          46.9443900060015
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чайковського, 2",
        adrs_full: "м. Миколаїв, вул. Чайковського, 2",
        lat: 46.9492223,
        lon: 32.0304323,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 4,
        q_2021: "261,827000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0304323,
          46.9492223
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чайковського, 22",
        adrs_full: "м. Миколаїв, вул. Чайковського, 22",
        lat: 46.9411569,
        lon: 32.0350947,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: null,
        q_2021: "193,312900 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0350947,
          46.9411569
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чайковського, 25",
        adrs_full: "м. Миколаїв, вул. Чайковського, 25",
        lat: 46.9459046,
        lon: 32.0316747,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 69,
        apart_ind: 7,
        q_2021: "207,629000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0316747,
          46.9459046
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чайковського, 26",
        adrs_full: "м. Миколаїв, вул. Чайковського, 26",
        lat: 46.94037545338367,
        lon: 32.03522333416028,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 0,
        q_2021: "243,780000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03522333416028,
          46.94037545338367
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чайковського, 27",
        adrs_full: "м. Миколаїв, вул. Чайковського, 27",
        lat: 46.94506891328048,
        lon: 32.03212106398343,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 56,
        apart_ind: 3,
        q_2021: "174,875000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03212106398343,
          46.94506891328048
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чайковського, 28",
        adrs_full: "м. Миколаїв, вул. Чайковського, 28",
        lat: 46.9391824,
        lon: 32.0357896,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "200,875000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0357896,
          46.9391824
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чайковського, 28/А",
        adrs_full: "м. Миколаїв, вул. Чайковського, 28/А",
        lat: 46.939166002994625,
        lon: 32.03630783933033,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 1,
        q_2021: "58,076000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03630783933033,
          46.939166002994625
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чайковського, 29",
        adrs_full: "м. Миколаїв, вул. Чайковського, 29",
        lat: 46.9409999,
        lon: 32.0341532,
        build_s: "4 400,00 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 90,
        apart_ind: 3,
        q_2021: "334,754024 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0341532,
          46.9409999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чайковського, 29/1",
        adrs_full: "м. Миколаїв, вул. Чайковського, 29/1",
        lat: 46.94040774508247,
        lon: 32.033611332043705,
        build_s: "3 356,90 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 73,
        apart_ind: 1,
        q_2021: "259,071343 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.033611332043705,
          46.94040774508247
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чайковського, 29/Б",
        adrs_full: "м. Миколаїв, вул. Чайковського, 29/Б",
        lat: 46.940017552417864,
        lon: 32.033906931453835,
        build_s: "2 756,10 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 6,
        q_2021: "188,900013 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.033906931453835,
          46.940017552417864
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Чайковського, 30/А",
        adrs_full: "м. Миколаїв, вул. Чайковського, 30/А",
        lat: 46.9379615937592,
        lon: 32.037079698455884,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 32,
        apart_ind: 1,
        q_2021: "141,286000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.037079698455884,
          46.9379615937592
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Чайковського, 31",
        adrs_full: "м. Миколаїв, вул. Чайковського, 31",
        lat: 46.94016017489397,
        lon: 32.034561191481586,
        build_s: "6 933,00 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 90,
        apart_ind: 5,
        q_2021: "248,980174 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.034561191481586,
          46.94016017489397
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "вул. Чайковського, 32",
        adrs_full: "м. Миколаїв, вул. Чайковського, 32",
        lat: 46.937616799987495,
        lon: 32.03667606955705,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 0,
        q_2021: "288,261000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03667606955705,
          46.937616799987495
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чайковського, 37",
        adrs_full: "м. Миколаїв, вул. Чайковського, 37",
        lat: 46.938272,
        lon: 32.0357354,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 15,
        apart_ind: 3,
        q_2021: "37,590000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0357354,
          46.938272
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чайковського, 39/1",
        adrs_full: "м. Миколаїв, вул. Чайковського, 39/1",
        lat: 46.93791840402129,
        lon: 32.035739744455846,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 40,
        apart_ind: 13,
        q_2021: "105,016000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.035739744455846,
          46.93791840402129
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чайковського, 4",
        adrs_full: "м. Миколаїв, вул. Чайковського, 4",
        lat: 46.948594,
        lon: 32.0310948,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "248,711000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0310948,
          46.948594
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чайковського, 6",
        adrs_full: "м. Миколаїв, вул. Чайковського, 6",
        lat: 46.9481398,
        lon: 32.0309967,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 1,
        q_2021: "103,464000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0309967,
          46.9481398
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чайковського, 6-А",
        adrs_full: "м. Миколаїв, вул. Чайковського, 6-А",
        lat: 46.9485247,
        lon: 32.0320597,
        build_s: "?",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: null,
        q_2021: "560,109100 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0320597,
          46.9485247
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чайковського, 8",
        adrs_full: "м. Миколаїв, вул. Чайковського, 8",
        lat: 46.947877,
        lon: 32.0315011,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 100,
        apart_ind: 0,
        q_2021: "262,600000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0315011,
          46.947877
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова 82",
        adrs_full: "м. Миколаїв, вул. Чкалова 82",
        lat: 46.962758794856434,
        lon: 32.00625328055473,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 0,
        q_2021: "273,827000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00625328055473,
          46.962758794856434
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 100",
        adrs_full: "м. Миколаїв, вул. Чкалова, 100",
        lat: 46.963075921609075,
        lon: 32.010585561353544,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 99,
        apart_ind: 7,
        q_2021: "287,413000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.010585561353544,
          46.96307592160907
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 100/А",
        adrs_full: "м. Миколаїв, вул. Чкалова, 100/А",
        lat: 46.96337040655142,
        lon: 32.01002093872997,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 40,
        apart_ind: 1,
        q_2021: "151,623000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01002093872997,
          46.96337040655141
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Полярний,2-к",
        address: "вул. Чкалова, 102",
        adrs_full: "м. Миколаїв, вул. Чкалова, 102",
        lat: 46.96244935250833,
        lon: 32.011219040394614,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "211,738000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.011219040394614,
          46.96244935250833
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Полярний,2-к",
        address: "вул. Чкалова, 106",
        adrs_full: "м. Миколаїв, вул. Чкалова, 106",
        lat: 46.9631177,
        lon: 32.0112985,
        build_s: "?",
        bld_floor: 10,
        bld_porch: "?",
        bld_apart: 180,
        apart_ind: 7,
        q_2021: "588,346000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0112985,
          46.9631177
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Полярний,2-к",
        address: "вул. Чкалова, 108",
        adrs_full: "м. Миколаїв, вул. Чкалова, 108",
        lat: 46.96229270908655,
        lon: 32.01218762066756,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 128,
        apart_ind: 9,
        q_2021: "553,491000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01218762066756,
          46.96229270908655
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 110",
        adrs_full: "м. Миколаїв, вул. Чкалова, 110",
        lat: 46.9624344,
        lon: 32.0159906,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 90,
        apart_ind: null,
        q_2021: "284,794800 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0159906,
          46.9624344
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.Полярний,2-к",
        address: "вул. Чкалова, 110-Б",
        adrs_full: "м. Миколаїв, вул. Чкалова, 110-Б",
        lat: 46.96303037233785,
        lon: 32.01475358404355,
        build_s: "?",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: null,
        q_2021: "517,262600 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01475358404355,
          46.96303037233785
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 110/А",
        adrs_full: "м. Миколаїв, вул. Чкалова, 110/А",
        lat: 46.96219390023525,
        lon: 32.0146388233477,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 216,
        apart_ind: 18,
        q_2021: "871,862000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0146388233477,
          46.96219390023525
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 112",
        adrs_full: "м. Миколаїв, вул. Чкалова, 112",
        lat: 46.9623952,
        lon: 32.0165218,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 90,
        apart_ind: null,
        q_2021: "332,135500 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0165218,
          46.9623952
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 116",
        adrs_full: "м. Миколаїв, вул. Чкалова, 116",
        lat: 46.9621343,
        lon: 32.0193862,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "315,140000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0193862,
          46.9621343
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 118",
        adrs_full: "м. Миколаїв, вул. Чкалова, 118",
        lat: 46.9618433,
        lon: 32.0197105,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "323,466000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0197105,
          46.9618433
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 120",
        adrs_full: "м. Миколаїв, вул. Чкалова, 120",
        lat: 46.96186262200471,
        lon: 32.02058349299099,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 73,
        apart_ind: 4,
        q_2021: "295,319000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02058349299099,
          46.96186262200471
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 122",
        adrs_full: "м. Миколаїв, вул. Чкалова, 122",
        lat: 46.96171180861222,
        lon: 32.02142773671062,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 108,
        apart_ind: 3,
        q_2021: "409,181000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02142773671062,
          46.96171180861222
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 199",
        adrs_full: "м. Миколаїв, вул. Чкалова, 199",
        lat: 46.9604837,
        lon: 32.0271096,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 14,
        apart_ind: 3,
        q_2021: "48,877000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0271096,
          46.9604837
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 203",
        adrs_full: "м. Миколаїв, вул. Чкалова, 203",
        lat: 46.9603726,
        lon: 32.0282762,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 13,
        apart_ind: 0,
        q_2021: "54,334000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0282762,
          46.9603726
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 205",
        adrs_full: "м. Миколаїв, вул. Чкалова, 205",
        lat: 46.9602706,
        lon: 32.0288736,
        build_s: "1 013,80 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 0,
        q_2021: "138,564200 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0288736,
          46.9602706
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 207",
        adrs_full: "м. Миколаїв, вул. Чкалова, 207",
        lat: 46.9603407,
        lon: 32.0293244,
        build_s: "675,30 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 1,
        q_2021: "59,270045 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0293244,
          46.9603407
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 209",
        adrs_full: "м. Миколаїв, вул. Чкалова, 209",
        lat: 46.960297,
        lon: 32.0301401,
        build_s: "1 037,10 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 32,
        apart_ind: 0,
        q_2021: "144,902600 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0301401,
          46.960297
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 213",
        adrs_full: "м. Миколаїв, вул. Чкалова, 213",
        lat: 46.9601655,
        lon: 32.0321154,
        build_s: "968,10 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 18,
        apart_ind: 8,
        q_2021: "61,779956 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0321154,
          46.9601655
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 213/А",
        adrs_full: "м. Миколаїв, вул. Чкалова, 213/А",
        lat: 46.9596388,
        lon: 32.0317087,
        build_s: "1 079,40 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 25,
        apart_ind: 2,
        q_2021: "93,950027 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0317087,
          46.9596388
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 215",
        adrs_full: "м. Миколаїв, вул. Чкалова, 215",
        lat: 46.960124,
        lon: 32.0327888,
        build_s: "1 028,00 м2",
        bld_floor: 2,
        bld_porch: 5,
        bld_apart: 24,
        apart_ind: 6,
        q_2021: "98,949244 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "5",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0327888,
          46.960124
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 215-Г",
        adrs_full: "м. Миколаїв, вул. Чкалова, 215-Г",
        lat: 46.9592059,
        lon: 32.0322177,
        build_s: "?",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: null,
        apart_ind: null,
        q_2021: "221,816900 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0322177,
          46.9592059
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 215/А",
        adrs_full: "м. Миколаїв, вул. Чкалова, 215/А",
        lat: 46.9596764,
        lon: 32.0322637,
        build_s: "1 039,50 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 18,
        apart_ind: 17,
        q_2021: "4,094727 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0322637,
          46.9596764
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 215/Б",
        adrs_full: "м. Миколаїв, вул. Чкалова, 215/Б",
        lat: 46.9593532,
        lon: 32.0331642,
        build_s: "783,00 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 19,
        apart_ind: 8,
        q_2021: "45,086900 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0331642,
          46.9593532
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 215/В",
        adrs_full: "м. Миколаїв, вул. Чкалова, 215/В",
        lat: 46.960124,
        lon: 32.0327888,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 11,
        q_2021: "273,965000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0327888,
          46.960124
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 33",
        adrs_full: "м. Миколаїв, вул. Чкалова, 33",
        lat: 46.9640504,
        lon: 31.9888281,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 14,
        q_2021: "40,168000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9888281,
          46.9640504
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 58",
        adrs_full: "м. Миколаїв, вул. Чкалова, 58",
        lat: 46.9630509,
        lon: 31.9996118,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "255,283000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9996118,
          46.9630509
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 60",
        adrs_full: "м. Миколаїв, вул. Чкалова, 60",
        lat: 46.963068749119145,
        lon: 32.00054822993814,
        build_s: "?",
        bld_floor: 10,
        bld_porch: "?",
        bld_apart: 119,
        apart_ind: 7,
        q_2021: "363,850000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00054822993814,
          46.963068749119145
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 62",
        adrs_full: "м. Миколаїв, вул. Чкалова, 62",
        lat: 46.96318126631818,
        lon: 32.00189205865481,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 108,
        apart_ind: 15,
        q_2021: "405,107000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00189205865481,
          46.96318126631819
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 78",
        adrs_full: "м. Миколаїв, вул. Чкалова, 78",
        lat: 46.9628832,
        lon: 32.0044777,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 130,
        apart_ind: 16,
        q_2021: "466,268000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0044777,
          46.9628832
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Чкалова,209",
        address: "вул. Чкалова, 82/А",
        adrs_full: "м. Миколаїв, вул. Чкалова, 82/А",
        lat: 46.96303478161632,
        lon: 32.00569335192279,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 8,
        q_2021: "284,075000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00569335192279,
          46.96303478161632
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Чкалова,209",
        address: "вул. Чкалова, 84",
        adrs_full: "м. Миколаїв, вул. Чкалова, 84",
        lat: 46.9634114,
        lon: 32.0062998,
        build_s: "?",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: null,
        q_2021: "233,147600 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0062998,
          46.9634114
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Чкалова,209",
        address: "вул. Чкалова, 85",
        adrs_full: "м. Миколаїв, вул. Чкалова, 85",
        lat: 46.9614815,
        lon: 32.0051871,
        build_s: "?",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: null,
        apart_ind: null,
        q_2021: "457,500800 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0051871,
          46.9614815
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Чкалова,209",
        address: "вул. Чкалова, 86",
        adrs_full: "м. Миколаїв, вул. Чкалова, 86",
        lat: 46.9629926,
        lon: 32.0068991,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 96,
        apart_ind: 2,
        q_2021: "372,118000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0068991,
          46.9629926
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Чкалова,209",
        address: "вул. Чкалова, 97",
        adrs_full: "м. Миколаїв, вул. Чкалова, 97",
        lat: 46.961166,
        lon: 32.0091065,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 0,
        q_2021: "265,774000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0091065,
          46.961166
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Чкалова,209",
        address: "вул. Чкалова, 98-А",
        adrs_full: "м. Миколаїв, вул. Чкалова, 98-А",
        lat: 46.96276263797598,
        lon: 32.009621571508426,
        build_s: "?",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 108,
        apart_ind: null,
        q_2021: "319,694200 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.009621571508426,
          46.96276263797598
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Чкалова, 98/Б",
        adrs_full: "м. Миколаїв, вул. Чкалова, 98/Б",
        lat: 46.962455618235666,
        lon: 32.01003471001348,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 1,
        q_2021: "282,526000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01003471001348,
          46.962455618235666
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Чкалова,209",
        address: "вул. Чкалова, 99",
        adrs_full: "м. Миколаїв, вул. Чкалова, 99",
        lat: 46.9615649,
        lon: 32.0097877,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 76,
        apart_ind: 2,
        q_2021: "199,745000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0097877,
          46.9615649
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-4",
        kot_name: "кот.Чкалова,209",
        address: "вул. Чкалова, 99-А",
        adrs_full: "м. Миколаїв, вул. Чкалова, 99-А",
        lat: 46.9611598,
        lon: 32.0100052,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: null,
        apart_ind: null,
        q_2021: "186,870400 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0100052,
          46.9611598
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шевченка, 1",
        adrs_full: "м. Миколаїв, вул. Шевченка, 1",
        lat: 46.9758726,
        lon: 31.9736131,
        build_s: "1 230,25 м2",
        bld_floor: 3,
        bld_porch: 4,
        bld_apart: 33,
        apart_ind: 0,
        q_2021: "145,057033 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9736131,
          46.9758726
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шевченка, 1/А",
        adrs_full: "м. Миколаїв, вул. Шевченка, 1/А",
        lat: 46.97621,
        lon: 31.9732741,
        build_s: "1 074,30 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 12,
        apart_ind: 7,
        q_2021: "44,510006 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9732741,
          46.97621
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шевченка, 16",
        adrs_full: "м. Миколаїв, вул. Шевченка, 16",
        lat: 46.9754162,
        lon: 31.9756881,
        build_s: "3 970,50 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 70,
        apart_ind: 8,
        q_2021: "313,846878 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9756881,
          46.9754162
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шевченка, 2/А",
        adrs_full: "м. Миколаїв, вул. Шевченка, 2/А",
        lat: 46.9767507,
        lon: 31.9732982,
        build_s: "1 822,20 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 5,
        q_2021: "170,349903 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9732982,
          46.9767507
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шевченка, 29",
        adrs_full: "м. Миколаїв, вул. Шевченка, 29",
        lat: 46.9721205,
        lon: 31.9805167,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 30,
        apart_ind: 7,
        q_2021: "121,464000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9805167,
          46.9721205
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шевченка, 3",
        adrs_full: "м. Миколаїв, вул. Шевченка, 3",
        lat: 46.9758076,
        lon: 31.9738217,
        build_s: "788,90 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 13,
        apart_ind: 1,
        q_2021: "60,670016 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9738217,
          46.9758076
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шевченка, 3/А",
        adrs_full: "м. Миколаїв, вул. Шевченка, 3/А",
        lat: 46.9753093,
        lon: 31.9740681,
        build_s: "1 469,10 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 30,
        apart_ind: 0,
        q_2021: "135,247959 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9740681,
          46.9753093
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шевченка, 42",
        adrs_full: "м. Миколаїв, вул. Шевченка, 42",
        lat: 46.9714636,
        lon: 31.9845883,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 8,
        apart_ind: 5,
        q_2021: "-60,870000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9845883,
          46.9714636
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шевченка, 47",
        adrs_full: "м. Миколаїв, вул. Шевченка, 47",
        lat: 46.9707838,
        lon: 31.9850786,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 10,
        apart_ind: 0,
        q_2021: "49,947000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9850786,
          46.9707838
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шевченка, 6/А",
        adrs_full: "м. Миколаїв, вул. Шевченка, 6/А",
        lat: 46.9766511,
        lon: 31.9737536,
        build_s: "762,00 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 0,
        q_2021: "79,259920 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9737536,
          46.9766511
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шевченка, 61",
        adrs_full: "м. Миколаїв, вул. Шевченка, 61",
        lat: 46.9690657,
        lon: 31.9903228,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 28,
        apart_ind: 12,
        q_2021: "96,363000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9903228,
          46.96906569999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шевченка, 64/А",
        adrs_full: "м. Миколаїв, вул. Шевченка, 64/А",
        lat: 46.9700925,
        lon: 31.9918988,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 84,
        apart_ind: 28,
        q_2021: "428,721000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9918988,
          46.9700925
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шевченка, 67",
        adrs_full: "м. Миколаїв, вул. Шевченка, 67",
        lat: 46.9683918,
        lon: 31.9919443,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 44,
        apart_ind: 14,
        q_2021: "229,434000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9919443,
          46.9683918
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шевченка, 69",
        adrs_full: "м. Миколаїв, вул. Шевченка, 69",
        lat: 46.968207,
        lon: 31.9934168,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "100,040000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9934168,
          46.968207
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шевченка, 69/А",
        adrs_full: "м. Миколаїв, вул. Шевченка, 69/А",
        lat: 46.9684323,
        lon: 31.9928289,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 23,
        apart_ind: 6,
        q_2021: "110,955000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9928289,
          46.9684323
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Шевченка, 75",
        adrs_full: "м. Миколаїв, вул. Шевченка, 75",
        lat: 46.96768570404986,
        lon: 31.995240245419843,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 40,
        apart_ind: 0,
        q_2021: "151,743000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.995240245419843,
          46.96768570404986
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Шевченка, 8",
        adrs_full: "м. Миколаїв, вул. Шевченка, 8",
        lat: 46.9762324,
        lon: 31.9743533,
        build_s: "2 471,40 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 57,
        apart_ind: 3,
        q_2021: "150,270359 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9743533,
          46.9762324
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Шевченка, 81",
        adrs_full: "м. Миколаїв, вул. Шевченка, 81",
        lat: 46.96692495028561,
        lon: 31.996953348473255,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: null,
        apart_ind: null,
        q_2021: "210,898300 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.996953348473255,
          46.96692495028561
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Шкільна, 1",
        adrs_full: "м. Миколаїв, вул. Шкільна, 1",
        lat: 46.9558207,
        lon: 32.0430621,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 26,
        apart_ind: 2,
        q_2021: "158,802000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0430621,
          46.9558207
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шкільна, 2",
        adrs_full: "м. Миколаїв, вул. Шкільна, 2",
        lat: 46.9554761,
        lon: 32.0433553,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 5,
        q_2021: "142,218000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0433553,
          46.95547610000001
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Шнеєрсона, 2",
        adrs_full: "м. Миколаїв, вул. Шнеєрсона, 2",
        lat: 46.9734501,
        lon: 31.9987148,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 45,
        apart_ind: 10,
        q_2021: "195,154000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9987148,
          46.9734501
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Шнеєрсона, 2/А",
        adrs_full: "м. Миколаїв, вул. Шнеєрсона, 2/А",
        lat: 46.97441,
        lon: 31.9986117,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 27,
        apart_ind: 6,
        q_2021: "80,922000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9986117,
          46.97441
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шнеєрсона, 2/Б",
        adrs_full: "м. Миколаїв, вул. Шнеєрсона, 2/Б",
        lat: 46.9739441,
        lon: 31.9987534,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 52,
        apart_ind: 18,
        q_2021: "222,977000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9987534,
          46.9739441
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шнеєрсона, 37",
        adrs_full: "м. Миколаїв, вул. Шнеєрсона, 37",
        lat: 46.9676805,
        lon: 31.9934826,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 29,
        apart_ind: 16,
        q_2021: "82,774000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9934826,
          46.9676805
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Шнеєрсона, 4",
        adrs_full: "м. Миколаїв, вул. Шнеєрсона, 4",
        lat: 46.9735521,
        lon: 31.9981172,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 144,
        apart_ind: 3,
        q_2021: "468,178000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9981172,
          46.9735521
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шосейна, 1",
        adrs_full: "м. Миколаїв, вул. Шосейна, 1",
        lat: 46.9756496,
        lon: 31.9637654,
        build_s: "2 617,10 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 62,
        apart_ind: 1,
        q_2021: "216,889967 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9637654,
          46.9756496
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шосейна, 10",
        adrs_full: "м. Миколаїв, вул. Шосейна, 10",
        lat: 46.9740571,
        lon: 31.9663758,
        build_s: "5 177,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 6,
        q_2021: "251,229877 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9663758,
          46.9740571
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шосейна, 105",
        adrs_full: "м. Миколаїв, вул. Шосейна, 105",
        lat: 46.961473501799425,
        lon: 31.971742970085124,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 144,
        apart_ind: 8,
        q_2021: "533,944000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.971742970085124,
          46.961473501799425
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шосейна, 11",
        adrs_full: "м. Миколаїв, вул. Шосейна, 11",
        lat: 46.973734,
        lon: 31.9652468,
        build_s: "4 170,30 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 171,
        apart_ind: 2,
        q_2021: "402,318068 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9652468,
          46.973734
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шосейна, 12",
        adrs_full: "м. Миколаїв, вул. Шосейна, 12",
        lat: 46.973237,
        lon: 31.966437,
        build_s: "4 424,00 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 100,
        apart_ind: 10,
        q_2021: "318,930091 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.966437,
          46.973237
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шосейна, 13",
        adrs_full: "м. Миколаїв, вул. Шосейна, 13",
        lat: 46.9728658,
        lon: 31.9659365,
        build_s: "3 155,70 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 69,
        apart_ind: 5,
        q_2021: "262,313228 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9659365,
          46.9728658
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Шосейна, 14",
        adrs_full: "м. Миколаїв, вул. Шосейна, 14",
        lat: 46.9723137,
        lon: 31.9677101,
        build_s: "4 071,10 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "289,419811 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9677101,
          46.9723137
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шосейна, 14/А",
        adrs_full: "м. Миколаїв, вул. Шосейна, 14/А",
        lat: 46.9718938,
        lon: 31.967366,
        build_s: "3 595,80 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 64,
        apart_ind: 2,
        q_2021: "229,330221 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.967366,
          46.9718938
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шосейна, 15",
        adrs_full: "м. Миколаїв, вул. Шосейна, 15",
        lat: 46.9722327,
        lon: 31.9664128,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 112,
        apart_ind: 10,
        q_2021: "479,454000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9664128,
          46.9722327
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шосейна, 2",
        adrs_full: "м. Миколаїв, вул. Шосейна, 2",
        lat: 46.975654131069376,
        lon: 31.965179691563772,
        build_s: "2 559,10 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 58,
        apart_ind: 3,
        q_2021: "214,122305 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.965179691563772,
          46.975654131069376
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шосейна, 27",
        adrs_full: "м. Миколаїв, вул. Шосейна, 27",
        lat: 46.96966857645251,
        lon: 31.967618044101307,
        build_s: "5 890,50 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 129,
        apart_ind: 5,
        q_2021: "528,975283 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.967618044101307,
          46.96966857645251
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шосейна, 4",
        adrs_full: "м. Миколаїв, вул. Шосейна, 4",
        lat: 46.97519957680415,
        lon: 31.965090057769206,
        build_s: "1 597,50 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 1,
        q_2021: "166,194262 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.965090057769206,
          46.97519957680415
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шосейна, 46",
        adrs_full: "м. Миколаїв, вул. Шосейна, 46",
        lat: 46.969973659162704,
        lon: 31.96847652238875,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 96,
        apart_ind: 20,
        q_2021: "320,056000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96847652238875,
          46.969973659162704
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шосейна, 5",
        adrs_full: "м. Миколаїв, вул. Шосейна, 5",
        lat: 46.9747165,
        lon: 31.9646638,
        build_s: "4 758,20 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 108,
        apart_ind: 5,
        q_2021: "270,040080 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9646638,
          46.9747165
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шосейна, 50",
        adrs_full: "м. Миколаїв, вул. Шосейна, 50",
        lat: 46.96938880191944,
        lon: 31.968682265531157,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 153,
        apart_ind: 4,
        q_2021: "431,026000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.968682265531157,
          46.969388801919436
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Шосейна, 58",
        adrs_full: "м. Миколаїв, вул. Шосейна, 58",
        lat: 46.9677079,
        lon: 31.9697752,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 129,
        apart_ind: 8,
        q_2021: "541,698000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9697752,
          46.9677079
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Шосейна, 6",
        adrs_full: "м. Миколаїв, вул. Шосейна, 6",
        lat: 46.9747573,
        lon: 31.9655485,
        build_s: "2 587,50 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 0,
        q_2021: "224,680046 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9655485,
          46.9747573
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Шосейна, 60",
        adrs_full: "м. Миколаїв, вул. Шосейна, 60",
        lat: 46.9672278,
        lon: 31.9692738,
        build_s: "?",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: null,
        apart_ind: null,
        q_2021: "209,740300 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9692738,
          46.9672278
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Шосейна, 8",
        adrs_full: "м. Миколаїв, вул. Шосейна, 8",
        lat: 46.974259,
        lon: 31.9657949,
        build_s: "2 581,10 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 1,
        q_2021: "191,377070 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9657949,
          46.974259
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Шосейна, 83",
        adrs_full: "м. Миколаїв, вул. Шосейна, 83",
        lat: 46.9630964,
        lon: 31.9707089,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 169,
        apart_ind: 1,
        q_2021: "412,675000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9707089,
          46.9630964
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Шосейна, 84",
        adrs_full: "м. Миколаїв, вул. Шосейна, 84",
        lat: 46.9638287,
        lon: 31.9713354,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 100,
        apart_ind: 6,
        q_2021: "346,204000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9713354,
          46.9638287
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Январьова, 28",
        adrs_full: "м. Миколаїв, вул. Январьова, 28",
        lat: 46.9645808,
        lon: 31.9675658,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 143,
        apart_ind: 9,
        q_2021: "591,481000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9675658,
          46.9645808
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Янтарна, 318/А",
        adrs_full: "м. Миколаїв, вул. Янтарна, 318/А",
        lat: 46.8691783,
        lon: 32.0481849,
        build_s: "1 478,10 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 30,
        apart_ind: 0,
        q_2021: "136,009971 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0481849,
          46.8691783
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "вул. Янтарна, 67",
        adrs_full: "м. Миколаїв, вул. Янтарна, 67",
        lat: 46.8731608,
        lon: 32.0220127,
        build_s: "9 340,60 м2",
        bld_floor: 10,
        bld_porch: 4,
        bld_apart: 161,
        apart_ind: 4,
        q_2021: "670,283019 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0220127,
          46.8731608
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Iнженерна, 13",
        adrs_full: "м. Миколаїв, вул. Iнженерна, 13",
        lat: 46.96747912825486,
        lon: 31.99978098266534,
        build_s: "1 500,00 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 21,
        apart_ind: 4,
        q_2021: "113,761992 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99978098266534,
          46.96747912825486
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Iнженерна, 17",
        adrs_full: "м. Миколаїв, вул. Iнженерна, 17",
        lat: 46.966948173115384,
        lon: 31.99942464831391,
        build_s: "2 382,60 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 58,
        apart_ind: 3,
        q_2021: "169,050826 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99942464831391,
          46.966948173115384
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "вул. Iнженерна, 20",
        adrs_full: "м. Миколаїв, вул. Iнженерна, 20",
        lat: 46.96709164562424,
        lon: 32.00040646619096,
        build_s: "514,90 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "52,956400 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00040646619096,
          46.96709164562424
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 10",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 10",
        lat: 46.95033112185077,
        lon: 32.03444165943793,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 59,
        apart_ind: 5,
        q_2021: "175,207000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03444165943793,
          46.95033112185077
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "пр-т Богоявленський, 10/А",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 10/А",
        lat: 46.950707714445805,
        lon: 32.035175544587126,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "165,565000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.035175544587126,
          46.950707714445805
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 12",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 12",
        lat: 46.9498957,
        lon: 32.0347585,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 3,
        q_2021: "184,694000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0347585,
          46.9498957
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 12/А",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 12/А",
        lat: 46.950227472047686,
        lon: 32.03544885353924,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "188,892000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03544885353924,
          46.950227472047686
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "пр-т Богоявленський, 14",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 14",
        lat: 46.94937408103916,
        lon: 32.0349680322346,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 1,
        q_2021: "176,808000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0349680322346,
          46.94937408103916
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 14/А",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 14/А",
        lat: 46.94973686025608,
        lon: 32.0357019173838,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 4,
        q_2021: "173,083000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0357019173838,
          46.94973686025608
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "пр-т Богоявленський, 16",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 16",
        lat: 46.948897491847454,
        lon: 32.03519957663019,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 57,
        apart_ind: 1,
        q_2021: "184,320000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03519957663019,
          46.948897491847454
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 16/А",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 16/А",
        lat: 46.94926351926513,
        lon: 32.03595498122834,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 2,
        q_2021: "193,121000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03595498122834,
          46.94926351926513
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 18",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 18",
        lat: 46.94824426715352,
        lon: 32.03518566714092,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 54,
        apart_ind: 4,
        q_2021: "204,789000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03518566714092,
          46.94824426715352
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 18/1",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 18/1",
        lat: 46.94841702311477,
        lon: 32.03590436845944,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 9,
        q_2021: "339,600000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03590436845944,
          46.948417023114764
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 20",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 20",
        lat: 46.94779164389094,
        lon: 32.035453914816145,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 1,
        q_2021: "236,889000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.035453914816145,
          46.94779164389094
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 22",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 22",
        lat: 46.9469505,
        lon: 32.0358876,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 5,
        q_2021: "196,260000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0358876,
          46.9469505
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.пр.Центральний,96/7",
        address: "пр-т Богоявленський, 23",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 23",
        lat: 46.95021710705634,
        lon: 32.03299919552402,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 1,
        q_2021: "169,418000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03299919552402,
          46.95021710705634
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.пр.Центральний,96/7",
        address: "пр-т Богоявленський, 23/2",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 23/2",
        lat: 46.94976104544977,
        lon: 32.033232014261,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: null,
        apart_ind: null,
        q_2021: "175,719900 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.033232014261,
          46.94976104544977
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.пр.Центральний,96/7",
        address: "пр-т Богоявленський, 24",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 24",
        lat: 46.9463196,
        lon: 32.0362205,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 59,
        apart_ind: 3,
        q_2021: "224,875000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03622050000001,
          46.9463196
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 25",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 25",
        lat: 46.9492146,
        lon: 32.0328886,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 0,
        q_2021: "239,269000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0328886,
          46.9492146
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 25/А",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 25/А",
        lat: 46.94912877179425,
        lon: 32.03356605853581,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 39,
        apart_ind: 5,
        q_2021: "152,538000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03356605853581,
          46.94912877179425
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 26",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 26",
        lat: 46.9457353,
        lon: 32.0365392,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 56,
        apart_ind: 4,
        q_2021: "237,235000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0365392,
          46.9457353
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 26/А",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 26/А",
        lat: 46.9458407,
        lon: 32.037687,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 90,
        apart_ind: 3,
        q_2021: "251,730000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.037687,
          46.9458407
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 27",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 27",
        lat: 46.94880053935585,
        lon: 32.03310042106183,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 78,
        apart_ind: 1,
        q_2021: "282,667000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03310042106183,
          46.94880053935585
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 27/А",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 27/А",
        lat: 46.94871761715818,
        lon: 32.03380393854968,
        build_s: "?",
        bld_floor: 10,
        bld_porch: "?",
        bld_apart: 39,
        apart_ind: 5,
        q_2021: "157,749000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03380393854968,
          46.94871761715819
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 28",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 28",
        lat: 46.94516498706095,
        lon: 32.036891548524686,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 61,
        apart_ind: 2,
        q_2021: "270,192000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.036891548524686,
          46.94516498706095
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 285",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 285",
        lat: 46.866958380216715,
        lon: 32.01592213917807,
        build_s: "7 355,97 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 2,
        q_2021: "466,898337 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01592213917807,
          46.866958380216715
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 287",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 287",
        lat: 46.866766812755586,
        lon: 32.014876684379985,
        build_s: "2 480,03 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 130,
        apart_ind: 0,
        q_2021: "269,440032 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.014876684379985,
          46.866766812755586
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 289",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 289",
        lat: 46.866317759468195,
        lon: 32.01484655599964,
        build_s: "2 557,10 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 130,
        apart_ind: 0,
        q_2021: "277,900160 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01484655599964,
          46.86631775946819
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 29",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 29",
        lat: 46.94837556173491,
        lon: 32.03336360746016,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 4,
        q_2021: "216,262000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03336360746016,
          46.94837556173491
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 293",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 293",
        lat: 46.8817922,
        lon: 32.0233794,
        build_s: "3 084,80 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 68,
        apart_ind: 5,
        q_2021: "214,282602 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0233794,
          46.8817922
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 295",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 295",
        lat: 46.865261640316774,
        lon: 32.016015309090946,
        build_s: "3 196,08 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 5,
        q_2021: "221,089706 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.016015309090946,
          46.865261640316774
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 299",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 299",
        lat: 46.8640723,
        lon: 32.0158686,
        build_s: "1 194,50 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 32,
        apart_ind: 3,
        q_2021: "98,041279 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0158686,
          46.8640723
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 30",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 30",
        lat: 46.9431597,
        lon: 32.037984,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 41,
        apart_ind: 7,
        q_2021: "184,734000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.037984,
          46.9431597
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 301",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 301",
        lat: 46.8769607,
        lon: 32.0230807,
        build_s: "1 484,42 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 36,
        apart_ind: 1,
        q_2021: "128,825936 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0230807,
          46.8769607
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 303",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 303",
        lat: 46.86332718545644,
        lon: 32.01529367272732,
        build_s: "1 323,30 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 32,
        apart_ind: 2,
        q_2021: "132,570000 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01529367272732,
          46.86332718545644
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 305",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 305",
        lat: 46.86318621031244,
        lon: 32.01609549090914,
        build_s: "1 252,20 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 48,
        apart_ind: 2,
        q_2021: "140,821716 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01609549090914,
          46.86318621031244
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 307",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 307",
        lat: 46.863285415082,
        lon: 32.015553309090954,
        build_s: "2 469,75 м2",
        bld_floor: 3,
        bld_porch: 6,
        bld_apart: 50,
        apart_ind: 11,
        q_2021: "283,999465 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.015553309090954,
          46.863285415082004
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 309",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 309",
        lat: 46.8618754,
        lon: 32.0158289,
        build_s: "2 782,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 22,
        apart_ind: 0,
        q_2021: "61,693851 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0158289,
          46.8618754
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 31",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 31",
        lat: 46.94806114522585,
        lon: 32.034153166655166,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 75,
        apart_ind: 1,
        q_2021: "206,950000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.034153166655166,
          46.94806114522585
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 312/А",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 312/А",
        lat: 46.8696645,
        lon: 32.0181483,
        build_s: "7 672,10 м2",
        bld_floor: 10,
        bld_porch: 3,
        bld_apart: 117,
        apart_ind: 15,
        q_2021: "359,869936 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0181483,
          46.8696645
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 312/Б",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 312/Б",
        lat: 46.8694766,
        lon: 32.0182964,
        build_s: "5 651,60 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 74,
        apart_ind: 9,
        q_2021: "233,612900 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0182964,
          46.8694766
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 313",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 313",
        lat: 46.8591973,
        lon: 32.0155116,
        build_s: "646,84 м2",
        bld_floor: 2,
        bld_porch: 3,
        bld_apart: 15,
        apart_ind: 8,
        q_2021: "43,689998 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0155116,
          46.8591973
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 314/2",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 314/2",
        lat: 46.8686970185491,
        lon: 32.01871476363641,
        build_s: "7 870,93 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 5,
        q_2021: "562,111999 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01871476363641,
          46.8686970185491
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 315",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 315",
        lat: 46.8586439,
        lon: 32.015397,
        build_s: "1 137,00 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 19,
        apart_ind: 1,
        q_2021: "49,625593 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.015397,
          46.8586439
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 316",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 316",
        lat: 46.866715698820215,
        lon: 32.017309672727315,
        build_s: "9 863,46 м2",
        bld_floor: 9,
        bld_porch: 6,
        bld_apart: 192,
        apart_ind: 10,
        q_2021: "814,570602 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.017309672727315,
          46.866715698820215
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 317",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 317",
        lat: 46.8573773,
        lon: 32.015072,
        build_s: "673,80 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 2,
        q_2021: "54,310020 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.015072,
          46.8573773
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 318",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 318",
        lat: 46.86552661082435,
        lon: 32.01771058181824,
        build_s: "1 768,14 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 0,
        q_2021: "158,177460 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01771058181824,
          46.86552661082435
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 318/1",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 318/1",
        lat: 46.865184628938714,
        lon: 32.01799312727278,
        build_s: "3 116,20 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 5,
        q_2021: "269,732339 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01799312727278,
          46.865184628938714
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 319",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 319",
        lat: 46.8546767,
        lon: 32.0132109,
        build_s: "428,16 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 7,
        q_2021: "31,449998 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0132109,
          46.8546767
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 32",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 32",
        lat: 46.942176,
        lon: 32.0384206,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 5,
        q_2021: "96,923000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0384206,
          46.942176
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 320",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 320",
        lat: 46.864974478684005,
        lon: 32.01820312727278,
        build_s: "2 403,40 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 58,
        apart_ind: 1,
        q_2021: "214,048026 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01820312727278,
          46.864974478684005
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 322",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 322",
        lat: 46.8648543924548,
        lon: 32.01724476363641,
        build_s: "1 776,60 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 1,
        q_2021: "111,846198 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01724476363641,
          46.8648543924548
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 323/2",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 323/2",
        lat: 46.853034,
        lon: 32.0112612,
        build_s: "4 525,10 м2",
        bld_floor: 16,
        bld_porch: 1,
        bld_apart: 80,
        apart_ind: 0,
        q_2021: "325,100251 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0112612,
          46.853034
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 323/3",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 323/3",
        lat: 46.8529455,
        lon: 32.0110056,
        build_s: "5 047,00 м2",
        bld_floor: 16,
        bld_porch: 1,
        bld_apart: 80,
        apart_ind: 0,
        q_2021: "383,028188 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0110056,
          46.8529455
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 324",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 324",
        lat: 46.864294421599986,
        lon: 32.01768767272733,
        build_s: "5 028,43 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 120,
        apart_ind: 0,
        q_2021: "443,469900 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01768767272733,
          46.864294421599986
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 325/1",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 325/1",
        lat: 46.8520941,
        lon: 32.0102283,
        build_s: "11 542,50 м2",
        bld_floor: 9,
        bld_porch: 6,
        bld_apart: 192,
        apart_ind: 5,
        q_2021: "739,252000 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0102283,
          46.8520941
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 325/2",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 325/2",
        lat: 46.8510525,
        lon: 32.0097912,
        build_s: "4 572,00 м2",
        bld_floor: 16,
        bld_porch: 1,
        bld_apart: 64,
        apart_ind: 0,
        q_2021: "363,339717 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0097912,
          46.85105249999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 325/3",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 325/3",
        lat: 46.8512857,
        lon: 32.010662,
        build_s: "5 788,40 м2",
        bld_floor: 16,
        bld_porch: 1,
        bld_apart: 73,
        apart_ind: 0,
        q_2021: "344,770146 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.010662,
          46.8512857
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 325/4",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 325/4",
        lat: 46.851042,
        lon: 32.0084762,
        build_s: "5 920,00 м2",
        bld_floor: 13,
        bld_porch: 1,
        bld_apart: 77,
        apart_ind: 0,
        q_2021: "340,118005 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0084762,
          46.851042
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 325/5",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 325/5",
        lat: 46.85112,
        lon: 32.0074167,
        build_s: "5 845,00 м2",
        bld_floor: 13,
        bld_porch: 1,
        bld_apart: 77,
        apart_ind: 1,
        q_2021: "337,600106 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0074167,
          46.85112
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 326",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 326",
        lat: 46.86366461057083,
        lon: 32.0171273545455,
        build_s: "1 785,50 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 38,
        apart_ind: 1,
        q_2021: "145,450175 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0171273545455,
          46.86366461057083
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 327/2",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 327/2",
        lat: 46.8523905,
        lon: 32.0090083,
        build_s: "10 526,94 м2",
        bld_floor: 9,
        bld_porch: 5,
        bld_apart: 173,
        apart_ind: 4,
        q_2021: "702,363800 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0090083,
          46.8523905
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 33",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 33",
        lat: 46.94751523085584,
        lon: 32.03440116922282,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 0,
        q_2021: "211,816000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03440116922282,
          46.94751523085584
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 330",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 330",
        lat: 46.8617043,
        lon: 32.0169169,
        build_s: "2 604,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 3,
        q_2021: "108,379881 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0169169,
          46.8617043
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 334",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 334",
        lat: 46.8608028,
        lon: 32.0173788,
        build_s: "5 891,00 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 137,
        apart_ind: 0,
        q_2021: "400,038227 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0173788,
          46.86080279999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 338",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 338",
        lat: 46.8570918,
        lon: 32.0160564,
        build_s: "2 073,50 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 122,
        apart_ind: 3,
        q_2021: "228,269810 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0160564,
          46.8570918
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 34",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 34",
        lat: 46.94176420594637,
        lon: 32.038598466498186,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 26,
        apart_ind: 5,
        q_2021: "106,814000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.038598466498186,
          46.94176420594637
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 34/А",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 34/А",
        lat: 46.942174,
        lon: 32.0390346,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 30,
        apart_ind: 0,
        q_2021: "94,418000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0390346,
          46.942174
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 340",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 340",
        lat: 46.8555485,
        lon: 32.016148,
        build_s: "1 998,30 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 113,
        apart_ind: 3,
        q_2021: "180,253273 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.016148,
          46.8555485
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 340/1",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 340/1",
        lat: 46.8564113,
        lon: 32.0166867,
        build_s: "6 287,00 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 250,
        apart_ind: 0,
        q_2021: "487,095710 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0166867,
          46.8564113
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 340/2",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 340/2",
        lat: 46.8560148,
        lon: 32.0178899,
        build_s: "12 930,00 м2",
        bld_floor: 9,
        bld_porch: 6,
        bld_apart: 192,
        apart_ind: 1,
        q_2021: "715,044857 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0178899,
          46.8560148
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 35",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 35",
        lat: 46.9465634,
        lon: 32.0349668,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 0,
        q_2021: "180,902000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0349668,
          46.9465634
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 36",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 36",
        lat: 46.9411098,
        lon: 32.0389326,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 33,
        apart_ind: 4,
        q_2021: "158,018000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0389326,
          46.9411098
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Богоявленський,325/4-1",
        address: "пр-т Богоявленський, 37",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 37",
        lat: 46.9457718,
        lon: 32.0352069,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 4,
        q_2021: "249,678000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0352069,
          46.9457718
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Богоявленський,325/4-1",
        address: "пр-т Богоявленський, 38",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 38",
        lat: 46.94024241109717,
        lon: 32.03943679988723,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 33,
        apart_ind: 8,
        q_2021: "169,901000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03943679988723,
          46.94024241109717
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 39",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 39",
        lat: 46.94509141339174,
        lon: 32.03573842118257,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 76,
        apart_ind: 7,
        q_2021: "240,336000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03573842118257,
          46.94509141339174
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 4",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 4",
        lat: 46.95208622803926,
        lon: 32.03367740662739,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 59,
        apart_ind: 3,
        q_2021: "257,655000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03367740662739,
          46.95208622803926
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 40",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 40",
        lat: 46.9394665,
        lon: 32.0398082,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 25,
        apart_ind: 3,
        q_2021: "129,460000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0398082,
          46.93946649999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 41",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 41",
        lat: 46.9422871,
        lon: 32.0372545,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 1,
        q_2021: "262,717000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0372545,
          46.94228709999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 41/А",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 41/А",
        lat: 46.9428747,
        lon: 32.0369201,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 16,
        apart_ind: 0,
        q_2021: "79,999000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0369201,
          46.9428747
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 42",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 42",
        lat: 46.9392485,
        lon: 32.0410063,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 29,
        apart_ind: 0,
        q_2021: "88,668000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0410063,
          46.9392485
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 43",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 43",
        lat: 46.9351226,
        lon: 32.0410305,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 1,
        q_2021: "183,632000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04103050000001,
          46.9351226
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 45",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 45",
        lat: 46.9410325,
        lon: 32.0379652,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 3,
        q_2021: "272,962000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0379652,
          46.9410325
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 47",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 47",
        lat: 46.94007137226479,
        lon: 32.03843157309155,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 40,
        apart_ind: 5,
        q_2021: "140,729000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03843157309155,
          46.94007137226479
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 49",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 49",
        lat: 46.939552008483915,
        lon: 32.03866411129419,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 31,
        apart_ind: 4,
        q_2021: "127,181000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03866411129419,
          46.939552008483915
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Богоявленський, 49/А",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 49/А",
        lat: 46.9395143342313,
        lon: 32.038139915006894,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 39,
        apart_ind: 5,
        q_2021: "137,530000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.038139915006894,
          46.9395143342313
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 51",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 51",
        lat: 46.9390864605021,
        lon: 32.03880205768557,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 66,
        apart_ind: 0,
        q_2021: "224,394000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03880205768557,
          46.9390864605021
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 51/А",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 51/А",
        lat: 46.9388088,
        lon: 32.0379316,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 76,
        apart_ind: 0,
        q_2021: "269,254000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0379316,
          46.9388088
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 53",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 53",
        lat: 46.9381149860702,
        lon: 32.03941690445864,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 28,
        apart_ind: 2,
        q_2021: "105,649000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03941690445864,
          46.938114986070204
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 53/А",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 53/А",
        lat: 46.93839485862814,
        lon: 32.03869958322339,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 32,
        apart_ind: 2,
        q_2021: "126,735000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03869958322339,
          46.93839485862814
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 55",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 55",
        lat: 46.9330943,
        lon: 32.0420935,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 35,
        apart_ind: 3,
        q_2021: "147,892000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0420935,
          46.9330943
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 6",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 6",
        lat: 46.95154726454578,
        lon: 32.03362679385848,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 3,
        q_2021: "179,161000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03362679385848,
          46.95154726454578
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Богоявленський, 8",
        adrs_full: "м. Миколаїв, пр-т Богоявленський, 8",
        lat: 46.9509059,
        lon: 32.0338858,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 65,
        apart_ind: 1,
        q_2021: "242,712000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0338858,
          46.9509059
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Героїв України, 10",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 10",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "1 011,50 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 3,
        q_2021: "68,409990 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Героїв України, 101",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 101",
        lat: 47.0139596,
        lon: 31.9969759,
        build_s: "6 316,00 м2",
        bld_floor: 14,
        bld_porch: 1,
        bld_apart: 80,
        apart_ind: 0,
        q_2021: "397,902461 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9969759,
          47.0139596
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Героїв України, 103",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 103",
        lat: 47.0142532,
        lon: 31.9970256,
        build_s: "5 925,40 м2",
        bld_floor: 14,
        bld_porch: 1,
        bld_apart: 80,
        apart_ind: 0,
        q_2021: "377,070605 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9970256,
          47.0142532
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Героїв України, 105",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 105",
        lat: 47.014545,
        lon: 31.9970834,
        build_s: "6 091,00 м2",
        bld_floor: 14,
        bld_porch: 1,
        bld_apart: 80,
        apart_ind: 0,
        q_2021: "276,928919 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9970834,
          47.014545
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Героїв України, 107",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 107",
        lat: 47.0138394,
        lon: 31.9984714,
        build_s: "5 215,30 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 9,
        q_2021: "272,919541 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9984714,
          47.01383939999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Героїв України, 107/А",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 107/А",
        lat: 47.0138394,
        lon: 31.9984714,
        build_s: "2 094,20 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 36,
        apart_ind: 3,
        q_2021: "145,159487 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9984714,
          47.01383939999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Героїв України, 12",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 12",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "6 468,50 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 140,
        apart_ind: 10,
        q_2021: "457,045100 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Героїв України, 13/А",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 13/А",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "6 145,00 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 4,
        q_2021: "417,444427 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Героїв України, 13/В",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 13/В",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "10 568,50 м2",
        bld_floor: 10,
        bld_porch: 4,
        bld_apart: 111,
        apart_ind: 32,
        q_2021: "434,829304 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Героїв України, 13/Г",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 13/Г",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "7 059,20 м2",
        bld_floor: 10,
        bld_porch: 3,
        bld_apart: 180,
        apart_ind: 5,
        q_2021: "496,750154 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Героїв України, 13/Д",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 13/Д",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "1 868,70 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 30,
        apart_ind: 5,
        q_2021: "118,979991 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Героїв України, 13/Е",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 13/Е",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "5 402,70 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 6,
        q_2021: "315,490845 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Героїв України, 14",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 14",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "512,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "45,970029 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Героїв України, 15",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 15",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "4 036,40 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 68,
        apart_ind: 4,
        q_2021: "193,771439 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Героїв України, 15/А",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 15/А",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "7 862,23 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 18,
        q_2021: "454,540922 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пр-т Героїв України, 15/Б",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 15/Б",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "3 995,28 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "247,880841 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "криш.кот.пр.Героїв України,101",
        address: "пр-т Героїв України, 15/В",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 15/В",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "7 862,23 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 5,
        q_2021: "419,943151 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "криш.кот.пр.Героїв України,101",
        address: "пр-т Героїв України, 15/Г",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 15/Г",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "9 148,00 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 142,
        apart_ind: 17,
        q_2021: "469,304817 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "криш.кот.пр.Героїв України,101",
        address: "пр-т Героїв України, 16",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 16",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "4 143,84 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 161,
        apart_ind: 1,
        q_2021: "369,343400 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,91-К",
        address: "пр-т Героїв України, 17",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 17",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "8 568,00 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 138,
        apart_ind: 7,
        q_2021: "479,441757 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,91-К",
        address: "пр-т Героїв України, 18",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 18",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "4 143,84 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 160,
        apart_ind: 4,
        q_2021: "344,831573 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "пр.Героїв України,12",
        address: "пр-т Героїв України, 19",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 19",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "8 568,40 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 135,
        apart_ind: 16,
        q_2021: "467,032400 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пр-т Героїв України, 20",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 20",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "5 820,00 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 4,
        q_2021: "432,725323 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пр-т Героїв України, 20/А",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 20/А",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "3 200,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 63,
        apart_ind: 8,
        q_2021: "257,200691 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пр-т Героїв України, 20/Б",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 20/Б",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "3 200,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 3,
        q_2021: "247,869954 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.пр.Героїв України,13-Е",
        address: "пр-т Героїв України, 20/В",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 20/В",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "3 846,00 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 68,
        apart_ind: 3,
        q_2021: "249,950042 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.пр.Героїв України,13-Е",
        address: "пр-т Героїв України, 20/Г",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 20/Г",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "4 021,65 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 7,
        q_2021: "258,568135 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "пр.Героїв України,12",
        address: "пр-т Героїв України, 21",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 21",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "8 568,40 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 2,
        q_2021: "489,850000 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пр-т Героїв України, 22/А",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 22/А",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "809,80 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 21,
        apart_ind: 0,
        q_2021: "119,187200 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пр-т Героїв України, 23/1",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 23/1",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "4 686,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 108,
        apart_ind: 3,
        q_2021: "288,462738 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пр-т Героїв України, 4",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 4",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "3 329,00 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 115,
        apart_ind: 0,
        q_2021: "230,360918 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пр-т Героїв України, 45",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 45",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "521,60 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 1,
        q_2021: "39,709990 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пр-т Героїв України, 47",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 47",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "545,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 1,
        q_2021: "41,380007 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "пр.Героїв України,12",
        address: "пр-т Героїв України, 47/А",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 47/А",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "516,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 2,
        q_2021: "53,769993 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пр-т Героїв України, 49",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 49",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "526,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 2,
        q_2021: "44,829977 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "пр.Героїв України,12",
        address: "пр-т Героїв України, 51",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 51",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "541,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 1,
        q_2021: "42,219980 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пр-т Героїв України, 53",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 53",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "1 007,00 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 20,
        apart_ind: 2,
        q_2021: "73,896539 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 55",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 55",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "1 368,00 м2",
        bld_floor: 3,
        bld_porch: 3,
        bld_apart: 35,
        apart_ind: 4,
        q_2021: "105,097016 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 59",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 59",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "1 362,00 м2",
        bld_floor: 3,
        bld_porch: 3,
        bld_apart: 34,
        apart_ind: 2,
        q_2021: "129,748815 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 59/А",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 59/А",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "1 188,00 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 1,
        q_2021: "108,021908 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 6",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 6",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "2 980,50 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 66,
        apart_ind: 4,
        q_2021: "240,582623 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 61",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 61",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "1 185,00 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 0,
        q_2021: "104,152517 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пр-т Героїв України, 63",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 63",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "3 198,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 2,
        q_2021: "280,020053 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 65",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 65",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "3 200,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 54,
        apart_ind: 3,
        q_2021: "232,501935 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пр-т Героїв України, 67",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 67",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "3 198,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 2,
        q_2021: "315,189899 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пр-т Героїв України, 69",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 69",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "1 407,00 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 18,
        apart_ind: 6,
        q_2021: "66,969964 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 71",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 71",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "1 360,00 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 32,
        apart_ind: 2,
        q_2021: "119,260030 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 72/131",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 72/131",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "539,00 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 4,
        apart_ind: 0,
        q_2021: "57,225800 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 72/132",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 72/132",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "467,00 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 8,
        apart_ind: 0,
        q_2021: "55,984400 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 72/133",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 72/133",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "453,00 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 7,
        apart_ind: 1,
        q_2021: "60,685200 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 72/62",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 72/62",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "308,00 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 6,
        apart_ind: 0,
        q_2021: "42,243700 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 72/63",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 72/63",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "243,00 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 4,
        apart_ind: 0,
        q_2021: "35,324000 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 73",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 73",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "1 406,10 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 18,
        apart_ind: 7,
        q_2021: "79,349979 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 75",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 75",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "1 995,90 м2",
        bld_floor: 3,
        bld_porch: 3,
        bld_apart: 24,
        apart_ind: 6,
        q_2021: "135,637049 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 75/А",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 75/А",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "3 888,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "225,310242 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пр-т Героїв України, 75/Б",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 75/Б",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "3 888,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "223,400070 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 75/В",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 75/В",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "4 657,00 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 16,
        q_2021: "245,337627 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 75/Г",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 75/Г",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "4 789,00 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 7,
        q_2021: "301,431987 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 77",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 77",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "879,61 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 7,
        q_2021: "88,660105 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 77/А",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 77/А",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "3 888,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 2,
        q_2021: "224,929679 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 79",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 79",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "4 410,10 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 88,
        apart_ind: 5,
        q_2021: "338,108594 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 79/А",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 79/А",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "6 919,00 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 108,
        apart_ind: 8,
        q_2021: "449,310158 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 79/Б",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 79/Б",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "3 509,06 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 1,
        q_2021: "253,630008 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 8",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 8",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "638,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 16,
        apart_ind: 1,
        q_2021: "49,132970 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 81",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 81",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "2 719,20 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 3,
        q_2021: "215,350308 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 81/А",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 81/А",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "6 180,00 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 108,
        apart_ind: 8,
        q_2021: "431,510300 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 83",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 83",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "274,00 м2",
        bld_floor: 2,
        bld_porch: 4,
        bld_apart: 8,
        apart_ind: 1,
        q_2021: "40,483900 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 85",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 85",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "297,30 м2",
        bld_floor: 2,
        bld_porch: 4,
        bld_apart: 8,
        apart_ind: 1,
        q_2021: "23,789954 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 87",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 87",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "3 197,30 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 5,
        q_2021: "217,583054 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 87/А",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 87/А",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "4 544,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 8,
        q_2021: "258,300183 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 87/Б",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 87/Б",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "5 171,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 7,
        q_2021: "259,168693 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 87/В",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 87/В",
        lat: 47.0141157,
        lon: 31.9974434,
        build_s: "2 568,00 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 36,
        apart_ind: 5,
        q_2021: "127,381590 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974434,
          47.0141157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 89",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 89",
        lat: 47.0140459,
        lon: 31.9975221,
        build_s: "5 040,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 70,
        apart_ind: 0,
        q_2021: "283,710647 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9975221,
          47.0140459
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 93",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 93",
        lat: 47.0133126,
        lon: 31.9970505,
        build_s: "5 029,00 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 10,
        q_2021: "270,629900 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9970505,
          47.0133126
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 93/А",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 93/А",
        lat: 47.0133126,
        lon: 31.9970505,
        build_s: "2 639,16 м2",
        bld_floor: 10,
        bld_porch: 1,
        bld_apart: 36,
        apart_ind: 6,
        q_2021: "136,774082 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9970505,
          47.0133126
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 95",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 95",
        lat: 47.0135869,
        lon: 31.9974981,
        build_s: "4 727,30 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 118,
        apart_ind: 4,
        q_2021: "295,050100 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974981,
          47.0135869
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 97",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 97",
        lat: 47.0139076,
        lon: 31.9976776,
        build_s: "5 410,00 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 8,
        q_2021: "246,182899 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9976776,
          47.0139076
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Героїв України, 97/Б",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 97/Б",
        lat: 47.0139076,
        lon: 31.9976776,
        build_s: "4 937,90 м2",
        bld_floor: 16,
        bld_porch: 1,
        bld_apart: 95,
        apart_ind: 2,
        q_2021: "430,416157 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9976776,
          47.0139076
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пр-т Героїв України, 99",
        adrs_full: "м. Миколаїв, пр-т Героїв України, 99",
        lat: 47.0141148,
        lon: 31.9974435,
        build_s: "7 256,50 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 106,
        apart_ind: 8,
        q_2021: "329,972000 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9974435,
          47.01411479999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Корабелiв, 1",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 1",
        lat: 46.8579459,
        lon: 32.0046229,
        build_s: "2 578,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 64,
        apart_ind: 1,
        q_2021: "256,033895 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0046229,
          46.8579459
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Корабелiв, 10/А",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 10/А",
        lat: 46.8553361,
        lon: 32.0074547,
        build_s: "5 771,10 м2",
        bld_floor: 5,
        bld_porch: 9,
        bld_apart: 124,
        apart_ind: 5,
        q_2021: "505,536900 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "9",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0074547,
          46.8553361
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Корабелiв, 10/Б",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 10/Б",
        lat: 46.8548175,
        lon: 32.0095897,
        build_s: "7 487,80 м2",
        bld_floor: 5,
        bld_porch: 11,
        bld_apart: 168,
        apart_ind: 16,
        q_2021: "598,883700 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "11",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0095897,
          46.8548175
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Корабелiв, 11",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 11",
        lat: 46.8568096,
        lon: 32.0123751,
        build_s: "4 295,30 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 100,
        apart_ind: 4,
        q_2021: "365,358080 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0123751,
          46.8568096
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Корабелiв, 12",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 12",
        lat: 46.8561743,
        lon: 32.0107029,
        build_s: "19 495,53 м2",
        bld_floor: 9,
        bld_porch: 10,
        bld_apart: 320,
        apart_ind: 28,
        q_2021: "1 430,462600 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01070290000001,
          46.8561743
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Корабелiв, 12/А",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 12/А",
        lat: 46.8556465,
        lon: 32.0113156,
        build_s: "4 332,21 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 171,
        apart_ind: 2,
        q_2021: "461,880307 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0113156,
          46.8556465
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Корабелiв, 12/Б",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 12/Б",
        lat: 46.854849,
        lon: 32.0110398,
        build_s: "4 337,72 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 171,
        apart_ind: 1,
        q_2021: "434,379971 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0110398,
          46.854849
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Корабелiв, 12/В",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 12/В",
        lat: 46.8561743,
        lon: 32.0107029,
        build_s: "7 803,23 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 8,
        q_2021: "667,440179 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01070290000001,
          46.8561743
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,72-А(КЕЧ)",
        address: "пр-т Корабелiв, 13",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 13",
        lat: 46.8566342,
        lon: 32.0137467,
        build_s: "3 373,20 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 66,
        apart_ind: 5,
        q_2021: "225,396920 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0137467,
          46.85663419999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,91-К",
        address: "пр-т Корабелiв, 15",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 15",
        lat: 46.8565281,
        lon: 32.0146265,
        build_s: "2 755,05 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 4,
        q_2021: "217,187179 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0146265,
          46.8565281
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,91-К",
        address: "пр-т Корабелiв, 16",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 16",
        lat: 46.8554154,
        lon: 32.0127983,
        build_s: "5 674,00 м2",
        bld_floor: 14,
        bld_porch: 1,
        bld_apart: 63,
        apart_ind: 1,
        q_2021: "291,650053 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0127983,
          46.8554154
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,91-К",
        address: "пр-т Корабелiв, 16/А",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 16/А",
        lat: 46.8549446,
        lon: 32.0113403,
        build_s: "4 238,56 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 108,
        apart_ind: 1,
        q_2021: "339,145778 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0113403,
          46.8549446
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,91-К",
        address: "пр-т Корабелiв, 18/А",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 18/А",
        lat: 46.854507,
        lon: 32.012153,
        build_s: "22 212,00 м2",
        bld_floor: 9,
        bld_porch: 6,
        bld_apart: 319,
        apart_ind: 0,
        q_2021: "1 129,081126 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.012153,
          46.854507
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,91-К",
        address: "пр-т Корабелiв, 2",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 2",
        lat: 46.85626119050428,
        lon: 32.0041134692958,
        build_s: "6 703,00 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 153,
        apart_ind: 2,
        q_2021: "356,361981 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0041134692958,
          46.85626119050428
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,91-К",
        address: "пр-т Корабелiв, 2/А",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 2/А",
        lat: 46.8551707,
        lon: 32.0046799,
        build_s: "10 439,26 м2",
        bld_floor: 5,
        bld_porch: 14,
        bld_apart: 188,
        apart_ind: 18,
        q_2021: "855,943963 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "14",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00467990000001,
          46.8551707
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Корабелiв, 20/1",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 20/1",
        lat: 46.8532506,
        lon: 32.009218,
        build_s: "4 852,10 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 0,
        q_2021: "305,321816 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.009218,
          46.8532506
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Корабелiв, 20/2",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 20/2",
        lat: 46.8528744,
        lon: 32.0096147,
        build_s: "3 963,02 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 1,
        q_2021: "268,639938 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0096147,
          46.8528744
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Корабелiв, 20/3",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 20/3",
        lat: 46.8533584,
        lon: 32.0102211,
        build_s: "7 161,00 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 93,
        apart_ind: 2,
        q_2021: "386,709706 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0102211,
          46.8533584
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Корабелiв, 3",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 3",
        lat: 46.8555541,
        lon: 31.9977833,
        build_s: "2 661,20 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 66,
        apart_ind: 2,
        q_2021: "254,030865 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9977833,
          46.8555541
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Корабелiв, 4",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 4",
        lat: 46.8565448,
        lon: 32.005063,
        build_s: "7 892,55 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 5,
        q_2021: "519,242189 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.005063,
          46.85654479999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Корабелiв, 5",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 5",
        lat: 46.8571821,
        lon: 32.0070905,
        build_s: "3 086,50 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 0,
        q_2021: "295,999972 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0070905,
          46.8571821
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Корабелiв, 6",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 6",
        lat: 46.8567246,
        lon: 32.005903,
        build_s: "7 809,50 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 6,
        q_2021: "633,020126 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.005903,
          46.8567246
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Корабелiв, 7",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 7",
        lat: 46.8573077,
        lon: 32.0080802,
        build_s: "5 952,63 м2",
        bld_floor: 5,
        bld_porch: 8,
        bld_apart: 129,
        apart_ind: 6,
        q_2021: "418,318616 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "8",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0080802,
          46.8573077
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Корабелiв, 8",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 8",
        lat: 46.8568504,
        lon: 32.0062415,
        build_s: "7 480,80 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 16,
        q_2021: "596,453189 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0062415,
          46.85685040000001
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Корабелiв, 9",
        adrs_full: "м. Миколаїв, пр-т Корабелiв, 9",
        lat: 46.8573954,
        lon: 32.0123856,
        build_s: "510,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 8,
        apart_ind: 2,
        q_2021: "59,749973 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0123856,
          46.85739539999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Миру, 1",
        adrs_full: "м. Миколаїв, пр-т Миру, 1",
        lat: 46.9531822,
        lon: 32.0394253,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 51,
        apart_ind: 2,
        q_2021: "150,719000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0394253,
          46.9531822
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Миру, 10",
        adrs_full: "м. Миколаїв, пр-т Миру, 10",
        lat: 46.952543,
        lon: 32.0417429,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 57,
        apart_ind: 3,
        q_2021: "188,603000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0417429,
          46.952543
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Миру, 11",
        adrs_full: "м. Миколаїв, пр-т Миру, 11",
        lat: 46.95074770735544,
        lon: 32.042964326630134,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 55,
        apart_ind: 5,
        q_2021: "221,323000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.042964326630134,
          46.95074770735544
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Миру, 11/А",
        adrs_full: "м. Миколаїв, пр-т Миру, 11/А",
        lat: 46.9500166,
        lon: 32.0427446,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "64,650000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0427446,
          46.9500166
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Миру, 11/Б",
        adrs_full: "м. Миколаїв, пр-т Миру, 11/Б",
        lat: 46.9502755,
        lon: 32.0434685,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 90,
        apart_ind: 6,
        q_2021: "333,579000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0434685,
          46.9502755
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Миру, 13",
        adrs_full: "м. Миколаїв, пр-т Миру, 13",
        lat: 46.9500266,
        lon: 32.044063,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 31,
        apart_ind: 3,
        q_2021: "113,143000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.044063,
          46.9500266
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Миру, 14",
        adrs_full: "м. Миколаїв, пр-т Миру, 14",
        lat: 46.9520151,
        lon: 32.043366,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 54,
        apart_ind: 4,
        q_2021: "174,930000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.043366,
          46.9520151
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Миру, 14/А",
        adrs_full: "м. Миколаїв, пр-т Миру, 14/А",
        lat: 46.9517032,
        lon: 32.0435549,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 49,
        apart_ind: 1,
        q_2021: "74,131000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0435549,
          46.9517032
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Миру, 14/Б",
        adrs_full: "м. Миколаїв, пр-т Миру, 14/Б",
        lat: 46.9512936108753,
        lon: 32.04354933008331,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 42,
        apart_ind: 1,
        q_2021: "156,385000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04354933008331,
          46.9512936108753
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Миру, 16",
        adrs_full: "м. Миколаїв, пр-т Миру, 16",
        lat: 46.9514753,
        lon: 32.0442847,
        build_s: "?",
        bld_floor: null,
        bld_porch: "?",
        bld_apart: null,
        apart_ind: null,
        q_2021: "241,273000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0442847,
          46.9514753
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Миру, 17",
        adrs_full: "м. Миколаїв, пр-т Миру, 17",
        lat: 46.9452883,
        lon: 32.0505953,
        build_s: "16 561,30 м2",
        bld_floor: 9,
        bld_porch: 9,
        bld_apart: 352,
        apart_ind: 16,
        q_2021: "1 242,867719 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0505953,
          46.9452883
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Миру, 17/А",
        adrs_full: "м. Миколаїв, пр-т Миру, 17/А",
        lat: 46.94422621393761,
        lon: 32.0509129,
        build_s: "3 979,90 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 7,
        q_2021: "255,089869 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.050912899999936,
          46.94422621393761
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Миру, 17/Б",
        adrs_full: "м. Миколаїв, пр-т Миру, 17/Б",
        lat: 46.94408023793559,
        lon: 32.05030962727266,
        build_s: "3 994,70 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 5,
        q_2021: "355,104830 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05030962727266,
          46.94408023793559
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Миру, 17/В",
        adrs_full: "м. Миколаїв, пр-т Миру, 17/В",
        lat: 46.94392644135999,
        lon: 32.04968344545448,
        build_s: "3 992,80 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 2,
        q_2021: "269,499247 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04968344545448,
          46.94392644135999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-6",
        kot_name: "кот.Самойловича,42(МГЗ)",
        address: "пр-т Миру, 18/А",
        adrs_full: "м. Миколаїв, пр-т Миру, 18/А",
        lat: 46.951043196420684,
        lon: 32.045430438531575,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 80,
        apart_ind: 4,
        q_2021: "331,147000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.045430438531575,
          46.95104319642069
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Миру, 19",
        adrs_full: "м. Миколаїв, пр-т Миру, 19",
        lat: 46.94363188058341,
        lon: 32.05172999090903,
        build_s: "11 398,70 м2",
        bld_floor: 9,
        bld_porch: 6,
        bld_apart: 216,
        apart_ind: 9,
        q_2021: "833,307208 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05172999090903,
          46.94363188058341
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Миру, 19/А",
        adrs_full: "м. Миколаїв, пр-т Миру, 19/А",
        lat: 46.94321877051257,
        lon: 32.05066979777935,
        build_s: "3 847,30 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "273,862600 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05066979777935,
          46.94321877051257
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Миру, 19/Б",
        adrs_full: "м. Миколаїв, пр-т Миру, 19/Б",
        lat: 46.94296109616123,
        lon: 32.05134915506595,
        build_s: "2 804,40 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 80,
        apart_ind: 2,
        q_2021: "214,890007 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05134915506595,
          46.94296109616123
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Миру, 19/В",
        adrs_full: "м. Миколаїв, пр-т Миру, 19/В",
        lat: 46.94309116051264,
        lon: 32.052161508223485,
        build_s: "2 784,70 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 80,
        apart_ind: 1,
        q_2021: "215,600068 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.052161508223485,
          46.94309116051264
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Миру, 20-А",
        adrs_full: "м. Миколаїв, пр-т Миру, 20-А",
        lat: 46.950773455430166,
        lon: 32.046278220627336,
        build_s: "?",
        bld_floor: null,
        bld_porch: "?",
        bld_apart: null,
        apart_ind: null,
        q_2021: "78,538300 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.046278220627336,
          46.950773455430166
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Миру, 20/А",
        adrs_full: "м. Миколаїв, пр-т Миру, 20/А",
        lat: 46.9505821837222,
        lon: 32.04674162694239,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 37,
        apart_ind: 8,
        q_2021: "145,551000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04674162694239,
          46.9505821837222
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Миру, 21",
        adrs_full: "м. Миколаїв, пр-т Миру, 21",
        lat: 46.943073982220184,
        lon: 32.05366400211661,
        build_s: "17 819,00 м2",
        bld_floor: 9,
        bld_porch: 10,
        bld_apart: 352,
        apart_ind: 16,
        q_2021: "1 227,517486 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05366400211661,
          46.943073982220184
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Миру, 21/А",
        adrs_full: "м. Миколаїв, пр-т Миру, 21/А",
        lat: 46.9422788,
        lon: 32.0538189,
        build_s: "2 166,50 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 80,
        apart_ind: 0,
        q_2021: "185,907038 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0538189,
          46.9422788
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Миру, 21/Б",
        adrs_full: "м. Миколаїв, пр-т Миру, 21/Б",
        lat: 46.9422203,
        lon: 32.0531288,
        build_s: "2 907,00 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 80,
        apart_ind: 3,
        q_2021: "175,052665 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0531288,
          46.9422203
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Миру, 22",
        adrs_full: "м. Миколаїв, пр-т Миру, 22",
        lat: 46.9493385,
        lon: 32.0465368,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 97,
        apart_ind: 1,
        q_2021: "248,572000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0465368,
          46.9493385
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 23/А",
        adrs_full: "м. Миколаїв, пр-т Миру, 23/А",
        lat: 46.93996462049646,
        lon: 32.055116173247775,
        build_s: "9 241,60 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 2,
        q_2021: "576,007667 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.055116173247775,
          46.93996462049646
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 23/Б",
        adrs_full: "м. Миколаїв, пр-т Миру, 23/Б",
        lat: 46.93988117815164,
        lon: 32.0561010615892,
        build_s: "9 214,80 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 6,
        q_2021: "498,903894 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0561010615892,
          46.93988117815164
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 23/В",
        adrs_full: "м. Миколаїв, пр-т Миру, 23/В",
        lat: 46.939849273691316,
        lon: 32.05704641061766,
        build_s: "9 321,48 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 11,
        q_2021: "569,072168 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05704641061766,
          46.93984927369131
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 24",
        adrs_full: "м. Миколаїв, пр-т Миру, 24",
        lat: 46.94956230882363,
        lon: 32.047628532720324,
        build_s: "3 004,60 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 1,
        q_2021: "166,423185 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.047628532720324,
          46.94956230882363
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Миру, 25",
        adrs_full: "м. Миколаїв, пр-т Миру, 25",
        lat: 46.93863410132156,
        lon: 32.05959674139488,
        build_s: "8 338,80 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 141,
        apart_ind: 9,
        q_2021: "581,955652 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05959674139488,
          46.93863410132156
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 25/А",
        adrs_full: "м. Миколаїв, пр-т Миру, 25/А",
        lat: 46.939055192105734,
        lon: 32.05896153147122,
        build_s: "9 963,00 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 128,
        apart_ind: 6,
        q_2021: "501,965400 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05896153147122,
          46.939055192105734
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 26",
        adrs_full: "м. Миколаїв, пр-т Миру, 26",
        lat: 46.949923395567566,
        lon: 32.046979463671065,
        build_s: "3 356,60 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 3,
        q_2021: "221,470196 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.046979463671065,
          46.949923395567566
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 27/А",
        adrs_full: "м. Миколаїв, пр-т Миру, 27/А",
        lat: 46.93921854314958,
        lon: 32.05735553615547,
        build_s: "4 005,10 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 5,
        q_2021: "286,812572 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05735553615547,
          46.93921854314958
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 27/Б",
        adrs_full: "м. Миколаїв, пр-т Миру, 27/Б",
        lat: 46.9384733,
        lon: 32.0575662,
        build_s: "9 321,48 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 5,
        q_2021: "524,405968 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0575662,
          46.9384733
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Миру, 27/В",
        adrs_full: "м. Миколаїв, пр-т Миру, 27/В",
        lat: 46.9388972,
        lon: 32.0580984,
        build_s: "4 833,10 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 7,
        q_2021: "303,561735 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0580984,
          46.9388972
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Миру, 28",
        adrs_full: "м. Миколаїв, пр-т Миру, 28",
        lat: 46.95012680143802,
        lon: 32.04783410975016,
        build_s: "3 402,70 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 3,
        q_2021: "257,350255 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04783410975016,
          46.95012680143802
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 3",
        adrs_full: "м. Миколаїв, пр-т Миру, 3",
        lat: 46.95261164434078,
        lon: 32.03948691989935,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 78,
        apart_ind: 1,
        q_2021: "236,536000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03948691989935,
          46.95261164434078
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 30",
        adrs_full: "м. Миколаїв, пр-т Миру, 30",
        lat: 46.9486193,
        lon: 32.047557,
        build_s: "4 784,40 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 100,
        apart_ind: 0,
        q_2021: "364,600088 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.047557,
          46.9486193
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 30/А",
        adrs_full: "м. Миколаїв, пр-т Миру, 30/А",
        lat: 46.94835446384677,
        lon: 32.04828453133239,
        build_s: "4 275,70 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 36,
        apart_ind: 26,
        q_2021: "56,931025 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04828453133239,
          46.94835446384677
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Миру, 32",
        adrs_full: "м. Миколаїв, пр-т Миру, 32",
        lat: 46.94914918475755,
        lon: 32.048169038618994,
        build_s: "3 492,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 0,
        q_2021: "261,700014 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.048169038618994,
          46.94914918475755
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Миру, 34/А",
        adrs_full: "м. Миколаїв, пр-т Миру, 34/А",
        lat: 46.94819756346229,
        lon: 32.04950521699022,
        build_s: "3 860,60 м2",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: 80,
        apart_ind: 4,
        q_2021: "249,029877 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04950521699022,
          46.94819756346229
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Миру, 4",
        adrs_full: "м. Миколаїв, пр-т Миру, 4",
        lat: 46.9534436,
        lon: 32.0404788,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 1,
        q_2021: "219,799000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "Так",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0404788,
          46.9534436
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Миру, 42",
        adrs_full: "м. Миколаїв, пр-т Миру, 42",
        lat: 46.94686295376401,
        lon: 32.05115887509254,
        build_s: "8 418,80 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 5,
        q_2021: "492,978678 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05115887509254,
          46.946862953764004
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 44",
        adrs_full: "м. Миколаїв, пр-т Миру, 44",
        lat: 46.94644149115647,
        lon: 32.05171744405154,
        build_s: "8 551,70 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 9,
        q_2021: "641,255887 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05171744405154,
          46.94644149115647
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Миру, 46",
        adrs_full: "м. Миколаїв, пр-т Миру, 46",
        lat: 46.94605514751778,
        lon: 32.05233113494729,
        build_s: "9 125,70 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 4,
        q_2021: "438,335718 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05233113494729,
          46.94605514751778
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Миру, 46/А",
        adrs_full: "м. Миколаїв, пр-т Миру, 46/А",
        lat: 46.94609779597845,
        lon: 32.053297606238196,
        build_s: "3 713,70 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "300,844777 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.053297606238196,
          46.94609779597845
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 48",
        adrs_full: "м. Миколаїв, пр-т Миру, 48",
        lat: 46.9448703,
        lon: 32.0535532,
        build_s: "15 978,00 м2",
        bld_floor: 9,
        bld_porch: 6,
        bld_apart: 313,
        apart_ind: 12,
        q_2021: "1 165,488503 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0535532,
          46.9448703
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Миру, 5",
        adrs_full: "м. Миколаїв, пр-т Миру, 5",
        lat: 46.95233525619712,
        lon: 32.039836148004824,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 78,
        apart_ind: 4,
        q_2021: "275,520000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.039836148004824,
          46.952335256197124
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Миру, 52",
        adrs_full: "м. Миколаїв, пр-т Миру, 52",
        lat: 46.9446242,
        lon: 32.0554209,
        build_s: "3 856,10 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 71,
        apart_ind: 1,
        q_2021: "306,599873 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0554209,
          46.9446242
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Миру, 54",
        adrs_full: "м. Миколаїв, пр-т Миру, 54",
        lat: 46.9440141,
        lon: 32.0554168,
        build_s: "8 485,30 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 11,
        q_2021: "518,646169 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0554168,
          46.9440141
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 56",
        adrs_full: "м. Миколаїв, пр-т Миру, 56",
        lat: 46.94363188058341,
        lon: 32.05596435454543,
        build_s: "8 554,80 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 3,
        q_2021: "509,149838 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05596435454543,
          46.94363188058341
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Миру, 58",
        adrs_full: "м. Миколаїв, пр-т Миру, 58",
        lat: 46.94320176680383,
        lon: 32.05643399090906,
        build_s: "8 466,20 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 8,
        q_2021: "601,693100 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05643399090906,
          46.94320176680383
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 60",
        adrs_full: "м. Миколаїв, пр-т Миру, 60",
        lat: 46.941522986623646,
        lon: 32.057879172727255,
        build_s: "8 881,60 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 3,
        q_2021: "505,755545 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.057879172727255,
          46.941522986623646
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 62",
        adrs_full: "м. Миколаїв, пр-т Миру, 62",
        lat: 46.94190097743632,
        lon: 32.05893299090909,
        build_s: "5 961,13 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 100,
        apart_ind: 3,
        q_2021: "356,289990 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05893299090909,
          46.94190097743632
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 62/А",
        adrs_full: "м. Миколаїв, пр-т Миру, 62/А",
        lat: 46.9426418,
        lon: 32.0596073,
        build_s: "3 160,00 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 67,
        apart_ind: 0,
        q_2021: "201,825389 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0596073,
          46.9426418
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 64",
        adrs_full: "м. Миколаїв, пр-т Миру, 64",
        lat: 46.94154384135873,
        lon: 32.0592919,
        build_s: "5 822,50 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 100,
        apart_ind: 7,
        q_2021: "330,635790 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0592919,
          46.94154384135873
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Миру, 64/А",
        adrs_full: "м. Миколаїв, пр-т Миру, 64/А",
        lat: 46.940889520174544,
        lon: 32.05993335454545,
        build_s: "3 340,50 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 3,
        q_2021: "230,631989 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05993335454545,
          46.940889520174544
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 66",
        adrs_full: "м. Миколаїв, пр-т Миру, 66",
        lat: 46.9408478101865,
        lon: 32.0588757181818,
        build_s: "3 995,28 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 4,
        q_2021: "278,838697 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0588757181818,
          46.9408478101865
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 68",
        adrs_full: "м. Миколаїв, пр-т Миру, 68",
        lat: 46.94046459815069,
        lon: 32.059406445454535,
        build_s: "3 800,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: 5,
        q_2021: "240,749870 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.059406445454535,
          46.94046459815069
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 7",
        adrs_full: "м. Миколаїв, пр-т Миру, 7",
        lat: 46.95209687027673,
        lon: 32.04032203058636,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 79,
        apart_ind: 4,
        q_2021: "250,309000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04032203058636,
          46.95209687027673
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-1",
        kot_name: "кот.112 мкр Миколаївська 34-б",
        address: "пр-т Миру, 7/1",
        adrs_full: "м. Миколаїв, пр-т Миру, 7/1",
        lat: 46.95192412619266,
        lon: 32.0408635872137,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 7,
        q_2021: "225,395000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0408635872137,
          46.95192412619266
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Миру, 70",
        adrs_full: "м. Миколаїв, пр-т Миру, 70",
        lat: 46.93993539606824,
        lon: 32.06015480909091,
        build_s: "4 487,60 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 69,
        apart_ind: 5,
        q_2021: "229,955021 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06015480909091,
          46.93993539606824
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Миру, 70/А",
        adrs_full: "м. Миколаїв, пр-т Миру, 70/А",
        lat: 46.940357715393006,
        lon: 32.06074280909092,
        build_s: "3 308,30 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 81,
        apart_ind: 1,
        q_2021: "257,550138 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06074280909092,
          46.940357715393006
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Миру, 70/Б",
        adrs_full: "м. Миколаїв, пр-т Миру, 70/Б",
        lat: 46.94085302393678,
        lon: 32.06080008181819,
        build_s: "3 675,70 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "234,804175 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06080008181819,
          46.94085302393678
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Миру, 70/В",
        adrs_full: "м. Миколаїв, пр-т Миру, 70/В",
        lat: 46.941098069627344,
        lon: 32.061494990909104,
        build_s: "3 807,90 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 6,
        q_2021: "211,239942 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.061494990909104,
          46.941098069627344
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Миру, 72",
        adrs_full: "м. Миколаїв, пр-т Миру, 72",
        lat: 46.9396385,
        lon: 32.0609881,
        build_s: "7 207,30 м2",
        bld_floor: 15,
        bld_porch: 1,
        bld_apart: 85,
        apart_ind: 0,
        q_2021: "398,199665 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "так",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0609881,
          46.9396385
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Миру, 8",
        adrs_full: "м. Миколаїв, пр-т Миру, 8",
        lat: 46.952980294282405,
        lon: 32.041546207648565,
        build_s: "?",
        bld_floor: 4,
        bld_porch: "?",
        bld_apart: 46,
        apart_ind: 0,
        q_2021: "125,149000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.041546207648565,
          46.95298029428241
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Миру, 9",
        adrs_full: "м. Миколаїв, пр-т Миру, 9",
        lat: 46.951509386372315,
        lon: 32.041884854545444,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 28,
        apart_ind: 5,
        q_2021: "113,251000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.041884854545444,
          46.95150938637231
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Миру, 9/А",
        adrs_full: "м. Миколаїв, пр-т Миру, 9/А",
        lat: 46.951145241891886,
        lon: 32.04240123813323,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 1,
        q_2021: "56,735000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04240123813323,
          46.951145241891886
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Центральний, 10",
        adrs_full: "м. Миколаїв, пр-т Центральний, 10",
        lat: 46.968657865077205,
        lon: 31.966739975884806,
        build_s: "3 056,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 8,
        q_2021: "198,092730 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.966739975884806,
          46.968657865077205
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Центральний, 10/А",
        adrs_full: "м. Миколаїв, пр-т Центральний, 10/А",
        lat: 46.969509378914715,
        lon: 31.96665021516308,
        build_s: "4 449,60 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 101,
        apart_ind: 3,
        q_2021: "373,300100 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96665021516308,
          46.969509378914715
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Центральний, 11",
        adrs_full: "м. Миколаїв, пр-т Центральний, 11",
        lat: 46.96778297368596,
        lon: 31.966607536398335,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 73,
        apart_ind: 7,
        q_2021: "299,323000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.966607536398335,
          46.96778297368596
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Центральний, 12",
        adrs_full: "м. Миколаїв, пр-т Центральний, 12",
        lat: 46.96881502250669,
        lon: 31.967530394899374,
        build_s: "2 765,30 м2",
        bld_floor: 9,
        bld_porch: 1,
        bld_apart: 58,
        apart_ind: 3,
        q_2021: "210,509917 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.967530394899374,
          46.96881502250669
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Центральний, 122",
        adrs_full: "м. Миколаїв, пр-т Центральний, 122",
        lat: 46.966055597361354,
        lon: 32.00884120697433,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 96,
        apart_ind: 8,
        q_2021: "389,728000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00884120697433,
          46.966055597361354
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 124/А",
        adrs_full: "м. Миколаїв, пр-т Центральний, 124/А",
        lat: 46.9662913,
        lon: 32.0094371,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 189,
        apart_ind: 1,
        q_2021: "421,835000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0094371,
          46.96629129999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 135",
        adrs_full: "м. Миколаїв, пр-т Центральний, 135",
        lat: 46.9650733,
        lon: 32.004756,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 96,
        apart_ind: 5,
        q_2021: "311,561000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.004756,
          46.9650733
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Центральний, 137",
        adrs_full: "м. Миколаїв, пр-т Центральний, 137",
        lat: 46.9653141,
        lon: 32.0056742,
        build_s: "?",
        bld_floor: 10,
        bld_porch: "?",
        bld_apart: 60,
        apart_ind: 3,
        q_2021: "278,010000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0056742,
          46.9653141
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Центральний, 138",
        adrs_full: "м. Миколаїв, пр-т Центральний, 138",
        lat: 46.9660478361111,
        lon: 32.01024860773769,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 96,
        apart_ind: 8,
        q_2021: "351,416000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01024860773769,
          46.9660478361111
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Центральний, 139",
        adrs_full: "м. Миколаїв, пр-т Центральний, 139",
        lat: 46.9650846667006,
        lon: 32.00667483022207,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 105,
        apart_ind: 25,
        q_2021: "304,601000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00667483022207,
          46.9650846667006
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Центральний, 140",
        adrs_full: "м. Миколаїв, пр-т Центральний, 140",
        lat: 46.966267,
        lon: 32.0110024,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 169,
        apart_ind: 0,
        q_2021: "399,830000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0110024,
          46.966267
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-5",
        kot_name: "кот. 114 мкр Китобоїв,7-а",
        address: "пр-т Центральний, 141",
        adrs_full: "м. Миколаїв, пр-т Центральний, 141",
        lat: 46.9652393,
        lon: 32.0080101,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 62,
        apart_ind: 3,
        q_2021: "216,318000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0080101,
          46.9652393
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 141/А",
        adrs_full: "м. Миколаїв, пр-т Центральний, 141/А",
        lat: 46.9649185,
        lon: 32.0087685,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 104,
        apart_ind: 8,
        q_2021: "460,737000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0087685,
          46.9649185
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 141/Б",
        adrs_full: "м. Миколаїв, пр-т Центральний, 141/Б",
        lat: 46.9644633,
        lon: 32.0077267,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 138,
        apart_ind: 7,
        q_2021: "569,076000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0077267,
          46.9644633
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 147",
        adrs_full: "м. Миколаїв, пр-т Центральний, 147",
        lat: 46.9650916,
        lon: 32.0095656,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 58,
        apart_ind: 5,
        q_2021: "177,707000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0095656,
          46.9650916
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "пр-т Центральний, 148",
        adrs_full: "м. Миколаїв, пр-т Центральний, 148",
        lat: 46.9658578,
        lon: 32.0119765,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 152,
        apart_ind: 1,
        q_2021: "378,867000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0119765,
          46.9658578
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "пр-т Центральний, 148/А",
        adrs_full: "м. Миколаїв, пр-т Центральний, 148/А",
        lat: 46.965998,
        lon: 32.012406,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 40,
        apart_ind: 1,
        q_2021: "145,455000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.012406,
          46.965998
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 149",
        adrs_full: "м. Миколаїв, пр-т Центральний, 149",
        lat: 46.9649246,
        lon: 32.0103717,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 8,
        q_2021: "191,010000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0103717,
          46.9649246
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "пр-т Центральний, 15",
        adrs_full: "м. Миколаїв, пр-т Центральний, 15",
        lat: 46.9677575,
        lon: 31.9685327,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 5,
        q_2021: "279,963000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9685327,
          46.9677575
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 151",
        adrs_full: "м. Миколаїв, пр-т Центральний, 151",
        lat: 46.96449367623814,
        lon: 32.010379516342816,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 8,
        q_2021: "212,702000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.010379516342816,
          46.96449367623814
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 151/А",
        adrs_full: "м. Миколаїв, пр-т Центральний, 151/А",
        lat: 46.9642241,
        lon: 32.0111983,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 144,
        apart_ind: 11,
        q_2021: "564,523000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0111983,
          46.9642241
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 152",
        adrs_full: "м. Миколаїв, пр-т Центральний, 152",
        lat: 46.96653424911626,
        lon: 32.013333671712346,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 129,
        apart_ind: 8,
        q_2021: "566,521000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.013333671712346,
          46.96653424911626
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 152/А",
        adrs_full: "м. Миколаїв, пр-т Центральний, 152/А",
        lat: 46.96617219987196,
        lon: 32.01398330183032,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 6,
        q_2021: "301,256000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01398330183032,
          46.96617219987196
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 153",
        adrs_full: "м. Миколаїв, пр-т Центральний, 153",
        lat: 46.9641191836957,
        lon: 32.01033686783483,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 3,
        q_2021: "214,818000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01033686783483,
          46.9641191836957
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 155",
        adrs_full: "м. Миколаїв, пр-т Центральний, 155",
        lat: 46.96374947520791,
        lon: 32.01029177397217,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 69,
        apart_ind: 4,
        q_2021: "206,095000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01029177397217,
          46.96374947520791
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 157",
        adrs_full: "м. Миколаїв, пр-т Центральний, 157",
        lat: 46.96490357153298,
        lon: 32.01124633692623,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 59,
        apart_ind: 1,
        q_2021: "199,101000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01124633692623,
          46.96490357153298
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 158",
        adrs_full: "м. Миколаїв, пр-т Центральний, 158",
        lat: 46.965789761619746,
        lon: 32.015489810510836,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 146,
        apart_ind: 5,
        q_2021: "578,100000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.015489810510836,
          46.965789761619746
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 159",
        adrs_full: "м. Миколаїв, пр-т Центральний, 159",
        lat: 46.96478791512336,
        lon: 32.01205317630494,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 62,
        apart_ind: 11,
        q_2021: "212,622000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01205317630494,
          46.96478791512336
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 16",
        adrs_full: "м. Миколаїв, пр-т Центральний, 16",
        lat: 46.96881499605026,
        lon: 31.97027455780435,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 235,
        apart_ind: 18,
        q_2021: "913,866000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97027455780435,
          46.96881499605026
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 160",
        adrs_full: "м. Миколаїв, пр-т Центральний, 160",
        lat: 46.965850102995425,
        lon: 32.016429281020294,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 69,
        apart_ind: 5,
        q_2021: "335,504000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.016429281020294,
          46.965850102995425
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 161",
        adrs_full: "м. Миколаїв, пр-т Центральний, 161",
        lat: 46.96479797221238,
        lon: 32.0128526472875,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 59,
        apart_ind: 1,
        q_2021: "231,631000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0128526472875,
          46.96479797221238
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 162",
        adrs_full: "м. Миколаїв, пр-т Центральний, 162",
        lat: 46.96596324289138,
        lon: 32.01698191073174,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 2,
        q_2021: "311,000000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01698191073174,
          46.96596324289138
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 166",
        adrs_full: "м. Миколаїв, пр-т Центральний, 166",
        lat: 46.965626168598256,
        lon: 32.01828789988173,
        build_s: "?",
        bld_floor: 12,
        bld_porch: "?",
        bld_apart: 95,
        apart_ind: 0,
        q_2021: "349,046000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01828789988173,
          46.965626168598256
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 171",
        adrs_full: "м. Миколаїв, пр-т Центральний, 171",
        lat: 46.9645393,
        lon: 32.0147847,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 263,
        apart_ind: 13,
        q_2021: "879,088000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0147847,
          46.9645393
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 173",
        adrs_full: "м. Миколаїв, пр-т Центральний, 173",
        lat: 46.96458045507431,
        lon: 32.01636555271334,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 160,
        apart_ind: 8,
        q_2021: "403,691000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01636555271334,
          46.96458045507431
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 177",
        adrs_full: "м. Миколаїв, пр-т Центральний, 177",
        lat: 46.96390691193793,
        lon: 32.01697607961524,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 90,
        apart_ind: 8,
        q_2021: "339,604000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01697607961524,
          46.96390691193794
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 177/А",
        adrs_full: "м. Миколаїв, пр-т Центральний, 177/А",
        lat: 46.9645091,
        lon: 32.0177208,
        build_s: "?",
        bld_floor: 14,
        bld_porch: "?",
        bld_apart: 77,
        apart_ind: 0,
        q_2021: "465,828000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0177208,
          46.9645091
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 179",
        adrs_full: "м. Миколаїв, пр-т Центральний, 179",
        lat: 46.96426017187442,
        lon: 32.01891212570433,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 1,
        q_2021: "302,231000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01891212570433,
          46.96426017187442
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 181",
        adrs_full: "м. Миколаїв, пр-т Центральний, 181",
        lat: 46.96393834085245,
        lon: 32.018333706606356,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 2,
        q_2021: "330,636000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.018333706606356,
          46.96393834085245
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 183",
        adrs_full: "м. Миколаїв, пр-т Центральний, 183",
        lat: 46.963669308746816,
        lon: 32.017670550952616,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 6,
        q_2021: "286,790000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.017670550952616,
          46.963669308746816
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 183/А",
        adrs_full: "м. Миколаїв, пр-т Центральний, 183/А",
        lat: 46.96331319619664,
        lon: 32.01848193881864,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 144,
        apart_ind: 5,
        q_2021: "639,660000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01848193881864,
          46.96331319619664
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 184",
        adrs_full: "м. Миколаїв, пр-т Центральний, 184",
        lat: 46.96554571307873,
        lon: 32.01930842274887,
        build_s: "?",
        bld_floor: 12,
        bld_porch: "?",
        bld_apart: 93,
        apart_ind: 0,
        q_2021: "297,775000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01930842274887,
          46.96554571307873
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 185",
        adrs_full: "м. Миколаїв, пр-т Центральний, 185",
        lat: 46.96403681388331,
        lon: 32.01948314984172,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 30,
        apart_ind: 2,
        q_2021: "105,490000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01948314984172,
          46.96403681388331
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 185/А",
        adrs_full: "м. Миколаїв, пр-т Центральний, 185/А",
        lat: 46.963871225099815,
        lon: 32.01924673553045,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 30,
        apart_ind: 1,
        q_2021: "106,982000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01924673553045,
          46.963871225099815
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 185/Б",
        adrs_full: "м. Миколаїв, пр-т Центральний, 185/Б",
        lat: 46.96369289814439,
        lon: 32.019013431933814,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 30,
        apart_ind: 1,
        q_2021: "102,918000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.019013431933814,
          46.96369289814438
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 186",
        adrs_full: "м. Миколаїв, пр-т Центральний, 186",
        lat: 46.9655054852736,
        lon: 32.02036210339869,
        build_s: "?",
        bld_floor: 12,
        bld_porch: "?",
        bld_apart: 95,
        apart_ind: 0,
        q_2021: "381,519000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02036210339869,
          46.9655054852736
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 187",
        adrs_full: "м. Миколаїв, пр-т Центральний, 187",
        lat: 46.96363345569381,
        lon: 32.01979422130391,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 6,
        q_2021: "242,753000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01979422130391,
          46.96363345569381
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 189",
        adrs_full: "м. Миколаїв, пр-т Центральний, 189",
        lat: 46.963464056492136,
        lon: 32.02071456285287,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 5,
        q_2021: "325,995000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02071456285287,
          46.963464056492136
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 189/А",
        adrs_full: "м. Миколаїв, пр-т Центральний, 189/А",
        lat: 46.963658931037855,
        lon: 32.02118160002528,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 72,
        apart_ind: 3,
        q_2021: "393,468000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02118160002528,
          46.963658931037855
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 191",
        adrs_full: "м. Миколаїв, пр-т Центральний, 191",
        lat: 46.96448475020341,
        lon: 32.02005863204676,
        build_s: "?",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: null,
        q_2021: "563,586900 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02005863204676,
          46.96448475020341
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 193",
        adrs_full: "м. Миколаїв, пр-т Центральний, 193",
        lat: 46.96427882733722,
        lon: 32.02104472858192,
        build_s: "?",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 72,
        apart_ind: null,
        q_2021: "249,947800 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02104472858192,
          46.96427882733722
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 195",
        adrs_full: "м. Миколаїв, пр-т Центральний, 195",
        lat: 46.96415357489688,
        lon: 32.02171975365487,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 67,
        apart_ind: 3,
        q_2021: "218,531000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02171975365487,
          46.96415357489688
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 2",
        adrs_full: "м. Миколаїв, пр-т Центральний, 2",
        lat: 46.9689239,
        lon: 31.9628896,
        build_s: "1 549,70 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 38,
        apart_ind: 4,
        q_2021: "132,730004 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9628896,
          46.9689239
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 21",
        adrs_full: "м. Миколаїв, пр-т Центральний, 21",
        lat: 46.96776,
        lon: 31.9704203,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 128,
        apart_ind: 6,
        q_2021: "636,475000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9704203,
          46.96776
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 22",
        adrs_full: "м. Миколаїв, пр-т Центральний, 22",
        lat: 46.96852880250115,
        lon: 31.971039624244472,
        build_s: "3 197,40 м2",
        bld_floor: 5,
        bld_porch: 5,
        bld_apart: 60,
        apart_ind: 4,
        q_2021: "113,740660 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "5",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.971039624244472,
          46.96852880250114
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 22/А",
        adrs_full: "м. Миколаїв, пр-т Центральний, 22/А",
        lat: 46.9689582,
        lon: 31.9709048,
        build_s: "3 205,80 м2",
        bld_floor: 5,
        bld_porch: 5,
        bld_apart: 63,
        apart_ind: 7,
        q_2021: "249,467902 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "5",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9709048,
          46.9689582
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 22/Б",
        adrs_full: "м. Миколаїв, пр-т Центральний, 22/Б",
        lat: 46.9701905712884,
        lon: 31.97021570483112,
        build_s: "2 937,60 м2",
        bld_floor: 5,
        bld_porch: 5,
        bld_apart: 56,
        apart_ind: 7,
        q_2021: "231,523912 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "5",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97021570483112,
          46.9701905712884
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 22/В",
        adrs_full: "м. Миколаїв, пр-т Центральний, 22/В",
        lat: 46.96832933691067,
        lon: 31.9713976912179,
        build_s: "2 485,00 м2",
        bld_floor: 5,
        bld_porch: 3,
        bld_apart: 60,
        apart_ind: 3,
        q_2021: "203,171051 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9713976912179,
          46.96832933691067
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 23",
        adrs_full: "м. Миколаїв, пр-т Центральний, 23",
        lat: 46.9676737,
        lon: 31.9720517,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 68,
        apart_ind: 4,
        q_2021: "340,407000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9720517,
          46.9676737
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 24",
        adrs_full: "м. Миколаїв, пр-т Центральний, 24",
        lat: 46.9689868,
        lon: 31.9741172,
        build_s: "4 021,70 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 77,
        apart_ind: 6,
        q_2021: "272,001067 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9741172,
          46.9689868
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 24/А",
        adrs_full: "м. Миколаїв, пр-т Центральний, 24/А",
        lat: 46.9691421,
        lon: 31.9725483,
        build_s: "1 842,90 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 2,
        q_2021: "168,476921 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9725483,
          46.9691421
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 26/А",
        adrs_full: "м. Миколаїв, пр-т Центральний, 26/А",
        lat: 46.968528,
        lon: 31.974284,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 38,
        apart_ind: 0,
        q_2021: "172,709000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.974284,
          46.968528
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 261",
        adrs_full: "м. Миколаїв, пр-т Центральний, 261",
        lat: 46.9634676,
        lon: 32.0270065,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 128,
        apart_ind: 6,
        q_2021: "426,432000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0270065,
          46.9634676
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 263",
        adrs_full: "м. Миколаїв, пр-т Центральний, 263",
        lat: 46.9632283,
        lon: 32.027976,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 143,
        apart_ind: 6,
        q_2021: "648,911000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.027976,
          46.9632283
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 265",
        adrs_full: "м. Миколаїв, пр-т Центральний, 265",
        lat: 46.9637458,
        lon: 32.0284799,
        build_s: "?",
        bld_floor: 10,
        bld_porch: "?",
        bld_apart: 205,
        apart_ind: 14,
        q_2021: "812,894000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0284799,
          46.9637458
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 267",
        adrs_full: "м. Миколаїв, пр-т Центральний, 267",
        lat: 46.9627938,
        lon: 32.0295713,
        build_s: "?",
        bld_floor: 10,
        bld_porch: "?",
        bld_apart: 239,
        apart_ind: 16,
        q_2021: "859,007000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0295713,
          46.9627938
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 27",
        adrs_full: "м. Миколаїв, пр-т Центральний, 27",
        lat: 46.9675137,
        lon: 31.9744609,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 76,
        apart_ind: 13,
        q_2021: "298,653000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9744609,
          46.9675137
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "пр-т Центральний, 28",
        adrs_full: "м. Миколаїв, пр-т Центральний, 28",
        lat: 46.9680965,
        lon: 31.9747553,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 33,
        apart_ind: 1,
        q_2021: "130,345000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9747553,
          46.96809649999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 29",
        adrs_full: "м. Миколаїв, пр-т Центральний, 29",
        lat: 46.9674401,
        lon: 31.9752386,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 34,
        apart_ind: 4,
        q_2021: "129,262000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9752386,
          46.9674401
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пр.Центральний,22-А/2",
        address: "пр-т Центральний, 30",
        adrs_full: "м. Миколаїв, пр-т Центральний, 30",
        lat: 46.9692802,
        lon: 31.969532,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 37,
        apart_ind: 2,
        q_2021: "132,858000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.969532,
          46.9692802
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пр.Центральний,22-А/2",
        address: "пр-т Центральний, 32",
        adrs_full: "м. Миколаїв, пр-т Центральний, 32",
        lat: 46.9680012,
        lon: 31.9756139,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 36,
        apart_ind: 3,
        q_2021: "160,826000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9756139,
          46.9680012
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пр.Центральний,22-А/2",
        address: "пр-т Центральний, 4",
        adrs_full: "м. Миколаїв, пр-т Центральний, 4",
        lat: 46.9688787,
        lon: 31.9638476,
        build_s: "2 488,00 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 64,
        apart_ind: 3,
        q_2021: "258,854004 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9638476,
          46.9688787
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пр.Центральний,22-А/2",
        address: "пр-т Центральний, 4/А",
        adrs_full: "м. Миколаїв, пр-т Центральний, 4/А",
        lat: 46.9692273,
        lon: 31.9632692,
        build_s: "1 850,60 м2",
        bld_floor: 9,
        bld_porch: 6,
        bld_apart: 54,
        apart_ind: 3,
        q_2021: "156,379921 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9632692,
          46.9692273
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 5",
        adrs_full: "м. Миколаїв, пр-т Центральний, 5",
        lat: 46.968341,
        lon: 31.9625953,
        build_s: "2 085,40 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 39,
        apart_ind: 4,
        q_2021: "126,399807 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9625953,
          46.968341
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пр.Центральний,22-А/2",
        address: "пр-т Центральний, 6",
        adrs_full: "м. Миколаїв, пр-т Центральний, 6",
        lat: 46.9687685,
        lon: 31.9650142,
        build_s: "1 651,80 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 4,
        q_2021: "128,979923 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9650142,
          46.9687685
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пр.Центральний,24-к",
        address: "пр-т Центральний, 6/А",
        adrs_full: "м. Миколаїв, пр-т Центральний, 6/А",
        lat: 46.9693034,
        lon: 31.964379,
        build_s: "3 237,30 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 3,
        q_2021: "272,526806 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.964379,
          46.9693034
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 6/Б",
        adrs_full: "м. Миколаїв, пр-т Центральний, 6/Б",
        lat: 46.9698256,
        lon: 31.9645974,
        build_s: "3 021,10 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 4,
        q_2021: "222,909792 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9645974,
          46.9698256
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 6/В",
        adrs_full: "м. Миколаїв, пр-т Центральний, 6/В",
        lat: 46.9687685,
        lon: 31.9650142,
        build_s: "3 032,60 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 100,
        apart_ind: 6,
        q_2021: "372,317875 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9650142,
          46.9687685
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 69",
        adrs_full: "м. Миколаїв, пр-т Центральний, 69",
        lat: 46.9667909,
        lon: 31.9854446,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 87,
        apart_ind: 15,
        q_2021: "322,885000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9854446,
          46.9667909
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 7",
        adrs_full: "м. Миколаїв, пр-т Центральний, 7",
        lat: 46.9682676,
        lon: 31.9633731,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 76,
        apart_ind: 9,
        q_2021: "333,501000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9633731,
          46.9682676
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 71",
        adrs_full: "м. Миколаїв, пр-т Центральний, 71",
        lat: 46.9666068,
        lon: 31.987389,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 66,
        apart_ind: 12,
        q_2021: "300,066000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.987389,
          46.9666068
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 71/А",
        adrs_full: "м. Миколаїв, пр-т Центральний, 71/А",
        lat: 46.9664031,
        lon: 31.988584,
        build_s: "?",
        bld_floor: 6,
        bld_porch: "?",
        bld_apart: 111,
        apart_ind: 20,
        q_2021: "344,429000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.988584,
          46.9664031
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 74",
        adrs_full: "м. Миколаїв, пр-т Центральний, 74",
        lat: 46.9674352,
        lon: 31.9855081,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 62,
        apart_ind: 2,
        q_2021: "218,305000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9855081,
          46.9674352
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 74/2",
        adrs_full: "м. Миколаїв, пр-т Центральний, 74/2",
        lat: 46.9680041,
        lon: 31.9869636,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 69,
        apart_ind: 4,
        q_2021: "234,093000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9869636,
          46.96800409999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 74/А",
        adrs_full: "м. Миколаїв, пр-т Центральний, 74/А",
        lat: 46.9673607,
        lon: 31.9865929,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 62,
        apart_ind: 2,
        q_2021: "256,845000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9865929,
          46.9673607
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 76",
        adrs_full: "м. Миколаїв, пр-т Центральний, 76",
        lat: 46.9672784,
        lon: 31.9879399,
        build_s: "?",
        bld_floor: 9,
        bld_porch: "?",
        bld_apart: 62,
        apart_ind: 7,
        q_2021: "232,077000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9879399,
          46.9672784
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "пр-т Центральний, 79",
        adrs_full: "м. Миколаїв, пр-т Центральний, 79",
        lat: 46.96631297479239,
        lon: 31.992044159637405,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 11,
        apart_ind: 2,
        q_2021: "85,490000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.992044159637405,
          46.96631297479239
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "пр-т Центральний, 8",
        adrs_full: "м. Миколаїв, пр-т Центральний, 8",
        lat: 46.9687275,
        lon: 31.9656877,
        build_s: "1 647,40 м2",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: 40,
        apart_ind: 5,
        q_2021: "139,889994 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9656877,
          46.9687275
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пров.Транспортний,6/2",
        address: "пр-т Центральний, 8/А",
        adrs_full: "м. Миколаїв, пр-т Центральний, 8/А",
        lat: 46.96928397961024,
        lon: 31.965324358813334,
        build_s: "3 298,70 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 74,
        apart_ind: 3,
        q_2021: "231,483402 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.965324358813334,
          46.96928397961024
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "пр-т Центральний, 8/Б",
        adrs_full: "м. Миколаїв, пр-т Центральний, 8/Б",
        lat: 46.9700203,
        lon: 31.9655295,
        build_s: "3 115,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 70,
        apart_ind: 3,
        q_2021: "243,738904 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9655295,
          46.97002029999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "пр-т Центральний, 81",
        adrs_full: "м. Миколаїв, пр-т Центральний, 81",
        lat: 46.966284392359995,
        lon: 31.992469974349408,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 15,
        apart_ind: 1,
        q_2021: "106,030000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.992469974349408,
          46.966284392359995
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "пр-т Центральний, 9",
        adrs_full: "м. Миколаїв, пр-т Центральний, 9",
        lat: 46.967687901363,
        lon: 31.9646485482651,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 86,
        apart_ind: 10,
        q_2021: "430,947000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9646485482651,
          46.967687901363
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "пр-т Центральний, 94/А",
        adrs_full: "м. Миколаїв, пр-т Центральний, 94/А",
        lat: 46.9665214,
        lon: 31.9990283,
        build_s: "2 159,20 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 30,
        apart_ind: 9,
        q_2021: "163,503494 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9990283,
          46.9665214
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 96",
        adrs_full: "м. Миколаїв, пр-т Центральний, 96",
        lat: 46.9665039,
        lon: 32.0001666,
        build_s: "2 068,70 м2",
        bld_floor: 6,
        bld_porch: 2,
        bld_apart: 47,
        apart_ind: 7,
        q_2021: "136,578217 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0001666,
          46.9665039
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пр-т Центральний, 97",
        adrs_full: "м. Миколаїв, пр-т Центральний, 97",
        lat: 46.9650335,
        lon: 31.9998113,
        build_s: "?",
        bld_floor: 10,
        bld_porch: 2,
        bld_apart: null,
        apart_ind: null,
        q_2021: "213,951700 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9998113,
          46.9650335
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. 1 Індивідуальний, 3",
        adrs_full: "м. Миколаїв, пров. 1 Індивідуальний, 3",
        lat: 46.93883325483038,
        lon: 32.03473356328597,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 12,
        apart_ind: 1,
        q_2021: "60,260000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03473356328597,
          46.93883325483038
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. 1 Лінії, 1/1",
        adrs_full: "м. Миколаїв, пров. 1 Лінії, 1/1",
        lat: 46.97949844553887,
        lon: 31.991508541057122,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 29,
        apart_ind: 0,
        q_2021: "97,137000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.991508541057122,
          46.97949844553887
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. 1 Лінії,, 1/2",
        adrs_full: "м. Миколаїв, пров. 1 Лінії,, 1/2",
        lat: 46.979326336781824,
        lon: 31.991520846527628,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 20,
        apart_ind: 1,
        q_2021: "88,888000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.991520846527628,
          46.979326336781824
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Армiйський, 17",
        adrs_full: "м. Миколаїв, пров. Армiйський, 17",
        lat: 47.00354528350485,
        lon: 31.993280721565014,
        build_s: "1 200,00 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 0,
        q_2021: "98,270022 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.993280721565018,
          47.00354528350485
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. 1 Індивідуальний, 1",
        adrs_full: "м. Миколаїв, пров. 1 Індивідуальний, 1",
        lat: 46.93838595481009,
        lon: 32.034791021727635,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 2,
        bld_apart: null,
        apart_ind: null,
        q_2021: "147,320000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.034791021727635,
          46.93838595481009
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Київський, 2",
        adrs_full: "м. Миколаїв, пров. Київський, 2",
        lat: 47.0011012,
        lon: 31.9982376,
        build_s: "1 179,00 м2",
        bld_floor: 3,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 3,
        q_2021: "94,397222 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9982376,
          47.0011012
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Корабелів, 16",
        adrs_full: "м. Миколаїв, пров. Корабелів, 16",
        lat: 46.95696274401078,
        lon: 31.98387168885005,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 24,
        apart_ind: 1,
        q_2021: "114,331000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98387168885005,
          46.95696274401079
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "пров. Корабелів, 16/А",
        adrs_full: "м. Миколаїв, пров. Корабелів, 16/А",
        lat: 46.9570901364918,
        lon: 31.984370958546865,
        build_s: "?",
        bld_floor: 2,
        bld_porch: "?",
        bld_apart: 10,
        apart_ind: 0,
        q_2021: "48,564000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.984370958546865,
          46.9570901364918
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "пров. Корабелів, 19",
        adrs_full: "м. Миколаїв, пров. Корабелів, 19",
        lat: 46.95721328226848,
        lon: 31.982386322618083,
        build_s: "?",
        bld_floor: 3,
        bld_porch: "?",
        bld_apart: 21,
        apart_ind: 3,
        q_2021: "77,293000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.982386322618083,
          46.957213282268484
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.Суднобудiвник Спортивна,1К",
        address: "пров. Корабелів, 3",
        adrs_full: "м. Миколаїв, пров. Корабелів, 3",
        lat: 46.9598848,
        lon: 31.9843375,
        build_s: "?",
        bld_floor: 1,
        bld_porch: "?",
        bld_apart: 4,
        apart_ind: 0,
        q_2021: "20,113000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9843375,
          46.9598848
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Корабельний, 16",
        adrs_full: "м. Миколаїв, пров. Корабельний, 16",
        lat: 47.0000903,
        lon: 31.9991122,
        build_s: "527,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 1,
        q_2021: "45,649989 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9991122,
          47.0000903
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Корабельний, 16/А",
        adrs_full: "м. Миколаїв, пров. Корабельний, 16/А",
        lat: 47.0060359,
        lon: 31.9981826,
        build_s: "527,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "50,729959 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9981826,
          47.0060359
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.пр.Центральний,96/7",
        address: "пров. Корабельний, 18",
        adrs_full: "м. Миколаїв, пров. Корабельний, 18",
        lat: 47.0006008,
        lon: 31.9990986,
        build_s: "590,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 1,
        q_2021: "42,820008 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9990986,
          47.0006008
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-2",
        kot_name: "кот.пр.Центральний,96/7",
        address: "пров. Кур'єрський, 4/А",
        adrs_full: "м. Миколаїв, пров. Кур'єрський, 4/А",
        lat: 46.9695589,
        lon: 31.979739,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 15,
        apart_ind: 0,
        q_2021: "78,615000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.979739,
          46.9695589
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Листопадовий, 2/А",
        adrs_full: "м. Миколаїв, пров. Листопадовий, 2/А",
        lat: 46.9645636,
        lon: 32.0659364,
        build_s: "2 317,00 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 54,
        apart_ind: 2,
        q_2021: "125,639979 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0659364,
          46.9645636
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Мiжрiчковий, 2",
        adrs_full: "м. Миколаїв, пров. Мiжрiчковий, 2",
        lat: 46.97934783866413,
        lon: 31.99029547301691,
        build_s: "528,00 м2",
        bld_floor: 2,
        bld_porch: 2,
        bld_apart: 12,
        apart_ind: 0,
        q_2021: "48,940022 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99029547301691,
          46.97934783866414
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Парусний, 1",
        adrs_full: "м. Миколаїв, пров. Парусний, 1",
        lat: 46.9963231,
        lon: 31.9992189,
        build_s: "8 568,40 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 8,
        q_2021: "474,299082 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9992189,
          46.9963231
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Парусний, 11",
        adrs_full: "м. Миколаїв, пров. Парусний, 11",
        lat: 46.99475,
        lon: 32.0044689,
        build_s: "12 665,34 м2",
        bld_floor: 9,
        bld_porch: 6,
        bld_apart: 213,
        apart_ind: 22,
        q_2021: "665,494760 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0044689,
          46.99475
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Парусний, 11/А",
        adrs_full: "м. Миколаїв, пров. Парусний, 11/А",
        lat: 46.9938293,
        lon: 32.0045126,
        build_s: "8 495,60 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 140,
        apart_ind: 10,
        q_2021: "447,831767 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0045126,
          46.99382929999999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Парусний, 5",
        adrs_full: "м. Миколаїв, пров. Парусний, 5",
        lat: 46.9967278,
        lon: 32.0030642,
        build_s: "4 003,00 м2",
        bld_floor: 9,
        bld_porch: 2,
        bld_apart: 68,
        apart_ind: 2,
        q_2021: "210,174462 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0030642,
          46.9967278
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Парусний, 7",
        adrs_full: "м. Миколаїв, пров. Парусний, 7",
        lat: 46.9960335,
        lon: 32.0034644,
        build_s: "12 469,32 м2",
        bld_floor: 9,
        bld_porch: 6,
        bld_apart: 215,
        apart_ind: 13,
        q_2021: "601,059828 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0034644,
          46.9960335
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Парусний, 7/А",
        adrs_full: "м. Миколаїв, пров. Парусний, 7/А",
        lat: 46.9952495,
        lon: 32.0026635,
        build_s: "7 862,23 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 144,
        apart_ind: 8,
        q_2021: "394,211820 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0026635,
          46.9952495
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Парусний, 9/Б",
        adrs_full: "м. Миколаїв, пров. Парусний, 9/Б",
        lat: 46.9946233,
        lon: 32.003657,
        build_s: "6 332,67 м2",
        bld_floor: 9,
        bld_porch: 3,
        bld_apart: 107,
        apart_ind: 9,
        q_2021: "318,107268 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.003657,
          46.9946233
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пров. Першотравневий, 63",
        adrs_full: "м. Миколаїв, пров. Першотравневий, 63",
        lat: 46.965728577615735,
        lon: 32.073137277099214,
        build_s: "9 304,60 м2",
        bld_floor: 9,
        bld_porch: 4,
        bld_apart: 145,
        apart_ind: 16,
        q_2021: "559,200313 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "немає ліфту",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.073137277099214,
          46.965728577615735
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пров. Першотравневий, 79/А",
        adrs_full: "м. Миколаїв, пров. Першотравневий, 79/А",
        lat: 46.965517747192635,
        lon: 32.070726845801516,
        build_s: "2 583,00 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 58,
        apart_ind: 0,
        q_2021: "252,940267 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.070726845801516,
          46.965517747192635
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пров. Південний, 30",
        adrs_full: "м. Миколаїв, пров. Південний, 30",
        lat: 46.9452346,
        lon: 32.0290939,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 64,
        apart_ind: 4,
        q_2021: "179,173000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0290939,
          46.9452346
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пров. Південний, 32",
        adrs_full: "м. Миколаїв, пров. Південний, 32",
        lat: 46.9446456,
        lon: 32.0292254,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: null,
        q_2021: "225,995400 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0292254,
          46.9446456
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пров. Південний, 32-А",
        adrs_full: "м. Миколаїв, пров. Південний, 32-А",
        lat: 46.9447578,
        lon: 32.0299464,
        build_s: "?",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: null,
        q_2021: "203,323800 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: null,
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0299464,
          46.9447578
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пров. Полярний, 2/А",
        adrs_full: "м. Миколаїв, пров. Полярний, 2/А",
        lat: 46.93968925018002,
        lon: 32.03192050341777,
        build_s: "3 837,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 1,
        q_2021: "223,700113 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03192050341777,
          46.93968925018002
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-7",
        kot_name: "кот.Героїв України,21А-1",
        address: "пров. Полярний, 2/Б",
        adrs_full: "м. Миколаїв, пров. Полярний, 2/Б",
        lat: 46.939837255536446,
        lon: 32.03236193187023,
        build_s: "3 837,00 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 60,
        apart_ind: 1,
        q_2021: "223,139971 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03236193187023,
          46.939837255536446
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Полярний, 2/В",
        adrs_full: "м. Миколаїв, пров. Полярний, 2/В",
        lat: 46.9385238,
        lon: 32.032498,
        build_s: "6 024,00 м2",
        bld_floor: 5,
        bld_porch: 6,
        bld_apart: 100,
        apart_ind: 1,
        q_2021: "363,131272 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "6",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.032498,
          46.9385238
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ТЕЦ",
        t_area: "ТЕЦ",
        kot_name: "ТЕЦ, Каботажний спуск, 18",
        address: "пров. Прорізний, 21",
        adrs_full: "м. Миколаїв, пров. Прорізний, 21",
        lat: 46.947750904393686,
        lon: 32.02795180126648,
        build_s: "?",
        bld_floor: 5,
        bld_porch: "?",
        bld_apart: 70,
        apart_ind: 1,
        q_2021: "274,734000 Гкал",
        q_tarif: "1 873,39 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: null,
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02795180126648,
          46.947750904393686
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пр.Центральний,22-А/2",
        address: "пров. Радiо, 1",
        adrs_full: "м. Миколаїв, пров. Радiо, 1",
        lat: 46.97111306130715,
        lon: 31.970486081933522,
        build_s: "3 073,10 м2",
        bld_floor: 5,
        bld_porch: 4,
        bld_apart: 80,
        apart_ind: 1,
        q_2021: "204,710137 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "4",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.970486081933522,
          46.97111306130715
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пр.Центральний,22-А/2",
        address: "пров. Радiо, 1/А",
        adrs_full: "м. Миколаїв, пров. Радiо, 1/А",
        lat: 46.97053058383765,
        lon: 31.970759297759408,
        build_s: "2 440,00 м2",
        bld_floor: 5,
        bld_porch: 1,
        bld_apart: 72,
        apart_ind: 0,
        q_2021: "146,372016 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.970759297759408,
          46.97053058383765
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пр.Центральний,24-к",
        address: "пров. Радiо, 4/6",
        adrs_full: "м. Миколаїв, пров. Радiо, 4/6",
        lat: 46.97088867330755,
        lon: 31.971311828219186,
        build_s: "1 314,70 м2",
        bld_floor: 4,
        bld_porch: 2,
        bld_apart: 24,
        apart_ind: 5,
        q_2021: "84,919910 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "2",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.971311828219182,
          46.97088867330755
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пров.Транспортний,6/2",
        address: "пров. Транспортний, 11",
        adrs_full: "м. Миколаїв, пров. Транспортний, 11",
        lat: 46.9639376,
        lon: 31.96408,
        build_s: "464,50 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 9,
        apart_ind: 1,
        q_2021: "65,514100 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96408,
          46.9639376
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пров.Транспортний,6/2",
        address: "пров. Транспортний, 13",
        adrs_full: "м. Миколаїв, пров. Транспортний, 13",
        lat: 46.9637682,
        lon: 31.9645565,
        build_s: "398,50 м2",
        bld_floor: 2,
        bld_porch: 1,
        bld_apart: 8,
        apart_ind: 1,
        q_2021: "46,792300 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9645565,
          46.9637682
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пров.Транспортний,6/2",
        address: "пров. Транспортний, 2",
        adrs_full: "м. Миколаїв, пров. Транспортний, 2",
        lat: 46.96496979418763,
        lon: 31.96288156418285,
        build_s: "2 411,10 м2",
        bld_floor: 4,
        bld_porch: 3,
        bld_apart: 54,
        apart_ind: 4,
        q_2021: "152,530164 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "3",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96288156418285,
          46.96496979418763
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пров.Транспортний,6/2",
        address: "пров. Транспортний, 7",
        adrs_full: "м. Миколаїв, пров. Транспортний, 7",
        lat: 46.9643484,
        lon: 31.9629634,
        build_s: "485,10 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 9,
        apart_ind: 4,
        q_2021: "41,839300 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9629634,
          46.9643484
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        oda: "Миколаївська ОДА",
        city: "м. Миколаїв",
        tec_okp: "ОКП",
        t_area: "ОКП-3",
        kot_name: "кот.пров.Транспортний,6/2",
        address: "пров. Транспортний, 9",
        adrs_full: "м. Миколаїв, пров. Транспортний, 9",
        lat: 46.96422383690165,
        lon: 31.963527398733504,
        build_s: "58,30 м2",
        bld_floor: 1,
        bld_porch: 1,
        bld_apart: 3,
        apart_ind: 2,
        q_2021: "6,683600 Гкал",
        q_tarif: "1 999,08 грн/Гкал",
        itp: "ні",
        project: "ні",
        elevator: "1",
        adrs_old: null,
        rename_sol: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.963527398733504,
          46.96422383690166
        ]
      }
    }
  ]
}