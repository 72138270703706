export const pipe_damage_data = {
  type: "FeatureCollection",
  name: "pipe-damage-data",
  crs: {
    type: "name",
    properties: {
      name: "urn:ogc:def:crs:OGC:1.3:CRS84"
    }
  },
  features: [
    {
      type: "Feature",
      properties: {
        pkuid: 1,
        name: "pipes_damage-1-(3555782.0,5933725.2)",
        photo: [
          "1-1.jpg"
        ],
        damag_desc: "Витік теплоносія: Це може статися через тріщини або пробоїни в теплових трубах або з'єднаннях. Витік може виникнути через природні зноси, корозію або неякісний монтаж. Внаслідок витоку теплоносія може стати недостатнім для задоволення потреб споживачів, і система потребує негайного ремонту.",
        rest_cost: "12 000,00",
        adrs: "вул. Лазурна, 19-Б"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.942077761465804,
          46.94890882556666,
          0
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        pkuid: 2,
        name: "pipes_damage-2-(3555613.61,5934039.63)",
        photo: [
          "2-1.jpg",
          "2-2.jpg",
          "2-3.jpg"
        ],
        damag_desc: "Пошкодження ізоляції: Ізоляція на теплових трубах є важливою для збереження тепла і запобігання втрат. Однак, можуть виникати ситуації, коли ізоляція може бути пошкоджена внаслідок зносу, механічних пошкоджень або зовнішніх факторів, таких як будівельні роботи або небезпечні погодні умови. Пошкодження ізоляції може призвести до втрати енергії та зниження ефективності системи.",
        rest_cost: "13 500,00",
        adrs: "вул. Лазурна, 11-Б"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.940626739348623,
          46.95077066892822,
          0
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        pkuid: 3,
        name: "pipes_damage-3-(3555459.8,5933306.9)",
        photo: [
          "3-1.jpg",
          "3-2.jpg"
        ],
        damag_desc: "Аварійний знеструмлення: В рідкісних випадках можуть виникати аварійні ситуації, такі як пошкодження обладнання, вибухи або пожежі, які можуть призвести до знеструмлення мережі теплопостачання. Це може стати причиною припинення постачання тепла до будинків і споживачів, що потребують негайного втручання та відновлення системи.",
        rest_cost: "28 950,00",
        adrs: "вул. Лазурна, 28"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.939371849133245,
          46.946300695231336,
          0
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        pkuid: 4,
        name: "pipes_damage-4-(3555433.46,5934085.44)",
        photo: [
          "4-1.jpg"
        ],
        damag_desc: "Замерзання трубопроводів: У холодні періоди року, особливо якщо немає належної ізоляції або недостатньо тепла, трубопроводи можуть замерзати. Замерзання може призвести до пошкодження труб, розширення матеріалу та появи тріщин. Це може перешкоджати нормальному руху теплоносія та викликати проблеми з постачанням тепла до споживачів.",
        rest_cost: "16 870,00",
        adrs: "вул. Лазурна, 48 (стадион 2)"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93905207975748,
          46.95104661474464,
          0
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        pkuid: 5,
        name: "pipes_damage-5-(3555428.50,5933474.40)",
        photo: [
          "5-1.jpg"
        ],
        damag_desc: "Витік теплоносія: Це може статися через тріщини або пробоїни в теплових трубах або з'єднаннях. Витік може виникнути через природні зноси, корозію або неякісний монтаж. Внаслідок витоку теплоносія може стати недостатнім для задоволення потреб споживачів, і система потребує негайного ремонту.",
        rest_cost: "120 520,00",
        adrs: "вул. Лазурна, 30"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.938924370189472,
          46.947327904297886,
          0
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        pkuid: 6,
        name: "pipes_damage-6-(3555423.84,5934086.05)",
        photo: [
          "6-1.jpg"
        ],
        damag_desc: "Пошкодження ізоляції: Ізоляція на теплових трубах є важливою для збереження тепла і запобігання втрат. Однак, можуть виникати ситуації, коли ізоляція може бути пошкоджена внаслідок зносу, механічних пошкоджень або зовнішніх факторів, таких як будівельні роботи або небезпечні погодні умови. Пошкодження ізоляції може призвести до втрати енергії та зниження ефективності системи.",
        rest_cost: "57 000,00",
        adrs: "вул. Лазурна, 48 (стадион 1)"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.938807652779175,
          46.95095594375028,
          0
        ]
      }
    }
  ]
}