export const boltsList = {
  type: "FeatureCollection",
  name: "bolts",
  crs: {
    type: "name",
    properties: {
      name: "urn:ogc:def:crs:OGC:1.3:CRS84"
    }
  },
  features: [
    {
      type: "Feature",
      properties: {
        id: 1
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01176776972,
          46.950710730958
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 2
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.012644155757,
          46.958424596431
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 3
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.012485098976,
          46.958475451872
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 4
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.979751238262,
          46.956207901546
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.985440970738,
          46.957578734263
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 6
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.985380621097,
          46.957575386583
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 7
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.984975842805,
          46.960132698895
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 8
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98524747783,
          46.960207593704
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 9
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.985042995419,
          46.960269189
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 10
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.977427823587,
          46.961714564581
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 11
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.977617075637,
          46.961778194635
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 12
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.991801905601,
          46.967657119219
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 13
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.991706388431,
          46.967622897286
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 14
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.991747139688,
          46.967559949679
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.002990081183,
          46.959404259186
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 16
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.003717421769,
          46.964713542012
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 17
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.003536426431,
          46.964739338012
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 18
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.003788573118,
          46.949512854704
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 19
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03518955523,
          46.947080104447
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.033744220699,
          46.949967409278
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 21
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.021963181775,
          46.957887157824
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 22
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.021995603264,
          46.957864550022
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 23
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.020267601669,
          46.966677739296
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 24
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.020360295256,
          46.966629388796
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 25
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01278106669,
          46.96679107681
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 26
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.012933447466,
          46.966878879492
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 27
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.012709634615,
          46.966822812514
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 28
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.012735504031,
          46.966896401741
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 29
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.003785085406,
          46.965969355132
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 30
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.004278010954,
          46.970599159213
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 31
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.000563378528,
          46.971837984102
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 32
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.998905312342,
          46.972368601583
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 33
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.995639279112,
          46.973294037024
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 34
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.988061689194,
          46.974555651404
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 35
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.988644530969,
          46.975396381326
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 36
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.975164851374,
          46.966275158223
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 37
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98670223321,
          46.963469236112
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 38
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.986807132489,
          46.963443870128
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 39
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.975320820462,
          46.966210293334
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 40
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03987060579,
          46.938403191366
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 41
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.066740051172,
          46.950590137628
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 42
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.066930216814,
          46.950511820913
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 43
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.048713117035,
          46.957919359519
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 44
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.044148024036,
          46.954347632801
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 45
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.038656453042,
          46.953947893158
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 46
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04337470449,
          46.959427834659
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 47
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.033224999,
          46.953362362572
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 48
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.033093106658,
          46.956589871966
        ]
      }
    }
  ]
}