import React from "react";

import {LayersControl, LayerGroup, Marker, Tooltip, useMap} from "react-leaflet";
import {boltIcon} from "../icons/boltIcon";

// @ts-ignore
export const LayerBolts = ({data}) => {
    const leafletMap = useMap();

    const layer =
        // @ts-ignore
        data.features.map((feature, index )=>{
            const {coordinates} = feature.geometry;
            const {id} = feature.properties;

            return (
                <Marker
                    key={index}
                    position={[coordinates[1], coordinates[0]]}
                    icon={boltIcon}
                    eventHandlers={{
                        click: (e) => leafletMap.panTo(e.latlng),
                    }}
                >
                    <Tooltip>
                        {`Задвижка ${id}`}
                    </Tooltip>
                </Marker>
            )
        });
    return (
        <LayersControl.Overlay checked name={`Задвижки`}>
            <LayerGroup>{layer}</LayerGroup>
        </LayersControl.Overlay>
    );
}