import React from "react";
import {LayerGroup, LayersControl, Polyline, Tooltip} from "react-leaflet";
import L from "leaflet";

// @ts-ignore
export const LayerPipeNEFCO = ({data}) => {
    const layer =
        // @ts-ignore
        data.features.map((feature, index) => {
            const {coordinates} = feature.geometry;
            const latLongCoordinate = L.GeoJSON.coordsToLatLngs(coordinates, 1);

            const style = {
                color: '#33a02c',
                weight: 3,
                dashArray: '4, 10',
                dashOffset: '0'
            };

            return (
                <Polyline pathOptions={style} positions={latLongCoordinate} key={index}>
                    <Tooltip>Плануються</Tooltip>
                </Polyline>
            )
        });
    return (
        <LayersControl.Overlay checked name={`NEFCO Мережі (плануються)`}>
            <LayerGroup>{layer}</LayerGroup>
        </LayersControl.Overlay>
    );
}