export const hitGenList = {
    type: "FeatureCollection",
    name: "hitGen_v_16-08-2022",
    crs: {
        type: "name",
        properties: {
            name: "urn:ogc:def:crs:OGC:1.3:CRS84"
        }
    },
    features: [
        {
            type: "Feature",
            properties: {
                id: 150,
                street: "вул. Знам'янська, 2",
                Q: 7.5,
                tip: null,

                district: 1
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.07784823410991,
                                46.91672283183108
                            ],
                            [
                                32.077991350100625,
                                46.916871162180996
                            ],
                            [
                                32.07863379673198,
                                46.916581952332564
                            ],
                            [
                                32.07849068074126,
                                46.91643362118209
                            ],
                            [
                                32.07784823410991,
                                46.91672283183108
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 153,
                street: "вул. 295 Стрілецької Дивізії, 75/1",
                Q: 0.4,
                tip: null,
                district: 1
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.0579384781618,
                                46.91523850314088
                            ],
                            [
                                32.058051935989155,
                                46.915259577894496
                            ],
                            [
                                32.0580898709331,
                                46.91516428640713
                            ],
                            [
                                32.05797641310575,
                                46.91514321161603
                            ],
                            [
                                32.0579384781618,
                                46.91523850314088
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 153,
                street: "вул. 295 Стрілецької дивізії, 75-А",
                Q: 0.3,
                tip: null,
                district: 1
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.05497832882604,
                                46.914102516827434
                            ],
                            [
                                32.05529930092585,
                                46.914202747191545
                            ],
                            [
                                32.055377618229485,
                                46.91408572022634
                            ],
                            [
                                32.05505664612967,
                                46.91398548964334
                            ],
                            [
                                32.05497832882604,
                                46.914102516827434
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 155,
                street: "вул. Вільна, 38-К",
                Q: 1.5,
                tip: null,
                district: 1
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.052304583279096,
                                46.91666196515255
                            ],
                            [
                                32.05222027160769,
                                46.91665109910444
                            ],
                            [
                                32.052202088482915,
                                46.916716926074756
                            ],
                            [
                                32.05207587407911,
                                46.91670125114844
                            ],
                            [
                                32.052046505090246,
                                46.916811585480005
                            ],
                            [
                                32.05235246494907,
                                46.91684958342812
                            ],
                            [
                                32.05238183393792,
                                46.9167392491748
                            ],
                            [
                                32.05228650567898,
                                46.91672741008679
                            ],
                            [
                                32.052304583279096,
                                46.91666196515255
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 1,
                street: "?",
                Q: 1,
                tip: null,

                district: 1
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.03854498994029,
                                46.929735969306435
                            ],
                            [
                                32.03869896156786,
                                46.92976408988802
                            ],
                            [
                                32.03875525212985,
                                46.929620355434686
                            ],
                            [
                                32.03860128050228,
                                46.92959223477764
                            ],
                            [
                                32.03854498994029,
                                46.929735969306435
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 104,
                street: "вул. Космонавтів, 43",
                Q: 1,
                tip: null,

                district: 1
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.03744622277932,
                                46.93437873390729
                            ],
                            [
                                32.037715236472664,
                                46.93445503672931
                            ],
                            [
                                32.037748276585134,
                                46.93440072334984
                            ],
                            [
                                32.03747926289179,
                                46.93432442045043
                            ],
                            [
                                32.03744622277932,
                                46.93437873390729
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 112,
                street: "вул. Миколаївська, 34-Б",
                Q: 37,
                tip: null,

                district: 1
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.0481749805049,
                                46.94358691608987
                            ],
                            [
                                32.04814989111002,
                                46.94363084374593
                            ],
                            [
                                32.04825981833014,
                                46.94366010814168
                            ],
                            [
                                32.04805383342583,
                                46.94401800528015
                            ],
                            [
                                32.048206693316246,
                                46.94405901133942
                            ],
                            [
                                32.04843797410356,
                                46.943657163112555
                            ],
                            [
                                32.048285114213144,
                                46.94361615674547
                            ],
                            [
                                32.048285074918674,
                                46.943616225019305
                            ],
                            [
                                32.0481749805049,
                                46.94358691608987
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 105,
                street: "вул. Театральна, 10-К",
                Q: 1.5,
                tip: null,

                district: 1
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.04558808576391,
                                46.946608765645244
                            ],
                            [
                                32.045899497252144,
                                46.946687683154515
                            ],
                            [
                                32.045959458937745,
                                46.94657741035444
                            ],
                            [
                                32.04564804744951,
                                46.94649849268259
                            ],
                            [
                                32.04558808576391,
                                46.946608765645244
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 113,
                street: "вул. Передова, 69-К",
                Q: 3.4,
                tip: null,

                district: 1
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.026337926189015,
                                46.94187015113008
                            ],
                            [
                                32.02647410297559,
                                46.94190178122627
                            ],
                            [
                                32.02659525005466,
                                46.94165865779021
                            ],
                            [
                                32.026459073268086,
                                46.94162702755038
                            ],
                            [
                                32.026337926189015,
                                46.94187015113008
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 114,
                street: "пров. Полярний, 2-К",
                Q: 4.5,
                tip: null,

                district: 1
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.03163002347657,
                                46.94019744644864
                            ],
                            [
                                32.03198862477055,
                                46.94028793038739
                            ],
                            [
                                32.03203023083811,
                                46.94021106442307
                            ],
                            [
                                32.031671629544135,
                                46.94012058035441
                            ],
                            [
                                32.03163002347657,
                                46.94019744644864
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 439,
                street: "вул. Кузнецька, 130",
                Q: 0.4,
                tip: null,

                district: 1
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.01376712683549,
                                46.95608259101387
                            ],
                            [
                                32.013764483920916,
                                46.95597822020252
                            ],
                            [
                                32.013594466765866,
                                46.95598022595123
                            ],
                            [
                                32.013603013080704,
                                46.956317726750704
                            ],
                            [
                                32.01360237501054,
                                46.956317735417755
                            ],
                            [
                                32.01360722956525,
                                46.95648423909367
                            ],
                            [
                                32.01360722956923,
                                46.95648423909361
                            ],
                            [
                                32.01377724672031,
                                46.95648223336385
                            ],
                            [
                                32.01377723903395,
                                46.95648192982348
                            ],
                            [
                                32.01397370423338,
                                46.95647926120019
                            ],
                            [
                                32.01396884967867,
                                46.95631275750878
                            ],
                            [
                                32.01387832174436,
                                46.956313987170354
                            ],
                            [
                                32.01387243079463,
                                46.956081348710555
                            ],
                            [
                                32.01376712683549,
                                46.95608259101387
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 435,
                street: "вул. Погранична, 150",
                Q: 0.5,
                tip: null,

                district: 1
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.01391729489136,
                                46.95984846071668
                            ],
                            [
                                32.014117225727176,
                                46.95983665211512
                            ],
                            [
                                32.01408941318886,
                                46.95961729995673
                            ],
                            [
                                32.013889482353036,
                                46.959629108606705
                            ],
                            [
                                32.01391729489136,
                                46.95984846071668
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 434,
                street: "вул. Погранична, 242-А",
                Q: 1.9,
                tip: "Вбудована котельня МОТЕ (переобладнання в ЦТП Q=1,55 Гкал)",

                district: 1
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.03123802020002,
                                46.95788476620653
                            ],
                            [
                                32.03125204902268,
                                46.95803181330562
                            ],
                            [
                                32.031501203011786,
                                46.95802073988796
                            ],
                            [
                                32.031487174189124,
                                46.95787369275845
                            ],
                            [
                                32.03123802020002,
                                46.95788476620653
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 436,
                street: "вул. Чкалова, 209",
                Q: 1,
                tip: "Вбудована уотельня МОТЕ (Переобландання в ЦПТ МТЕЦ Q =0,75)",

                district: 1
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.02972455457317,
                                46.96042448008235
                            ],
                            [
                                32.02994262575157,
                                46.96040539875689
                            ],
                            [
                                32.02991944863631,
                                46.960282014590035
                            ],
                            [
                                32.02970137745791,
                                46.9603010959595
                            ],
                            [
                                32.02972455457317,
                                46.96042448008235
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 432,
                street: "вул. Садова, 46",
                Q: 0.9,
                tip: "Вбудована кот. ОКП МОТЕ (переобладнання в ЦТП МТЕЦ Q=0,82 Гкал)",

                district: 1
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.00443369117665,
                                46.95563395127337
                            ],
                            [
                                32.00463791379552,
                                46.9556248106101
                            ],
                            [
                                32.004630588682545,
                                46.95554856264975
                            ],
                            [
                                32.004426366063676,
                                46.955557703326065
                            ],
                            [
                                32.00443369117665,
                                46.95563395127337
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 207,
                street: "вул. Адміральська, 4-К",
                Q: 0.8,
                tip: null,

                district: 2
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.987763932290985,
                                46.978375039860545
                            ],
                            [
                                31.98789578432453,
                                46.978330060211775
                            ],
                            [
                                31.987815191435544,
                                46.97822008708527
                            ],
                            [
                                31.987683339402,
                                46.97826506682654
                            ],
                            [
                                31.987763932290985,
                                46.978375039860545
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 208,
                street: "вул. Нікольська, 34",
                Q: 0.4,
                tip: null,

                district: 2
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.985038209370824,
                                46.977310734273665
                            ],
                            [
                                31.985125574783055,
                                46.9774227978839
                            ],
                            [
                                31.985211644860506,
                                46.977391561464444
                            ],
                            [
                                31.985124279448275,
                                46.97727949778875
                            ],
                            [
                                31.985038209370824,
                                46.977310734273665
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 206,
                street: "вул. Адміральська, 27/1",
                Q: 1.3,
                tip: "Вбудована кот. ОКП МОТЕ (переобладнання в ЦТП МТЕЦ Q=1,023 Гкал)",

                district: 2
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.993492495230434,
                                46.97514986979008
                            ],
                            [
                                31.993651816437634,
                                46.97509863987508
                            ],
                            [
                                31.993609832361074,
                                46.975037853548535
                            ],
                            [
                                31.993450511153874,
                                46.97508908352177
                            ],
                            [
                                31.993492495230434,
                                46.97514986979008
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 230,
                street: "вул. Адміральська, 31",
                Q: 0.6,
                tip: null,

                district: 2
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.994956602186228,
                                46.974162123389725
                            ],
                            [
                                31.99501839575485,
                                46.97425561626012
                            ],
                            [
                                31.99515848854049,
                                46.97421250744332
                            ],
                            [
                                31.995096694971863,
                                46.97411901449755
                            ],
                            [
                                31.994956602186228,
                                46.974162123389725
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 236,
                street: "вул. Спаська, 46",
                Q: 1.1,
                tip: "Вбудована кот. ОКП МОТЕ (переобладнання в ЦТП МТЕЦ Q=0,46 Гкал)",

                district: 2
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.992606379434218,
                                46.97306459912268
                            ],
                            [
                                31.992420006870795,
                                46.973120235901696
                            ],
                            [
                                31.992496920557034,
                                46.97324019323407
                            ],
                            [
                                31.992683293120454,
                                46.973184556579845
                            ],
                            [
                                31.992606379434218,
                                46.97306459912268
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 236,
                street: "вул. Спаська, 55",
                Q: 0.3,
                tip: "Вбудована кот. ОКП МОТЕ (переобладнання в ЦТП МТЕЦ Q=0,26 Гкал)",

                district: 2
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.997177004064845,
                                46.97116423781097
                            ],
                            [
                                31.997089102709147,
                                46.97119172906216
                            ],
                            [
                                31.99716053697606,
                                46.97129808018877
                            ],
                            [
                                31.997248438331752,
                                46.971270588992226
                            ],
                            [
                                31.997177004064845,
                                46.97116423781097
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 236,
                street: "вул. Спаська, 66-К",
                Q: 0.2,
                tip: "Малоефективна котельня МОТЕ (Переобладнана в ЦТП МТЕЦ Q=0,11 Гкал)",

                district: 2
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.99883798063955,
                                46.97141297569049
                            ],
                            [
                                31.998914127396016,
                                46.97152852782889
                            ],
                            [
                                31.999186987854326,
                                46.971444804956164
                            ],
                            [
                                31.99911084109785,
                                46.97132925263689
                            ],
                            [
                                31.99883798063955,
                                46.97141297569049
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 233,
                street: "вул. Садова, 2",
                Q: 0.5,
                tip: "Вбудована кот. ОКП МОТЕ (переобладнання в ЦТП МТЕЦ Q=0,14 Гкал)",

                district: 2
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.00544816826957,
                                46.97018162231074
                            ],
                            [
                                32.005558147072286,
                                46.97020026460875
                            ],
                            [
                                32.00559385699805,
                                46.97010216894287
                            ],
                            [
                                32.00548387819533,
                                46.97008352661066
                            ],
                            [
                                32.00544816826957,
                                46.97018162231074
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 211,
                street: "вул. Велика Морська, 45-А",
                Q: 0.9,
                tip: null,

                district: 2
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.984111523736733,
                                46.97341629429294
                            ],
                            [
                                31.984014239958373,
                                46.97344722883062
                            ],
                            [
                                31.98408840672722,
                                46.97355582236477
                            ],
                            [
                                31.984185690505576,
                                46.9735248878899
                            ],
                            [
                                31.984111523736733,
                                46.97341629429294
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 203,
                street: "вул. Велика Морська, 54/56",
                Q: 0.7,
                tip: null,

                district: 2
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.984925813053014,
                                46.974685138722144
                            ],
                            [
                                31.984957093517668,
                                46.97472930754217
                            ],
                            [
                                31.98518731952931,
                                46.974653399187794
                            ],
                            [
                                31.98515603906465,
                                46.97460923030506
                            ],
                            [
                                31.984925813053014,
                                46.974685138722144
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 225,
                street: "вул. Потьомкінська, 22-В",
                Q: 0.6,
                tip: null,

                district: 2
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.981172854343217,
                                46.974319772956
                            ],
                            [
                                31.981254100363685,
                                46.974295750562916
                            ],
                            [
                                31.981173753030767,
                                46.9741692355534
                            ],
                            [
                                31.981092507010295,
                                46.97419325800331
                            ],
                            [
                                31.981172854343217,
                                46.974319772956
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 236,
                street: "вул. Потьомкінська, 81/83",
                Q: 0.6,
                tip: "Переобладнання в ЦТП МТЕЦ Q=0,51 Гкал",

                district: 2
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.995800417565096,
                                46.96842885379509
                            ],
                            [
                                31.995867529766226,
                                46.968522924149724
                            ],
                            [
                                31.996009009541595,
                                46.96847960229643
                            ],
                            [
                                31.995941897340465,
                                46.96838058078572
                            ],
                            [
                                31.995800417565096,
                                46.96842885379509
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 219,
                street: "пр-т Центральний, 96/7",
                Q: 1.3,
                tip: null,

                district: 2
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.000899131007905,
                                46.96671946761324
                            ],
                            [
                                32.0005944053379,
                                46.9668036385229
                            ],
                            [
                                32.00066417986178,
                                46.96692127710482
                            ],
                            [
                                32.000968905531785,
                                46.96683710638027
                            ],
                            [
                                32.000899131007905,
                                46.96671946761324
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 231,
                street: "вул. Нікольська, 9-А",
                Q: 0.3,
                tip: "Вдубована котельня ОКП МОТЕ. Переобладнання в ЦТП МТЕЦ Q=0,23 Гкал",

                district: 2
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.977323398573322,
                                46.97804548546332
                            ],
                            [
                                31.977479787977202,
                                46.97806584291295
                            ],
                            [
                                31.977515847564966,
                                46.977936890811776
                            ],
                            [
                                31.977359458161086,
                                46.97791653331305
                            ],
                            [
                                31.977323398573322,
                                46.97804548546332
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 228,
                street: "вул. Набережна, 25/1",
                Q: 1.1,
                tip: "Вдубована котельня ОКП МОТЕ. Переобладнання в ЦТП МТЕЦ Q=0,96 Гкал",

                district: 2
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.0029392415566,
                                46.973383221250955
                            ],
                            [
                                32.00281486167982,
                                46.973424823745056
                            ],
                            [
                                32.00290669311972,
                                46.9735526500651
                            ],
                            [
                                32.003031072996485,
                                46.97351104767044
                            ],
                            [
                                32.0029392415566,
                                46.973383221250955
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 314,
                street: "вул. Спортивна, 1-К",
                Q: 37,
                tip: null,

                district: 3
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.96035593924195,
                                46.97350231373363
                            ],
                            [
                                31.961085679656673,
                                46.97337783145541
                            ],
                            [
                                31.961044073589118,
                                46.97326427371354
                            ],
                            [
                                31.9603143331744,
                                46.973388756256085
                            ],
                            [
                                31.96035593924195,
                                46.97350231373363
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 1,
                street: "вул. 8 Березня, 105",
                Q: 0,
                tip: null,

                district: 3
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.973124976434594,
                                46.9626797863909
                            ],
                            [
                                31.97336762080182,
                                46.96273352780127
                            ],
                            [
                                31.97342419747761,
                                46.96261454850869
                            ],
                            [
                                31.973181553110383,
                                46.9625608069788
                            ],
                            [
                                31.973124976434594,
                                46.9626797863909
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 316,
                street: "пров. Транспортний, 6",
                Q: 2.6,
                tip: null,

                district: 3
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.965198756521065,
                                46.96397869916374
                            ],
                            [
                                31.96527023821796,
                                46.964058929419366
                            ],
                            [
                                31.96557772128048,
                                46.9639313356991
                            ],
                            [
                                31.965506239583583,
                                46.96385110525211
                            ],
                            [
                                31.965198756521065,
                                46.96397869916374
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 1,
                street: null,
                Q: 0,
                tip: null,

                district: 3
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.975993729375052,
                                46.97079010036774
                            ],
                            [
                                31.975864710100073,
                                46.970873921891055
                            ],
                            [
                                31.975995068132875,
                                46.970967349820505
                            ],
                            [
                                31.97612408740785,
                                46.97088352844362
                            ],
                            [
                                31.976124057568448,
                                46.970883507057614
                            ],
                            [
                                31.976462679673894,
                                46.97066489501752
                            ],
                            [
                                31.976455054820196,
                                46.97065939561986
                            ],
                            [
                                31.97645573067077,
                                46.97065895497866
                            ],
                            [
                                31.976225680522358,
                                46.97049465714064
                            ],
                            [
                                31.97622690617272,
                                46.97049386624295
                            ],
                            [
                                31.97612931549934,
                                46.97042344555661
                            ],
                            [
                                31.975852013683102,
                                46.970602385376445
                            ],
                            [
                                31.97569904472235,
                                46.97070254979715
                            ],
                            [
                                31.97587591237244,
                                46.970828320889964
                            ],
                            [
                                31.976028881333193,
                                46.9707281567048
                            ],
                            [
                                31.975950358793977,
                                46.97067231899758
                            ],
                            [
                                31.97609952664919,
                                46.97057606274382
                            ],
                            [
                                31.97609951554485,
                                46.97057606998365
                            ],
                            [
                                31.976237627009517,
                                46.97067470665256
                            ],
                            [
                                31.976024607423195,
                                46.97081223077707
                            ],
                            [
                                31.975993729375052,
                                46.97079010036774
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 327,
                street: "вул. Мостобудівельників, 6",
                Q: 0.3,
                tip: "Діюча котельня. Переобладнання в ЦТП Q=0,28",

                district: 3
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.973972344044178,
                                46.98298158908723
                            ],
                            [
                                31.974004239251546,
                                46.9830279155462
                            ],
                            [
                                31.97395382424635,
                                46.9830482710988
                            ],
                            [
                                31.9740124702728,
                                46.983126183659586
                            ],
                            [
                                31.97411638691617,
                                46.983093895585114
                            ],
                            [
                                31.974098895996004,
                                46.983069328558855
                            ],
                            [
                                31.974194581618107,
                                46.983042655774724
                            ],
                            [
                                31.97412358905977,
                                46.982937368338945
                            ],
                            [
                                31.973972344044178,
                                46.98298158908723
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 319,
                street: "вул. Велика Морська, 6",
                Q: 1.6,
                tip: "Ддіюча котельня. Переобладнання в ЦТП Q=1,24",

                district: 3
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.972472240420124,
                                46.98179428439875
                            ],
                            [
                                31.97231996652687,
                                46.98168969643774
                            ],
                            [
                                31.97221105385151,
                                46.98176350142166
                            ],
                            [
                                31.972363327744763,
                                46.98186808923829
                            ],
                            [
                                31.972472240420124,
                                46.98179428439875
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 333,
                street: "вул. Терасна, 16",
                Q: 1.4,
                tip: "Діюча котельня. Переобладнання в ЦТП Q=0,93 Гкал",

                district: 3
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.97087130678571,
                                46.97871833016697
                            ],
                            [
                                31.971004749147617,
                                46.97881904214667
                            ],
                            [
                                31.971133358854754,
                                46.97873971936489
                            ],
                            [
                                31.970999916492843,
                                46.978639007235785
                            ],
                            [
                                31.97087130678571,
                                46.97871833016697
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 315,
                street: "вул. Нікольська, 6",
                Q: 0.3,
                tip: "Діюча котельня. Переобладнання в ЦТП Q=0,370 Гкал.",

                district: 3
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.96985889117113,
                                46.977147293031656
                            ],
                            [
                                31.969990741277886,
                                46.977171475824754
                            ],
                            [
                                31.970016463219313,
                                46.97710619047038
                            ],
                            [
                                31.969884613112555,
                                46.97708200764776
                            ],
                            [
                                31.96985889117113,
                                46.977147293031656
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 323,
                street: "вул. Бугський Бульвар, 9",
                Q: 0.9,
                tip: "Діюча котельня. Переобладнання в ЦТП Q=0,95 Гкал",

                district: 3
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.967208502326468,
                                46.979446972471976
                            ],
                            [
                                31.967044910778988,
                                46.97932763867038
                            ],
                            [
                                31.96691464418287,
                                46.97941076378048
                            ],
                            [
                                31.967078235730348,
                                46.97953009739655
                            ],
                            [
                                31.967208502326468,
                                46.979446972471976
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 309,
                street: "вул. Велика Морська, 21",
                Q: 1.4,
                tip: "Діюча котельня. Переобладнання в ЦТП Q=0,96 Гкал",

                district: 3
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.97460921931391,
                                46.978561088135756
                            ],
                            [
                                31.974745775074705,
                                46.97865744690753
                            ],
                            [
                                31.97490113560093,
                                46.97855495873366
                            ],
                            [
                                31.974764579840134,
                                46.97845859977718
                            ],
                            [
                                31.97460921931391,
                                46.978561088135756
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 320,
                street: "вул. Велика Морська, 13",
                Q: 1.6,
                tip: "Діюча котельня. Переобладнання в ЦТП Q=1,1 Гкал",

                district: 3
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.97211830650609,
                                46.97969406279097
                            ],
                            [
                                31.972268522644026,
                                46.97980497227874
                            ],
                            [
                                31.97237966317958,
                                46.97973490488486
                            ],
                            [
                                31.97222944704165,
                                46.97962399525172
                            ],
                            [
                                31.97211830650609,
                                46.97969406279097
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 318,
                street: "вул. Велика Морська, 3",
                Q: 1.1,
                tip: "Діюча котельня. Переобладнання в ЦТП Q=1,16 Гкал",

                district: 3
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.970616145126744,
                                46.98099126823159
                            ],
                            [
                                31.970475188887725,
                                46.981076905145635
                            ],
                            [
                                31.970560861725684,
                                46.98114254093573
                            ],
                            [
                                31.970701817964702,
                                46.98105690412682
                            ],
                            [
                                31.970616145126744,
                                46.98099126823159
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 328,
                street: "вул. Нікольська, 8",
                Q: 0.9,
                tip: "Діюча котельня. Переобладнання в ЦТП Q=0,63 Гкал",

                district: 3
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.972625543191057,
                                46.97802793593159
                            ],
                            [
                                31.972723286568478,
                                46.97791912870615
                            ],
                            [
                                31.972587904125092,
                                46.97786251556808
                            ],
                            [
                                31.97249016074767,
                                46.97797132290872
                            ],
                            [
                                31.972625543191057,
                                46.97802793593159
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 441,
                street: "вул. Котельна, 8",
                Q: 1.3,
                tip: "_",

                district: 4
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.018828261109185,
                                46.97210580323479
                            ],
                            [
                                32.01889750867502,
                                46.97216462634495
                            ],
                            [
                                32.01901369900338,
                                46.972100940053465
                            ],
                            [
                                32.01894445143754,
                                46.97204211687325
                            ],
                            [
                                32.018828261109185,
                                46.97210580323479
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 430,
                street: "вул. Адміралтейська, 2",
                Q: 0.5,
                tip: "_",

                district: 4
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.014881937576114,
                                46.972570619284355
                            ],
                            [
                                32.01497370566577,
                                46.97259831561605
                            ],
                            [
                                32.01506132460191,
                                46.97246314571744
                            ],
                            [
                                32.01496955651226,
                                46.972435449315746
                            ],
                            [
                                32.014881937576114,
                                46.972570619284355
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 425,
                street: "вул. 3-я Воєнна, 8",
                Q: 0.1,
                tip: "_",

                district: 4
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.02260447852058,
                                46.97329540369043
                            ],
                            [
                                32.02264651986137,
                                46.97332694200379
                            ],
                            [
                                32.022736043556996,
                                46.97327138017554
                            ],
                            [
                                32.0226940022162,
                                46.97323984182942
                            ],
                            [
                                32.02260447852058,
                                46.97329540369043
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 416,
                street: "вул. Безменна, 91-В",
                Q: 3.2,
                tip: "_",

                district: 4
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.02399107325975,
                                46.99095235029705
                            ],
                            [
                                32.024177686026505,
                                46.990990724821444
                            ],
                            [
                                32.02430947157312,
                                46.99069254338997
                            ],
                            [
                                32.024122858806365,
                                46.99065416865148
                            ],
                            [
                                32.02399107325975,
                                46.99095235029705
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 417,
                street: "вул. Котельна, 177-К",
                Q: 0.1,
                tip: "_",

                district: 4
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.03147285520843,
                                46.97984519123889
                            ],
                            [
                                32.03162488231382,
                                46.97997736694451
                            ],
                            [
                                32.031693453655144,
                                46.979940655221256
                            ],
                            [
                                32.031541426549765,
                                46.979808479424875
                            ],
                            [
                                32.03147285520843,
                                46.97984519123889
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 414,
                street: "вул. 2 Екіпажна, 4-І",
                Q: 10,
                tip: "_",

                district: 4
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.01863388519292,
                                46.97705710758592
                            ],
                            [
                                32.01848974297882,
                                46.97693032264205
                            ],
                            [
                                32.01855502147615,
                                46.97689664676848
                            ],
                            [
                                32.01847864729519,
                                46.976827727595584
                            ],
                            [
                                32.01841212064598,
                                46.976862047410016
                            ],
                            [
                                32.01833605856668,
                                46.97679514454942
                            ],
                            [
                                32.01823415393443,
                                46.97684907820957
                            ],
                            [
                                32.01853198056067,
                                46.977111040981846
                            ],
                            [
                                32.01863388519292,
                                46.97705710758592
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 424,
                street: "вул. 2 Екіпажна, 5",
                Q: 1,
                tip: "_",

                district: 4
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.01913448764347,
                                46.97304868065928
                            ],
                            [
                                32.01916840901166,
                                46.9730754214232
                            ],
                            [
                                32.01924584318213,
                                46.97302735913036
                            ],
                            [
                                32.01921192180952,
                                46.97300061833154
                            ],
                            [
                                32.01913448764347,
                                46.97304868065928
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 503,
                street: "вул. Космонавтів, 93",
                Q: 1.3,
                tip: "Законсервована",

                district: 5
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.084376460621186,
                                46.978116916596896
                            ],
                            [
                                32.084525304014704,
                                46.978109751848145
                            ],
                            [
                                32.084509365762756,
                                46.977955620040014
                            ],
                            [
                                32.08436052236924,
                                46.97796278480941
                            ],
                            [
                                32.084376460621186,
                                46.978116916596896
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 514,
                street: "вул. Новозаводська, 48",
                Q: 42,
                tip: "Кот. 135 МКР",

                district: 5
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.085540224852984,
                                46.96905964795175
                            ],
                            [
                                32.08570314373547,
                                46.969250063918544
                            ],
                            [
                                32.08611272786001,
                                46.96908687942074
                            ],
                            [
                                32.08594980897751,
                                46.968896462873005
                            ],
                            [
                                32.085540224852984,
                                46.96905964795175
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 515,
                street: "вул. Листопадова, 1-А",
                Q: 3.3,
                tip: "_",

                district: 5
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.066771726224495,
                                46.96474713364341
                            ],
                            [
                                32.066863882652484,
                                46.9646186150747
                            ],
                            [
                                32.06644351227556,
                                46.964478225971746
                            ],
                            [
                                32.06635135584757,
                                46.96460674487773
                            ],
                            [
                                32.066771726224495,
                                46.96474713364341
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 524,
                street: "вул. О. Янати, 70",
                Q: 0.4,
                tip: "_",

                district: 5
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.063190485612004,
                                46.97256338838626
                            ],
                            [
                                32.063336884101176,
                                46.97270856861601
                            ],
                            [
                                32.06340967432329,
                                46.97267439326299
                            ],
                            [
                                32.06326327583412,
                                46.97252921294047
                            ],
                            [
                                32.063190485612004,
                                46.97256338838626
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 508,
                street: "вул. Казарського, 2",
                Q: 4.4,
                tip: "_",

                district: 5
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.050122881469754,
                                46.984339213011914
                            ],
                            [
                                32.05012796073722,
                                46.98465453254165
                            ],
                            [
                                32.05037104001106,
                                46.98465271026774
                            ],
                            [
                                32.05036596074359,
                                46.984337390727255
                            ],
                            [
                                32.050122881469754,
                                46.984339213011914
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 1,
                street: "вул. Херсонське шосе, ?",
                Q: 3.8,
                tip: "Котельня 127 МКР (4,5 МВт)",

                district: 5
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.05343576694117,
                                46.95738915686759
                            ],
                            [
                                32.053409687544516,
                                46.9573621004373
                            ],
                            [
                                32.05305041048944,
                                46.95752343034677
                            ],
                            [
                                32.05311719050973,
                                46.95759271199233
                            ],
                            [
                                32.0534739131436,
                                46.957432529328585
                            ],
                            [
                                32.05353354518265,
                                46.957500331296885
                            ],
                            [
                                32.05363301168557,
                                46.957459577143005
                            ],
                            [
                                32.053535233436705,
                                46.957348402632064
                            ],
                            [
                                32.05343576694117,
                                46.95738915686759
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 551,
                street: "вул. Д. Яворницького, 2-В",
                Q: 0.6,
                tip: "_",

                district: 5
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.059255301028834,
                                46.93343940022932
                            ],
                            [
                                32.059398252195024,
                                46.93335893469915
                            ],
                            [
                                32.059235308945524,
                                46.933223956636475
                            ],
                            [
                                32.05909235777934,
                                46.93330442236946
                            ],
                            [
                                32.059255301028834,
                                46.93343940022932
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 546,
                street: "вул. Китобоїв, 7-А",
                Q: 37,
                tip: "Кот. 114 МКР",

                district: 5
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.06173384148173,
                                46.94186321507631
                            ],
                            [
                                32.06205621525584,
                                46.94227356477861
                            ],
                            [
                                32.06220313130047,
                                46.94221976495481
                            ],
                            [
                                32.06188075752636,
                                46.94180941484015
                            ],
                            [
                                32.06173384148173,
                                46.94186321507631
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 506,
                street: "вул. 8 Повздовжня, 11-В",
                Q: 3.5,
                tip: "_",

                district: 5
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.04589770959248,
                                46.954395272337365
                            ],
                            [
                                32.045818447906306,
                                46.954426901460174
                            ],
                            [
                                32.04595545039006,
                                46.954586861323286
                            ],
                            [
                                32.04603471207624,
                                46.954555232295014
                            ],
                            [
                                32.04589770959248,
                                46.954395272337365
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 504,
                street: "вул. Будівельників, 8",
                Q: 0,
                tip: "Законсервована",

                district: 5
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.04238336836852,
                                46.954981240419734
                            ],
                            [
                                32.04229557080845,
                                46.95487719825995
                            ],
                            [
                                32.04214860418196,
                                46.95493498002341
                            ],
                            [
                                32.04223640174204,
                                46.95503902207085
                            ],
                            [
                                32.04238336836852,
                                46.954981240419734
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 505,
                street: "вул. 11 Повздовжня, 1",
                Q: 0.2,
                tip: "_",

                district: 5
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.04021647580955,
                                46.95420216781365
                            ],
                            [
                                32.04003844186829,
                                46.954277079134016
                            ],
                            [
                                32.04011465245789,
                                46.95436146700173
                            ],
                            [
                                32.04029268639915,
                                46.95428655579949
                            ],
                            [
                                32.04021647580955,
                                46.95420216781365
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 601,
                street: "Заводська площа, 1",
                Q: 100,
                tip: "_",

                district: 6
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.9940988685188,
                                46.86043731276679
                            ],
                            [
                                31.994368859726386,
                                46.86088038451571
                            ],
                            [
                                31.99452913177507,
                                46.86083472217861
                            ],
                            [
                                31.994528010395307,
                                46.86083288192566
                            ],
                            [
                                31.99467667605531,
                                46.860790399841264
                            ],
                            [
                                31.994216401354507,
                                46.860037306072925
                            ],
                            [
                                31.99403743090014,
                                46.86008844864743
                            ],
                            [
                                31.994228139457537,
                                46.86040048250261
                            ],
                            [
                                31.9940988685188,
                                46.86043731276679
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 616,
                street: "пров. Богоявленський, 325/4",
                Q: 1.1,
                tip: "_",

                district: 6
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.00801178927717,
                                46.85140657357338
                            ],
                            [
                                32.00832000411852,
                                46.85137222326633
                            ],
                            [
                                32.00829462179788,
                                46.85126570326897
                            ],
                            [
                                32.00798640695653,
                                46.85130005364415
                            ],
                            [
                                32.00801178927717,
                                46.85140657357338
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 1,
                street: "?",
                Q: 0,
                tip: "_",

                district: 6
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.018810452421185,
                                46.857781406641834
                            ],
                            [
                                32.01917705622354,
                                46.85773126903112
                            ],
                            [
                                32.01915628887029,
                                46.857660264131496
                            ],
                            [
                                32.018789685067944,
                                46.85771040180852
                            ],
                            [
                                32.018810452421185,
                                46.857781406641834
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 1,
                street: "вул. Кобзарська, 15",
                Q: 0,
                tip: "Котельня УОММР",

                district: 6
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.01284459754782,
                                46.84593176740172
                            ],
                            [
                                32.012696918591395,
                                46.8459633320388
                            ],
                            [
                                32.012750139078015,
                                46.84607981445884
                            ],
                            [
                                32.01289781803443,
                                46.8460482498902
                            ],
                            [
                                32.01284459754782,
                                46.84593176740172
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 614,
                street: "вул. Дкнайської флотилії, 5",
                Q: 1.1,
                tip: "_",

                district: 6
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.02344777472297,
                                46.850036375664516
                            ],
                            [
                                32.0235559727498,
                                46.85005370424597
                            ],
                            [
                                32.02364134964648,
                                46.84980436209586
                            ],
                            [
                                32.02353315161965,
                                46.849787033433955
                            ],
                            [
                                32.02344777472297,
                                46.850036375664516
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 625,
                street: "вул. Самойловича, 25-А",
                Q: 90,
                tip: "_",

                district: 6
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.031987428694826,
                                46.859439455527394
                            ],
                            [
                                32.03229964739853,
                                46.85948366348629
                            ],
                            [
                                32.03248886106144,
                                46.85885883753963
                            ],
                            [
                                32.03217664235774,
                                46.85881462906629
                            ],
                            [
                                32.031987428694826,
                                46.859439455527394
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 614,
                street: "вул. Металугрів, 615",
                Q: 0.5,
                tip: "_",

                district: 6
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.0177405132636,
                                46.86710030032326
                            ],
                            [
                                32.01807796820729,
                                46.86710190251461
                            ],
                            [
                                32.01807912194913,
                                46.86698831445776
                            ],
                            [
                                32.017741667005446,
                                46.866986712263014
                            ],
                            [
                                32.0177405132636,
                                46.86710030032326
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 623,
                street: "вул. Г. Сагайдачного, 124",
                Q: 0.3,
                tip: "(вугільна котельня)",

                district: 6
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.02378870272611,
                                46.86856150791176
                            ],
                            [
                                32.02376332040547,
                                46.8684234705926
                            ],
                            [
                                32.02366001511794,
                                46.86843234945915
                            ],
                            [
                                32.02368539743858,
                                46.86857038675545
                            ],
                            [
                                32.02378870272611,
                                46.86856150791176
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 1,
                street: "вул. Янтарна, 67-А",
                Q: 2.5,
                tip: "Будівниутво нової кательної (1,5 Мвт палетний + 1,5 Мвт гозовий)",

                district: 6
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.0213115622671,
                                46.87333258495606
                            ],
                            [
                                32.02161956862459,
                                46.873290473476125
                            ],
                            [
                                32.02158726385287,
                                46.873180053378775
                            ],
                            [
                                32.02127925749539,
                                46.873222164945346
                            ],
                            [
                                32.0213115622671,
                                46.87333258495606
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 3,
                street: "вул. Христо Ботева, 46",
                Q: 0.8,
                tip: "_",

                district: 7
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.00574903589208,
                                47.03379574101126
                            ],
                            [
                                32.00572339799666,
                                47.03370337867844
                            ],
                            [
                                32.00557156787456,
                                47.03372295645607
                            ],
                            [
                                32.005597205769966,
                                47.033815318755
                            ],
                            [
                                32.00574903589208,
                                47.03379574101126
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 1,
                street: "?",
                Q: 1.4,
                tip: "_",

                district: 7
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.013750889163724,
                                47.029645322706614
                            ],
                            [
                                32.01402212803589,
                                47.02963847523203
                            ],
                            [
                                32.01400747780994,
                                47.02936885562244
                            ],
                            [
                                32.01373623893777,
                                47.02937570313163
                            ],
                            [
                                32.013750889163724,
                                47.029645322706614
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 709,
                street: "вул. Малко-Тирнівська, 1",
                Q: 6,
                tip: "_",

                district: 7
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.999230683969895,
                                47.020698468271036
                            ],
                            [
                                31.999824562486264,
                                47.02090577940781
                            ],
                            [
                                31.999902391811617,
                                47.02080215878769
                            ],
                            [
                                31.999308513295254,
                                47.02059484724856
                            ],
                            [
                                31.999230683969895,
                                47.020698468271036
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 723,
                street: "вуд. Архитектора Старова, 4-Ж",
                Q: 0.9,
                tip: "_",

                district: 7
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.000724249105446,
                                47.013847534664066
                            ],
                            [
                                32.000667289252036,
                                47.013970897386294
                            ],
                            [
                                32.000766178277196,
                                47.013992123618564
                            ],
                            [
                                32.000823138130606,
                                47.01386876094538
                            ],
                            [
                                32.000724249105446,
                                47.013847534664066
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 722,
                street: "вул. Архитектора Старова, 4-Е",
                Q: 0.7,
                tip: "_",

                district: 7
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.00085142782948,
                                47.01402970617121
                            ],
                            [
                                32.00081297098637,
                                47.0141015006673
                            ],
                            [
                                32.00090820258741,
                                47.01412521446544
                            ],
                            [
                                32.000946659430525,
                                47.01405342000123
                            ],
                            [
                                32.00085142782948,
                                47.01402970617121
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 713,
                street: "вул. Героїв України, 91",
                Q: 2.2,
                tip: "_",

                district: 7
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.998007390103144,
                                47.0144234157772
                            ],
                            [
                                31.998117266797752,
                                47.0144234157772
                            ],
                            [
                                31.998117266797752,
                                47.014263595817575
                            ],
                            [
                                31.998007390103144,
                                47.014263595817575
                            ],
                            [
                                31.998007390103144,
                                47.0144234157772
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 724,
                street: "вул.  Героїв України, 101",
                Q: 1.1,
                tip: "_",

                district: 7
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.99702590653047,
                                47.01379546705742
                            ],
                            [
                                31.996891765399127,
                                47.013796403512096
                            ],
                            [
                                31.996893345202047,
                                47.01390160454646
                            ],
                            [
                                31.9970274863334,
                                47.01390066809363
                            ],
                            [
                                31.99702590653047,
                                47.01379546705742
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 706,
                street: "вул. Героїв України, 72",
                Q: 16,
                tip: "_",

                district: 7
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.99269518567421,
                                47.011458405635125
                            ],
                            [
                                31.993035645642077,
                                47.011488685192504
                            ],
                            [
                                31.993101571658844,
                                47.011144053870574
                            ],
                            [
                                31.99276111169099,
                                47.0111137741178
                            ],
                            [
                                31.99269518567421,
                                47.011458405635125
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 703,
                street: "вул. Героїв України, 12",
                Q: 1,
                tip: "_",

                district: 7
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.996768327414937,
                                46.999210291352036
                            ],
                            [
                                31.996934948802725,
                                46.99921566003804
                            ],
                            [
                                31.996945936472184,
                                46.99905704352177
                            ],
                            [
                                31.996779315084403,
                                46.99905167481983
                            ],
                            [
                                31.996768327414937,
                                46.999210291352036
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 701,
                street: "вул. Героїв України, 21-А",
                Q: 22,
                tip: "_",

                district: 7
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.000444160669495,
                                46.994667833320946
                            ],
                            [
                                32.00047162984315,
                                46.99428437255286
                            ],
                            [
                                32.000307367223236,
                                46.99427889834308
                            ],
                            [
                                32.00027989804958,
                                46.99466235915045
                            ],
                            [
                                32.000444160669495,
                                46.994667833320946
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 847,
                street: "вул. Озерна, 45-А",
                Q: 10,
                tip: "_",

                district: 8
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.945803733864118,
                                46.945173319710484
                            ],
                            [
                                31.94641256977388,
                                46.945408997586725
                            ],
                            [
                                31.94647987370669,
                                46.945327961873105
                            ],
                            [
                                31.94587103779693,
                                46.94509228364011
                            ],
                            [
                                31.945803733864118,
                                46.945173319710484
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 819,
                street: "вул. Біла, 71",
                Q: 30,
                tip: "_",

                district: 8
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.943333164779915,
                                46.95648546403647
                            ],
                            [
                                31.94377081918021,
                                46.95662389627673
                            ],
                            [
                                31.943910321877336,
                                46.95641842584395
                            ],
                            [
                                31.943472667477035,
                                46.95627999307214
                            ],
                            [
                                31.943333164779915,
                                46.95648546403647
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 1,
                street: "?",
                Q: 0,
                tip: "_",

                district: 8
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.94469860625379,
                                46.951569705037926
                            ],
                            [
                                31.944788758086638,
                                46.95159813765768
                            ],
                            [
                                31.94487808876111,
                                46.95146615653961
                            ],
                            [
                                31.944787936928265,
                                46.95143772384974
                            ],
                            [
                                31.94469860625379,
                                46.951569705037926
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 811,
                street: "вул. Генерала Карпенка, 1",
                Q: 0.2,
                tip: "_",

                district: 8
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.95338999139692,
                                46.95229016622945
                            ],
                            [
                                31.95357744316809,
                                46.95241028797227
                            ],
                            [
                                31.953633733730083,
                                46.95236935777513
                            ],
                            [
                                31.953446281958918,
                                46.95224923594045
                            ],
                            [
                                31.95338999139692,
                                46.95229016622945
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 1,
                street: "?",
                Q: 0,
                tip: "_",

                district: 8
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.95368101465054,
                                46.953316410661884
                            ],
                            [
                                31.953737305212535,
                                46.95323538692433
                            ],
                            [
                                31.953562646440094,
                                46.95317884900199
                            ],
                            [
                                31.95351042843515,
                                46.95325401084528
                            ],
                            [
                                31.953485552844313,
                                46.953246508078706
                            ],
                            [
                                31.953415801495755,
                                46.95335426125413
                            ],
                            [
                                31.95355208629623,
                                46.95339536624709
                            ],
                            [
                                31.953616677607627,
                                46.95329558446932
                            ],
                            [
                                31.95368101465054,
                                46.953316410661884
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 820,
                street: "вул. Курортна, 7-Б",
                Q: 29,
                tip: "_",

                district: 8
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.955925626336114,
                                46.95565461449784
                            ],
                            [
                                31.956936176753263,
                                46.956037119476136
                            ],
                            [
                                31.957010822933302,
                                46.95594524154337
                            ],
                            [
                                31.956000272516153,
                                46.95556273590834
                            ],
                            [
                                31.955925626336114,
                                46.95565461449784
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 1,
                street: "?",
                Q: 0,
                tip: "_",

                district: 8
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.95597262294756,
                                46.9574606020187
                            ],
                            [
                                31.956078784668893,
                                46.95749470071147
                            ],
                            [
                                31.956117943320717,
                                46.95743790500966
                            ],
                            [
                                31.956011781599386,
                                46.9574038062807
                            ],
                            [
                                31.95597262294756,
                                46.9574606020187
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 813,
                street: "вул. Дачна, 7",
                Q: 4.8,
                tip: "_",

                district: 8
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.952021882090854,
                                46.95609498191129
                            ],
                            [
                                31.951979052315423,
                                46.956155955295465
                            ],
                            [
                                31.952076399525453,
                                46.9561878125219
                            ],
                            [
                                31.952054077896843,
                                46.956219868676634
                            ],
                            [
                                31.952171174450935,
                                46.95625785586464
                            ],
                            [
                                31.95232413793462,
                                46.9560381847238
                            ],
                            [
                                31.952207041380532,
                                46.95600019737986
                            ],
                            [
                                31.952118926120487,
                                46.956126739957035
                            ],
                            [
                                31.952021882090854,
                                46.95609498191129
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 812,
                street: "вул. Абрикосова, 5",
                Q: 1.1,
                tip: "_",

                district: 8
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.957106417496263,
                                46.95982783229953
                            ],
                            [
                                31.957244696485482,
                                46.95974264243899
                            ],
                            [
                                31.95712859334096,
                                46.959654854886985
                            ],
                            [
                                31.956990314351746,
                                46.9597400448873
                            ],
                            [
                                31.957106417496263,
                                46.95982783229953
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 816,
                street: "вул. Генерала Карпенка, 8",
                Q: 0.9,
                tip: "_",

                district: 8
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.948944286008754,
                                46.960116808659166
                            ],
                            [
                                31.949110571651094,
                                46.96009127314155
                            ],
                            [
                                31.949044491426157,
                                46.959890827181916
                            ],
                            [
                                31.94887820578382,
                                46.95991636279519
                            ],
                            [
                                31.948944286008754,
                                46.960116808659166
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 820,
                street: "вул. Генерала Карпенка, 51-А",
                Q: 17,
                tip: "_",

                district: 8
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.952555378616783,
                                46.9632205650241
                            ],
                            [
                                31.95273269903053,
                                46.963336079650425
                            ],
                            [
                                31.95304107341357,
                                46.96311560242577
                            ],
                            [
                                31.95286375299982,
                                46.96300008732339
                            ],
                            [
                                31.952555378616783,
                                46.9632205650241
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 815,
                street: "вул. Генерала Карпенка, 20",
                Q: 5,
                tip: "_",

                district: 8
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.952216199959906,
                                46.96552725541345
                            ],
                            [
                                31.952427644113346,
                                46.965786455996195
                            ],
                            [
                                31.95254634377666,
                                46.96574136052165
                            ],
                            [
                                31.952334899623214,
                                46.96548215972038
                            ],
                            [
                                31.952216199959906,
                                46.96552725541345
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 1,
                street: "вул. Леваневців, 8",
                Q: 0.5,
                tip: "Дахова котельня",

                district: 8
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.949196572138714,
                                46.96449563246468
                            ],
                            [
                                31.949277481901007,
                                46.96454600582864
                            ],
                            [
                                31.9493411147102,
                                46.96449840394502
                            ],
                            [
                                31.94926020494791,
                                46.96444803053625
                            ],
                            [
                                31.949196572138714,
                                46.96449563246468
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 824,
                street: "вул. Леваневців, 10",
                Q: 0.7,
                tip: "Дахова котельня",

                district: 8
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.94933517598826,
                                46.963120986559275
                            ],
                            [
                                31.949392681783795,
                                46.96319029314011
                            ],
                            [
                                31.949487605969942,
                                46.96315360893279
                            ],
                            [
                                31.949430100174414,
                                46.963084302304445
                            ],
                            [
                                31.94933517598826,
                                46.963120986559275
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 228,
                street: "вул. Набережна, 5/11",
                Q: 0.2,
                tip: "Вбудована котельня ОКП МОТЕ (Переобладнання в ЦТП МТЕЦ Q=0,178)",

                district: 9
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.99172724264204,
                                46.97700354332312
                            ],
                            [
                                31.991798881470455,
                                46.97711090154331
                            ],
                            [
                                31.99198893381563,
                                46.97705186435085
                            ],
                            [
                                31.991917294987214,
                                46.976944506012124
                            ],
                            [
                                31.99172724264204,
                                46.97700354332312
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 1,
                street: "?",
                Q: 0,
                tip: null,

                district: 9
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.992870260968857,
                                46.96958099623134
                            ],
                            [
                                31.99279832076417,
                                46.96947404781352
                            ],
                            [
                                31.992671403793594,
                                46.96951380165097
                            ],
                            [
                                31.99274334399828,
                                46.9696207499893
                            ],
                            [
                                31.992870260968857,
                                46.96958099623134
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 1,
                street: "?",
                Q: 0,
                tip: null,

                district: 9
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.978786170015574,
                                46.9739970529778
                            ],
                            [
                                31.978927481131922,
                                46.97395147219623
                            ],
                            [
                                31.978858155140284,
                                46.9738514075175
                            ],
                            [
                                31.978716844023943,
                                46.97389698838437
                            ],
                            [
                                31.978786170015574,
                                46.9739970529778
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 1,
                street: "Каботажний узвіз, 18",
                Q: 1.1,
                tip: "Миколаївська ТЕЦ",

                district: 9
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.988467531306146,
                                46.94996185166261
                            ],
                            [
                                31.989032705516887,
                                46.95033181945186
                            ],
                            [
                                31.989655561867284,
                                46.94988843238079
                            ],
                            [
                                31.989090387656542,
                                46.94951846152668
                            ],
                            [
                                31.988467531306146,
                                46.94996185166261
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 306,
                street: "пров. Корабелів, 14",
                Q: 1,
                tip: "_",

                district: 9
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                31.98673005528735,
                                46.95973344201959
                            ],
                            [
                                31.986710826865792,
                                46.95959283149386
                            ],
                            [
                                31.986458966332474,
                                46.95960887533579
                            ],
                            [
                                31.98647819475403,
                                46.959749485819366
                            ],
                            [
                                31.98673005528735,
                                46.95973344201959
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 442,
                street: "вул. 6 Слобідська, 48",
                Q: 0.7,
                tip: "_",

                district: 9
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.023377936128426,
                                46.96169636793734
                            ],
                            [
                                32.02353695552885,
                                46.96168952378496
                            ],
                            [
                                32.02352478754333,
                                46.961557838065026
                            ],
                            [
                                32.02336576814292,
                                46.96156468223425
                            ],
                            [
                                32.023377936128426,
                                46.96169636793734
                            ]
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                id: 201,
                street: "вул. Слобідська, 13",
                Q: 0.3,
                tip: "Вбудована котельня МОТЕ (Переключення на ЦТП 71, Q=0,08 Гкал)",

                district: 9
            },
            geometry: {
                type: "MultiPolygon",
                coordinates: [
                    [
                        [
                            [
                                32.00677262594654,
                                46.96698440845729
                            ],
                            [
                                32.00693726560472,
                                46.96701665801798
                            ],
                            [
                                32.00698516497627,
                                46.96690277902521
                            ],
                            [
                                32.00682052531809,
                                46.966870529395855
                            ],
                            [
                                32.00677262594654,
                                46.96698440845729
                            ]
                        ]
                    ]
                ]
            }
        }
    ]
}