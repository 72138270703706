import L from "leaflet";

import tk_okp_img from "../images/icon-luk-okp_12x12.png";

const LeafIcon = L.Icon.extend({
    options: {
        iconSize: [12, 12],
        iconAnchor: [6, 6],
        tooltipAnchor: [6, 0]
    },
});

// @ts-ignore
export const tk_okp_Icon = new LeafIcon({iconUrl: tk_okp_img});
