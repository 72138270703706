export const pipeMain = {
  type: "FeatureCollection",
  name: "pipe-main",
  crs: {
    type: "name",
    properties: {
      name: "urn:ogc:def:crs:OGC:1.3:CRS84"
    }
  },
  features: [
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.989347530186503,
              46.95001386199504
            ],
            [
              31.989894196157646,
              46.95039483613911
            ],
            [
              31.990231338585843,
              46.950241406605194
            ],
            [
              31.99098752809994,
              46.95002166354054
            ],
            [
              31.990755147443213,
              46.94867457982983
            ],
            [
              31.991065623238672,
              46.948632970526795
            ],
            [
              31.991284670579024,
              46.94861216586314
            ],
            [
              31.99170371766492,
              46.948599162944255
            ],
            [
              31.99186943173979,
              46.94860176352828
            ],
            [
              31.99227895502828,
              46.948565355340364
            ],
            [
              31.992955144644153,
              46.94851984507064
            ],
            [
              31.993088477807838,
              46.94851984507064
            ],
            [
              31.99313800155436,
              46.94838721492101
            ],
            [
              31.993793238815936,
              46.94841192056202
            ],
            [
              31.994233238256115,
              46.94845613062803
            ],
            [
              31.995336093995817,
              46.94859266148363
            ],
            [
              31.99593609323243,
              46.94868758273041
            ],
            [
              31.99748656745024,
              46.94887352386296
            ],
            [
              31.998130376154933,
              46.94889692899471
            ],
            [
              31.998717042075185,
              46.94911017527925
            ],
            [
              31.998694184961405,
              46.94946385017083
            ],
            [
              32.00130370545082,
              46.94959647765266
            ],
            [
              32.00132275304564,
              46.949388434397335
            ],
            [
              32.00238179931726,
              46.94937023057401
            ],
            [
              32.002408465950005,
              46.94960948032926
            ],
            [
              32.00438645292291,
              46.94947099521194
            ],
            [
              32.00440468549346,
              46.94998212238553
            ],
            [
              32.00527012484247,
              46.94995391094802
            ],
            [
              32.005654176106184,
              46.94992414414
            ],
            [
              32.00621798491266,
              46.94986953319808
            ],
            [
              32.00776845913045,
              46.94972390374721
            ],
            [
              32.00942179036024,
              46.94957047228996
            ],
            [
              32.01007321810285,
              46.94951065990781
            ],
            [
              32.01035512250609,
              46.949484654503436
            ],
            [
              32.0105570270111,
              46.950407838621764
            ],
            [
              32.01063321739036,
              46.950589873047306
            ],
            [
              32.01182940634464,
              46.9507172967768
            ],
            [
              32.01186750153427,
              46.95124779230839
            ],
            [
              32.01188654912908,
              46.951541642962404
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.01275839350696,
              46.96684274449201
            ],
            [
              32.01286049590205,
              46.966610073016575
            ],
            [
              32.012969891325376,
              46.96636495812158
            ],
            [
              32.01304464486466,
              46.96617956590675
            ],
            [
              32.01317227285853,
              46.96587721346654
            ],
            [
              32.013261612454244,
              46.965650759079274
            ],
            [
              32.01339471021929,
              46.9654131053672
            ],
            [
              32.01342205907512,
              46.96531854129282
            ],
            [
              32.0134348218745,
              46.96518167194176
            ],
            [
              32.01344940793094,
              46.96507093193777
            ],
            [
              32.01341294278985,
              46.96468271797853
            ],
            [
              32.01338194741989,
              46.96444257139463
            ],
            [
              32.01334183576468,
              46.96417131644373
            ],
            [
              32.013298077595344,
              46.963839089469424
            ],
            [
              32.01321420777081,
              46.96308379425158
            ],
            [
              32.013112105375704,
              46.9622849366351
            ],
            [
              32.01309387280515,
              46.961996249951625
            ],
            [
              32.013020942522935,
              46.96148108967217
            ],
            [
              32.0129735378395,
              46.96116751142122
            ],
            [
              32.01282403076095,
              46.959987843443685
            ],
            [
              32.01260523991429,
              46.95808638292571
            ],
            [
              32.01238644906766,
              46.956558196832766
            ],
            [
              32.01224058850323,
              46.95530375739119
            ],
            [
              32.01210566748112,
              46.95425836872616
            ],
            [
              32.012072848854125,
              46.95384020754207
            ],
            [
              32.01204367674125,
              46.95350418279427
            ],
            [
              32.012007211600135,
              46.95295160417693
            ],
            [
              32.0119598069167,
              46.95236168286966
            ],
            [
              32.0119269882897,
              46.95205054032039
            ],
            [
              32.01190875571914,
              46.95187381054688
            ],
            [
              32.01188654912908,
              46.951541642962404
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.01264875127643,
              46.95846453546814
            ],
            [
              32.012341474842785,
              46.95848503229845
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.012341474842785,
              46.95848503229845
            ],
            [
              32.011373408136656,
              46.958551443933644
            ],
            [
              32.010170452005674,
              46.95862885357
            ],
            [
              32.00910508282013,
              46.9587011870634
            ],
            [
              32.00610791854943,
              46.95889534490432
            ],
            [
              32.004345318221496,
              46.95901716907223
            ],
            [
              32.0018092561463,
              46.9591745248786
            ],
            [
              32.00016564993333,
              46.95928619646042
            ],
            [
              31.99568849635775,
              46.95956029844589
            ],
            [
              31.9931226857538,
              46.95972272858947
            ],
            [
              31.988943017465633,
              46.95999175243008
            ],
            [
              31.98538806828104,
              46.960230319609934
            ],
            [
              31.985105457258,
              46.96018463661528
            ],
            [
              31.985164954315483,
              46.96006789100731
            ],
            [
              31.985179828579852,
              46.9599968283386
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.985179828579852,
              46.9599968283386
            ],
            [
              31.98525428607725,
              46.95910701654716
            ],
            [
              31.985353447839724,
              46.95786169184768
            ],
            [
              31.985393112544713,
              46.95744206503994
            ],
            [
              31.98541955568137,
              46.957094629601315
            ],
            [
              31.98545922038636,
              46.95638847140071
            ],
            [
              31.98546583117052,
              46.95634334915419
            ],
            [
              31.986397951737736,
              46.956395239734384
            ],
            [
              31.98644092183481,
              46.95607035875138
            ],
            [
              31.98649711350021,
              46.95574547579591
            ],
            [
              31.986530167421023,
              46.955474738492974
            ],
            [
              31.98663593996766,
              46.95480917013391
            ],
            [
              31.986692131633063,
              46.95446848617758
            ],
            [
              31.98682765270844,
              46.953626919823975
            ],
            [
              31.98697970074423,
              46.952692833361475
            ],
            [
              31.986741712514306,
              46.95171811706177
            ],
            [
              31.98713835956418,
              46.95098706818711
            ],
            [
              31.98772010857067,
              46.950793022673004
            ],
            [
              31.988711726195376,
              46.949998782538174
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.98546583117052,
              46.95634334915419
            ],
            [
              31.98332570369348,
              46.956154442390456
            ],
            [
              31.980566783294165,
              46.95585788179945
            ],
            [
              31.980504999579633,
              46.9558283619571
            ],
            [
              31.979751238262352,
              46.95620790154602
            ],
            [
              31.97828078585647,
              46.956933236383236
            ],
            [
              31.977391100367203,
              46.957295900114886
            ],
            [
              31.977113073651804,
              46.957532051921085
            ],
            [
              31.975469626845246,
              46.95844713032608
            ],
            [
              31.97553141055977,
              46.95856098691307
            ],
            [
              31.975778545417903,
              46.95898689273846
            ],
            [
              31.976414917677587,
              46.960024233336604
            ],
            [
              31.976730014621708,
              46.96054289609378
            ],
            [
              31.976952435994015,
              46.96092661896275
            ],
            [
              31.97747141919609,
              46.96178682286679
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.97747141919609,
              46.961786822866785
            ],
            [
              31.977487637421152,
              46.961811068609755
            ],
            [
              31.98455414977062,
              46.9600163268533
            ],
            [
              31.984853800786095,
              46.960083795473885
            ],
            [
              31.985105457258,
              46.96018463661528
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.985105457258,
              46.96018463661528
            ],
            [
              31.98496501147226,
              46.96037475292559
            ],
            [
              31.985054597858326,
              46.960558181810214
            ],
            [
              31.98518743284456,
              46.96080486104284
            ],
            [
              31.986055494033714,
              46.96232286205494
            ],
            [
              31.9863675017921,
              46.9628499356658
            ],
            [
              31.986750560822195,
              46.96345711802885
            ],
            [
              31.98801094859862,
              46.96316196079645
            ],
            [
              31.98956171983334,
              46.96276982081161
            ],
            [
              31.989697644005314,
              46.962744521359035
            ],
            [
              31.989805765505753,
              46.96272765505068
            ],
            [
              31.99036181893653,
              46.96373540764217
            ],
            [
              31.99046994043696,
              46.96397574750685
            ],
            [
              31.990500832294213,
              46.96406850997286
            ],
            [
              31.99092713992448,
              46.9653123547733
            ],
            [
              31.99142140964072,
              46.96698201283298
            ],
            [
              31.991853895642436,
              46.96774936345115
            ],
            [
              31.992274024901253,
              46.96837335473942
            ],
            [
              31.994572379081795,
              46.97183047187428
            ],
            [
              31.99628378797431,
              46.97449904483364
            ],
            [
              31.999601573444615,
              46.973457769173635
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.976086492447116,
              46.95948887494899
            ],
            [
              31.976268594448904,
              46.95943926705213
            ],
            [
              31.97705077916856,
              46.9592114765625
            ],
            [
              31.978532741086884,
              46.958859008708465
            ],
            [
              31.979582063520255,
              46.95860778020373
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 2,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.981465977300633,
              46.9783182528536
            ],
            [
              31.98134969113217,
              46.97813458552128
            ],
            [
              31.98109331217806,
              46.97776724896388
            ],
            [
              31.98036446343709,
              46.9767576851549
            ],
            [
              31.979745491390748,
              46.97576059723898
            ],
            [
              31.979562363566373,
              46.975480709569815
            ],
            [
              31.97898001708489,
              46.97470351380685
            ],
            [
              31.978774913921608,
              46.97438863385171
            ],
            [
              31.978273143682852,
              46.9736714003659
            ],
            [
              31.978009439615775,
              46.97327154476904
            ],
            [
              31.977672484418942,
              46.97277172106874
            ],
            [
              31.977320878996167,
              46.97226439523589
            ],
            [
              31.977522319602965,
              46.97215193319273
            ],
            [
              31.977888575251686,
              46.972034472584035
            ],
            [
              31.978734625800247,
              46.971779557184114
            ],
            [
              31.979349935290116,
              46.971589619429515
            ],
            [
              31.980393763888987,
              46.97124972913249
            ],
            [
              31.981411954592442,
              46.97091983354319
            ],
            [
              31.98275977537977,
              46.97049496501365
            ],
            [
              31.98297220365603,
              46.97042248710399
            ],
            [
              31.983140681254444,
              46.97036000606856
            ],
            [
              31.983276195844464,
              46.97031002118765
            ],
            [
              31.984631341744752,
              46.96989014634377
            ],
            [
              31.984818132125604,
              46.96982516541864
            ],
            [
              31.985653195004705,
              46.96955024524644
            ],
            [
              31.986169615469414,
              46.969377794599026
            ],
            [
              31.986367393519732,
              46.96931531234318
            ],
            [
              31.987341633545356,
              46.969010397887416
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 2,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.987341633545356,
              46.969010397887416
            ],
            [
              31.98828291056257,
              46.96871297965347
            ],
            [
              31.989821184287234,
              46.96818811991212
            ],
            [
              31.990022624894028,
              46.96814313169466
            ],
            [
              31.99177131188157,
              46.96760283770939
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.98828291056257,
              46.96871297965346
            ],
            [
              31.98857019233706,
              46.96918441178056
            ],
            [
              31.988967579715922,
              46.9698367245313
            ],
            [
              31.989093937914728,
              46.96994044402489
            ],
            [
              31.989308197469242,
              46.97026409753716
            ],
            [
              31.989593876875237,
              46.97069896480975
            ],
            [
              31.989848424551106,
              46.971085093993345
            ],
            [
              31.990317231781486,
              46.97177737040461
            ],
            [
              31.990504022162337,
              46.97205977661997
            ],
            [
              31.99062122396992,
              46.97223471777526
            ],
            [
              31.991276821581156,
              46.973301846430715
            ],
            [
              31.991593632717294,
              46.97376792755813
            ],
            [
              31.991782254376385,
              46.97405032326234
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.00559655286134,
              46.9709707040189
            ],
            [
              32.00443552245488,
              46.9707370269676
            ],
            [
              32.00427620124767,
              46.970695789734904
            ],
            [
              32.004283526360645,
              46.970304659250004
            ],
            [
              32.004105892371015,
              46.96870761755572
            ],
            [
              32.00401432845884,
              46.9678903336425
            ],
            [
              32.003919101990164,
              46.96705554146337
            ],
            [
              32.00382753807799,
              46.96631821418258
            ],
            [
              32.003739636722294,
              46.96559587353348
            ],
            [
              32.003681035818495,
              46.9650259923666
            ],
            [
              32.003615109801714,
              46.96442111185483
            ],
            [
              32.003523545889536,
              46.963731239782255
            ],
            [
              32.00340634408194,
              46.962811396513366
            ],
            [
              32.0031316523454,
              46.96058421048576
            ],
            [
              32.00303276332025,
              46.95971930741721
            ],
            [
              32.00298331880767,
              46.95935434412437
            ],
            [
              32.0024394291693,
              46.959381841445484
            ],
            [
              32.00241001348286,
              46.95913724954927
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.010057264830074,
              46.95863653844476
            ],
            [
              32.010085554140964,
              46.958837083067664
            ],
            [
              32.01029065730425,
              46.960321932540246
            ],
            [
              32.01033094542561,
              46.96057440284655
            ],
            [
              32.010363908433995,
              46.960686889233024
            ],
            [
              32.01032728286913,
              46.96084187008924
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.00657225508961,
              46.958865264990855
            ],
            [
              32.00660520983891,
              46.95914518022253
            ],
            [
              32.00669311119461,
              46.959730122474745
            ],
            [
              32.00675903721139,
              46.96017757398324
            ],
            [
              32.00682130067167,
              46.9606100235612
            ],
            [
              32.00684327601059,
              46.9608724912537
            ],
            [
              32.00688722668844,
              46.96108496415695
            ],
            [
              32.006927514809796,
              46.961422419386636
            ],
            [
              32.00698977827008,
              46.9618298624942
            ],
            [
              32.00709599240821,
              46.96243477230496
            ],
            [
              32.007143605642554,
              46.96262224296544
            ],
            [
              32.0071582558685,
              46.96279471539287
            ],
            [
              32.00720220654634,
              46.96314965689974
            ],
            [
              32.00722051932877,
              46.96327213616899
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.00722051932877,
              46.96327213616899
            ],
            [
              32.00724432594596,
              46.963408362746655
            ],
            [
              32.007258976171904,
              46.9635608361589
            ],
            [
              32.00728095151082,
              46.963728306455174
            ],
            [
              32.00729560173678,
              46.96384828485256
            ],
            [
              32.00783033498392,
              46.96380579253426
            ],
            [
              32.00784681648811,
              46.963885778046574
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.01264875127643,
              46.95846453546814
            ],
            [
              32.013771632752935,
              46.95841224725539
            ],
            [
              32.01542338850114,
              46.95830678679168
            ],
            [
              32.018952490586145,
              46.95806633615719
            ],
            [
              32.02084992375619,
              46.95794400120912
            ],
            [
              32.02200568923762,
              46.95786385057428
            ],
            [
              32.02334069107714,
              46.957783699819345
            ],
            [
              32.02512069352981,
              46.95767401964437
            ],
            [
              32.028285142334575,
              46.95745465861995
            ],
            [
              32.03044833975972,
              46.95731966677333
            ],
            [
              32.032379562284376,
              46.95720417985685
            ],
            [
              32.03228705757108,
              46.95644643003876
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 0,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.032287057571075,
              46.95644643003876
            ],
            [
              32.03211091149503,
              46.95592543418517
            ],
            [
              32.033065808644125,
              46.95586848291386
            ],
            [
              32.03302563497767,
              46.95559216292226
            ],
            [
              32.03297310018306,
              46.95523146826729
            ],
            [
              32.03292365567048,
              46.95485389640409
            ],
            [
              32.032676433107596,
              46.952839431665645
            ],
            [
              32.03271351649204,
              46.952316291246966
            ],
            [
              32.032954558490836,
              46.95164548274372
            ],
            [
              32.03311525315671,
              46.950987322833214
            ],
            [
              32.033269767258496,
              46.95069621106038
            ],
            [
              32.03341192023215,
              46.950379783424424
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.03263882937633,
              46.95589394850384
            ],
            [
              32.03284948890161,
              46.95656454985078
            ],
            [
              32.03406706002376,
              46.9566911065029
            ],
            [
              32.03616845180817,
              46.9585556731525
            ],
            [
              32.03726859221294,
              46.9582435104137
            ],
            [
              32.03913512256263,
              46.959719939736594
            ],
            [
              32.03996331814827,
              46.96040330180216
            ],
            [
              32.04400540705123,
              46.959247486785806
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.04051320128528,
              46.937127666588445
            ],
            [
              32.03995283014277,
              46.93825301266869
            ],
            [
              32.039073816585905,
              46.93985846546975
            ],
            [
              32.038019000317654,
              46.941838957742505
            ],
            [
              32.03678838133803,
              46.94413443666158
            ],
            [
              32.03507430490214,
              46.94729243470812
            ],
            [
              32.03341192023215,
              46.950379783424424
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.04614893146499,
              46.95183489470582
            ],
            [
              32.04586050514168,
              46.95194927454254
            ],
            [
              32.046132449960794,
              46.952264286599664
            ],
            [
              32.044934793989725,
              46.95277242714113
            ],
            [
              32.043322353496585,
              46.95339493960498
            ],
            [
              32.04258068580807,
              46.95255492107929
            ],
            [
              32.042281271815305,
              46.952204284446026
            ],
            [
              32.04215491361653,
              46.952056153841184
            ],
            [
              32.042139805571026,
              46.952036465565364
            ],
            [
              32.04197636398781,
              46.95194927454254
            ],
            [
              32.04180880202856,
              46.95192302345409
            ],
            [
              32.037949383130986,
              46.950932973003695
            ],
            [
              32.03774885816335,
              46.95088797027534
            ],
            [
              32.035537589684665,
              46.9504135642119
            ],
            [
              32.03364699337816,
              46.94994322092624
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.03219423075438,
              46.95873734735381
            ],
            [
              32.03217437565677,
              46.95854600509507
            ],
            [
              32.03231343834836,
              46.95854284129209
            ],
            [
              32.03217686617401,
              46.95721630107597
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.02195960341212,
              46.95786704656073
            ],
            [
              32.022014687179876,
              46.958176629833474
            ],
            [
              32.02213636703503,
              46.95885052025623
            ],
            [
              32.02224761718831,
              46.95963829689162
            ],
            [
              32.02242144555281,
              46.96083654935387
            ],
            [
              32.02244925809112,
              46.961012132608886
            ],
            [
              32.02253964884066,
              46.961481933619844
            ],
            [
              32.02283515706029,
              46.96286046731882
            ],
            [
              32.0229742197519,
              46.96344888396718
            ],
            [
              32.023266251404245,
              46.96420812169233
            ],
            [
              32.02328189595705,
              46.96435996794433
            ],
            [
              32.023236700582295,
              46.96448334270672
            ],
            [
              32.0232175794622,
              46.96454977515323
            ],
            [
              32.023233224015,
              46.96466365915531
            ],
            [
              32.02322800916407,
              46.964922269842994
            ],
            [
              32.023155001250984,
              46.96513105554113
            ],
            [
              32.02272043033973,
              46.966208186991864
            ],
            [
              32.022588320782724,
              46.96652135770517
            ],
            [
              32.02206683568923,
              46.96651661270804
            ],
            [
              32.01854854959183,
              46.966749117072325
            ],
            [
              32.01838862749649,
              46.96697213051299
            ],
            [
              32.01844425257313,
              46.96796381731893
            ],
            [
              32.01460264571777,
              46.96721412276913
            ],
            [
              32.01378912897192,
              46.96705279472001
            ],
            [
              32.013166823427035,
              46.966927053403154
            ],
            [
              32.01275839350696,
              46.96684274449201
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.02028297038447,
              46.96681238339078
            ],
            [
              32.02026281807455,
              46.96663583053205
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.01682146636512,
              46.96764712977197
            ],
            [
              32.016619330123575,
              46.96790426366434
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.01378912897192,
              46.96705279472001
            ],
            [
              32.013974048701314,
              46.966636840017195
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.01275839350696,
              46.96684274449201
            ],
            [
              32.01223430197727,
              46.966628502547614
            ],
            [
              32.01193454461985,
              46.96658421598009
            ],
            [
              32.01174912769772,
              46.96655680046753
            ],
            [
              32.010355410499784,
              46.96661795813011
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.999601573444615,
              46.973457769173635
            ],
            [
              31.99964995321018,
              46.97343746738025
            ],
            [
              31.998844934739967,
              46.97228193396457
            ],
            [
              32.000505961333964,
              46.97175898362857
            ],
            [
              32.00060021493604,
              46.971888667354
            ],
            [
              32.001171532842534,
              46.9727191073586
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.998844934739967,
              46.97228193396456
            ],
            [
              31.995532585189668,
              46.9733277225474
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.995532585189668,
              46.9733277225474
            ],
            [
              31.99295099432803,
              46.974126979987595
            ],
            [
              31.99202564135728,
              46.974425149355774
            ],
            [
              31.990803248313462,
              46.97481859226531
            ],
            [
              31.99021609472673,
              46.97499992815074
            ],
            [
              31.98880692611862,
              46.97546591638477
            ],
            [
              31.988711127375527,
              46.975499652931866
            ],
            [
              31.98857515496597,
              46.97528879916336
            ],
            [
              31.98840209917199,
              46.97505264195558
            ],
            [
              31.988164147455272,
              46.97470894701303
            ],
            [
              31.987907654046346,
              46.974325186859616
            ],
            [
              31.987085639024944,
              46.9731549122453
            ],
            [
              31.987002201409982,
              46.97303893769063
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.985708185819178,
              46.959921380113165
            ],
            [
              31.985745269203612,
              46.959465802269186
            ],
            [
              31.986836138762087,
              46.959461583937696
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.98643754019207,
              46.95946312529608
            ],
            [
              31.98645912435377,
              46.95907982356037
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.98655802230655,
              46.95946265939849
            ],
            [
              31.986585825917224,
              46.95966828178986
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.96738809572532,
              46.96682998178731
            ],
            [
              31.967287514239747,
              46.9670465315184
            ],
            [
              31.967024840266742,
              46.967577963315605
            ],
            [
              31.966759076011698,
              46.9681030633579
            ],
            [
              31.966774527421872,
              46.968252789734706
            ],
            [
              31.966830152498513,
              46.96835612177849
            ],
            [
              31.966941402651784,
              46.968417277383836
            ],
            [
              31.96714536126612,
              46.96846367124469
            ],
            [
              31.968016820800084,
              46.968402515692404
            ],
            [
              31.97034689345473,
              46.968252789734706
            ],
            [
              31.970575574325352,
              46.96823381035802
            ],
            [
              31.970819706606147,
              46.968216939795326
            ],
            [
              31.97084133858039,
              46.96832448954138
            ],
            [
              31.970569393761277,
              46.96873570716455
            ],
            [
              31.970492136710394,
              46.96888964935891
            ],
            [
              31.97045505332597,
              46.968967674685494
            ],
            [
              31.970782623221716,
              46.96903937353388
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.970819706606147,
              46.968216939795326
            ],
            [
              31.97093095675943,
              46.96801449262784
            ],
            [
              31.970983491554023,
              46.967843677234285
            ],
            [
              31.971236240181728,
              46.96761216609452
            ],
            [
              31.971252346091106,
              46.96737129602185
            ],
            [
              31.971400679628807,
              46.96706129358406
            ],
            [
              31.971675714729944,
              46.96655094552135
            ],
            [
              31.97175915234491,
              46.966390669918454
            ],
            [
              31.972355576777726,
              46.96636747209403
            ],
            [
              31.972698598083653,
              46.9663400564704
            ],
            [
              31.975124469481393,
              46.96617134463103
            ],
            [
              31.975350060069978,
              46.966751289349645
            ],
            [
              31.975652907709446,
              46.967548439465396
            ],
            [
              31.975791970401044,
              46.96797864252876
            ],
            [
              31.975881588580066,
              46.96837510111178
            ],
            [
              31.975989748451294,
              46.96852271791852
            ],
            [
              31.976827214882878,
              46.96907733170886
            ],
            [
              31.979265447408782,
              46.970871879126555
            ],
            [
              31.979796975918855,
              46.9712387944512
            ],
            [
              31.97993948133431,
              46.971397652373625
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.975124469481393,
              46.96617134463103
            ],
            [
              31.97532082046186,
              46.966210293334115
            ],
            [
              31.97581835586953,
              46.96609219481594
            ],
            [
              31.977063739529747,
              46.965791675089335
            ],
            [
              31.97793519906369,
              46.96558078304502
            ],
            [
              31.97849917553513,
              46.96545213848973
            ],
            [
              31.979857354489635,
              46.965140015987735
            ],
            [
              31.98084315445888,
              46.964908031867274
            ],
            [
              31.98253971929624,
              46.96449678481765
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.98253971929624,
              46.96449678481765
            ],
            [
              31.983165501408376,
              46.96433861203365
            ],
            [
              31.98346062334275,
              46.964265852395975
            ],
            [
              31.983911804519902,
              46.96415934897978
            ],
            [
              31.98430736062041,
              46.96406549925908
            ],
            [
              31.98532097312798,
              46.96381558403127
            ],
            [
              31.986750560822195,
              46.96345711802885
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.968603815950985,
              46.96836479655993
            ],
            [
              31.968617226156844,
              46.96845253403175
            ],
            [
              31.968419448106584,
              46.96886585946564
            ],
            [
              31.968181496389867,
              46.96933822747909
            ],
            [
              31.967378023060704,
              46.97093033001205
            ],
            [
              31.96725132149725,
              46.971160179310466
            ],
            [
              31.966923751601506,
              46.97106950493458
            ],
            [
              31.966005937837036,
              46.970862851131365
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.984802439654143,
              46.96983062454279
            ],
            [
              31.985573969187453,
              46.97095879361786
            ],
            [
              31.987236356547864,
              46.970412960561156
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.985573969187453,
              46.97095879361786
            ],
            [
              31.984210865967206,
              46.97139137164144
            ],
            [
              31.98297563869776,
              46.97177381968383
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.03275056778651,
              46.95344351756604
            ],
            [
              32.03460230960722,
              46.953126762634604
            ],
            [
              32.03787032989874,
              46.95388587441605
            ],
            [
              32.03884098070175,
              46.95396245088383
            ],
            [
              32.03932874492436,
              46.95407565067895
            ],
            [
              32.039918939633736,
              46.9547248801732
            ],
            [
              32.04066521889434,
              46.95559716586231
            ],
            [
              32.041109084336924,
              46.95611653532068
            ],
            [
              32.04292356724505,
              46.958233912482825
            ],
            [
              32.044235653003895,
              46.959741993238005
            ],
            [
              32.04995224969296,
              46.95741493691296
            ],
            [
              32.051561871627584,
              46.95676241082002
            ],
            [
              32.05379095412494,
              46.955853521930095
            ],
            [
              32.06263411948099,
              46.95228107279491
            ],
            [
              32.06735567715591,
              46.95033660137545
            ],
            [
              32.06935551046863,
              46.949530823680526
            ],
            [
              32.07013593322482,
              46.949204513559756
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.04764678603483,
              46.9583534347104
            ],
            [
              32.047339052870306,
              46.95802251145261
            ],
            [
              32.04685616628991,
              46.957501495898924
            ],
            [
              32.04605867178594,
              46.956579302578476
            ],
            [
              32.04560748988001,
              46.95605660833275
            ],
            [
              32.04496607992727,
              46.9553041859953
            ],
            [
              32.04438807932347,
              46.95461334643579
            ],
            [
              32.04384666103637,
              46.954014056702476
            ],
            [
              32.043322353496585,
              46.95339493960498
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 2
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.04400540705123,
              46.959247486785806
            ],
            [
              32.04437100757568,
              46.95942656485163
            ],
            [
              32.044544947105756,
              46.959616091406744
            ]
          ]
        ]
      }
    }
  ]
}