export const border_scheme_data = {
  type: "FeatureCollection",
  name: "border_scheme_data",
  crs: {
    type: "name",
    properties: {
      name: "urn:ogc:def:crs:OGC:1.3:CRS84"
    }
  },
  features: [
    {
      type: "Feature",
      properties: {
        pkuid: 1,
        shema_name_short: "Схема ул. Казарского",
        shema_name: "Схема границ раздела теплосетей ЖК П \"Юг\" и ОКП Николаевоблтеплоэнерго",
        img: 'shema-1.jpg'
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05020413912773,
          46.98394045354908
        ]
      }
    }
  ]
}