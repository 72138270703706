export const boilerList = {
  type: "FeatureCollection",
  name: "boilerList_v_04-08-2022",
  crs: {
    type: "name",
    properties: {
      name: "urn:ogc:def:crs:OGC:1.3:CRS84"
    }
  },
  features: [
    {
      type: "Feature",
      properties: {
        id: 1,
        comp: "ОКП",
        adrs_cot: "вул. Космонавтів, 43-К",
        latitude: 46.934386317536465,
        longitude: 32.03758533539154,
        cot_tip: null,
        area_t: "1 РТМ",
        year_build: "1956",
        cot_cond: "окремо розташована, 1 пов., фундамент та стіни - камінь, з/б плити перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "54,37 C",
        t_output: "49,19 C",
        total_pow: "1 249,06 кВт",
        join_pow: "210,93 кВт",
        heat_area: "2 626,00 м2",
        gas_use: "71 076,80 м3",
        prod_pow: "421,9768 Гкал",
        kkd: "71%",
        rel_pow: "371,4870 Гкал",
        len_netw: "840,0 м",
        netw_loss: "10,67",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Космонавтів, 43-К",
        boilers: [
          {name: "НИИСТУ-5 (водотрубний)", power: "267,49", year_build: "1992", kkd: "89,6%", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "290,75", year_build: "1992", kkd: "87,3%", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "345,41", year_build: "1993", status_work: 0,},{name: "НИИСТУ-5 (водотрубний)", power: "345,41", year_build: "1993", status_work: 0,}
        ],
        boil_totl: 4,
        boil_not_w: 2
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03758533539154,
          46.934386317536465
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 2,
        comp: "ОКП",
        adrs_cot: "вул. Театральна, 10-К",
        latitude: 46.946601169486826,
        longitude: 32.045769829280374,
        cot_tip: null,
        area_t: "1 РТМ",
        year_build: "1976",
        cot_cond: "окремо розташована, 1 пов., фундамент  - камінь, цегляні стіни, з/б плити перекриття,",
        year_moder: null,
        mod_work: null,
        t_input: "52,40 C",
        t_output: "45,20 C",
        total_pow: "1 832,89 кВт",
        join_pow: "0,00 кВт",
        heat_area: null,
        gas_use: "16 814,73 м3",
        prod_pow: "79,3930 Гкал",
        kkd: "83%",
        rel_pow: "58,4471 Гкал",
        len_netw: "0,0 м",
        netw_loss: "25,65",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Театральна, 10-К",
        boilers: [
          {name: "НИИСТУ-5 (водотрубний)", power: "540,8", year_build: "1992", kkd: "83,8% ", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "540,8", year_build: "1992", kkd: "83,2% ", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "375,6", year_build: "1992", kkd: "82,2%", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "375,6", year_build: "1992", kkd: "83,0%", status_work: 1,}
        ],
        boil_totl: 4,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.045769829280374,
          46.946601169486826
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 3,
        comp: "ОКП",
        adrs_cot: "вул. Миколаївська, 34-Б",
        latitude: 46.94382633048953,
        longitude: 32.04824162517786,
        cot_tip: "(кот. 112 мкр.)",
        area_t: "1 РТМ",
        year_build: "1970",
        cot_cond: "окремо розташована, 1-2 пов., фундамент - моноліт. з/б, бетонні блоки, цегляні стіни, з/б перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "58,84 C",
        t_output: "43,97 C",
        total_pow: "37 797,50 кВт",
        join_pow: "19 827,52 кВт",
        heat_area: "401 345,38 м2",
        gas_use: "4 162 088,12 м3",
        prod_pow: "32 091,2216 Гкал",
        kkd: "91%",
        rel_pow: "28 505,1744 Гкал",
        len_netw: "15 347,8 м",
        netw_loss: "9,22",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Миколаївська, 34-Б",
        boilers: [
          {name: "Котел КВГ-7,56 (водотрубний) ", power: "7559,5", year_build: "1983", kkd: "87,2%", status_work: 1,},{name: "Котел КВГ-7,56 (водотрубний) ", power: "7559,5", year_build: "1982", kkd: "88,1%", status_work: 1,},{name: "Котел КВГ-7,56 (водотрубний) ", power: "7559,5", year_build: "1993", kkd: "89,6%", status_work: 1,},{name: "Котел КВГ-7,56 (водотрубний) ", power: "7559,5", year_build: "1993", kkd: "89,6%", status_work: 1,},{name: "Котел КВГ-7,56 (водотрубний) ", power: "7559,5", year_build: "1982", kkd: "91,2%", status_work: 1,}
        ],
        boil_totl: 5,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04824162517786,
          46.94382633048953
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 4,
        comp: "ОКП",
        adrs_cot: "вул. Передова, 69-К",
        latitude: 46.94176102330021,
        longitude: 32.02646529890601,
        cot_tip: null,
        area_t: "1 РТМ",
        year_build: "1977",
        cot_cond: "окремо розташована, 1 пов., підвал, фундамент - камінь, стніни - камінь, з/б перекриття,",
        year_moder: null,
        mod_work: null,
        t_input: "52,51 C",
        t_output: "43,93 C",
        total_pow: "4 041,43 кВт",
        join_pow: "2 398,92 кВт",
        heat_area: "51 715,00 м2",
        gas_use: "564 549,70 м3",
        prod_pow: "3 819,4956 Гкал",
        kkd: "81%",
        rel_pow: "3 352,8371 Гкал",
        len_netw: "2 954,0 м",
        netw_loss: "10,29",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Передова, 69-К",
        boilers: [
          {name: "НИИСТУ-5мк (водотрубний)", power: "976,92", year_build: "2004", kkd: "89,4%", status_work: 1,},{name: "НИИСТУ-5мк (водотрубний)", power: "976,92", year_build: "2004", kkd: "89,8%", status_work: 1,},{name: "НИИСТУ-5мк (водотрубний)", power: "976,92", year_build: "2004", kkd: "88,0%", status_work: 1,},{name: "НИИСТУ-5мк (водотрубний)", power: "976,92", year_build: "2004", kkd: "89,5%", status_work: 1,}
        ],
        boil_totl: 4,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02646529890601,
          46.94176102330021
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5,
        comp: "ОКП",
        adrs_cot: "пров. Полярний, 2-К",
        latitude: 46.94020197431781,
        longitude: 32.031822199845834,
        cot_tip: null,
        area_t: "1 РТМ",
        year_build: "1969",
        cot_cond: "окремо розташована, 1-2 пов., бетонний фундамент, цегляні стіни, з/б плити перекриття,",
        year_moder: null,
        mod_work: null,
        t_input: "51,97 C",
        t_output: "40,68 C",
        total_pow: "5 300,95 кВт",
        join_pow: "2 832,37 кВт",
        heat_area: "57 237,10 м2",
        gas_use: "643 026,48 м3",
        prod_pow: "4 342,4407 Гкал",
        kkd: "81%",
        rel_pow: "3 810,0902 Гкал",
        len_netw: "2 228,0 м",
        netw_loss: "11,38",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, пров. Полярний, 2-К",
        boilers: [
          {name: "НИИСТУ (водотрубний)", power: "1054,84", year_build: "1993", kkd: "89,9%", status_work: 1,},{name: "НИИСТУ (водотрубний)", power: "1054,84", year_build: "1997", kkd: "89,4%", status_work: 1,},{name: "НИИСТУ (водотрубний)", power: "1054,84", year_build: "1987", kkd: "83,3%", status_work: 1,},{name: "НИИСТУ (водотрубний)", power: "1054,84", year_build: "1986", kkd: "82,8%", status_work: 1,},{name: "НИИСТУ (водотрубний)", power: "540,795", year_build: "1995", kkd: "89,5%", status_work: 1,},{name: "НИИСТУ (водотрубний)", power: "540,795", year_build: "1989", kkd: "-87,0%", status_work: 1,}
        ],
        boil_totl: 6,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.031822199845834,
          46.94020197431781
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 6,
        comp: "ОКП",
        adrs_cot: "вул. Знаменська, 2-К",
        latitude: 46.916644909332426,
        longitude: 32.078240606378685,
        cot_tip: null,
        area_t: "1 РТМ",
        year_build: "1962",
        cot_cond: "окремо розташована, 1-2 пов., цегляні стіни, з/б плити перекриття, фундаментні блоки, вікна замінені",
        year_moder: null,
        mod_work: null,
        t_input: "59,21 C",
        t_output: "42,37 C",
        total_pow: "9 071,40 кВт",
        join_pow: "5 833,49 кВт",
        heat_area: "106 940,03 м2",
        gas_use: "1 275 861,67 м3",
        prod_pow: "9 453,8168 Гкал",
        kkd: "89%",
        rel_pow: "7 969,1801 Гкал",
        len_netw: "10 566,9 м",
        netw_loss: "14,86",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Знаменська, 2-К",
        boilers: [
          {name: "КБН-Г (водотрубний)", power: "2907,5", year_build: "1990", kkd: "90,2%", status_work: 1,},{name: "КБН-Г (водотрубний)", power: "2907,5", year_build: "1997", kkd: "89,2%", status_work: 1,},{name: "КБН-Г (водотрубний)", power: "2907,5", year_build: "1997", kkd: "91,8%", status_work: 1,},{name: "АПВЕ-90 (електричний)", power: "86,06", status_work: 1,},{name: "АПВЕ-90 (електричний)", power: "86,06", status_work: 1,},{name: "АПВЕ-90 (електричний)", power: "86,06", status_work: 1,},{name: "АПВЕ-90 (електричний)", power: "86,06", status_work: 1,}
        ],
        boil_totl: 7,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.078240606378685,
          46.916644909332426
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 7,
        comp: "ОКП",
        adrs_cot: "вул. 295 Стрілецької дивізії, 75/1-К",
        latitude: 46.915203266052686,
        longitude: 32.05800742566002,
        cot_tip: null,
        area_t: "1 РТМ",
        year_build: "1971",
        cot_cond: "окремо розташована, 1пов., з/б фундамент, цегляні стіни, з/б плити перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "55,50 C",
        t_output: "42,79 C",
        total_pow: "400,07 кВт",
        join_pow: "192,01 кВт",
        heat_area: "3 155,50 м2",
        gas_use: "39 210,99 м3",
        prod_pow: "246,5205 Гкал",
        kkd: "76%",
        rel_pow: "228,1100 Гкал",
        len_netw: "152,0 м",
        netw_loss: "6,54",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. 295 Стрілецької дивізії, 75/1-К",
        boilers: [{name: "Котел КГБ-100 (водотрубний)", power: "100", year_build: "2001", kkd: "88,2%", status_work: 1,},{name: "Котел КГБ-100 (водотрубний)", power: "100", year_build: "2001", kkd: "88,0%", status_work: 1,},{name: "Котел КГБ-100 (водотрубний)", power: "100", year_build: "2001", kkd: "87,9%", status_work: 1,},{name: "Котел КГБ-100 (водотрубний)", power: "100", year_build: "2001", kkd: "87,9%.", status_work: 1,}],
        boil_totl: 4,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05800742566002,
          46.915203266052686
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 8,
        comp: "ОКП",
        adrs_cot: "вул. 295 Стрілецької дивізії, 75-А",
        latitude: 46.915046190564986,
        longitude: 32.05459314608098,
        cot_tip: null,
        area_t: "1 РТМ",
        year_build: "2001",
        cot_cond: "дах ж/будинку",
        year_moder: null,
        mod_work: null,
        t_input: "54,40 C",
        t_output: "46,62 C",
        total_pow: "324,48 кВт",
        join_pow: "131,30 кВт",
        heat_area: "1 832,20 м2",
        gas_use: "33 659,29 м3",
        prod_pow: "210,4881 Гкал",
        kkd: "75%",
        rel_pow: "196,8600 Гкал",
        len_netw: "40,0 м",
        netw_loss: "5,54",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. 295 Стрілецької дивізії, 75-А",
        boilers: [{name: "МН120'Бернард' (водотрубний)", power: "108,0", year_build: "2001", kkd: "90,2%", status_work: 1,},{name: "МН120'Бернард' (водотрубний)", power: "108,0", year_build: "2001", kkd: "90,5%", status_work: 1,},{name: "МН120'Бернард' (водотрубний)", power: "108,0", year_build: "2001", kkd: "90,6%", status_work: 1,}],
        boil_totl: 3,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05459314608098,
          46.915046190564986
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 9,
        comp: "ОКП",
        adrs_cot: "вул. Вільна, 38-К",
        latitude: 46.91676939799334,
        longitude: 32.052228214462225,
        cot_tip: null,
        area_t: "2 РТМ",
        year_build: "1990",
        cot_cond: "окремо розташована, 1 пов., фундамент - камінь бут., цегляні стіни, з/б плити перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "53,27 C",
        t_output: "45,61 C",
        total_pow: "1 789,86 кВт",
        join_pow: "428,22 кВт",
        heat_area: "6 616,93 м2",
        gas_use: "92 745,17 м3",
        prod_pow: "602,6518 Гкал",
        kkd: "78%",
        rel_pow: "519,1100 Гкал",
        len_netw: "757,1 м",
        netw_loss: "13",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Вільна, 38-К",
        boilers: [{name: "НИИСТУ 5м (водотрубний)", power: "596,6", year_build: "2001", kkd: "89,4%", status_work: 1,},{name: "НИИСТУ 5м (водотрубний)", power: "596,6", year_build: "1997", status_work: 0,},{name: "НИИСТУ 5м (водотрубний)", power: "596,6", year_build: "1991", kkd: "89,6%", status_work: 1,}],
        boil_totl: 3,
        boil_not_w: 1
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.052228214462225,
          46.91676939799334
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 10,
        comp: "ОКП",
        adrs_cot: "вул. 1 Слобідська, 13",
        latitude: 46.96693210356409,
        longitude: 32.006871144822625,
        cot_tip: null,
        area_t: "2 РТМ",
        year_build: "-",
        cot_cond: "вбудована, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "53,28 C",
        t_output: "45,99 C",
        total_pow: "386,12 кВт",
        join_pow: "53,61 кВт",
        heat_area: "1 313,80 м2",
        gas_use: "20 168,93 м3",
        prod_pow: "97,5458 Гкал",
        kkd: "58%",
        rel_pow: "96,5800 Гкал",
        len_netw: "0,0 м",
        netw_loss: "0",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. 1 Слобідська, 13",
        boilers: [{name: "Универсал-5 (водотрубний)", power: "193,05", year_build: "1965", kkd: "71,8%", status_work: 1,},{name: "Универсал-5 (водотрубний)", power: "193,05", year_build: "1965", kkd: "72,4%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.006871144822625,
          46.96693210356409
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 11,
        comp: "ОКП",
        adrs_cot: "вул. Велика Морська, 54/56",
        latitude: 46.9746712472075,
        longitude: 31.985051520825778,
        cot_tip: null,
        area_t: "2 РТМ",
        year_build: "1992",
        cot_cond: "окремо розташована, 1 пов.,",
        year_moder: null,
        mod_work: null,
        t_input: "51,84 C",
        t_output: "44,86 C",
        total_pow: "907,14 кВт",
        join_pow: "78,97 кВт",
        heat_area: "325,50 м2",
        gas_use: "21 744,33 м3",
        prod_pow: "126,3602 Гкал",
        kkd: "70%",
        rel_pow: "99,1367 Гкал",
        len_netw: "210,0 м",
        netw_loss: "20,76",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Велика Морська, 54/56",
        boilers: [{name: "НИИСТУ-5мк-2ф (водотрубний)", power: "453,575", year_build: "2004", kkd: "88,5%", status_work: 1,},{name: "НИИСТУ-5мк-2ф (водотрубний)", power: "453,575", year_build: "2004", kkd: "88,2%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.985051520825778,
          46.9746712472075
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 12,
        comp: "ОКП",
        adrs_cot: "вул. Адміральська, 27/1",
        latitude: 46.97474123646213,
        longitude: 31.99319107520812,
        cot_tip: null,
        area_t: "2 РТМ",
        year_build: "-",
        cot_cond: "вбудована, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "50,93 C",
        t_output: "39,97 C",
        total_pow: "1 571,21 кВт",
        join_pow: "588,25 кВт",
        heat_area: "3 341,20 м2",
        gas_use: "126 247,09 м3",
        prod_pow: "763,2026 Гкал",
        kkd: "73%",
        rel_pow: "716,3328 Гкал",
        len_netw: "534,0 м",
        netw_loss: "5,2",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Адміральська, 27/1",
        boilers: [{name: "НИИСТУ-5 (водотрубний)", power: "540,79", year_build: "1978", kkd: "84,0% ", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "540,79", year_build: "1979", kkd: "85,0%", status_work: 1,},{name: "Універсал (водотрубний)", power: "489,6", year_build: "1972", status_work: 0,}],
        boil_totl: 3,
        boil_not_w: 1
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99319107520812,
          46.97474123646213
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 13,
        comp: "ОКП",
        adrs_cot: "вул. Адміральська, 4-К",
        latitude: 46.97830097023245,
        longitude: 31.987799670939136,
        cot_tip: null,
        area_t: "2 РТМ",
        year_build: "1970",
        cot_cond: "окремо розташована, 1 пов. фундамент - б.блоки, стіни - цегла, з/б перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "54,81 C",
        t_output: "48,72 C",
        total_pow: "960,06 кВт",
        join_pow: "1 110,78 кВт",
        heat_area: "7 140,90 м2",
        gas_use: "134 522,13 м3",
        prod_pow: "1 377,2365 Гкал",
        kkd: "95%",
        rel_pow: "1 356,5762 Гкал",
        len_netw: "522,0 м",
        netw_loss: "0,52",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Адміральська, 4-К",
        boilers: [
            {name: "Buderus Logomax plus GB162-100 (водотрубний, конденсаційний)", power: "94,5", year_build: "2014", kkd: "97,8%", status_work: 1,},
          {name: "Buderus Logomax plus GB162-100 (водотрубний, конденсаційний)", power: "94,5", year_build: "2014", kkd: "97,8%", status_work: 1,},
          {name: "Buderus Logomax plus GB162-100 (водотрубний, конденсаційний)", power: "94,5", year_build: "2014", kkd: "97,8%", status_work: 1,},
          {name: "Buderus Logomax plus GB162-100 (водотрубний, конденсаційний)", power: "94,5", year_build: "2014", kkd: "97,8%", status_work: 1,},
          {name: "Buderus Logomax plus GB162-100 (водотрубний, конденсаційний)", power: "94,5", year_build: "2014", kkd: "97,8%", status_work: 1,},
          {name: "Buderus Logomax plus GB162-100 (водотрубний, конденсаційний)", power: "94,5", year_build: "2014", kkd: "97,8%", status_work: 1,},
          {name: "Buderus Logomax plus GB162-100 (водотрубний, конденсаційний)", power: "94,5", year_build: "2014", kkd: "97,8%", status_work: 1,},
          {name: "Buderus Logomax plus GB162-100 (водотрубний, конденсаційний)", power: "94,5", year_build: "2014", kkd: "97,7%", status_work: 1,},
          {name: "Buderus Logomax plus GB162-100 (водотрубний, конденсаційний)", power: "94,5", year_build: "2014", kkd: "97,7%", status_work: 1,},
          {name: "Buderus Logomax plus GB162-100 (водотрубний, конденсаційний)", power: "94,5", year_build: "2014", kkd: "97,7%", status_work: 1,},
          {name: "КЕО-6/220в (електричний)", power: "60", year_build: "?", status_work: 0 },
          {name: "КЕО-6/220в (електричний)", power: "60", year_build: "?", status_work: 0 },
          {name: "КЕО-6/220в (електричний)", power: "60", year_build: "?", status_work: 0 }
        ],
        boil_totl: 13,
        boil_not_w: 3
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.987799670939136,
          46.97830097023245
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 14,
        comp: "ОКП",
        adrs_cot: "вул. Нікольська, 34-А",
        latitude: 46.97735299533539,
        longitude: 31.98512697374097,
        cot_tip: null,
        area_t: "2 РТМ",
        year_build: "1971",
        cot_cond: "окремо розташована, 1 пов. фундамент - з/б, стіни - камінь, з/б перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "53,06 C",
        t_output: "38,40 C",
        total_pow: "500,16 кВт",
        join_pow: "224,11 кВт",
        heat_area: "4 784,30 м2",
        gas_use: "47 623,96 м3",
        prod_pow: "518,6130 Гкал",
        kkd: "91%",
        rel_pow: "499,2535 Гкал",
        len_netw: "396,0 м",
        netw_loss: "2,77",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Нікольська, 34-А",
        boilers: [{name: "МН120 'Бернард' (водотрубний)", power: "119,7", year_build: "2014", kkd: "92,0%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний)", power: "119,7", year_build: "2014", kkd: "92,0%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний)", power: "119,7", year_build: "2014", kkd: "92,2%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний)", power: "119,7", year_build: "2014", status_work: 0,},{name: "Котел КЕО-9/380в (електричний)", power: "9", year_build: "2014", status_work: 0},{name: "КЕО-12/380в (електричний)", power: "12", year_build: "2014", status_work: 0}],
        boil_totl: 6,
        boil_not_w: 3
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98512697374097,
          46.97735299533539
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        comp: "ОКП",
        adrs_cot: "вул. Велика Морська, 45-А",
        latitude: 46.97348627331328,
        longitude: 31.984105206964404,
        cot_tip: null,
        area_t: "2 РТМ",
        year_build: "1966",
        cot_cond: "підвальна, фундамент - копка котлована, стіни - камінь, перекриття - з/б плити",
        year_moder: null,
        mod_work: null,
        t_input: "50,80 C",
        t_output: "43,61 C",
        total_pow: "1 058,33 кВт",
        join_pow: "285,05 кВт",
        heat_area: "4 399,00 м2",
        gas_use: "67 571,67 м3",
        prod_pow: "409,4359 Гкал",
        kkd: "73%",
        rel_pow: "337,7119 Гкал",
        len_netw: "872,0 м",
        netw_loss: "16,69",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Велика Морська, 45-А",
        boilers: [{name: "НИИСТУ-5 (водотрубний)", power: "407,05", year_build: "1992", kkd: "89,1%", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "651,28", year_build: "1981", kkd: "90,4%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.984105206964404,
          46.97348627331328
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 16,
        comp: "ОКП",
        adrs_cot: "просп. Центральний, 96/7",
        latitude: 46.966825842112875,
        longitude: 32.0007657387056,
        cot_tip: null,
        area_t: "2 РТМ",
        year_build: "1985",
        cot_cond: "окремо розташована, 1 пов. фундамент - з/б блоки, стіни - цегла, перекриття - з/б плити",
        year_moder: null,
        mod_work: null,
        t_input: "52,56 C",
        t_output: "43,46 C",
        total_pow: "1 518,88 кВт",
        join_pow: "603,71 кВт",
        heat_area: "10 588,40 м2",
        gas_use: "140 158,25 м3",
        prod_pow: "928,2741 Гкал",
        kkd: "80%",
        rel_pow: "798,5389 Гкал",
        len_netw: "1 134,0 м",
        netw_loss: null,
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, просп. Центральний, 96/7",
        boilers: [{name: "НИИСТУ-5мк, (водотрубний)", power: "759,439", year_build: "2004", kkd: "88,5%", status_work: 1,},{name: "НИИСТУ-5мк, (водотрубний)", power: "759,439", year_build: "2004", kkd: "88,4%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0007657387056,
          46.966825842112875
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 17,
        comp: "ОКП",
        adrs_cot: "вул. Набережна, 25/1",
        latitude: 46.973474742508685,
        longitude: 32.00292057767497,
        cot_tip: null,
        area_t: "2 РТМ",
        year_build: "2010",
        cot_cond: "підвальна, фундамент - з/б, стіни - цегла, з/б перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "52,03 C",
        t_output: "43,00 C",
        total_pow: "1 953,84 кВт",
        join_pow: "662,56 кВт",
        heat_area: "13 493,25 м2",
        gas_use: "149 749,71 м3",
        prod_pow: "1 065,5671 Гкал",
        kkd: "86%",
        rel_pow: "906,3671 Гкал",
        len_netw: "1 170,0 м",
        netw_loss: null,
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Набережна, 25/1",
        boilers: [{name: "НИИСТУ-5мк (водотрубний)", power: "котла 976,92", year_build: "2003", kkd: "89,7%", status_work: 1,},{name: "НИИСТУ-5мк (водотрубний)", power: "котла 976,92", year_build: "2003", kkd: "88,5%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00292057767497,
          46.973474742508685
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 18,
        comp: "ОКП",
        adrs_cot: "вул. Потьомкінська, 22-В",
        latitude: 46.974245523343654,
        longitude: 31.98117568771371,
        cot_tip: null,
        area_t: "2 РТМ",
        year_build: "1987",
        cot_cond: "окремо розташована, 1 пов. фундамент - бутобетонний, стіни - цегла, з/б перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "52,62 C",
        t_output: "45,16 C",
        total_pow: "757,11 кВт",
        join_pow: "185,27 кВт",
        heat_area: "3 142,20 м2",
        gas_use: "33 933,34 м3",
        prod_pow: "295,1899 Гкал",
        kkd: "89%",
        rel_pow: "198,4400 Гкал",
        len_netw: "336,0 м",
        netw_loss: "32,1",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Потьомкінська, 22-В",
        boilers: [{name: "Универсал-5 (водотрубний)", power: "489,623", year_build: "1987", kkd: "83,7%", status_work: 1,},{name: "НИИСТУ-5мк", power: "267,49", year_build: "1987", kkd: "90,15%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98117568771371,
          46.974245523343654
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 19,
        comp: "ОКП",
        adrs_cot: "вул. Набережна, 5/11",
        latitude: 46.97621995522431,
        longitude: 31.997086762065315,
        cot_tip: null,
        area_t: "2 РТМ",
        year_build: "-",
        cot_cond: "вбудована, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "52,24 C",
        t_output: "44,95 C",
        total_pow: "267,49 кВт",
        join_pow: "132,23 кВт",
        heat_area: "2 750,50 м2",
        gas_use: "34 253,22 м3",
        prod_pow: "180,7315 Гкал",
        kkd: "64%",
        rel_pow: "178,9419 Гкал",
        len_netw: "0,0 м",
        netw_loss: "0",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Набережна, 5/11",
        boilers: [{name: "НИИСТУ-5, (водотрубний) ", power: "133,745", year_build: "2001", kkd: "88,8%", status_work: 1,},{name: "НИИСТУ-5, (водотрубний)", power: "133,745", year_build: "2001", kkd: "88,6%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.997086762065315,
          46.97621995522431
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        comp: "ОКП",
        adrs_cot: "вул. Адміральська, 31-К",
        latitude: 46.97366650274322,
        longitude: 31.99674603357112,
        cot_tip: null,
        area_t: "2 РТМ",
        year_build: "1973",
        cot_cond: "окремо розташована, цокольний, фундамент - з/б, стіни - камінь, з/б перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "52,37 C",
        t_output: "40,60 C",
        total_pow: "721,10 кВт",
        join_pow: "446,59 кВт",
        heat_area: "2 916,80 м2",
        gas_use: "96 594,41 м3",
        prod_pow: "659,0646 Гкал",
        kkd: "82%",
        rel_pow: "602,0503 Гкал",
        len_netw: "144,0 м",
        netw_loss: "7,74",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Адміральська, 31-К",
        boilers: [{name: "НИИСТУ-5мк, (водотрубний)", power: "360,53", year_build: "2005", kkd: "89,9%", status_work: 1,},{name: "НИИСТУ-5мк, (водотрубний)", power: "360,53", year_build: "2005", kkd: "90,4%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99674603357112,
          46.97366650274322
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 21,
        comp: "ОКП",
        adrs_cot: "вул. Нікольська, 9-А",
        latitude: 46.977992137180415,
        longitude: 31.977415026515754,
        cot_tip: null,
        area_t: "2 РТМ",
        year_build: "-",
        cot_cond: "вбудована, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "51,96 C",
        t_output: "41,37 C",
        total_pow: "353,55 кВт",
        join_pow: "183,75 кВт",
        heat_area: "2 494,30 м2",
        gas_use: "42 642,10 м3",
        prod_pow: "222,4262 Гкал",
        kkd: "63%",
        rel_pow: "163,5961 Гкал",
        len_netw: "257,0 м",
        netw_loss: "25,71",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Нікольська, 9-А",
        boilers: [{name: "НИИСТУ-5мкф, (водотрубний)", power: "176,776", year_build: "1990", kkd: "87,3%", status_work: 1,},{name: "НИИСТУ-5мкф, (водотрубний)", power: "176,776", year_build: "1990", kkd: "86,3%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.977415026515754,
          46.977992137180415
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 22,
        comp: "ОКП",
        adrs_cot: "вул. Садова, 2",
        latitude: 46.9706649,
        longitude: 32.0049444,
        cot_tip: null,
        area_t: "2 РТМ",
        year_build: "-",
        cot_cond: "вбудована, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "52,28 C",
        t_output: "44,64 C",
        total_pow: "586,15 кВт",
        join_pow: "153,28 кВт",
        heat_area: "3 336,11 м2",
        gas_use: "40 711,24 м3",
        prod_pow: "259,2593 Гкал",
        kkd: "77%",
        rel_pow: "239,1072 Гкал",
        len_netw: "100,0 м",
        netw_loss: "6,85",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Садова, 2",
        boilers: [{name: "НИИСТУ-5, (водотрубний)", power: "293,076", year_build: "1994", kkd: "89,8%", status_work: 1,},{name: "НИИСТУ-5, (водотрубний)", power: "293,076ік вир-ва: - 1994", kkd: "90,7%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0049444,
          46.9706649
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 23,
        comp: "ОКП",
        adrs_cot: "вул. Спаська, 46",
        latitude: 46.9731608029004,
        longitude: 31.99255435878127,
        cot_tip: null,
        area_t: "2 РТМ",
        year_build: "-",
        cot_cond: "вбудована, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "50,80 C",
        t_output: "43,40 C",
        total_pow: "1 622,38 кВт",
        join_pow: "329,48 кВт",
        heat_area: "5 311,50 м2",
        gas_use: "95 002,53 м3",
        prod_pow: "506,7677 Гкал",
        kkd: "64%",
        rel_pow: "462,0253 Гкал",
        len_netw: "310,0 м",
        netw_loss: "7,92",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Спаська, 46",
        boilers: [{name: "НИИСТУ-5, (водотрубний) ", power: "540,795", year_build: "1977", kkd: "82,8%", status_work: 1,},{name: "НИИСТУ-5, (водотрубний) ", power: "540,795", year_build: "1964", kkd: "83,8%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.992554358781266,
          46.9731608029004
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 24,
        comp: "ОКП",
        adrs_cot: "вул. Спаська, 55",
        latitude: 46.97123314999329,
        longitude: 31.99716682131012,
        cot_tip: null,
        area_t: "2 РТМ",
        year_build: "-",
        cot_cond: "вбудована, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "51,97 C",
        t_output: "45,78 C",
        total_pow: "353,55 кВт",
        join_pow: "396,93 кВт",
        heat_area: "2 894,42 м2",
        gas_use: "55 387,00 м3",
        prod_pow: "366,9282 Гкал",
        kkd: "80%",
        rel_pow: "350,9668 Гкал",
        len_netw: "262,0 м",
        netw_loss: "3,39",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Спаська, 55",
        boilers: [{name: "Универсал-5 (водотрубний)", power: "176,776", year_build: "1969", kkd: "84,7%", status_work: 1,},{name: "Универсал-5 (водотрубний)", power: "176,776", year_build: "1969", kkd: "82,9%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99716682131012,
          46.97123314999329
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 25,
        comp: "ОКП",
        adrs_cot: "вул. Спаська, 66-К",
        latitude: 46.971435550135645,
        longitude: 31.99900049139068,
        cot_tip: null,
        area_t: "2 РТМ",
        year_build: "1987",
        cot_cond: "окремо розташована, 1пов. фундамент - з/б блоки, стіни -- вапняк, перекриття - з/б плити",
        year_moder: null,
        mod_work: null,
        t_input: "52,26 C",
        t_output: "46,69 C",
        total_pow: "227,95 кВт",
        join_pow: "80,36 кВт",
        heat_area: "2 224,60 м2",
        gas_use: "21 002,01 м3",
        prod_pow: "86,2314 Гкал",
        kkd: "50%",
        rel_pow: "71,5734 Гкал",
        len_netw: "176,0 м",
        netw_loss: "16,17",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Спаська, 66-К",
        boilers: [{name: "НИИСТУ-5мк (водотрубний) ", power: "113,974", year_build: "1987", kkd: "87,8%", status_work: 1,},{name: "НИИСТУ-5мк (водотрубний) ", power: "113,974", year_build: "1987", kkd: "87,6%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99900049139068,
          46.971435550135645
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 26,
        comp: "ОКП",
        adrs_cot: "вул. Потьомкінська, 81/3",
        latitude: 46.96845214083647,
        longitude: 31.99590073808144,
        cot_tip: null,
        area_t: "2 РТМ",
        year_build: "-",
        cot_cond: "вбудована, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "52,12 C",
        t_output: "45,72 C",
        total_pow: "625,69 кВт",
        join_pow: "457,18 кВт",
        heat_area: "7 849,20 м2",
        gas_use: "106 041,18 м3",
        prod_pow: "654,7355 Гкал",
        kkd: "75%",
        rel_pow: "576,9688 Гкал",
        len_netw: "324,0 м",
        netw_loss: "11",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Потьомкінська, 81/3",
        boilers: [{name: "НИИСТУ-5мкф (водотрубний)", power: "312,847", year_build: "1998", kkd: "89,6%", status_work: 1,},{name: "НИИСТУ-5мкф (водотрубний)", power: "312,847", year_build: "1998", kkd: "89,3%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.995900738081442,
          46.968452140836476
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 27,
        comp: "ОКП",
        adrs_cot: "вул. Корабелів, 14-В",
        latitude: 46.959667254318624,
        longitude: 31.986590763240216,
        cot_tip: null,
        area_t: "2 РТМ",
        year_build: "1984",
        cot_cond: "окремо розташована, 1 пов.",
        year_moder: null,
        mod_work: null,
        t_input: "54,00 C",
        t_output: "43,00 C",
        total_pow: "1 125,78 кВт",
        join_pow: "99,55 кВт",
        heat_area: null,
        gas_use: "32 074,50 м3",
        prod_pow: "195,9244 Гкал",
        kkd: "73%",
        rel_pow: "191,4633 Гкал",
        len_netw: "0,0 м",
        netw_loss: "0",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Корабелів, 14-В",
        boilers: [{name: "НИИСТУ-5 (водотрубний)", power: "584,989", year_build: "2005", status_work: 0,},{name: "НИИСТУ-5 (водотрубний)", power: "540,795", year_build: "1987", kkd: "82,4%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 1
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.986590763240216,
          46.959667254318624
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 28,
        comp: "ОКП",
        adrs_cot: "вул. Мала Морська, 78/1",
        latitude: 46.95604876931571,
        longitude: 31.996168630490082,
        cot_tip: null,
        area_t: "3 РТМ",
        year_build: "-",
        cot_cond: "вбудована, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "51,08 C",
        t_output: "39,66 C",
        total_pow: "274,47 кВт",
        join_pow: "130,63 кВт",
        heat_area: "2 013,30 м2",
        gas_use: "27 821,38 м3",
        prod_pow: "209,9501 Гкал",
        kkd: "91%",
        rel_pow: "207,2565 Гкал",
        len_netw: "60,0 м",
        netw_loss: "0,3",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Мала Морська, 78/1",
        boilers: [{name: "Универсал-6 (водотрубний)", power: "137,234", year_build: "1976", kkd: "82,1%", status_work: 1,},{name: "Универсал-6 (водотрубний)", power: "137,234", year_build: "1976", kkd: "80,9%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.996168630490082,
          46.95604876931571
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 29,
        comp: "ОКП",
        adrs_cot: "просп. Центральний, 22-А/2",
        latitude: 46.968983580718515,
        longitude: 31.970810143050127,
        cot_tip: null,
        area_t: "3 РТМ",
        year_build: "1956",
        cot_cond: "вбудована, фундамент - фундаментні блоки, стіни - кам'яні блоки, перекриття - з/б плити",
        year_moder: null,
        mod_work: null,
        t_input: "51,89 C",
        t_output: "42,91 C",
        total_pow: "2 709,79 кВт",
        join_pow: "1 261,04 кВт",
        heat_area: "24 981,10 м2",
        gas_use: "323 509,49 м3",
        prod_pow: "2 124,9514 Гкал",
        kkd: "79%",
        rel_pow: "1 834,6835 Гкал",
        len_netw: "1 649,0 м",
        netw_loss: "12,8",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, просп. Центральний, 22-А/2",
        boilers: [{name: "НИИСТУ-5м (водотрубний)", power: "825,73", year_build: "1992", kkd: "88,9%", status_work: 1,},{name: "НИИСТУ-5мк-1у", power: "942,03", year_build: "2005", kkd: "89,1%", status_work: 1,},{name: "НИИСТУ-5мк-1у", power: "942,03", year_build: "2005", kkd: "89,0%", status_work: 1,}],
        boil_totl: 3,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.970810143050127,
          46.968983580718515
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 30,
        comp: "ОКП",
        adrs_cot: "вул. Спортивна, 1-К",
        latitude: 46.97339191067549,
        longitude: 31.96069197551218,
        cot_tip: null,
        area_t: "3 РТМ",
        year_build: "1975",
        cot_cond: "окремо розташована, 2пов. фундамент - з/б, стіни - цегла, перекриття - з/б плити",
        year_moder: 2020,
        mod_work: "Капітальний ремонт котла №1, заміна всіх пальників, заміна насосної групи, встановлення нової ХВП, встановлення частотних перетворювачів,",
        t_input: "62,57 C",
        t_output: "43,05 C",
        total_pow: "44 077,70 кВт",
        join_pow: "24 194,01 кВт",
        heat_area: "417 994,98 м2",
        gas_use: "5 190 207,06 м3",
        prod_pow: "39 054,3663 Гкал",
        kkd: "90%",
        rel_pow: "33 023,5645 Гкал",
        len_netw: "31 899,0 м",
        netw_loss: "13,58",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Спортивна, 1-К",
        boilers: [{name: "ТВГ-8М (водотрубний)", power: "9652,9", year_build: "1980", kkd: "85,1%", status_work: 1,},{name: "ТВГ-8М (водотрубний)", power: "9652,9", year_build: "1980", kkd: "93,1%", status_work: 1,},{name: "№3 ТВГ-8М", power: "9652,9", year_build: "1980", kkd: "93,6%", status_work: 1,},{name: "КВ-Г-7,56-150", power: "7559,5", year_build: "1988", kkd: "89,8%", status_work: 1,},{name: "КВ-Г-7,56-150", power: "7559,5", year_build: "-1988", kkd: "87,3%", status_work: 1,}],
        boil_totl: 5,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96069197551218,
          46.97339191067549
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 31,
        comp: "ОКП",
        adrs_cot: "вул. Нікольська, 6/1",
        latitude: 46.97712746376248,
        longitude: 31.969933874685545,
        cot_tip: null,
        area_t: "3 РТМ",
        year_build: "1996",
        cot_cond: "окремо розташована, 1пов. фундамент - бетон, стіни - цегла, перекриття - з/б плити",
        year_moder: null,
        mod_work: null,
        t_input: "54,31 C",
        t_output: "48,41 C",
        total_pow: "366,35 кВт",
        join_pow: "231,90 кВт",
        heat_area: "4 261,00 м2",
        gas_use: "38 894,20 м3",
        prod_pow: "255,7814 Гкал",
        kkd: "79%",
        rel_pow: "228,4960 Гкал",
        len_netw: "152,0 м",
        netw_loss: "9,77",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Нікольська, 6/1",
        boilers: [{name: "Модуль 105G (водотрубний)", power: "122,115", year_build: "2003", kkd: "90,2%", status_work: 1,},{name: "Модуль 105G (водотрубний)", power: "122,115", year_build: "2003", kkd: "90,2%", status_work: 1,},{name: "Модуль 105G (водотрубний)", power: "122,115", year_build: "2003", kkd: "90,2%", status_work: 1,}],
        boil_totl: 3,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.969933874685545,
          46.97712746376248
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 32,
        comp: "ОКП",
        adrs_cot: "пров. Транспортний, 6/2",
        latitude: 46.96395897007135,
        longitude: 31.965383583860735,
        cot_tip: null,
        area_t: "3 РТМ",
        year_build: "1977",
        cot_cond: "окремо розташована, 2пов. фундамент -  бетонні блоки, стіни - цегла, перекриття - з/б плити",
        year_moder: null,
        mod_work: null,
        t_input: "52,37 C",
        t_output: "41,34 C",
        total_pow: "3 035,43 кВт",
        join_pow: "2 001,64 кВт",
        heat_area: "36 672,28 м2",
        gas_use: "450 143,90 м3",
        prod_pow: "2 972,1004 Гкал",
        kkd: "80%",
        rel_pow: "2 535,1529 Гкал",
        len_netw: "4 014,0 м",
        netw_loss: "13,85",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, пров. Транспортний, 6/2",
        boilers: [{name: "НИИСТУ-5мкф (водотрубний)", power: "976,92", year_build: "2005", kkd: "88,9%", status_work: 1,},{name: "НИИСТУ-5мкф (водотрубний)", power: "976,92", year_build: "2005", kkd: "88,4%", status_work: 1,},{name: "НИИСТУ-5", power: "540,795", year_build: "1993", kkd: "83,2%", status_work: 1,},{name: "НИИСТУ-5", power: "540,795", year_build: "1993", status_work: 0,}],
        boil_totl: 4,
        boil_not_w: 1
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.965383583860735,
          46.96395897007135
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 33,
        comp: "ОКП",
        adrs_cot: "просп. Центральний, 24-К",
        latitude: 46.96969161054209,
        longitude: 31.972142789304616,
        cot_tip: null,
        area_t: "3 РТМ",
        year_build: "1985",
        cot_cond: "окремо розташована, 1пов. фундамент - камінь бутовий, стіни - камінь бутовий, перекриття - з/б плити",
        year_moder: null,
        mod_work: null,
        t_input: "52,02 C",
        t_output: "42,97 C",
        total_pow: "1 081,59 кВт",
        join_pow: "246,21 кВт",
        heat_area: "3 157,60 м2",
        gas_use: "67 646,05 м3",
        prod_pow: "457,3631 Гкал",
        kkd: "82%",
        rel_pow: "363,5279 Гкал",
        len_netw: "1 042,0 м",
        netw_loss: "19,72",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, просп. Центральний, 24-К",
        boilers: [{name: "НИИСТУ-5 (водотрубний)", power: "540,795", year_build: "1991", kkd: "83,7%", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "540,795", year_build: "1991", kkd: "82,2%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.972142789304616,
          46.9696916105421
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 34,
        comp: "ОКП",
        adrs_cot: "вул. Чорноморська, 1/1",
        latitude: 46.989291618349206,
        longitude: 31.94748624175298,
        cot_tip: null,
        area_t: "3 РТМ",
        year_build: "1996",
        cot_cond: "окремо розташована, 1пов. фундамент - бетон, стіни - цегла, перекриття - з/б плити",
        year_moder: null,
        mod_work: null,
        t_input: "56,10 C",
        t_output: "40,60 C",
        total_pow: "300,05 кВт",
        join_pow: "179,80 кВт",
        heat_area: "3 268,90 м2",
        gas_use: "39 415,39 м3",
        prod_pow: "288,6643 Гкал",
        kkd: "88%",
        rel_pow: "259,7800 Гкал",
        len_netw: "256,0 м",
        netw_loss: null,
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Чорноморська, 1/1",
        boilers: [{name: "АОГВ-100Э 'МАЯК' (водотрубний)", power: "100,018", year_build: "2011", kkd: "90,3%", status_work: 1,},{name: "АОГВ-100Э 'МАЯК' (водотрубний)", power: "100,018", year_build: "2011", kkd: "- 90,5%", status_work: 1,},{name: "АОГВ-100Э 'МАЯК' (водотрубний)", power: "100,018", year_build: "2011", kkd: "90,3%", status_work: 1,}],
        boil_totl: 3,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94748624175298,
          46.989291618349206
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 35,
        comp: "ОКП",
        adrs_cot: "вул. Олександра Матросова, 2/1",
        latitude: 46.99748063482188,
        longitude: 31.94599079077227,
        cot_tip: null,
        area_t: "3 РТМ",
        year_build: "1998",
        cot_cond: "окремо розташована, 1пов.  фундамент - бетон, стіни - залізо, перекриття - металеві",
        year_moder: null,
        mod_work: null,
        t_input: "58,89 C",
        t_output: "44,93 C",
        total_pow: "200,04 кВт",
        join_pow: "120,60 кВт",
        heat_area: "2 117,00 м2",
        gas_use: "28 507,34 м3",
        prod_pow: "203,9111 Гкал",
        kkd: "86%",
        rel_pow: "184,3400 Гкал",
        len_netw: "82,0 м",
        netw_loss: "8,69",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Олександра Матросова, 2/1",
        boilers: [{name: "АОГВ-100Э 'МАЯК' (водотрубний)", power: "100,018", year_build: "2009", kkd: "89,3%", status_work: 1,},{name: "АОГВ-100Э 'МАЯК' (водотрубний)", power: "100,018", year_build: "2009", kkd: "- 89,2%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94599079077227,
          46.997480634821876
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 36,
        comp: "ОКП",
        adrs_cot: "пров. Гастело, 14/1",
        latitude: 47.000582263483125,
        longitude: 31.943636636290577,
        cot_tip: null,
        area_t: "3 РТМ",
        year_build: "1980",
        cot_cond: "окремо розташована, 1пов. фундамент - бетон, стіни - цегла, перекриття - з/б плити",
        year_moder: null,
        mod_work: null,
        t_input: "50,20 C",
        t_output: "44,08 C",
        total_pow: "324,48 кВт",
        join_pow: "206,43 кВт",
        heat_area: "2 933,20 м2",
        gas_use: "38 806,42 м3",
        prod_pow: "294,2034 Гкал",
        kkd: "91%",
        rel_pow: "260,7878 Гкал",
        len_netw: "685,4 м",
        netw_loss: "10,47",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, пров. Гастело, 14/1",
        boilers: [{name: "АМЕ-108К (водотрубний) конденсаційний", power: "108,159", year_build: "2011", kkd: "93,7%", status_work: 1,},{name: "АМЕ-108К (водотрубний) конденсаційний", power: "108,159", year_build: "2011", kkd: "93,6%", status_work: 1,},{name: "АМЕ-108К (водотрубний) конденсаційний", power: "108,159", year_build: "2011", kkd: "93,8%", status_work: 1,}],
        boil_totl: 3,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.943636636290577,
          47.00058226348313
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 37,
        comp: "ОКП",
        adrs_cot: "вул. Молдавська, 7/1",
        latitude: 46.925313563360746,
        longitude: 31.97812302696802,
        cot_tip: "(сел. Мала Корениха)",
        area_t: "3 РТМ",
        year_build: "1988",
        cot_cond: "окремо розташована, 1пов. фундамент - бетон, стіни - цегла, перекриття - з/б плити",
        year_moder: null,
        mod_work: null,
        t_input: "57,71 C",
        t_output: "46,84 C",
        total_pow: "579,17 кВт",
        join_pow: "141,77 кВт",
        heat_area: "3 006,10 м2",
        gas_use: "40 628,41 м3",
        prod_pow: "270,8465 Гкал",
        kkd: "80%",
        rel_pow: "226,8990 Гкал",
        len_netw: "548,0 м",
        netw_loss: "15,39",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Молдавська, 7/1",
        boilers: [{name: "Рівнетерм-96 (водотрубний)", power: "96,529", year_build: "2004", kkd: "88,7%", status_work: 1,},{name: "Рівнетерм-96 (водотрубний)", power: "96,529", year_build: "2004", kkd: "88,6%", status_work: 1,},{name: "Рівнетерм-96 (водотрубний)", power: "96,529", year_build: "2004", kkd: "88,5%", status_work: 1,},{name: "Рівнетерм-96 (водотрубний)", power: "96,529", year_build: "2004", kkd: "88,8%", status_work: 1,},{name: "Рівнетерм-96 (водотрубний)", power: "96,529", year_build: "2004", kkd: "- 88,7%", status_work: 1,},{name: "Рівнетерм-96 (водотрубний)", power: "96,529", year_build: "2004", kkd: "- 88,3%", status_work: 1,}],
        boil_totl: 6,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97812302696802,
          46.925313563360746
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 38,
        comp: "ОКП",
        adrs_cot: "вул. 2 Екіпажна, 4-И",
        latitude: 46.97366041434117,
        longitude: 32.018422199536246,
        cot_tip: null,
        area_t: "4 РТМ",
        year_build: "1981",
        cot_cond: "окремо розташована, 1-2пов., фундамент - камінь бут., цегляні стіни, з/б плити перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "54,26 C",
        t_output: "41,98 C",
        total_pow: "5 815,00 кВт",
        join_pow: "3 016,12 кВт",
        heat_area: "33 759,40 м2",
        gas_use: "665 041,00 м3",
        prod_pow: "4 986,3144 Гкал",
        kkd: "89%",
        rel_pow: "4 141,8166 Гкал",
        len_netw: "5 814,0 м",
        netw_loss: null,
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. 2 Екіпажна, 4-И",
        boilers: [{name: "КБН-Г-2,5 (водотрубний)", power: "2907,5", year_build: "1987", kkd: "- 91,0%", status_work: 1,},{name: "КБН-Г-2,5 (водотрубний)", power: "2907,5", year_build: "1987", kkd: "- 88,6%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.018422199536246,
          46.97366041434117
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 39,
        comp: "ОКП",
        adrs_cot: "вул. Безіменна, 91-В",
        latitude: 46.99104869055096,
        longitude: 32.02477783040397,
        cot_tip: null,
        area_t: "4 РТМ",
        year_build: "1988",
        cot_cond: "окремо розташована, 1 пов., бетонний фундамент, цегляні стіни, з/б перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "53,81 C",
        t_output: "40,63 C",
        total_pow: "3 814,64 кВт",
        join_pow: "1 410,37 кВт",
        heat_area: "24 667,18 м2",
        gas_use: "398 859,17 м3",
        prod_pow: "2 342,3520 Гкал",
        kkd: "71%",
        rel_pow: "1 788,1238 Гкал",
        len_netw: "3 861,0 м",
        netw_loss: "22,9",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Безіменна, 91-В",
        boilers: [{name: "НИИСТУ-5мк (водотрубний)", power: "825,73", year_build: "2003", kkd: "88,7%", status_work: 1,},{name: "НИИСТУ-5мк", power: "825,73", year_build: "2003", kkd: "89,8%", status_work: 1,},{name: "НИИСТУ-5", power: "540,795", year_build: "1995", kkd: "80,3%", status_work: 1,},{name: "НИИСТУ-5", power: "540,795", year_build: "1994", kkd: "80,4%", status_work: 1,},{name: "НИИСТУ-5", power: "540,795", year_build: "1994", kkd: "81,2%", status_work: 1,},{name: "НИИСТУ-5", power: "540,795", year_build: "1992", kkd: "81,8%", status_work: 1,}],
        boil_totl: 6,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02477783040397,
          46.99104869055096
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 40,
        comp: "ОКП",
        adrs_cot: "вул. Котельна, 117-К",
        latitude: 46.97989307662933,
        longitude: 32.03158260415813,
        cot_tip: null,
        area_t: "4 РТМ",
        year_build: "1987",
        cot_cond: "окремо розташована, 1 пов., бетонний фундамент, цегляні стіни, з/б перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "52,40 C",
        t_output: "45,94 C",
        total_pow: "145,03 кВт",
        join_pow: "96,30 кВт",
        heat_area: "1 262,34 м2",
        gas_use: "18 318,08 м3",
        prod_pow: "143,8344 Гкал",
        kkd: "95%",
        rel_pow: "131,9870 Гкал",
        len_netw: "104,0 м",
        netw_loss: "7,32",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Котельна, 117-К",
        boilers: [{name: "Колві-Термона КТН 50СЕ (водотрубний)", power: "50", year_build: "2008", kkd: "85,3%", status_work: 1,},{name: "Колві-Термона КТН 50СЕ (водотрубний)", power: "50", year_build: "2008", kkd: "85,7%", status_work: 1,},{name: "АВПЕ ПР115 електричний", power: "45", year_build: "2014", status_work: 0,}],
        boil_totl: 3,
        boil_not_w: 1
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03158260415813,
          46.97989307662933
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 41,
        comp: "ОКП",
        adrs_cot: "вул. 2 Екіпажна, 5/1",
        latitude: 46.97327192015236,
        longitude: 32.01971262330559,
        cot_tip: null,
        area_t: "4 РТМ",
        year_build: "1948",
        cot_cond: "окремо розташована, 1пов., фундамент -камінь бут., стіни - камінь черепашник, з/б плити перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "52,16 C",
        t_output: "44,24 C",
        total_pow: "1 232,78 кВт",
        join_pow: "352,76 кВт",
        heat_area: "3 850,60 м2",
        gas_use: "90 622,95 м3",
        prod_pow: "620,3835 Гкал",
        kkd: "82%",
        rel_pow: "500,0098 Гкал",
        len_netw: "478,0 м",
        netw_loss: "17,53",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. 2 Екіпажна, 5/1",
        boilers: [{name: "НИИСТУ-5мк (водотрубний)", power: "267,49", year_build: "1996", kkd: "90,9%", status_work: 1,},{name: "НИИСТУ-5мк (водотрубний)", power: "290,75", year_build: "1996", kkd: "89,9%", status_work: 1,},{name: "НИИСТУ-5мк (водотрубний)", power: "219,6", year_build: "2004", kkd: "88,3%", status_work: 1,},{name: "НИИСТУ-5мк (водотрубний)", power: "453,57", year_build: "1996", status_work: 0,}],
        boil_totl: 4,
        boil_not_w: 1
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01971262330559,
          46.97327192015236
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 42,
        comp: "ОКП",
        adrs_cot: "вул. 3 Воєнна, 8/1",
        latitude: 46.973284373896064,
        longitude: 32.02266832610198,
        cot_tip: null,
        area_t: "4 РТМ",
        year_build: "1990",
        cot_cond: "окремо розташована, 1 пов., фундамент -камінь бут., цегляні стіни, з/б плити перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "51,10 C",
        t_output: "43,19 C",
        total_pow: "170,96 кВт",
        join_pow: "84,32 кВт",
        heat_area: "1 395,10 м2",
        gas_use: "23 907,82 м3",
        prod_pow: "124,5363 Гкал",
        kkd: "63%",
        rel_pow: "118,5700 Гкал",
        len_netw: "80,0 м",
        netw_loss: "3,84",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. 3 Воєнна, 8/1",
        boilers: [{name: "АОГВ-50Э 'МАЯК' (водотрубний)", power: "50", year_build: "2014", kkd: "89,9%", status_work: 1,},{name: "АОГВ-50Э 'МАЯК' (водотрубний)", power: "50", year_build: "2014", kkd: "89,8%", status_work: 1,},{name: "АОГВ-50Э 'МАЯК' (водотрубний)", power: "50", year_build: "2014", kkd: "89,8%", status_work: 1,},{name: "Буран 6 (електричний)", power: "6' , year_build: '?", status_work: 0,},{name: "Буран 15 (електричний)", power: "15", year_build: "?", status_work: 0}],
        boil_totl: 5,
        boil_not_w: 2
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02266832610198,
          46.973284373896064
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 43,
        comp: "ОКП",
        adrs_cot: "вул. 5 Воєнна, 42/2",
        latitude: 46.9745504,
        longitude: 32.0269005,
        cot_tip: null,
        area_t: "4 РТМ",
        year_build: "1961",
        cot_cond: "окремо розташована, 1 пов., кам'яний фундамент, кам'яні стіни, дерев'яне перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "50,60 C",
        t_output: "44,50 C",
        total_pow: "84,90 кВт",
        join_pow: "57,45 кВт",
        heat_area: "432,10 м2",
        gas_use: "10 383,86 м3",
        prod_pow: "74,3462 Гкал",
        kkd: "86%",
        rel_pow: "73,6100 Гкал",
        len_netw: "0,0 м",
        netw_loss: "0",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. 5 Воєнна, 42/2",
        boilers: [{name: "УІТ'Богдан'-55 (водотрубний)", power: "50", year_build: "2009", kkd: "91,0%", status_work: 1,},{name: "УІТ'Богдан'-40 (водотрубний)", power: "35", year_build: "2010", kkd: "90,2%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0269005,
          46.9745504
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 44,
        comp: "ОКП",
        adrs_cot: "пл. Адміралтейська, 2",
        latitude: 46.97509094048278,
        longitude: 31.993547480741093,
        cot_tip: null,
        area_t: "4 РТМ",
        year_build: "-",
        cot_cond: "вбудована, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "49,30 C",
        t_output: "44,60 C",
        total_pow: "625,69 кВт",
        join_pow: "164,10 кВт",
        heat_area: "2 465,40 м2",
        gas_use: "33 638,63 м3",
        prod_pow: "221,1900 Гкал",
        kkd: "79%",
        rel_pow: "219,0000 Гкал",
        len_netw: "100,0 м",
        netw_loss: "0",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, пл. Адміралтейська, 2",
        boilers: [{name: "НИИСТУ-5 (водотрубний)", power: "312,84", year_build: "1980", kkd: "89,2%", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "312,84", year_build: "1980", kkd: "90,5%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.993547480741093,
          46.97509094048278
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 45,
        comp: "ОКП",
        adrs_cot: "вул. Погранічна, 96",
        latitude: 46.95922186716631,
        longitude: 32.00548613621085,
        cot_tip: null,
        area_t: "4 РТМ",
        year_build: "-",
        cot_cond: "дах ж/будинку, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "54,64 C",
        t_output: "43,32 C",
        total_pow: "790,84 кВт",
        join_pow: "300,98 кВт",
        heat_area: "6 981,40 м2",
        gas_use: "60 041,23 м3",
        prod_pow: "358,8255 Гкал",
        kkd: "72%",
        rel_pow: "355,2729 Гкал",
        len_netw: "0,0 м",
        netw_loss: "0",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Погранічна, 96",
        boilers: [{name: "Протерм 120 СОР (водотрубний)", power: "98,855", year_build: "2003", kkd: "88,7%", status_work: 1,},{name: "Протерм 120 СОР (водотрубний)", power: "98,855", year_build: "2003", kkd: "89,2%", status_work: 1,},{name: "Протерм 120 СОР (водотрубний)", power: "98,855", year_build: "2003", kkd: "90,0%", status_work: 1,},{name: "Протерм 120 СОР (водотрубний)", power: "98,855", year_build: "2003", kkd: "89,9%", status_work: 1,},{name: "Протерм 120 СОР (водотрубний)", power: "98,855", year_build: "2003", kkd: "89,5%", status_work: 1,},{name: "Протерм 120 СОР (водотрубний)", power: "98,855", year_build: "2003", kkd: "89,6%", status_work: 1,},{name: "Протерм 120 СОР (водотрубний)", power: "98,855", year_build: "2003", kkd: "89,6%", status_work: 1,},{name: "Протерм 120 СОР (водотрубний)", power: "98,855", year_build: "2003", kkd: "89,6%", status_work: 1,}],
        boil_totl: 8,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00548613621085,
          46.95922186716631
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 46,
        comp: "ОКП",
        adrs_cot: "вул. Погранічна, 242-А/1",
        latitude: 46.9579578642323,
        longitude: 32.03135865483345,
        cot_tip: null,
        area_t: "4 РТМ",
        year_build: "-",
        cot_cond: "вбудована, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "49,01 C",
        t_output: "42,52 C",
        total_pow: "2 306,23 кВт",
        join_pow: "1 196,49 кВт",
        heat_area: "15 504,00 м2",
        gas_use: "230 570,13 м3",
        prod_pow: "1 620,6361 Гкал",
        kkd: "85%",
        rel_pow: "1 351,6338 Гкал",
        len_netw: "2 703,0 м",
        netw_loss: "15,76",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Погранічна, 242-А/1",
        boilers: [{name: "НИИСТУ-5мк (водотрубний)", power: "759,43", year_build: "1988", kkd: "88,5%", status_work: 1,},{name: "НИИСТУ-5мк (водотрубний)", power: "773,4", year_build: "1990", kkd: "87,3%", status_work: 1,},{name: "НИИСТУ-5мк (водотрубний)", power: "773,4", year_build: "1990", kkd: "87,8%", status_work: 1,}],
        boil_totl: 3,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03135865483345,
          46.9579578642323
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 47,
        comp: "ОКП",
        adrs_cot: "вул. Погранічна, 150/9-А",
        latitude: 46.959726901588255,
        longitude: 32.01401195552128,
        cot_tip: null,
        area_t: "4 РТМ",
        year_build: "1970",
        cot_cond: "окремо розташована, 1 пов., бетонний фундамент, цегляні стіни, з/б плити перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "52,84 C",
        t_output: "45,81 C",
        total_pow: "532,65 кВт",
        join_pow: "264,70 кВт",
        heat_area: "4 064,00 м2",
        gas_use: "73 642,31 м3",
        prod_pow: "463,0901 Гкал",
        kkd: "76%",
        rel_pow: "387,7663 Гкал",
        len_netw: "703,0 м",
        netw_loss: "15,43",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Погранічна, 150/9-А",
        boilers: [{name: "НИИСТУ -5 (водотрубний)", power: "266,32", year_build: "1986", kkd: "89,1%", status_work: 1,},{name: "НИИСТУ -5 (водотрубний)", power: "266,32", year_build: "1986", kkd: "89,1%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01401195552128,
          46.959726901588255
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 48,
        comp: "ОКП",
        adrs_cot: "вул. Чкалова, 209",
        latitude: 46.96035898760089,
        longitude: 32.029822894794364,
        cot_tip: null,
        area_t: "4 РТМ",
        year_build: "-",
        cot_cond: "вбудована, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "52,27 C",
        t_output: "44,89 C",
        total_pow: "1 300,23 кВт",
        join_pow: "688,96 кВт",
        heat_area: "8 769,10 м2",
        gas_use: "136 542,66 м3",
        prod_pow: "936,6401 Гкал",
        kkd: "83%",
        rel_pow: "790,3326 Гкал",
        len_netw: "1 432,0 м",
        netw_loss: "14,78",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Чкалова, 209",
        boilers: [{name: "НИИСТУ -5мк (водотрубний)", power: "650,11", year_build: "2003", kkd: "88,8%", status_work: 1,},{name: "НИИСТУ -5мк (водотрубний)", power: "650,11", year_build: "2003", kkd: "89,9%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.029822894794364,
          46.96035898760089
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 49,
        comp: "ОКП",
        adrs_cot: "вул. Кузнецька, 130/10",
        latitude: 46.95629931666628,
        longitude: 32.01373950156941,
        cot_tip: null,
        area_t: "4 РТМ",
        year_build: "1948",
        cot_cond: "вбудована, підвал, фундамент - камінь бут., копка котловану, стіни - камінь черепашник, з/б перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "50,75 C",
        t_output: "40,57 C",
        total_pow: "441,94 кВт",
        join_pow: "232,37 кВт",
        heat_area: "2 192,30 м2",
        gas_use: "35 869,57 м3",
        prod_pow: "258,0072 Гкал",
        kkd: "87%",
        rel_pow: "205,7649 Гкал",
        len_netw: "1 056,0 м",
        netw_loss: "19,45",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Кузнецька, 130/10",
        boilers: [{name: "Котел НИИСТУ -5 (водотрубний)", power: "220,97", year_build: "1980", kkd: "90,1%", status_work: 1,},{name: "Котел НИИСТУ -5 (водотрубний)", power: "220,97", year_build: "1980", kkd: "89,0%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01373950156941,
          46.95629931666628
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 50,
        comp: "ОКП",
        adrs_cot: "вул. Котельна, 8/1",
        latitude: 46.97210334947212,
        longitude: 32.018918200406716,
        cot_tip: null,
        area_t: "4 РТМ",
        year_build: "2008",
        cot_cond: "окремо розташована, 1 пов. , кам'яний  кам'яні стіни, з/б плити перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "64,22 C",
        t_output: "58,41 C",
        total_pow: "1 550,28 кВт",
        join_pow: "285,87 кВт",
        heat_area: "9 363,10 м2",
        gas_use: "94 475,77 м3",
        prod_pow: "706,4409 Гкал",
        kkd: "90%",
        rel_pow: "670,6600 Гкал",
        len_netw: "290,0 м",
        netw_loss: null,
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Котельна, 8/1",
        boilers: [{name: "Vissmann Vitoplex 100 (жаротрубний)", power: "310,52", year_build: "2009", kkd: "92,9%", status_work: 1,},{name: "Vissmann Vitoplex 100 (жаротрубний)", power: "619,87", year_build: "2009", kkd: "92,0%", status_work: 1,},{name: "Vissmann Vitoplex 100 (жаротрубний)", power: "619,87", year_build: "2009", kkd: "91,7%", status_work: 1,}],
        boil_totl: 3,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.018918200406716,
          46.97210334947212
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 51,
        comp: "ОКП",
        adrs_cot: "вул. 6 Слобідська, 48",
        latitude: 46.95990378137948,
        longitude: 32.02084411488704,
        cot_tip: null,
        area_t: "4 РТМ",
        year_build: "-",
        cot_cond: "дах ж/будинку, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "49,73 C",
        t_output: "38,75 C",
        total_pow: "718,73 кВт",
        join_pow: "301,91 кВт",
        heat_area: "6 638,40",
        gas_use: "68 182,70 м3",
        prod_pow: "428,7148 Гкал",
        kkd: "76%",
        rel_pow: "424,4701 Гкал",
        len_netw: "0,0 м",
        netw_loss: "0",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. 6 Слобідська, 48",
        boilers: [{name: "Модуль АF 105 (водотрубний)", power: "119,78", year_build: "2010", kkd: "-87,0%", status_work: 1,},{name: "Модуль АF 105 (водотрубний)", power: "119,78", year_build: "2010", kkd: "87,6%", status_work: 1,},{name: "Модуль АF 105 (водотрубний)", power: "119,78", year_build: "2010", kkd: "88,1%", status_work: 1,},{name: "Модуль АF 105 (водотрубний)", power: "119,78", year_build: "2010", kkd: "87,3%", status_work: 1,},{name: "Модуль АF 105 (водотрубний)", power: "119,78", year_build: "2010", kkd: "87,9", status_work: 1,},{name: "Модуль АF 105 (водотрубний)", power: "119,78", year_build: "2010", kkd: "87,8", status_work: 1,}],
        boil_totl: 6,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02084411488704,
          46.95990378137948
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 52,
        comp: "ОКП",
        adrs_cot: "вул. 6 Слобідська, 48-А",
        latitude: 46.9615903,
        longitude: 32.0240358,
        cot_tip: null,
        area_t: "4 РТМ",
        year_build: "-",
        cot_cond: "дах ж/будинку, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "53,31 C",
        t_output: "42,83 C",
        total_pow: "610,58 кВт",
        join_pow: "291,80 кВт",
        heat_area: "6 899,20",
        gas_use: "59 565,21 м3",
        prod_pow: "405,7369 Гкал",
        kkd: "82%",
        rel_pow: "401,7197 Гкал",
        len_netw: "0,0 м",
        netw_loss: "0",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. 6 Слобідська, 48-А",
        boilers: [{name: "Модуль 105G (водотрубний)", power: "122,1", year_build: "2010", kkd: "-89,7%", status_work: 1,},{name: "Модуль 105G (водотрубний)", power: "122,1", year_build: "2010", kkd: "90,2%", status_work: 1,},{name: "Модуль 105G (водотрубний)", power: "122,1", year_build: "2010", kkd: "90,2%", status_work: 1,},{name: "Модуль 105G (водотрубний)", power: "122,1", year_build: "2010", kkd: "89,2%", status_work: 1,},{name: "Модуль 105G (водотрубний)", power: "122,1", year_build: "2010", kkd: "89,5%", status_work: 1,}],
        boil_totl: 5,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0240358,
          46.9615903
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 53,
        comp: "ОКП",
        adrs_cot: "вул. 11-А Повздовжня, 1",
        latitude: 46.9543518,
        longitude: 32.0402034,
        cot_tip: null,
        area_t: "5 РТМ",
        year_build: "-",
        cot_cond: "вбудована, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "49,00 C",
        t_output: "39,75 C",
        total_pow: "279,12 кВт",
        join_pow: "172,12 кВт",
        heat_area: "2 474,50 м2",
        gas_use: "44 544,58 м3",
        prod_pow: "262,9774 Гкал",
        kkd: "71%",
        rel_pow: "232,4074 Гкал",
        len_netw: "210,0 м",
        netw_loss: "10,74",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. 11-А Повздовжня, 1",
        boilers: [{name: "НИИСТУ -5мк (водотрубний)", power: "139,56", year_build: "1969", kkd: "89,8%", status_work: 1,},{name: "НИИСТУ -5мк (водотрубний)", power: "139,56", year_build: "1977", kkd: "87,6%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0402034,
          46.9543518
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 54,
        comp: "ОКП",
        adrs_cot: "вул. 8 Повздовжня, 11-В",
        latitude: 46.9527197,
        longitude: 32.0510343,
        cot_tip: null,
        area_t: "5 РТМ",
        year_build: "1982",
        cot_cond: "окремо розташована, 1 пов., фундамент- камінь, кам'яні стіни, цегляні перегородки, з/б плити перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "51,51 C",
        t_output: "41,05 C",
        total_pow: "4 128,65 кВт",
        join_pow: "845,38 кВт",
        heat_area: "16 013,80 м2",
        gas_use: "198 904,38 м3",
        prod_pow: "1 278,0766 Гкал",
        kkd: "78%",
        rel_pow: "1 053,3970 Гкал",
        len_netw: "1 236,0 м",
        netw_loss: "16,76",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. 8 Повздовжня, 11-В",
        boilers: [{name: "НИИСТУ -5м (водотрубний)", power: "825,73", year_build: "2001", kkd: "91,5%", status_work: 1,},{name: "НИИСТУ -5м (водотрубний)", power: "825,73", year_build: "2001", kkd: "90,2%", status_work: 1,},{name: "НИИСТУ -5м (водотрубний)", power: "825,73", year_build: "2000", kkd: "89,1%", status_work: 1,},{name: "НИИСТУ -5м (водотрубний)", power: "825,73", year_build: "2002", kkd: "90,1%", status_work: 1,},{name: "НИИСТУ -5м (водотрубний)", power: "825,73", year_build: "2002", kkd: "88,6%", status_work: 1,}],
        boil_totl: 5,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0510343,
          46.9527197
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 55,
        comp: "ОКП",
        adrs_cot: "вул. Казарського, 2-К",
        latitude: 46.98449359764035,
        longitude: 32.0502579444882,
        cot_tip: null,
        area_t: "5 РТМ",
        year_build: "2001",
        cot_cond: "окремо розташована, 1 пов., бетонний фундамент, цегляні стіни, з/б плити перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "54,31 C",
        t_output: "43,68 C",
        total_pow: "5 815,00 кВт",
        join_pow: "2 428,34 кВт",
        heat_area: "52 748,20 м2",
        gas_use: "500 292,67 м3",
        prod_pow: "3 826,6870 Гкал",
        kkd: "92%",
        rel_pow: "3 359,2524 Гкал",
        len_netw: "2 526,0 м",
        netw_loss: "11,34",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Казарського, 2-К",
        boilers: [{name: "КВ-Г-2,9-115СН ' Дрозд' (водотрубний)", power: "2907,5", year_build: "2006", kkd: "92,6%", status_work: 1,},{name: "КВ-Г-2,9-115СН ' Дрозд' (водотрубний)", power: "2907,5", year_build: "2006", kkd: "93,2%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0502579444882,
          46.98449359764035
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 56,
        comp: "ОКП",
        adrs_cot: "вул. Новозаводська, 48",
        latitude: 46.969084983682045,
        longitude: 32.08583534525373,
        cot_tip: null,
        area_t: "5 РТМ",
        year_build: "1980",
        cot_cond: "окремо розташована, 1 пов., з/б фундамент, з/б стіни, з/б перекриття",
        year_moder: 2021,
        mod_work: "Заміна насосної групи, встановлення нової ХВП, заміна вікон, заміна всіх котлів, встановлення частотних перетворювачів",
        t_input: "60,00 C",
        t_output: "48,00 C",
        total_pow: "49 299,57 кВт",
        join_pow: "18 920,38 кВт",
        heat_area: "549 888,08 м2",
        gas_use: "5 251 471,41 м3",
        prod_pow: "34 652,0110 Гкал",
        kkd: "79%",
        rel_pow: "30 339,6271 Гкал",
        len_netw: "33 361,0 м",
        netw_loss: "10,47",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Новозаводська, 48",
        boilers: [{name: "Riello RTQ-10000T (водотрубний)", power: "10000", year_build: "2021", status_work: 1,},{name: "Riello RTQ-10000T (водотрубний)", power: "10000", year_build: "2021", status_work: 1,},{name: "Riello RTQ-10000T (водотрубний)", power: "10000", year_build: "2021", status_work: 1,}],
        boil_totl: 3,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.08583534525373,
          46.96908498368205
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 57,
        comp: "ОКП",
        adrs_cot: "вул. Листопадова, 1-К",
        latitude: 46.96461274795099,
        longitude: 32.06660900386063,
        cot_tip: null,
        area_t: "5 РТМ",
        year_build: "1975",
        cot_cond: "окремо розташована, 1-2 пов., бетонний фундамент, цегляні стіни, з/б плити перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "52,65 C",
        t_output: "40,08 C",
        total_pow: "3 907,68 кВт",
        join_pow: "2 018,04 кВт",
        heat_area: "41 578,30 м2",
        gas_use: "519 421,48 м3",
        prod_pow: "3 305,2421 Гкал",
        kkd: "77%",
        rel_pow: "2 688,8470 Гкал",
        len_netw: "1 766,0 м",
        netw_loss: "17,84",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Листопадова, 1-К",
        boilers: [{name: "НИИСТУ-5 (водотрубний)", power: "976,92", year_build: "1999", kkd: "88,6%", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "976,92", year_build: "1999", kkd: "88,2%", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "976,92", year_build: "1999", kkd: "84,2%", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "976,92", year_build: "1999", kkd: "88,3%", status_work: 1,}],
        boil_totl: 4,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06660900386063,
          46.96461274795098
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 58,
        comp: "ОКП",
        adrs_cot: "вул. Олександра Янати, 70-В",
        latitude: 46.97261776515787,
        longitude: 32.06329611776366,
        cot_tip: null,
        area_t: "5 РТМ",
        year_build: "1980",
        cot_cond: "окремо розташована, 1 пов., бетонні фундаментні блоки, цегляні стіни, з/б плити перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "52,22 C",
        t_output: "42,10 C",
        total_pow: "532,65 кВт",
        join_pow: "153,40 кВт",
        heat_area: "2 420,60 м2",
        gas_use: "37 409,51 м3",
        prod_pow: "246,0629 Гкал",
        kkd: "79%",
        rel_pow: "185,5820 Гкал",
        len_netw: "200,0 м",
        netw_loss: "23,83",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Олександра Янати, 70-В",
        boilers: [{name: "НИИСТУ-5мк-2ф (водотрубний)", power: "266,327", year_build: "2003", kkd: "90,9%", status_work: 1,},{name: "НИИСТУ-5мк-2ф (водотрубний)", power: "266,327", year_build: "2003", kkd: "90,5%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06329611776366,
          46.97261776515787
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 59,
        comp: "ОКП",
        adrs_cot: "вул. Китобоїв, 7-А",
        latitude: 46.94205718645041,
        longitude: 32.061978336759786,
        cot_tip: null,
        area_t: "5 РТМ",
        year_build: "1976",
        cot_cond: "окремо розташована, 1-2 пов., стрічкові збірні фундаменти - бетонні блоки, цегляні стіни, з/б плити перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "59,90 C",
        t_output: "42,70 C",
        total_pow: "37 797,50 кВт",
        join_pow: "19 083,09 кВт",
        heat_area: "403 442,93 м2",
        gas_use: "4 483 641,67 м3",
        prod_pow: "31 041,5277 Гкал",
        kkd: "83%",
        rel_pow: "26 420,8525 Гкал",
        len_netw: "14 722,6 м",
        netw_loss: null,
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Китобоїв, 7-А",
        boilers: [{name: "КВ-Г-7,56-150 (водотрубний)", power: "7559,5", year_build: "1995", kkd: "88,5%", status_work: 1,},{name: "КВ-Г-7,56-150 (водотрубний)", power: "7559,5", year_build: "1984", kkd: "86,7%", status_work: 1,},{name: "КВ-Г-7,56-150 (водотрубний)", power: "7559,5", year_build: "1984", kkd: "89,5%", status_work: 1,},{name: "КВ-Г-7,56-150 (водотрубний)", power: "7559,5", year_build: "1983", kkd: "86,4%", status_work: 1,},{name: "КВ-Г-7,56-150 (водотрубний)", power: "7559,5", year_build: "1985", kkd: "89,5%", status_work: 1,}],
        boil_totl: 5,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.061978336759786,
          46.94205718645041
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 60,
        comp: "ОКП",
        adrs_cot: "вул. Дмитра Яворницького, 2-В",
        latitude: 46.933333627123766,
        longitude: 32.059243018908425,
        cot_tip: null,
        area_t: "5 РТМ",
        year_build: "1979",
        cot_cond: "окремо розташована, 1 пов., бетониий фундамент, цегляні стіни, з/б плити перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "52,06 C",
        t_output: "42,77 C",
        total_pow: "721,06 кВт",
        join_pow: "465,90 кВт",
        heat_area: "9 091,20 м2",
        gas_use: "108 838,18 м3",
        prod_pow: "744,8584 Гкал",
        kkd: "83%",
        rel_pow: "661,9221 Гкал",
        len_netw: "584,0 м",
        netw_loss: "10,25",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Дмитра Яворницького, 2-В",
        boilers: [{name: "НИИСТУ-5мк-2 (водотрубний)", power: "360,53", year_build: "2005", kkd: "91,4%", status_work: 1,},{name: "НИИСТУ-5мк-2 (водотрубний)", power: "360,53", year_build: "2005", kkd: "91,7%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.059243018908425,
          46.933333627123766
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 61,
        comp: "ОКП",
        adrs_cot: "пров. Дунайської флотилії, 5-А",
        latitude: 46.84990649254683,
        longitude: 32.02354130576562,
        cot_tip: null,
        area_t: "6 РТМ",
        year_build: "1983",
        cot_cond: "окремо розташована, 1 пов.,  фундамент-бетон, стіни-цегла, покрівля-рубероїд",
        year_moder: null,
        mod_work: null,
        t_input: "51,30 C",
        t_output: "43,77 C",
        total_pow: "1 286,28 кВт",
        join_pow: "496,25 кВт",
        heat_area: "11 384,40 м2",
        gas_use: "141 574,91 м3",
        prod_pow: "908,0286 Гкал",
        kkd: "77%",
        rel_pow: "780,4991 Гкал",
        len_netw: "838,0 м",
        netw_loss: "13,19",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, пров. Дунайської флотилії, 5-А",
        boilers: [{name: "НИИСТУ-5мк (водотрубний)", power: "643,139", year_build: "2005", kkd: "89,5%", status_work: 1,},{name: "НИИСТУ-5мк (водотрубний)", power: "643,139", year_build: "2005", kkd: "89,2%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02354130576562,
          46.84990649254683
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 62,
        comp: "ОКП",
        adrs_cot: "вул. Металургів, 72/1",
        latitude: 46.867033815418026,
        longitude: 32.017900957667145,
        cot_tip: null,
        area_t: "6 РТМ",
        year_build: "2001",
        cot_cond: "окремо розташована, 1 пов.,  фундамент-з/бетон, стіни-цегла, покрівля-рубероїд",
        year_moder: null,
        mod_work: null,
        t_input: "47,41 C",
        t_output: "37,25 C",
        total_pow: "610,58 кВт",
        join_pow: "290,63 кВт",
        heat_area: "4 306,20 м2",
        gas_use: "51 029,14 м3",
        prod_pow: "351,5624 Гкал",
        kkd: "83%",
        rel_pow: "337,5703 Гкал",
        len_netw: "132,0 м",
        netw_loss: "3,02",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Металургів, 72/1",
        boilers: [{name: "Модуль 105G (водотрубний)", power: "122,1", year_build: "2001", kkd: "-91,3%", status_work: 1,},{name: "Модуль 105G (водотрубний)", power: "122,1", year_build: "2001", kkd: "91,2%", status_work: 1,},{name: "Модуль 105G (водотрубний)", power: "122,1", year_build: "2001", kkd: "91,5%", status_work: 1,},{name: "Модуль 105G (водотрубний)", power: "122,1", year_build: "2001", kkd: "91,5%", status_work: 1,},{name: "Модуль 105G (водотрубний)", power: "122,1", year_build: "2001", kkd: "91,5%", status_work: 1,}],
        boil_totl: 5,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.017900957667145,
          46.867033815418026
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 63,
        comp: "ОКП",
        adrs_cot: "просп. Богоявленський, 325/4-1",
        latitude: 46.85134205188831,
        longitude: 32.008148324881574,
        cot_tip: null,
        area_t: "6 РТМ",
        year_build: "2003",
        cot_cond: "окремо розташована, 1 пов.,  фундамент-бетон, стіни-цегла, покрівля-рубероїд",
        year_moder: null,
        mod_work: null,
        t_input: "52,17 C",
        t_output: "43,73 C",
        total_pow: "1 297,91 кВт",
        join_pow: "457,29 кВт",
        heat_area: "11 765,00 м2",
        gas_use: "106 930,99 м3",
        prod_pow: "735,9630 Гкал",
        kkd: "83%",
        rel_pow: "677,7182 Гкал",
        len_netw: "128,0 м",
        netw_loss: "6,99",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, просп. Богоявленський, 325/4-1",
        boilers: [{name: "МН120'Бернард' (водотрубний) ", power: "108,15", year_build: "2003", kkd: "91,1%", status_work: 1,},{name: "МН120'Бернард' (водотрубний) ", power: "108,15", year_build: "2003", kkd: "90,9%", status_work: 1,},{name: "МН120'Бернард' (водотрубний) ", power: "108,15", year_build: "2003", kkd: "90,9%", status_work: 1,},{name: "МН120'Бернард' (водотрубний) ", power: "108,15", year_build: "2003", kkd: "90,6%", status_work: 1,},{name: "МН120'Бернард' (водотрубний) ", power: "108,15", year_build: "2003", kkd: "90,6%", status_work: 1,},{name: "МН120'Бернард' (водотрубний) ", power: "108,15", year_build: "2003", kkd: "90,6%", status_work: 1,},{name: "МН120'Бернард' (водотрубний) ", power: "108,15", year_build: "2003", kkd: "91,2%", status_work: 1,},{name: "МН120'Бернард' (водотрубний) ", power: "108,15", year_build: "2003", kkd: "90,8%", status_work: 1,},{name: "МН120'Бернард' (водотрубний) ", power: "108,15", year_build: "2003", kkd: "91,0%", status_work: 1,},{name: "МН120'Бернард' (водотрубний)", power: "108,15", year_build: "2003", kkd: "90,7%", status_work: 1,},{name: "МН120'Бернард' (водотрубний)", power: "108,15", year_build: "2003", kkd: "90,7%", status_work: 1,},{name: "МН120'Бернард' (водотрубний)", power: "108,15", year_build: "2003", kkd: "90,7%", status_work: 1,}],
        boil_totl: 12,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.008148324881574,
          46.85134205188831
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 64,
        comp: "ОКП",
        adrs_cot: "вул. Рибна, 91-К",
        latitude: 46.8858254033165,
        longitude: 32.01674607798781,
        cot_tip: null,
        area_t: "6 РТМ",
        year_build: "1977",
        cot_cond: "окремо розташована, 1 пов.,  фундамент-бетон, стіни-цегла, покрівля-толь у один шар",
        year_moder: null,
        mod_work: null,
        t_input: "52,37 C",
        t_output: "45,78 C",
        total_pow: "2 163,18 кВт",
        join_pow: "947,73 кВт",
        heat_area: "13 447,50 м2",
        gas_use: "209 369,52 м3",
        prod_pow: "1 405,1730 Гкал",
        kkd: "81%",
        rel_pow: "1 213,6490 Гкал",
        len_netw: "1 370,0 м",
        netw_loss: "12,77",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Рибна, 91-К",
        boilers: [{name: "НИИСТУ-5 (водотрубний)", power: "540,79", year_build: "1987", kkd: "83,7%", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "540,79", year_build: "1987", kkd: "82,5%", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "540,79", year_build: "1987", kkd: "82,7%", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "540,79", year_build: "1987", kkd: "83,2%", status_work: 1,}],
        boil_totl: 4,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01674607798781,
          46.8858254033165
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 65,
        comp: "ОКП",
        adrs_cot: "вул. Гетьмана Сагайдачного, 124/1",
        latitude: 46.86849925928885,
        longitude: 32.02371607395103,
        cot_tip: null,
        area_t: "6 РТМ",
        year_build: "-",
        cot_cond: "вбудована, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "47,83 C",
        t_output: "37,01 C",
        total_pow: "377,98 кВт",
        join_pow: "149,98 кВт",
        heat_area: "1 645,90 м2",
        gas_use: "62 700,00 м3",
        prod_pow: "206,0396 Гкал",
        kkd: "64%",
        rel_pow: "203,9996 Гкал",
        len_netw: "0,0 м",
        netw_loss: "0",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Гетьмана Сагайдачного, 124/1",
        boilers: [{name: "НИИСТУ-5 (водотрубний)", power: "377,97", year_build: "1971", kkd: "68,6%", status_work: 1,}],
        boil_totl: 1,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02371607395103,
          46.86849925928885
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 66,
        comp: "ОКП",
        adrs_cot: "вул. Самойловича, 42",
        latitude: 46.859137576139275,
        longitude: 32.032241761915635,
        cot_tip: null,
        area_t: "6 РТМ",
        year_build: "2009",
        cot_cond: "окремо розташована , 3 пов.,  фундамент-бетон, стіни-з/бетон, покрівля-з/бетон та рубероїд, перекриття-з/бетонні плити, підлога-цементна",
        year_moder: 2021,
        mod_work: "Встановлення двох нових котлів, заміна ХВП, заміна насосної групи, заміна пальників на котлі №1",
        t_input: "64,73 C",
        t_output: "44,09 C",
        total_pow: "104 670,00 кВт",
        join_pow: "51 945,28 кВт",
        heat_area: "1 067 119,67 м2",
        gas_use: "11 798 459,48 м3",
        prod_pow: "92 182,5511 Гкал",
        kkd: "93%",
        rel_pow: "73 419,5786 Гкал",
        len_netw: "70 544,9 м",
        netw_loss: "18,6",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Самойловича, 42",
        boilers: [{name: "ПТВМ-30М-4 (водотрубний)", power: "34890", year_build: "1978", kkd: "89,8%", status_work: 1,},{name: "ПТВМ-30М-4 (водотрубний)", power: "34890", year_build: "1978", kkd: "91,0%", status_work: 1,},{name: "ПТВМ-30М-4 (водотрубний)", power: "34890", year_build: "1978", kkd: "91,5%", status_work: 1,},{name: "Viessmann Vitomax D-HW (жаротрубний)", power: "23294,89", year_build: "2021", status_work: 1,},{name: "Viessmann Vitomax D-HW (жаротрубний)", power: "23294,89", year_build: "2021", status_work: 1,}],
        boil_totl: 5,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.032241761915635,
          46.85913757613927
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 67,
        comp: "ОКП",
        adrs_cot: "вул. Металургів, 97/1",
        latitude: 46.86698573012174,
        longitude: 32.02445169605218,
        cot_tip: null,
        area_t: "6 РТМ",
        year_build: "2009",
        cot_cond: "окремо роташована, модульна котельна, металеві контейнери",
        year_moder: null,
        mod_work: null,
        t_input: "59,20 C",
        t_output: "44,50 C",
        total_pow: "593,13 кВт",
        join_pow: "475,55 кВт",
        heat_area: "8 221,20 м2",
        gas_use: "87 248,82 м3",
        prod_pow: "638,3393 Гкал",
        kkd: "88%",
        rel_pow: "626,1700 Гкал",
        len_netw: "37,2 м",
        netw_loss: "0,93",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Металургів, 97/1",
        boilers: [{name: "FERROLI Pegasus F2N102T (водотрубний)", power: "98,85", year_build: "2009", kkd: "90,7%", status_work: 1,},{name: "FERROLI Pegasus F2N102T (водотрубний)", power: "98,85", year_build: "2009", kkd: "90,3%", status_work: 1,},{name: "FERROLI Pegasus F2N102T (водотрубний)", power: "98,85", year_build: "2009", kkd: "89,9%", status_work: 1,},{name: "FERROLI Pegasus F2N102T (водотрубний)", power: "98,85", year_build: "2009", kkd: "89,6%", status_work: 1,},{name: "FERROLI Pegasus F2N102T (водотрубний)", power: "98,85", year_build: "2009", kkd: "89,7%", status_work: 1,},{name: "FERROLI Pegasus F2N102T (водотрубний)", power: "98,85", year_build: "2009", kkd: "89,6%", status_work: 1,}],
        boil_totl: 6,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02445169605218,
          46.86698573012175
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 68,
        comp: "ОКП",
        adrs_cot: "просп. Героїв України, 21А-1",
        latitude: 46.9957884,
        longitude: 31.998295,
        cot_tip: null,
        area_t: "7 РТМ",
        year_build: "1972",
        cot_cond: "окремо розташована, 1-2 пов., фундамент - камінь бут, цегляні стіни, з/б плити перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "58,47 C",
        t_output: "40,73 C",
        total_pow: "22 678,50 кВт",
        join_pow: "8 183,33 кВт",
        heat_area: "177 460,50 м2",
        gas_use: "1 711 879,39 м3",
        prod_pow: "11 925,4266 Гкал",
        kkd: "83%",
        rel_pow: "10 145,5931 Гкал",
        len_netw: "6 566,0 м",
        netw_loss: null,
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, просп. Героїв України, 21А-1",
        boilers: [{name: "КВ-Г-7,56-150 (водотрубний)", power: "7559,5", year_build: "1993", kkd: "91,1%", status_work: 1,},{name: "КВ-Г-7,56-150 (водотрубний)", power: "7559,5", year_build: "1986", kkd: "87,6%", status_work: 1,},{name: "КВ-Г-7,56-150 (водотрубний)", power: "7559,5", year_build: "1993", kkd: "85,5%", status_work: 1,}],
        boil_totl: 3,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.998295,
          46.9957884
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 69,
        comp: "ОКП",
        adrs_cot: "просп. Героїв України, 12",
        latitude: 46.99914279204728,
        longitude: 31.996855864910163,
        cot_tip: null,
        area_t: "7 РТМ",
        year_build: "-",
        cot_cond: "вбудована, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "51,89 C",
        t_output: "40,20 C",
        total_pow: "1 628,20 кВт",
        join_pow: "969,36 кВт",
        heat_area: "15 268,18 м2",
        gas_use: "206 279,49 м3",
        prod_pow: "1 593,7182 Гкал",
        kkd: "93%",
        rel_pow: "1 239,7701 Гкал",
        len_netw: "856,0 м",
        netw_loss: "21,43",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, просп. Героїв України, 12",
        boilers: [{name: "НИИСТУ-5мк (водотрубний)", power: "814,1", year_build: "2005", kkd: "88,6%", status_work: 1,},{name: "НИИСТУ-5мк (водотрубний)", power: "814,1", year_build: "2005", kkd: "88,3%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.996855864910163,
          46.99914279204728
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 70,
        comp: "ОКП",
        adrs_cot: "просп. Героїв України, 72-А",
        latitude: 47.0050515,
        longitude: 31.9968626,
        cot_tip: "(КЕЧ)",
        area_t: "7 РТМ",
        year_build: "1976",
        cot_cond: "окремо розташована, 1 пов., з/б фундамент, цегляні стіни, з/б колони, з/б плити перекриття, вікна замінені",
        year_moder: 2020,
        mod_work: "Заміна насосної групи, встановлення нової ХВП, заміна вікон, заміна пальників, заміна котла №1, ремонт покрівлі котельні",
        t_input: "59,10 C",
        t_output: "42,40 C",
        total_pow: null,
        join_pow: "8 182,98 кВт",
        heat_area: "129 441,56 м2",
        gas_use: "1 702 111,83 м3",
        prod_pow: "12 844,2205 Гкал",
        kkd: "91%",
        rel_pow: "11 034,9615 Гкал",
        len_netw: "12 013,2 м",
        netw_loss: "12,71",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, просп. Героїв України, 72-А",
        boilers: [{name: "Vissmann Vitomax LW M62C (жаротрубний)", power: "4291,47", year_build: "2020", status_work: 1,},{name: "Vissmann Vitomax LW M62C (жаротрубний)", power: "4291,47", year_build: "2020", status_work: 1,},{name: "Vissmann Vitomax LW M62C (жаротрубний)", power: "4291,47", year_build: "2020", status_work: 1,}],
        boil_totl: 3,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9968626,
          47.0050515
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 71,
        comp: "ОКП",
        adrs_cot: "вул. Малко-Тирнівська, 1-Б",
        latitude: 47.01649246905517,
        longitude: 31.998533873941206,
        cot_tip: null,
        area_t: "7 РТМ",
        year_build: "1992",
        cot_cond: "окремо розташована, 1-2 пов., з/б фундамент, цегляні стіни, з/б плити перекриття, вікна замінені",
        year_moder: null,
        mod_work: null,
        t_input: "58,95 C",
        t_output: "39,63 C",
        total_pow: "7 065,23 кВт",
        join_pow: "план покупне",
        heat_area: "98 537,85 м2",
        gas_use: "760 424,16 м3",
        prod_pow: "5 377,6330 Гкал",
        kkd: "85%",
        rel_pow: "4 886,3798 Гкал",
        len_netw: "4 566,0 м",
        netw_loss: "8,37",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Малко-Тирнівська, 1-Б",
        boilers: [{name: "КБН-Г-2,5 (водотрубний)", power: "2907,5", year_build: "1989", kkd: "92,2%", status_work: 1,},{name: "КВ-Г-2,9-115СН 'Дрозд'", power: "2907,5", year_build: "2006", kkd: "93,0%", status_work: 1,},{name: "КВМУ-1,25гн (жаротрубний)", power: "1244,41", year_build: "2014", kkd: "94,4%", status_work: 1,}],
        boil_totl: 3,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.998533873941202,
          47.01649246905518
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 72,
        comp: "ОКП",
        adrs_cot: "вул. Архітектора Старова, 10-Г",
        latitude: 47.01441299155613,
        longitude: 32.00669892229861,
        cot_tip: null,
        area_t: "7 РТМ",
        year_build: "2002",
        cot_cond: "дах ж/будинку",
        year_moder: null,
        mod_work: null,
        t_input: "51,11 C",
        t_output: "42,73 C",
        total_pow: "488,46 кВт",
        join_pow: "413,10 кВт",
        heat_area: "8 362,00 м2",
        gas_use: "73 035,87 м3",
        prod_pow: "505,3872 Гкал",
        kkd: "84%",
        rel_pow: "498,1525 Гкал",
        len_netw: "32,0 м",
        netw_loss: "0,45",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Архітектора Старова, 10-Г",
        boilers: [{name: "Модуль 105G (водотрубний)", power: "122,1", year_build: "2002", kkd: "-90,5%", status_work: 1,},{name: "Модуль 105G (водотрубний)", power: "122,1", year_build: "2002", kkd: "90,4%", status_work: 1,},{name: "Модуль 105G (водотрубний)", power: "122,1", year_build: "2002", kkd: "90,4%", status_work: 1,},{name: "Модуль 105G (водотрубний)", power: "122,1", year_build: "2002", kkd: "90,6%", status_work: 1,},{name: "Модуль 105G (водотрубний)", power: "122,1", year_build: "2002", kkd: "90,6%", status_work: 1,}],
        boil_totl: 5,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00669892229861,
          47.01441299155613
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 73,
        comp: "ОКП",
        adrs_cot: "просп. Героїв України, 91-К",
        latitude: 47.01552083832834,
        longitude: 31.997968374502705,
        cot_tip: null,
        area_t: "7 РТМ",
        year_build: "1975",
        cot_cond: "окремо розташована, 1 пов., з/б фундамент, цегляні стіни, з/б перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "52,29 C",
        t_output: "40,67 C",
        total_pow: "2 600,47 кВт",
        join_pow: "1 662,04 кВт",
        heat_area: "37 309,36 м2",
        gas_use: "388 915,38 м3",
        prod_pow: "2 297,0435 Гкал",
        kkd: "71%",
        rel_pow: "2 128,1860 Гкал",
        len_netw: "1 540,0 м",
        netw_loss: "6,42",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, просп. Героїв України, 91-К",
        boilers: [{name: "НИИСТУ-5мк (водотрубний)", power: "650,117", year_build: "2006", kkd: "88,3%", status_work: 1,},{name: "НИИСТУ-5мк (водотрубний)", power: "650,117", year_build: "2005", kkd: "87,9%", status_work: 1,},{name: "НИИСТУ-5мк (водотрубний)", power: "650,117", year_build: "2005", kkd: "87,1%", status_work: 1,},{name: "НИИСТУ-5мк (водотрубний)", power: "650,117", year_build: "2006", kkd: "86,8%", status_work: 1,}],
        boil_totl: 4,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.997968374502705,
          47.01552083832834
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 74,
        comp: "ОКП",
        adrs_cot: "вул. Лісова, 1-Б",
        latitude: 47.0155914,
        longitude: 31.9159408,
        cot_tip: null,
        area_t: "7 РТМ",
        year_build: "1978",
        cot_cond: "окремо розташована, 1пов, фундаментні блоки, цегляні стіни, з/б плити перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "52,13 C",
        t_output: "42,18 C",
        total_pow: "2 000,36 кВт",
        join_pow: "555,68 кВт",
        heat_area: "10 380,99 м2",
        gas_use: "109 102,39 м3",
        prod_pow: "816,6194 Гкал",
        kkd: "90%",
        rel_pow: "718,0142 Гкал",
        len_netw: "330,0 м",
        netw_loss: "11,2",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Лісова, 1-Б",
        boilers: [
          {name: "Ква-1гн 'Факел' (водотрубний)", power: "1000", year_build: "1991", kkd: "90,4%", status_work: 1,},{name: "Ква-1гн 'Факел' (водотрубний)", power: "1000", year_build: "1999", kkd: "90,5%", status_work: 1,}
        ],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9159408,
          47.01559140000001
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 75,
        comp: "ОКП",
        adrs_cot: "вул. Верхня, 1/1",
        latitude: 47.013763213527675,
        longitude: 31.924362605727545,
        cot_tip: null,
        area_t: "7 РТМ",
        year_build: "1947",
        cot_cond: "окремо розташована, 1пов., фундамент -камінь, стіни - камінь,перекриття - металеві, з/б",
        year_moder: null,
        mod_work: null,
        t_input: "51,00 C",
        t_output: "45,00 C",
        total_pow: "744,32 кВт",
        join_pow: "23,73 кВт",
        heat_area: null,
        gas_use: "16 300,00 м3",
        prod_pow: "25,7807 Гкал",
        kkd: "52%",
        rel_pow: "21,8400 Гкал",
        len_netw: "90,0 м",
        netw_loss: "14,44",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Верхня, 1/1",
        boilers: [
          {name: "НИИСТУ-5 (водотрубний)", power: "372,16", year_build: "1985 ", status_work: 0,},{name: "НИИСТУ-5 (водотрубний)", power: "372,16", year_build: "1985 ", status_work: 0,}
        ],
        boil_totl: 2,
        boil_not_w: 2
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.924362605727545,
          47.013763213527675
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 76,
        comp: "ОКП",
        adrs_cot: "вул. Курчатова, 22",
        latitude: 47.0094723,
        longitude: 31.9160189,
        cot_tip: null,
        area_t: "7 РТМ",
        year_build: "-",
        cot_cond: "вбудована, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "60,90 C",
        t_output: "47,81 C",
        total_pow: "200,04 кВт",
        join_pow: "210,15 кВт",
        heat_area: "3 093,70 м2",
        gas_use: "46 453,86 м3",
        prod_pow: "358,0066 Гкал",
        kkd: "93%",
        rel_pow: "320,5389 Гкал",
        len_netw: "108,0 м",
        netw_loss: "9,57",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Курчатова, 22",
        boilers: [{name: "Колви КТ DUO 100 (водотрубний)", power: "100", year_build: "2006", kkd: "90,1%", status_work: 1,},{name: "Колви КТ DUO 100 (водотрубний)", power: "100", year_build: "2006", kkd: "90,4%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9160189,
          47.0094723
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 77,
        comp: "ОКП",
        adrs_cot: "вул. Силікатна, 267",
        latitude: 47.009343,
        longitude: 31.914263,
        cot_tip: null,
        area_t: "7 РТМ",
        year_build: "-",
        cot_cond: "вбудована, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "58,02 C",
        t_output: "47,74 C",
        total_pow: "200,04 кВт",
        join_pow: "102,81 кВт",
        heat_area: "2 127,47 м2",
        gas_use: "26 969,49 м3",
        prod_pow: "137,8676 Гкал",
        kkd: "62%",
        rel_pow: "131,7760 Гкал",
        len_netw: "18,0 м",
        netw_loss: "3,46",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Силікатна, 267",
        boilers: [{name: "АОГВ-100Э 'МАЯК' (водотрубний)", power: "100,018", year_build: "2006", kkd: "90,6%", status_work: 1,},{name: "АОГВ-100Э 'МАЯК' (водотрубний)", power: "100,018", year_build: "2006", kkd: "90,3%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.914263,
          47.009343
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 78,
        comp: "ОКП",
        adrs_cot: "просп. Героїв України, 13-Є",
        latitude: 46.994468419474025,
        longitude: 32.00037701250105,
        cot_tip: null,
        area_t: "7 РТМ",
        year_build: "2007",
        cot_cond: "дах ж/будинку",
        year_moder: null,
        mod_work: null,
        t_input: "55,92 C",
        t_output: "41,91 C",
        total_pow: "1 081,59 кВт",
        join_pow: "360,76 кВт",
        heat_area: "7 271,40 м2",
        gas_use: "67 163,85 м3",
        prod_pow: "438,8155 Гкал",
        kkd: "79%",
        rel_pow: "434,4708 Гкал",
        len_netw: "0,0 м",
        netw_loss: "0",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, просп. Героїв України, 13-Є",
        boilers: [{name: "МН120 'Бернард' (водотрубний)", power: "108,159", year_build: "2007 ", kkd: "90,7%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний)", power: "108,159", year_build: "2007", kkd: "90,6%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний)", power: "108,159", year_build: "2007", kkd: "90,3%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний)", power: "108,159", year_build: "2007", kkd: "90,8%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний)", power: "108,159", year_build: "2007", kkd: "90,4%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний)", power: "108,159", year_build: "2007", kkd: "90,6%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний)", power: "108,159", year_build: "2007", kkd: "90,6%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний)", power: "108,159", year_build: "2007", kkd: "90,4%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний)", power: "108,159", year_build: "2007", kkd: "90,4%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний)", power: "108,159", year_build: "2007", kkd: "90,5%", status_work: 1,}],
        boil_totl: 10,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00037701250105,
          46.994468419474025
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 79,
        comp: "ОКП",
        adrs_cot: "вул. Архітектора Старова, 4-Є",
        latitude: 47.01418871600686,
        longitude: 32.001307035813284,
        cot_tip: null,
        area_t: "7 РТМ",
        year_build: "2007",
        cot_cond: "дах ж/будинку",
        year_moder: null,
        mod_work: null,
        t_input: "51,20 C",
        t_output: "43,88 C",
        total_pow: "838,52 кВт",
        join_pow: "332,27 кВт",
        heat_area: "6 554,00 м2",
        gas_use: "65 005,44 м3",
        prod_pow: "447,3471 Гкал",
        kkd: "83%",
        rel_pow: "442,9178 Гкал",
        len_netw: "0,0 м",
        netw_loss: "0",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Архітектора Старова, 4-Є",
        boilers: [{name: "Модуль AF105 (водотрубний)", power: "119,789", year_build: "2007", kkd: "91,0%", status_work: 1,},{name: "Модуль AF105 (водотрубний)", power: "119,789", year_build: "2007", kkd: "91,3%", status_work: 1,},{name: "Модуль AF105 (водотрубний)", power: "119,789", year_build: "2007", kkd: "91,2%", status_work: 1,},{name: "Модуль AF105 (водотрубний)", power: "119,789", year_build: "2007", kkd: "91,2%", status_work: 1,},{name: "Модуль AF105 (водотрубний)", power: "119,789", year_build: "2007", kkd: "91,2%", status_work: 1,},{name: "Модуль AF105 (водотрубний)", power: "119,789", year_build: "2007", kkd: "90,9%", status_work: 1,},{name: "Модуль AF105 (водотрубний)", power: "119,789", year_build: "2007", kkd: "90,7%", status_work: 1,}],
        boil_totl: 7,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.001307035813284,
          47.01418871600686
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 80,
        comp: "ОКП",
        adrs_cot: "вул. Архітектора Старова, 4-Ж",
        latitude: 47.01373917101824,
        longitude: 32.00068445636942,
        cot_tip: null,
        area_t: "7 РТМ",
        year_build: "-",
        cot_cond: "дах ж/будинку, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "54,73 C",
        t_output: "43,10 C",
        total_pow: "1 151,37 кВт",
        join_pow: "294,36 кВт",
        heat_area: "6 694,70 м2",
        gas_use: "56 812,72 м3",
        prod_pow: "383,2330 Гкал",
        kkd: "81%",
        rel_pow: "379,4386 Гкал",
        len_netw: "0,0 м",
        netw_loss: "0",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Архітектора Старова, 4-Ж",
        boilers: [{name: "ВПМ 'Колві'-192 (водотрубний)", power: "191,895", year_build: "2011", kkd: "90,4%", status_work: 1,},{name: "ВПМ 'Колві'-192 (водотрубний)", power: "191,895", year_build: "2011", kkd: "90,7%", status_work: 1,},{name: "ВПМ 'Колві'-192 (водотрубний)", power: "191,895", year_build: "2011", kkd: "90,5%", status_work: 1,},{name: "ВПМ 'Колві'-192 (водотрубний)", power: "191,895", year_build: "2011", kkd: "90,2%", status_work: 1,},{name: "ВПМ 'Колві'-192 (водотрубний)", power: "191,895", year_build: "2011", kkd: "90,2%", status_work: 1,},{name: "ВПМ 'Колві'-192 (водотрубний)", power: "191,895", year_build: "2011", kkd: "90,7%", status_work: 1,}],
        boil_totl: 6,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00068445636942,
          47.01373917101824
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 81,
        comp: "ОКП",
        adrs_cot: "просп. Героїв України, 101",
        latitude: 47.01386310270572,
        longitude: 31.99696410593099,
        cot_tip: null,
        area_t: "7 РТМ",
        year_build: "2016",
        cot_cond: "дах ж/будинку",
        year_moder: null,
        mod_work: null,
        t_input: "54,20 C",
        t_output: "43,00 C",
        total_pow: "1 946,86 кВт",
        join_pow: "863,76 кВт",
        heat_area: "18 332,40 м2",
        gas_use: "151 557,39 м3",
        prod_pow: "1 110,4546 Гкал",
        kkd: "88%",
        rel_pow: "1 051,9020 Гкал",
        len_netw: "180,0 м",
        netw_loss: "4,33",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, просп. Героїв України, 101",
        boilers: [{name: "МН120 'Бернард' (водотрубний) ", power: "108,159", year_build: "2003", kkd: "90,1%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний) ", power: "108,159", year_build: "2003", kkd: "90,1%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний) ", power: "108,159", year_build: "2003", kkd: "89,0%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний) ", power: "108,159", year_build: "2003", kkd: "88,4%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний) ", power: "108,159", year_build: "2003", kkd: "88,4%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний) ", power: "108,159", year_build: "2003", kkd: "90,6%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний) ", power: "108,159", year_build: "2003", kkd: "90,9%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний) ", power: "108,159", year_build: "2003", kkd: "88,5%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний) ", power: "108,159", year_build: "2003", kkd: "90,2%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний) ", power: "108,159", year_build: "2003", kkd: "90,6%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний) ", power: "108,159", year_build: "2003", kkd: "90,6%", status_work: 1,},{name: "МН120 'Бернард' (водотрубний) ", power: "108,159", year_build: "2003", kkd: "90,4%", status_work: 1,}],
        boil_totl: 12,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99696410593099,
          47.01386310270572
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 82,
        comp: "ОКП",
        adrs_cot: "вул. Генерала Карпенка, 1/1",
        latitude: 46.952338118003766,
        longitude: 31.95351895159905,
        cot_tip: null,
        area_t: "8 РТМ",
        year_build: "1968",
        cot_cond: "окремо розташована, 1пов. Фундамент - бетонний, стіни - цегла, перекриття  з/б плити",
        year_moder: null,
        mod_work: null,
        t_input: "53,52 C",
        t_output: "43,78 C",
        total_pow: "250,04 кВт",
        join_pow: "102,69 кВт",
        heat_area: "1 210,00 м2",
        gas_use: "23 531,23 м3",
        prod_pow: "169,2628 Гкал",
        kkd: "87%",
        rel_pow: "159,7871 Гкал",
        len_netw: "72,0 м",
        netw_loss: "4,65",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Генерала Карпенка, 1/1",
        boilers: [{name: "АОГВ-50Э 'МАЯК' (водотрубний)", power: "50,009", year_build: "2014", kkd: "89,7%", status_work: 1,},{name: "АОГВ-100Э 'МАЯК'", power: "100,018", year_build: "2014", kkd: "89,6%", status_work: 1,},{name: "АОГВ-100Э 'МАЯК'", power: "100,018", year_build: "2014", kkd: "89,7%", status_work: 1,}],
        boil_totl: 3,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95351895159905,
          46.952338118003766
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 83,
        comp: "ОКП",
        adrs_cot: "вул. Абрикосова, 5",
        latitude: 46.95973394995661,
        longitude: 31.95712025399619,
        cot_tip: null,
        area_t: "8 РТМ",
        year_build: "-",
        cot_cond: "вбудована, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "51,93 C",
        t_output: "42,80 C",
        total_pow: "1 374,67 кВт",
        join_pow: "625,58 кВт",
        heat_area: "10 299,80 м2",
        gas_use: "184 089,96 м3",
        prod_pow: "953,9013 Гкал",
        kkd: "63%",
        rel_pow: "857,4381 Гкал",
        len_netw: "994,0 м",
        netw_loss: "9,21",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Абрикосова, 5",
        boilers: [{name: "НИИСТУ-5 (водотрубний)", power: "458,222", year_build: "1986", kkd: "86,8%", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "458,222", year_build: "1980", kkd: "84,8%", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "458,222", year_build: "1986", kkd: "84,8%", status_work: 1,}],
        boil_totl: 3,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95712025399619,
          46.95973394995661
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 84,
        comp: "ОКП",
        adrs_cot: "вул. Дачна, 7-Б",
        latitude: 46.956151325046974,
        longitude: 31.952161699217974,
        cot_tip: null,
        area_t: "8 РТМ",
        year_build: "1961",
        cot_cond: "окремо розташована, 1пов. Фундамент  - бетон, стіни - цегла, перекриття - з/б плити",
        year_moder: null,
        mod_work: null,
        t_input: "52,30 C",
        t_output: "42,53 C",
        total_pow: "5 594,03 кВт",
        join_pow: "2 093,40 кВт",
        heat_area: "36 169,20 м2",
        gas_use: "533 905,05 м3",
        prod_pow: "3 423,0215 Гкал",
        kkd: "77%",
        rel_pow: "3 008,2721 Гкал",
        len_netw: "5 104,0 м",
        netw_loss: "11,24",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Дачна, 7-Б",
        boilers: [{name: "НИИСТУ-5 (водотрубний) ", power: "976,92", year_build: "1984", kkd: "89,9%", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "976,92", year_build: "1982", kkd: "89,5%", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "1279,3", year_build: "1986", kkd: "87,4%", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "1279,3", year_build: "1982", kkd: "87,4%", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "540,795", year_build: "1986", kkd: "76,5%", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "540,795", year_build: "1984", kkd: "78,8%", status_work: 1,}],
        boil_totl: 6,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.952161699217974,
          46.956151325046974
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 85,
        comp: "ОКП",
        adrs_cot: "вул. Спортивна, 29/4",
        latitude: 46.96711200016612,
        longitude: 31.951960486595254,
        cot_tip: null,
        area_t: "8 РТМ",
        year_build: "1969",
        cot_cond: "окремо розташована, 1пов. Фундамент - камінь, стіни - камінь, цегла, з/б перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "64,84 C",
        t_output: "55,86 C",
        total_pow: "300,05 кВт",
        join_pow: "129,79 кВт",
        heat_area: "1 089,50 м2",
        gas_use: "30 536,04 м3",
        prod_pow: "205,6908 Гкал",
        kkd: "81%",
        rel_pow: "163,5442 Гкал",
        len_netw: "372,0 м",
        netw_loss: null,
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Спортивна, 29/4",
        boilers: [{name: "АОГВ-100Э 'МАЯК' (водотрубний)", power: "100,018", year_build: "2014", kkd: "90,7%", status_work: 1,},{name: "АОГВ-100Э 'МАЯК' (водотрубний)", power: "100,018", year_build: "2014", kkd: "90,4%", status_work: 1,},{name: "АОГВ-100Э 'МАЯК' (водотрубний)", power: "100,018", year_build: "2014", kkd: "90,5%", status_work: 1,}],
        boil_totl: 3,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.951960486595254,
          46.96711200016612
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 86,
        comp: "ОКП",
        adrs_cot: "вул. Генерала  Карпенка, 20-А",
        latitude: 46.96562042797502,
        longitude: 31.952373265914908,
        cot_tip: null,
        area_t: "8 РТМ",
        year_build: "1968",
        cot_cond: "окремо розташована, 1пов. Фундамент - бетонний, стіни - цегла, перекриття - з/б плити",
        year_moder: null,
        mod_work: null,
        t_input: "55,63 C",
        t_output: "46,19 C",
        total_pow: "5 820,82 кВт",
        join_pow: "3 818,94 кВт",
        heat_area: "67 924,80 м2",
        gas_use: "795 205,49 м3",
        prod_pow: "6 093,4087 Гкал",
        kkd: "92%",
        rel_pow: "5 227,2029 Гкал",
        len_netw: "7 660,0 м",
        netw_loss: "13,36",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Генерала  Карпенка, 20-А",
        boilers: [{name: "ICI Caldai REX 240 (жаротрубний)", power: "2400,432", year_build: "2008", kkd: "92,0%", status_work: 1,},{name: "ICI Caldai REX 240", power: "2400,432", year_build: "2008", kkd: "92,3%", status_work: 1,},{name: "ICI Caldai REX 100", power: "1019,951", year_build: "2011", kkd: "92,5%", status_work: 1,}],
        boil_totl: 3,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.952373265914908,
          46.96562042797502
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 87,
        comp: "ОКП",
        adrs_cot: "вул. Генерала  Карпенка, 8-3",
        latitude: 46.9600128148945,
        longitude: 31.94899658140438,
        cot_tip: null,
        area_t: "8 РТМ",
        year_build: "-",
        cot_cond: "вбудована, не має на балансі приміщення",
        year_moder: null,
        mod_work: null,
        t_input: "51,90 C",
        t_output: "43,13 C",
        total_pow: "1 081,59 кВт",
        join_pow: "984,01 кВт",
        heat_area: "19 002,90 м2",
        gas_use: "256 947,12 м3",
        prod_pow: "1 527,5552 Гкал",
        kkd: "72%",
        rel_pow: "1 347,9634 Гкал",
        len_netw: "864,0 м",
        netw_loss: "10,87",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Генерала  Карпенка, 8-3",
        boilers: [{name: "НИИСТУ-5 (водотрубний)", power: "540,795", year_build: "1992", kkd: "76,6%", status_work: 1,},{name: "НИИСТУ-5 (водотрубний)", power: "540,795", year_build: "1992", kkd: "76,9%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94899658140438,
          46.9600128148945
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 88,
        comp: "ОКП",
        adrs_cot: "вул. Біла, 71",
        latitude: 46.9564453,
        longitude: 31.943664,
        cot_tip: null,
        area_t: "8 РТМ",
        year_build: "1978",
        cot_cond: "окремо розташована , 2пов. Фундамент - бетон, стіни  з/б, перекриття - з/б плити, вікна замінені",
        year_moder: 2020,
        mod_work: "Заміна насосної групи, встановлення нової ХВП, заміна вікон, заміна пальників, заміна котла №1, ремонт покрівлі котельні",
        t_input: "58,37 C",
        t_output: "42,58 C",
        total_pow: "23 260,00 кВт",
        join_pow: "28 555,14 кВт",
        heat_area: "584 644,10 м2",
        gas_use: "5 677 258,40 м3",
        prod_pow: "46 769,0448 Гкал",
        kkd: "98%",
        rel_pow: "39 955,5816 Гкал",
        len_netw: "37 290,0 м",
        netw_loss: "12,69",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Біла, 71",
        boilers: [{name: "Viessmann Vitomax D-HW (жаротрубний)", power: "26400,0", year_build: "2020", status_work: 1,},{name: "КВ-ГМ-10-150 (водотрубний)", power: "11630", year_build: "1976", kkd: "90,4%", status_work: 1,},{name: "КВ-ГМ-10-150 (водотрубний)", power: "11630", year_build: "1990", kkd: "90,1%", status_work: 1,}],
        boil_totl: 3,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.943664,
          46.9564453
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 89,
        comp: "ОКП",
        adrs_cot: "вул. Курортна, 11-А",
        latitude: 46.95578391816979,
        longitude: 31.956429122085,
        cot_tip: null,
        area_t: "8 РТМ",
        year_build: "1972",
        cot_cond: "окремо розташована, 2пов. Фундамент - цегла, стіни - цегла, перекриття- дерев'яні по балкам",
        year_moder: null,
        mod_work: null,
        t_input: "59,76 C",
        t_output: "40,30 C",
        total_pow: "34 773,70 кВт",
        join_pow: "13 947,86 кВт",
        heat_area: "275 549,70 м2",
        gas_use: "3 962 693,43 м3",
        prod_pow: "26 256,4445 Гкал",
        kkd: "79%",
        rel_pow: "20 122,1008 Гкал",
        len_netw: "17 357,0 м",
        netw_loss: null,
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Курортна, 11-А",
        boilers: [{name: "ТВГ-8М (водотрубний)", power: "9652,9", year_build: "1996", kkd: "89,1%", status_work: 1,},{name: "ТВГ-8М (водотрубний)", power: "9652,9", year_build: "1996", kkd: "90,3%", status_work: 1,},{name: "ТВГ-8М (водотрубний)", power: "9652,9", year_build: "1995", kkd: "90,3%", status_work: 1,},{name: "КВ-Г-2,9-115СН 'Дрозд'", power: "2907,5", year_build: "2006", kkd: "93,1%", status_work: 1,},{name: "КВ-Г-2,9-115СН 'Дрозд'", power: "2907,5", year_build: "2006", kkd: "92,6%", status_work: 1,}],
        boil_totl: 5,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.956429122084998,
          46.95578391816979
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 90,
        comp: "ОКП",
        adrs_cot: "вул. Генерала  Карпенка, 51-А",
        latitude: 46.963165377179585,
        longitude: 31.952791679796544,
        cot_tip: null,
        area_t: "8 РТМ",
        year_build: "1969",
        cot_cond: "окремо розташована, 2пов. Фундамент - бетонні блоки, стіни - цегла, перекриття - з/б плити",
        year_moder: null,
        mod_work: null,
        t_input: "59,36 C",
        t_output: "41,66 C",
        total_pow: "19 771,00 кВт",
        join_pow: "7 521,12 кВт",
        heat_area: "148 021,44 м2",
        gas_use: "1 643 314,98 м3",
        prod_pow: "12 594,5416 Гкал",
        kkd: "92%",
        rel_pow: "10 237,2821 Гкал",
        len_netw: "10 116,0 м",
        netw_loss: "17,14",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Генерала  Карпенка, 51-А",
        boilers: [{name: "№1 ТВГ-4 (водотрубний)", power: "4652,0", year_build: "1964", kkd: "90,2%", status_work: 1,},{name: "КВ-Г-7,56-150", power: "7559,5", year_build: "1991", kkd: "88,3%", status_work: 1,},{name: "№3 КВ-Г-7,56-150", power: "7559,5", year_build: "1993", kkd: "87,9%", status_work: 1,}],
        boil_totl: 3,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.952791679796544,
          46.963165377179585
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 91,
        comp: "ОКП",
        adrs_cot: "вул. Леваневців, 8",
        latitude: 46.96449703916865,
        longitude: 31.949268823312273,
        cot_tip: null,
        area_t: "8 РТМ",
        year_build: "2007",
        cot_cond: "дах ж/будинку",
        year_moder: null,
        mod_work: null,
        t_input: "53,57 C",
        t_output: "32,50 C",
        total_pow: "621,04 кВт",
        join_pow: "130,02 кВт",
        heat_area: "5 440,71 м2",
        gas_use: "28 569,50 м3",
        prod_pow: "165,6905 Гкал",
        kkd: "70%",
        rel_pow: "164,0500 Гкал",
        len_netw: "0,0 м",
        netw_loss: "0",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Леваневців, 8",
        boilers: [{name: "RADAN RD-14 (водотрубний)", power: "310,521", year_build: "2006", kkd: "92,3%", status_work: 1,},{name: "RADAN RD-14 (водотрубний)", power: "310,521", year_build: "2006", kkd: "92,5%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.949268823312273,
          46.96449703916865
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 92,
        comp: "ОКП",
        adrs_cot: "вул. Леваневців, 10",
        latitude: 46.963141242494366,
        longitude: 31.94941770120128,
        cot_tip: null,
        area_t: "8 РТМ",
        year_build: "2007",
        cot_cond: "дах ж/будинку",
        year_moder: null,
        mod_work: null,
        t_input: "51,84 C",
        t_output: "41,22 C",
        total_pow: "880,39 кВт",
        join_pow: "299,59 кВт",
        heat_area: "9 310,20 м2",
        gas_use: "55 319,21 м3",
        prod_pow: "397,9906 Гкал",
        kkd: "87%",
        rel_pow: "394,0501 Гкал",
        len_netw: null,
        netw_loss: "0",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Леваневців, 10",
        boilers: [{name: "КСВ-0,25 'ВК-22' (жаротрубний)", power: "250,045", year_build: "2000", kkd: "92,9%", status_work: 1,},{name: "№2 КСВ-0,63 'ВК-34'", power: "630,345", year_build: "2001", kkd: "93,2%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94941770120128,
          46.963141242494366
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 93,
        comp: "ОКП",
        adrs_cot: "вул. Озерна, 45-А",
        latitude: 46.945240899358765,
        longitude: 31.946119918589204,
        cot_tip: null,
        area_t: "8 РТМ",
        year_build: "1991",
        cot_cond: "окремо розташована, 1 пов. Фундамент - бетонний стрічковий, стіни - з/б, цегла, з/б перекриття",
        year_moder: null,
        mod_work: null,
        t_input: "53,41 C",
        t_output: "40,61 C",
        total_pow: "11 630,00 кВт",
        join_pow: "2 975,77 кВт",
        heat_area: "62 798,20 м2",
        gas_use: "615 704,64 м3",
        prod_pow: "4 152,4429 Гкал",
        kkd: "81%",
        rel_pow: "3 990,5395 Гкал",
        len_netw: "2034",
        netw_loss: "2,94",
        pkd: "Ні",
        mod_prct: "Ні",
        adrs_cot_f: "м. Миколаїв, вул. Озерна, 45-А",
        boilers: [{name: "КВ-ГМ-5,8-115СН (водотрубний)", power: "5815,0", year_build: "2005", kkd: "92,1%", status_work: 1,},{name: "КВ-ГМ-5,8-115СН (водотрубний)", power: "5815,0", year_build: "2005", kkd: "92,5%", status_work: 1,}],
        boil_totl: 2,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.946119918589204,
          46.945240899358765
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 94,
        comp: "МТЕЦ",
        adrs_cot: "вул. Каботажний спуск, 18",
        latitude: 46.949958601180576,
        longitude: 31.989055709179326,
        cot_tip: "Покупне тепло: ТОВ \"НІКТЕПЛОПОСТАЧ\"\nТОВ \"АПК ЄВГРОЙЛ\"",
        area_t: "1 ТЕЦ",
        year_build: "1948",
        cot_cond: "Фундамент -ленточний, стени -ракушняк, бут ,колони, ригеля , ферми",
        year_moder: null,
        mod_work: null,
        t_input: "120,00 C",
        t_output: "70,00 C",
        total_pow: "476 830,00 кВт",
        join_pow: "206 155,00 кВт",
        heat_area: "2 150 000,00 м2",
        gas_use: "42 264 519,95 м3",
        prod_pow: "261 910,0000 Гкал",
        kkd: "89%",
        rel_pow: "217 332,0000 Гкал",
        len_netw: "106149",
        netw_loss: "17,02",
        pkd: null,
        mod_prct: null,
        adrs_cot_f: "м. Миколаїв, вул. Каботажний спуск, 18",
        boilers: [{name: "ТКП-2 (паровий)", power: "?", year_build: "?", kkd: "93%", status_work: 1,},{name: "ТКП-2 (паровий)", power: "?", year_build: "?", kkd: "93%", status_work: 1,},{name: "ТП-230", power: "?", year_build: "?", kkd: "93%", status_work: 1,},{name: "ТП-230", power: "?", year_build: "?", kkd: "93%", status_work: 1,},{name: "ПТВМ-100 (водотрубний)", power: "?", year_build: "?", kkd: "93%", status_work: 1,},{name: "ПТВМ-100 (водотрубний)", power: "?", year_build: "?", kkd: "93%", status_work: 1,},{name: "КВГМ-100", power: "?", year_build: "?", kkd: "93%", status_work: 1,}  ],
        boil_totl: 7,
        boil_not_w: 0
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.989055709179326,
          46.949958601180576
        ]
      }
    }
  ]
}