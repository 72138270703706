import React from "react";
import {Col, Row} from 'antd';
import {Collapse} from 'antd';

const {Panel} = Collapse;

const header = `Демопроєкт WEB ТЕПЛОВА МЕРЕЖА м. МИКОЛАЄВА`;

export const Legend = () => {
    return (
        <Collapse defaultActiveKey={['1']}>
            <Panel header={header} key="1">
                <Row>
                    <Col span={5} lg={3} sm={24} xs={24}>
                        <ul className={'listUnStyle'}>
                            <li><span className={'circle circle-1'}></span>Тепловий р-н №1 ОКП</li>
                            <li><span className={'circle circle-2'}></span>Тепловий р-н №2 ОКП</li>
                            <li><span className={'circle circle-3'}></span>Тепловий р-н №3 ОКП</li>
                            <li><span className={'circle circle-4'}></span>Тепловий р-н №4 ОКП</li>
                            <li><span className={'circle circle-5'}></span>Тепловий р-н №5 ОКП</li>
                            <li><span className={'circle circle-6'}></span>Тепловий р-н №6 ОКП</li>
                            <li><span className={'circle circle-7'}></span>Тепловий р-н №7 ОКП</li>
                            <li><span className={'circle circle-8'}></span>Тепловий р-н №8 ОКП</li>
                            <li><span className={'circle circle-tec'}></span>Теплові мережі ТЕЦ</li>
                        </ul>
                    </Col>
                    <Col span={13} lg={9} sm={24} xs={24}>
                        <ul className={'listUnStyle'}>
                            <li><span className={'line-legend line-1'}></span>внутрішньоквартальні мережі</li>
                            <li><span className={'line-legend line-2'}></span>магістральні мережі</li>
                            <li><span className={'line-legend line-3'}>
                                <span className={'short'}></span>
                                <span className={'short'}></span>
                                <span className={'short'}></span>
                                <span className={'short'}></span>
                            </span>мережі що планується побудувати
                            </li>
                            <li>
                                <svg className={'legend-icon legend-icon-boiler'} width="20" height="20"
                                     viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="10" cy="10" r="10" fill="#EE7922"/>
                                    <path
                                        d="M14.0459 9.6395L9.7701 2.06897L5.49427 9.6395C4.91699 10.4927 4.59769 11.5278 4.59769 12.6437C4.59769 15.5638 6.91346 17.931 9.7701 17.931C12.6267 17.931 14.9425 15.5638 14.9425 12.6437C14.9425 11.5278 14.6232 10.4927 14.0459 9.6395Z"
                                        fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M12.6437 15.475C12.334 15.54 12.0133 15.5741 11.6849 15.5741C9.05408 15.5741 6.9214 13.3856 6.9214 10.6859C6.9214 10.5713 6.92525 10.4575 6.93282 10.3448C6.47619 10.9896 6.20688 11.7831 6.20688 12.6412C6.20688 14.8009 7.91302 16.5517 10.0177 16.5517C11.0355 16.5517 11.9601 16.1422 12.6437 15.475Z"
                                          fill="#EE7922"/>
                                    <rect x="11.0345" y="0.919525" width="3.67816" height="11.954" rx="1.83908"
                                          fill="#EE7922"/>
                                    <circle cx="12.8736" cy="11.954" r="2.52874" fill="#EE7922"/>
                                    <rect x="11.4943" y="2.75864" width="2.75862" height="10.1149" rx="1.37931"
                                          fill="white"/>
                                    <circle cx="12.8736" cy="11.954" r="2.06897" fill="white"/>
                                    <circle cx="12.8736" cy="11.954" r="1.6092" fill="#EE7922"/>
                                    <rect x="11.954" y="3.44827" width="1.83908" height="9.42529" rx="0.91954"
                                          fill="#EE7922"/>
                                    <rect x="12.4138" y="5.74713" width="0.91954" height="5.51724" rx="0.45977"
                                          fill="white"/>
                                    <circle cx="12.8736" cy="11.954" r="1.14943" fill="white"/>
                                </svg>
                                <span className={'legend-text'}>Котельні</span>
                            </li>
                            <li>
                                <svg className={'legend-icon legend-icon-bolt'} width="20" height="10"
                                     viewBox="0 0 10 5" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M5.00029 2.48504L9.98495 0.0161133V4.98437L5.00029 2.51543L0.015625 4.98437V0.0161133L5.00029 2.48504Z"
                                        fill="#070000"/>
                                </svg>
                                <span className={'legend-text'}>Задвіжки</span>
                            </li>
                            <li>
                                <svg className={'legend-icon legend-icon-building'} width="20" height="25"
                                     viewBox="0 0 20 25" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M20 10C20 15.5229 15.5229 20 10 20C4.47715 20 0 15.5229 0 10C0 4.47715 4.47715 0 10 0C15.5229 0 20 4.47715 20 10Z"
                                        fill="#FC4D4D"/>
                                    <path d="M10 24.6487L5.83374 17.7567H14.1663L10 24.6487Z" fill="#FC4D4D"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M2.89044 5.28024H6.23611V13.4054H5.16072V15.6757H2.89044V5.28024ZM4.08533 6.5946H5.3997V7.90894H4.08533V6.5946ZM5.3997 11.1351H4.08533V12.4495H5.3997V11.1351ZM4.08533 8.86488H5.3997V10.1792H4.08533V8.86488Z"
                                          fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M17.1095 5.28024H13.7639V13.4054H14.8393V15.6757H17.1095V5.28024ZM15.9147 6.5946H14.6003V7.90894H15.9147V6.5946ZM14.6003 11.1351H15.9147V12.4495H14.6003V11.1351ZM15.9147 8.86488H14.6003V10.1792H15.9147V8.86488Z"
                                          fill="white"/>
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                          d="M13.2859 4.32431H6.71405V15.6757H9.22332V13.1664H10.7767V15.6757H13.2859V4.32431ZM8.02845 5.63868H9.46232V7.07252H8.02845V5.63868ZM9.46232 8.02846H8.02845V9.46232H9.46232V8.02846ZM8.02845 10.4182H9.46232V11.8521H8.02845V10.4182ZM11.9715 5.63868H10.5377V7.07252H11.9715V5.63868ZM10.5377 8.02846H11.9715V9.46232H10.5377V8.02846ZM11.9715 10.4182H10.5377V11.8521H11.9715V10.4182Z"
                                          fill="white"/>
                                </svg>
                                <span className={'legend-text'}>Споживачі теплової енергії</span>
                            </li>
                        </ul>
                    </Col>
                    <Col span={6} lg={12} sm={24} xs={24}>
                        <h2>Проєкт розроблено КУ ММР «Центр Енергоефективності м. Миколаєва»</h2>
                        <p>tel: +38067-158-55-90</p>
                    </Col>
                </Row>
            </Panel>
        </Collapse>
    )
};