import L from "leaflet";

import informationSVG from "../images/svg/information-icon.svg";

const LeafIcon = L.Icon.extend({
    options: {
        iconSize: [20, 20],
        iconAnchor: [20, 20],
        tooltipAnchor: [10, -10]
    },
});

// @ts-ignore
export const informationIcon = new LeafIcon({iconUrl: informationSVG});
