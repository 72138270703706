import React from "react";

import {Marker, Popup, Tooltip, useMap} from "react-leaflet";
import {Card, Image} from "antd";

import {informationIcon} from "../icons/icon_information";


// @ts-ignore
const PopupStatistics = ({feature}) => {
    const {
        shema_name,
        img,
    } = feature.properties;

    // let image = false;

        return (
            <Card type="inner" className={"card"} >
                <div className={"count-title"}>{shema_name}</div>
                <Image
                    width={200}
                    src={`../border-scheme-img/${img}`}
                />
            </Card>
        )


}


// @ts-ignore
export const LayerBorderScheme = ({data}) => {
    const leafletMap = useMap();

    return (
        // @ts-ignore
        data.features.map((feature, index) => {
            const {coordinates} = feature.geometry;
            const {shema_name_short} = feature.properties;
            return (
                <Marker
                    key={index}
                    position={[coordinates[1], coordinates[0]]}
                    icon={informationIcon}
                    eventHandlers={{
                        click: (e) => leafletMap.panTo(e.latlng),
                    }}
                >
                    <Popup className={'knm-popup-border-schema'}>
                        <PopupStatistics feature={feature}/>
                    </Popup>
                    <Tooltip>
                        {shema_name_short}
                    </Tooltip>
                </Marker>
            )
        })
    )
}