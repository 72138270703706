import React from "react";
import {LayersControl, LayerGroup, Polygon, Tooltip} from "react-leaflet";
import L from "leaflet";

// @ts-ignore
export const LayerHitGen = ({data}) => {
    const layer =
        // @ts-ignore
        data.features.map((feature, index) => {
            const {coordinates} = feature.geometry;
            const latLongCoordinates = L.GeoJSON.coordsToLatLngs(coordinates, 2);

            const style = {
                color: '#ff7f00',
                weight: 0.5, //border
                opacity: 0.5, //border
                fillOpacity: 0.5
            }

            return (
                <Polygon pathOptions={style} positions={latLongCoordinates} key={index}>
                    <Tooltip>Котельня</Tooltip>
                </Polygon>
            )
        });
    return (
        <LayersControl.Overlay checked name={`Котельні 2`}>
            <LayerGroup>{layer}</LayerGroup>
        </LayersControl.Overlay>
    );
}