import React from "react";
import {LayersControl, LayerGroup, Polyline, Tooltip} from "react-leaflet";
import parse from 'html-react-parser';
import L from "leaflet";

// @ts-ignore
export const LayerPipeIntra = ({data}) => {

    const color_1 = '#C2DB27';
    const color_2 = '#2A5485';
    const color_3 = '#028E9B';
    const color_4 = '#FF0D00';
    const color_5 = '#779E00';
    const color_6 = '#987C65';
    const color_7 = '#A43DD4';
    const color_8 = '#4D6DE0';

    let style = {
        color: color_8,
        weight: 3,
        // dashArray: '2, 5',
        // dashOffset: '0'
    };


    const layer =
        // @ts-ignore
        data.features.map((feature, index) => {
            const {coordinates} = feature.geometry;
            const latLongCoordinate = L.GeoJSON.coordsToLatLngs(coordinates, 1);

            const {diameter_1, diameter_2, layingMeth, material} = feature.properties;

            const {district} = feature.properties;
            let pipeColor = "#FC4D4D";

            if (district === 1) {
                pipeColor = color_1;
            } else if (district === 2) {
                pipeColor = color_2;
            } else if (district === 3) {
                pipeColor = color_3;
            } else if (district === 4) {
                pipeColor = color_4;
            } else if (district === 5) {
                pipeColor = color_5;
            } else if (district === 6) {
                pipeColor = color_6;
            } else if (district === 7) {
                pipeColor = color_7;
            } else if (district === 8) {
                pipeColor = color_8;
            }

            style = {
                color: pipeColor,
                weight: 3,
                // dashArray: '2, 5',
                // dashOffset: '0'
            };
            let tooltip = `<span class="icon-diameter">&#8960;</span>1 = ${diameter_1}`;
            if (diameter_2) {
                tooltip +=`; <span class="icon-diameter">&#8960;</span>2 = ${diameter_2}`;
            }
            if (layingMeth) {
                tooltip += `; <span class="icon-layingMeth">&#129153;</span>`;
            }
            if (material) {
                tooltip += `; <span class="icon-material">M</span>=${material}`;
            }

            return (
                <Polyline pathOptions={style} positions={latLongCoordinate} key={index}>
                    <Tooltip className={'pipe-tooltip'}>
                        {parse(tooltip)}
                        {/*<span className={'icon-diameter'}> &#8960;</span>1 = {diameter_1}*/}
                    </Tooltip>
                </Polyline>
            )
        })
    return (
        <LayersControl.Overlay checked name={`Мережі квартальні`}>
            <LayerGroup>
                {layer}
            </LayerGroup>
        </LayersControl.Overlay>
    )
}

