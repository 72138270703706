import React from "react";

import {LayerGroup, LayersControl, Marker, Tooltip, useMap} from "react-leaflet";
import {tk_tec_Icon} from "../icons/tk_tec_Icon";

// @ts-ignore
export const LayerTkTec = ({data}) => {
    const leafletMap = useMap();
    const layer =
        // @ts-ignore
        data.features.map((feature, index) => {
            const {coordinates} = feature.geometry;
            const {title} = feature.properties;

            return (
                <Marker
                    key={index}
                    position={[coordinates[1], coordinates[0]]}
                    icon={tk_tec_Icon}
                    eventHandlers={{
                        click: (e) => leafletMap.panTo(e.latlng),
                    }}
                >
                    <Tooltip>
                        {title}
                    </Tooltip>
                </Marker>
            )
        });
    return (
        <LayersControl.Overlay checked name={`ТК ТЕЦ`}>
            <LayerGroup>{layer}</LayerGroup>
        </LayersControl.Overlay>
    );
}