import React from "react";
import {LayersControl, LayerGroup, Polygon, Tooltip} from "react-leaflet";
import L from "leaflet";

// @ts-ignore
export const LayerNEFCO = ({data}) => {
    const layer =
        // @ts-ignore
        data.features.map((feature, index) => {
            const {coordinates} = feature.geometry;
            const {comments, project, sub_projec} = feature.properties;
            const latLongCoordinates = L.GeoJSON.coordsToLatLngs(coordinates, 2);

            const style = {
                color: '#33a02c',
                weight: 2, //border
                opacity: 1, //border
                fillOpacity: 0.4,
                // fillRule: 'evenodd',
            }

            return (
                <Polygon pathOptions={style} positions={latLongCoordinates} key={index}>
                    <Tooltip className={"nefco-tooltip"} >
                        <p className={"project-title"}>{project} ({sub_projec})</p>
                            {`${comments}`}
                    </Tooltip>
                </Polygon>
            )
        });
    return (
        <LayersControl.Overlay checked name={`NEFCO DEMOUKRAINE`}>
            <LayerGroup>{layer}</LayerGroup>
        </LayersControl.Overlay>
    );
}