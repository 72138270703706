export const pipePlan = {
  type: "FeatureCollection",
  name: "pipe-plan",
  crs: {
    type: "name",
    properties: {
      name: "urn:ogc:def:crs:OGC:1.3:CRS84"
    }
  },
  features: [
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 1,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.03169104226856,
              46.95833052193133,
              0
            ],
            [
              32.03169055795058,
              46.958330616478776,
              0
            ],
            [
              32.03169007534933,
              46.95833071943098,
              0
            ],
            [
              32.031689594610654,
              46.95833083075683,
              0
            ],
            [
              32.03168911587982,
              46.958330950422685,
              0
            ],
            [
              32.031688639301514,
              46.95833107839237,
              0
            ],
            [
              32.03168816501975,
              46.95833121462722,
              0
            ],
            [
              32.031687693177865,
              46.958331359086074,
              0
            ],
            [
              32.03168722391845,
              46.95833151172526,
              0
            ],
            [
              32.03168675738331,
              46.958331672498666,
              0
            ],
            [
              32.031686293713435,
              46.95833184135769,
              0
            ],
            [
              32.03168583304895,
              46.95833201825131,
              0
            ],
            [
              32.03168537552906,
              46.958332203126076,
              0
            ],
            [
              32.03168492129204,
              46.95833239592611,
              0
            ],
            [
              32.03168447047515,
              46.95833259659314,
              0
            ],
            [
              32.03168402321464,
              46.95833280506654,
              0
            ],
            [
              32.03168357964566,
              46.958333021283295,
              0
            ],
            [
              32.03168313990227,
              46.958333245178075,
              0
            ],
            [
              32.03168270411735,
              46.958333476683215,
              0
            ],
            [
              32.0316822724226,
              46.95833371572875,
              0
            ],
            [
              32.03168184494848,
              46.95833396224244,
              0
            ],
            [
              32.031681421824175,
              46.9583342161498,
              0
            ],
            [
              32.03168100317755,
              46.95833447737409,
              0
            ],
            [
              32.03168058913512,
              46.95833474583635,
              0
            ],
            [
              32.03168017982202,
              46.95833502145548,
              0
            ],
            [
              32.03167977536192,
              46.95833530414818,
              0
            ],
            [
              32.03167937587708,
              46.958335593829005,
              0
            ],
            [
              32.031678981488206,
              46.95833589041043,
              0
            ],
            [
              32.031678592314485,
              46.958336193802815,
              0
            ],
            [
              32.03167820847353,
              46.95833650391448,
              0
            ],
            [
              32.031677830081335,
              46.95833682065171,
              0
            ],
            [
              32.03167745725226,
              46.95833714391878,
              0
            ],
            [
              32.03167709009895,
              46.95833747361801,
              0
            ],
            [
              32.03167672873239,
              46.95833780964975,
              0
            ],
            [
              32.031676373261774,
              46.95833815191246,
              0
            ],
            [
              32.03167602379452,
              46.9583385003027,
              0
            ],
            [
              32.031675680436244,
              46.958338854715194,
              0
            ],
            [
              32.0316753432907,
              46.95833921504284,
              0
            ],
            [
              32.031675012459786,
              46.95833958117674,
              0
            ],
            [
              32.03167468804348,
              46.95833995300624,
              0
            ],
            [
              32.03167437013981,
              46.958340330418984,
              0
            ],
            [
              32.03167405884485,
              46.95834071330092,
              0
            ],
            [
              32.03167375425269,
              46.958341101536334,
              0
            ],
            [
              32.03167345645535,
              46.9583414950079,
              0
            ],
            [
              32.03167316554285,
              46.95834189359671,
              0
            ],
            [
              32.03167288160309,
              46.958342297182305,
              0
            ],
            [
              32.031672604721884,
              46.958342705642735,
              0
            ],
            [
              32.0316723349829,
              46.95834311885456,
              0
            ],
            [
              32.031672072467664,
              46.95834353669289,
              0
            ],
            [
              32.0316718172555,
              46.95834395903147,
              0
            ],
            [
              32.03167156942353,
              46.958344385742656,
              0
            ],
            [
              32.03167132904666,
              46.95834481669751,
              0
            ],
            [
              32.03167109619753,
              46.958345251765785,
              0
            ],
            [
              32.0316708709465,
              46.958345690816,
              0
            ],
            [
              32.031670653361644,
              46.958346133715494,
              0
            ],
            [
              32.03167044350872,
              46.9583465803304,
              0
            ],
            [
              32.031670241451145,
              46.95834703052576,
              0
            ],
            [
              32.03167004724998,
              46.958347484165515,
              0
            ],
            [
              32.031669860963916,
              46.958347941112585,
              0
            ],
            [
              32.031669682649245,
              46.95834840122888,
              0
            ],
            [
              32.031669512359855,
              46.958348864375345,
              0
            ],
            [
              32.031669350147205,
              46.95834933041202,
              0
            ],
            [
              32.03166919606032,
              46.95834979919806,
              0
            ],
            [
              32.031669050145766,
              46.958350270591815,
              0
            ],
            [
              32.03166891244764,
              46.95835074445081,
              0
            ],
            [
              32.03166878300755,
              46.958351220631855,
              0
            ],
            [
              32.031668661864614,
              46.958351698991045,
              0
            ],
            [
              32.03166854905544,
              46.958352179383816,
              0
            ],
            [
              32.03166844461413,
              46.95835266166499,
              0
            ],
            [
              32.031668348572225,
              46.95835314568883,
              0
            ],
            [
              32.03166826095877,
              46.95835363130906,
              0
            ],
            [
              32.031668181800235,
              46.95835411837892,
              0
            ],
            [
              32.03166811112054,
              46.95835460675122,
              0
            ],
            [
              32.03166804894104,
              46.958355096278375,
              0
            ],
            [
              32.031667995280536,
              46.958355586812445,
              0
            ],
            [
              32.03166795015524,
              46.95835607820519,
              0
            ],
            [
              32.03166791357878,
              46.95835657030812,
              0
            ],
            [
              32.03166788556223,
              46.9583570629725,
              0
            ],
            [
              32.031667866114034,
              46.958357556049464,
              0
            ],
            [
              32.03166785524009,
              46.95835804939,
              0
            ],
            [
              32.03166785294366,
              46.95835854284501,
              0
            ],
            [
              32.031667859225465,
              46.958359036265385,
              0
            ],
            [
              32.03166787408359,
              46.958359529502,
              0
            ],
            [
              32.03166789751354,
              46.958360022405806,
              0
            ],
            [
              32.03166792950825,
              46.958360514827845,
              0
            ],
            [
              32.031667970058045,
              46.9583610066193,
              0
            ],
            [
              32.031668019150665,
              46.95836149763156,
              0
            ],
            [
              32.03166807677128,
              46.95836198771624,
              0
            ],
            [
              32.03166814290248,
              46.95836247672522,
              0
            ],
            [
              32.03166821752427,
              46.95836296451074,
              0
            ],
            [
              32.03166830061411,
              46.95836345092538,
              0
            ],
            [
              32.031668392146884,
              46.95836393582215,
              0
            ],
            [
              32.03166849209493,
              46.958364419054504,
              0
            ],
            [
              32.03166860042804,
              46.95836490047642,
              0
            ],
            [
              32.03166871711349,
              46.95836537994241,
              0
            ],
            [
              32.031668842116005,
              46.95836585730756,
              0
            ],
            [
              32.03166897539781,
              46.95836633242764,
              0
            ],
            [
              32.03166911691863,
              46.958366805159045,
              0
            ],
            [
              32.031669266635696,
              46.958367275358924,
              0
            ],
            [
              32.03166942450376,
              46.95836774288517,
              0
            ],
            [
              32.031669590475126,
              46.958368207596514,
              0
            ],
            [
              32.03166976449962,
              46.95836866935251,
              0
            ],
            [
              32.031669946524666,
              46.958369128013615,
              0
            ],
            [
              32.031670136495244,
              46.95836958344122,
              0
            ],
            [
              32.031670334353954,
              46.95837003549769,
              0
            ],
            [
              32.031670540041,
              46.95837048404642,
              0
            ],
            [
              32.03167075349422,
              46.95837092895186,
              0
            ],
            [
              32.03167097464911,
              46.95837137007955,
              0
            ],
            [
              32.03167120343884,
              46.958371807296174,
              0
            ],
            [
              32.031671439794266,
              46.95837224046963,
              0
            ],
            [
              32.03167168364396,
              46.958372669468986,
              0
            ],
            [
              32.03167193491423,
              46.958373094164614,
              0
            ],
            [
              32.03167219352914,
              46.95837351442816,
              0
            ],
            [
              32.031672459410544,
              46.95837393013263,
              0
            ],
            [
              32.03167273247808,
              46.95837434115239,
              0
            ],
            [
              32.03167301264924,
              46.95837474736323,
              0
            ],
            [
              32.03167329983935,
              46.95837514864239,
              0
            ],
            [
              32.031673593961614,
              46.95837554486861,
              0
            ],
            [
              32.03167389492716,
              46.95837593592214,
              0
            ],
            [
              32.03167420264503,
              46.95837632168482,
              0
            ],
            [
              32.031674517022225,
              46.95837670204004,
              0
            ],
            [
              32.03167483796374,
              46.95837707687289,
              0
            ],
            [
              32.031675165372604,
              46.95837744607008,
              0
            ],
            [
              32.031675499149856,
              46.95837780952004,
              0
            ],
            [
              32.03167583919463,
              46.958378167112926,
              0
            ],
            [
              32.031676185404166,
              46.95837851874069,
              0
            ],
            [
              32.03167653767384,
              46.958378864297046,
              0
            ],
            [
              32.03167689589719,
              46.958379203677595,
              0
            ],
            [
              32.031677259965974,
              46.95837953677975,
              0
            ],
            [
              32.03167762977015,
              46.95837986350286,
              0
            ],
            [
              32.03167800519798,
              46.95838018374819,
              0
            ],
            [
              32.031678386136,
              46.95838049741895,
              0
            ],
            [
              32.03167877246909,
              46.958380804420365,
              0
            ],
            [
              32.03167916408051,
              46.958381104659644,
              0
            ],
            [
              32.0316795608519,
              46.95838139804606,
              0
            ],
            [
              32.031679962663354,
              46.958381684490945,
              0
            ],
            [
              32.031680369393456,
              46.95838196390775,
              0
            ],
            [
              32.03168078091929,
              46.95838223621202,
              0
            ],
            [
              32.031681197116484,
              46.958382501321466,
              0
            ],
            [
              32.03168161785926,
              46.95838275915598,
              0
            ],
            [
              32.03168204302049,
              46.958383009637636,
              0
            ],
            [
              32.03168247247166,
              46.95838325269074,
              0
            ],
            [
              32.03168290608301,
              46.95838348824184,
              0
            ],
            [
              32.03168334372349,
              46.95838371621976,
              0
            ],
            [
              32.03168378526086,
              46.95838393655559,
              0
            ],
            [
              32.03168423056167,
              46.958384149182756,
              0
            ],
            [
              32.031684679491356,
              46.958384354036994,
              0
            ],
            [
              32.03168513191425,
              46.958384551056405,
              0
            ],
            [
              32.03168558769364,
              46.958384740181444,
              0
            ],
            [
              32.03168604669176,
              46.958384921354956,
              0
            ],
            [
              32.03168650876993,
              46.95838509452219,
              0
            ],
            [
              32.031686973788496,
              46.95838525963082,
              0
            ],
            [
              32.03168744160693,
              46.958385416630954,
              0
            ],
            [
              32.03168791208385,
              46.958385565475126,
              0
            ],
            [
              32.03168838507708,
              46.95838570611837,
              0
            ],
            [
              32.031688860443694,
              46.95838583851819,
              0
            ],
            [
              32.03168933804002,
              46.95838596263456,
              0
            ],
            [
              32.031689817721734,
              46.958386078429974,
              0
            ],
            [
              32.03169029934387,
              46.95838618586944,
              0
            ],
            [
              32.03169078276088,
              46.9583862849205,
              0
            ],
            [
              32.031691267826695,
              46.95838637555321,
              0
            ],
            [
              32.0316917543947,
              46.958386457740176,
              0
            ],
            [
              32.03169224231787,
              46.958386531456576,
              0
            ],
            [
              32.03169273144874,
              46.95838659668012,
              0
            ],
            [
              32.03169322163951,
              46.9583866533911,
              0
            ],
            [
              32.03169371274203,
              46.95838670157238,
              0
            ],
            [
              32.0316942046079,
              46.9583867412094,
              0
            ],
            [
              32.03169469708847,
              46.95838677229018,
              0
            ],
            [
              32.0316951900349,
              46.95838679480533,
              0
            ],
            [
              32.031695683298246,
              46.958386808748045,
              0
            ],
            [
              32.031696176729426,
              46.95838681411411,
              0
            ],
            [
              32.03169667017933,
              46.9583868109019,
              0
            ],
            [
              32.03169716349883,
              46.95838679911239,
              0
            ],
            [
              32.03169765653885,
              46.958386778749144,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 1,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.0314497307489,
              46.95796155459352,
              0
            ],
            [
              32.03164709628447,
              46.95795674356325,
              0
            ],
            [
              32.03170735678417,
              46.95846928282016,
              0
            ],
            [
              32.03210600316673,
              46.95845029997227,
              0
            ],
            [
              32.032138998540624,
              46.95877807982267,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 1,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.03214784692066,
              46.95885393255971,
              0
            ],
            [
              32.032158151676065,
              46.95894226993896,
              0
            ],
            [
              32.032467566164875,
              46.958928032929535,
              0
            ],
            [
              32.032504649549296,
              46.959298193943646,
              0
            ],
            [
              32.0297141248713,
              46.95943107163177,
              0
            ],
            [
              32.02978503748717,
              46.960307833562155,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 3,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.96597529885864,
              46.97667065115755,
              0
            ],
            [
              31.966270165903193,
              46.976846839206324,
              0
            ],
            [
              31.966253703860684,
              46.97692967477533,
              0
            ],
            [
              31.967531569910793,
              46.977818394077424,
              0
            ],
            [
              31.967570667261757,
              46.97787736071237,
              0
            ],
            [
              31.968253595818087,
              46.978376048919316,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 3,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.96703976639071,
              46.979413278249076,
              0
            ],
            [
              31.966809297795525,
              46.97924761467775,
              0
            ],
            [
              31.968078932824373,
              46.978497910403675,
              0
            ],
            [
              31.968253595818087,
              46.978376048919316,
              0
            ],
            [
              31.96879297391839,
              46.97797634027484,
              0
            ],
            [
              31.96981567830954,
              46.97710517343828,
              0
            ],
            [
              31.96994737464965,
              46.977129041211946,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 3,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.96879297391839,
              46.97797634027484,
              0
            ],
            [
              31.96975600340542,
              46.97827327786291,
              0
            ],
            [
              31.97017578548951,
              46.97827889368793,
              0
            ],
            [
              31.971233471720996,
              46.978615842109804,
              0
            ],
            [
              31.97100300312581,
              46.97872815777858,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 3,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.971233471720996,
              46.978615842109804,
              0
            ],
            [
              31.971914588729984,
              46.978681827593796,
              0
            ],
            [
              31.972649207377145,
              46.97865234472803,
              0
            ],
            [
              31.972980505982733,
              46.97851335385619,
              0
            ],
            [
              31.973311804588317,
              46.97874921694021,
              0
            ],
            [
              31.974077289565194,
              46.97922374784974,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 3,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.974750175552927,
              46.978582147363184,
              0
            ],
            [
              31.973922185439793,
              46.97912759777038,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 3,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.972262349378088,
              46.97970810197935,
              0
            ],
            [
              31.972459893888246,
              46.97958596091692,
              0
            ],
            [
              31.97299491026993,
              46.979945363705504,
              0
            ],
            [
              31.974077289565194,
              46.97922374784973,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 3,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.97060688522783,
              46.98106005855042,
              0
            ],
            [
              31.970810603003937,
              46.98121167771614,
              0
            ],
            [
              31.97160489655522,
              46.980695047684186,
              0
            ],
            [
              31.972201645596325,
              46.98031037968984,
              0
            ],
            [
              31.97273254646738,
              46.980122256378344,
              0
            ],
            [
              31.97299491026993,
              46.979945363705504,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 3,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.972345688468316,
              46.98175357233088,
              0
            ],
            [
              31.972452691744653,
              46.9816833790376,
              0
            ],
            [
              31.971293105197972,
              46.98089784582015,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 3,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.972452691744653,
              46.9816833790376,
              0
            ],
            [
              31.973302544689403,
              46.982282826795206,
              0
            ],
            [
              31.972816914435256,
              46.98250042326465,
              0
            ],
            [
              31.973183194881184,
              46.98301563201932,
              0
            ],
            [
              31.97354535981648,
              46.98336939646348,
              0
            ],
            [
              31.974168859676666,
              46.98316584181133,
              0
            ],
            [
              31.974074202932222,
              46.983028266504064,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 3,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.972590561350703,
              46.97797388333758,
              0
            ],
            [
              31.972423883170258,
              46.9781563983708,
              0
            ],
            [
              31.972980505982733,
              46.97851335385619,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 3,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.97042787509839,
              46.97367072821058,
              0
            ],
            [
              31.97034308034767,
              46.97363431296246,
              0
            ],
            [
              31.969726058606057,
              46.973427965082216,
              0
            ],
            [
              31.969387062471345,
              46.97330648571586,
              0
            ],
            [
              31.96885539946869,
              46.97313341874472,
              0
            ],
            [
              31.96839732384063,
              46.97298431259974,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 3,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.96839732384063,
              46.97298431259974,
              0
            ],
            [
              31.967151266046226,
              46.97260029693619,
              0
            ],
            [
              31.9669149026765,
              46.972527606372374,
              0
            ],
            [
              31.966541815994987,
              46.97241286808284,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 3,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.966541815994987,
              46.97241286808284,
              0
            ],
            [
              31.966214494847108,
              46.972306049500425,
              0
            ],
            [
              31.965707223755565,
              46.97214050530254,
              0
            ],
            [
              31.964680746934263,
              46.97180752312792,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.98567749015999,
              46.95759185432997,
              0
            ],
            [
              31.98632234725479,
              46.95763153992494,
              0
            ],
            [
              31.988898955743437,
              46.95781152792903,
              0
            ],
            [
              31.99043173563328,
              46.95790902117845,
              0
            ],
            [
              31.991827169654858,
              46.95799901478949,
              0
            ],
            [
              31.993541246090807,
              46.958119006035446,
              0
            ],
            [
              31.994044618698002,
              46.958157909389456,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 2,
        district: 9,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.993552927412477,
              46.97509114281025,
              0
            ],
            [
              31.993113420634007,
              46.974437644618874,
              0
            ],
            [
              31.99223074452057,
              46.97472128630615,
              0
            ],
            [
              31.99202564135728,
              46.974425149355774,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 2,
        district: 9,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.99896761792999,
              46.971443260683124,
              0
            ],
            [
              31.998374964179487,
              46.97162445383673,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 2,
        district: 9,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.998671106375475,
              46.972045763478285,
              0
            ],
            [
              31.998466829721927,
              46.97175834971633,
              0
            ],
            [
              31.998048316251673,
              46.971148355183566,
              0
            ],
            [
              31.997277348111094,
              46.97139827520355,
              0
            ],
            [
              31.997183952920672,
              46.97126081933708,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 2,
        district: 9,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.991276821581156,
              46.973301846430715,
              0
            ],
            [
              31.992284024615152,
              46.97298445910219,
              0
            ],
            [
              31.992423201761675,
              46.97294197404126,
              0
            ],
            [
              31.992564210186426,
              46.973148151227306,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 2,
        district: 9,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.00552696428808,
              46.9701459569276,
              0
            ],
            [
              32.00590420760628,
              46.97020968783806,
              0
            ],
            [
              32.00559655286134,
              46.97097070401891,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.00457517496835,
              46.95558563212922,
              0
            ],
            [
              32.004899311217464,
              46.95557188249499,
              0
            ],
            [
              32.005000031520865,
              46.95632935708051,
              0
            ],
            [
              32.00505496986818,
              46.95680183579791,
              0
            ],
            [
              32.00510258310252,
              46.95764928717222,
              0
            ],
            [
              32.005080607763595,
              46.95815925144227,
              0
            ],
            [
              32.00488282971329,
              46.95816300116156,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.006898209724106,
              46.966947284280714,
              0
            ],
            [
              32.00731539779888,
              46.967028475742076,
              0
            ],
            [
              32.00742433024063,
              46.96672901571516,
              0
            ],
            [
              32.00899645897474,
              46.966684915146004,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.991001026363733,
              46.977297674669046,
              0
            ],
            [
              31.991838773966123,
              46.97703455638012,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.993204068391382,
              46.96977232587143,
              0
            ],
            [
              31.99336170528339,
              46.969716117973995,
              0
            ],
            [
              31.9934133998167,
              46.969787906795,
              0
            ],
            [
              31.9945579349225,
              46.969421535251314,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.996166813906417,
              46.96880637198118,
              0
            ],
            [
              31.996235739950823,
              46.96890044167185,
              0
            ],
            [
              31.9945579349225,
              46.969421535251314,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              31.98000774038066,
              46.97816482212648,
              0
            ],
            [
              31.979264890712244,
              46.97839507093571,
              0
            ],
            [
              31.97916611845716,
              46.97839507093571,
              0
            ],
            [
              31.977365582557262,
              46.978129723135545,
              0
            ],
            [
              31.977404679908226,
              46.97800477054082,
              0
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        district: 9,
        n_type: 3
      },
      geometry: {
        type: "MultiLineString",
        coordinates: [
          [
            [
              32.001171532842534,
              46.9727191073586,
              0
            ],
            [
              32.00129547612638,
              46.97289730172334,
              0
            ],
            [
              32.000939408243866,
              46.97302543846575,
              0
            ],
            [
              32.00117353507072,
              46.97333163411333,
              0
            ],
            [
              32.00198810132248,
              46.97307869000865,
              0
            ],
            [
              32.002310025709406,
              46.97352633369484,
              0
            ],
            [
              32.002856321638745,
              46.97336990844602,
              0
            ],
            [
              32.00291729216657,
              46.973459769815086,
              0
            ]
          ]
        ]
      }
    }
  ]
}