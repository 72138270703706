import React from "react";
import {LayersControl, LayerGroup, Polyline, Tooltip} from "react-leaflet";
import L from "leaflet";

// @ts-ignore
export const LayerPipeMain = ({data}) => {
 const layer =
        // @ts-ignore
        data.features.map((feature, index) => {
            const {coordinates} = feature.geometry;
            const latLongCoordinate = L.GeoJSON.coordsToLatLngs(coordinates, 1);

            const style = {
                color: '#b7484b',
                weight: 5,
                // dashArray: '2, 5',
                // dashOffset: '0'
            };

            return (
                <Polyline pathOptions={style} positions={latLongCoordinate} key={index}>
                    {/*<Tooltip><span className={'icon-diameter'}> &#8960;</span> = {diameter}</Tooltip>*/}
                    <Tooltip><span className={'icon-diameter'}> &#8960;</span> = ???</Tooltip>
                </Polyline>
            )
        });
    return (
        <LayersControl.Overlay checked name={`Мережі магістральні`}>
            <LayerGroup>{layer}</LayerGroup>
        </LayersControl.Overlay>
    )
}