import React from "react";

import {LayerGroup, Marker, Popup, Tooltip, useMap} from "react-leaflet";

import {buildingIcon1, buildingIcon2, buildingIcon3, buildingIcon4, buildingIcon5, buildingIcon6, buildingIcon7, buildingIcon8, buildingIconDef} from "../icons/heatingUsersIcon";

import {PopupStatisticsHitUsers} from "../components/PopupStatisticsHitUsers";
// @ts-ignore
export const LayerHeatingUsers = ({data}) => {
    const leafletMap = useMap();
    let iconBuilding = buildingIconDef;
    const layer =
        // @ts-ignore
        data.features.map((feature, index) => {
            const {coordinates} = feature.geometry;
            const {address, t_area} = feature.properties;

            if (t_area === "ОКП-1") {iconBuilding = buildingIcon1}
            else if (t_area === "ОКП-2") {iconBuilding = buildingIcon2}
            else if (t_area === "ОКП-3") {iconBuilding = buildingIcon3}
            else if (t_area === "ОКП-4") {iconBuilding = buildingIcon4}
            else if (t_area === "ОКП-5") {iconBuilding = buildingIcon5}
            else if (t_area === "ОКП-6") {iconBuilding = buildingIcon6}
            else if (t_area === "ОКП-7") {iconBuilding = buildingIcon7}
            else if (t_area === "ОКП-8") {iconBuilding = buildingIcon8}


            return (
                <Marker
                    key={index}
                    position={[coordinates[1], coordinates[0]]}
                    icon={iconBuilding}
                    eventHandlers={{
                        click: (e) => leafletMap.panTo(e.latlng),
                    }}
                >
                    <Popup className={'knm-popup'}>
                        <PopupStatisticsHitUsers feature={feature}/>
                    </Popup>
                    <Tooltip>
                        {address}
                    </Tooltip>
                </Marker>
            )
        })
    return (
        <LayerGroup>{layer}</LayerGroup>
    )

}