export const pipeIntra_2023 = {
    type: "FeatureCollection",
    name: "pipe-intra",
    crs: {
        type: "name",
        properties: {
            name: "urn:ogc:def:crs:OGC:1.3:CRS84"
        }
    },
    features: [
        {
            type: "Feature",
            properties: {
                fid: 1,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: "",
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.08393985188304,
                            46.91194898463621
                        ],
                        [
                            32.084473388514034,
                            46.912193078891995
                        ],
                        [
                            32.08438528154745,
                            46.912286703791
                        ],
                        [
                            32.08464960244721,
                            46.91261438964952
                        ],
                        [
                            32.08536424784285,
                            46.91230342250576
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 2,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0846300231213,
                            46.9118386402927
                        ],
                        [
                            32.0845223368288,
                            46.911935609576375
                        ],
                        [
                            32.08501671480797,
                            46.91215964138843
                        ],
                        [
                            32.08494329233583,
                            46.912216485132085
                        ],
                        [
                            32.08463491795277,
                            46.912330172438516
                        ],
                        [
                            32.08440975570482,
                            46.91240707842082
                        ],
                        [
                            32.083533580870444,
                            46.912794950388864
                        ],
                        [
                            32.083068571880126,
                            46.913015634910806
                        ],
                        [
                            32.082699012103596,
                            46.91319786614164
                        ],
                        [
                            32.07885901680991,
                            46.91489643202804
                        ],
                        [
                            32.07961282085737,
                            46.9157390077267
                        ],
                        [
                            32.077043034331936,
                            46.91693263395504
                        ],
                        [
                            32.076808082421046,
                            46.91669190475528
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 3,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07806136278419,
                            46.91645963926676
                        ],
                        [
                            32.078247162875286,
                            46.916625035341205
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 4,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07846104341742,
                            46.91627399388098
                        ],
                        [
                            32.07879719064746,
                            46.916631913363844
                        ],
                        [
                            32.07904193222131,
                            46.91688267320419
                        ],
                        [
                            32.07923344250284,
                            46.91695873679048
                        ],
                        [
                            32.080157953798064,
                            46.91801943642918
                        ],
                        [
                            32.081391451330255,
                            46.91748114861401
                        ],
                        [
                            32.08213057088329,
                            46.91711337122091
                        ],
                        [
                            32.08300674571767,
                            46.91800271949765
                        ],
                        [
                            32.0837018117874,
                            46.91766503636396
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 5,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07883512559138,
                            46.91572916822221
                        ],
                        [
                            32.08007351795509,
                            46.91520757520842
                        ],
                        [
                            32.08025952155122,
                            46.9153881272108
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 6,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07921202761512,
                            46.91440177089308
                        ],
                        [
                            32.07945870112743,
                            46.914631173967855
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 7,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07982627393145,
                            46.91446858502671
                        ],
                        [
                            32.079483690762096,
                            46.914057377801
                        ],
                        [
                            32.078957496378315,
                            46.913470566997276
                        ],
                        [
                            32.07880575660253,
                            46.91327161828624
                        ],
                        [
                            32.07825264064561,
                            46.91267142400236
                        ],
                        [
                            32.07853898828703,
                            46.91256442487253
                        ],
                        [
                            32.07912636806428,
                            46.91234373849256
                        ],
                        [
                            32.07987038244879,
                            46.91206620736111
                        ],
                        [
                            32.080399024248315,
                            46.911853878356425
                        ],
                        [
                            32.080971719531135,
                            46.91161647012674
                        ],
                        [
                            32.08172062874712,
                            46.91135899804103
                        ],
                        [
                            32.082073056613474,
                            46.91122691772486
                        ],
                        [
                            32.082205217063354,
                            46.91117508865111
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 8,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.083502959258716,
                            46.91049545438499
                        ],
                        [
                            32.08345401094394,
                            46.91061416111416
                        ],
                        [
                            32.08322150644878,
                            46.91061081726623
                        ],
                        [
                            32.082122004928245,
                            46.91108104383285
                        ],
                        [
                            32.08245485346868,
                            46.911437577315226
                        ],
                        [
                            32.08232758785029,
                            46.911611454448575
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 9,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.084474583306914,
                            46.91122775367726
                        ],
                        [
                            32.0838137810575,
                            46.91148689829018
                        ],
                        [
                            32.082521545547564,
                            46.91199515247286
                        ],
                        [
                            32.081679634533515,
                            46.91232785572562
                        ],
                        [
                            32.081128965992335,
                            46.912381355553386
                        ],
                        [
                            32.08066395700202,
                            46.91256191707785
                        ],
                        [
                            32.07880575660253,
                            46.91327161828624
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 10,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.083409957460646,
                            46.91122942558201
                        ],
                        [
                            32.08387514004894,
                            46.91146283539204
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 11,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.08290334240277,
                            46.9114451008567
                        ],
                        [
                            32.08333236763024,
                            46.91167624544408
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 12,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.08099925295821,
                            46.91143005377269
                        ],
                        [
                            32.08150342060034,
                            46.911226081772455
                        ],
                        [
                            32.08165221337073,
                            46.91138251903358
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 13,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0802943972255,
                            46.91172597898306
                        ],
                        [
                            32.08060521902429,
                            46.911600587144264
                        ],
                        [
                            32.0807126116073,
                            46.91172388227058
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 14,
                id: 1,
                district: 1,
                n_type: null,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0793071707062,
                            46.91215476502805
                        ],
                        [
                            32.079421580854024,
                            46.91211708522284
                        ],
                        [
                            32.07919949690687,
                            46.91219018173213
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 15,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0793071707062,
                            46.91215476502805
                        ],
                        [
                            32.0796287001446,
                            46.912571948255824
                        ],
                        [
                            32.07943045946979,
                            46.9126388227276
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 16,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0797926769991,
                            46.91250841743032
                        ],
                        [
                            32.0802821601468,
                            46.91231782450196
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 17,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.08004770773111,
                            46.91240911470076
                        ],
                        [
                            32.08029774707387,
                            46.91270178412896
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 18,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.08062235093446,
                            46.912167355921845
                        ],
                        [
                            32.08088177700274,
                            46.91206871517896
                        ],
                        [
                            32.081128965992335,
                            46.912381355553386
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 19,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.08100001947559,
                            46.91221826644858
                        ],
                        [
                            32.08121952037466,
                            46.912125559019074
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 20,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.08175550442141,
                            46.91184133921567
                        ],
                        [
                            32.082258018236736,
                            46.912099292434206
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 21,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.081128965992335,
                            46.912381355553386
                        ],
                        [
                            32.081275810936646,
                            46.912573620118636
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 22,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.08158663273543,
                            46.91299994343333
                        ],
                        [
                            32.08133210149863,
                            46.91311362907781
                        ],
                        [
                            32.081764971132436,
                            46.913611030491055
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 23,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.08206632622019,
                            46.913277469729465
                        ],
                        [
                            32.0822071157547,
                            46.9134154523205
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 24,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.08252154554756,
                            46.912635479006
                        ],
                        [
                            32.08292781656015,
                            46.91283275822393
                        ],
                        [
                            32.08283236734635,
                            46.91292471015363
                        ],
                        [
                            32.08298221578567,
                            46.91305821746141
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 25,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.08290823723424,
                            46.912364636862954
                        ],
                        [
                            32.0833756936403,
                            46.9125769638441
                        ],
                        [
                            32.083299823752405,
                            46.912667244352846
                        ],
                        [
                            32.083533580870444,
                            46.912794950388864
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 26,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.08356903948364,
                            46.91223590277184
                        ],
                        [
                            32.08404139072118,
                            46.9124515739963
                        ],
                        [
                            32.084134629513365,
                            46.912528873749835
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 27,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.078821052950886,
                            46.912866195307515
                        ],
                        [
                            32.0790755841877,
                            46.91276588399412
                        ],
                        [
                            32.079643384639034,
                            46.91346805924503
                        ],
                        [
                            32.0798636520555,
                            46.91338446743568
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 28,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07928606194121,
                            46.91347140291468
                        ],
                        [
                            32.07957006660175,
                            46.913377390388746
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 29,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07987526086565,
                            46.91286314440205
                        ],
                        [
                            32.08017202643856,
                            46.91323400185029
                        ],
                        [
                            32.080328661045826,
                            46.913173815497835
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 30,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.08086219767682,
                            46.913675366369546
                        ],
                        [
                            32.08108907590191,
                            46.91391000448298
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 31,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05780466391215,
                            46.915361070385636
                        ],
                        [
                            32.057914467951676,
                            46.914986045459365
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 33,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.052242314795095,
                            46.91677792493711
                        ],
                        [
                            32.052482161537476,
                            46.91681470302023
                        ],
                        [
                            32.05258005816701,
                            46.91649540154763
                        ],
                        [
                            32.05508376446753,
                            46.916823061671984
                        ],
                        [
                            32.05507152738884,
                            46.91686987009763
                        ],
                        [
                            32.05580330469466,
                            46.916970173729155
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 34,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05277585142609,
                            46.91628977707905
                        ],
                        [
                            32.05270107607627,
                            46.91651177044028
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 35,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05457531384784,
                            46.916852734875114
                        ],
                        [
                            32.05460198484026,
                            46.916760011312284
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 36,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.053880247778096,
                            46.91678085046738
                        ],
                        [
                            32.053917905296224,
                            46.91667048589387
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 37,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03865539911789,
                            46.92966986592209
                        ],
                        [
                            32.038542817993914,
                            46.92993059267129
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 38,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.038542817993914,
                            46.92993059267129
                        ],
                        [
                            32.03763237933918,
                            46.929770145591206
                        ],
                        [
                            32.0372212134951,
                            46.93062585779758
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 39,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03697647192124,
                            46.93058574659331
                        ],
                        [
                            32.03757364136145,
                            46.93071276530378
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 40,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.038542817993914,
                            46.92993059267129
                        ],
                        [
                            32.040706333506776,
                            46.93034507873686
                        ],
                        [
                            32.04053746182082,
                            46.93074451993433
                        ],
                        [
                            32.042062201825914,
                            46.931067079061876
                        ],
                        [
                            32.04225065283779,
                            46.931187411503124
                        ],
                        [
                            32.041942125491296,
                            46.9318518464432
                        ],
                        [
                            32.04169248908595,
                            46.931788338476345
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 41,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03869566234694,
                            46.92995987468104
                        ],
                        [
                            32.03925746338957,
                            46.92865368801755
                        ],
                        [
                            32.039536468783766,
                            46.92871051426454
                        ],
                        [
                            32.03968331372806,
                            46.92837958409707
                        ],
                        [
                            32.0401042692351,
                            46.928466495248124
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 42,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03873351411544,
                            46.929967126342326
                        ],
                        [
                            32.03892461484913,
                            46.92952278873442
                        ],
                        [
                            32.039225646984974,
                            46.92958462805572
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 43,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0388014974111,
                            46.929980150614604
                        ],
                        [
                            32.038816928556635,
                            46.9299439632396
                        ],
                        [
                            32.0407895456419,
                            46.93032502303336
                        ],
                        [
                            32.04067941193365,
                            46.930595774397204
                        ],
                        [
                            32.04117378991283,
                            46.93069270973794
                        ],
                        [
                            32.04128881845256,
                            46.93042864408736
                        ],
                        [
                            32.04097065440655,
                            46.930365134432876
                        ],
                        [
                            32.04116155283414,
                            46.929913879456215
                        ],
                        [
                            32.041357346093235,
                            46.92995064852252
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 44,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.041043901744565,
                            46.93019198926657
                        ],
                        [
                            32.04126923912665,
                            46.930239786209626
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 45,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.041073194879154,
                            46.93012274475511
                        ],
                        [
                            32.04081401979928,
                            46.93007265478894
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 46,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04002350451572,
                            46.93057739008865
                        ],
                        [
                            32.04055740484594,
                            46.930697347789426
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 47,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.039093486535066,
                            46.93184087878979
                        ],
                        [
                            32.03948017822176,
                            46.9319344693597
                        ],
                        [
                            32.03989134406584,
                            46.93100524145219
                        ],
                        [
                            32.04018076696307,
                            46.9306127241303
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 48,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04058841278512,
                            46.93075503747334
                        ],
                        [
                            32.04039551170796,
                            46.93119743919436
                        ],
                        [
                            32.040613331708705,
                            46.93124423506195
                        ],
                        [
                            32.04066228002347,
                            46.931135601735186
                        ],
                        [
                            32.0408801000242,
                            46.931182397656805
                        ],
                        [
                            32.04084583620386,
                            46.93125760530233
                        ],
                        [
                            32.04095352249636,
                            46.93142139047606
                        ],
                        [
                            32.0407405973271,
                            46.93186929023008
                        ],
                        [
                            32.0403783797978,
                            46.931790740917215
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 49,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04115115638357,
                            46.93087434740541
                        ],
                        [
                            32.041107709687886,
                            46.93097348697622
                        ],
                        [
                            32.04165837822906,
                            46.93108713448753
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 50,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04117378991283,
                            46.93069270973793
                        ],
                        [
                            32.04232636976227,
                            46.930892533868416
                        ],
                        [
                            32.04262250706665,
                            46.93024908287951
                        ],
                        [
                            32.042321474930795,
                            46.930180559071985
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 51,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.036445128723244,
                            46.934218014393814
                        ],
                        [
                            32.03737759411969,
                            46.93455057966497
                        ],
                        [
                            32.037498741198746,
                            46.934358393956146
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 52,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03765170468241,
                            46.93440936501568
                        ],
                        [
                            32.03759051928894,
                            46.93451799170203
                        ],
                        [
                            32.03789399884055,
                            46.93458818113602
                        ],
                        [
                            32.03687587389326,
                            46.93639636293689
                        ],
                        [
                            32.03531197523624,
                            46.93600197728821
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 53,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03676084535354,
                            46.93610558735858
                        ],
                        [
                            32.03700589852133,
                            46.936165443633385
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 54,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03556594779177,
                            46.93606602455851
                        ],
                        [
                            32.03580880063119,
                            46.93564101160628
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 55,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.037499964906615,
                            46.93481713174551
                        ],
                        [
                            32.03773301329786,
                            46.93487409424198
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 56,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0543283955259,
                            46.941817200107295
                        ],
                        [
                            32.053036160015864,
                            46.94150974436995
                        ],
                        [
                            32.052950500465016,
                            46.94166681436942
                        ],
                        [
                            32.05275470720592,
                            46.94161835665277
                        ],
                        [
                            32.05244878023858,
                            46.94218647884925
                        ],
                        [
                            32.05303738372374,
                            46.94232391338676
                        ],
                        [
                            32.05309061501605,
                            46.94223242964573
                        ],
                        [
                            32.05332128394942,
                            46.94228548187989
                        ],
                        [
                            32.053362890016984,
                            46.94221697347314
                        ],
                        [
                            32.053931914176225,
                            46.9423535724656
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 57,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05303738372374,
                            46.94232391338675
                        ],
                        [
                            32.05297191535273,
                            46.94244484713328
                        ],
                        [
                            32.05224503287834,
                            46.94225770256699
                        ],
                        [
                            32.05216916299044,
                            46.94233874292824
                        ],
                        [
                            32.052000291304466,
                            46.94265789037456
                        ],
                        [
                            32.05232579759771,
                            46.94273725920847
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 58,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.052000291304466,
                            46.94265789037456
                        ],
                        [
                            32.051769010517155,
                            46.94265204214003
                        ],
                        [
                            32.051111879391314,
                            46.94250583606936
                        ],
                        [
                            32.05083042658136,
                            46.94243732794458
                        ],
                        [
                            32.051056812537205,
                            46.941997871303265
                        ],
                        [
                            32.05158912546036,
                            46.94170044232803
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 59,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0510783829949,
                            46.94249768275225
                        ],
                        [
                            32.0507129506259,
                            46.943107367059284
                        ],
                        [
                            32.05059302725471,
                            46.94334463575765
                        ],
                        [
                            32.05245551063186,
                            46.943829195325605
                        ],
                        [
                            32.05254117018271,
                            46.94367380256257
                        ],
                        [
                            32.05290338771204,
                            46.94375066355551
                        ],
                        [
                            32.05301107400454,
                            46.94352509293688
                        ],
                        [
                            32.054283730188665,
                            46.94265287760215
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 60,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.050773802980125,
                            46.94300584198245
                        ],
                        [
                            32.050846334783664,
                            46.94302465694895
                        ],
                        [
                            32.05088182231189,
                            46.9429544785733
                        ],
                        [
                            32.05103356208768,
                            46.942991238686304
                        ],
                        [
                            32.05108128669459,
                            46.94290936385467
                        ],
                        [
                            32.051808169168986,
                            46.94309232886692
                        ],
                        [
                            32.051853446360155,
                            46.94300711236367
                        ],
                        [
                            32.05244694467679,
                            46.943158329790926
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 61,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.051809392876855,
                            46.94430539614511
                        ],
                        [
                            32.05188036793328,
                            46.94418843493277
                        ],
                        [
                            32.05128564590878,
                            46.94403304321249
                        ],
                        [
                            32.05125627691991,
                            46.94408316962315
                        ],
                        [
                            32.05104824658213,
                            46.94402803056885
                        ],
                        [
                            32.05092587579519,
                            46.9442452446964
                        ],
                        [
                            32.05050002545665,
                            46.94413663774279
                        ],
                        [
                            32.050424155568756,
                            46.944273649555605
                        ],
                        [
                            32.05024304680409,
                            46.94422519419847
                        ],
                        [
                            32.0503262589392,
                            46.94406646082481
                        ],
                        [
                            32.049921694219684,
                            46.94396068982028
                        ],
                        [
                            32.049856837702606,
                            46.94407096801082
                        ],
                        [
                            32.04951861174543,
                            46.94398625852019
                        ],
                        [
                            32.049575384892655,
                            46.94388884178604
                        ],
                        [
                            32.04888545875764,
                            46.94370750691523
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 62,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04912513786883,
                            46.943271150729956
                        ],
                        [
                            32.048242461095974,
                            46.94489407892012
                        ],
                        [
                            32.04920674289702,
                            46.94518981930777
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 63,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.048280779438976,
                            46.94376155907699
                        ],
                        [
                            32.048787645943705,
                            46.94388558243138
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 64,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04853553913068,
                            46.94435491241996
                        ],
                        [
                            32.050760011582256,
                            46.944898587382276
                        ],
                        [
                            32.05036842506407,
                            46.94517093591142
                        ],
                        [
                            32.050762458998,
                            46.94539817043369
                        ],
                        [
                            32.05162150192229,
                            46.944871853709635
                        ],
                        [
                            32.05248543967805,
                            46.945403182949036
                        ],
                        [
                            32.05280849855556,
                            46.94561704983354
                        ],
                        [
                            32.05331021878199,
                            46.94594453184454
                        ],
                        [
                            32.05343258956892,
                            46.945860990705555
                        ],
                        [
                            32.05360390867063,
                            46.94596124005669
                        ],
                        [
                            32.05362348799654,
                            46.946028072853146
                        ],
                        [
                            32.05377522777234,
                            46.94613500515393
                        ],
                        [
                            32.0534815378837,
                            46.9462686702295
                        ],
                        [
                            32.05426471092008,
                            46.94722436579528
                        ],
                        [
                            32.053956336537006,
                            46.947341320374505
                        ],
                        [
                            32.05378012260382,
                            46.947144168221826
                        ],
                        [
                            32.05347174822075,
                            46.94727198304767
                        ],
                        [
                            32.05363817249098,
                            46.94746495779469
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 65,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05347174822075,
                            46.94727198304767
                        ],
                        [
                            32.05335916709677,
                            46.94715252214132
                        ],
                        [
                            32.05277423473521,
                            46.9473797482519
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 66,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0521403540589,
                            46.947187608589026
                        ],
                        [
                            32.052524598329875,
                            46.947028883999494
                        ],
                        [
                            32.052664101026984,
                            46.94716254684302
                        ],
                        [
                            32.053182953163585,
                            46.94696038166281
                        ],
                        [
                            32.052967580578574,
                            46.946723128989916
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 67,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05278485159471,
                            46.94711549774522
                        ],
                        [
                            32.05318050574785,
                            46.94759193646953
                        ],
                        [
                            32.052446281026235,
                            46.9478659420573
                        ],
                        [
                            32.05226272484583,
                            46.94766210876585
                        ],
                        [
                            32.051927428889634,
                            46.947769037802416
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 68,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05363817249098,
                            46.94746495779469
                        ],
                        [
                            32.05376054327792,
                            46.9476069733979
                        ],
                        [
                            32.05264207428533,
                            46.948066433062145
                        ],
                        [
                            32.05279748518474,
                            46.948239355950655
                        ],
                        [
                            32.053220276253604,
                            46.948071863016416
                        ],
                        [
                            32.05341362209696,
                            46.94825648113384
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 69,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.053140162842894,
                            46.94786182206429
                        ],
                        [
                            32.05322700664688,
                            46.947959504619675
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 70,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05279748518474,
                            46.948239355950655
                        ],
                        [
                            32.05287029580296,
                            46.948321640317374
                        ],
                        [
                            32.05247381445331,
                            46.94849706849416
                        ],
                        [
                            32.05204306928329,
                            46.94869254492815
                        ],
                        [
                            32.05114377784852,
                            46.94905858803879
                        ],
                        [
                            32.051343901808195,
                            46.94926975981439
                        ],
                        [
                            32.051617218944756,
                            46.94916034888024
                        ],
                        [
                            32.05177966960179,
                            46.949337782921326
                        ],
                        [
                            32.051587849955894,
                            46.94941429788489
                        ],
                        [
                            32.051653930180834,
                            46.94948279708114
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 71,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.048131409606796,
                            46.94398366446203
                        ],
                        [
                            32.047911142190316,
                            46.94392852530523
                        ],
                        [
                            32.04803946107772,
                            46.943672553629305
                        ],
                        [
                            32.045148009821325,
                            46.942949378092756
                        ],
                        [
                            32.043965908019544,
                            46.94512152347352
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 72,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.045148009821325,
                            46.942949378092756
                        ],
                        [
                            32.044844530269756,
                            46.94287418686673
                        ],
                        [
                            32.04572315251995,
                            46.94114141757844
                        ],
                        [
                            32.04542456779983,
                            46.941067894786904
                        ],
                        [
                            32.04554204375528,
                            46.940833957960265
                        ],
                        [
                            32.04491550532618,
                            46.94069025340282
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 73,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04466800823748,
                            46.94269105193125
                        ],
                        [
                            32.04529240734993,
                            46.94284578126499
                        ],
                        [
                            32.04558120240711,
                            46.942514938440375
                        ],
                        [
                            32.04588957679018,
                            46.941926768370635
                        ],
                        [
                            32.045659519710746,
                            46.941863272351654
                        ],
                        [
                            32.04591894577905,
                            46.941392062699926
                        ],
                        [
                            32.046075580386315,
                            46.94143884965931
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 74,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04560472869427,
                            46.94247006640005
                        ],
                        [
                            32.04605110622894,
                            46.94257843368646
                        ],
                        [
                            32.04595320959939,
                            46.942732158707244
                        ],
                        [
                            32.04702028286146,
                            46.94300618917124
                        ],
                        [
                            32.0474216590426,
                            46.942287691679425
                        ],
                        [
                            32.04812161994387,
                            46.942464810561326
                        ],
                        [
                            32.04818525275307,
                            46.942354529062186
                        ],
                        [
                            32.04763213679614,
                            46.942220854213225
                        ],
                        [
                            32.04778877140341,
                            46.94190337510925
                        ],
                        [
                            32.047514660840676,
                            46.94183653716324
                        ],
                        [
                            32.04769087477386,
                            46.941495662340735
                        ],
                        [
                            32.04697133454669,
                            46.94131185638872
                        ],
                        [
                            32.04702517769293,
                            46.941191546696636
                        ],
                        [
                            32.04636927027496,
                            46.941041159201305
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 75,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.047077257372344,
                            46.94290420053963
                        ],
                        [
                            32.047470607357376,
                            46.94300618917124
                        ],
                        [
                            32.0474020797167,
                            46.94310978568863
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 76,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04818525275307,
                            46.942354529062186
                        ],
                        [
                            32.048552365113885,
                            46.9424380756733
                        ],
                        [
                            32.04845446848434,
                            46.942621877758825
                        ],
                        [
                            32.048713894552634,
                            46.94268871472438
                        ],
                        [
                            32.04864047208048,
                            46.94282907208043
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 77,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04719649679464,
                            46.94082059011073
                        ],
                        [
                            32.04711817949101,
                            46.94096763627208
                        ],
                        [
                            32.04774961275159,
                            46.94130517252403
                        ],
                        [
                            32.0479527482579,
                            46.941502346181636
                        ],
                        [
                            32.04813140960683,
                            46.941709544835966
                        ],
                        [
                            32.048356571854775,
                            46.942113914093724
                        ],
                        [
                            32.048317413202966,
                            46.94218743544884
                        ],
                        [
                            32.04880689635071,
                            46.94231442664255
                        ],
                        [
                            32.04887052915991,
                            46.942214170462016
                        ],
                        [
                            32.049183798374465,
                            46.942281007936565
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 78,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04880689635071,
                            46.94231442664255
                        ],
                        [
                            32.049242536352196,
                            46.94266532179592
                        ],
                        [
                            32.05027137339326,
                            46.942924277032475
                        ],
                        [
                            32.04966288000532,
                            46.944022512470234
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 79,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.048242461095974,
                            46.94489407892012
                        ],
                        [
                            32.04817255678394,
                            46.94501646885497
                        ],
                        [
                            32.047736916782455,
                            46.94490619261215
                        ],
                        [
                            32.04743588464659,
                            46.94552106331057
                        ],
                        [
                            32.047901312708774,
                            46.94563201281701
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 80,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05197465645843,
                            46.94872039143193
                        ],
                        [
                            32.0500986729703,
                            46.946536921165084
                        ],
                        [
                            32.05067626308463,
                            46.94675412511258
                        ],
                        [
                            32.05202234174091,
                            46.94583183766145
                        ],
                        [
                            32.052560773203425,
                            46.94617936813197
                        ],
                        [
                            32.05287404241797,
                            46.94596216185231
                        ],
                        [
                            32.05323136511583,
                            46.94615597672882
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 81,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0500986729703,
                            46.946536921165084
                        ],
                        [
                            32.04752888644467,
                            46.948317967517205
                        ],
                        [
                            32.047876419479564,
                            46.94854518868042
                        ],
                        [
                            32.048223952514455,
                            46.9483213090119
                        ],
                        [
                            32.04842953543651,
                            46.9484683345714
                        ],
                        [
                            32.048742804651056,
                            46.948488383480054
                        ],
                        [
                            32.049535767350406,
                            46.94939057659651
                        ],
                        [
                            32.04901691521379,
                            46.94957101339496
                        ],
                        [
                            32.04867427701038,
                            46.94919343199173
                        ],
                        [
                            32.04796452644616,
                            46.949494160758874
                        ],
                        [
                            32.04794372341239,
                            46.949502514311625
                        ],
                        [
                            32.04821416285151,
                            46.949846679551364
                        ],
                        [
                            32.0473673570059,
                            46.95018917188911
                        ],
                        [
                            32.04695374374608,
                            46.94991517819425
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 82,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.047775061081,
                            46.95002427542453
                        ],
                        [
                            32.04786418240088,
                            46.9501206735969
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 83,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04804945714858,
                            46.94991329527786
                        ],
                        [
                            32.04778097026576,
                            46.949502514311625
                        ],
                        [
                            32.04761209857978,
                            46.94955096489184
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 84,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04835114250457,
                            46.94933034768458
                        ],
                        [
                            32.048512747571635,
                            46.94948246578284
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 85,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04774391464422,
                            46.94845855558298
                        ],
                        [
                            32.04745301655678,
                            46.948665481846916
                        ],
                        [
                            32.04815297745805,
                            46.94915834685882
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 86,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.048391118476964,
                            46.9477203854908
                        ],
                        [
                            32.04852498465033,
                            46.94789359599702
                        ],
                        [
                            32.0490732057758,
                            46.94854351794011
                        ],
                        [
                            32.04962142690126,
                            46.94915166397377
                        ],
                        [
                            32.05001301341945,
                            46.949582708351656
                        ],
                        [
                            32.05044375858945,
                            46.950155758099
                        ],
                        [
                            32.05023572825167,
                            46.95022091497043
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 87,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05006445649274,
                            46.949651146911556
                        ],
                        [
                            32.049827009823304,
                            46.94974977888247
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 88,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04963119489532,
                            46.949162416273104
                        ],
                        [
                            32.04990165600335,
                            46.94905142059767
                        ],
                        [
                            32.05010968634113,
                            46.94928365079929
                        ],
                        [
                            32.05035075679139,
                            46.94918674911104
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 89,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04959450532814,
                            46.94813251423527
                        ],
                        [
                            32.049990986677805,
                            46.94786853458155
                        ],
                        [
                            32.05067136825316,
                            46.948206027324794
                        ],
                        [
                            32.050984637467714,
                            46.9479587556223
                        ],
                        [
                            32.051204904884194,
                            46.9480890746349
                        ],
                        [
                            32.05138033296462,
                            46.948028663810966
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 90,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05004972465554,
                            46.94830961377958
                        ],
                        [
                            32.05040905190974,
                            46.948075909617245
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 91,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05170662511063,
                            46.94948246578284
                        ],
                        [
                            32.05183878556053,
                            46.94943401514061
                        ],
                        [
                            32.05201010466223,
                            46.949370528025796
                        ],
                        [
                            32.05277859320419,
                            46.95016578223822
                        ],
                        [
                            32.05294991230589,
                            46.95010396668305
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 92,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.051594043986654,
                            46.949896800518175
                        ],
                        [
                            32.05238240466652,
                            46.94975579633428
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 93,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.051679879870825,
                            46.94945492480001
                        ],
                        [
                            32.05165400567226,
                            46.949426496933576
                        ],
                        [
                            32.05183633814479,
                            46.94934797337477
                        ],
                        [
                            32.0516601242116,
                            46.94914581644864
                        ],
                        [
                            32.0519758408419,
                            46.94900881710598
                        ],
                        [
                            32.052577905113615,
                            46.949708011298654
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 94,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04369897565842,
                            46.94590596658513
                        ],
                        [
                            32.045847806676996,
                            46.94646401845128
                        ],
                        [
                            32.04577438420483,
                            46.94658765789783
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 95,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.044275539524435,
                            46.94605570091312
                        ],
                        [
                            32.043965908019544,
                            46.94512152347352
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 96,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04298433026272,
                            46.945047156838754
                        ],
                        [
                            32.043131175207044,
                            46.9447798202536
                        ],
                        [
                            32.04404302468636,
                            46.94497982211182
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 97,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.045847806676996,
                            46.94646401845128
                        ],
                        [
                            32.04628344667848,
                            46.94568207587783
                        ],
                        [
                            32.047027461063045,
                            46.94585250023267
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 98,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04577438420483,
                            46.94658765789783
                        ],
                        [
                            32.0456520134179,
                            46.94680486163944
                        ],
                        [
                            32.04500589566288,
                            46.946647806714516
                        ],
                        [
                            32.044814997235264,
                            46.94698196557646
                        ],
                        [
                            32.04527021656266,
                            46.94709557911433
                        ],
                        [
                            32.04477094375197,
                            46.94795435599724
                        ],
                        [
                            32.04465346779651,
                            46.94792094081195
                        ],
                        [
                            32.04453109700957,
                            46.94814816366056
                        ],
                        [
                            32.044707310942755,
                            46.9482183352277
                        ],
                        [
                            32.04461430914469,
                            46.94838206852661
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 99,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0456520134179,
                            46.94680486163944
                        ],
                        [
                            32.045888367506194,
                            46.94685846951446
                        ],
                        [
                            32.045960387800974,
                            46.94672633423458
                        ],
                        [
                            32.04600688870001,
                            46.94663611111656
                        ],
                        [
                            32.0471131206139,
                            46.9469117923885
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 100,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04672322629022,
                            46.94681462796709
                        ],
                        [
                            32.046890405781674,
                            46.94650578856651
                        ],
                        [
                            32.04731870353595,
                            46.946616061514135
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 101,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04453109700957,
                            46.94814816366056
                        ],
                        [
                            32.042154656327305,
                            46.94730860384786
                        ],
                        [
                            32.042203604642076,
                            46.94719833232692
                        ],
                        [
                            32.04084773632284,
                            46.94686083323003
                        ],
                        [
                            32.04129316598727,
                            46.946058846641996
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 102,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04175817497763,
                            46.94832108628502
                        ],
                        [
                            32.042282604895746,
                            46.9473538064376
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 103,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.043069989813574,
                            46.94259847091622
                        ],
                        [
                            32.043711212737115,
                            46.94274885403711
                        ],
                        [
                            32.0437552662204,
                            46.942671991605884
                        ],
                        [
                            32.04455312375122,
                            46.942875843899145
                        ],
                        [
                            32.04466800823748,
                            46.94269105193125
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 104,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04275182576754,
                            46.94290926223381
                        ],
                        [
                            32.042487504867765,
                            46.94340719294947
                        ],
                        [
                            32.04413216824417,
                            46.94381823293663
                        ],
                        [
                            32.04428880285145,
                            46.94352081407455
                        ],
                        [
                            32.04477379371429,
                            46.943637020271616
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 105,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04413216824417,
                            46.94381823293663
                        ],
                        [
                            32.043867847344394,
                            46.944279395994734
                        ],
                        [
                            32.04353499880393,
                            46.94419585225714
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 106,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05370248156338,
                            46.947437462515325
                        ],
                        [
                            32.053866458417865,
                            46.94744080406498
                        ],
                        [
                            32.055043665388176,
                            46.94698969297542
                        ],
                        [
                            32.05488458336516,
                            46.94679755191243
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 107,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.054240913025886,
                            46.9462144413335
                        ],
                        [
                            32.054909153316196,
                            46.947041238808346
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 108,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.053920646552065,
                            46.94698977373044
                        ],
                        [
                            32.05428992613202,
                            46.947403184647406
                        ],
                        [
                            32.054590893476515,
                            46.947288763344716
                        ],
                        [
                            32.054681447858854,
                            46.94737731458597
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 109,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02630811795277,
                            46.941377160051054
                        ],
                        [
                            32.026175957502886,
                            46.941636158849285
                        ],
                        [
                            32.0264867793017,
                            46.94170968086047
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 110,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.026528385369254,
                            46.941718035628085
                        ],
                        [
                            32.0270056314383,
                            46.941841686036256
                        ],
                        [
                            32.02704479009012,
                            46.941773177061606
                        ],
                        [
                            32.02863805773601,
                            46.94217420396055
                        ],
                        [
                            32.02895132695056,
                            46.941557623862195
                        ],
                        [
                            32.02928907032251,
                            46.941639500761084
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 111,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02863805773601,
                            46.94217420396055
                        ],
                        [
                            32.02843492222971,
                            46.94256353136783
                        ],
                        [
                            32.029453047177,
                            46.942830879020754
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 112,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.028809174989014,
                            46.94266180590187
                        ],
                        [
                            32.02891706313023,
                            46.942780751437546
                        ],
                        [
                            32.02879224492756,
                            46.94301802158346
                        ],
                        [
                            32.02888035189415,
                            46.943044756181855
                        ],
                        [
                            32.02881182425346,
                            46.94318511260448
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 113,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02734337481025,
                            46.94288434839115
                        ],
                        [
                            32.02744616627127,
                            46.942703889052105
                        ],
                        [
                            32.02729932132695,
                            46.94266378689415
                        ],
                        [
                            32.027451061102745,
                            46.94238975467751
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 114,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02808738919481,
                            46.942807486154436
                        ],
                        [
                            32.027039895258646,
                            46.94255350580489
                        ],
                        [
                            32.02701052626979,
                            46.942620342855804
                        ],
                        [
                            32.02671683638114,
                            46.942540138384686
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 115,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02671683638114,
                            46.942540138384686
                        ],
                        [
                            32.026932208966144,
                            46.94214579798739
                        ],
                        [
                            32.026755995032964,
                            46.94210569541139
                        ],
                        [
                            32.02688448435926,
                            46.9418609019524
                        ],
                        [
                            32.02732012436074,
                            46.94195948784865
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 116,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02739966537225,
                            46.94197285541382
                        ],
                        [
                            32.02745113206952,
                            46.94187545398851
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 117,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02688448435926,
                            46.9418609019524
                        ],
                        [
                            32.02670582301033,
                            46.94181495083704
                        ],
                        [
                            32.027142686719685,
                            46.94104714363796
                        ],
                        [
                            32.02719408245019,
                            46.940953568993706
                        ],
                        [
                            32.02764930177759,
                            46.941058840456996
                        ],
                        [
                            32.02759545863133,
                            46.94116076977184
                        ],
                        [
                            32.02851079211761,
                            46.94138635035243
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 118,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02764930177759,
                            46.941058840456996
                        ],
                        [
                            32.02788156709054,
                            46.94061669824997
                        ],
                        [
                            32.02775454065435,
                            46.9405876237252
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 119,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02788156709054,
                            46.94061669824997
                        ],
                        [
                            32.02800172964396,
                            46.940395459683096
                        ],
                        [
                            32.02837863166772,
                            46.9404806803434
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 120,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02788156709054,
                            46.94061669824997
                        ],
                        [
                            32.02884608807382,
                            46.940851639284396
                        ],
                        [
                            32.028733506949834,
                            46.941055498508966
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 121,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02884608807382,
                            46.940851639284396
                        ],
                        [
                            32.02895622178205,
                            46.94065613421691
                        ],
                        [
                            32.0293135444799,
                            46.940743025446146
                        ],
                        [
                            32.02936004537894,
                            46.94065446323035
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 122,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03014566583108,
                            46.94158686562615
                        ],
                        [
                            32.030375722910506,
                            46.941647020061815
                        ],
                        [
                            32.03071836111393,
                            46.9410053692634
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 123,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.030077138190386,
                            46.94085832320568
                        ],
                        [
                            32.03115400111541,
                            46.94110562770757
                        ],
                        [
                            32.03118337010428,
                            46.94103210486688
                        ],
                        [
                            32.031594535948386,
                            46.94113904714724
                        ],
                        [
                            32.03181480336487,
                            46.94073467052638
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 124,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03173702382892,
                            46.94087746197168
                        ],
                        [
                            32.031335109880075,
                            46.9407705966721
                        ],
                        [
                            32.03163736572379,
                            46.94023587945437
                        ],
                        [
                            32.03179400033107,
                            46.94027598343094
                        ],
                        [
                            32.031825816735676,
                            46.94021749845508
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 125,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03179400033107,
                            46.94027598343094
                        ],
                        [
                            32.032604094940574,
                            46.94046647690944
                        ],
                        [
                            32.03268485965996,
                            46.94032277136564
                        ],
                        [
                            32.033857171798786,
                            46.94061686601898
                        ],
                        [
                            32.033783749326616,
                            46.94075388683988
                        ],
                        [
                            32.034175335844814,
                            46.940847461833144
                        ],
                        [
                            32.034424972250164,
                            46.94038961120144
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 126,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03431323747163,
                            46.940594541086156
                        ],
                        [
                            32.034609543375105,
                            46.94066766586281
                        ],
                        [
                            32.034689211531465,
                            46.94052668567408
                        ],
                        [
                            32.03592975853765,
                            46.94086858974221
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 127,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03285538752922,
                            46.94036555131361
                        ],
                        [
                            32.033152316066044,
                            46.93982815398741
                        ],
                        [
                            32.033842487304355,
                            46.93999525496412
                        ],
                        [
                            32.03401870123754,
                            46.93964100027437
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 128,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.033583061236044,
                            46.94042804406923
                        ],
                        [
                            32.03338726797696,
                            46.94080568730277
                        ],
                        [
                            32.03354145516849,
                            46.94084244889122
                        ],
                        [
                            32.03333097741496,
                            46.941253508570014
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 129,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.032071227890626,
                            46.94034117347583
                        ],
                        [
                            32.03232019471488,
                            46.939824811962566
                        ],
                        [
                            32.03209503246693,
                            46.93977133953653
                        ],
                        [
                            32.03213419111874,
                            46.93969781486356
                        ],
                        [
                            32.031928608196694,
                            46.93964100027438
                        ],
                        [
                            32.03207545314101,
                            46.939333532039896
                        ],
                        [
                            32.03219292909646,
                            46.93936361053204
                        ],
                        [
                            32.03227614123158,
                            46.939209875839055
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 130,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.079262056076466,
                            46.91381021207692
                        ],
                        [
                            32.07901195137834,
                            46.91391778094173
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 131,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07914639282913,
                            46.91476931730296
                        ],
                        [
                            32.07928850935681,
                            46.91487405393785
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 132,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.050775072613824,
                            46.94902533959989
                        ],
                        [
                            32.051001018034825,
                            46.948933850603524
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 133,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05086317958042,
                            46.948783083881196
                        ],
                        [
                            32.051078552165436,
                            46.949018656698215
                        ],
                        [
                            32.051590062054814,
                            46.94882652291833
                        ],
                        [
                            32.0516244862576,
                            46.94886292319142
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 134,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.051590062054814,
                            46.94882652291833
                        ],
                        [
                            32.051519086998404,
                            46.94874799847932
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 135,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.013793488997656,
                            46.95622279780142
                        ],
                        [
                            32.013781025795595,
                            46.95622468504486
                        ],
                        [
                            32.013793488997656,
                            46.95622279780142
                        ],
                        [
                            32.01379550916129,
                            46.95628149852187
                        ],
                        [
                            32.01417645430334,
                            46.9562625882297
                        ],
                        [
                            32.01429371519176,
                            46.956200654105636
                        ],
                        [
                            32.01474950011046,
                            46.956180828920594
                        ],
                        [
                            32.01461822560246,
                            46.95499358396492
                        ],
                        [
                            32.0143921417276,
                            46.95500354000697
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 136,
                id: 7,
                district: 7,
                n_type: 7,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.914045604572845,
                            47.015652795363586
                        ],
                        [
                            31.91419875099845,
                            47.01508721107256
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 137,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01449059760859,
                            46.95591202104876
                        ],
                        [
                            32.014718624139654,
                            46.95590158940829
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 138,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01443589989693,
                            46.955461515939376
                        ],
                        [
                            32.01466878371386,
                            46.95545083399056
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 139,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01380712113089,
                            46.960478461607316
                        ],
                        [
                            32.01375767661833,
                            46.96011147246903
                        ],
                        [
                            32.01505250479114,
                            46.96002077936304
                        ],
                        [
                            32.01511431043185,
                            46.96040675128393
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 140,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.014708664815664,
                            46.960044862817035
                        ],
                        [
                            32.014768198843896,
                            46.96041940605386
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 141,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01409688934021,
                            46.96008771314964
                        ],
                        [
                            32.014153232718854,
                            46.960461588598704
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 142,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.014045072847615,
                            46.959685424449894
                        ],
                        [
                            32.01443135810203,
                            46.95966222371778
                        ],
                        [
                            32.01448754194123,
                            46.9600603508376
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 143,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01500615056062,
                            46.95982462863127
                        ],
                        [
                            32.014962886612125,
                            46.95953356492987
                        ],
                        [
                            32.01491035181753,
                            46.95935217660498
                        ],
                        [
                            32.01487017815107,
                            46.959046346060106
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 144,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01470948348522,
                            46.95987735739327
                        ],
                        [
                            32.0146631292547,
                            46.95957363901174
                        ],
                        [
                            32.01462604587027,
                            46.95936904996347
                        ],
                        [
                            32.01458278192178,
                            46.9590716562405
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 145,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.014358300977214,
                            46.959666611612626
                        ],
                        [
                            32.01428302456435,
                            46.95913493163915
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 146,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.014329769475886,
                            46.95946509322874
                        ],
                        [
                            32.01493168361018,
                            46.959425829544166
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 147,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.014013783858495,
                            46.958747918262496
                        ],
                        [
                            32.01410378820629,
                            46.959473453750796
                        ],
                        [
                            32.01416723132751,
                            46.95967808747361
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 148,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01392764213027,
                            46.9592836285314
                        ],
                        [
                            32.014078910550616,
                            46.9592729130664
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 149,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01388437818177,
                            46.958756332721705
                        ],
                        [
                            32.014013783858495,
                            46.958747918262496
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 150,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00387881386882,
                            46.95535270830608
                        ],
                        [
                            32.004032641241295,
                            46.95650267021577
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 151,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00391321637183,
                            46.95560989204027
                        ],
                        [
                            32.00457517496835,
                            46.95558563212922
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 152,
                id: 1,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00398212449411,
                            46.956125026660516
                        ],
                        [
                            32.004591656472535,
                            46.956103115794306
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 153,
                id: 2,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98560668651998,
                            46.978300073758255
                        ],
                        [
                            31.985899691038963,
                            46.97876486189814
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 154,
                id: 2,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.985696940717116,
                            46.97844324290361
                        ],
                        [
                            31.98535030756587,
                            46.97853246833322
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 155,
                id: 2,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.985829391681737,
                            46.978653347578664
                        ],
                        [
                            31.98646738729449,
                            46.9784774933637
                        ],
                        [
                            31.98661022699749,
                            46.978380037596956
                        ],
                        [
                            31.987660811052294,
                            46.97807443678665
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 156,
                id: 2,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98661022699749,
                            46.978380037596956
                        ],
                        [
                            31.986764054369964,
                            46.97861493068156
                        ],
                        [
                            31.986591914215058,
                            46.97865991009067
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 157,
                id: 2,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98689759558529,
                            46.97829644610271
                        ],
                        [
                            31.986672490457774,
                            46.97796522388483
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 158,
                id: 2,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98778406802855,
                            46.97827382424026
                        ],
                        [
                            31.987329911024126,
                            46.97753915051504
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 159,
                id: 2,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.987507974899724,
                            46.977827199352525
                        ],
                        [
                            31.987864644271276,
                            46.97771407374614
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 160,
                id: 2,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98512904749172,
                            46.977342714041534
                        ],
                        [
                            31.985857896232687,
                            46.97711531215019
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 161,
                id: 2,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.985510132088322,
                            46.977223815201164
                        ],
                        [
                            31.985636311565212,
                            46.97742018039906
                        ],
                        [
                            31.9851363726047,
                            46.97758635785193
                        ],
                        [
                            31.985235261629846,
                            46.977715051313986
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 162,
                id: 2,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.993951780078298,
                            46.97492791490391
                        ],
                        [
                            31.993956238893613,
                            46.97493704189736
                        ],
                        [
                            31.993951780078298,
                            46.97493704189736
                        ],
                        [
                            31.993956238893613,
                            46.97493704189736
                        ],
                        [
                            31.993960697708932,
                            46.97493399956639
                        ],
                        [
                            31.993015428861785,
                            46.97522910486554
                        ],
                        [
                            31.993024346492412,
                            46.97522606255118
                        ],
                        [
                            31.993015428861785,
                            46.97522910486554
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 163,
                id: 2,
                district: 2,
                n_type: -1,
                OKP_comm: "",
                diameter_1: 89,
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99545996875736,
                            46.97385587602862
                        ],
                        [
                            31.99545813747912,
                            46.973865872345755
                        ],
                        [
                            31.994954535962115,
                            46.974027062701545
                        ],
                        [
                            31.995064412656742,
                            46.974192001167324
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 164,
                id: 2,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99509005055215,
                            46.974194500231064
                        ],
                        [
                            31.995236552811637,
                            46.97414701799983
                        ],
                        [
                            31.99548011281805,
                            46.97452312609691
                        ],
                        [
                            31.99473478257288,
                            46.97474304189825
                        ],
                        [
                            31.994524185574864,
                            46.974430661228574
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 165,
                id: 7,
                district: 7,
                n_type: 7,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99512768177079,
                            47.0034606072401
                        ],
                        [
                            31.994744838042273,
                            47.00319983216972
                        ],
                        [
                            31.994089061786745,
                            47.00316629129329
                        ],
                        [
                            31.994150487049176,
                            47.00216681991366
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 166,
                id: 2,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.992519131610212,
                            46.973165242296176
                        ],
                        [
                            31.991707883469253,
                            46.973415155661925
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 167,
                id: 7,
                district: 7,
                n_type: 7,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.994126168402826,
                            47.00256251913241
                        ],
                        [
                            31.993733849648322,
                            47.00253646425884
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 168,
                id: 2,
                district: 2,
                n_type: 1,
                OKP_comm: "",
                diameter_1: 57,
                layingMeth: "воздушка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.997162361046612,
                            46.97123428304431
                        ],
                        [
                            31.996790611563153,
                            46.97134049902484
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 169,
                id: 7,
                district: 7,
                n_type: 7,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99512190938921,
                            47.00360232104439
                        ],
                        [
                            31.99230753629253,
                            47.00352405955624
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 170,
                id: 7,
                district: 7,
                n_type: 7,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.996143827387424,
                            47.00393399941203
                        ],
                        [
                            31.9961175564992,
                            47.004141484309045
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 171,
                id: 7,
                district: 7,
                n_type: 7,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.995277505498514,
                            47.002776786333136
                        ],
                        [
                            31.995248683083652,
                            47.00303471729736
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 172,
                id: 2,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00476423689961,
                            46.97062518652027
                        ],
                        [
                            32.004833825472865,
                            46.9704677347679
                        ],
                        [
                            32.005372221276495,
                            46.97033777424215
                        ],
                        [
                            32.0054482193236,
                            46.970213436712775
                        ],
                        [
                            32.005503157670915,
                            46.970162826881875
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 173,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98224653852839,
                            46.9736029064545
                        ],
                        [
                            31.983708241931105,
                            46.97314533649239
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 174,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98329863595502,
                            46.97327355947368
                        ],
                        [
                            31.983393033163505,
                            46.973419457216345
                        ],
                        [
                            31.983606262623944,
                            46.97335198147619
                        ],
                        [
                            31.98374659644938,
                            46.9735582229679
                        ],
                        [
                            31.984094527185533,
                            46.97345741228278
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 175,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98411924944181,
                            46.97351223622006
                        ],
                        [
                            31.984273147676525,
                            46.97372458222034
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 176,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.984273763543584,
                            46.973880187729264
                        ],
                        [
                            31.984185690505576,
                            46.97375261743542
                        ],
                        [
                            31.984646142528835,
                            46.973605015062795
                        ],
                        [
                            31.984743486412956,
                            46.9737462916278
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 177,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.984103798031637,
                            46.97352067066696
                        ],
                        [
                            31.98363407516225,
                            46.97365035012059
                        ],
                        [
                            31.98352900557305,
                            46.97381271257042
                        ],
                        [
                            31.98301601875517,
                            46.97397296593364
                        ],
                        [
                            31.98313344947252,
                            46.97414587033958
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 178,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98352900557305,
                            46.97381271257042
                        ],
                        [
                            31.983712877354147,
                            46.97407470548408
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 179,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.983607706035478,
                            46.97392485045379
                        ],
                        [
                            31.984040447249896,
                            46.97379215371602
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 180,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98508206111796,
                            46.97465714469992
                        ],
                        [
                            31.985272113463136,
                            46.974933366230545
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 181,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98499398807994,
                            46.97468877319179
                        ],
                        [
                            31.984501088095303,
                            46.974831101173706
                        ],
                        [
                            31.984433101890524,
                            46.974725673075326
                        ],
                        [
                            31.98405145205916,
                            46.97484164397211
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 182,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98422296271212,
                            46.97528127681519
                        ],
                        [
                            31.984012823533714,
                            46.97496077744966
                        ],
                        [
                            31.984501088095303,
                            46.974831101173706
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 183,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.981152444283243,
                            46.97421972845153
                        ],
                        [
                            31.98111072547577,
                            46.97415330801442
                        ],
                        [
                            31.981560361511914,
                            46.97402046689272
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 184,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99518733853853,
                            46.96812064844792
                        ],
                        [
                            31.995560990252947,
                            46.96800677293058
                        ],
                        [
                            31.995673448535925,
                            46.968128075103664
                        ],
                        [
                            31.995716980774493,
                            46.968150355064736
                        ],
                        [
                            31.996166813906417,
                            46.96880637198118
                        ],
                        [
                            31.99573149152069,
                            46.96894252542729
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 185,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00075665620113,
                            46.96680800845878
                        ],
                        [
                            32.00048095202351,
                            46.966654520242486
                        ],
                        [
                            32.00046644127731,
                            46.96654064160386
                        ],
                        [
                            31.999403529118833,
                            46.966590154085225
                        ],
                        [
                            31.99912782494121,
                            46.96659262970809
                        ],
                        [
                            31.998598182705244,
                            46.966572824721965
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 186,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.999156846433582,
                            46.9678576580042
                        ],
                        [
                            31.999835223818007,
                            46.967642283479876
                        ],
                        [
                            31.99959869682146,
                            46.96730387659986
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 187,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.999058898896802,
                            46.96747641974939
                        ],
                        [
                            32.00003474657814,
                            46.967164495519846
                        ],
                        [
                            31.999980331279914,
                            46.96709022757803
                        ],
                        [
                            32.00060066567958,
                            46.96689960605543
                        ],
                        [
                            32.000662336350885,
                            46.966971398656675
                        ],
                        [
                            32.000829209932085,
                            46.96692188652819
                        ],
                        [
                            32.00077842232041,
                            46.96684761824955
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 188,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.999437594640128,
                            46.967355371998316
                        ],
                        [
                            31.999193123299058,
                            46.96700110591176
                        ],
                        [
                            31.999349113820617,
                            46.96694664259816
                        ],
                        [
                            31.999454316730503,
                            46.96709517877737
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 189,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00017078482367,
                            46.967030194249716
                        ],
                        [
                            32.00008553418981,
                            46.96694416699168
                        ],
                        [
                            32.00003837426468,
                            46.966689178910464
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 190,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00003474657814,
                            46.967164495519846
                        ],
                        [
                            32.00016262252894,
                            46.967217101916226
                        ],
                        [
                            32.00021341014062,
                            46.9672864185007
                        ],
                        [
                            32.000325868423594,
                            46.96725423581194
                        ],
                        [
                            32.000460092825854,
                            46.967432478152524
                        ],
                        [
                            32.0006378494667,
                            46.967378015278136
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 191,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: "",
                diameter_1: 57,
                layingMeth: "воздушка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.977312080919095,
                            46.97744458621821
                        ],
                        [
                            31.977437603993256,
                            46.97753163278879
                        ],
                        [
                            31.97737998684446,
                            46.97775065256538
                        ],
                        [
                            31.97744789276983,
                            46.97784752641116
                        ],
                        [
                            31.977402622152923,
                            46.977976691265866
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 192,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00831278758107,
                            46.86069010390624
                        ],
                        [
                            32.012564304193305,
                            46.860217569934235
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 193,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.987355505054317,
                            46.978163246386494
                        ],
                        [
                            31.98708412322212,
                            46.977772228868275
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 194,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.9851363726047,
                            46.977586357851926
                        ],
                        [
                            31.984884306578113,
                            46.97766573507684
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 195,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: "",
                diameter_1: 57,
                layingMeth: "воздушка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00298770991326,
                            46.97345068768725
                        ],
                        [
                            32.00317336838345,
                            46.97339070970231
                        ],
                        [
                            32.003183123667895,
                            46.973314993090575
                        ],
                        [
                            32.00347578220147,
                            46.97289563760777
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 196,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: "",
                diameter_1: 100,
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00289046513433,
                            46.97347807489029
                        ],
                        [
                            32.002770962899795,
                            46.973513020925516
                        ],
                        [
                            32.00235677281663,
                            46.972907897851904
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 197,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: "",
                diameter_1: 57,
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.001553991164364,
                            46.973038751356455
                        ],
                        [
                            32.00162227815552,
                            46.97313693382299
                        ],
                        [
                            32.00235677281663,
                            46.972907897851904
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 198,
                id: 7,
                district: 7,
                n_type: 7,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.001974702747304,
                            47.019603895446956
                        ],
                        [
                            32.001872501391134,
                            47.0194994479909
                        ],
                        [
                            32.00161227271831,
                            47.01962008716229
                        ],
                        [
                            32.001976592860274,
                            47.01995361757049
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 199,
                id: 1,
                district: 2,
                n_type: 1,
                OKP_comm: "",
                diameter_1: 57,
                layingMeth: "воздушка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00237587387947,
                            46.97327172640719
                        ],
                        [
                            32.00275632997312,
                            46.973151910793575
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 200,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.960677341093415,
                            46.97332815512178
                        ],
                        [
                            31.960647560172074,
                            46.97325533696701
                        ],
                        [
                            31.95983257073099,
                            46.97338893430772
                        ],
                        [
                            31.959440984212737,
                            46.972889612539475
                        ],
                        [
                            31.95928434960544,
                            46.972548935480326
                        ],
                        [
                            31.95917421589719,
                            46.97239696609222
                        ],
                        [
                            31.959044502863012,
                            46.972069645943456
                        ],
                        [
                            31.958959188480144,
                            46.971560523117
                        ],
                        [
                            31.95863657199157,
                            46.97169585553029
                        ],
                        [
                            31.958345688272356,
                            46.971822165473746
                        ],
                        [
                            31.958292800323413,
                            46.971843818576964
                        ],
                        [
                            31.957748054449247,
                            46.971934039745975
                        ],
                        [
                            31.9574386599479,
                            46.97158398075983
                        ],
                        [
                            31.956846314919687,
                            46.971822165473746
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 201,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.956989774576517,
                            46.971764479770265
                        ],
                        [
                            31.957528569461115,
                            46.97232018462961
                        ],
                        [
                            31.95721124176742,
                            46.97246092833318
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 202,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.958959188480137,
                            46.971560523117
                        ],
                        [
                            31.958134841389942,
                            46.97052187268119
                        ],
                        [
                            31.957583255286846,
                            46.969381516075366
                        ],
                        [
                            31.957723461505857,
                            46.96937047662868
                        ],
                        [
                            31.957615610568155,
                            46.96903193249094
                        ],
                        [
                            31.95704400059836,
                            46.96907609045031
                        ],
                        [
                            31.95686065400427,
                            46.967622535497405
                        ],
                        [
                            31.956637810738634,
                            46.96712946115985
                        ],
                        [
                            31.956496382347144,
                            46.966861148515015
                        ],
                        [
                            31.958013523274055,
                            46.966755860695685
                        ],
                        [
                            31.95800709471081,
                            46.96659354156819
                        ],
                        [
                            31.958566379713524,
                            46.96655844547586
                        ],
                        [
                            31.958579236840023,
                            46.96667689469519
                        ],
                        [
                            31.958656379599024,
                            46.96667250769174
                        ],
                        [
                            31.95869908091994,
                            46.96696807370293
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 203,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95831888002841,
                            46.966992757997744
                        ],
                        [
                            31.95910637902649,
                            46.96694230773431
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 204,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.9561484363613,
                            46.967938691627076
                        ],
                        [
                            31.95607772216555,
                            46.967565803845964
                        ],
                        [
                            31.956857396996565,
                            46.967627642371724
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 205,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.954213438823178,
                            46.967131495976865
                        ],
                        [
                            31.95443200997366,
                            46.966881438331384
                        ],
                        [
                            31.955769151129562,
                            46.96742103494807
                        ],
                        [
                            31.95601665081468,
                            46.9671643982116
                        ],
                        [
                            31.955814151072325,
                            46.96708762629905
                        ],
                        [
                            31.955814151072325,
                            46.96694724308827
                        ],
                        [
                            31.955910579521056,
                            46.966822213980976
                        ],
                        [
                            31.955187366155485,
                            46.966554606839374
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 206,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.955973530923455,
                            46.967148050562905
                        ],
                        [
                            31.956241650528415,
                            46.96683976194349
                        ],
                        [
                            31.956350936103657,
                            46.966883631824594
                        ],
                        [
                            31.956496382347144,
                            46.966861148515015
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 207,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.960647560172074,
                            46.97325533696701
                        ],
                        [
                            31.960647560172074,
                            46.97325533696701
                        ],
                        [
                            31.961385304698446,
                            46.97314075341913
                        ],
                        [
                            31.96162637582031,
                            46.973653968311275
                        ],
                        [
                            31.96186101837892,
                            46.974353599482754
                        ],
                        [
                            31.961957446827665,
                            46.974605815372065
                        ],
                        [
                            31.962131018035404,
                            46.97476153067504
                        ],
                        [
                            31.9622210179209,
                            46.975018130959704
                        ],
                        [
                            31.962576828715772,
                            46.97511517570143
                        ],
                        [
                            31.96249101757737,
                            46.97527911631103
                        ],
                        [
                            31.96408851554489,
                            46.97580985570154
                        ],
                        [
                            31.964094944108137,
                            46.97607851309567
                        ],
                        [
                            31.965162085607574,
                            46.97621229300894
                        ],
                        [
                            31.96597529885864,
                            46.97667065115755
                        ],
                        [
                            31.965792084806033,
                            46.97681978119133
                        ],
                        [
                            31.966479941073725,
                            46.97727374799877
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 208,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96609097191437,
                            46.97701703919191
                        ],
                        [
                            31.96567958494916,
                            46.97729348559863
                        ],
                        [
                            31.965862799001773,
                            46.97738778736404
                        ],
                        [
                            31.9662163699805,
                            46.977653147253314
                        ],
                        [
                            31.96591422750777,
                            46.97785490761016
                        ],
                        [
                            31.965734227736785,
                            46.977732097048836
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 209,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.965702084920533,
                            46.97708514389941
                        ],
                        [
                            31.96584635790583,
                            46.97718141662793
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 210,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96597529885864,
                            46.97667065115755
                        ],
                        [
                            31.967604939642403,
                            46.97688118755493
                        ],
                        [
                            31.967874939298873,
                            46.97688118755493
                        ],
                        [
                            31.967887796425376,
                            46.97672328533461
                        ],
                        [
                            31.96822672590146,
                            46.97673562708472
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 211,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.968009939127118,
                            46.9772145351555
                        ],
                        [
                            31.968209224587852,
                            46.97706102007128
                        ],
                        [
                            31.96823493884086,
                            46.97658292741397
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 212,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96700197897879,
                            46.976803290014736
                        ],
                        [
                            31.967064940329436,
                            46.976550853384296
                        ],
                        [
                            31.967604939642403,
                            46.97657936363383
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 213,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.967064940329436,
                            46.976550853384296
                        ],
                        [
                            31.96662779802847,
                            46.97646093634385
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 214,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.965735834877584,
                            46.97849828779393
                        ],
                        [
                            31.965812811930054,
                            46.97844382325829
                        ],
                        [
                            31.96530190685824,
                            46.97810683285652
                        ],
                        [
                            31.964759496834063,
                            46.97771811636435
                        ],
                        [
                            31.96433039023716,
                            46.9774121851774
                        ],
                        [
                            31.965196639135034,
                            46.976870496273456
                        ],
                        [
                            31.965113067812787,
                            46.97680689681142
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 215,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.964801433544302,
                            46.97711762971679
                        ],
                        [
                            31.965291460442955,
                            46.977360648210194
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 216,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96433039023715,
                            46.9774121851774
                        ],
                        [
                            31.964131104776428,
                            46.97751745201988
                        ],
                        [
                            31.96380806947314,
                            46.977300338930185
                        ],
                        [
                            31.963824140881254,
                            46.97672904219455
                        ],
                        [
                            31.962980391954765,
                            46.97629480873114
                        ],
                        [
                            31.963170034570624,
                            46.976176380810955
                        ],
                        [
                            31.96218646439344,
                            46.97544168322319
                        ],
                        [
                            31.962163964422064,
                            46.975332026001084
                        ],
                        [
                            31.962376107009298,
                            46.97495261027756
                        ],
                        [
                            31.96261074956791,
                            46.97472013457356
                        ],
                        [
                            31.964680746934263,
                            46.97180752312792
                        ],
                        [
                            31.96438824730642,
                            46.971714308169226
                        ],
                        [
                            31.964470211487853,
                            46.971592580155004
                        ],
                        [
                            31.964140747621308,
                            46.971491688257565
                        ],
                        [
                            31.96419378326812,
                            46.97136995973665
                        ],
                        [
                            31.963735748136592,
                            46.971121018565455
                        ],
                        [
                            31.964151997607004,
                            46.97032922440533
                        ],
                        [
                            31.96341100599676,
                            46.9701770122248
                        ],
                        [
                            31.96375379572077,
                            46.96951902802398
                        ],
                        [
                            31.964166461874303,
                            46.96961089684779
                        ],
                        [
                            31.964127890494808,
                            46.96970521216126
                        ],
                        [
                            31.964970032280505,
                            46.969900422397984
                        ],
                        [
                            31.96487681811338,
                            46.970080278389965
                        ],
                        [
                            31.964751461130014,
                            46.97004737794883
                        ],
                        [
                            31.964722532595392,
                            46.9701109854501
                        ],
                        [
                            31.964510390008158,
                            46.97007589166565
                        ],
                        [
                            31.964458961502167,
                            46.970181172949935
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 217,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96520146055748,
                            46.978139180081804
                        ],
                        [
                            31.96528786416969,
                            46.978096769242164
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 218,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96376237893215,
                            46.971071900238535
                        ],
                        [
                            31.9631009275157,
                            46.9709203294649
                        ],
                        [
                            31.962978784813963,
                            46.970940069409835
                        ],
                        [
                            31.962904856336586,
                            46.97080188964238
                        ],
                        [
                            31.9627762850716,
                            46.97080408297481
                        ],
                        [
                            31.962504678274303,
                            46.969072419023966
                        ],
                        [
                            31.962657356651473,
                            46.96892655749907
                        ],
                        [
                            31.96572378132152,
                            46.968722570037706
                        ],
                        [
                            31.965755924137767,
                            46.968902429990436
                        ],
                        [
                            31.96600342382288,
                            46.968979199298275
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 219,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.963477697711994,
                            46.97100666594184
                        ],
                        [
                            31.96338699858031,
                            46.97114514507327
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 220,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96340781255052,
                            46.970990651844325
                        ],
                        [
                            31.963558962647234,
                            46.97072183294711
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 221,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96419378326812,
                            46.97136995973665
                        ],
                        [
                            31.96497163942132,
                            46.97161231985181
                        ],
                        [
                            31.96536217463873,
                            46.97174830423182
                        ],
                        [
                            31.965444138820164,
                            46.97162986624285
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 222,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96367930914625,
                            46.969662005545565
                        ],
                        [
                            31.96359753402672,
                            46.96964270086741
                        ],
                        [
                            31.96370842674277,
                            46.96943542605461
                        ],
                        [
                            31.963774319516077,
                            46.96944968307805
                        ],
                        [
                            31.963893247936184,
                            46.969207313162876
                        ],
                        [
                            31.9646598541037,
                            46.96938278470441
                        ],
                        [
                            31.964754675411623,
                            46.96918428248031
                        ],
                        [
                            31.965460210228272,
                            46.969341110265425
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 223,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96370842674277,
                            46.96943542605461
                        ],
                        [
                            31.963274498723425,
                            46.9692380207242
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 224,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96400010774264,
                            46.97061815227554
                        ],
                        [
                            31.96425485461897,
                            46.97067577287632
                        ],
                        [
                            31.96431110454741,
                            46.9705715892366
                        ],
                        [
                            31.96482538960738,
                            46.97067686954514
                        ],
                        [
                            31.96475949683407,
                            46.97081285630361
                        ],
                        [
                            31.964966817998864,
                            46.970856722926136
                        ],
                        [
                            31.96521592482479,
                            46.97036541469875
                        ],
                        [
                            31.965079317855732,
                            46.97033141775713
                        ],
                        [
                            31.96528985330216,
                            46.96984887819121
                        ],
                        [
                            31.965537352987266,
                            46.969902615767374
                        ],
                        [
                            31.965722174180694,
                            46.96958018950053
                        ],
                        [
                            31.966344137675087,
                            46.96972495255464
                        ],
                        [
                            31.9664630660952,
                            46.969476003726705
                        ],
                        [
                            31.966130387947036,
                            46.96938936487601
                        ],
                        [
                            31.96635056623834,
                            46.96890133328522
                        ],
                        [
                            31.967547886143567,
                            46.968819080331414
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 225,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.966604990044477,
                            46.968883855001586
                        ],
                        [
                            31.96658038737451,
                            46.96867541153537
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 226,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.9664630660952,
                            46.969476003726705
                        ],
                        [
                            31.96672985147006,
                            46.96953851521535
                        ],
                        [
                            31.966842351326928,
                            46.9693235631235
                        ],
                        [
                            31.96771824306968,
                            46.96946613348497
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 227,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.971044747458073,
                            46.97397891299968
                        ],
                        [
                            31.971223417181484,
                            46.97403586059849
                        ],
                        [
                            31.971474131148216,
                            46.973686044078434
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 228,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97087627476602,
                            46.97295240889797
                        ],
                        [
                            31.974032699321544,
                            46.97372661852565
                        ],
                        [
                            31.974170913431408,
                            46.97379899449023
                        ],
                        [
                            31.974662698520003,
                            46.97413894089205
                        ],
                        [
                            31.97582188488273,
                            46.97496114221573
                        ],
                        [
                            31.975714163070634,
                            46.97488764164416
                        ],
                        [
                            31.975601952849697,
                            46.974973392301166
                        ],
                        [
                            31.97570069784412,
                            46.9750499552716
                        ],
                        [
                            31.975691721026447,
                            46.9750499552716
                        ],
                        [
                            31.975563644494944,
                            46.975142961039744
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 229,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.975563644494944,
                            46.975142961039744
                        ],
                        [
                            31.975778054243793,
                            46.975276100731186
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 230,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.975830688105393,
                            46.974609653430704
                        ],
                        [
                            31.97598336648257,
                            46.974514250175815
                        ],
                        [
                            31.976434973050857,
                            46.97480923210312
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 231,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97581662562329,
                            46.97495699660184
                        ],
                        [
                            31.97589175945627,
                            46.97486954419273
                        ],
                        [
                            31.97602675928451,
                            46.974779624325144
                        ],
                        [
                            31.97629997322262,
                            46.97458662507442
                        ],
                        [
                            31.977675685758015,
                            46.97363697088828
                        ],
                        [
                            31.97788139978201,
                            46.973753211220995
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 232,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.974580283495353,
                            46.974082688672695
                        ],
                        [
                            31.974576212376224,
                            46.974088244358605
                        ],
                        [
                            31.974580283495353,
                            46.974082688672695
                        ],
                        [
                            31.974576212376224,
                            46.97408546651572
                        ],
                        [
                            31.9745721412571,
                            46.974088244358605
                        ],
                        [
                            31.974580283495353,
                            46.97408546651572
                        ],
                        [
                            31.974576212376224,
                            46.97408546651572
                        ],
                        [
                            31.974580283495353,
                            46.974088244358605
                        ],
                        [
                            31.973306023208625,
                            46.97492576814167
                        ],
                        [
                            31.974568070137973,
                            46.97408685543718
                        ],
                        [
                            31.97457479224253,
                            46.97408356847141
                        ],
                        [
                            31.974568070137973,
                            46.97408407759423
                        ],
                        [
                            31.97418095806146,
                            46.97434701992181
                        ],
                        [
                            31.973560601707877,
                            46.974748371652794
                        ],
                        [
                            31.97295470962161,
                            46.975138754599605
                        ],
                        [
                            31.973134709392593,
                            46.97525279851811
                        ],
                        [
                            31.973082477316197,
                            46.975288985479885
                        ],
                        [
                            31.973047120218315,
                            46.975321882696605
                        ],
                        [
                            31.973064798767258,
                            46.97583946290902
                        ],
                        [
                            31.973243994967838,
                            46.97584001119049
                        ],
                        [
                            31.973242428886905,
                            46.97618151788111
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 234,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.974085513068136,
                            46.97640758571126
                        ],
                        [
                            31.97427015437656,
                            46.97627424834708
                        ],
                        [
                            31.97407729747907,
                            46.97614046858881
                        ],
                        [
                            31.97452408262491,
                            46.97584220431629
                        ],
                        [
                            31.974141583111575,
                            46.975581221713135
                        ],
                        [
                            31.973807297822596,
                            46.97540796365126
                        ],
                        [
                            31.973370155521625,
                            46.97513162684663
                        ],
                        [
                            31.973134709392586,
                            46.97525279851811
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 235,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.9743151543193,
                            46.975699650951405
                        ],
                        [
                            31.973794440696082,
                            46.97601546097122
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 236,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.972710191912494,
                            46.962542308118685
                        ],
                        [
                            31.973233937490058,
                            46.962649791161645
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 237,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97325957538546,
                            46.96262354532224
                        ],
                        [
                            31.97372472005924,
                            46.96169868371292
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 238,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97320280575992,
                            46.96266103937459
                        ],
                        [
                            31.973074616282886,
                            46.96290974925652
                        ],
                        [
                            31.97320280575992,
                            46.96294099413481
                        ],
                        [
                            31.972978474175115,
                            46.96334967546254
                        ],
                        [
                            31.972620459278563,
                            46.96326469000013
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 239,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.972978474175115,
                            46.96334967546253
                        ],
                        [
                            31.97371922622451,
                            46.96417702688011
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 240,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.965707223755565,
                            46.97214050530254
                        ],
                        [
                            31.96579136862105,
                            46.97199773263672
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 241,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.964542096088316,
                            46.97200261820765
                        ],
                        [
                            31.964853754160472,
                            46.9720976991972
                        ],
                        [
                            31.964516798963697,
                            46.97257753608653
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 242,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96422013188828,
                            46.972969899510076
                        ],
                        [
                            31.96412124286314,
                            46.97293991005064
                        ],
                        [
                            31.964557087085044,
                            46.97229513260523
                        ],
                        [
                            31.965121120783998,
                            46.97246507470185
                        ],
                        [
                            31.96508449521913,
                            46.972520055852804
                        ],
                        [
                            31.965267623043456,
                            46.972580035225725
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 243,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.966214494847108,
                            46.972306049500425
                        ],
                        [
                            31.966128811917017,
                            46.972448467990766
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 244,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.963373261897974,
                            46.97364725207814
                        ],
                        [
                            31.963906055488117,
                            46.97381647279128
                        ],
                        [
                            31.963818154132436,
                            46.973946424863996
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 245,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.964459101517594,
                            46.9743737650303
                        ],
                        [
                            31.964576303325174,
                            46.974186335553235
                        ],
                        [
                            31.963757721950408,
                            46.973930180872195
                        ],
                        [
                            31.96288184034319,
                            46.97517551745063
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 246,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96271572462996,
                            46.975220938071594
                        ],
                        [
                            31.962772494255503,
                            46.975142219018316
                        ],
                        [
                            31.96386393608853,
                            46.97547458756614
                        ],
                        [
                            31.963801672628254,
                            46.97555455563023
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 247,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.962935478019155,
                            46.974844834882376
                        ],
                        [
                            31.963080175944615,
                            46.97489352459619
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 248,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96324313276404,
                            46.97440125463171
                        ],
                        [
                            31.96339417757902,
                            46.97444707514575
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 249,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.963530643448237,
                            46.97398516051688
                        ],
                        [
                            31.963683321688723,
                            46.97403596498017
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 250,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.963853713605918,
                            46.973799848495936
                        ],
                        [
                            31.963999450678536,
                            46.97360030181691
                        ],
                        [
                            31.963852948419063,
                            46.97355781724523
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 251,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.966504639315403,
                            46.9731004811893
                        ],
                        [
                            31.9669149026765,
                            46.972527606372374
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 252,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.967151266046226,
                            46.97260029693619
                        ],
                        [
                            31.96744797647607,
                            46.972210327203186
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 253,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96766383844342,
                            46.972260147230166
                        ],
                        [
                            31.967165730761234,
                            46.972145186018686
                        ],
                        [
                            31.967326883246653,
                            46.97180030090154
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 254,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.970081125724615,
                            46.97158974494498
                        ],
                        [
                            31.97040526197369,
                            46.97214081248947
                        ],
                        [
                            31.970256928435973,
                            46.97218579734446
                        ],
                        [
                            31.969815590379326,
                            46.971728449555535
                        ],
                        [
                            31.96974050797136,
                            46.97176593722636
                        ],
                        [
                            31.96935227698378,
                            46.97141730087348
                        ],
                        [
                            31.969244231567416,
                            46.97140105611346
                        ],
                        [
                            31.96828097921143,
                            46.97118112656901
                        ],
                        [
                            31.968138139508454,
                            46.97146353593271
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 255,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.969244231567416,
                            46.97140105611346
                        ],
                        [
                            31.968454950644553,
                            46.97293179067315
                        ],
                        [
                            31.96839732384063,
                            46.97298431259974
                        ],
                        [
                            31.968328592445758,
                            46.97301551121804
                        ],
                        [
                            31.968207728081705,
                            46.97320044568854
                        ],
                        [
                            31.96769680145183,
                            46.973933929486115
                        ],
                        [
                            31.967473385506135,
                            46.97426630554379
                        ],
                        [
                            31.968304785828614,
                            46.97451871004215
                        ],
                        [
                            31.968652728694842,
                            46.97476111720126
                        ],
                        [
                            31.969070260134323,
                            46.974896065040504
                        ],
                        [
                            31.969246062845684,
                            46.974946045635306
                        ],
                        [
                            31.96849890132241,
                            46.976068097691986
                        ],
                        [
                            31.968432975305646,
                            46.97611058026941
                        ],
                        [
                            31.96778470280751,
                            46.976105582320876
                        ],
                        [
                            31.9674037969329,
                            46.976068097691986
                        ],
                        [
                            31.9667445367653,
                            46.97587317719815
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 256,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96834507394997,
                            46.9754458490139
                        ],
                        [
                            31.96881361497077,
                            46.97559547839732
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 257,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.968561164782674,
                            46.97514596754729
                        ],
                        [
                            31.969021877831953,
                            46.97528271862384
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 258,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.968498651575548,
                            46.97465377386064
                        ],
                        [
                            31.968698510650928,
                            46.97438251172319
                        ],
                        [
                            31.96923873773271,
                            46.974529955759394
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 259,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.968150958456167,
                            46.974246313057364
                        ],
                        [
                            31.967863447771972,
                            46.97468364699152
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 260,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96865925974814,
                            46.97253555698808
                        ],
                        [
                            31.96833958011522,
                            46.97245695525228
                        ],
                        [
                            31.968515382826588,
                            46.97206958639167
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 261,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96833958011522,
                            46.97245695525228
                        ],
                        [
                            31.96807587604819,
                            46.972399474888945
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 262,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.967740133632674,
                            46.97387172240738
                        ],
                        [
                            31.968647234860097,
                            46.97416384397183
                        ],
                        [
                            31.969192955776613,
                            46.97340412277752
                        ],
                        [
                            31.96900210799826,
                            46.973335605516425
                        ],
                        [
                            31.96900210799826,
                            46.97333994622968
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 263,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.965973568624854,
                            46.97578571264308
                        ],
                        [
                            31.965731839896737,
                            46.97571823988854
                        ],
                        [
                            31.965515749064018,
                            46.97576821971489
                        ],
                        [
                            31.965076242285626,
                            46.975625777086655
                        ],
                        [
                            31.96548278605565,
                            46.97502101643989
                        ],
                        [
                            31.965680564105927,
                            46.97490356213272
                        ],
                        [
                            31.96598089373782,
                            46.974443738533346
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 264,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.965680564105927,
                            46.97490356213272
                        ],
                        [
                            31.965860029373772,
                            46.975068497895094
                        ],
                        [
                            31.96634714938649,
                            46.97521094200778
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 265,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96548278605565,
                            46.97502101643989
                        ],
                        [
                            31.96532163357023,
                            46.97484108633229
                        ],
                        [
                            31.96483817611399,
                            46.97469364315392
                        ],
                        [
                            31.96479788799264,
                            46.974748622013735
                        ],
                        [
                            31.964611097611826,
                            46.97469114411351
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 266,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.964995366275687,
                            46.9747415825089
                        ],
                        [
                            31.966541815994987,
                            46.97241286808284
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 267,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96611660738689,
                            46.97305317668029
                        ],
                        [
                            31.965805091026475,
                            46.972954282772726
                        ],
                        [
                            31.96553406184646,
                            46.97335663984453
                        ],
                        [
                            31.96514216830239,
                            46.973231684556254
                        ],
                        [
                            31.96503229160779,
                            46.973381630867166
                        ],
                        [
                            31.964746612201832,
                            46.973306657764255
                        ],
                        [
                            31.964567146933977,
                            46.97349908851706
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 268,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.964746612201832,
                            46.973306657764255
                        ],
                        [
                            31.964834513557513,
                            46.973106728976006
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 269,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.965215419432123,
                            46.97383396635795
                        ],
                        [
                            31.965387559587,
                            46.97388894610157
                        ],
                        [
                            31.96550109883809,
                            46.973721507615394
                        ],
                        [
                            31.966189659457566,
                            46.9739314304102
                        ],
                        [
                            31.966270235700275,
                            46.97380647646493
                        ],
                        [
                            31.966446038411636,
                            46.973848960839085
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 270,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.963283619487896,
                            46.9746042677432
                        ],
                        [
                            31.963814949395548,
                            46.97478798184458
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 271,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96506754371397,
                            46.97521281626991
                        ],
                        [
                            31.965305967331673,
                            46.97528404678355
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 273,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97087627476602,
                            46.97295240889797
                        ],
                        [
                            31.972155740998872,
                            46.97292225135945
                        ],
                        [
                            31.97284247034012,
                            46.972829783723135
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 274,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97160511840636,
                            46.97293522975957
                        ],
                        [
                            31.97162009211272,
                            46.97217938184509
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 275,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97087627476602,
                            46.97295240889797
                        ],
                        [
                            31.970873846228557,
                            46.97257112340485
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 276,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96285245988572,
                            46.96823710319722
                        ],
                        [
                            31.962401278708565,
                            46.96825608257273
                        ],
                        [
                            31.962099976210112,
                            46.966154603032145
                        ],
                        [
                            31.962582060207634,
                            46.965899425284164
                        ],
                        [
                            31.962682494373784,
                            46.965951093604154
                        ],
                        [
                            31.96470971938896,
                            46.966426650695524
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 277,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96228632506049,
                            46.967454330825184
                        ],
                        [
                            31.962966800321027,
                            46.96739462302227
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 278,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96235151281521,
                            46.96602145928221
                        ],
                        [
                            31.962599056758823,
                            46.96623052344948
                        ],
                        [
                            31.963448884318538,
                            46.96642243291284
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 279,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.9646077400818,
                            46.96663753940507
                        ],
                        [
                            31.965142358873923,
                            46.96556410721916
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 280,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96441614259561,
                            46.965893098547696
                        ],
                        [
                            31.964918727616737,
                            46.96601312695776
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 281,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.963074960192266,
                            46.96557886969393
                        ],
                        [
                            31.96313676583298,
                            46.96546076978173
                        ],
                        [
                            31.964979154098398,
                            46.96589179955374
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 282,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96374864167598,
                            46.965730712055084
                        ],
                        [
                            31.9639340585981,
                            46.96536797681067
                        ],
                        [
                            31.96427707990402,
                            46.96541015545383
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 283,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.963805840187568,
                            46.96561730123161
                        ],
                        [
                            31.963305124119078,
                            46.96507373098939
                        ],
                        [
                            31.96335076786392,
                            46.96486446668971
                        ],
                        [
                            31.963214998059435,
                            46.96472637449015
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 284,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.962306252535964,
                            46.965210333837376
                        ],
                        [
                            31.962473127765875,
                            46.965136520914285
                        ],
                        [
                            31.962593648765257,
                            46.96527149303937
                        ],
                        [
                            31.963305124119078,
                            46.96507373098939
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 285,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.962881044994546,
                            46.96496569633025
                        ],
                        [
                            31.96304756545804,
                            46.96514532220979
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 286,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.965290122446174,
                            46.96397558007246
                        ],
                        [
                            31.965188143139006,
                            46.96385642212438
                        ],
                        [
                            31.963214998059435,
                            46.96472637449015
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 287,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.964887395150917,
                            46.963989021713495
                        ],
                        [
                            31.9647168751286,
                            46.96381951474148
                        ],
                        [
                            31.963990658850296,
                            46.96414218985574
                        ],
                        [
                            31.963904130953303,
                            46.964051503582354
                        ],
                        [
                            31.963035761701377,
                            46.96441846569454
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 288,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.964599444411252,
                            46.96374991789305
                        ],
                        [
                            31.964683438370503,
                            46.96383437152877
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 289,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.964120450695777,
                            46.96396081715523
                        ],
                        [
                            31.96420611606555,
                            46.96404645730861
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 290,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.976230908521252,
                            46.970572374665124
                        ],
                        [
                            31.97726615300306,
                            46.97039524123626
                        ],
                        [
                            31.977633896565262,
                            46.97062298410848
                        ],
                        [
                            31.978128341690905,
                            46.970319326730014
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 291,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97364413207154,
                            46.98319742792881
                        ],
                        [
                            31.97369146044377,
                            46.98326340775852
                        ],
                        [
                            31.974088607219404,
                            46.98313846716103
                        ],
                        [
                            31.974041278847174,
                            46.98306125653344
                        ],
                        [
                            31.974137993346943,
                            46.98303177608259
                        ],
                        [
                            31.974172975187287,
                            46.98308231398839
                        ],
                        [
                            31.97478824402622,
                            46.98289841193511
                        ],
                        [
                            31.974681240749884,
                            46.98272293228684
                        ],
                        [
                            31.974887016281304,
                            46.98266537483675
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 292,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.973898448032692,
                            46.9831982904262
                        ],
                        [
                            31.973685287177823,
                            46.98288577741968
                        ],
                        [
                            31.973533013284573,
                            46.9829292962936
                        ],
                        [
                            31.973401316944464,
                            46.98273556684075
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 293,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.9745667895085,
                            46.98296460411448
                        ],
                        [
                            31.974459003175948,
                            46.9827945280529
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 294,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97239713235115,
                            46.98179077473894
                        ],
                        [
                            31.972769586063016,
                            46.98212910488456
                        ],
                        [
                            31.972866300562785,
                            46.982195086032746
                        ],
                        [
                            31.972798394637415,
                            46.982240009321096
                        ],
                        [
                            31.972888935871246,
                            46.982296163378464
                        ],
                        [
                            31.972615254414464,
                            46.98262606727427
                        ],
                        [
                            31.97274900850988,
                            46.982825412471406
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 295,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.972798394637415,
                            46.982240009321096
                        ],
                        [
                            31.9721584327347,
                            46.98229335567699
                        ],
                        [
                            31.971997927820208,
                            46.98219227832597
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 296,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.971071937928834,
                            46.98198169989758
                        ],
                        [
                            31.97120774977957,
                            46.98188342968048
                        ],
                        [
                            31.97114601712014,
                            46.98180481337677
                        ],
                        [
                            31.97165222492742,
                            46.98146788505139
                        ],
                        [
                            31.97184565392696,
                            46.9814426153414
                        ],
                        [
                            31.972318937649224,
                            46.98175427426331
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 297,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97238478581927,
                            46.98176971677526
                        ],
                        [
                            31.972445028219752,
                            46.9817346111674
                        ],
                        [
                            31.973355017449915,
                            46.98238460589919
                        ],
                        [
                            31.974469291952538,
                            46.98203925808371
                        ],
                        [
                            31.97463391237767,
                            46.982272297911265
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 298,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97418943722981,
                            46.98240987516393
                        ],
                        [
                            31.974033949855652,
                            46.98217425899884
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 299,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97376965514571,
                            46.98255025975069
                        ],
                        [
                            31.973606049504014,
                            46.98230695187379
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 300,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.967077834864025,
                            46.979459607800074
                        ],
                        [
                            31.966863828311343,
                            46.97958596091691
                        ],
                        [
                            31.967658121862627,
                            46.98013489154686
                        ],
                        [
                            31.96787418617061,
                            46.97999871235099
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 301,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.967234898750462,
                            46.97984240576242
                        ],
                        [
                            31.968034691085116,
                            46.97933606224157
                        ],
                        [
                            31.968415375818243,
                            46.979626674635384
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 302,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.968034691085116,
                            46.97933606224157
                        ],
                        [
                            31.968283679478123,
                            46.97916899449821
                        ],
                        [
                            31.968668479721888,
                            46.97945539602437
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 303,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.968283679478123,
                            46.97916899449821
                        ],
                        [
                            31.96956771879418,
                            46.978409461453396
                        ],
                        [
                            31.969909306176337,
                            46.978679019702525
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 304,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96937017428402,
                            46.979027197096386
                        ],
                        [
                            31.96900641459491,
                            46.97874148461388
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 305,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.969049164455,
                            46.97922374784975
                        ],
                        [
                            31.968678529987745,
                            46.97893543425515
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 306,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.967604620224453,
                            46.97901596560294
                        ],
                        [
                            31.96794620760661,
                            46.97893453720489
                        ],
                        [
                            31.968230177839963,
                            46.978752024827806
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 307,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96794620760661,
                            46.97893453720489
                        ],
                        [
                            31.968174618446486,
                            46.97910020174627
                        ],
                        [
                            31.968034691085116,
                            46.97933606224157
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 308,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.969629451453592,
                            46.97989482283432
                        ],
                        [
                            31.97026324009035,
                            46.97949891769064
                        ],
                        [
                            31.97014594803745,
                            46.979405556653354
                        ],
                        [
                            31.97053486379183,
                            46.97916478269961
                        ],
                        [
                            31.9703990519411,
                            46.97906089156256
                        ],
                        [
                            31.97095361699827,
                            46.97872675383419
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 309,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96859440053058,
                            46.980511839367814
                        ],
                        [
                            31.969578007570743,
                            46.981180090425426
                        ],
                        [
                            31.96999367414422,
                            46.98127836193531
                        ],
                        [
                            31.970240604781917,
                            46.98125870764778
                        ],
                        [
                            31.97055338358966,
                            46.98108603038247
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 310,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97060688522783,
                            46.98106005855042
                        ],
                        [
                            31.970281759888195,
                            46.98095687303898
                        ],
                        [
                            31.9697755520809,
                            46.98056799524033
                        ],
                        [
                            31.97011508170775,
                            46.98024088645412
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 311,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.969921174199968,
                            46.97971259440935
                        ],
                        [
                            31.97012331272901,
                            46.97984217604274
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 312,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.971132641710607,
                            46.98054202315664
                        ],
                        [
                            31.971023580678953,
                            46.98059958289271
                        ],
                        [
                            31.970731379424333,
                            46.98040022939611
                        ],
                        [
                            31.971158878090876,
                            46.9801187466088
                        ],
                        [
                            31.971150459941185,
                            46.980048562102404
                        ],
                        [
                            31.971653253805094,
                            46.979750219522394
                        ],
                        [
                            31.972013289151462,
                            46.9795203679993
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 313,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97221502100586,
                            46.97968704319539
                        ],
                        [
                            31.97176643034737,
                            46.97931640724005
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 314,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.970631063852775,
                            46.98064099778642
                        ],
                        [
                            31.97086076078971,
                            46.980488499565965
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 315,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.971158878090876,
                            46.9801187466088
                        ],
                        [
                            31.97136516806112,
                            46.980260892091856
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 316,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.971398417967677,
                            46.97990143156231
                        ],
                        [
                            31.971694408911393,
                            46.98009944287207
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 317,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.971398417967677,
                            46.97990143156231
                        ],
                        [
                            31.97130755091233,
                            46.97983550744544
                        ],
                        [
                            31.97112029517874,
                            46.979938695121085
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 318,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.970816776269885,
                            46.97721573730019
                        ],
                        [
                            31.97114395936484,
                            46.97736877134772
                        ],
                        [
                            31.970892913216506,
                            46.9776285070314
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 319,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97114395936484,
                            46.97736877134772
                        ],
                        [
                            31.971454680417278,
                            46.97740667879641
                        ],
                        [
                            31.97155345267236,
                            46.977306996188595
                        ],
                        [
                            31.97161518533179,
                            46.97733788377708
                        ],
                        [
                            31.97154933716173,
                            46.97740667879641
                        ],
                        [
                            31.972008216596794,
                            46.97761867921767
                        ],
                        [
                            31.971907386586402,
                            46.97771274536055
                        ],
                        [
                            31.972547348489112,
                            46.97797247937336
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 320,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.972254051758643,
                            46.977853442542894
                        ],
                        [
                            31.972141970692217,
                            46.977957035764646
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 321,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.971762852440577,
                            46.97750532208609
                        ],
                        [
                            31.971940310671425,
                            46.97732805590995
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 322,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.9739404488368,
                            46.97789245335256
                        ],
                        [
                            31.97478001300498,
                            46.978016002246704
                        ],
                        [
                            31.975043405685195,
                            46.9780595250846
                        ],
                        [
                            31.975072214259598,
                            46.97816341816733
                        ],
                        [
                            31.975222430397533,
                            46.97824765565378
                        ],
                        [
                            31.9747820707603,
                            46.97852985026646
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 323,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.969635624719547,
                            46.97698969538288
                        ],
                        [
                            31.969710732788506,
                            46.97677207648516
                        ],
                        [
                            31.969803331777644,
                            46.97673838057664
                        ],
                        [
                            31.97005849343661,
                            46.976781202456735
                        ],
                        [
                            31.96994017250604,
                            46.97711886230978
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 324,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97102358067896,
                            46.978739038346454
                        ],
                        [
                            31.971496864401217,
                            46.9791082743697
                        ],
                        [
                            31.971986610165985,
                            46.978782560595825
                        ],
                        [
                            31.97240021898414,
                            46.97902263430245
                        ],
                        [
                            31.972723286568467,
                            46.97877132905099
                        ],
                        [
                            31.973338555407402,
                            46.97911389010702
                        ],
                        [
                            31.97341675010934,
                            46.979049309092204
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 325,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.973338555407402,
                            46.97911389010702
                        ],
                        [
                            31.97291877332331,
                            46.97942837045571
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 326,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97591692281607,
                            46.9793455387576
                        ],
                        [
                            31.97528107642399,
                            46.97909563895857
                        ],
                        [
                            31.975182304168907,
                            46.97890189561398
                        ],
                        [
                            31.975521833795742,
                            46.978681476607406
                        ],
                        [
                            31.975585624210485,
                            46.97859022006483
                        ],
                        [
                            31.975796160335808,
                            46.978462957416106
                        ],
                        [
                            31.976139160390005,
                            46.9787109594571
                        ],
                        [
                            31.976340820410794,
                            46.978740442290544
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 327,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97541223970098,
                            46.97875262402224
                        ],
                        [
                            31.97589017199699,
                            46.97904790515624
                        ],
                        [
                            31.976009521805207,
                            46.97896788074495
                        ],
                        [
                            31.976390206538333,
                            46.97907036812735
                        ],
                        [
                            31.97697255129225,
                            46.979160219917425
                        ],
                        [
                            31.976919049654075,
                            46.979313248399826
                        ],
                        [
                            31.977114536408926,
                            46.979345538757585
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 328,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.974781041882647,
                            46.97858179637612
                        ],
                        [
                            31.974920969244017,
                            46.97867305293307
                        ],
                        [
                            31.97541223970098,
                            46.97875262402224
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 329,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.974174518503787,
                            46.9785207245933
                        ],
                        [
                            31.974485239556223,
                            46.97870464170494
                        ],
                        [
                            31.974697188353588,
                            46.97856986281483
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 330,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97020127562481,
                            46.97503375721927
                        ],
                        [
                            31.970543063678967,
                            46.97496848256022
                        ],
                        [
                            31.97048940961448,
                            46.97486198318493
                        ],
                        [
                            31.970613789491242,
                            46.97478210851424
                        ],
                        [
                            31.970686954124634,
                            46.97467893688806
                        ],
                        [
                            31.970728414083556,
                            46.97460072600654
                        ],
                        [
                            31.9709235197726,
                            46.97430951427161
                        ],
                        [
                            31.971018633796014,
                            46.97417472430315
                        ],
                        [
                            31.971077165502724,
                            46.97409484860595
                        ],
                        [
                            31.971044747458073,
                            46.97397891299968
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 331,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97042787509839,
                            46.97367072821058
                        ],
                        [
                            31.971044747458073,
                            46.97397891299968
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 332,
                id: 1,
                district: 3,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97042787509839,
                            46.97367072821058
                        ],
                        [
                            31.970475996098344,
                            46.97362058419677
                        ],
                        [
                            31.9706454941657,
                            46.9733726337612
                        ],
                        [
                            31.970900350972013,
                            46.973006530345145
                        ],
                        [
                            31.97087627476602,
                            46.97295240889797
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 333,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01840545536631,
                            46.995256614334984
                        ],
                        [
                            32.01823831272183,
                            46.99521715239112
                        ],
                        [
                            32.019234740025496,
                            46.99337775500624
                        ],
                        [
                            32.01961402525722,
                            46.99346106638774
                        ],
                        [
                            32.02003429257965,
                            46.992642745677706
                        ],
                        [
                            32.020830630852195,
                            46.992818688428336
                        ],
                        [
                            32.02091259503363,
                            46.99267069923757
                        ],
                        [
                            32.02060241685684,
                            46.99259506015959
                        ],
                        [
                            32.02058152402626,
                            46.99179700655276
                        ],
                        [
                            32.020597595434396,
                            46.991558026643126
                        ],
                        [
                            32.02074063096669,
                            46.991575566489324
                        ],
                        [
                            32.02110866621273,
                            46.99165888068027
                        ],
                        [
                            32.02148634430364,
                            46.991765215708966
                        ],
                        [
                            32.021576344189135,
                            46.99179591031708
                        ],
                        [
                            32.021864022394546,
                            46.99187593546246
                        ],
                        [
                            32.022056879292045,
                            46.991921977272625
                        ],
                        [
                            32.02239437886264,
                            46.992020638160845
                        ],
                        [
                            32.0224538430727,
                            46.99203598539377
                        ],
                        [
                            32.0224795573257,
                            46.992005290923544
                        ],
                        [
                            32.02274714627096,
                            46.99153116874242
                        ],
                        [
                            32.02317464572705,
                            46.99080764470622
                        ],
                        [
                            32.023872144839636,
                            46.99104224296678
                        ],
                        [
                            32.02409714455336,
                            46.9904656117523
                        ],
                        [
                            32.02485250073519,
                            46.990649783614856
                        ],
                        [
                            32.02495214346556,
                            46.9904656117523
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 334,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0244778728856,
                            46.99055844147645
                        ],
                        [
                            32.02470464378044,
                            46.99009068849968
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 335,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02397101147996,
                            46.990788867451144
                        ],
                        [
                            32.02413250165123,
                            46.99081970352043
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 336,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.022880538958375,
                            46.9931009629707
                        ],
                        [
                            32.02309589582725,
                            46.99315358097296
                        ],
                        [
                            32.02370018077269,
                            46.99209901862557
                        ],
                        [
                            32.02354268097308,
                            46.992059554350014
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 337,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.023263038471725,
                            46.992377460186084
                        ],
                        [
                            32.02350622638794,
                            46.99243749861438
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 338,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02362044701623,
                            46.992238166453326
                        ],
                        [
                            32.02316661002298,
                            46.992112173377606
                        ],
                        [
                            32.023263038471725,
                            46.99194335381386
                        ],
                        [
                            32.023201967120855,
                            46.99192581408835
                        ],
                        [
                            32.023507323875215,
                            46.991318497542125
                        ],
                        [
                            32.02394446617618,
                            46.99142812197786
                        ],
                        [
                            32.02400232324543,
                            46.991311920068824
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 339,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02364723606146,
                            46.99135358406916
                        ],
                        [
                            32.023817936082715,
                            46.99102401032087
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 340,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.023507323875215,
                            46.991318497542125
                        ],
                        [
                            32.02341893113052,
                            46.99129328389009
                        ],
                        [
                            32.0235073238752,
                            46.991120076741616
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 341,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.023201967120855,
                            46.99192581408835
                        ],
                        [
                            32.02308303870074,
                            46.991969663391345
                        ],
                        [
                            32.02303161019474,
                            46.99205626565907
                        ],
                        [
                            32.02287893181757,
                            46.992017897583096
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 342,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02337261174456,
                            46.99158642403965
                        ],
                        [
                            32.023798216362266,
                            46.99169560465737
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 343,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.018729319591,
                            46.99431076527815
                        ],
                        [
                            32.01901697244541,
                            46.99438570438213
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 344,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01906454381127,
                            46.993691940434196
                        ],
                        [
                            32.01923232931426,
                            46.993743337537126
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 345,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.020830630852195,
                            46.992818688428336
                        ],
                        [
                            32.02072697026978,
                            46.99301326618512
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 346,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02058419741983,
                            46.99175725358539
                        ],
                        [
                            32.020244828026065,
                            46.99167806485052
                        ],
                        [
                            32.020093756789706,
                            46.991952123674444
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 347,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0315648211218,
                            46.979876191778956
                        ],
                        [
                            32.03092386955633,
                            46.980217967613065
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 348,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03106482175795,
                            46.97976833051086
                        ],
                        [
                            32.03134034290321,
                            46.97999589097912
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 349,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02206483320866,
                            46.97961758427757
                        ],
                        [
                            32.02214292834739,
                            46.97957210041685
                        ],
                        [
                            32.022523880243654,
                            46.979568201798415
                        ],
                        [
                            32.02266483244527,
                            46.979498026617975
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 350,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02214292834739,
                            46.97957210041685
                        ],
                        [
                            32.02171435746409,
                            46.97895611517835
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 351,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0219486428803,
                            46.97882615958016
                        ],
                        [
                            32.02171435746409,
                            46.97895611517835
                        ],
                        [
                            32.02164150041393,
                            46.97899477690784
                        ],
                        [
                            32.02105197735445,
                            46.97850549279042
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 352,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02186879653848,
                            46.97917809138881
                        ],
                        [
                            32.022040071335404,
                            46.9790834713581
                        ],
                        [
                            32.022184833055974,
                            46.97920173039626
                        ],
                        [
                            32.02228435673888,
                            46.97914584978446
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 353,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02144715578656,
                            46.97883347796234
                        ],
                        [
                            32.0211957866953,
                            46.9789629378385
                        ],
                        [
                            32.01892150387459,
                            46.97703956297551
                        ],
                        [
                            32.018788170710906,
                            46.9771032433858
                        ],
                        [
                            32.01862436139551,
                            46.97710584258461
                        ],
                        [
                            32.01851102820637,
                            46.97716302492643
                        ],
                        [
                            32.018390075979305,
                            46.97705840718647
                        ],
                        [
                            32.01847769491545,
                            46.97700902234369
                        ],
                        [
                            32.018388171219826,
                            46.976931046183275
                        ],
                        [
                            32.01830150466343,
                            46.97698108089928
                        ],
                        [
                            32.017583410338965,
                            46.976354668871004
                        ],
                        [
                            32.01657388781386,
                            46.97549951363309
                        ],
                        [
                            32.01645960224498,
                            46.975382546047996
                        ],
                        [
                            32.01750721995971,
                            46.974862687018856
                        ],
                        [
                            32.01713388710137,
                            46.97452737526397
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 354,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0204300733838,
                            46.97774134290146
                        ],
                        [
                            32.01999555091752,
                            46.977947899736925
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 355,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01961864584476,
                            46.97708894779008
                        ],
                        [
                            32.019239120409516,
                            46.977308177374105
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 356,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01695103019116,
                            46.97486788563416
                        ],
                        [
                            32.01714729248971,
                            46.97504129429085
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 357,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01836817124527,
                            46.97642224894503
                        ],
                        [
                            32.017943221710816,
                            46.976668542442
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 358,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.017922457526645,
                            46.976506723917446
                        ],
                        [
                            32.01804629195127,
                            46.97660880479407
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 359,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.019461503187564,
                            46.97461705186949
                        ],
                        [
                            32.01933388430231,
                            46.974689832772114
                        ],
                        [
                            32.01948340792159,
                            46.97480615204475
                        ],
                        [
                            32.01955483640215,
                            46.97477690979332
                        ],
                        [
                            32.01962055060425,
                            46.974740519413636
                        ],
                        [
                            32.019846264602776,
                            46.97491727245437
                        ],
                        [
                            32.01995293113374,
                            46.97485358944085
                        ],
                        [
                            32.02076435867279,
                            46.97552810567053
                        ],
                        [
                            32.02055293037036,
                            46.97564117403246
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 360,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01890531341902,
                            46.97603106310212
                        ],
                        [
                            32.0204070268892,
                            46.97523106714875
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 361,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01927483675839,
                            46.97565417038058
                        ],
                        [
                            32.01940938665426,
                            46.97576253348412
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 362,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.019487034500784,
                            46.975721168845965
                        ],
                        [
                            32.019918645463086,
                            46.976077849599434
                        ],
                        [
                            32.01972435999599,
                            46.976179220203214
                        ],
                        [
                            32.01957197923747,
                            46.97604665860577
                        ],
                        [
                            32.01937007473246,
                            46.976153227759035
                        ],
                        [
                            32.019827217007986,
                            46.976501525461465
                        ],
                        [
                            32.01985483602045,
                            46.976525568316184
                        ],
                        [
                            32.01892150387459,
                            46.97703956297551
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 363,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.019572931617226,
                            46.97653986406251
                        ],
                        [
                            32.01968054602625,
                            46.976621551805806
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 364,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.019165313088216,
                            46.976788089593875
                        ],
                        [
                            32.01925347128153,
                            46.97685674601522
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 365,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01918635589167,
                            46.97304516825841
                        ],
                        [
                            32.01957302206638,
                            46.97360923424228
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 366,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01922360028023,
                            46.97302654293554
                        ],
                        [
                            32.01945111745957,
                            46.97291259889184
                        ],
                        [
                            32.01981683127999,
                            46.97322062720886
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 367,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01918635589167,
                            46.97304516825841
                        ],
                        [
                            32.01969635524278,
                            46.97344287438637
                        ],
                        [
                            32.019860164558175,
                            46.97335319581186
                        ],
                        [
                            32.020048735746826,
                            46.973500060645215
                        ],
                        [
                            32.01991540258313,
                            46.97358064117889
                        ],
                        [
                            32.01983540268492,
                            46.9735130575137
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 368,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.019166355917115,
                            46.97305459106852
                        ],
                        [
                            32.01875588024889,
                            46.97330933123693
                        ],
                        [
                            32.0189577847539,
                            46.97347699119588
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 369,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01894859906875,
                            46.97209752289959
                        ],
                        [
                            32.019053360840225,
                            46.9720364359734
                        ],
                        [
                            32.01949907455886,
                            46.97237306306117
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 370,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01889526580327,
                            46.972118318433004
                        ],
                        [
                            32.018655266108624,
                            46.97226778609154
                        ],
                        [
                            32.01898669425838,
                            46.972534227403344
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 371,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02266002295656,
                            46.97328387931893
                        ],
                        [
                            32.02256288022301,
                            46.97320719742061
                        ],
                        [
                            32.02225240442755,
                            46.97337875676974
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 372,
                id: 4,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01875588024889,
                            46.97330933123693
                        ],
                        [
                            32.018441028295435,
                            46.97301227277936
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 373,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.08380859176909,
                            46.97707094638754
                        ],
                        [
                            32.08378344295662,
                            46.97690793746302
                        ],
                        [
                            32.083789730159744,
                            46.97690793746302
                        ],
                        [
                            32.08161123611639,
                            46.97697104500151
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 376,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.08161123611639,
                            46.97697104500151
                        ],
                        [
                            32.08157842742439,
                            46.97552180932497
                        ],
                        [
                            32.081446139384134,
                            46.97489691800604
                        ],
                        [
                            32.08118319305412,
                            46.973396681177746
                        ],
                        [
                            32.08107980327265,
                            46.972865970788675
                        ],
                        [
                            32.080858475205304,
                            46.972185499428285
                        ],
                        [
                            32.08110015527885,
                            46.97216987625965
                        ],
                        [
                            32.08089154721537,
                            46.97171680238433
                        ],
                        [
                            32.080771979178984,
                            46.971494603832014
                        ],
                        [
                            32.08068548315266,
                            46.971322746506075
                        ],
                        [
                            32.08070329115808,
                            46.97116651209419
                        ],
                        [
                            32.080774523179755,
                            46.97104499612496
                        ],
                        [
                            32.080863563206854,
                            46.970897440648315
                        ],
                        [
                            32.080937339229294,
                            46.970796755501176
                        ],
                        [
                            32.08097469477662,
                            46.970738740206656
                        ],
                        [
                            32.08096534557378,
                            46.97070069024415
                        ],
                        [
                            32.08009532582801,
                            46.969662822765706
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 377,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.08081013919059,
                            46.976105034634415
                        ],
                        [
                            32.08125788332684,
                            46.97610156307402
                        ],
                        [
                            32.08127569133226,
                            46.97649037643833
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 378,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.08127569133226,
                            46.97649037643833
                        ],
                        [
                            32.08160027600493,
                            46.976486915941784
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 379,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.077300575764156,
                            46.97017670263595
                        ],
                        [
                            32.078826812337645,
                            46.96959944349295
                        ],
                        [
                            32.07920491445261,
                            46.97000414493547
                        ],
                        [
                            32.080112552605925,
                            46.96965621918842
                        ],
                        [
                            32.07986116113502,
                            46.96936329528598
                        ],
                        [
                            32.08038405881112,
                            46.96914309283111
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 380,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07973009161228,
                            46.97147612567109
                        ],
                        [
                            32.07972500361073,
                            46.97111157904563
                        ],
                        [
                            32.07940954751481,
                            46.970771333286685
                        ],
                        [
                            32.07953674755348,
                            46.970712310842885
                        ],
                        [
                            32.07879454728343,
                            46.96994956691493
                        ],
                        [
                            32.07878826008031,
                            46.96995814746077
                        ],
                        [
                            32.078781972877195,
                            46.969936696093576
                        ],
                        [
                            32.07877568567408,
                            46.96994527664149
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 381,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.079457883529514,
                            46.970151594378244
                        ],
                        [
                            32.07912950501779,
                            46.97029460977112
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 382,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07895188120355,
                            46.970112423316706
                        ],
                        [
                            32.078673059290885,
                            46.97022710916725
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 383,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.08038405881112,
                            46.96914309283111
                        ],
                        [
                            32.0844202799289,
                            46.96747046993172
                        ],
                        [
                            32.08575842433576,
                            46.969064149643145
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 384,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0781976540369,
                            46.97190592035942
                        ],
                        [
                            32.07847749412199,
                            46.971787877910856
                        ],
                        [
                            32.07805773399437,
                            46.97132264925327
                        ],
                        [
                            32.07839354209647,
                            46.971187246122454
                        ],
                        [
                            32.07761695691196,
                            46.97029646072248
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 385,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07878023021404,
                            46.97090949503774
                        ],
                        [
                            32.07831855067514,
                            46.97110122753493
                        ],
                        [
                            32.07831791425571,
                            46.97110049753216
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 386,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07831138948488,
                            46.97160378125862
                        ],
                        [
                            32.078080630001324,
                            46.97169587405708
                        ],
                        [
                            32.077910181949505,
                            46.971515337733024
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 387,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07810204037809,
                            46.970043862763504
                        ],
                        [
                            32.07851024324139,
                            46.97048576821119
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 388,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07764560586906,
                            46.97022205490018
                        ],
                        [
                            32.07761603186007,
                            46.97019059041471
                        ],
                        [
                            32.07740507539461,
                            46.97027562668944
                        ],
                        [
                            32.07741136259773,
                            46.97027562668944
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 389,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.077300575764156,
                            46.97017670263595
                        ],
                        [
                            32.07646995951159,
                            46.96976527555404
                        ],
                        [
                            32.07632738347205,
                            46.969592698444615
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 390,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07713793528832,
                            46.97009614258286
                        ],
                        [
                            32.07703536368349,
                            46.97024397153078
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 391,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07761695691196,
                            46.97029646072248
                        ],
                        [
                            32.077478655818275,
                            46.97034292175135
                        ],
                        [
                            32.077300575764156,
                            46.97017670263595
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 392,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07632738347205,
                            46.969592698444615
                        ],
                        [
                            32.07622319143656,
                            46.96962900048526
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 393,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.076026031376614,
                            46.9694536651727
                        ],
                        [
                            32.076026031376614,
                            46.9694536651727
                        ],
                        [
                            32.0769774876659,
                            46.96907000867204
                        ],
                        [
                            32.07699974767267,
                            46.96896628206409
                        ],
                        [
                            32.078028795985546,
                            46.968532711345276
                        ],
                        [
                            32.078093668005266,
                            46.968601284325565
                        ],
                        [
                            32.078320084074115,
                            46.968506670949985
                        ],
                        [
                            32.07756960384592,
                            46.9676421226289
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 394,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0784604115946,
                            46.968855922243414
                        ],
                        [
                            32.078816164224946,
                            46.96924794924643
                        ],
                        [
                            32.07895099626593,
                            46.96918892512151
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 395,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.076629766004274,
                            46.96921022108222
                        ],
                        [
                            32.076775875604575,
                            46.96937641329314
                        ],
                        [
                            32.07703536368348,
                            46.96927225327894
                        ],
                        [
                            32.0772923077616,
                            46.96957605275343
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 396,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07704189416879,
                            46.968673892528415
                        ],
                        [
                            32.07645416199407,
                            46.968011353353205
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 397,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0764462769193,
                            46.96800603781402
                        ],
                        [
                            32.07645256412241,
                            46.96800603781402
                        ],
                        [
                            32.07666258365345,
                            46.96793017581251
                        ],
                        [
                            32.07654772201853,
                            46.96779528490814
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 398,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07989784749755,
                            46.96934784596961
                        ],
                        [
                            32.07921237004131,
                            46.968544329572254
                        ],
                        [
                            32.077386604286694,
                            46.96648814138709
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 400,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.078085266667266,
                            46.96727498842604
                        ],
                        [
                            32.0773625634794,
                            46.96756728546775
                        ],
                        [
                            32.07748944551795,
                            46.967730886913955
                        ],
                        [
                            32.07754286953418,
                            46.967796509637566
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 401,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07754286953418,
                            46.967796509637566
                        ],
                        [
                            32.07658808871552,
                            46.96816232709209
                        ],
                        [
                            32.07658830638722,
                            46.96816257247005
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 402,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07513878880388,
                            46.967225042384044
                        ],
                        [
                            32.07536356203534,
                            46.96749193855312
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 403,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07561426254832,
                            46.96740186065543
                        ],
                        [
                            32.07514168227256,
                            46.967564121784626
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 407,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.075362501871865,
                            46.970012140737154
                        ],
                        [
                            32.07553345872379,
                            46.96979887542545
                        ],
                        [
                            32.07523695543373,
                            46.96969497663219
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 408,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.075990454693326,
                            46.96969644019312
                        ],
                        [
                            32.07599674189645,
                            46.96969644019312
                        ],
                        [
                            32.076179889320166,
                            46.96963938158098
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 410,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.076981890475984,
                            46.97025821610062
                        ],
                        [
                            32.07688439164637,
                            46.97039218941183
                        ],
                        [
                            32.076459670717355,
                            46.9702573047164
                        ],
                        [
                            32.07578118571126,
                            46.97120878138537
                        ],
                        [
                            32.07578118571126,
                            46.97120878138537
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 411,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07578118571126,
                            46.97120878138537
                        ],
                        [
                            32.07567700887961,
                            46.97134548641261
                        ],
                        [
                            32.075530092834974,
                            46.97159337730405
                        ],
                        [
                            32.07540187519603,
                            46.97181757175316
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 415,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07137286962216,
                            46.97069078008248
                        ],
                        [
                            32.07162930490006,
                            46.97077098118426
                        ],
                        [
                            32.071808275354414,
                            46.97051032716376
                        ],
                        [
                            32.07254285557756,
                            46.970727235143656
                        ],
                        [
                            32.072401281934546,
                            46.970940496753656
                        ],
                        [
                            32.07388112718407,
                            46.97142899022819
                        ],
                        [
                            32.07362017911829,
                            46.971790062479215
                        ],
                        [
                            32.07362646632141,
                            46.97178148222726
                        ],
                        [
                            32.07362646632141,
                            46.97179864272979
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 416,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.071973569404044,
                            46.97055913552206
                        ],
                        [
                            32.072047347827045,
                            46.97045108743651
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 417,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07253199235102,
                            46.97098364413772
                        ],
                        [
                            32.0724346719447,
                            46.97111092315546
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 418,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07308576408764,
                            46.97116644327477
                        ],
                        [
                            32.073003637717534,
                            46.971280437647415
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 419,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.074070782441694,
                            46.972060106254915
                        ],
                        [
                            32.07412420645792,
                            46.97199995688968
                        ],
                        [
                            32.074925566701346,
                            46.972269717150546
                        ],
                        [
                            32.07472255543967,
                            46.97253583066926
                        ],
                        [
                            32.07438064173581,
                            46.97241917833091
                        ],
                        [
                            32.07415893206847,
                            46.97241188755131
                        ],
                        [
                            32.07400400242141,
                            46.972362674763055
                        ],
                        [
                            32.07383037436866,
                            46.97235356127877
                        ],
                        [
                            32.073643390311865,
                            46.97229523494262
                        ],
                        [
                            32.07345373505426,
                            46.97228247604809
                        ],
                        [
                            32.073047712530915,
                            46.97214577341588
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 420,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07336825662828,
                            46.971680981852614
                        ],
                        [
                            32.0735338710786,
                            46.97173566342265
                        ],
                        [
                            32.0736033222997,
                            46.971640881999036
                        ],
                        [
                            32.07370555921461,
                            46.97167757689398
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 421,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.073627891746085,
                            46.97178754588424
                        ],
                        [
                            32.07514493906798,
                            46.972307765996746
                        ],
                        [
                            32.075235759895556,
                            46.97209086442689
                        ],
                        [
                            32.07540187519603,
                            46.97181757175316
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 422,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07534091028827,
                            46.97191787130138
                        ],
                        [
                            32.075633101016265,
                            46.972003830146285
                        ],
                        [
                            32.07556899219679,
                            46.9721040790435
                        ],
                        [
                            32.07587484468971,
                            46.972194302890315
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 423,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07545279496149,
                            46.972292728731425
                        ],
                        [
                            32.07574395584994,
                            46.97239662247831
                        ],
                        [
                            32.075898885497,
                            46.972163316939884
                        ],
                        [
                            32.076043130340814,
                            46.97221070720975
                        ],
                        [
                            32.076157991975705,
                            46.97204848649645
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 424,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07596432991684,
                            46.96925420304589
                        ],
                        [
                            32.07596432991684,
                            46.96925420304589
                        ],
                        [
                            32.074845096776876,
                            46.96795452897476
                        ],
                        [
                            32.07417743592243,
                            46.967197964627694
                        ],
                        [
                            32.073763089657824,
                            46.96735814199524
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 425,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.074113187754556,
                            46.96789802069007
                        ],
                        [
                            32.0738193556653,
                            46.96752615823087
                        ],
                        [
                            32.07388152981077,
                            46.96749931574772
                        ],
                        [
                            32.073361244726144,
                            46.966887241449385
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 426,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0739219648679,
                            46.9672938218312
                        ],
                        [
                            32.073859092836734,
                            46.96722517400649
                        ],
                        [
                            32.07520637668663,
                            46.966685356742744
                        ],
                        [
                            32.07694532841486,
                            46.96596895610412
                        ],
                        [
                            32.077386604286694,
                            46.96648814138709
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 427,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07680533716211,
                            46.9660266290336
                        ],
                        [
                            32.07636300663797,
                            46.96551140258394
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 428,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.073361244726144,
                            46.966887241449385
                        ],
                        [
                            32.07331049191072,
                            46.966906837441286
                        ],
                        [
                            32.073355902324515,
                            46.966959245291335
                        ],
                        [
                            32.07326241029612,
                            46.96699843721522
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 429,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07333383487776,
                            46.96689782457089
                        ],
                        [
                            32.07257858288838,
                            46.96600495865526
                        ],
                        [
                            32.0728163197606,
                            46.965915635825645
                        ],
                        [
                            32.072904469387375,
                            46.96580990656925
                        ],
                        [
                            32.073286451103414,
                            46.965664072769265
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 430,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07296626275986,
                            46.966463279261184
                        ],
                        [
                            32.07388212888436,
                            46.96611980207416
                        ],
                        [
                            32.07374189084176,
                            46.965950271226276
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 431,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0766534997262,
                            46.96658281907173
                        ],
                        [
                            32.07639172204669,
                            46.966265634631746
                        ],
                        [
                            32.07614062917041,
                            46.96636954008646
                        ],
                        [
                            32.075902892298195,
                            46.96610430734156
                        ],
                        [
                            32.075181668079104,
                            46.9663895919931
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 432,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07431412916231,
                            46.96668027877283
                        ],
                        [
                            32.07503087031763,
                            46.96640139323807
                        ],
                        [
                            32.07503087031763,
                            46.96640139323807
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 433,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.072977927409696,
                            46.96509987451119
                        ],
                        [
                            32.07312484345432,
                            46.96526758511572
                        ],
                        [
                            32.073298471507066,
                            46.96520013634885
                        ],
                        [
                            32.07337326512978,
                            46.96528946037321
                        ],
                        [
                            32.07373654844013,
                            46.96515820869414
                        ],
                        [
                            32.07413455736104,
                            46.96540795119576
                        ],
                        [
                            32.07413455736104,
                            46.96540795119575
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 434,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07540810250464,
                            46.96491683961425
                        ],
                        [
                            32.075403377746454,
                            46.96491211092791
                        ],
                        [
                            32.07568652503247,
                            46.965223834573926
                        ],
                        [
                            32.07562775861461,
                            46.96525117866673
                        ],
                        [
                            32.075681182630845,
                            46.965353263156416
                        ],
                        [
                            32.07618069718258,
                            46.96590925561782
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 435,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07420267298173,
                            46.9646249954364
                        ],
                        [
                            32.07386476607908,
                            46.96474895659956
                        ],
                        [
                            32.07352418797563,
                            46.96435610810258
                        ],
                        [
                            32.072157868760584,
                            46.9649139338785
                        ],
                        [
                            32.07198691190866,
                            46.96470794007002
                        ],
                        [
                            32.0716476694056,
                            46.96484283875989
                        ],
                        [
                            32.07112678524738,
                            46.964259490628834
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 436,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07134421159,
                            46.965358777514254
                        ],
                        [
                            32.071333234400264,
                            46.9653632978082
                        ],
                        [
                            32.07133792438689,
                            46.965358777514254
                        ],
                        [
                            32.07123897568147,
                            46.96550821245601
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 437,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07236889362469,
                            46.965568369121954
                        ],
                        [
                            32.07270196560728,
                            46.96595860114644
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 438,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07429082260851,
                            46.96461337406268
                        ],
                        [
                            32.07442304704867,
                            46.96455959669236
                        ],
                        [
                            32.07458231739705,
                            46.96473528442659
                        ],
                        [
                            32.07478566939607,
                            46.96465939837799
                        ],
                        [
                            32.07477938219296,
                            46.96465939837799
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 439,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.075403377746454,
                            46.96491211092791
                        ],
                        [
                            32.07472673749871,
                            46.96413841431857
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 440,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07636300663797,
                            46.96551140258394
                        ],
                        [
                            32.076441139261696,
                            46.96548041276179
                        ],
                        [
                            32.07481237456693,
                            46.96362372507
                        ],
                        [
                            32.07393622070078,
                            46.962646591087086
                        ],
                        [
                            32.07326842049793,
                            46.96188456003517
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 441,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07167638481433,
                            46.96415239086677
                        ],
                        [
                            32.07133981351209,
                            46.96374768855331
                        ],
                        [
                            32.071719124027304,
                            46.96319714265305
                        ],
                        [
                            32.07316691486709,
                            46.962602838401736
                        ],
                        [
                            32.07323102368657,
                            46.962675759524096
                        ],
                        [
                            32.07338580098163,
                            46.96261125657399
                        ],
                        [
                            32.07333414722478,
                            46.96254995890508
                        ],
                        [
                            32.07371953695455,
                            46.96239933307791
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 442,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.071605451442316,
                            46.96336213201834
                        ],
                        [
                            32.07203031892184,
                            46.96384795292528
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 443,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "Polygon",
                coordinates: []
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 444,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07512822089451,
                            46.96398377474082
                        ],
                        [
                            32.07431085661458,
                            46.96429914027364
                        ],
                        [
                            32.07392887489855,
                            46.96388350115749
                        ],
                        [
                            32.073704494030395,
                            46.963961889483485
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 445,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "Polygon",
                coordinates: []
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 446,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07326842049793,
                            46.96188456003517
                        ],
                        [
                            32.07216321116221,
                            46.96060726773549
                        ],
                        [
                            32.071650340606425,
                            46.959905370670526
                        ],
                        [
                            32.0713618509188,
                            46.959569915314795
                        ],
                        [
                            32.07068336591269,
                            46.958804194123296
                        ],
                        [
                            32.06885092215607,
                            46.956692934651144
                        ],
                        [
                            32.067761072225004,
                            46.95544218378953
                        ],
                        [
                            32.066975739186454,
                            46.95452689276899
                        ],
                        [
                            32.065917943665134,
                            46.95331985159196
                        ],
                        [
                            32.06478001211946,
                            46.95208725576974
                        ],
                        [
                            32.06453159044401,
                            46.952188453685785
                        ],
                        [
                            32.06470274296783,
                            46.952368281115525
                        ],
                        [
                            32.06449686483346,
                            46.95245193204698
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 447,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06525682146432,
                            46.95312475325679
                        ],
                        [
                            32.065604626259386,
                            46.952980472211856
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 448,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06601847921366,
                            46.953434572994006
                        ],
                        [
                            32.06580174642985,
                            46.95351859589369
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 449,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06660993527094,
                            46.954109480213965
                        ],
                        [
                            32.066381397005934,
                            46.95420416690322
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 450,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.066362698600244,
                            46.95472016269469
                        ],
                        [
                            32.0667233107098,
                            46.954581591875375
                        ],
                        [
                            32.06729761888424,
                            46.95522156726632
                        ],
                        [
                            32.06750074792056,
                            46.95513878239885
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 451,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06729761888424,
                            46.95522156726632
                        ],
                        [
                            32.066725981910594,
                            46.95533369891865
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 452,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06737241250696,
                            46.95541027649739
                        ],
                        [
                            32.067646261008605,
                            46.95530837443355
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 453,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06711330602826,
                            46.95576034403331
                        ],
                        [
                            32.06748193174023,
                            46.9556163061057
                        ],
                        [
                            32.067551382961334,
                            46.95568923675042
                        ],
                        [
                            32.068681300904565,
                            46.9552443582716
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 454,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06775539592331,
                            46.95581162326521
                        ],
                        [
                            32.06768193790099,
                            46.95572501831164
                        ],
                        [
                            32.06792341334947,
                            46.955628494138345
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 455,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00653537594163,
                            46.8514464726516
                        ],
                        [
                            32.00610481470293,
                            46.85150119686822
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 456,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06806859421843,
                            46.95684540209929
                        ],
                        [
                            32.06834105670119,
                            46.95673600845855
                        ],
                        [
                            32.06807927902168,
                            46.95643699803281
                        ],
                        [
                            32.06848949515124,
                            46.95627815129998
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 457,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.068672285601814,
                            46.95688368982071
                        ],
                        [
                            32.06856543756935,
                            46.956768826574255
                        ],
                        [
                            32.06882248052174,
                            46.956660294383205
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 458,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06888998846794,
                            46.95736501883871
                        ],
                        [
                            32.06929338930814,
                            46.95720273307854
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 459,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06887663246388,
                            46.957804410108956
                        ],
                        [
                            32.06911971173773,
                            46.95769684162529
                        ],
                        [
                            32.069271970183976,
                            46.95785728267419
                        ],
                        [
                            32.069704585576275,
                            46.95767649770326
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 460,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06960052788377,
                            46.95819457194692
                        ],
                        [
                            32.069718060719474,
                            46.95832219436052
                        ],
                        [
                            32.07011690868668,
                            46.95815155642062
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 461,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06977282033611,
                            46.95883541570761
                        ],
                        [
                            32.0700038792063,
                            46.95874061142448
                        ],
                        [
                            32.069834257954774,
                            46.95854826760317
                        ],
                        [
                            32.07018151406026,
                            46.95840241400757
                        ],
                        [
                            32.07009603563429,
                            46.95830578578155
                        ],
                        [
                            32.07021112949007,
                            46.95826011247478
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 462,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07028101629047,
                            46.95903733003922
                        ],
                        [
                            32.07056416357649,
                            46.95937461187338
                        ],
                        [
                            32.06929051020071,
                            46.959898895104836
                        ],
                        [
                            32.0692990210538,
                            46.959893086361625
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 463,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06910034553184,
                            46.959502231472406
                        ],
                        [
                            32.06933808240405,
                            46.959746530998615
                        ],
                        [
                            32.069295343191065,
                            46.95976293913578
                        ],
                        [
                            32.06963992809573,
                            46.96015308669257
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 464,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07208704043993,
                            46.9605030236778
                        ],
                        [
                            32.07184633996597,
                            46.96060157055611
                        ],
                        [
                            32.07102895251768,
                            46.95967542901278
                        ],
                        [
                            32.069671982505476,
                            46.960193195158645
                        ],
                        [
                            32.06963992809573,
                            46.96015308669257
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 465,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07160269133978,
                            46.96032550595014
                        ],
                        [
                            32.07107770193248,
                            46.96054414295419
                        ],
                        [
                            32.07144632764445,
                            46.9609889773713
                        ],
                        [
                            32.071171193960886,
                            46.961098362317145
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 466,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07102962031787,
                            46.96025062313726
                        ],
                        [
                            32.07124783773654,
                            46.96047328832246
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 467,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.071134642361244,
                            46.96061285533818
                        ],
                        [
                            32.07097352510085,
                            46.96068087523839
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 468,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07038452532193,
                            46.96110565463892
                        ],
                        [
                            32.07018685646187,
                            46.96086500749564
                        ],
                        [
                            32.0704005525268,
                            46.96077932226664
                        ],
                        [
                            32.07001589960995,
                            46.96030896260797
                        ],
                        [
                            32.06950035785334,
                            46.96051132715398
                        ],
                        [
                            32.06940953702576,
                            46.960400117903625
                        ],
                        [
                            32.06873639442128,
                            46.96067540595373
                        ],
                        [
                            32.06864023119207,
                            46.9605641970444
                        ],
                        [
                            32.069671982505476,
                            46.960193195158645
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 470,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07292216609277,
                            46.96205000190971
                        ],
                        [
                            32.073289317404914,
                            46.96190840579294
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 471,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.072892782883855,
                            46.961782013161965
                        ],
                        [
                            32.07242799394266,
                            46.96199439891255
                        ],
                        [
                            32.072353200319945,
                            46.962032682949584
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 472,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.072353200319945,
                            46.962032682949584
                        ],
                        [
                            32.072299776303716,
                            46.9621320390131
                        ],
                        [
                            32.07214484665665,
                            46.96227970548221
                        ],
                        [
                            32.07187238417388,
                            46.96267530376742
                        ],
                        [
                            32.07137286962215,
                            46.962531284453824
                        ],
                        [
                            32.070961504697195,
                            46.96241643333094
                        ],
                        [
                            32.07011473403998,
                            46.96212656987679
                        ],
                        [
                            32.06926529218195,
                            46.96186040456722
                        ],
                        [
                            32.06866961440102,
                            46.96270629521459
                        ],
                        [
                            32.06806058061601,
                            46.96358498642259
                        ],
                        [
                            32.067662989695776,
                            46.96345758238088
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 473,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06734202759773,
                            46.96425037576386
                        ],
                        [
                            32.067472916437495,
                            46.9642941271018
                        ],
                        [
                            32.06797307265439,
                            46.963556945395815
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 474,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06761181887968,
                            46.963831090294065
                        ],
                        [
                            32.06775723114045,
                            46.963875076078345
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 475,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.067662989695776,
                            46.96345758238088
                        ],
                        [
                            32.06748510871658,
                            46.96372215620721
                        ],
                        [
                            32.06731415186464,
                            46.96403206380666
                        ],
                        [
                            32.066868061329146,
                            46.964699270545125
                        ],
                        [
                            32.066603612448816,
                            46.96461359145697
                        ],
                        [
                            32.06461891024593,
                            46.96396643646485
                        ],
                        [
                            32.06442925498832,
                            46.964066700426834
                        ],
                        [
                            32.06384426201062,
                            46.96432191693667
                        ],
                        [
                            32.06331002184834,
                            46.96385523439633
                        ],
                        [
                            32.062773110485246,
                            46.96339037078733
                        ],
                        [
                            32.06216407670023,
                            46.96369116534853
                        ],
                        [
                            32.06149627649739,
                            46.96404300168914
                        ],
                        [
                            32.06115970519514,
                            46.96421436155595
                        ],
                        [
                            32.06080710668804,
                            46.96439301274756
                        ],
                        [
                            32.06040642656632,
                            46.96458624703825
                        ],
                        [
                            32.05993629522351,
                            46.964826877443315
                        ],
                        [
                            32.059460821479085,
                            46.965071152656485
                        ],
                        [
                            32.05933794624176,
                            46.96513677864307
                        ],
                        [
                            32.05894260852167,
                            46.96534459373612
                        ],
                        [
                            32.058760966866494,
                            46.965428448720495
                        ],
                        [
                            32.05821604190096,
                            46.965636262680455
                        ],
                        [
                            32.05772988335329,
                            46.9658185549631
                        ],
                        [
                            32.05837631394966,
                            46.96639459449316
                        ],
                        [
                            32.05870220044865,
                            46.96623053323346
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 476,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.057879470598735,
                            46.96686490064894
                        ],
                        [
                            32.05771919855005,
                            46.966708132389805
                        ],
                        [
                            32.0578954978036,
                            46.966620633626775
                        ],
                        [
                            32.057703171345175,
                            46.96643834407744
                        ],
                        [
                            32.05816261073546,
                            46.96620416251783
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 477,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.057387969649426,
                            46.96618678347922
                        ],
                        [
                            32.05789212347772,
                            46.96596312903256
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 478,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06374275637979,
                            46.964684686880226
                        ],
                        [
                            32.064041930870665,
                            46.96477948063095
                        ],
                        [
                            32.06425028453396,
                            46.96449327591004
                        ],
                        [
                            32.06483794871247,
                            46.964318270995086
                        ],
                        [
                            32.06520924562525,
                            46.96444405583557
                        ],
                        [
                            32.06546835210396,
                            46.964092222132614
                        ],
                        [
                            32.06524664243661,
                            46.964019302940955
                        ],
                        [
                            32.06544164009585,
                            46.96376226199769
                        ],
                        [
                            32.065791567402144,
                            46.963873464259464
                        ],
                        [
                            32.06610409789708,
                            46.963443237832706
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 479,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06426898293963,
                            46.964312802082276
                        ],
                        [
                            32.06443059058873,
                            46.964364756731456
                        ],
                        [
                            32.06440387858061,
                            46.96440577352413
                        ],
                        [
                            32.06447486086488,
                            46.96442639771087
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 480,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06325767465473,
                            46.97258084222852
                        ],
                        [
                            32.0627127496892,
                            46.97227918630443
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 481,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07369681432808,
                            46.96145203877819
                        ],
                        [
                            32.075461723361876,
                            46.96071427455352
                        ],
                        [
                            32.0769712360557,
                            46.960103617434015
                        ],
                        [
                            32.07667962950624,
                            46.959777941389945
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 482,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06771123420757,
                            46.95366664191435
                        ],
                        [
                            32.06821609116092,
                            46.95346425148118
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 483,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06793160076832,
                            46.953578299987264
                        ],
                        [
                            32.068363007205555,
                            46.95404042403555
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 484,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06859005927452,
                            46.95383803501596
                        ],
                        [
                            32.068726733039384,
                            46.95398613620882
                        ],
                        [
                            32.06809922612543,
                            46.954223781444796
                        ],
                        [
                            32.06795965588302,
                            46.95407324380444
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 485,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06922380166703,
                            46.953857293913956
                        ],
                        [
                            32.068935311979395,
                            46.95396487011608
                        ],
                        [
                            32.06989160186988,
                            46.95508255582761
                        ],
                        [
                            32.068943325581834,
                            46.955472737511975
                        ],
                        [
                            32.06908623482524,
                            46.95564868299502
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 486,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06898296839952,
                            46.95545642597119
                        ],
                        [
                            32.06788939709749,
                            46.954308857565046
                        ],
                        [
                            32.06809922612543,
                            46.954223781444796
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 487,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06792483937474,
                            46.9543460502561
                        ],
                        [
                            32.06771457320858,
                            46.954426169005515
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 488,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.068459932276454,
                            46.954907567043236
                        ],
                        [
                            32.068204738557476,
                            46.955010536000074
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 489,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.068459932276454,
                            46.954907567043236
                        ],
                        [
                            32.06912496723701,
                            46.95462855580083
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 490,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06972064501796,
                            46.95536698761687
                        ],
                        [
                            32.069576153654616,
                            46.955212351770996
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 491,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07005187391857,
                            46.95480723900193
                        ],
                        [
                            32.0697547829975,
                            46.95492264706793
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 492,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.069688590608216,
                            46.95438970283489
                        ],
                        [
                            32.06939067085811,
                            46.95449708414606
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 493,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.050509368782244,
                            46.982159697271555
                        ],
                        [
                            32.05053340958954,
                            46.98232188731283
                        ],
                        [
                            32.05129203061999,
                            46.98230548609506
                        ],
                        [
                            32.05128134581675,
                            46.9821742761718
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 494,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0510142257356,
                            46.98342075744119
                        ],
                        [
                            32.05068032563417,
                            46.98342075744119
                        ],
                        [
                            32.05062155534535,
                            46.982319981621465
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 495,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.050657952788534,
                            46.98300171355075
                        ],
                        [
                            32.0503116999222,
                            46.98301437858288
                        ],
                        [
                            32.0503330695287,
                            46.983213012581174
                        ],
                        [
                            32.05045861596683,
                            46.98320572327318
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 496,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05044793116359,
                            46.98271369268586
                        ],
                        [
                            32.050642284364464,
                            46.982708241667915
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 497,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05120121634327,
                            46.982307449479755
                        ],
                        [
                            32.0512466202062,
                            46.982850368303254
                        ],
                        [
                            32.05184496918796,
                            46.982833967247615
                        ],
                        [
                            32.0518796947985,
                            46.98330230652344
                        ],
                        [
                            32.05198654283095,
                            46.98329683955166
                        ],
                        [
                            32.05198921403178,
                            46.98338977799574
                        ],
                        [
                            32.05189305080256,
                            46.98338977799574
                        ],
                        [
                            32.05192243401148,
                            46.98399842969439
                        ],
                        [
                            32.051040937743714,
                            46.98401665269229
                        ],
                        [
                            32.051043608944525,
                            46.98422074984445
                        ],
                        [
                            32.050618888015514,
                            46.98424261735025
                        ],
                        [
                            32.05062423041713,
                            46.98485672615191
                        ],
                        [
                            32.05070436644148,
                            46.98535784828507
                        ],
                        [
                            32.0508272416788,
                            46.98535420377742
                        ],
                        [
                            32.05093408971126,
                            46.98552731761623
                        ],
                        [
                            32.05086196728935,
                            46.985551006834775
                        ],
                        [
                            32.05097415772343,
                            46.98577514276762
                        ],
                        [
                            32.05091004890396,
                            46.98579154292094
                        ],
                        [
                            32.05101155453479,
                            46.986013855613855
                        ],
                        [
                            32.05091004890396,
                            46.98603572238617
                        ],
                        [
                            32.05105429374777,
                            46.986340034039095
                        ],
                        [
                            32.0512012097924,
                            46.98630723406399
                        ],
                        [
                            32.051409563455685,
                            46.98680652039522
                        ],
                        [
                            32.05121723699727,
                            46.98684296447037
                        ],
                        [
                            32.05133744103379,
                            46.98711629424183
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 498,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.050972414440984,
                            46.985928132994665
                        ],
                        [
                            32.05108901935832,
                            46.98590452161813
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 499,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05090864730918,
                            46.98548609620711
                        ],
                        [
                            32.051043608944525,
                            46.98545078314611
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 500,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.051694943277425,
                            46.9840031325659
                        ],
                        [
                            32.05170339554495,
                            46.98421346067387
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 501,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05173277875387,
                            46.98378339784997
                        ],
                        [
                            32.051743546878974,
                            46.984002127793175
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 502,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.051238606603754,
                            46.983657658489356
                        ],
                        [
                            32.051468329873536,
                            46.983648546929984
                        ],
                        [
                            32.05147974496838,
                            46.98400758131814
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 503,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0512713398416,
                            46.98401188963591
                        ],
                        [
                            32.0512706610135,
                            46.983947405267195
                        ],
                        [
                            32.05093408971126,
                            46.98395287217244
                        ],
                        [
                            32.050931418510444,
                            46.983874513143874
                        ],
                        [
                            32.050773817662574,
                            46.98387633544817
                        ],
                        [
                            32.050509368782244,
                            46.98409683380928
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 505,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05070436644148,
                            46.98535784828507
                        ],
                        [
                            32.0503330695287,
                            46.98536422617288
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 506,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05029033031572,
                            46.98449318188245
                        ],
                        [
                            32.05062105175566,
                            46.984491340024256
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 507,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05143616885269,
                            46.98266874219104
                        ],
                        [
                            32.051232012663625,
                            46.98267569852849
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 508,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05140579823373,
                            46.98284600515153
                        ],
                        [
                            32.05142615184965,
                            46.9830828685853
                        ],
                        [
                            32.05160245110319,
                            46.98307831275683
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 509,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05929804024181,
                            46.933328134720824
                        ],
                        [
                            32.05998988125197,
                            46.93305635406416
                        ],
                        [
                            32.06051076541019,
                            46.933468584586734
                        ],
                        [
                            32.060465354996396,
                            46.9334941208864
                        ],
                        [
                            32.06076987188891,
                            46.933760427286025
                        ],
                        [
                            32.060903431929475,
                            46.933872603900426
                        ],
                        [
                            32.060976889951796,
                            46.93383521172173
                        ],
                        [
                            32.06134952246499,
                            46.934163532398905
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 510,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05943277883957,
                            46.933275204540024
                        ],
                        [
                            32.05939420347104,
                            46.93295055987857
                        ],
                        [
                            32.05911105618502,
                            46.932711610173044
                        ],
                        [
                            32.058870648112,
                            46.932835645267765
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 511,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05924650263124,
                            46.93282591438042
                        ],
                        [
                            32.05944762748726,
                            46.932724378504886
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 512,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05908300857649,
                            46.93601212263801
                        ],
                        [
                            32.05959587913229,
                            46.93646810449829
                        ],
                        [
                            32.059697384763126,
                            46.936413386880076
                        ],
                        [
                            32.06034381535948,
                            46.93700068639502
                        ],
                        [
                            32.06227776474695,
                            46.938799023109645
                        ],
                        [
                            32.0613775700735,
                            46.93923856681294
                        ],
                        [
                            32.06096086274691,
                            46.93884826677455
                        ],
                        [
                            32.060797919497425,
                            46.93895769697887
                        ],
                        [
                            32.05966533035338,
                            46.939752883079485
                        ],
                        [
                            32.05959855033311,
                            46.939710935483916
                        ],
                        [
                            32.05968135755825,
                            46.93964892593462
                        ],
                        [
                            32.058839929302664,
                            46.93914555164144
                        ],
                        [
                            32.05872506766777,
                            46.939076246114496
                        ],
                        [
                            32.059149788596784,
                            46.93876619397455
                        ],
                        [
                            32.05896814694161,
                            46.93863670107869
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 513,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.058516714004476,
                            46.938416015281035
                        ],
                        [
                            32.058687670856415,
                            46.93852362251915
                        ],
                        [
                            32.05878650528643,
                            46.93849261706593
                        ],
                        [
                            32.05897081814242,
                            46.938596576455865
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 514,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.059010886154596,
                            46.93855189218142
                        ],
                        [
                            32.05896013333918,
                            46.9384999124683
                        ],
                        [
                            32.05944629188686,
                            46.93828013803585
                        ],
                        [
                            32.058896024519704,
                            46.937691940614904
                        ],
                        [
                            32.0591284189903,
                            46.937587979468574
                        ],
                        [
                            32.05892006532701,
                            46.937367289348565
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 515,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05930471824386,
                            46.93775577630648
                        ],
                        [
                            32.059849643209375,
                            46.938350356521035
                        ],
                        [
                            32.05983895840614,
                            46.93847985010939
                        ],
                        [
                            32.05793172102678,
                            46.93976564973248
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 516,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.058687670856415,
                            46.93852362251915
                        ],
                        [
                            32.05799983664746,
                            46.9387744012602
                        ],
                        [
                            32.05777412017889,
                            46.93851632712
                        ],
                        [
                            32.0575630953148,
                            46.938602959920594
                        ],
                        [
                            32.05748429489086,
                            46.93852909406783
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 517,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05811751613079,
                            46.938731496363914
                        ],
                        [
                            32.05797579584015,
                            46.93858198567648
                        ],
                        [
                            32.0566775922458,
                            46.93912001803668
                        ],
                        [
                            32.056920671519634,
                            46.93939176791959
                        ],
                        [
                            32.05617398403094,
                            46.93976861876798
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 518,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05696608193344,
                            46.93874795555745
                        ],
                        [
                            32.057143252361705,
                            46.93892702873589
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 519,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05707584414589,
                            46.93895496560238
                        ],
                        [
                            32.05725724282188,
                            46.93913825632703
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 520,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.056490608189,
                            46.939225800034244
                        ],
                        [
                            32.05672622212867,
                            46.93948782095531
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 522,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05617398403094,
                            46.93976861876798
                        ],
                        [
                            32.056348216339856,
                            46.93996423857794
                        ],
                        [
                            32.05545685347497,
                            46.940324643768314
                        ],
                        [
                            32.05530192382791,
                            46.940167797743705
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 523,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05891071612414,
                            46.938641260693025
                        ],
                        [
                            32.05879051208762,
                            46.938586545295465
                        ],
                        [
                            32.05769665535536,
                            46.93900329283134
                        ],
                        [
                            32.057659258544,
                            46.93920026646778
                        ],
                        [
                            32.0569594039314,
                            46.939470192126
                        ],
                        [
                            32.05712768958253,
                            46.939648925934634
                        ],
                        [
                            32.05690330871437,
                            46.93974011642347
                        ],
                        [
                            32.05739948426509,
                            46.94031826050887
                        ],
                        [
                            32.05707426556629,
                            46.94053346712286
                        ],
                        [
                            32.05727460562714,
                            46.940653836546694
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 524,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05697231721522,
                            46.93981881405394
                        ],
                        [
                            32.05656540181172,
                            46.93998541806758
                        ],
                        [
                            32.05644252657439,
                            46.939852280511715
                        ],
                        [
                            32.05617006409163,
                            46.939956237261754
                        ],
                        [
                            32.05589760160886,
                            46.9396612366597
                        ],
                        [
                            32.05483179248512,
                            46.940091198355404
                        ],
                        [
                            32.054703574846165,
                            46.9403337627091
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 525,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.056180748894874,
                            46.939409094207655
                        ],
                        [
                            32.05668552560562,
                            46.93993623564764
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 526,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.054703574846165,
                            46.9403337627091
                        ],
                        [
                            32.05409380375928,
                            46.940197230772895
                        ],
                        [
                            32.05405847985021,
                            46.94026309087741
                        ],
                        [
                            32.05378868856826,
                            46.94019925817502
                        ],
                        [
                            32.05363643012202,
                            46.94044911717595
                        ],
                        [
                            32.053347940434385,
                            46.94048741662779
                        ],
                        [
                            32.053225065197054,
                            46.94046005987928
                        ],
                        [
                            32.05314760037353,
                            46.940602314818875
                        ],
                        [
                            32.052963287517535,
                            46.940554896547674
                        ],
                        [
                            32.05245041696175,
                            46.94147407401383
                        ],
                        [
                            32.05072482123758,
                            46.94103637242662
                        ],
                        [
                            32.050313456312615,
                            46.94179870037986
                        ],
                        [
                            32.051056812537205,
                            46.941997871303265
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 527,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.054703574846165,
                            46.9403337627091
                        ],
                        [
                            32.05465549323158,
                            46.940432703116784
                        ],
                        [
                            32.055093570164644,
                            46.94053209928695
                        ],
                        [
                            32.05498538653179,
                            46.940734538622515
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 528,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05545685347497,
                            46.940324643768314
                        ],
                        [
                            32.054703574846165,
                            46.9403337627091
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 529,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05959855033311,
                            46.939710935483916
                        ],
                        [
                            32.05912028748986,
                            46.94004163976645
                        ],
                        [
                            32.059835285505,
                            46.94045686825771
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 530,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.060183209410695,
                            46.93992660048957
                        ],
                        [
                            32.05880486979199,
                            46.94089685429363
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 531,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.059835285505,
                            46.940456868257705
                        ],
                        [
                            32.05998887955166,
                            46.94063673863368
                        ],
                        [
                            32.06034548485999,
                            46.94049995513286
                        ],
                        [
                            32.06026345705205,
                            46.94040264228807
                        ],
                        [
                            32.06078489739346,
                            46.940195382615656
                        ],
                        [
                            32.06115886550705,
                            46.93999658884061
                        ],
                        [
                            32.060979895052704,
                            46.93977226103376
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 532,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06078489739346,
                            46.940195382615656
                        ],
                        [
                            32.060804931399545,
                            46.940223651395286
                        ],
                        [
                            32.06072880217642,
                            46.94025556774144
                        ],
                        [
                            32.06080092459834,
                            46.940332166894606
                        ],
                        [
                            32.060433634486756,
                            46.94047442217399
                        ],
                        [
                            32.06092513543606,
                            46.941027025634966
                        ],
                        [
                            32.0608247896928,
                            46.9410672180661
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 533,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06031776676193,
                            46.940510587007545
                        ],
                        [
                            32.06074082258008,
                            46.94102611375277
                        ],
                        [
                            32.06077821939143,
                            46.94101243551789
                        ],
                        [
                            32.061086743085156,
                            46.94137536349965
                        ],
                        [
                            32.06186005572006,
                            46.94106350091027
                        ],
                        [
                            32.061794611300186,
                            46.94099055033481
                        ],
                        [
                            32.06184402851519,
                            46.94096866514278
                        ],
                        [
                            32.06191080853548,
                            46.9410461751576
                        ],
                        [
                            32.06223402383366,
                            46.94091304023946
                        ],
                        [
                            32.06206039578092,
                            46.94071424912903
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 534,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06080138288967,
                            46.94103968369426
                        ],
                        [
                            32.06071744957297,
                            46.941069884080896
                        ],
                        [
                            32.0606483799829,
                            46.9410960385309
                        ],
                        [
                            32.06034748826059,
                            46.940736134425045
                        ],
                        [
                            32.059992218552665,
                            46.9408692697831
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 535,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06265540576166,
                            46.941085386063534
                        ],
                        [
                            32.06207041278397,
                            46.94132065089607
                        ],
                        [
                            32.06282369141279,
                            46.94222887287607
                        ],
                        [
                            32.0634113555913,
                            46.942002730691065
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 536,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06209313358555,
                            46.942079749033546
                        ],
                        [
                            32.062275006684224,
                            46.94201459029064
                        ],
                        [
                            32.0618235737471,
                            46.94149664825338
                        ],
                        [
                            32.06163658969029,
                            46.94156959813926
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 537,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.061999873000644,
                            46.94201459029064
                        ],
                        [
                            32.06221058830315,
                            46.941940681529395
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 538,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.062357392099045,
                            46.94166666136325
                        ],
                        [
                            32.06206439557168,
                            46.94177295070645
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 539,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06192775057874,
                            46.942023708943594
                        ],
                        [
                            32.06176747853006,
                            46.94208024455725
                        ],
                        [
                            32.06134275760104,
                            46.94154041819683
                        ],
                        [
                            32.06017277164565,
                            46.942001824173886
                        ],
                        [
                            32.06067228619738,
                            46.94257812012536
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 540,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06017277164565,
                            46.942001824173886
                        ],
                        [
                            32.05960914827444,
                            46.941381751981545
                        ],
                        [
                            32.059283261775434,
                            46.94151306198637
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 541,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06017277164565,
                            46.942001824173886
                        ],
                        [
                            32.058992100887004,
                            46.94247599218117
                        ],
                        [
                            32.05876351948468,
                            46.94221842928318
                        ],
                        [
                            32.05638233769425,
                            46.94315076248937
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 542,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05986203097086,
                            46.941659962638916
                        ],
                        [
                            32.060242222866755,
                            46.94153677070289
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 543,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05850064329283,
                            46.94232135720841
                        ],
                        [
                            32.057835470935665,
                            46.94157506937669
                        ],
                        [
                            32.05813464542654,
                            46.941356219443215
                        ],
                        [
                            32.058546010351506,
                            46.941826745694236
                        ],
                        [
                            32.058765048818046,
                            46.941728263797835
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 544,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.058163723092456,
                            46.94245327642439
                        ],
                        [
                            32.0582628630655,
                            46.94256717785493
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 545,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05761910366994,
                            46.94237751147903
                        ],
                        [
                            32.05781796338398,
                            46.94258865636227
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 546,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05922580692408,
                            46.94238213406579
                        ],
                        [
                            32.05986024115073,
                            46.94308510953558
                        ],
                        [
                            32.059021484095936,
                            46.94341519718248
                        ],
                        [
                            32.05910963372272,
                            46.94350820493648
                        ],
                        [
                            32.05858340716287,
                            46.943708809345935
                        ],
                        [
                            32.05784348453811,
                            46.94286079466929
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 547,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05933897519483,
                            46.9432902507652
                        ],
                        [
                            32.05897874488296,
                            46.9428717368797
                        ],
                        [
                            32.05863148877747,
                            46.94300669062415
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 548,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05918423962909,
                            46.943351145909034
                        ],
                        [
                            32.05966257229068,
                            46.94393859165492
                        ],
                        [
                            32.06007927961726,
                            46.94444921547709
                        ],
                        [
                            32.05982017313855,
                            46.94453492685557
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 549,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05976081099425,
                            46.94405897160012
                        ],
                        [
                            32.05944086262333,
                            46.94420484611276
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 550,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.053959558558304,
                            46.94556162908329
                        ],
                        [
                            32.053168883118126,
                            46.94508748840564
                        ],
                        [
                            32.05619268243664,
                            46.94301580910811
                        ],
                        [
                            32.05638233769425,
                            46.94315076248937
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 551,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.055023427989276,
                            46.94381690359989
                        ],
                        [
                            32.05484639722769,
                            46.94369239628612
                        ],
                        [
                            32.055025367682056,
                            46.94357385737142
                        ],
                        [
                            32.05492386205122,
                            46.94350820493648
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 552,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0556180627481,
                            46.94340950136212
                        ],
                        [
                            32.05543673260701,
                            46.94329301027973
                        ],
                        [
                            32.055583648651634,
                            46.94319088369928
                        ],
                        [
                            32.055466115815946,
                            46.94311428863609
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 553,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05638233769425,
                            46.94315076248937
                        ],
                        [
                            32.056935276262216,
                            46.94354650219997
                        ],
                        [
                            32.05666014257864,
                            46.94363403869938
                        ],
                        [
                            32.05682041462733,
                            46.94374893013762
                        ],
                        [
                            32.05750157083424,
                            46.94453675050042
                        ],
                        [
                            32.05770725329672,
                            46.94445468642024
                        ],
                        [
                            32.05813731662735,
                            46.94497624709998
                        ],
                        [
                            32.057939647767306,
                            46.945045544989675
                        ],
                        [
                            32.058083892611116,
                            46.94522061294302
                        ],
                        [
                            32.05790225095594,
                            46.94528991051637
                        ],
                        [
                            32.05801444139003,
                            46.94542485816495
                        ],
                        [
                            32.05823882225818,
                            46.94535191353229
                        ],
                        [
                            32.05860744797016,
                            46.945731224536715
                        ],
                        [
                            32.05753896764559,
                            46.94614335748522
                        ],
                        [
                            32.057448146818,
                            46.9460375891551
                        ],
                        [
                            32.05769389729266,
                            46.945942762198676
                        ],
                        [
                            32.0570474666963,
                            46.94523520191328
                        ],
                        [
                            32.05694061866384,
                            46.94527896880023
                        ],
                        [
                            32.05707417870441,
                            46.945417563706165
                        ],
                        [
                            32.05705815149955,
                            46.945578041570016
                        ],
                        [
                            32.057309244375816,
                            46.945858876674116
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 554,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05795227177379,
                            46.94535007782491
                        ],
                        [
                            32.05779006052187,
                            46.94539932755483
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 555,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.057981123950086,
                            46.94503100445725
                        ],
                        [
                            32.05777403331701,
                            46.94485588739413
                        ],
                        [
                            32.05737869559691,
                            46.94500360154084
                        ],
                        [
                            32.05731458677744,
                            46.94493977449042
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 556,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.058284258566005,
                            46.94539866704007
                        ],
                        [
                            32.05858607836367,
                            46.94528443965857
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 557,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05860744797016,
                            46.945731224536715
                        ],
                        [
                            32.05923250896002,
                            46.946298362419085
                        ],
                        [
                            32.059574422663886,
                            46.946710491001156
                        ],
                        [
                            32.060546739759246,
                            46.94782650468261
                        ],
                        [
                            32.06038112530892,
                            46.94788850476044
                        ],
                        [
                            32.06060550617709,
                            46.94812556321936
                        ],
                        [
                            32.06086194145499,
                            46.94802435762109
                        ],
                        [
                            32.06106628831706,
                            46.948256856683294
                        ],
                        [
                            32.060883311061474,
                            46.9483261503261
                        ],
                        [
                            32.061090329124355,
                            46.948583265427246
                        ],
                        [
                            32.06125129398732,
                            46.948530153949136
                        ],
                        [
                            32.06132673039617,
                            46.948610618024915
                        ],
                        [
                            32.061412208822134,
                            46.94858508893421
                        ],
                        [
                            32.061957133787665,
                            46.94919413677833
                        ],
                        [
                            32.061999873000644,
                            46.94936554420336
                        ],
                        [
                            32.06222959627043,
                            46.94961718389668
                        ],
                        [
                            32.06221891146717,
                            46.94977400223764
                        ],
                        [
                            32.06247534674507,
                            46.950069402980795
                        ],
                        [
                            32.062806575645695,
                            46.95042315234281
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 558,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.060951151705595,
                            46.948125858200925
                        ],
                        [
                            32.06104758991137,
                            46.94808818099362
                        ],
                        [
                            32.061253272373854,
                            46.94832706208393
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 559,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05873415654825,
                            46.945846191778834
                        ],
                        [
                            32.057443960357396,
                            46.94633041212972
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 560,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05691791345692,
                            46.94627921477507
                        ],
                        [
                            32.05618600443459,
                            46.94542212274301
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 561,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.056661478179024,
                            46.945644603270104
                        ],
                        [
                            32.056314222073546,
                            46.94578319722938
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 562,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05666682058065,
                            46.94556801171759
                        ],
                        [
                            32.05677633981392,
                            46.94553518673298
                        ],
                        [
                            32.058053173801774,
                            46.947056056547936
                        ],
                        [
                            32.05794098336769,
                            46.94719829396131
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 563,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05386740213028,
                            46.94082641652508
                        ],
                        [
                            32.05419863103089,
                            46.94089936732419
                        ],
                        [
                            32.05453583432438,
                            46.94029620448841
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 564,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05688652684738,
                            46.94408266101227
                        ],
                        [
                            32.05727652216585,
                            46.94390758933745
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 565,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04595136365696,
                            46.954517626998204
                        ],
                        [
                            32.04616841873602,
                            46.95476233548064
                        ],
                        [
                            32.04637226337603,
                            46.954684697891224
                        ],
                        [
                            32.0465732630408,
                            46.954922144497814
                        ],
                        [
                            32.04737319636588,
                            46.95459420256198
                        ],
                        [
                            32.04864382216581,
                            46.956107379813
                        ],
                        [
                            32.048795029074824,
                            46.95604412353516
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 566,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.048199956723224,
                            46.95523177311051
                        ],
                        [
                            32.04798408461643,
                            46.955321709424815
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 567,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04737319636588,
                            46.95459420256198
                        ],
                        [
                            32.04760976201386,
                            46.954492656681985
                        ],
                        [
                            32.04775853010176,
                            46.95464247840458
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 568,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.047339052870306,
                            46.954257935139914
                        ],
                        [
                            32.04749335297719,
                            46.954542625322254
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 569,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04742269183048,
                            46.95441225272471
                        ],
                        [
                            32.04836579655892,
                            46.95390501854328
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 570,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04181256086364,
                            46.954763847153956
                        ],
                        [
                            32.04203705577047,
                            46.95501703087593
                        ],
                        [
                            32.04226142731287,
                            46.954943785265506
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 571,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04021281757789,
                            46.954264593921216
                        ],
                        [
                            32.04080301228725,
                            46.95403153608135
                        ],
                        [
                            32.04084203342506,
                            46.954066494822044
                        ],
                        [
                            32.04128102122541,
                            46.953886706769524
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 572,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.040932413266304,
                            46.95402947968413
                        ],
                        [
                            32.0410371391141,
                            46.95414307103137
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 573,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.040687818457776,
                            46.954077024242586
                        ],
                        [
                            32.04062010070377,
                            46.95399657731784
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 574,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.040533300463146,
                            46.9541380408135
                        ],
                        [
                            32.04065912184158,
                            46.95427791148143
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 575,
                id: 5,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04642381315602,
                            46.954745595209296
                        ],
                        [
                            32.04667672459945,
                            46.95463866221654
                        ],
                        [
                            32.046466684894675,
                            46.95437033921029
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 576,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99418075422417,
                            46.86010998296973
                        ],
                        [
                            31.9943517110761,
                            46.860071626058804
                        ],
                        [
                            31.99403383817954,
                            46.85949444256689
                        ],
                        [
                            31.994146028613628,
                            46.859463391380366
                        ],
                        [
                            31.99381747091382,
                            46.85889168103131
                        ],
                        [
                            31.994795130410804,
                            46.85863048226449
                        ],
                        [
                            31.995398821794183,
                            46.85861221656893
                        ],
                        [
                            31.99716448553053,
                            46.85814278606106
                        ],
                        [
                            31.997298045571092,
                            46.85807520234258
                        ],
                        [
                            31.998299745875382,
                            46.85794368783579
                        ],
                        [
                            31.999982602386574,
                            46.85778660064164
                        ],
                        [
                            32.00242407992821,
                            46.85753087631931
                        ],
                        [
                            32.003401739425186,
                            46.85741762658747
                        ],
                        [
                            32.01133824334884,
                            46.856462427226724
                        ],
                        [
                            32.01152514952806,
                            46.8570888589642
                        ],
                        [
                            32.011890020387135,
                            46.8581799195265
                        ],
                        [
                            32.01232613480532,
                            46.85974514616396
                        ],
                        [
                            32.012513040984544,
                            46.86015537946983
                        ],
                        [
                            32.01265610497358,
                            46.86032893877105
                        ],
                        [
                            32.01324912828297,
                            46.861345038331784
                        ],
                        [
                            32.013186826223226,
                            46.86230274189712
                        ],
                        [
                            32.01324220583189,
                            46.86306636808298
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 577,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.004441174587164,
                            46.8560888526806
                        ],
                        [
                            32.00474774449063,
                            46.857255668481194
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 578,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.004067362228724,
                            46.856210353451736
                        ],
                        [
                            32.004217348668845,
                            46.85619457414635
                        ],
                        [
                            32.00414350919062,
                            46.85587898706478
                        ],
                        [
                            32.00364509271271,
                            46.85589003264394
                        ],
                        [
                            32.00355510084863,
                            46.85581902530959
                        ],
                        [
                            32.00351587362583,
                            46.855576021721554
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 579,
                id: 5,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00419583615315,
                            46.856102630675586
                        ],
                        [
                            32.00442040723392,
                            46.856073073339516
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 580,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00453578141863,
                            46.856062027798
                        ],
                        [
                            32.00482652436409,
                            46.85602415735264
                        ],
                        [
                            32.0048657515869,
                            46.85615196999863
                        ],
                        [
                            32.005244178912726,
                            46.856077807142334
                        ],
                        [
                            32.006506372493405,
                            46.85591843555139
                        ],
                        [
                            32.00670481609109,
                            46.85672949001349
                        ],
                        [
                            32.00759550479702,
                            46.85663481502685
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 581,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00570100152976,
                            46.85602012622917
                        ],
                        [
                            32.0058418171895,
                            46.85654960739618
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 582,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00495440018704,
                            46.856134596966946
                        ],
                        [
                            32.00508496253783,
                            46.85665375003754
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 583,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00442040723392,
                            46.856073073339516
                        ],
                        [
                            32.00416658402757,
                            46.85511210272718
                        ],
                        [
                            32.0045080916143,
                            46.85507423161193
                        ],
                        [
                            32.00454501135341,
                            46.8552004685589
                        ],
                        [
                            32.00493266861402,
                            46.85515628566122
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 584,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00417370070853,
                            46.85513904660214
                        ],
                        [
                            32.003035917017456,
                            46.85525411917152
                        ],
                        [
                            32.00265287472423,
                            46.853707697667815
                        ],
                        [
                            32.002495965833035,
                            46.853726633710636
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 585,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00280960803332,
                            46.85434046805805
                        ],
                        [
                            32.00221445282235,
                            46.854419372686905
                        ],
                        [
                            32.00213830586045,
                            46.854188986684264
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 586,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.002770556392626,
                            46.85478230753543
                        ],
                        [
                            32.003328967446606,
                            46.85472234455569
                        ],
                        [
                            32.00327358783794,
                            46.854510895619114
                        ],
                        [
                            32.00343972666392,
                            46.85449195985283
                        ],
                        [
                            32.003287432740116,
                            46.85387023181803
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 587,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00623870438486,
                            46.855149579325364
                        ],
                        [
                            32.006506372493405,
                            46.85591843555138
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 588,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00492275914226,
                            46.85439237507733
                        ],
                        [
                            32.00492275914226,
                            46.85438830419138
                        ],
                        [
                            32.00491680629089,
                            46.85439237507733
                        ],
                        [
                            32.00469730527719,
                            46.853543190514884
                        ],
                        [
                            32.00465115560331,
                            46.85327177233603
                        ],
                        [
                            32.00449424671211,
                            46.85329070853255
                        ],
                        [
                            32.00444809703822,
                            46.85302086710171
                        ],
                        [
                            32.00414282196221,
                            46.853065500487226
                        ],
                        [
                            32.00399583023418,
                            46.852577440519255
                        ],
                        [
                            32.00370508728873,
                            46.85260584517878
                        ],
                        [
                            32.00342818924543,
                            46.85165270284249
                        ],
                        [
                            32.00376508186478,
                            46.851608517025916
                        ],
                        [
                            32.00372816212566,
                            46.85149174004995
                        ],
                        [
                            32.001856792849736,
                            46.85173160599596
                        ],
                        [
                            32.00189371258885,
                            46.851876787495485
                        ],
                        [
                            32.00176218601828,
                            46.85188941195514
                        ],
                        [
                            32.00170449892594,
                            46.85235020270227
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 589,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.000806887768924,
                            46.85160693896036
                        ],
                        [
                            32.001856792849736,
                            46.85173160599596
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 590,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00346741646823,
                            46.85230601745954
                        ],
                        [
                            32.00361346360598,
                            46.85229045872293
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 591,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00545185244515,
                            46.85196200254068
                        ],
                        [
                            32.00511265234211,
                            46.85200618806644
                        ],
                        [
                            32.00525571633115,
                            46.85253956761654
                        ],
                        [
                            32.00469269030978,
                            46.85261057928723
                        ],
                        [
                            32.00473422501628,
                            46.852771538726536
                        ],
                        [
                            32.00297822992506,
                            46.85295459005165
                        ],
                        [
                            32.00200908677354,
                            46.85293880978958
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 592,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00251904066994,
                            46.85248118017251
                        ],
                        [
                            32.003012842180475,
                            46.85242121462337
                        ],
                        [
                            32.00314315348234,
                            46.8529373978458
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 593,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00427965072854,
                            46.85231706377364
                        ],
                        [
                            32.00433272285351,
                            46.85250011664778
                        ],
                        [
                            32.00482421688035,
                            46.85244330720189
                        ],
                        [
                            32.00498112577155,
                            46.8529751043854
                        ],
                        [
                            32.00629523773535,
                            46.8528015213139
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 594,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00584643215685,
                            46.85101358302713
                        ],
                        [
                            32.00629869896089,
                            46.85111142408448
                        ],
                        [
                            32.00651560242813,
                            46.85130079336658
                        ],
                        [
                            32.00654098474877,
                            46.851487794877436
                        ],
                        [
                            32.006409458178204,
                            46.851498841359806
                        ],
                        [
                            32.00643511883801,
                            46.85157330756136
                        ],
                        [
                            32.00643841859247,
                            46.85157782090372
                        ],
                        [
                            32.0064285193291,
                            46.85157556423259
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 596,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.005207969173064,
                            46.85236155445186
                        ],
                        [
                            32.00534801567891,
                            46.85234586308183
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 597,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00893096098496,
                            46.85231430219533
                        ],
                        [
                            32.00649195072029,
                            46.85261728594018
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 598,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00752801089894,
                            46.85266620503037
                        ],
                        [
                            32.007497068312865,
                            46.85249242636429
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 599,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.007142919831736,
                            46.85253642008481
                        ],
                        [
                            32.00724527203753,
                            46.8529324483701
                        ],
                        [
                            32.00705605837463,
                            46.85295138468627
                        ],
                        [
                            32.007342186352695,
                            46.854008651745616
                        ],
                        [
                            32.01382160056574,
                            46.85325436483698
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 600,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.007919057276226,
                            46.85364886767972
                        ],
                        [
                            32.007997380126085,
                            46.85393237926944
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 601,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00785529114177,
                            46.85394892015419
                        ],
                        [
                            32.00831594447161,
                            46.855293124316724
                        ],
                        [
                            32.0086712969605,
                            46.855286812487314
                        ],
                        [
                            32.008897430362516,
                            46.85599057689637
                        ],
                        [
                            32.008629762253996,
                            46.856025291494
                        ],
                        [
                            32.00876821127565,
                            46.85652076103228
                        ],
                        [
                            32.00907279912327,
                            46.85648604675496
                        ],
                        [
                            32.00922047807969,
                            46.857073030607424
                        ],
                        [
                            32.00933448219593,
                            46.85714600866213
                        ],
                        [
                            32.00938697744997,
                            46.857485651168496
                        ],
                        [
                            32.009343712130715,
                            46.857489990373594
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 602,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00793924775854,
                            46.855759014176776
                        ],
                        [
                            32.00878923617914,
                            46.85565385992036
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 603,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00811896788747,
                            46.85471834317362
                        ],
                        [
                            32.00868225750805,
                            46.85464970838943
                        ],
                        [
                            32.00863610783416,
                            46.85446508486061
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 604,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.007147486320214,
                            46.853289220934606
                        ],
                        [
                            32.004235736429486,
                            46.85367608826899
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 605,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.004235736429486,
                            46.85367608826899
                        ],
                        [
                            32.0042726561686,
                            46.85402640418647
                        ],
                        [
                            32.004235736429486,
                            46.85367608826899
                        ],
                        [
                            32.004244966364276,
                            46.85347094725641
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 606,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00534142634577,
                            46.8535291819855
                        ],
                        [
                            32.00538486330916,
                            46.85368240028772
                        ],
                        [
                            32.005664068836154,
                            46.85364768417553
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 607,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00491789954526,
                            46.8543932500378
                        ],
                        [
                            32.00459455014393,
                            46.854433525219534
                        ],
                        [
                            32.00461185627164,
                            46.85454871781985
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 608,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00923516069702,
                            46.853788286351225
                        ],
                        [
                            32.00915990663272,
                            46.85347962631516
                        ],
                        [
                            32.00958448363244,
                            46.85343386398978
                        ],
                        [
                            32.00948526183358,
                            46.85296361438696
                        ],
                        [
                            32.00979215716491,
                            46.85292574175658
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 609,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.009224516176154,
                            46.85317664743531
                        ],
                        [
                            32.00929629696153,
                            46.85346492571478
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 610,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.008661490154786,
                            46.85202625893505
                        ],
                        [
                            32.00887723988018,
                            46.85262986091221
                        ],
                        [
                            32.01015327836303,
                            46.852476002228926
                        ],
                        [
                            32.01024557771079,
                            46.85283895021117
                        ],
                        [
                            32.0109101330147,
                            46.85275689262108
                        ],
                        [
                            32.010993202427684,
                            46.85305671782316
                        ],
                        [
                            32.01120087596016,
                            46.85303462549697
                        ],
                        [
                            32.01125625556882,
                            46.85322398799796
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 611,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.010351721960724,
                            46.85270639558024
                        ],
                        [
                            32.010377390076556,
                            46.85282267436716
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 612,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01015327836303,
                            46.852476002228926
                        ],
                        [
                            32.00999636947184,
                            46.85188265589707
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 613,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00933185352219,
                            46.852575045800585
                        ],
                        [
                            32.00931090259695,
                            46.852499376941886
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 614,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00814562333143,
                            46.85132293572082
                        ],
                        [
                            32.00807870630431,
                            46.85106255289315
                        ],
                        [
                            32.008480208467084,
                            46.851007320009835
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 615,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00807870630431,
                            46.85106255289314
                        ],
                        [
                            32.00758490479377,
                            46.8511225199593
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 616,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00988546603679,
                            46.85120300196913
                        ],
                        [
                            32.01134841069886,
                            46.85172376500031
                        ],
                        [
                            32.01283904516525,
                            46.85178373132817
                        ],
                        [
                            32.01382160056574,
                            46.85325436483698
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 617,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.010711545199285,
                            46.85133556031069
                        ],
                        [
                            32.01076773000799,
                            46.85151706124696
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 618,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01277443562181,
                            46.85258538001764
                        ],
                        [
                            32.0133301392891,
                            46.85251877834962
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 619,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0129728792195,
                            46.853762582586086
                        ],
                        [
                            32.012682136274044,
                            46.853790986618776
                        ],
                        [
                            32.0127375158827,
                            46.85401506234996
                        ],
                        [
                            32.01249753757851,
                            46.854040310261055
                        ],
                        [
                            32.01255291718718,
                            46.8542580730016
                        ],
                        [
                            32.01206834561142,
                            46.85430856858344
                        ],
                        [
                            32.01256214712195,
                            46.85613584550338
                        ],
                        [
                            32.012815970328305,
                            46.85610428684417
                        ],
                        [
                            32.01286212000218,
                            46.85626839166952
                        ],
                        [
                            32.01580185422847,
                            46.85591493449941
                        ],
                        [
                            32.015838773967566,
                            46.856025390115
                        ],
                        [
                            32.01664177829313,
                            46.855918090377294
                        ],
                        [
                            32.01647563946715,
                            46.85535634119226
                        ],
                        [
                            32.01562648546772,
                            46.85544470662208
                        ],
                        [
                            32.015594180696006,
                            46.85535318528136
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 620,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01220679463306,
                            46.85604432534058
                        ],
                        [
                            32.01307440850204,
                            46.85594964914593
                        ],
                        [
                            32.01303748876294,
                            46.85581079042519
                        ],
                        [
                            32.012857505034795,
                            46.85556778679988
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 621,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01096536840562,
                            46.85400559438023
                        ],
                        [
                            32.00982085649335,
                            46.85420442139389
                        ],
                        [
                            32.009867006167234,
                            46.85435590814829
                        ],
                        [
                            32.00975624694991,
                            46.854368532025205
                        ],
                        [
                            32.009899310938955,
                            46.85483245744325
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 622,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0101531341453,
                            46.854799320046304
                        ],
                        [
                            32.009190913444854,
                            46.85494449325292
                        ],
                        [
                            32.009257830471995,
                            46.85518749969876
                        ],
                        [
                            32.009142456287286,
                            46.855200123380186
                        ],
                        [
                            32.00939627949363,
                            46.85600803281902
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 623,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00929133830831,
                            46.85567401056284
                        ],
                        [
                            32.00902246713519,
                            46.855711379984115
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 624,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00865788471152,
                            46.855162252327005
                        ],
                        [
                            32.00910899777372,
                            46.85510307875294
                        ],
                        [
                            32.009142456287286,
                            46.85520012338018
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 625,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00929133830831,
                            46.85567401056284
                        ],
                        [
                            32.009613182960884,
                            46.85562538191441
                        ],
                        [
                            32.0096431802489,
                            46.855350818348064
                        ],
                        [
                            32.01090998879696,
                            46.85517250907322
                        ],
                        [
                            32.01121688412829,
                            46.855032070378
                        ],
                        [
                            32.0114868597205,
                            46.854995777172334
                        ],
                        [
                            32.01151685700852,
                            46.85509203301191
                        ],
                        [
                            32.01170376318775,
                            46.855068363559155
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 626,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.011046144390654,
                            46.85511020281417
                        ],
                        [
                            32.01096075343823,
                            46.8548174667186
                        ],
                        [
                            32.01082461190029,
                            46.85469596279591
                        ],
                        [
                            32.01080153706335,
                            46.8546107520887
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 627,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.010045549321056,
                            46.855294183068565
                        ],
                        [
                            32.01009313956926,
                            46.85546443102852
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 628,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.010045549321056,
                            46.855294183068565
                        ],
                        [
                            32.00969096975937,
                            46.854869048262486
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 629,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00865120762081,
                            46.856102038641026
                        ],
                        [
                            32.0097193272108,
                            46.85596858439823
                        ],
                        [
                            32.01073692751991,
                            46.85621632000042
                        ],
                        [
                            32.01170145570404,
                            46.85610586477756
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 631,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01410123874592,
                            46.85551374201296
                        ],
                        [
                            32.01423045783279,
                            46.85600921627119
                        ],
                        [
                            32.014535045680404,
                            46.8559713457886
                        ],
                        [
                            32.01455561923422,
                            46.85606477509573
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 632,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00689508276582,
                            46.85699718671337
                        ],
                        [
                            32.0070575726608,
                            46.857668390571185
                        ],
                        [
                            32.00640916974276,
                            46.85774570700121
                        ],
                        [
                            32.00675759978056,
                            46.85900957816431
                        ],
                        [
                            32.00621303362875,
                            46.859079003433656
                        ],
                        [
                            32.007260631225876,
                            46.86130372021731
                        ],
                        [
                            32.00734370063886,
                            46.86146465360068
                        ],
                        [
                            32.00761136874738,
                            46.86178336338723
                        ],
                        [
                            32.00813747502963,
                            46.86223775822714
                        ],
                        [
                            32.00890355961609,
                            46.86295720885835
                        ],
                        [
                            32.00880203033354,
                            46.86300454079871
                        ],
                        [
                            32.008772033045524,
                            46.863017951507565
                        ],
                        [
                            32.009093927020835,
                            46.86334138523567
                        ],
                        [
                            32.009215069914774,
                            46.863285376119826
                        ],
                        [
                            32.009368517580434,
                            46.86341711577828
                        ],
                        [
                            32.00992115992517,
                            46.86314732523263
                        ],
                        [
                            32.00954619382489,
                            46.86278050257897
                        ],
                        [
                            32.00909969573008,
                            46.86312918137535
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 633,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00983924425404,
                            46.86110728616915
                        ],
                        [
                            32.00857705067336,
                            46.86126033123238
                        ],
                        [
                            32.00853782345057,
                            46.86112621960268
                        ],
                        [
                            32.008120168901925,
                            46.8603168093608
                        ],
                        [
                            32.007843270858636,
                            46.859570500216385
                        ],
                        [
                            32.00802556207047,
                            46.85955314406634
                        ],
                        [
                            32.00833591862733,
                            46.859523954164985
                        ],
                        [
                            32.00859204931737,
                            46.85950344233292
                        ],
                        [
                            32.00928198694191,
                            46.859432439776775
                        ],
                        [
                            32.00933044409949,
                            46.85960600141492
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 634,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.008001333491684,
                            46.859154739988774
                        ],
                        [
                            32.008115482547446,
                            46.85954468679586
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 635,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0076782857745,
                            46.85842103761596
                        ],
                        [
                            32.00662858823115,
                            46.85854161400838
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 636,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.005474638846614,
                            46.859861609351356
                        ],
                        [
                            32.00652683604487,
                            46.85974541545564
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 637,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00608150705816,
                            46.860284464253546
                        ],
                        [
                            32.006051509770145,
                            46.86005568123114
                        ],
                        [
                            32.0058346063029,
                            46.86007619285225
                        ],
                        [
                            32.005381366678435,
                            46.85937862977536
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 638,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0050523693306,
                            46.85941192790974
                        ],
                        [
                            32.005691542313876,
                            46.859347236585464
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 639,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00672200341044,
                            46.85770840431072
                        ],
                        [
                            32.006696451462645,
                            46.85761937741954
                        ],
                        [
                            32.005291193892944,
                            46.857780321846434
                        ],
                        [
                            32.00532349866466,
                            46.85788603998198
                        ],
                        [
                            32.00451472562988,
                            46.85797992382338
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 640,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.004833158379654,
                            46.85843040619062
                        ],
                        [
                            32.004789316189466,
                            46.858269463712254
                        ],
                        [
                            32.00489084547201,
                            46.85825841862251
                        ],
                        [
                            32.00480995477536,
                            46.8579456531075
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 641,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00538233949885,
                            46.858861161626955
                        ],
                        [
                            32.0066744110156,
                            46.85870782766138
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 642,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00856666699671,
                            46.8582820866692
                        ],
                        [
                            32.00921550212701,
                            46.85821131287851
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 643,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0092381447517,
                            46.85831364404849
                        ],
                        [
                            32.009037393670305,
                            46.85740636199512
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 644,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00860762483229,
                            46.85729275342322
                        ],
                        [
                            32.0086814643105,
                            46.85756257338898
                        ],
                        [
                            32.009343712130715,
                            46.857489990373594
                        ],
                        [
                            32.00941062915783,
                            46.85775349780879
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 645,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01318682622322,
                            46.86230274189712
                        ],
                        [
                            32.01278993902785,
                            46.86248260524108
                        ],
                        [
                            32.01208846398483,
                            46.86292121683415
                        ],
                        [
                            32.01069935880098,
                            46.863757408864146
                        ],
                        [
                            32.01071320370315,
                            46.86407610504006
                        ],
                        [
                            32.0101824824535,
                            46.8642717393792
                        ],
                        [
                            32.009651761203855,
                            46.86462514153949
                        ],
                        [
                            32.00902643312275,
                            46.86619846287788
                        ],
                        [
                            32.00888798410111,
                            46.86621187278887
                        ],
                        [
                            32.0088879841011,
                            46.866381074024986
                        ],
                        [
                            32.00899874331842,
                            46.86637949639355
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 646,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01090068675329,
                            46.85802883802868
                        ],
                        [
                            32.01095375887825,
                            46.85822291653304
                        ],
                        [
                            32.01154908967133,
                            46.858150334410276
                        ],
                        [
                            32.01183253468422,
                            46.858008023511886
                        ],
                        [
                            32.01268898661622,
                            46.857897874088714
                        ],
                        [
                            32.015031082565734,
                            46.85760912114071
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 647,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01505761862822,
                            46.85771089489794
                        ],
                        [
                            32.01486725122346,
                            46.85698111756499
                        ],
                        [
                            32.01497685669893,
                            46.85696770534824
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 648,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.014974441107064,
                            46.8573920333924
                        ],
                        [
                            32.01508992339994,
                            46.857377959874704
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 649,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01457265034491,
                            46.85766564057643
                        ],
                        [
                            32.014546510989994,
                            46.857560995498396
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 650,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01405979488942,
                            46.85772886970239
                        ],
                        [
                            32.01393733529474,
                            46.857292753423245
                        ],
                        [
                            32.01406193941423,
                            46.85727855233484
                        ],
                        [
                            32.013999637354495,
                            46.85705449022098
                        ],
                        [
                            32.0138773407187,
                            46.857065535558355
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 651,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01314586838767,
                            46.85792706487407
                        ],
                        [
                            32.01282282067049,
                            46.85797282337055
                        ],
                        [
                            32.012705139002094,
                            46.85723594904716
                        ],
                        [
                            32.01291281253455,
                            46.857210702638504
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 652,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.012773744071325,
                            46.85766552707166
                        ],
                        [
                            32.013028186719275,
                            46.857635156306266
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 653,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01233716141453,
                            46.857943122823634
                        ],
                        [
                            32.012372861350144,
                            46.85804067210391
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 654,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.011832910165715,
                            46.8573779598747
                        ],
                        [
                            32.012003395675386,
                            46.85798604887039
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 655,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.011518955243865,
                            46.857068098418246
                        ],
                        [
                            32.01159293186153,
                            46.85705606812646
                        ],
                        [
                            32.01156293457351,
                            46.85695508208232
                        ],
                        [
                            32.014671115109465,
                            46.85655902560742
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 656,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01240978108925,
                            46.857389005145535
                        ],
                        [
                            32.01224543102803,
                            46.856868115975026
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 657,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01664177829313,
                            46.855918090377294
                        ],
                        [
                            32.01681822868683,
                            46.85618269035394
                        ],
                        [
                            32.017076666860575,
                            46.85640044440703
                        ],
                        [
                            32.01845654210965,
                            46.85621109310658
                        ],
                        [
                            32.01879804969637,
                            46.85735981406766
                        ],
                        [
                            32.01642595645883,
                            46.857662769346845
                        ],
                        [
                            32.01635211698062,
                            46.857416618312705
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 658,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01681822868683,
                            46.85618269035394
                        ],
                        [
                            32.01662440005652,
                            46.856203203454584
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 659,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.018250230722245,
                            46.85623940391808
                        ],
                        [
                            32.01821656380546,
                            46.856117995139044
                        ],
                        [
                            32.018281173348896,
                            46.85610852754007
                        ],
                        [
                            32.018255791028245,
                            46.85600438384131
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 660,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01828809579997,
                            46.8567633658666
                        ],
                        [
                            32.018793434728984,
                            46.85669551551927
                        ],
                        [
                            32.01876343744097,
                            46.85659452879705
                        ],
                        [
                            32.01901726064732,
                            46.85656770416703
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 661,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01878281734714,
                            46.85730857789978
                        ],
                        [
                            32.020286376679074,
                            46.85713417438973
                        ],
                        [
                            32.02061865433103,
                            46.85710892793322
                        ],
                        [
                            32.021580875031475,
                            46.857033188492466
                        ],
                        [
                            32.02216005343868,
                            46.857061590795276
                        ],
                        [
                            32.02686501269097,
                            46.857304587660686
                        ],
                        [
                            32.027114220929924,
                            46.85734876879081
                        ],
                        [
                            32.02778223745936,
                            46.854762533566806
                        ],
                        [
                            32.0274591897422,
                            46.85469941461566
                        ],
                        [
                            32.025668582395575,
                            46.85485089997376
                        ],
                        [
                            32.025631662656465,
                            46.85472466220502
                        ],
                        [
                            32.02532707480885,
                            46.854756221675025
                        ],
                        [
                            32.025354764613176,
                            46.85486036779444
                        ],
                        [
                            32.025100941406826,
                            46.85488561530813
                        ],
                        [
                            32.02505479173294,
                            46.85472150625701
                        ],
                        [
                            32.0269884630686,
                            46.854500589434664
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 662,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0270253828077,
                            46.85463945154336
                        ],
                        [
                            32.026762329666575,
                            46.85362322508299
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 663,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02681787603604,
                            46.85383781332741
                        ],
                        [
                            32.02712229712286,
                            46.85380311750315
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 664,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02624911594007,
                            46.85458505799078
                        ],
                        [
                            32.02627429686527,
                            46.854672589038955
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 665,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02578628195882,
                            46.854637935545355
                        ],
                        [
                            32.0254978286022,
                            46.85360034398636
                        ],
                        [
                            32.02534438093654,
                            46.85360665601399
                        ],
                        [
                            32.02532361358329,
                            46.85353406765143
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 666,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0254978286022,
                            46.85360034398636
                        ],
                        [
                            32.02567088987925,
                            46.85357982989142
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 667,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02453560790176,
                            46.85398143132492
                        ],
                        [
                            32.02465328957016,
                            46.853967229360805
                        ],
                        [
                            32.02476866375486,
                            46.85440591051832
                        ],
                        [
                            32.02582779877046,
                            46.854282827677515
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 668,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.025087206801125,
                            46.85436889243181
                        ],
                        [
                            32.02511853596998,
                            46.85446498623675
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 669,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01220816470145,
                            46.85854706946255
                        ],
                        [
                            32.01254967228818,
                            46.85870485539491
                        ],
                        [
                            32.01305270373349,
                            46.8586417410776
                        ],
                        [
                            32.01298347922267,
                            46.858373504401406
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 670,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01254967228818,
                            46.85870485539491
                        ],
                        [
                            32.012692736277224,
                            46.85928234795325
                        ],
                        [
                            32.01221247883172,
                            46.85933723614596
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 671,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.012306232758455,
                            46.85905277173738
                        ],
                        [
                            32.01218162863898,
                            46.85906697235653
                        ],
                        [
                            32.01223700824763,
                            46.85927682551285
                        ],
                        [
                            32.01221247883172,
                            46.85933723614596
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 672,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.012692736277224,
                            46.85928234795325
                        ],
                        [
                            32.012752730853265,
                            46.8594957503939
                        ],
                        [
                            32.014457961303215,
                            46.859309565686964
                        ],
                        [
                            32.0145266089431,
                            46.859544663218294
                        ],
                        [
                            32.014888883883074,
                            46.859500483894976
                        ],
                        [
                            32.01485542536952,
                            46.85938451299832
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 673,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.013509008634,
                            46.85856876505574
                        ],
                        [
                            32.013559773275276,
                            46.85875021876466
                        ],
                        [
                            32.01363592023718,
                            46.858742329485715
                        ],
                        [
                            32.013663610041505,
                            46.85885120143272
                        ],
                        [
                            32.01375360190557,
                            46.858841734315654
                        ],
                        [
                            32.0138813968991,
                            46.8593725176462
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 674,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.013955556284216,
                            46.85936442058684
                        ],
                        [
                            32.013912818280474,
                            46.85918254947831
                        ],
                        [
                            32.015078097545995,
                            46.859053166199075
                        ],
                        [
                            32.01556036163806,
                            46.85927879781307
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 675,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.015078097545995,
                            46.859053166199075
                        ],
                        [
                            32.01489580633416,
                            46.858360486999224
                        ],
                        [
                            32.01456352868221,
                            46.8584015115288
                        ],
                        [
                            32.01449430417138,
                            46.858169564737686
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 676,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01489580633416,
                            46.858360486999224
                        ],
                        [
                            32.01521193160025,
                            46.85805911353261
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 677,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01497867030708,
                            46.85867535874658
                        ],
                        [
                            32.01535961055667,
                            46.85863030159967
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 678,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01481042943748,
                            46.85891431550382
                        ],
                        [
                            32.01503503653602,
                            46.85888954164098
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 679,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.012752730853265,
                            46.8594957503939
                        ],
                        [
                            32.012466026004255,
                            46.859649588965496
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 680,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01305128281781,
                            46.85946315319828
                        ],
                        [
                            32.01308789285981,
                            46.85960304298265
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 681,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01697254165883,
                            46.85832577391126
                        ],
                        [
                            32.01909081169002,
                            46.85806384716036
                        ],
                        [
                            32.01898697492379,
                            46.85772302489686
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 682,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.017920917457126,
                            46.858822011149776
                        ],
                        [
                            32.01994227317316,
                            46.85855693109694
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 683,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01883736068567,
                            46.85809518676279
                        ],
                        [
                            32.01900622998773,
                            46.85867968371487
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 684,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.019487698885406,
                            46.85712105806815
                        ],
                        [
                            32.01951800789501,
                            46.85722330044981
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 685,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01904696949981,
                            46.855451610165204
                        ],
                        [
                            32.01966537512983,
                            46.85536955656716
                        ],
                        [
                            32.01981766905364,
                            46.85598495549641
                        ],
                        [
                            32.020312256163805,
                            46.85592224040261
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 686,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.018917750412946,
                            46.85488038828484
                        ],
                        [
                            32.01900081982593,
                            46.855151798333715
                        ],
                        [
                            32.01927771786923,
                            46.85512023909619
                        ],
                        [
                            32.019361350034785,
                            46.855409896372336
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 687,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.020565770392544,
                            46.85711294605574
                        ],
                        [
                            32.01988920104817,
                            46.85393517630308
                        ],
                        [
                            32.020858344199695,
                            46.853808936381725
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 688,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01942770430935,
                            46.85413715955999
                        ],
                        [
                            32.0199229615106,
                            46.85409374983758
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 689,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02094141361268,
                            46.855579424173925
                        ],
                        [
                            32.02025715529493,
                            46.85566343914359
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 690,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02046460664455,
                            46.856637803320595
                        ],
                        [
                            32.02065427611051,
                            46.856616126559345
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 691,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01277681637958,
                            46.84600809444852
                        ],
                        [
                            32.012456076146094,
                            46.84606806715789
                        ],
                        [
                            32.01228993732012,
                            46.84576978117856
                        ],
                        [
                            32.01229224480381,
                            46.845682978069206
                        ],
                        [
                            32.012190715521264,
                            46.84548096301711
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 696,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02362417037213,
                            46.8535980403955
                        ],
                        [
                            32.02333126030121,
                            46.853631800650895
                        ],
                        [
                            32.02346632470881,
                            46.85421454391409
                        ],
                        [
                            32.02365373500696,
                            46.85419432650609
                        ],
                        [
                            32.02386025479758,
                            46.85502710403255
                        ],
                        [
                            32.02376564796613,
                            46.85503972775169
                        ],
                        [
                            32.02386256228128,
                            46.85542632771303
                        ],
                        [
                            32.02427329437883,
                            46.855375833182215
                        ],
                        [
                            32.02433328895488,
                            46.85565670840735
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 697,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.023785679706016,
                            46.855119636400545
                        ],
                        [
                            32.02429521547393,
                            46.85505550739642
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 698,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02380690630351,
                            46.85503422250834
                        ],
                        [
                            32.02377949286829,
                            46.85494662775332
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 699,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02384272267921,
                            46.855347185974
                        ],
                        [
                            32.022618828570145,
                            46.855481556051714
                        ],
                        [
                            32.022478072064814,
                            46.85494031588319
                        ],
                        [
                            32.021993500489046,
                            46.85499238878964
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 700,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.022618828570145,
                            46.855481556051714
                        ],
                        [
                            32.022730741529315,
                            46.855887877083184
                        ],
                        [
                            32.0224192312306,
                            46.855959673295324
                        ],
                        [
                            32.022112335899294,
                            46.855999121722675
                        ],
                        [
                            32.02226924479049,
                            46.856415695346705
                        ],
                        [
                            32.02335145464303,
                            46.85629103918441
                        ],
                        [
                            32.02340452676799,
                            46.85645198759273
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 701,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.022112335899294,
                            46.85599912172267
                        ],
                        [
                            32.022073108676494,
                            46.855901289569665
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 702,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02226924479049,
                            46.856415695346705
                        ],
                        [
                            32.022456150969724,
                            46.856701298880694
                        ],
                        [
                            32.02174083102455,
                            46.85678966209732
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 703,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.022456150969724,
                            46.856701298880694
                        ],
                        [
                            32.02266843946958,
                            46.85668078597032
                        ],
                        [
                            32.02272052174284,
                            46.85709053733981
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 704,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02263830903875,
                            46.8555522837752
                        ],
                        [
                            32.02246307342081,
                            46.85556834332637
                        ],
                        [
                            32.022437691100166,
                            46.85547208834056
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 705,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0265357821409,
                            46.85679124001057
                        ],
                        [
                            32.026425022923576,
                            46.8564141174224
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 706,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0262219643585,
                            46.85633048736832
                        ],
                        [
                            32.026247346679135,
                            46.856439364205585
                        ],
                        [
                            32.026544686795575,
                            46.85639849389742
                        ],
                        [
                            32.026302726287796,
                            46.85564221102122
                        ],
                        [
                            32.02613658746183,
                            46.85566272432833
                        ],
                        [
                            32.02605121056514,
                            46.85539605072468
                        ],
                        [
                            32.02564740091867,
                            46.85544654523647
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 707,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.026888827146095,
                            46.856560864183635
                        ],
                        [
                            32.02675730057553,
                            46.85657664338139
                        ],
                        [
                            32.026441175309444,
                            46.8553900347738
                        ],
                        [
                            32.02666038626038,
                            46.85536794340749
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 708,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.026097360239014,
                            46.85595622155658
                        ],
                        [
                            32.02606967043468,
                            46.85586312314713
                        ],
                        [
                            32.02635910934401,
                            46.855818445443205
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 709,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02620811945634,
                            46.85627654196954
                        ],
                        [
                            32.02615966229876,
                            46.856142417891235
                        ],
                        [
                            32.02662409385173,
                            46.85607664147871
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 710,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.026247346679135,
                            46.856439364205585
                        ],
                        [
                            32.02581123226095,
                            46.856500607329544
                        ],
                        [
                            32.02573047033166,
                            46.8562607626836
                        ],
                        [
                            32.02467595028345,
                            46.8564501138089
                        ],
                        [
                            32.02441520462602,
                            46.85648009434252
                        ],
                        [
                            32.024394437272775,
                            46.85640593193939
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 711,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02548918372746,
                            46.856304088509624
                        ],
                        [
                            32.025465109706836,
                            46.85623867167549
                        ],
                        [
                            32.02514898444075,
                            46.85628443161078
                        ],
                        [
                            32.02510514225056,
                            46.856164508938924
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 712,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.024981297201,
                            46.856395285349144
                        ],
                        [
                            32.025137447022274,
                            46.85707181197524
                        ],
                        [
                            32.02541896003295,
                            46.85703709805417
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 713,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02453940137709,
                            46.856465814203155
                        ],
                        [
                            32.02467825776715,
                            46.857021318991706
                        ],
                        [
                            32.02448673662054,
                            46.857033942242026
                        ],
                        [
                            32.02444800262532,
                            46.85717975656399
                        ],
                        [
                            32.02450478318318,
                            46.85784130213906
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 714,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02448079958133,
                            46.85755934259213
                        ],
                        [
                            32.02478209453338,
                            46.85753098036511
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 715,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02524128378851,
                            46.857852869266424
                        ],
                        [
                            32.02539819267971,
                            46.85783077891331
                        ],
                        [
                            32.02543049745142,
                            46.85793649694952
                        ],
                        [
                            32.02615735481507,
                            46.85784340197334
                        ],
                        [
                            32.0263004188041,
                            46.858403547275174
                        ],
                        [
                            32.02608120785317,
                            46.8584351045831
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 716,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02609981117376,
                            46.85785077209636
                        ],
                        [
                            32.02604659559775,
                            46.85765405576113
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 717,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02523897630482,
                            46.85788600477908
                        ],
                        [
                            32.025289740946086,
                            46.85805957141603
                        ],
                        [
                            32.02506822251145,
                            46.85808797317594
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 718,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.025213593984176,
                            46.85786707020293
                        ],
                        [
                            32.02512360212011,
                            46.85787811537316
                        ],
                        [
                            32.0250889898647,
                            46.85775977414527
                        ],
                        [
                            32.02441058965863,
                            46.85785444714846
                        ],
                        [
                            32.02447519920206,
                            46.85805957141604
                        ],
                        [
                            32.02360758533308,
                            46.85819526857778
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 719,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.023132243692096,
                            46.85782288949929
                        ],
                        [
                            32.023215313105084,
                            46.858040636901094
                        ],
                        [
                            32.02404600723496,
                            46.85836252274718
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 720,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.023612598991534,
                            46.85819458153497
                        ],
                        [
                            32.023126691309464,
                            46.85868997847302
                        ],
                        [
                            32.02093458180006,
                            46.858595306943016
                        ],
                        [
                            32.020879202191395,
                            46.858383873256656
                        ],
                        [
                            32.021262244484625,
                            46.85832707003486
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 721,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.022134473320996,
                            46.85839649618667
                        ],
                        [
                            32.023612598991534,
                            46.85819458153497
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 722,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.027114220929924,
                            46.85734876879081
                        ],
                        [
                            32.032320060549296,
                            46.85833418473382
                        ],
                        [
                            32.031678580082335,
                            46.859855227842914
                        ],
                        [
                            32.0292603371709,
                            46.859476548939554
                        ],
                        [
                            32.02905727860583,
                            46.86022128157747
                        ],
                        [
                            32.02934802155128,
                            46.860650444846705
                        ],
                        [
                            32.02941263109472,
                            46.86088711429973
                        ],
                        [
                            32.02946339573599,
                            46.86148351669431
                        ],
                        [
                            32.029587999855465,
                            46.86205151281548
                        ],
                        [
                            32.029740293779284,
                            46.86219035539779
                        ],
                        [
                            32.029860282931374,
                            46.86222191048006
                        ],
                        [
                            32.0306725171917,
                            46.86220297743293
                        ],
                        [
                            32.03214930675592,
                            46.862161955807906
                        ],
                        [
                            32.03511211581916,
                            46.86209726779706
                        ],
                        [
                            32.03644584139436,
                            46.862084645737085
                        ],
                        [
                            32.03784417651299,
                            46.86228344283715
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 723,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03199809162625,
                            46.85909762507531
                        ],
                        [
                            32.03223237616892,
                            46.85913731348737
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 724,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04866431097726,
                            46.872729940661834
                        ],
                        [
                            32.04865637384211,
                            46.87273536665557
                        ],
                        [
                            32.04783096594106,
                            46.872738186151295
                        ],
                        [
                            32.047849425810625,
                            46.87306629361945
                        ],
                        [
                            32.04771097678898,
                            46.873072603358786
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 727,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04783096594106,
                            46.872738186151295
                        ],
                        [
                            32.0478257720817,
                            46.87226246936733
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 728,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.047138720832834,
                            46.872268105609876
                        ],
                        [
                            32.048292462679896,
                            46.87225864085871
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 729,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0478257720817,
                            46.87226246936732
                        ],
                        [
                            32.047824620360906,
                            46.87211312009932
                        ],
                        [
                            32.04708161061141,
                            46.87211942995072
                        ],
                        [
                            32.04707238067663,
                            46.872010584909944
                        ],
                        [
                            32.04622784164459,
                            46.8720657961901
                        ],
                        [
                            32.04631783350866,
                            46.87273936923472
                        ],
                        [
                            32.04636167569885,
                            46.873158967841505
                        ],
                        [
                            32.04620015184026,
                            46.873158967841505
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 730,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04656934923131,
                            46.87195064117003
                        ],
                        [
                            32.04672698938358,
                            46.87203316468485
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 731,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0461863069381,
                            46.87265891950299
                        ],
                        [
                            32.04630667960816,
                            46.872655884738734
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 732,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0461078524925,
                            46.872135204576026
                        ],
                        [
                            32.046236372883094,
                            46.87212965135074
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 733,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04769309379034,
                            46.87186703531571
                        ],
                        [
                            32.047824620360906,
                            46.87211312009932
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 734,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04622784164459,
                            46.8720657961901
                        ],
                        [
                            32.04617938448701,
                            46.87174714744826
                        ],
                        [
                            32.0459071014111,
                            46.871759767236384
                        ],
                        [
                            32.04587248915569,
                            46.87151368083258
                        ],
                        [
                            32.04580095716118,
                            46.8710467445544
                        ],
                        [
                            32.04578711225901,
                            46.87093316484546
                        ],
                        [
                            32.04576865238946,
                            46.8701917358398
                        ],
                        [
                            32.04574096258513,
                            46.869977192770065
                        ],
                        [
                            32.04563943330258,
                            46.869302007513255
                        ],
                        [
                            32.045611743498256,
                            46.86896125658872
                        ],
                        [
                            32.045574823759154,
                            46.868576331645976
                        ],
                        [
                            32.04554713395482,
                            46.86794530117771
                        ],
                        [
                            32.043996504912386,
                            46.86801471489237
                        ],
                        [
                            32.04393189536895,
                            46.86747202345808
                        ],
                        [
                            32.043862670858125,
                            46.866730546640696
                        ],
                        [
                            32.043844210988574,
                            46.866509679225885
                        ],
                        [
                            32.04256586502205,
                            46.866566473790776
                        ],
                        [
                            32.04250587044599,
                            46.86561988986407
                        ],
                        [
                            32.042505870446,
                            46.86555993898667
                        ],
                        [
                            32.04104292578393,
                            46.86567037476155
                        ],
                        [
                            32.03885543124191,
                            46.86586600329079
                        ],
                        [
                            32.03874467202459,
                            46.86555678367549
                        ],
                        [
                            32.03784417651299,
                            46.86228344283715
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 735,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.046393980470555,
                            46.87163041426736
                        ],
                        [
                            32.04617938448701,
                            46.87174714744826
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 736,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.048279194648636,
                            46.8696112055542
                        ],
                        [
                            32.047909997257584,
                            46.86786957702299
                        ],
                        [
                            32.04555296848348,
                            46.868078266452166
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 737,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04805434196889,
                            46.86855050640556
                        ],
                        [
                            32.048860680539555,
                            46.868494298104615
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 738,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.043961804944686,
                            46.86772325127626
                        ],
                        [
                            32.04455953093373,
                            46.86769288691322
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 739,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04389479877131,
                            46.867074676064
                        ],
                        [
                            32.043281184967235,
                            46.867061846058945
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 740,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.009031204495635,
                            46.86637715834079
                        ],
                        [
                            32.00923657054442,
                            46.86637558070924
                        ],
                        [
                            32.009241185511804,
                            46.86666428651167
                        ],
                        [
                            32.00898043985437,
                            46.86666428651167
                        ],
                        [
                            32.00898043985437,
                            46.86666428651167
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 744,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.008707003036626,
                            46.867768611317175
                        ],
                        [
                            32.010347710377225,
                            46.86770120958983
                        ],
                        [
                            32.010360545201216,
                            46.867692434608976
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 745,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00945087809252,
                            46.866742576000576
                        ],
                        [
                            32.009446263125135,
                            46.86655957167672
                        ],
                        [
                            32.00980392309772,
                            46.866299262727395
                        ],
                        [
                            32.00980161561402,
                            46.866056306569114
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 746,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.009237973667155,
                            46.866463358238185
                        ],
                        [
                            32.010394638923415,
                            46.86645702587771
                        ],
                        [
                            32.01038091320447,
                            46.86615403783669
                        ],
                        [
                            32.01178521066454,
                            46.866148015634536
                        ],
                        [
                            32.011810880312524,
                            46.86613046516357
                        ],
                        [
                            32.01178521066454,
                            46.866148015634536
                        ],
                        [
                            32.01182371513652,
                            46.866156790867855
                        ],
                        [
                            32.011810880312524,
                            46.86613924039977
                        ],
                        [
                            32.01182230248896,
                            46.86613677026143
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 747,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.010849213211145,
                            46.86568555966285
                        ],
                        [
                            32.01087228804808,
                            46.86596638095899
                        ],
                        [
                            32.01099227720018,
                            46.8662219586145
                        ],
                        [
                            32.01100612210235,
                            46.86644913773117
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 748,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01054048490316,
                            46.866152126197704
                        ],
                        [
                            32.01050770562441,
                            46.865824392846804
                        ],
                        [
                            32.01036584525352,
                            46.865827164024935
                        ],
                        [
                            32.010341028458875,
                            46.86556869511927
                        ],
                        [
                            32.010927667656745,
                            46.865158621356144
                        ],
                        [
                            32.01032772189628,
                            46.86518701936083
                        ],
                        [
                            32.01029541712457,
                            46.86491881538422
                        ],
                        [
                            32.01028618718979,
                            46.864862019076085
                        ],
                        [
                            32.01067845941779,
                            46.86483362089946
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 749,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.010242299336554,
                            46.8664578598867
                        ],
                        [
                            32.010244652483316,
                            46.86695555438505
                        ],
                        [
                            32.01033002938,
                            46.866957131999555
                        ],
                        [
                            32.0103254144126,
                            46.86719692886588
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 750,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01095535746109,
                            46.8675992172553
                        ],
                        [
                            32.01094382004261,
                            46.867023391767106
                        ],
                        [
                            32.01174913185186,
                            46.866794637461496
                        ],
                        [
                            32.01174682436816,
                            46.86696817529983
                        ],
                        [
                            32.01189681080828,
                            46.866966597685646
                        ],
                        [
                            32.01189911829197,
                            46.86722374818564
                        ],
                        [
                            32.012159863949414,
                            46.86722217057896
                        ],
                        [
                            32.01218063130266,
                            46.8675992172553
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 751,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01174913185186,
                            46.86679463746149
                        ],
                        [
                            32.011735286949694,
                            46.86636710093885
                        ],
                        [
                            32.01245291437857,
                            46.86635605751493
                        ],
                        [
                            32.01245522186226,
                            46.8664175851336
                        ],
                        [
                            32.01276673216096,
                            46.86641127461185
                        ],
                        [
                            32.01276903964465,
                            46.866567459807015
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 752,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01245291437857,
                            46.86635605751493
                        ],
                        [
                            32.01245291437858,
                            46.866231424430225
                        ],
                        [
                            32.012582133465436,
                            46.86622984679439
                        ],
                        [
                            32.01257982598175,
                            46.866127300365456
                        ],
                        [
                            32.0125728648251,
                            46.86589940184017
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 753,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0120814095038,
                            46.865903275177715
                        ],
                        [
                            32.01288210634568,
                            46.8658969645955
                        ],
                        [
                            32.01286176610081,
                            46.86475520472582
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 754,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.013047091429804,
                            46.86790369234552
                        ],
                        [
                            32.01331475953831,
                            46.86790053717209
                        ],
                        [
                            32.01331475953831,
                            46.867581863700714
                        ],
                        [
                            32.0142608278529,
                            46.867578708508375
                        ],
                        [
                            32.01426544282029,
                            46.86785951990068
                        ],
                        [
                            32.01398854477699,
                            46.86785951990068
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 755,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.013817959106106,
                            46.86758018550119
                        ],
                        [
                            32.01381317601625,
                            46.867146445404096
                        ],
                        [
                            32.013637807255485,
                            46.867119626045714
                        ],
                        [
                            32.01362165486964,
                            46.86674415362136
                        ],
                        [
                            32.01399221370481,
                            46.866737047739655
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 756,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.013632725606,
                            46.86700150017289
                        ],
                        [
                            32.01385932569013,
                            46.866996572347226
                        ],
                        [
                            32.014101611478004,
                            46.86707860817756
                        ],
                        [
                            32.01443850409735,
                            46.86715433344813
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 757,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01505229475997,
                            46.867465121460484
                        ],
                        [
                            32.015036142374115,
                            46.86717957518125
                        ],
                        [
                            32.014376202037596,
                            46.86699026189355
                        ],
                        [
                            32.0143738945539,
                            46.86693662300731
                        ],
                        [
                            32.01400008219546,
                            46.866853009342464
                        ],
                        [
                            32.0139793148422,
                            46.8665469506659
                        ],
                        [
                            32.014117763863865,
                            46.86654221778606
                        ],
                        [
                            32.01410622644539,
                            46.866297685093556
                        ],
                        [
                            32.01439927687453,
                            46.866173051873346
                        ],
                        [
                            32.015463026857525,
                            46.86614938730517
                        ],
                        [
                            32.01547917924337,
                            46.86639549830423
                        ],
                        [
                            32.016734450372965,
                            46.86638761014869
                        ],
                        [
                            32.016743680307755,
                            46.86617305187334
                        ],
                        [
                            32.017292861426945,
                            46.86617778478571
                        ],
                        [
                            32.01735977845407,
                            46.867119626045714
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 758,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.015885262068586,
                            46.86639294646961
                        ],
                        [
                            32.01592683108002,
                            46.86736099978875
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 759,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01458849053744,
                            46.86555776971027
                        ],
                        [
                            32.01462622113777,
                            46.86616800318977
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 760,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01516305397728,
                            46.866775706027
                        ],
                        [
                            32.014484653771206,
                            46.86678832698407
                        ],
                        [
                            32.01446619390166,
                            46.866330815394555
                        ],
                        [
                            32.015103059401234,
                            46.86632134959636
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 761,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01382009846731,
                            46.866419162763904
                        ],
                        [
                            32.014469501430206,
                            46.866412789859915
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 763,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01207448705271,
                            46.86460209631577
                        ],
                        [
                            32.01255444366108,
                            46.8645894748447
                        ],
                        [
                            32.01254982869369,
                            46.864409618559336
                        ],
                        [
                            32.01308977987812,
                            46.864396997043
                        ],
                        [
                            32.01309007737371,
                            46.864613947249744
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 764,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01191296319413,
                            46.86414141069872
                        ],
                        [
                            32.01205602718316,
                            46.8641350999094
                        ],
                        [
                            32.012120636726586,
                            46.86398364074301
                        ],
                        [
                            32.01262366817191,
                            46.86396470831716
                        ],
                        [
                            32.01283595667176,
                            46.8639615529122
                        ],
                        [
                            32.012840571639146,
                            46.8640877689658
                        ],
                        [
                            32.01311746968244,
                            46.864081458170176
                        ],
                        [
                            32.01309439484551,
                            46.86346615203369
                        ],
                        [
                            32.013552711521015,
                            46.86348233592274
                        ],
                        [
                            32.014295721270514,
                            46.863527300827236
                        ],
                        [
                            32.014683378531124,
                            46.86353045625773
                        ],
                        [
                            32.01519333242752,
                            46.863532033972895
                        ],
                        [
                            32.01558098968813,
                            46.86353361168801
                        ],
                        [
                            32.01595480204658,
                            46.863532033972895
                        ],
                        [
                            32.01691240777963,
                            46.86354623340734
                        ],
                        [
                            32.01696778738829,
                            46.86354623340734
                        ],
                        [
                            32.01697240235568,
                            46.86367560585934
                        ],
                        [
                            32.017272375235905,
                            46.86367402814839
                        ],
                        [
                            32.017270067752214,
                            46.86354781112205
                        ],
                        [
                            32.01828766806132,
                            46.86354938883671
                        ],
                        [
                            32.01917604928356,
                            46.863432637826584
                        ],
                        [
                            32.01918527921835,
                            46.863970636992235
                        ],
                        [
                            32.01915528193031,
                            46.86521345755935
                        ],
                        [
                            32.019141437028146,
                            46.86555107696441
                        ],
                        [
                            32.01921066153897,
                            46.865929713023476
                        ],
                        [
                            32.01925219624547,
                            46.866134806440364
                        ],
                        [
                            32.01925219624547,
                            46.86629257007398
                        ],
                        [
                            32.019144898253685,
                            46.86696306034413
                        ],
                        [
                            32.019080288710256,
                            46.86737008329353
                        ],
                        [
                            32.019066443808086,
                            46.86756886082154
                        ],
                        [
                            32.019084903677644,
                            46.867688758022226
                        ],
                        [
                            32.01885877027562,
                            46.867707689134676
                        ],
                        [
                            32.01887261517779,
                            46.86837973930158
                        ],
                        [
                            32.01872955118875,
                            46.86854380660908
                        ],
                        [
                            32.01872493622136,
                            46.86878044126547
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 765,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01792077815397,
                            46.86743358175052
                        ],
                        [
                            32.01790462576812,
                            46.86705811152226
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 766,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.017523890958586,
                            46.865122347285954
                        ],
                        [
                            32.017916163186584,
                            46.8659395733018
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 767,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01606094629653,
                            46.86616990888893
                        ],
                        [
                            32.01600095172048,
                            46.8651034152617
                        ],
                        [
                            32.01566405910114,
                            46.86510972593719
                        ],
                        [
                            32.01566867406853,
                            46.86343737098056
                        ],
                        [
                            32.01586250269884,
                            46.8634342155446
                        ],
                        [
                            32.015830197927116,
                            46.86253175325063
                        ],
                        [
                            32.01607479119869,
                            46.86252544227208
                        ],
                        [
                            32.01607940616608,
                            46.862314024062044
                        ],
                        [
                            32.01623631505728,
                            46.862314024062044
                        ],
                        [
                            32.01624029732387,
                            46.86183751615137
                        ],
                        [
                            32.01602287281557,
                            46.86183754116296
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 768,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0149718139929,
                            46.86230455755556
                        ],
                        [
                            32.016115172163325,
                            46.86221068127585
                        ],
                        [
                            32.016114018421476,
                            46.862314024062044
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 769,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.013944983749035,
                            46.862071838746104
                        ],
                        [
                            32.01394267626534,
                            46.8619535067612
                        ],
                        [
                            32.015934034693345,
                            46.86175786397409
                        ],
                        [
                            32.01595671391331,
                            46.8619096664042
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 770,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01443993900142,
                            46.86202213934421
                        ],
                        [
                            32.01442331290059,
                            46.86190628625101
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 771,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.015152951462895,
                            46.86197165101582
                        ],
                        [
                            32.01513199910297,
                            46.86183666076265
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 772,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01469606969145,
                            46.86176654169347
                        ],
                        [
                            32.0146822247893,
                            46.861687653283916
                        ],
                        [
                            32.01502142489233,
                            46.86165609788763
                        ],
                        [
                            32.01504932371784,
                            46.86184478328197
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 773,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01602287281556,
                            46.86183754116296
                        ],
                        [
                            32.01602748778296,
                            46.861903807249774
                        ],
                        [
                            32.01551291891917,
                            46.86194640683383
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 774,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01602287281556,
                            46.86183754116296
                        ],
                        [
                            32.01598595307646,
                            46.86155038717497
                        ],
                        [
                            32.01735198342337,
                            46.86142258737875
                        ],
                        [
                            32.0173012187821,
                            46.86086720695299
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 775,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01682984819113,
                            46.861471436092465
                        ],
                        [
                            32.01686279688022,
                            46.86165136457659
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 776,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01660117082752,
                            46.861492830143405
                        ],
                        [
                            32.01650975187501,
                            46.860982385752095
                        ],
                        [
                            32.01673588527704,
                            46.86096029668649
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 777,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01655827891459,
                            46.861253340622405
                        ],
                        [
                            32.016752037662904,
                            46.861234831570805
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 778,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01599821467705,
                            46.861645755503204
                        ],
                        [
                            32.015939803402574,
                            46.861649786806154
                        ],
                        [
                            32.01588211631022,
                            46.86127900946704
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 779,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.013759231311646,
                            46.862657972665794
                        ],
                        [
                            32.01535139506058,
                            46.86260748493534
                        ],
                        [
                            32.01583841720123,
                            46.86276136736218
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 780,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01394383000717,
                            46.86239291155124
                        ],
                        [
                            32.014240934226365,
                            46.86264269780543
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 781,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01886052537279,
                            46.86779288437729
                        ],
                        [
                            32.01790347202627,
                            46.867934861963505
                        ],
                        [
                            32.01790577950996,
                            46.868002698107915
                        ],
                        [
                            32.0177304107492,
                            46.86817149930448
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 782,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.019084903677644,
                            46.867688758022226
                        ],
                        [
                            32.019029524068976,
                            46.86883092319047
                        ],
                        [
                            32.019089518645025,
                            46.86910857292936
                        ],
                        [
                            32.019181817992795,
                            46.86962916231866
                        ],
                        [
                            32.019047983938535,
                            46.87024755285007
                        ],
                        [
                            32.01975753517447,
                            46.87058592934682
                        ],
                        [
                            32.01979906988096,
                            46.87080046998326
                        ],
                        [
                            32.019707924275046,
                            46.870869091255834
                        ],
                        [
                            32.019338726883994,
                            46.87127292961106
                        ],
                        [
                            32.01950486570997,
                            46.87138650860098
                        ],
                        [
                            32.019864833166245,
                            46.87201749861251
                        ],
                        [
                            32.02010019650304,
                            46.872003301418836
                        ],
                        [
                            32.02018788088342,
                            46.87219575194689
                        ],
                        [
                            32.02028133397303,
                            46.87240003269497
                        ],
                        [
                            32.02178350585789,
                            46.87227383618563
                        ],
                        [
                            32.02182885702077,
                            46.87265940269339
                        ],
                        [
                            32.021844731291075,
                            46.87265940269339
                        ],
                        [
                            32.02182885702077,
                            46.872664828694255
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 785,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02182885702077,
                            46.872664828694255
                        ],
                        [
                            32.021844731291075,
                            46.87265397669197
                        ],
                        [
                            32.02182885702077,
                            46.872664828694255
                        ],
                        [
                            32.02182601645934,
                            46.87265453912499
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 787,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.019464484745306,
                            46.87171699054473
                        ],
                        [
                            32.01960754873435,
                            46.871680708616935
                        ],
                        [
                            32.0195844738974,
                            46.87163969423424
                        ],
                        [
                            32.01964228276305,
                            46.87162738899594
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 788,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01818729252063,
                            46.86961575326137
                        ],
                        [
                            32.01851264772149,
                            46.86956527207452
                        ],
                        [
                            32.01916043043804,
                            46.86950853207749
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 789,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01879712008884,
                            46.86954035483594
                        ],
                        [
                            32.01877800834631,
                            46.86945011168927
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 790,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01874801105829,
                            46.87007323685251
                        ],
                        [
                            32.01908908326963,
                            46.870057650990695
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 791,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01382160056574,
                            46.85325436483698
                        ],
                        [
                            32.01455812051736,
                            46.855736627097684
                        ],
                        [
                            32.01473443638164,
                            46.85604327510889
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 792,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.023755244212545,
                            46.868524435066604
                        ],
                        [
                            32.023769089114715,
                            46.868592270465925
                        ],
                        [
                            32.024087521864494,
                            46.86855993034439
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 793,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0190483832728,
                            46.868918202118856
                        ],
                        [
                            32.019512935440936,
                            46.868893584808106
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 794,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.016017329856595,
                            46.86539456331347
                        ],
                        [
                            32.016370142649535,
                            46.865386559758754
                        ],
                        [
                            32.01638860251909,
                            46.86569104739972
                        ],
                        [
                            32.017794760393826,
                            46.86568665455793
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 795,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.017124636956154,
                            46.86367480517964
                        ],
                        [
                            32.0171246898175,
                            46.86381518675805
                        ],
                        [
                            32.01741081779557,
                            46.86381518675805
                        ],
                        [
                            32.01805229826254,
                            46.865014230555275
                        ],
                        [
                            32.01831996637105,
                            46.864954279001466
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 796,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.017872546127,
                            46.86467824378447
                        ],
                        [
                            32.0177107906758,
                            46.86484699710651
                        ],
                        [
                            32.01735543818692,
                            46.86485330781215
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 797,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01765073904863,
                            46.86354840132497
                        ],
                        [
                            32.017660026034534,
                            46.862770734679195
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 798,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01765760607736,
                            46.8629733763195
                        ],
                        [
                            32.018247280634675,
                            46.86297031810457
                        ],
                        [
                            32.018247280634675,
                            46.86286303224482
                        ],
                        [
                            32.01893491077551,
                            46.86286303224482
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 799,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01585346531227,
                            46.86318174970754
                        ],
                        [
                            32.016069016027444,
                            46.86318291702422
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 800,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.014862202055426,
                            46.86430821787723
                        ],
                        [
                            32.01528677905515,
                            46.86432083941442
                        ],
                        [
                            32.0154621478159,
                            46.86421986703367
                        ],
                        [
                            32.01591845946692,
                            46.864219768408084
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 801,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0159721017123,
                            46.86459693479844
                        ],
                        [
                            32.01596748674492,
                            46.86442654467206
                        ],
                        [
                            32.01591902958734,
                            46.86431137326181
                        ],
                        [
                            32.01591672210365,
                            46.8639406143042
                        ],
                        [
                            32.015979024163386,
                            46.86385384056137
                        ],
                        [
                            32.015981331647076,
                            46.86367871494412
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 802,
                id: 6,
                district: 6,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.047138720832834,
                            46.872268105609876
                        ],
                        [
                            32.04714622015484,
                            46.87242861176103
                        ],
                        [
                            32.046594731551956,
                            46.872464893183235
                        ],
                        [
                            32.046557811812846,
                            46.872308725148066
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 803,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01345422208825,
                            47.02975766379421
                        ],
                        [
                            32.01390471653618,
                            47.02974518146283
                        ],
                        [
                            32.01389006631023,
                            47.029535477859
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 804,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.999531285784585,
                            47.02071754189921
                        ],
                        [
                            32.000736266868905,
                            47.01968381993195
                        ],
                        [
                            32.00104392161383,
                            47.019548985156355
                        ],
                        [
                            32.002054787204315,
                            47.01918692712975
                        ],
                        [
                            32.00376153852741,
                            47.018585156288665
                        ],
                        [
                            32.00434754756537,
                            47.0183754088414
                        ],
                        [
                            32.00443911147755,
                            47.0183454448531
                        ],
                        [
                            32.004519687720276,
                            47.018322971850836
                        ],
                        [
                            32.00499215750714,
                            47.01815567253673
                        ],
                        [
                            32.0057796071519,
                            47.01786352173127
                        ],
                        [
                            32.00675018462103,
                            47.01750394931479
                        ],
                        [
                            32.00757059727418,
                            47.01721928610117
                        ],
                        [
                            32.00779035066342,
                            47.01714187742098
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 805,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00733253110251,
                            47.01660001351758
                        ],
                        [
                            32.007745397821466,
                            47.01715771218094
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 807,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.001974702747304,
                            47.019603895446956
                        ],
                        [
                            32.002241577585195,
                            47.01947282832708
                        ],
                        [
                            32.002241577585195,
                            47.01947282832708
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 808,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00361196940019,
                            47.01642079630459
                        ],
                        [
                            32.00361196940019,
                            47.01642453254995
                        ],
                        [
                            32.00360648933675,
                            47.01642079630459
                        ],
                        [
                            32.00361744946363,
                            47.01642453254995
                        ],
                        [
                            32.00361744946363,
                            47.01642079630459
                        ],
                        [
                            32.00365990258489,
                            47.01649638454108
                        ],
                        [
                            32.004916686440005,
                            47.01818239651938
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 809,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0040847591374,
                            47.0177623922339
                        ],
                        [
                            32.00449545772661,
                            47.01761731191937
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 810,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.003615951907044,
                            47.01709568186486
                        ],
                        [
                            32.00400940252016,
                            47.0169652541807
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 811,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.002744263463065,
                            47.01659127373217
                        ],
                        [
                            32.00315080723315,
                            47.01645393405887
                        ],
                        [
                            32.003235046032366,
                            47.01654382915768
                        ],
                        [
                            32.004403401551805,
                            47.01616177394255
                        ],
                        [
                            32.00520183886602,
                            47.01590207471943
                        ],
                        [
                            32.0053684851862,
                            47.015863369438605
                        ],
                        [
                            32.00548385571555,
                            47.016003207740134
                        ],
                        [
                            32.00569874341957,
                            47.01592730000901
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 812,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.005815317077655,
                            47.016085612282055
                        ],
                        [
                            32.00559006985369,
                            47.015779715993894
                        ],
                        [
                            32.00557541962774,
                            47.015638628543954
                        ],
                        [
                            32.005866592868486,
                            47.01501434331825
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 813,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.005155942455055,
                            47.016347183432494
                        ],
                        [
                            32.004969152074196,
                            47.01607749668887
                        ],
                        [
                            32.00388503535397,
                            47.01643208453286
                        ],
                        [
                            32.0044527316095,
                            47.017286082210866
                        ],
                        [
                            32.00462120920791,
                            47.017360993636295
                        ],
                        [
                            32.00495083929177,
                            47.01784042427039
                        ],
                        [
                            32.005998330447134,
                            47.017483348738466
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 814,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0055456384653,
                            47.01763766559001
                        ],
                        [
                            32.005737599251894,
                            47.01787910707528
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 815,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00451507278981,
                            47.01731380143756
                        ],
                        [
                            32.00476038635443,
                            47.01723863825373
                        ],
                        [
                            32.00472009823307,
                            47.01704386788271
                        ],
                        [
                            32.005485572538916,
                            47.016771687584416
                        ],
                        [
                            32.005749276606,
                            47.017123773761895
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 817,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.998054421454242,
                            47.014341485806284
                        ],
                        [
                            31.997832836786753,
                            47.014342110103016
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 818,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99808463754525,
                            47.014293414935594
                        ],
                        [
                            31.998590070340494,
                            47.01429591212476
                        ],
                        [
                            31.99859748677241,
                            47.01414245519098
                        ],
                        [
                            31.998149174930468,
                            47.014131221410466
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 819,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.998427527454375,
                            47.01413819509586
                        ],
                        [
                            31.99846851723769,
                            47.01359231697974
                        ],
                        [
                            31.998430749133302,
                            47.012987369007604
                        ],
                        [
                            31.99816866306947,
                            47.012979518993326
                        ],
                        [
                            31.99814609340089,
                            47.01344447667413
                        ],
                        [
                            31.99814609340089,
                            47.01344447667413
                        ],
                        [
                            31.997664069764856,
                            47.01357335607124
                        ],
                        [
                            31.997201391559035,
                            47.01356566178761
                        ],
                        [
                            31.997072422024313,
                            47.013518396878105
                        ],
                        [
                            31.997015997852873,
                            47.0134546441432
                        ],
                        [
                            31.996970858515716,
                            47.01340957750883
                        ],
                        [
                            31.997001488780214,
                            47.012905048444956
                        ],
                        [
                            31.997327310531496,
                            47.01291561934053
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 820,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.997873645258778,
                            47.01352059524692
                        ],
                        [
                            31.99779303929957,
                            47.0137382333113
                        ],
                        [
                            31.99763746979832,
                            47.013775880209714
                        ],
                        [
                            31.99761570618933,
                            47.014035011060614
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 821,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99824670566981,
                            47.0120733675825
                        ],
                        [
                            31.99790242536001,
                            47.01206337840947
                        ],
                        [
                            31.997891437690544,
                            47.01228064250116
                        ],
                        [
                            31.997396992564767,
                            47.01226066423078
                        ],
                        [
                            31.99742995557315,
                            47.0117711942719
                        ],
                        [
                            31.997580120389127,
                            47.01177618888598
                        ],
                        [
                            31.99759843317156,
                            47.01148587616762
                        ],
                        [
                            31.99850308462392,
                            47.01151896565392
                        ],
                        [
                            31.99849209695446,
                            47.01170626424646
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 822,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.9971259633847,
                            47.01242985497172
                        ],
                        [
                            31.997477568807483,
                            47.012439844076205
                        ],
                        [
                            31.997495481470292,
                            47.012264643718446
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 823,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.996920860221415,
                            47.01177556455924
                        ],
                        [
                            31.99695748578629,
                            47.01143842705715
                        ],
                        [
                            31.99673406984056,
                            47.0114309350885
                        ],
                        [
                            31.996741394953535,
                            47.011251127525306
                        ],
                        [
                            31.997461087303293,
                            47.01126798451006
                        ],
                        [
                            31.9974446057991,
                            47.01161323936093
                        ],
                        [
                            31.997589848460397,
                            47.011621969873836
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 824,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.996941841028498,
                            47.011066480530246
                        ],
                        [
                            31.996987622984584,
                            47.01069812159455
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 825,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.996968192422496,
                            47.01085445906869
                        ],
                        [
                            31.99678435109955,
                            47.010854206199525
                        ],
                        [
                            31.996799001325478,
                            47.01034599305046
                        ],
                        [
                            31.996996779375795,
                            47.01034724173734
                        ],
                        [
                            31.997035236218913,
                            47.01001384130309
                        ],
                        [
                            31.996797170047245,
                            47.01000759782942
                        ],
                        [
                            31.996853939672807,
                            47.0095056201576
                        ],
                        [
                            31.997073693062042,
                            47.00951311239633
                        ],
                        [
                            31.997231182990998,
                            47.007867292045674
                        ],
                        [
                            31.997099330957465,
                            47.00786229706606
                        ],
                        [
                            31.99715060674828,
                            47.00730285639306
                        ],
                        [
                            31.9973190843467,
                            47.00730785142499
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 826,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99715060674828,
                            47.00730285639305
                        ],
                        [
                            31.997048055166637,
                            47.00729786136068
                        ],
                        [
                            31.9971689195307,
                            47.00554457613273
                        ],
                        [
                            31.99742529848481,
                            47.00555706412273
                        ],
                        [
                            31.997564475631336,
                            47.00394359163662
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 827,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.997133637599543,
                            47.006056389586014
                        ],
                        [
                            31.997395998032925,
                            47.006064074051544
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 828,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.997087230678922,
                            47.00672957900828
                        ],
                        [
                            31.997194557426123,
                            47.00673591723353
                        ],
                        [
                            31.997203713817342,
                            47.006631020341715
                        ],
                        [
                            31.997339228407373,
                            47.00663726421009
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 829,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.998322624824205,
                            47.0070668406018
                        ],
                        [
                            31.997934393836545,
                            47.00706184554733
                        ],
                        [
                            31.997901430828176,
                            47.00773617367591
                        ],
                        [
                            31.998007644966297,
                            47.007796113542035
                        ],
                        [
                            31.998025957748734,
                            47.00787478451423
                        ],
                        [
                            31.99853871565695,
                            47.00788852070384
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 830,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.998220923428082,
                            47.00788000741905
                        ],
                        [
                            31.99820267609925,
                            47.008299980382326
                        ],
                        [
                            31.998166966173496,
                            47.00845357453333
                        ],
                        [
                            31.99811019654795,
                            47.00930582673685
                        ],
                        [
                            31.99807906481781,
                            47.009411967084695
                        ],
                        [
                            31.99788128676749,
                            47.00946004234931
                        ],
                        [
                            31.99788128676749,
                            47.009501249684526
                        ],
                        [
                            31.99806075203536,
                            47.00950437145106
                        ],
                        [
                            31.998048390907222,
                            47.00978657839107
                        ],
                        [
                            31.99793302037788,
                            47.00978532969108
                        ],
                        [
                            31.997936682934363,
                            47.00969542321507
                        ],
                        [
                            31.997782855561898,
                            47.009689179704175
                        ],
                        [
                            31.99778651811838,
                            47.00962674455505
                        ],
                        [
                            31.997944008047337,
                            47.009630490666055
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 831,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99801863263576,
                            47.00819071598165
                        ],
                        [
                            31.99820720493291,
                            47.00819574693032
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 832,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.997820854585445,
                            47.00864525441935
                        ],
                        [
                            31.997817192028965,
                            47.00880509119248
                        ],
                        [
                            31.997978344514408,
                            47.008810086083926
                        ],
                        [
                            31.997967356844942,
                            47.00896742492559
                        ],
                        [
                            31.998000319853325,
                            47.00896742492559
                        ],
                        [
                            31.997974681957917,
                            47.00936701355136
                        ],
                        [
                            31.99773524232756,
                            47.00945317445702
                        ],
                        [
                            31.99772059210161,
                            47.009730386862614
                        ],
                        [
                            31.997896394813004,
                            47.00973663036869
                        ],
                        [
                            31.99789273225652,
                            47.00983902376429
                        ],
                        [
                            31.997641847137135,
                            47.00985400814719
                        ],
                        [
                            31.997548451946713,
                            47.01012122893612
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 833,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.998432959338388,
                            47.01027232047276
                        ],
                        [
                            31.99844211572962,
                            47.010148700156385
                        ],
                        [
                            31.997352505174643,
                            47.01011623416728
                        ],
                        [
                            31.997263806504932,
                            47.011263363703634
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 834,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.997288197912255,
                            47.01094791493731
                        ],
                        [
                            31.99793851421264,
                            47.0109703328497
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 835,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.998249831514055,
                            47.0094119670847
                        ],
                        [
                            31.99851353558114,
                            47.009419459336584
                        ],
                        [
                            31.998476910016265,
                            47.00983902376429
                        ],
                        [
                            31.997788349396647,
                            47.009809054985894
                        ],
                        [
                            31.997612546685268,
                            47.00974412257502
                        ],
                        [
                            31.99630894558853,
                            47.009681577922926
                        ],
                        [
                            31.996294026349837,
                            47.009866495129735
                        ],
                        [
                            31.993488508080592,
                            47.009756609583285
                        ],
                        [
                            31.992891511373152,
                            47.011257526706586
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 836,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.996830590875234,
                            46.999172822922326
                        ],
                        [
                            31.996691413728712,
                            46.999255253432985
                        ],
                        [
                            31.9966657758333,
                            46.99960495721551
                        ],
                        [
                            31.99664746305086,
                            46.999772314644495
                        ],
                        [
                            31.996812278092793,
                            46.999779808248455
                        ],
                        [
                            31.99676466485846,
                            47.000294366539975
                        ],
                        [
                            31.99668042605925,
                            47.00038179099992
                        ],
                        [
                            31.996610837485985,
                            47.00103122536677
                        ],
                        [
                            31.996585199590577,
                            47.00143087336114
                        ],
                        [
                            31.99664746305086,
                            47.001505807027264
                        ],
                        [
                            31.996629150268433,
                            47.001907949240014
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 837,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99686721644011,
                            46.99944009714606
                        ],
                        [
                            31.996678069601458,
                            46.99943726911806
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 839,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.995277505498514,
                            47.002776786333136
                        ],
                        [
                            31.99551207053982,
                            47.00278590758996
                        ],
                        [
                            31.99554869610468,
                            47.00231383504994
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 840,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99438949697646,
                            47.002258884536154
                        ],
                        [
                            31.994468241940936,
                            47.00226263116389
                        ],
                        [
                            31.994416966150105,
                            47.00282712007403
                        ],
                        [
                            31.994535999235953,
                            47.002832115524484
                        ],
                        [
                            31.994521349009997,
                            47.00300071170347
                        ],
                        [
                            31.99529597970707,
                            47.00303692859102
                        ],
                        [
                            31.99528499203761,
                            47.00313059284148
                        ],
                        [
                            31.995143983612852,
                            47.00312185085173
                        ],
                        [
                            31.995127502108645,
                            47.00332416510542
                        ],
                        [
                            31.995132838993477,
                            47.00333399546488
                        ],
                        [
                            31.995127502108645,
                            47.00332416510542
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 841,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.997870704860336,
                            47.00358368736714
                        ],
                        [
                            31.997859775256078,
                            47.003576233894414
                        ],
                        [
                            31.998078367341257,
                            47.00358741410311
                        ],
                        [
                            31.997848845651824,
                            47.003576233894414
                        ],
                        [
                            31.998078367341257,
                            47.00358368736714
                        ],
                        [
                            31.998083832143383,
                            47.00358368736714
                        ],
                        [
                            31.998083832143383,
                            47.00358368736714
                        ],
                        [
                            31.99786524005821,
                            47.00357250715766
                        ],
                        [
                            31.997859775256078,
                            47.003576233894414
                        ],
                        [
                            31.99785431045395,
                            47.003576233894414
                        ],
                        [
                            31.997863431804653,
                            47.00357768126768
                        ],
                        [
                            31.997848781578703,
                            47.003163063076656
                        ],
                        [
                            31.997925695264936,
                            47.00210652145229
                        ],
                        [
                            31.997951333160344,
                            47.001759331818356
                        ],
                        [
                            31.998028246846584,
                            47.001042465507474
                        ],
                        [
                            31.998486066407487,
                            47.00105745235804
                        ],
                        [
                            31.998272722492096,
                            47.00026938809657
                        ],
                        [
                            31.99828279452244,
                            47.00013762661437
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 842,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.997874419474112,
                            47.00085263170283
                        ],
                        [
                            31.99797330849927,
                            46.999753580629985
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 843,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.997782855561926,
                            47.000562884069005
                        ],
                        [
                            31.99790025094906,
                            47.00056554327734
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 844,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.997830468796266,
                            47.00007330691025
                        ],
                        [
                            31.998255325348786,
                            47.00009079188603
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 845,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.998945717246652,
                            47.00092069719062
                        ],
                        [
                            31.999033618602343,
                            46.99979541992002
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 846,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.998969829236188,
                            47.000612027702886
                        ],
                        [
                            31.99909588206263,
                            47.00061721187005
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 847,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.998714976187944,
                            47.00009766098209
                        ],
                        [
                            31.999134338905744,
                            47.000113897023816
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 848,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.997917084000548,
                            47.00037846159878
                        ],
                        [
                            31.998985159782,
                            47.000415772886555
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 849,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.998301479882866,
                            47.000375614779834
                        ],
                        [
                            31.998301200995503,
                            47.00037572044509
                        ],
                        [
                            31.998300923992375,
                            47.000375830955626
                        ],
                        [
                            31.998300648957663,
                            47.000375946277856
                        ],
                        [
                            31.99830037597494,
                            47.00037606637674
                        ],
                        [
                            31.998300105127154,
                            47.00037619121578
                        ],
                        [
                            31.998299836496606,
                            47.00037632075704
                        ],
                        [
                            31.998299570164928,
                            47.00037645496117
                        ],
                        [
                            31.99829930621305,
                            47.00037659378737
                        ],
                        [
                            31.998299044721175,
                            47.00037673719347
                        ],
                        [
                            31.998298785768768,
                            47.000376885135886
                        ],
                        [
                            31.998298529434507,
                            47.00037703756966
                        ],
                        [
                            31.998298275796294,
                            47.00037719444849
                        ],
                        [
                            31.998298024931195,
                            47.00037735572469
                        ],
                        [
                            31.998297776915443,
                            47.00037752134925
                        ],
                        [
                            31.9982975318244,
                            47.00037769127185
                        ],
                        [
                            31.99829728973254,
                            47.00037786544085
                        ],
                        [
                            31.998297050713436,
                            47.00037804380334
                        ],
                        [
                            31.99829681483971,
                            47.0003782263051
                        ],
                        [
                            31.998296582183038,
                            47.00037841289069
                        ],
                        [
                            31.998296352814116,
                            47.00037860350341
                        ],
                        [
                            31.998296126802643,
                            47.00037879808533
                        ],
                        [
                            31.9982959042173,
                            47.00037899657733
                        ],
                        [
                            31.998295685125722,
                            47.00037919891909
                        ],
                        [
                            31.99829546959448,
                            47.00037940504913
                        ],
                        [
                            31.998295257689072,
                            47.000379614904816
                        ],
                        [
                            31.99829504947389,
                            47.00037982842237
                        ],
                        [
                            31.998294845012197,
                            47.000380045536915
                        ],
                        [
                            31.99829464436613,
                            47.00038026618248
                        ],
                        [
                            31.998294447596653,
                            47.00038049029201
                        ],
                        [
                            31.998294254763564,
                            47.00038071779742
                        ],
                        [
                            31.998294065925453,
                            47.000380948629555
                        ],
                        [
                            31.99829388113971,
                            47.0003811827183
                        ],
                        [
                            31.998293700462476,
                            47.0003814199925
                        ],
                        [
                            31.998293523948657,
                            47.000381660380064
                        ],
                        [
                            31.998293351651892,
                            47.00038190380795
                        ],
                        [
                            31.998293183624536,
                            47.00038215020218
                        ],
                        [
                            31.998293019917647,
                            47.00038239948788
                        ],
                        [
                            31.998292860580968,
                            47.00038265158931
                        ],
                        [
                            31.998292705662916,
                            47.00038290642987
                        ],
                        [
                            31.99829255521057,
                            47.0003831639321
                        ],
                        [
                            31.998292409269645,
                            47.00038342401777
                        ],
                        [
                            31.998292267884487,
                            47.00038368660785
                        ],
                        [
                            31.99829213109806,
                            47.00038395162253
                        ],
                        [
                            31.99829199895193,
                            47.000384218981296
                        ],
                        [
                            31.998291871486252,
                            47.0003844886029
                        ],
                        [
                            31.998291748739756,
                            47.00038476040542
                        ],
                        [
                            31.99829163074974,
                            47.00038503430625
                        ],
                        [
                            31.998291517552065,
                            47.000385310222164
                        ],
                        [
                            31.998291409181117,
                            47.000385588069335
                        ],
                        [
                            31.998291305669838,
                            47.00038586776331
                        ],
                        [
                            31.998291207049675,
                            47.000386149219125
                        ],
                        [
                            31.998291113350597,
                            47.000386432351235
                        ],
                        [
                            31.998291024601073,
                            47.00038671707361
                        ],
                        [
                            31.998290940828078,
                            47.000387003299736
                        ],
                        [
                            31.99829086205706,
                            47.00038729094263
                        ],
                        [
                            31.998290788311962,
                            47.0003875799149
                        ],
                        [
                            31.99829071961519,
                            47.00038787012872
                        ],
                        [
                            31.99829065598762,
                            47.00038816149591
                        ],
                        [
                            31.998290597448584,
                            47.00038845392794
                        ],
                        [
                            31.99829054401587,
                            47.00038874733594
                        ],
                        [
                            31.998290495705717,
                            47.000389041630754
                        ],
                        [
                            31.998290452532803,
                            47.00038933672296
                        ],
                        [
                            31.99829041451025,
                            47.00038963252288
                        ],
                        [
                            31.998290381649607,
                            47.00038992894064
                        ],
                        [
                            31.998290353960865,
                            47.000390225886164
                        ],
                        [
                            31.99829033145243,
                            47.00039052326922
                        ],
                        [
                            31.99829031413115,
                            47.000390820999435
                        ],
                        [
                            31.998290302002285,
                            47.00039111898634
                        ],
                        [
                            31.99829029506952,
                            47.000391417139404
                        ],
                        [
                            31.99829029333496,
                            47.00039171536801
                        ],
                        [
                            31.998290296799134,
                            47.00039201358154
                        ],
                        [
                            31.99829030546099,
                            47.000392311689374
                        ],
                        [
                            31.998290319317896,
                            47.00039260960093
                        ],
                        [
                            31.99829033836564,
                            47.00039290722568
                        ],
                        [
                            31.998290362598432,
                            47.00039320447319
                        ],
                        [
                            31.998290392008915,
                            47.00039350125313
                        ],
                        [
                            31.998290426588145,
                            47.00039379747532
                        ],
                        [
                            31.998290466325617,
                            47.00039409304975
                        ],
                        [
                            31.99829051120926,
                            47.0003943878866
                        ],
                        [
                            31.998290561225428,
                            47.000394681896275
                        ],
                        [
                            31.99829061635893,
                            47.00039497498945
                        ],
                        [
                            31.998290676593008,
                            47.000395267077046
                        ],
                        [
                            31.998290741909358,
                            47.000395558070316
                        ],
                        [
                            31.998290812288136,
                            47.00039584788084
                        ],
                        [
                            31.998290887707952,
                            47.00039613642054
                        ],
                        [
                            31.998290968145895,
                            47.00039642360175
                        ],
                        [
                            31.998291053577518,
                            47.000396709337195
                        ],
                        [
                            31.998291143976857,
                            47.000396993540065
                        ],
                        [
                            31.998291239316448,
                            47.000397276123984
                        ],
                        [
                            31.99829133956732,
                            47.00039755700309
                        ],
                        [
                            31.99829144469901,
                            47.00039783609203
                        ],
                        [
                            31.99829155467957,
                            47.000398113306
                        ],
                        [
                            31.99829166947558,
                            47.00039838856077
                        ],
                        [
                            31.99829178905216,
                            47.00039866177268
                        ],
                        [
                            31.998291913372974,
                            47.00039893285873
                        ],
                        [
                            31.99829204240024,
                            47.000399201736535
                        ],
                        [
                            31.998292176094754,
                            47.00039946832439
                        ],
                        [
                            31.998292314415895,
                            47.0003997325413
                        ],
                        [
                            31.99829245732162,
                            47.00039999430695
                        ],
                        [
                            31.99829260476852,
                            47.000400253541834
                        ],
                        [
                            31.998292756711777,
                            47.000400510167154
                        ],
                        [
                            31.998292913105228,
                            47.00040076410494
                        ],
                        [
                            31.998293073901348,
                            47.00040101527802
                        ],
                        [
                            31.998293239051275,
                            47.00040126361008
                        ],
                        [
                            31.998293408504825,
                            47.00040150902565
                        ],
                        [
                            31.998293582210508,
                            47.00040175145017
                        ],
                        [
                            31.998293760115537,
                            47.000401990809955
                        ],
                        [
                            31.99829394216586,
                            47.00040222703229
                        ],
                        [
                            31.998294128306146,
                            47.000402460045386
                        ],
                        [
                            31.998294318479843,
                            47.00040268977843
                        ],
                        [
                            31.99829451262916,
                            47.00040291616163
                        ],
                        [
                            31.9982947106951,
                            47.000403139126185
                        ],
                        [
                            31.998294912617478,
                            47.000403358604345
                        ],
                        [
                            31.998295118334934,
                            47.00040357452942
                        ],
                        [
                            31.99829532778496,
                            47.00040378683579
                        ],
                        [
                            31.99829554090391,
                            47.00040399545895
                        ],
                        [
                            31.998295757627023,
                            47.0004042003355
                        ],
                        [
                            31.998295977888446,
                            47.000404401403195
                        ],
                        [
                            31.998296201621244,
                            47.000404598600916
                        ],
                        [
                            31.998296428757435,
                            47.00040479186876
                        ],
                        [
                            31.998296659228,
                            47.000404981147994
                        ],
                        [
                            31.998296892962905,
                            47.0004051663811
                        ],
                        [
                            31.998297129891125,
                            47.000405347511794
                        ],
                        [
                            31.998297369940666,
                            47.00040552448503
                        ],
                        [
                            31.998297613038584,
                            47.00040569724705
                        ],
                        [
                            31.99829785911101,
                            47.00040586574533
                        ],
                        [
                            31.99829810808317,
                            47.000406029928676
                        ],
                        [
                            31.998298359879406,
                            47.00040618974721
                        ],
                        [
                            31.99829861442321,
                            47.00040634515236
                        ],
                        [
                            31.998298871637232,
                            47.000406496096915
                        ],
                        [
                            31.99829913144331,
                            47.00040664253499
                        ],
                        [
                            31.998299393762505,
                            47.0004067844221
                        ],
                        [
                            31.998299658515098,
                            47.00040692171511
                        ],
                        [
                            31.998299925620643,
                            47.00040705437233
                        ],
                        [
                            31.998300194997974,
                            47.00040718235343
                        ],
                        [
                            31.998300466565237,
                            47.000407305619525
                        ],
                        [
                            31.998300740239912,
                            47.000407424133165
                        ],
                        [
                            31.998301015938836,
                            47.00040753785833
                        ],
                        [
                            31.998301293578233,
                            47.00040764676046
                        ],
                        [
                            31.99830157307374,
                            47.000407750806474
                        ],
                        [
                            31.99830185434042,
                            47.000407849964745
                        ],
                        [
                            31.99830213729281,
                            47.00040794420514
                        ],
                        [
                            31.998302421844933,
                            47.00040803349904
                        ],
                        [
                            31.998302707910316,
                            47.00040811781929
                        ],
                        [
                            31.99830299540204,
                            47.00040819714029
                        ],
                        [
                            31.998303284232737,
                            47.00040827143791
                        ],
                        [
                            31.998303574314644,
                            47.000408340689596
                        ],
                        [
                            31.998303865559617,
                            47.00040840487429
                        ],
                        [
                            31.99830415787915,
                            47.000408463972505
                        ],
                        [
                            31.998304451184424,
                            47.000408517966264
                        ],
                        [
                            31.998304745386307,
                            47.00040856683917
                        ],
                        [
                            31.998305040395405,
                            47.00040861057637
                        ],
                        [
                            31.998305336122066,
                            47.00040864916458
                        ],
                        [
                            31.998305632476438,
                            47.00040868259207
                        ],
                        [
                            31.99830592936846,
                            47.00040871084867
                        ],
                        [
                            31.99830622670792,
                            47.00040873392581
                        ],
                        [
                            31.998306524404466,
                            47.00040875181647
                        ],
                        [
                            31.99830682236764,
                            47.00040876451522
                        ],
                        [
                            31.99830712050689,
                            47.0004087720182
                        ],
                        [
                            31.998307418731635,
                            47.00040877432312
                        ],
                        [
                            31.998307716951242,
                            47.000408771429285
                        ],
                        [
                            31.9983080150751,
                            47.00040876333758
                        ],
                        [
                            31.99830831301261,
                            47.00040875005046
                        ],
                        [
                            31.99830861067325,
                            47.000408731571966
                        ],
                        [
                            31.998308907966557,
                            47.0004087079077
                        ],
                        [
                            31.998309204802204,
                            47.00040867906487
                        ],
                        [
                            31.998309501089985,
                            47.00040864505223
                        ],
                        [
                            31.99830979673987,
                            47.00040860588012
                        ],
                        [
                            31.998310091662024,
                            47.00040856156044
                        ],
                        [
                            31.99831038576682,
                            47.00040851210666
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 850,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.998192822993854,
                            47.00038809404226
                        ],
                        [
                            31.99821137467095,
                            47.000189456999244
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 851,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99864172505821,
                            47.000541652499564
                        ],
                        [
                            31.998344190947844,
                            47.00053338443281
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 852,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.998018263671163,
                            47.00113551323755
                        ],
                        [
                            31.99832308264383,
                            47.001146748922345
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 853,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.997758133305663,
                            47.00125914997987
                        ],
                        [
                            31.998004067472174,
                            47.001267827982
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 854,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99770136368011,
                            47.001807413970624
                        ],
                        [
                            31.9979468508303,
                            47.00182003191378
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 855,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.997906367147586,
                            47.00237202831685
                        ],
                        [
                            31.99819947136238,
                            47.00237690318362
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 856,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.997560355255345,
                            47.003427195217974
                        ],
                        [
                            31.997585993150754,
                            47.00316743406835
                        ],
                        [
                            31.997999862033815,
                            47.00318242032285
                        ],
                        [
                            31.998014512259772,
                            47.002945137466234
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 857,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99785892042559,
                            47.00345000441086
                        ],
                        [
                            31.9982086277536,
                            47.00346216296857
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 858,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99699265899982,
                            46.9977159051075
                        ],
                        [
                            31.99678389328005,
                            46.99770841121409
                        ],
                        [
                            31.996835169070867,
                            46.99727626158219
                        ],
                        [
                            31.997029284564693,
                            46.99727626158219
                        ],
                        [
                            31.997091548024976,
                            46.99646690836069
                        ],
                        [
                            31.996923070426554,
                            46.99645972654496
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 859,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.996304098380214,
                            46.99676667024246
                        ],
                        [
                            31.99687545719223,
                            46.996795397334495
                        ],
                        [
                            31.996965189826167,
                            46.99615840166813
                        ],
                        [
                            31.996970683660887,
                            46.99581741851467
                        ],
                        [
                            31.998320335726458,
                            46.995819916567655
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 860,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.999641145159682,
                            46.995696262804394
                        ],
                        [
                            31.999593531925342,
                            46.99634575412296
                        ],
                        [
                            31.99828966181587,
                            46.99631327974453
                        ],
                        [
                            31.998361081667372,
                            46.9953939968451
                        ],
                        [
                            31.99906246123469,
                            46.99508423491462
                        ],
                        [
                            31.99934848004141,
                            46.99465273116684
                        ],
                        [
                            31.999326559787637,
                            46.99468263330494
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 861,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.999893222129174,
                            46.99461013785533
                        ],
                        [
                            32.00036202935954,
                            46.99462512651015
                        ],
                        [
                            32.00039499236794,
                            46.99440279436565
                        ],
                        [
                            32.000797032414866,
                            46.994419632394816
                        ],
                        [
                            32.0007502603472,
                            46.99502981860124
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 862,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.000795366917984,
                            46.994441360508766
                        ],
                        [
                            32.001230055247035,
                            46.99445650390102
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 863,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.000333989656106,
                            46.994003136101384
                        ],
                        [
                            32.00036239296519,
                            46.99399022064363
                        ],
                        [
                            32.00034921041184,
                            46.99399310124971
                        ],
                        [
                            32.000345547855346,
                            46.99407553975106
                        ],
                        [
                            32.00060558936593,
                            46.994192951942544
                        ],
                        [
                            32.000590427168234,
                            46.994410979463524
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 864,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00034921041184,
                            46.99399310124971
                        ],
                        [
                            32.00035653552481,
                            46.99368582935155
                        ],
                        [
                            32.000448099436994,
                            46.99368582935155
                        ],
                        [
                            32.000459080195974,
                            46.99349857598171
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 865,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99688809453135,
                            46.99340103918543
                        ],
                        [
                            31.997986861477543,
                            46.993421024509765
                        ],
                        [
                            31.99938962061217,
                            46.99346349329915
                        ],
                        [
                            31.99976686393035,
                            46.99347598411313
                        ],
                        [
                            32.000286946951555,
                            46.993493471247795
                        ],
                        [
                            32.00079237974679,
                            46.99350846021583
                        ],
                        [
                            32.00228304023712,
                            46.993560921570804
                        ],
                        [
                            32.003052177099455,
                            46.9935859031503
                        ],
                        [
                            32.003407445078714,
                            46.99359589577883
                        ],
                        [
                            32.003524646886305,
                            46.99225436868614
                        ],
                        [
                            32.004198557279956,
                            46.99227185622055
                        ],
                        [
                            32.004253495627275,
                            46.9922737298846
                        ],
                        [
                            32.00424708615342,
                            46.9924448575919
                        ],
                        [
                            32.00433565622354,
                            46.992446411073736
                        ],
                        [
                            32.00426265201855,
                            46.993314227846156
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 866,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00367938989789,
                            46.992480457082586
                        ],
                        [
                            32.00408776494621,
                            46.992489200813516
                        ],
                        [
                            32.00405388629877,
                            46.993039428432354
                        ],
                        [
                            32.00428511317806,
                            46.99304722868646
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 867,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0001880579264,
                            46.99287017627205
                        ],
                        [
                            32.00014427340716,
                            46.99348867403065
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 868,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.000448099436994,
                            46.99368582935155
                        ],
                        [
                            32.00044077432401,
                            46.993794498883304
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 869,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00225007722874,
                            46.99376327202897
                        ],
                        [
                            32.00357226012065,
                            46.99380324239923
                        ],
                        [
                            32.00356493500767,
                            46.994033071448044
                        ],
                        [
                            32.00385793952666,
                            46.99404306399298
                        ],
                        [
                            32.00384328930072,
                            46.994235420118486
                        ],
                        [
                            32.00347153981726,
                            46.99421793322659
                        ],
                        [
                            32.00338730101805,
                            46.994792499536764
                        ],
                        [
                            32.00337997590508,
                            46.99507728228766
                        ],
                        [
                            32.00336624131826,
                            46.99529399095187
                        ],
                        [
                            32.00319953928849,
                            46.9952896632732
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 870,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00356931727294,
                            46.993895575822705
                        ],
                        [
                            32.00406304268995,
                            46.993930647754915
                        ],
                        [
                            32.004136293819684,
                            46.9939331458961
                        ],
                        [
                            32.004143618932666,
                            46.99383571830305
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 871,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00450987458141,
                            46.99441528496007
                        ],
                        [
                            32.004447611121115,
                            46.99523466157784
                        ],
                        [
                            32.00361254824202,
                            46.99520968076907
                        ],
                        [
                            32.00334884417494,
                            46.99518969611366
                        ],
                        [
                            32.00149745210756,
                            46.995130811986286
                        ],
                        [
                            32.00151390337481,
                            46.99489741967418
                        ],
                        [
                            32.00076274782185,
                            46.99486690827294
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 872,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00266577739006,
                            46.99496736631803
                        ],
                        [
                            32.00265390978296,
                            46.995167593506714
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 873,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00348531906941,
                            46.99520003878307
                        ],
                        [
                            32.00351182793861,
                            46.9947300469762
                        ],
                        [
                            32.00365833019811,
                            46.994737541287314
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 874,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00320510251356,
                            46.99518512436565
                        ],
                        [
                            32.00318860732862,
                            46.995495085813786
                        ],
                        [
                            32.00358965726399,
                            46.99550757615292
                        ],
                        [
                            32.00354661016776,
                            46.99578525625715
                        ],
                        [
                            32.00348802132146,
                            46.995983455899335
                        ],
                        [
                            32.00343308297415,
                            46.996797812458055
                        ],
                        [
                            32.00300090130865,
                            46.99677782839658
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 875,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00337197497372,
                            46.995203523625584
                        ],
                        [
                            32.00337222167502,
                            46.99520352167163
                        ],
                        [
                            32.00337246830475,
                            46.99520351541645
                        ],
                        [
                            32.003372714787915,
                            46.995203504861955
                        ],
                        [
                            32.003372961049585,
                            46.99520349001135
                        ],
                        [
                            32.00337320701488,
                            46.99520347086916
                        ],
                        [
                            32.00337345260902,
                            46.99520344744119
                        ],
                        [
                            32.00337369775733,
                            46.99520341973458
                        ],
                        [
                            32.003373942385274,
                            46.99520338775774
                        ],
                        [
                            32.00337418641848,
                            46.9952033515204
                        ],
                        [
                            32.00337442978275,
                            46.99520331103358
                        ],
                        [
                            32.00337467240409,
                            46.995203266309574
                        ],
                        [
                            32.003374914208734,
                            46.995203217362
                        ],
                        [
                            32.00337515512316,
                            46.995203164205726
                        ],
                        [
                            32.00337539507412,
                            46.99520310685693
                        ],
                        [
                            32.00337563398867,
                            46.99520304533302
                        ],
                        [
                            32.003375871794155,
                            46.99520297965273
                        ],
                        [
                            32.00337610841828,
                            46.99520290983602
                        ],
                        [
                            32.0033763437891,
                            46.99520283590412
                        ],
                        [
                            32.003376577835056,
                            46.99520275787949
                        ],
                        [
                            32.00337681048499,
                            46.995202675785876
                        ],
                        [
                            32.00337704166815,
                            46.99520258964823
                        ],
                        [
                            32.003377271314264,
                            46.995202499492734
                        ],
                        [
                            32.003377499353505,
                            46.99520240534681
                        ],
                        [
                            32.00337772571654,
                            46.995202307239076
                        ],
                        [
                            32.00337795033454,
                            46.99520220519937
                        ],
                        [
                            32.00337817313922,
                            46.9952020992587
                        ],
                        [
                            32.003378394062835,
                            46.995201989449285
                        ],
                        [
                            32.003378613038215,
                            46.99520187580452
                        ],
                        [
                            32.00337882999878,
                            46.99520175835894
                        ],
                        [
                            32.003379044878564,
                            46.99520163714826
                        ],
                        [
                            32.00337925761224,
                            46.99520151220935
                        ],
                        [
                            32.00337946813513,
                            46.995201383580174
                        ],
                        [
                            32.00337967638321,
                            46.99520125129985
                        ],
                        [
                            32.00337988229319,
                            46.9952011154086
                        ],
                        [
                            32.00338008580244,
                            46.995200975947725
                        ],
                        [
                            32.0033802868491,
                            46.99520083295965
                        ],
                        [
                            32.00338048537204,
                            46.995200686487834
                        ],
                        [
                            32.0033806813109,
                            46.99520053657681
                        ],
                        [
                            32.0033808746061,
                            46.99520038327216
                        ],
                        [
                            32.00338106519889,
                            46.995200226620504
                        ],
                        [
                            32.00338125303129,
                            46.99520006666946
                        ],
                        [
                            32.00338143804622,
                            46.995199903467665
                        ],
                        [
                            32.00338162018742,
                            46.99519973706473
                        ],
                        [
                            32.0033817993995,
                            46.995199567511264
                        ],
                        [
                            32.00338197562798,
                            46.9951993948588
                        ],
                        [
                            32.00338214881929,
                            46.99519921915985
                        ],
                        [
                            32.00338231892076,
                            46.995199040467824
                        ],
                        [
                            32.003382485880664,
                            46.99519885883705
                        ],
                        [
                            32.00338264964826,
                            46.99519867432275
                        ],
                        [
                            32.00338281017374,
                            46.99519848698103
                        ],
                        [
                            32.00338296740831,
                            46.99519829686885
                        ],
                        [
                            32.003383121304154,
                            46.99519810404401
                        ],
                        [
                            32.003383271814485,
                            46.99519790856513
                        ],
                        [
                            32.00338341889354,
                            46.99519771049166
                        ],
                        [
                            32.0033835624966,
                            46.99519750988381
                        ],
                        [
                            32.00338370258001,
                            46.99519730680257
                        ],
                        [
                            32.003383839101176,
                            46.99519710130969
                        ],
                        [
                            32.00338397201859,
                            46.99519689346766
                        ],
                        [
                            32.00338410129183,
                            46.99519668333965
                        ],
                        [
                            32.00338422688161,
                            46.99519647098957
                        ],
                        [
                            32.00338434874973,
                            46.99519625648196
                        ],
                        [
                            32.00338446685914,
                            46.99519603988206
                        ],
                        [
                            32.00338458117393,
                            46.99519582125572
                        ],
                        [
                            32.00338469165935,
                            46.995195600669405
                        ],
                        [
                            32.00338479828181,
                            46.99519537819018
                        ],
                        [
                            32.00338490100887,
                            46.9951951538857
                        ],
                        [
                            32.00338499980933,
                            46.995194927824166
                        ],
                        [
                            32.00338509465312,
                            46.99519470007429
                        ],
                        [
                            32.003385185511426,
                            46.99519447070533
                        ],
                        [
                            32.00338527235661,
                            46.995194239787025
                        ],
                        [
                            32.00338535516227,
                            46.99519400738958
                        ],
                        [
                            32.00338543390323,
                            46.99519377358365
                        ],
                        [
                            32.00338550855555,
                            46.99519353844033
                        ],
                        [
                            32.00338557909654,
                            46.99519330203111
                        ],
                        [
                            32.00338564550474,
                            46.99519306442787
                        ],
                        [
                            32.00338570775997,
                            46.995192825702844
                        ],
                        [
                            32.00338576584329,
                            46.99519258592862
                        ],
                        [
                            32.003385819737055,
                            46.995192345178104
                        ],
                        [
                            32.00338586942487,
                            46.99519210352449
                        ],
                        [
                            32.00338591489162,
                            46.99519186104124
                        ],
                        [
                            32.003385956123495,
                            46.99519161780209
                        ],
                        [
                            32.003385993107955,
                            46.995191373881
                        ],
                        [
                            32.003386025833755,
                            46.99519112935212
                        ],
                        [
                            32.00338605429094,
                            46.995190884289805
                        ],
                        [
                            32.00338607847087,
                            46.995190638768555
                        ],
                        [
                            32.00338609836618,
                            46.99519039286304
                        ],
                        [
                            32.003386113970826,
                            46.99519014664799
                        ],
                        [
                            32.00338612528006,
                            46.995189900198305
                        ],
                        [
                            32.00338613229045,
                            46.99518965358889
                        ],
                        [
                            32.003386134999865,
                            46.995189406894724
                        ],
                        [
                            32.00338613340748,
                            46.995189160190826
                        ],
                        [
                            32.00338612751377,
                            46.99518891355219
                        ],
                        [
                            32.00338611732054,
                            46.99518866705382
                        ],
                        [
                            32.00338610283088,
                            46.995188420770646
                        ],
                        [
                            32.003386084049204,
                            46.99518817477756
                        ],
                        [
                            32.00338606098121,
                            46.99518792914935
                        ],
                        [
                            32.00338603363392,
                            46.995187683960694
                        ],
                        [
                            32.00338600201565,
                            46.99518743928615
                        ],
                        [
                            32.00338596613601,
                            46.99518719520009
                        ],
                        [
                            32.00338592600591,
                            46.995186951776745
                        ],
                        [
                            32.00338588163755,
                            46.99518670909012
                        ],
                        [
                            32.003385833044426,
                            46.995186467214
                        ],
                        [
                            32.0033857802413,
                            46.995186226221925
                        ],
                        [
                            32.003385723244236,
                            46.99518598618717
                        ],
                        [
                            32.00338566207056,
                            46.9951857471827
                        ],
                        [
                            32.00338559673887,
                            46.99518550928121
                        ],
                        [
                            32.00338552726904,
                            46.99518527255501
                        ],
                        [
                            32.00338545368218,
                            46.99518503707609
                        ],
                        [
                            32.003385376000665,
                            46.99518480291603
                        ],
                        [
                            32.00338529424811,
                            46.99518457014604
                        ],
                        [
                            32.00338520844939,
                            46.99518433883687
                        ],
                        [
                            32.00338511863057,
                            46.99518410905887
                        ],
                        [
                            32.00338502481896,
                            46.995183880881896
                        ],
                        [
                            32.0033849270431,
                            46.99518365437531
                        ],
                        [
                            32.0033848253327,
                            46.995183429608
                        ],
                        [
                            32.003384719718696,
                            46.99518320664829
                        ],
                        [
                            32.00338461023319,
                            46.99518298556397
                        ],
                        [
                            32.00338449690948,
                            46.99518276642227
                        ],
                        [
                            32.00338437978201,
                            46.99518254928981
                        ],
                        [
                            32.003384258886406,
                            46.9951823342326
                        ],
                        [
                            32.003384134259406,
                            46.99518212131604
                        ],
                        [
                            32.00338400593892,
                            46.995181910604856
                        ],
                        [
                            32.00338387396395,
                            46.995181702163116
                        ],
                        [
                            32.00338373837463,
                            46.9951814960542
                        ],
                        [
                            32.00338359921218,
                            46.99518129234077
                        ],
                        [
                            32.00338345651891,
                            46.99518109108476
                        ],
                        [
                            32.003383310338215,
                            46.995180892347356
                        ],
                        [
                            32.00338316071453,
                            46.995180696188996
                        ],
                        [
                            32.003383007693344,
                            46.995180502669314
                        ],
                        [
                            32.00338285132119,
                            46.99518031184714
                        ],
                        [
                            32.00338269164561,
                            46.99518012378051
                        ],
                        [
                            32.00338252871515,
                            46.99517993852659
                        ],
                        [
                            32.00338236257935,
                            46.9951797561417
                        ],
                        [
                            32.00338219328872,
                            46.99517957668131
                        ],
                        [
                            32.00338202089473,
                            46.99517940019997
                        ],
                        [
                            32.0033818454498,
                            46.99517922675135
                        ],
                        [
                            32.00338166700727,
                            46.99517905638817
                        ],
                        [
                            32.00338148562139,
                            46.99517888916223
                        ],
                        [
                            32.00338130134731,
                            46.99517872512438
                        ],
                        [
                            32.003381114241066,
                            46.9951785643245
                        ],
                        [
                            32.00338092435953,
                            46.99517840681147
                        ],
                        [
                            32.00338073176045,
                            46.99517825263318
                        ],
                        [
                            32.003380536502384,
                            46.995178101836515
                        ],
                        [
                            32.00338033864468,
                            46.995177954467316
                        ],
                        [
                            32.003380138247515,
                            46.99517781057039
                        ],
                        [
                            32.003379935371804,
                            46.99517767018949
                        ],
                        [
                            32.00337973007924,
                            46.9951775333673
                        ],
                        [
                            32.00337952243223,
                            46.99517740014541
                        ],
                        [
                            32.00337931249392,
                            46.995177270564334
                        ],
                        [
                            32.003379100328125,
                            46.99517714466347
                        ],
                        [
                            32.00337888599937,
                            46.995177022481094
                        ],
                        [
                            32.003378669572804,
                            46.995176904054354
                        ],
                        [
                            32.00337845111424,
                            46.995176789419254
                        ],
                        [
                            32.00337823069009,
                            46.99517667861066
                        ],
                        [
                            32.00337800836738,
                            46.99517657166225
                        ],
                        [
                            32.003377784213704,
                            46.995176468606545
                        ],
                        [
                            32.0033775582972,
                            46.995176369474876
                        ],
                        [
                            32.00337733068658,
                            46.995176274297386
                        ],
                        [
                            32.003377101451036,
                            46.99517618310301
                        ],
                        [
                            32.00337687066026,
                            46.99517609591948
                        ],
                        [
                            32.003376638384424,
                            46.9951760127733
                        ],
                        [
                            32.00337640469415,
                            46.99517593368975
                        ],
                        [
                            32.0033761696605,
                            46.99517585869288
                        ],
                        [
                            32.00337593335492,
                            46.99517578780548
                        ],
                        [
                            32.00337569584926,
                            46.995175721049115
                        ],
                        [
                            32.00337545721574,
                            46.99517565844407
                        ],
                        [
                            32.0033752175269,
                            46.99517560000939
                        ],
                        [
                            32.003374976855625,
                            46.99517554576284
                        ],
                        [
                            32.00337473527509,
                            46.99517549572091
                        ],
                        [
                            32.00337449285874,
                            46.99517544989881
                        ],
                        [
                            32.003374249680284,
                            46.99517540831048
                        ],
                        [
                            32.00337400581366,
                            46.99517537096856
                        ],
                        [
                            32.00337376133301,
                            46.99517533788441
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 876,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.001323450437475,
                            46.99547884836857
                        ],
                        [
                            32.00168238097323,
                            46.99549383677972
                        ],
                        [
                            32.00171297561723,
                            46.995137666785006
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 877,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0010725653181,
                            46.99650679382458
                        ],
                        [
                            32.00017890153519,
                            46.99602217558729
                        ],
                        [
                            32.00032906635117,
                            46.99521280337082
                        ],
                        [
                            32.00030558634705,
                            46.995243005512556
                        ],
                        [
                            32.00032452188643,
                            46.995223632774234
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 878,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99906246123469,
                            46.99508423491462
                        ],
                        [
                            32.00032906635117,
                            46.99521280337082
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 879,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.998409886751837,
                            46.996841527566495
                        ],
                        [
                            31.99837727058884,
                            46.996315461741965
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 880,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.996468731813597,
                            46.99209760517572
                        ],
                        [
                            31.99686428791422,
                            46.9920476406136
                        ],
                        [
                            31.997442971839206,
                            46.99253729130806
                        ],
                        [
                            31.997397120913323,
                            46.99341029779881
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 881,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.997627930941842,
                            47.00274273890267
                        ],
                        [
                            31.997879056718816,
                            47.00274718450551
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 882,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.995141913396786,
                            47.00314726327201
                        ],
                        [
                            31.99588638533214,
                            47.00318858172054
                        ],
                        [
                            31.996439431361722,
                            47.00321106109182
                        ],
                        [
                            31.996426612414027,
                            47.00334593712085
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 883,
                id: 8,
                district: 7,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.994150487049176,
                            47.00216681991366
                        ],
                        [
                            31.994184393813196,
                            47.00166191849415
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 884,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.945962530582502,
                            46.94517085802853
                        ],
                        [
                            31.94602861080744,
                            46.94509734077058
                        ],
                        [
                            31.9457031045142,
                            46.94497369788181
                        ],
                        [
                            31.945191594624813,
                            46.94553175946678
                        ],
                        [
                            31.9434514820346,
                            46.94484337128448
                        ],
                        [
                            31.941877618903916,
                            46.94681831544343
                        ],
                        [
                            31.945317713017275,
                            46.94815093710139
                        ],
                        [
                            31.940178479723215,
                            46.95492239050371
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 885,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.943864221718563,
                            46.946878065703224
                        ],
                        [
                            31.944010104676934,
                            46.94669945109396
                        ],
                        [
                            31.943849645982578,
                            46.94664661208388
                        ],
                        [
                            31.944631595311087,
                            46.945635351096826
                        ],
                        [
                            31.94493323930088,
                            46.945557657393074
                        ],
                        [
                            31.946035953054633,
                            46.94597369333064
                        ],
                        [
                            31.9459558001892,
                            46.94606475297402
                        ],
                        [
                            31.94578203367178,
                            46.94628446505378
                        ],
                        [
                            31.945999853672514,
                            46.94636967634011
                        ],
                        [
                            31.946027998953504,
                            46.94638053659235
                        ],
                        [
                            31.94592765490822,
                            46.94650083462361
                        ],
                        [
                            31.945903639641276,
                            46.9464920629013
                        ],
                        [
                            31.945592205988532,
                            46.94688177660567
                        ],
                        [
                            31.945189606099508,
                            46.94696113917739
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 886,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.946035953054633,
                            46.945973484478074
                        ],
                        [
                            31.947567423453147,
                            46.945513380285654
                        ],
                        [
                            31.947795033116844,
                            46.945525911546184
                        ],
                        [
                            31.948118091994356,
                            46.945637857343456
                        ],
                        [
                            31.94767266232991,
                            46.9461917349704
                        ],
                        [
                            31.94759649671449,
                            46.94624216357743
                        ],
                        [
                            31.947456530027207,
                            46.94631469174955
                        ],
                        [
                            31.94683686985514,
                            46.94640810491506
                        ],
                        [
                            31.946680235247868,
                            46.94638554901571
                        ],
                        [
                            31.946199318055214,
                            46.94618755793585
                        ],
                        [
                            31.946027998953504,
                            46.94638053659235
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 887,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.946270293111606,
                            46.945302645814884
                        ],
                        [
                            31.94606103906596,
                            46.94557499228835
                        ],
                        [
                            31.946663763433076,
                            46.945784870023
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 888,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94606103906596,
                            46.94557499228835
                        ],
                        [
                            31.945785704795355,
                            46.94558000478714
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 889,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94331014377567,
                            46.9466743891194
                        ],
                        [
                            31.944010104676934,
                            46.94693169150004
                        ],
                        [
                            31.944445744678422,
                            46.94709375794909
                        ],
                        [
                            31.94458524737553,
                            46.9471004410911
                        ],
                        [
                            31.94482998894939,
                            46.94705365907955
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 890,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.944458516159685,
                            46.945859188466365
                        ],
                        [
                            31.944613239693027,
                            46.94591772081244
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 891,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94403376900323,
                            46.94640849552544
                        ],
                        [
                            31.943507007779143,
                            46.94621596176446
                        ],
                        [
                            31.94337484732925,
                            46.946237682329105
                        ],
                        [
                            31.94334914946399,
                            46.94624269476584
                        ],
                        [
                            31.943391979239426,
                            46.94634127259308
                        ],
                        [
                            31.94308605227209,
                            46.94638304280409
                        ],
                        [
                            31.943137448002606,
                            46.946520048867455
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 892,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94308605227209,
                            46.94638304280409
                        ],
                        [
                            31.94296368148515,
                            46.94612072533852
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 893,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.943700583067717,
                            46.94521617969689
                        ],
                        [
                            31.94403832643968,
                            46.945336271489765
                        ],
                        [
                            31.94339014367761,
                            46.94617711534802
                        ],
                        [
                            31.94337484732925,
                            46.946237682329105
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 894,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94310647289707,
                            46.94555452458013
                        ],
                        [
                            31.943302266156188,
                            46.9456171808053
                        ],
                        [
                            31.94321293548172,
                            46.94572662016937
                        ],
                        [
                            31.943625885974452,
                            46.945871304265985
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 895,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.945291192810966,
                            46.94569270777629
                        ],
                        [
                            31.945214921556232,
                            46.94578844077996
                        ],
                        [
                            31.94496895627446,
                            46.94569905149568
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 896,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.93880513973576,
                            46.95444763327217
                        ],
                        [
                            31.93882678345117,
                            46.95452677727749
                        ],
                        [
                            31.9419987113482,
                            46.95545392501367
                        ],
                        [
                            31.942861425396217,
                            46.95571243807729
                        ],
                        [
                            31.942398251967607,
                            46.956388575387365
                        ],
                        [
                            31.94314716118375,
                            46.95661242131115
                        ],
                        [
                            31.943651328825982,
                            46.95594756276797
                        ],
                        [
                            31.944121232647877,
                            46.956091226368876
                        ],
                        [
                            31.944375763884732,
                            46.95588742440187
                        ],
                        [
                            31.9447624555715,
                            46.95527601384372
                        ],
                        [
                            31.94620995199637,
                            46.95317399010701
                        ],
                        [
                            31.94424145609009,
                            46.95255113373982
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 897,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.93880513973576,
                            46.95444763327217
                        ],
                        [
                            31.938612711673276,
                            46.95374160986266
                        ],
                        [
                            31.939386095046807,
                            46.953638033920626
                        ],
                        [
                            31.939146248304382,
                            46.952869560992866
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 898,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.939252099909158,
                            46.953208712644816
                        ],
                        [
                            31.940183952577726,
                            46.95307671565107
                        ],
                        [
                            31.940232900892514,
                            46.95325379883611
                        ],
                        [
                            31.94096712561422,
                            46.953153563142955
                        ],
                        [
                            31.940830070332822,
                            46.952722547523145
                        ],
                        [
                            31.940541275275628,
                            46.952755959710814
                        ],
                        [
                            31.94045316830902,
                            46.95247195545073
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 899,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.938687699966053,
                            46.95401674490584
                        ],
                        [
                            31.93900185077578,
                            46.95397716085547
                        ],
                        [
                            31.93896636324757,
                            46.95387776180409
                        ],
                        [
                            31.939674890104016,
                            46.95378003314503
                        ],
                        [
                            31.939750759991913,
                            46.95399386656027
                        ],
                        [
                            31.940022423138934,
                            46.95395711400281
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 900,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.939150308539944,
                            46.95385238984471
                        ],
                        [
                            31.93929309324872,
                            46.95438811967967
                        ],
                        [
                            31.93969446942992,
                            46.954338002918846
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 901,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.938687699966053,
                            46.95401674490584
                        ],
                        [
                            31.937149157061345,
                            46.95422273419086
                        ],
                        [
                            31.93728376492699,
                            46.95466209047585
                        ],
                        [
                            31.93770961526558,
                            46.95460195066514
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 902,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.937149157061345,
                            46.95422273419086
                        ],
                        [
                            31.937053707847543,
                            46.95390699683837
                        ],
                        [
                            31.93683833526251,
                            46.95394040828654
                        ],
                        [
                            31.936559329868256,
                            46.9530750450522
                        ],
                        [
                            31.936593593688606,
                            46.95296812661573
                        ],
                        [
                            31.93628521930549,
                            46.95187220032603
                        ],
                        [
                            31.936745333464426,
                            46.95181205738007
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 903,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.936682685519944,
                            46.953457646712934
                        ],
                        [
                            31.937076958297062,
                            46.95339997555209
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 904,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.938175847963876,
                            46.95388444409905
                        ],
                        [
                            31.938320245492484,
                            46.954353873231504
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 905,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.938612711673276,
                            46.95374160986266
                        ],
                        [
                            31.93827619200917,
                            46.953784209587106
                        ],
                        [
                            31.93799473919918,
                            46.95287540811115
                        ],
                        [
                            31.9378895003224,
                            46.95289127885755
                        ],
                        [
                            31.93773286571511,
                            46.952331623377646
                        ],
                        [
                            31.937708391557717,
                            46.952246421582316
                        ],
                        [
                            31.937649653579975,
                            46.95225310408097
                        ],
                        [
                            31.937551756950413,
                            46.95187052968955
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 906,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.93773286571511,
                            46.952331623377646
                        ],
                        [
                            31.937270304140437,
                            46.95239176573974
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 907,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.941022593331954,
                            46.95381024527896
                        ],
                        [
                            31.942040317415767,
                            46.95413419427466
                        ],
                        [
                            31.942975230228075,
                            46.95292803214697
                        ],
                        [
                            31.944761843717554,
                            46.95349269314784
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 908,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.943302546706803,
                            46.953031481296975
                        ],
                        [
                            31.94223611067489,
                            46.954561857420444
                        ],
                        [
                            31.942314427978534,
                            46.954652067178756
                        ],
                        [
                            31.942157793371244,
                            46.95485587385063
                        ],
                        [
                            31.94202563292134,
                            46.95486255602345
                        ],
                        [
                            31.941450490222664,
                            46.95570116208926
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 909,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94333942969627,
                            46.956358872965026
                        ],
                        [
                            31.94360727534268,
                            46.95643869022777
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 910,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.944778147265296,
                            46.95153378613921
                        ],
                        [
                            31.944491799623833,
                            46.95198151766588
                        ],
                        [
                            31.94497394052442,
                            46.9521335450563
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 911,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.944971493108685,
                            46.9516657670905
                        ],
                        [
                            31.94483443782729,
                            46.95186290258883
                        ],
                        [
                            31.944746330860696,
                            46.95183116048137
                        ],
                        [
                            31.944206931302116,
                            46.95260036722686
                        ],
                        [
                            31.94238337895374,
                            46.95201732037814
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 912,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94324472974997,
                            46.95229272202004
                        ],
                        [
                            31.94350304366527,
                            46.9519384988479
                        ],
                        [
                            31.944110002768547,
                            46.95214398648211
                        ],
                        [
                            31.944019448386193,
                            46.95224923594042
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 913,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94350304366527,
                            46.9519384988479
                        ],
                        [
                            31.943324382316323,
                            46.95187167343099
                        ],
                        [
                            31.943906867262204,
                            46.95105806728804
                        ],
                        [
                            31.944303348611925,
                            46.95117668414884
                        ],
                        [
                            31.944746330860692,
                            46.95057691450881
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 914,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.944303348611925,
                            46.95075066462249
                        ],
                        [
                            31.94470299461013,
                            46.950885425549814
                        ],
                        [
                            31.944734093781996,
                            46.950842551473826
                        ],
                        [
                            31.945228471761272,
                            46.951002935418174
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 915,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.945821299656824,
                            46.95373839093433
                        ],
                        [
                            31.94642097508009,
                            46.95392442861953
                        ],
                        [
                            31.94634009572531,
                            46.95404513456038
                        ],
                        [
                            31.946619480516038,
                            46.95413192916492
                        ],
                        [
                            31.946310513367656,
                            46.954569769424666
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 916,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.947052408340735,
                            46.95387974078952
                        ],
                        [
                            31.94692024789083,
                            46.95407185642956
                        ],
                        [
                            31.946521319125374,
                            46.95396661055565
                        ],
                        [
                            31.94640139575416,
                            46.95413199683599
                        ],
                        [
                            31.945613327886203,
                            46.95524959375768
                        ],
                        [
                            31.946088126539575,
                            46.95540662344304
                        ],
                        [
                            31.945995124741493,
                            46.95554026536551
                        ],
                        [
                            31.94692024789084,
                            46.95580420718188
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 917,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.945995124741493,
                            46.95554026536551
                        ],
                        [
                            31.945799331482373,
                            46.95549349073061
                        ],
                        [
                            31.945574169234384,
                            46.955827594368635
                        ],
                        [
                            31.945402850132652,
                            46.955767455867736
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 918,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.945794436650896,
                            46.95455798944676
                        ],
                        [
                            31.946234971483918,
                            46.95470833897035
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 919,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.946342657776434,
                            46.954631493711055
                        ],
                        [
                            31.94653355620407,
                            46.95468829239161
                        ],
                        [
                            31.946648584743812,
                            46.95452959004488
                        ],
                        [
                            31.947250649015604,
                            46.95471335061386
                        ],
                        [
                            31.947896766770704,
                            46.953769482806365
                        ],
                        [
                            31.94982533037305,
                            46.9543742283443
                        ],
                        [
                            31.94991833217113,
                            46.954250606883946
                        ],
                        [
                            31.950192442733897,
                            46.95433246436936
                        ],
                        [
                            31.950657451724314,
                            46.95363750704297
                        ],
                        [
                            31.95099274768055,
                            46.95374609472012
                        ],
                        [
                            31.951961924313206,
                            46.954040115632246
                        ],
                        [
                            31.952319247011108,
                            46.95347546040735
                        ],
                        [
                            31.9522751935278,
                            46.95330171913954
                        ],
                        [
                            31.952265403864846,
                            46.95301103537248
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 920,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.950119020261727,
                            46.95360409540567
                        ],
                        [
                            31.950576658313107,
                            46.953758253685336
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 921,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95141370318767,
                            46.953482142752534
                        ],
                        [
                            31.95122164412988,
                            46.95381553560608
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 922,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95122164412988,
                            46.95381553560608
                        ],
                        [
                            31.951066170152725,
                            46.954183784354065
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 923,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.951961924313206,
                            46.954040115632246
                        ],
                        [
                            31.95140880835618,
                            46.95487539350112
                        ],
                        [
                            31.95369714207217,
                            46.95563882606951
                        ],
                        [
                            31.954078938927452,
                            46.95521117153046
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 924,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95173616299803,
                            46.95438104649638
                        ],
                        [
                            31.9518738173466,
                            46.95443102729789
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 925,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.953993753435388,
                            46.95530658896232
                        ],
                        [
                            31.955146012189655,
                            46.95567223643643
                        ],
                        [
                            31.95531243645992,
                            46.95547511497001
                        ],
                        [
                            31.95695709983653,
                            46.95610991031672
                        ],
                        [
                            31.957064786129045,
                            46.955982951849805
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 926,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.954383543530238,
                            46.95543028173993
                        ],
                        [
                            31.954289416681018,
                            46.95557868735645
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 927,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.9548401915,
                            46.955575190281074
                        ],
                        [
                            31.954739741176994,
                            46.955729034012336
                        ],
                        [
                            31.95482050589638,
                            46.955754091747245
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 928,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.954739741176994,
                            46.955729034012336
                        ],
                        [
                            31.954588001401177,
                            46.95568393005992
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 929,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.955521203933156,
                            46.95555569395579
                        ],
                        [
                            31.95585331533825,
                            46.955199477803646
                        ],
                        [
                            31.956191058710225,
                            46.955027412670944
                        ],
                        [
                            31.956939967926367,
                            46.95427399474971
                        ],
                        [
                            31.95714799826418,
                            46.95367425981991
                        ],
                        [
                            31.957926276469184,
                            46.95288908136787
                        ],
                        [
                            31.958141649054223,
                            46.952985976353126
                        ],
                        [
                            31.958782871977846,
                            46.95227763655245
                        ],
                        [
                            31.95986462973449,
                            46.952768797693864
                        ],
                        [
                            31.960300269736027,
                            46.953320095646475
                        ],
                        [
                            31.960662487265402,
                            46.95347713099373
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 930,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95804130500892,
                            46.95181403644191
                        ],
                        [
                            31.957849182873403,
                            46.95171546869351
                        ],
                        [
                            31.957611783546714,
                            46.95170795080695
                        ],
                        [
                            31.95731931736591,
                            46.95200532418137
                        ],
                        [
                            31.957501649838466,
                            46.95221582343641
                        ],
                        [
                            31.95846200239553,
                            46.9526320927627
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 931,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95754149733326,
                            46.95327727341147
                        ],
                        [
                            31.956412549834592,
                            46.95277297421484
                        ],
                        [
                            31.95628528421616,
                            46.952914975734565
                        ],
                        [
                            31.956216756575476,
                            46.95288657546077
                        ],
                        [
                            31.956123754777398,
                            46.95297678804311
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 932,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95825488909479,
                            46.95286088414615
                        ],
                        [
                            31.959092470068814,
                            46.95324074250319
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 933,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.955059128930927,
                            46.9536416834962
                        ],
                        [
                            31.95688443578746,
                            46.95432986157635
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 934,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.953541731172738,
                            46.95235532050248
                        ],
                        [
                            31.95367878645412,
                            46.952445533980324
                        ],
                        [
                            31.953443834543176,
                            46.95261092496143
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 935,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.961229176242547,
                            46.95383121262662
                        ],
                        [
                            31.96058297182433,
                            46.95448094747468
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 936,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96071521893735,
                            46.954537861311465
                        ],
                        [
                            31.959786495717275,
                            46.954138174922605
                        ],
                        [
                            31.959621224102015,
                            46.95431901838992
                        ],
                        [
                            31.95916355735882,
                            46.95413525646686
                        ],
                        [
                            31.95868386387398,
                            46.954457257057065
                        ],
                        [
                            31.95819927555766,
                            46.954808072615364
                        ],
                        [
                            31.957064786129045,
                            46.955982951849805
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 937,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.958019390500837,
                            46.9552708115248
                        ],
                        [
                            31.957903750107175,
                            46.955223201388456
                        ],
                        [
                            31.957807689039413,
                            46.95496050961142
                        ],
                        [
                            31.957611895780296,
                            46.95487823545103
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 938,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.958334863605902,
                            46.954709914482045
                        ],
                        [
                            31.958327764883958,
                            46.954746680058655
                        ],
                        [
                            31.95868875870546,
                            46.954901205407026
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 939,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.958863129872352,
                            46.95433692261363
                        ],
                        [
                            31.95947437915768,
                            46.954608859776926
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 940,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.957242135843234,
                            46.955799289876936
                        ],
                        [
                            31.95714443937414,
                            46.95575985705198
                        ],
                        [
                            31.957308416228653,
                            46.955574429555746
                        ],
                        [
                            31.956191058710225,
                            46.955027412670944
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 941,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.956761668209293,
                            46.95603447941643
                        ],
                        [
                            31.956840843681015,
                            46.95594568035187
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 942,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.956532501947365,
                            46.95594602779696
                        ],
                        [
                            31.956285280308258,
                            46.956239689178986
                        ],
                        [
                            31.955761533340105,
                            46.95612776555455
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 943,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95636876276739,
                            46.956140525004436
                        ],
                        [
                            31.957061111097524,
                            46.9563866929868
                        ],
                        [
                            31.95699441901863,
                            46.95647105294413
                        ],
                        [
                            31.957183481884474,
                            46.95653536688167
                        ],
                        [
                            31.957315642334382,
                            46.95669239279397
                        ],
                        [
                            31.95745759244724,
                            46.95674417783423
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 944,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.957400008900276,
                            46.9556357972471
                        ],
                        [
                            31.958149599247427,
                            46.95596906747681
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 945,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95712208592932,
                            46.95592361269502
                        ],
                        [
                            31.958807954081234,
                            46.95651699147883
                        ],
                        [
                            31.959375754532687,
                            46.95655708325868
                        ],
                        [
                            31.96124558015729,
                            46.95732884416656
                        ],
                        [
                            31.961754642631,
                            46.95683104295051
                        ],
                        [
                            31.962351812071326,
                            46.95707827366352
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 946,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95950791498258,
                            46.956829372468846
                        ],
                        [
                            31.959378201948407,
                            46.95677090557734
                        ],
                        [
                            31.959769788466655,
                            46.95635996505178
                        ],
                        [
                            31.959975371388733,
                            46.95629648615262
                        ],
                        [
                            31.96018340172655,
                            46.956074309412514
                        ],
                        [
                            31.961164815437897,
                            46.95650529803738
                        ],
                        [
                            31.960971469594508,
                            46.95668571084966
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 947,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.960681401254643,
                            46.95629300674174
                        ],
                        [
                            31.961027760156504,
                            46.95592062270667
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 948,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.961974910047516,
                            46.956197926659954
                        ],
                        [
                            31.962858427129294,
                            46.95659383403052
                        ],
                        [
                            31.962696897690524,
                            46.95675921219138
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 949,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.962156018812202,
                            46.95653286114528
                        ],
                        [
                            31.962328418738863,
                            46.956356335602955
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 950,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.962768625797956,
                            46.956685775099125
                        ],
                        [
                            31.96291104656769,
                            46.95675503598148
                        ],
                        [
                            31.963218197242938,
                            46.95646102998586
                        ],
                        [
                            31.96287555903947,
                            46.95628729841097
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 951,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.962006342510772,
                            46.956935247921
                        ],
                        [
                            31.962223322745018,
                            46.95672079104806
                        ],
                        [
                            31.963202289040634,
                            46.95716346776619
                        ],
                        [
                            31.963596269954703,
                            46.956767765003036
                        ],
                        [
                            31.962777050555975,
                            46.955879068752516
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 952,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.962517719564524,
                            46.95685391411412
                        ],
                        [
                            31.962615521117204,
                            46.95675775051796
                        ],
                        [
                            31.962662022016247,
                            46.9567788403735
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 953,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.964035022245827,
                            46.95727142186324
                        ],
                        [
                            31.96452695280937,
                            46.9567786315631
                        ],
                        [
                            31.964338501797467,
                            46.95669928354818
                        ],
                        [
                            31.964260184493813,
                            46.95657566745896
                        ],
                        [
                            31.964020337751393,
                            46.956462074044104
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 954,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.963596269954703,
                            46.956767765003036
                        ],
                        [
                            31.96463708651763,
                            46.95721796686369
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 955,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96298415979124,
                            46.95610374402644
                        ],
                        [
                            31.9633742199963,
                            46.955707005804975
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 956,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.963269866253224,
                            46.95641368122879
                        ],
                        [
                            31.963494143367512,
                            46.956191453466566
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 957,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.963283804282398,
                            46.955798969740826
                        ],
                        [
                            31.962282672576695,
                            46.955337820332005
                        ],
                        [
                            31.963232269883424,
                            46.95436305821185
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 958,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96273717592622,
                            46.955547177844956
                        ],
                        [
                            31.963046266287257,
                            46.95521420109763
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 959,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.955783115521616,
                            46.95565678505972
                        ],
                        [
                            31.95529774805753,
                            46.956167231187656
                        ],
                        [
                            31.955261036821444,
                            46.95635265662973
                        ],
                        [
                            31.954690788954263,
                            46.95721796686369
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 960,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95526103682144,
                            46.956352656629726
                        ],
                        [
                            31.95546417232777,
                            46.956422817440256
                        ],
                        [
                            31.95454149659417,
                            46.95775418490905
                        ],
                        [
                            31.954825396819892,
                            46.95784940063567
                        ],
                        [
                            31.954611859796675,
                            46.95814131095212
                        ],
                        [
                            31.95493538176315,
                            46.95827640077995
                        ],
                        [
                            31.954818054572673,
                            46.95846913394409
                        ],
                        [
                            31.95468344670703,
                            46.95867793735051
                        ],
                        [
                            31.954485206032174,
                            46.958738072580424
                        ],
                        [
                            31.954732395021804,
                            46.95905712225311
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 961,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95468344670703,
                            46.95867793735051
                        ],
                        [
                            31.955104402214147,
                            46.958668750017765
                        ],
                        [
                            31.95515090311319,
                            46.95859191044576
                        ],
                        [
                            31.955527805136985,
                            46.958707169762384
                        ],
                        [
                            31.95574562513776,
                            46.95838978843599
                        ],
                        [
                            31.95611029008288,
                            46.95850003689961
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 962,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.955579240563445,
                            46.95863222436328
                        ],
                        [
                            31.955757862216455,
                            46.95868378383417
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 963,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95574562513776,
                            46.95838978843599
                        ],
                        [
                            31.95543480333891,
                            46.95824111969354
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 964,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95465317740625,
                            46.95808901306969
                        ],
                        [
                            31.954913503786507,
                            46.95817639019626
                        ],
                        [
                            31.95497836030358,
                            46.95809787947512
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 965,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95465317740625,
                            46.95808901306969
                        ],
                        [
                            31.95442463249264,
                            46.95801018119297
                        ],
                        [
                            31.95448948900972,
                            46.95792331799085
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 966,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.956048492835464,
                            46.957461437249684
                        ],
                        [
                            31.956545318230493,
                            46.957849818248135
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 967,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.954531095077286,
                            46.95658109654996
                        ],
                        [
                            31.95474891507806,
                            46.95625535011526
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 968,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.954078323165565,
                            46.95605321927929
                        ],
                        [
                            31.954014690356356,
                            46.956145097026656
                        ],
                        [
                            31.954679363055437,
                            46.95635936430246
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 969,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.953640235748285,
                            46.95728436798793
                        ],
                        [
                            31.953877635074967,
                            46.957209196897736
                        ],
                        [
                            31.954501517678704,
                            46.95630203324529
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 970,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.9539486101314,
                            46.95642407031105
                        ],
                        [
                            31.95411748181739,
                            46.956479196596355
                        ],
                        [
                            31.95428867689268,
                            46.9562334208256
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 971,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95397671406556,
                            46.957065130982826
                        ],
                        [
                            31.95410279732295,
                            46.956980342039685
                        ],
                        [
                            31.954217825862692,
                            46.957015422044954
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 972,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.954155063794847,
                            46.956805800385894
                        ],
                        [
                            31.952601285999638,
                            46.956325816653134
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 973,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.953668992883223,
                            46.95685087991645
                        ],
                        [
                            31.953801765187063,
                            46.95688679524654
                        ],
                        [
                            31.953907067309206,
                            46.95672899372735
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 974,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.953237024005265,
                            46.95578176137688
                        ],
                        [
                            31.952958018611028,
                            46.95593878950064
                        ],
                        [
                            31.95290849505677,
                            46.956018133532744
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 975,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.953190523106233,
                            46.95610918119875
                        ],
                        [
                            31.95282830557686,
                            46.955992245778056
                        ],
                        [
                            31.95233148018183,
                            46.95672225272252
                        ],
                        [
                            31.952106317933847,
                            46.95665376279181
                        ],
                        [
                            31.95201331613576,
                            46.95678740159965
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 976,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95237436592533,
                            46.956659239245795
                        ],
                        [
                            31.952691250295477,
                            46.956758168138954
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 977,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.952351319773214,
                            46.956693101747554
                        ],
                        [
                            31.95262517007051,
                            46.95698201251665
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 978,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.951536681920594,
                            46.95614197317841
                        ],
                        [
                            31.951490181021562,
                            46.95621046376419
                        ],
                        [
                            31.952475454312868,
                            46.95651070635759
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 979,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.952087350461877,
                            46.95621547526701
                        ],
                        [
                            31.9515966436062,
                            46.95615032569357
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 980,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.951630295572613,
                            46.95610647497447
                        ],
                        [
                            31.951729415910048,
                            46.956133203036096
                        ],
                        [
                            31.95216750332733,
                            46.95554518259631
                        ],
                        [
                            31.952441613890098,
                            46.955508431104285
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 981,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95216750332733,
                            46.95554518259631
                        ],
                        [
                            31.950393126916534,
                            46.95496383785518
                        ],
                        [
                            31.950202228488898,
                            46.95503901209964
                        ],
                        [
                            31.950094542196382,
                            46.95500727187598
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 982,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.950648768879127,
                            46.95504759505609
                        ],
                        [
                            31.950713738378354,
                            46.954952144074326
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 983,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95149661225089,
                            46.955325377103364
                        ],
                        [
                            31.951570333887005,
                            46.955229453046336
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 984,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.950970370128065,
                            46.955152962627764
                        ],
                        [
                            31.95086547815417,
                            46.95530462691768
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 985,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95042739073688,
                            46.95561200342625
                        ],
                        [
                            31.9503466260175,
                            46.95573395122578
                        ],
                        [
                            31.94967113927353,
                            46.95559529822659
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 986,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94981616582613,
                            46.955625066963165
                        ],
                        [
                            31.94963932286892,
                            46.955882626934205
                        ],
                        [
                            31.951288881077023,
                            46.95641969360823
                        ],
                        [
                            31.951493240291235,
                            46.956127356273754
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 987,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94963932286892,
                            46.9558826269342
                        ],
                        [
                            31.949529189160664,
                            46.95592104867933
                        ],
                        [
                            31.949477793430155,
                            46.955981187007616
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 988,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.950838811977437,
                            46.95627315968709
                        ],
                        [
                            31.950748002198708,
                            46.956398812423004
                        ],
                        [
                            31.9511175619753,
                            46.95651825269243
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 989,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.950180201747262,
                            46.95619751737967
                        ],
                        [
                            31.95026024826786,
                            46.95608478978044
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 990,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.951466318718104,
                            46.9556754831374
                        ],
                        [
                            31.951488345459754,
                            46.95608642891948
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 991,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.950986625233256,
                            46.95591603714891
                        ],
                        [
                            31.950922992424047,
                            46.95600290360954
                        ],
                        [
                            31.95135373759411,
                            46.95613988504941
                        ],
                        [
                            31.951493240291228,
                            46.956127356273754
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 992,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.950662020219863,
                            46.95621559955048
                        ],
                        [
                            31.950306243657813,
                            46.95673959234705
                        ],
                        [
                            31.95049469466972,
                            46.95700352824783
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 993,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94629982409306,
                            46.954728291205335
                        ],
                        [
                            31.947161314433192,
                            46.954987225409205
                        ],
                        [
                            31.9488630041383,
                            46.95551567845313
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 994,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.947250649015604,
                            46.95471335061386
                        ],
                        [
                            31.94719068342206,
                            46.954811818504766
                        ],
                        [
                            31.94844131286469,
                            46.955224441451165
                        ],
                        [
                            31.94863955353956,
                            46.954933768127816
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 995,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94844131286469,
                            46.955224441451165
                        ],
                        [
                            31.94826265151575,
                            46.955496737442445
                        ],
                        [
                            31.948536809885727,
                            46.95558247314095
                        ],
                        [
                            31.948543333770786,
                            46.95558692611718
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 996,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.948539614839603,
                            46.95559315071784
                        ],
                        [
                            31.947641007918044,
                            46.956844832767345
                        ],
                        [
                            31.94781722185125,
                            46.95690497005744
                        ],
                        [
                            31.94756024319866,
                            46.95726746284675
                        ],
                        [
                            31.946999784994414,
                            46.95705364251231
                        ],
                        [
                            31.946666936453916,
                            46.957469589096064
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 998,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.944842226028104,
                            46.9482515998509
                        ],
                        [
                            31.94509186243345,
                            46.94834014949931
                        ],
                        [
                            31.945252461293993,
                            46.948125660235455
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 999,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.941506398376266,
                            46.9492724180396
                        ],
                        [
                            31.9415145255173,
                            46.94935508253698
                        ],
                        [
                            31.94275458040299,
                            46.949745229032374
                        ],
                        [
                            31.943373776584888,
                            46.94997495018024
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1000,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.942473127593043,
                            46.949775301675096
                        ],
                        [
                            31.94255261115468,
                            46.949681685591756
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1001,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.943102113437885,
                            46.949964090657275
                        ],
                        [
                            31.943547543102323,
                            46.94937099028728
                        ],
                        [
                            31.943821653665065,
                            46.949461208795555
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1002,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94243396894122,
                            46.94737779074838
                        ],
                        [
                            31.942032592760075,
                            46.9472341037559
                        ],
                        [
                            31.94180498309637,
                            46.94754820970453
                        ],
                        [
                            31.94161163725301,
                            46.94748973267288
                        ],
                        [
                            31.9413448689375,
                            46.94784393542903
                        ],
                        [
                            31.94115152309414,
                            46.947775434135416
                        ],
                        [
                            31.94080643747499,
                            46.94822319710246
                        ],
                        [
                            31.94193224871478,
                            46.948615821995055
                        ],
                        [
                            31.94190043231018,
                            46.94865759043117
                        ],
                        [
                            31.942064409164676,
                            46.94871272471692
                        ],
                        [
                            31.94226020242377,
                            46.94872776133046
                        ],
                        [
                            31.942747238155775,
                            46.94889650525928
                        ],
                        [
                            31.941800606946863,
                            46.950107736388446
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1003,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.942169648041432,
                            46.94849719945879
                        ],
                        [
                            31.941902879725916,
                            46.94884304189386
                        ],
                        [
                            31.942262649839506,
                            46.948973358753506
                        ],
                        [
                            31.942507391413365,
                            46.94866260264131
                        ],
                        [
                            31.943036033212927,
                            46.9488547370096
                        ],
                        [
                            31.943750678608627,
                            46.947937498029866
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1004,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94306949539566,
                            46.94881178904145
                        ],
                        [
                            31.943591596585616,
                            46.948985053840765
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1005,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.943198551669305,
                            46.948646147858625
                        ],
                        [
                            31.943334617933054,
                            46.94869434662797
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1006,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94241194219957,
                            46.95078606678279
                        ],
                        [
                            31.94261752512161,
                            46.95051374682246
                        ],
                        [
                            31.942360546469054,
                            46.95041350599738
                        ],
                        [
                            31.94242540298613,
                            46.950329136490765
                        ],
                        [
                            31.94127634129681,
                            46.94992232324069
                        ],
                        [
                            31.940065635323577,
                            46.95149902060727
                        ],
                        [
                            31.939767050603457,
                            46.95140045227873
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1007,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94006563532357,
                            46.95149902060727
                        ],
                        [
                            31.94050678201048,
                            46.95165731428047
                        ],
                        [
                            31.940381963807802,
                            46.95183022556786
                        ],
                        [
                            31.941354811563926,
                            46.95217604646674
                        ],
                        [
                            31.94153102549711,
                            46.95194717008325
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1008,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.940381963807802,
                            46.95183022556786
                        ],
                        [
                            31.940091945042763,
                            46.95180516599561
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1009,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.940558610711406,
                            46.950857026410645
                        ],
                        [
                            31.94180574791378,
                            46.95130606040411
                        ],
                        [
                            31.941950145442362,
                            46.951108922854715
                        ],
                        [
                            31.942128806791285,
                            46.95116739593241
                        ],
                        [
                            31.94180574791378,
                            46.951585058915164
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1010,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.93976582689559,
                            46.95142885334119
                        ],
                        [
                            31.939522309029577,
                            46.951751287875545
                        ],
                        [
                            31.94030303465022,
                            46.95204364911608
                        ],
                        [
                            31.941110681843988,
                            46.9523761036718
                        ],
                        [
                            31.941211025889277,
                            46.952658437828646
                        ],
                        [
                            31.941582933099916,
                            46.95307196791348
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1011,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.9397009703785,
                            46.951417994113186
                        ],
                        [
                            31.939470913299058,
                            46.95173291082992
                        ],
                        [
                            31.939228619140938,
                            46.95198267104629
                        ],
                        [
                            31.939719325996542,
                            46.952163934419886
                        ],
                        [
                            31.939856381277906,
                            46.95226166603018
                        ],
                        [
                            31.93963121902994,
                            46.95255235386746
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1012,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.939695912518147,
                            46.95135328425378
                        ],
                        [
                            31.939549230602704,
                            46.95129854246002
                        ],
                        [
                            31.939593284085998,
                            46.95124508149393
                        ],
                        [
                            31.938671604174438,
                            46.95090610573887
                        ],
                        [
                            31.939155183950078,
                            46.95013934699783
                        ],
                        [
                            31.939084221612354,
                            46.95011403541417
                        ],
                        [
                            31.93921393464651,
                            46.949941953930505
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1013,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.937511444148942,
                            46.950584976089395
                        ],
                        [
                            31.93751966424411,
                            46.95059619863669
                        ],
                        [
                            31.93751966424411,
                            46.95059619863669
                        ],
                        [
                            31.93749500395861,
                            46.950584976089395
                        ],
                        [
                            31.937503224053785,
                            46.95059058736334
                        ],
                        [
                            31.937229080482428,
                            46.949599460010816
                        ],
                        [
                            31.93714097351584,
                            46.94951091244576
                        ],
                        [
                            31.9369549699197,
                            46.94897962397911
                        ],
                        [
                            31.936864415537357,
                            46.948939526522324
                        ],
                        [
                            31.936428775535877,
                            46.949009697052
                        ],
                        [
                            31.936463039356223,
                            46.949160062162946
                        ],
                        [
                            31.93546449373483,
                            46.94930708541854
                        ],
                        [
                            31.93551833688108,
                            46.94948752249846
                        ],
                        [
                            31.9350704598009,
                            46.94954516199303
                        ],
                        [
                            31.935028853733343,
                            46.94940398688893
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1014,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.937510974893417,
                            46.95058944876283
                        ],
                        [
                            31.93749500395861,
                            46.95057936481486
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1015,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.937394376641983,
                            46.95017996802485
                        ],
                        [
                            31.937873974529577,
                            46.95011988283352
                        ],
                        [
                            31.9378446055407,
                            46.94999792224077
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1016,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.937479033590613,
                            46.95047727459422
                        ],
                        [
                            31.93705898508861,
                            46.95053546564311
                        ],
                        [
                            31.937030642627114,
                            46.95043515985776
                        ],
                        [
                            31.936145290522305,
                            46.950564420385426
                        ],
                        [
                            31.935919713062237,
                            46.95059059799452
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1017,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.936322312951248,
                            46.95122252763283
                        ],
                        [
                            31.935913594522887,
                            46.94970304376893
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1018,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.936243607590352,
                            46.950929929729504
                        ],
                        [
                            31.936031070478354,
                            46.95095146289214
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1019,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.9360084647529,
                            46.95005574466266
                        ],
                        [
                            31.93577653924152,
                            46.95008605132707
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1020,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.93705898508861,
                            46.9505354656431
                        ],
                        [
                            31.937193592954216,
                            46.951008265478464
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1021,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.938979049939576,
                            46.95457128481324
                        ],
                        [
                            31.936831375424894,
                            46.95482225415465
                        ],
                        [
                            31.936202389580046,
                            46.95271064442521
                        ],
                        [
                            31.93603351789409,
                            46.95207246749471
                        ],
                        [
                            31.93591604193863,
                            46.95166483217411
                        ],
                        [
                            31.936733478795357,
                            46.951606359639904
                        ],
                        [
                            31.93696353587479,
                            46.95159466512539
                        ],
                        [
                            31.937271910257863,
                            46.95162139544052
                        ],
                        [
                            31.937592521719633,
                            46.95158798254453
                        ],
                        [
                            31.938272903294994,
                            46.95148941437982
                        ],
                        [
                            31.93820927048579,
                            46.951146930362455
                        ],
                        [
                            31.938130953182153,
                            46.95072759328847
                        ],
                        [
                            31.938358562845853,
                            46.95070253319992
                        ],
                        [
                            31.938148085092323,
                            46.94976694816487
                        ],
                        [
                            31.9377736304843,
                            46.94980203289882
                        ],
                        [
                            31.936919482391495,
                            46.948567370568306
                        ],
                        [
                            31.93676774261569,
                            46.94819980659238
                        ],
                        [
                            31.93655726486217,
                            46.94756491741609
                        ],
                        [
                            31.936368813850287,
                            46.94758663743337
                        ],
                        [
                            31.93635412935586,
                            46.94754486816155
                        ],
                        [
                            31.936694320143538,
                            46.94750476962993
                        ],
                        [
                            31.937134854976502,
                            46.947666834343885
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1022,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.93795473924897,
                            46.94999082178672
                        ],
                        [
                            31.937979213406354,
                            46.95011111170425
                        ],
                        [
                            31.938219628749216,
                            46.95008496543647
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1023,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.93611917744494,
                            46.947960888654485
                        ],
                        [
                            31.935879330702544,
                            46.9470820624485
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1024,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.93544369070106,
                            46.94790074131328
                        ],
                        [
                            31.935287056093777,
                            46.94738280307831
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1025,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.936360838459212,
                            46.94756395185711
                        ],
                        [
                            31.934697228900763,
                            46.94778880024832
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1026,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.93508900662342,
                            46.94875431034073
                        ],
                        [
                            31.935040058308648,
                            46.94857220004963
                        ],
                        [
                            31.934937266847637,
                            46.948580553746275
                        ],
                        [
                            31.93436314770086,
                            46.94674111521379
                        ],
                        [
                            31.934379587891186,
                            46.94675795024281
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1027,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00610648559792,
                            46.85157204857501
                        ],
                        [
                            32.00597691959946,
                            46.851586548224184
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1028,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.934217726620567,
                            46.947075196562025
                        ],
                        [
                            31.934461106631197,
                            46.947046282157494
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1029,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.93488851703109,
                            46.94842347532245
                        ],
                        [
                            31.934592181228542,
                            46.94846026038891
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1030,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.935061788315334,
                            46.948653045766285
                        ],
                        [
                            31.93664311561729,
                            46.94845190667351
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1031,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.935769388198693,
                            46.94878438354017
                        ],
                        [
                            31.935717301092357,
                            46.948569667012194
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1032,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.936200133368644,
                            46.94827480760001
                        ],
                        [
                            31.936428775535877,
                            46.949009697052
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1033,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.93778850618286,
                            46.949185357918545
                        ],
                        [
                            31.937663687980198,
                            46.94936078326449
                        ],
                        [
                            31.93711076864799,
                            46.94942463760189
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1034,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.93505229538735,
                            46.9454728886302
                        ],
                        [
                            31.93483692280237,
                            46.945763613339
                        ],
                        [
                            31.93665780011172,
                            46.94640186549485
                        ],
                        [
                            31.936648334156658,
                            46.946404413521535
                        ],
                        [
                            31.936623673871157,
                            46.94639880180871
                        ],
                        [
                            31.936631893966325,
                            46.946393190095314
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1035,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.93546499086624,
                            46.94498897050939
                        ],
                        [
                            31.935538413338385,
                            46.9448987444631
                        ],
                        [
                            31.936585907274406,
                            46.94527468531941
                        ],
                        [
                            31.93706560075913,
                            46.94545680682939
                        ],
                        [
                            31.93772640300849,
                            46.945709102576366
                        ],
                        [
                            31.93789527469444,
                            46.945735835830874
                        ],
                        [
                            31.938849766832416,
                            46.94610842917588
                        ],
                        [
                            31.93884309956563,
                            46.9461126036751
                        ],
                        [
                            31.938834879470473,
                            46.94610699193169
                        ],
                        [
                            31.938834879470473,
                            46.94610699193169
                        ],
                        [
                            31.938826659375298,
                            46.94610699193169
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1036,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.93767584605224,
                            46.94623606188119
                        ],
                        [
                            31.93767584605224,
                            46.946241673611055
                        ],
                        [
                            31.93766032278356,
                            46.94622538619332
                        ],
                        [
                            31.938646631326122,
                            46.94658795132342
                        ],
                        [
                            31.93868089514647,
                            46.94654451046957
                        ],
                        [
                            31.939596387260384,
                            46.94687879010958
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1037,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00609235112536,
                            46.85152854960399
                        ],
                        [
                            32.00502284270174,
                            46.85167837924468
                        ],
                        [
                            32.00511265234211,
                            46.85200618806643
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1038,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.938996611776695,
                            46.947603788931175
                        ],
                        [
                            31.939914392678585,
                            46.94649438636366
                        ],
                        [
                            31.939944592317705,
                            46.94649420084655
                        ],
                        [
                            31.93993637222254,
                            46.946488589143144
                        ],
                        [
                            31.93992815212738,
                            46.94649981254936
                        ],
                        [
                            31.93993637222254,
                            46.94650542425159
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1039,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.93929520479969,
                            46.94766703394728
                        ],
                        [
                            31.93929520479969,
                            46.94766703394728
                        ],
                        [
                            31.940085558816794,
                            46.94797657811906
                        ],
                        [
                            31.940315615896193,
                            46.94769756079762
                        ],
                        [
                            31.9406502999984,
                            46.947814932033694
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1040,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.938919977071365,
                            46.94758603700032
                        ],
                        [
                            31.93837909819318,
                            46.948269377666335
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1041,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.939060703476333,
                            46.94764367854254
                        ],
                        [
                            31.938964030554665,
                            46.94777149217562
                        ],
                        [
                            31.940214659996975,
                            46.948229279677484
                        ],
                        [
                            31.9406502999984,
                            46.947814932033694
                        ],
                        [
                            31.94082773763944,
                            46.94758854315561
                        ],
                        [
                            31.941427354495342,
                            46.947292816020166
                        ],
                        [
                            31.941535040787834,
                            46.947204264639154
                        ],
                        [
                            31.941877618903916,
                            46.94681831544343
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1042,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.940214659996975,
                            46.948229279677484
                        ],
                        [
                            31.93949634347777,
                            46.949098898072215
                        ],
                        [
                            31.938926095610725,
                            46.94889172801715
                        ],
                        [
                            31.938327702462686,
                            46.949687824695786
                        ],
                        [
                            31.938148085092323,
                            46.94976694816487
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1043,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.952848757131513,
                            46.955355789647626
                        ],
                        [
                            31.953451202090992,
                            46.9545332753016
                        ],
                        [
                            31.953762023889762,
                            46.95427600946273
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1044,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.953027836016386,
                            46.95511129568336
                        ],
                        [
                            31.952029253546993,
                            46.954802233719874
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1045,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.952836900740646,
                            46.954103941360785
                        ],
                        [
                            31.952294521912886,
                            46.954884334636304
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1046,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.957174945136924,
                            46.959733455289054
                        ],
                        [
                            31.957267946934984,
                            46.95979693011088
                        ],
                        [
                            31.9574502794075,
                            46.959669145195406
                        ],
                        [
                            31.957695020981333,
                            46.959801106083376
                        ],
                        [
                            31.95776354862201,
                            46.95976435751435
                        ],
                        [
                            31.95800584278011,
                            46.95986959198554
                        ],
                        [
                            31.958267716264107,
                            46.959911351638965
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1047,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.956461523449185,
                            46.958858998434344
                        ],
                        [
                            31.957308329294673,
                            46.959493753638014
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1048,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.957133098234536,
                            46.95936240321735
                        ],
                        [
                            31.95730098704745,
                            46.95926490855105
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1049,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95673097158279,
                            46.95906097424443
                        ],
                        [
                            31.956906953113577,
                            46.958959223440814
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1050,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.956728291764676,
                            46.95926323814537
                        ],
                        [
                            31.957063485456374,
                            46.95931022249454
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1051,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.957244263223686,
                            46.95944573076573
                        ],
                        [
                            31.9571443524402,
                            46.95951713921222
                        ],
                        [
                            31.957026876484758,
                            46.95966413401602
                        ],
                        [
                            31.957110088619864,
                            46.95971257539699
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1052,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.957026876484758,
                            46.95966413401602
                        ],
                        [
                            31.956796819405348,
                            46.9595204800077
                        ],
                        [
                            31.956302441426192,
                            46.959502105629966
                        ],
                        [
                            31.956202097380924,
                            46.95939854083752
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1053,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.956302441426192,
                            46.959502105629966
                        ],
                        [
                            31.956173952099938,
                            46.95955889914074
                        ],
                        [
                            31.95609441108844,
                            46.95965828763949
                        ],
                        [
                            31.95585823546968,
                            46.95995645202806
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1054,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95595987825849,
                            46.9598281313655
                        ],
                        [
                            31.95540913468168,
                            46.95962905574727
                        ],
                        [
                            31.95541158209742,
                            46.95945867525756
                        ],
                        [
                            31.956023436032,
                            46.95919391626313
                        ],
                        [
                            31.95591330232377,
                            46.95907865799505
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1055,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.955546189963016,
                            46.95923734685046
                        ],
                        [
                            31.955660690402965,
                            46.95935088226317
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1056,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.9554572037564,
                            46.9596464311601
                        ],
                        [
                            31.955372423445596,
                            46.95976686309942
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1057,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95541158209742,
                            46.95945867525756
                        ],
                        [
                            31.955196209512433,
                            46.95945616965809
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1058,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.948202037872154,
                            46.95761720567585
                        ],
                        [
                            31.948983987200577,
                            46.95998920219017
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1059,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94823589694437,
                            46.95771991732413
                        ],
                        [
                            31.948843260795606,
                            46.9576138647615
                        ],
                        [
                            31.94943064057281,
                            46.95782267150543
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1060,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.948843260795606,
                            46.95761386476149
                        ],
                        [
                            31.94888486686316,
                            46.95783436465901
                        ],
                        [
                            31.949090449785174,
                            46.958452427710384
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1061,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95108754102769,
                            46.95808660206386
                        ],
                        [
                            31.952284327323753,
                            46.95850254061787
                        ],
                        [
                            31.952394461031982,
                            46.958913464688905
                        ],
                        [
                            31.952578017212367,
                            46.95930099994564
                        ],
                        [
                            31.951765475187226,
                            46.95957828638077
                        ],
                        [
                            31.95183889765937,
                            46.95977205195239
                        ],
                        [
                            31.951204728367586,
                            46.96028193396454
                        ],
                        [
                            31.951291900241856,
                            46.960390927806664
                        ],
                        [
                            31.951415494736644,
                            46.96034582778381
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1062,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.950150180799902,
                            46.95829039565495
                        ],
                        [
                            31.950315340899134,
                            46.958118755656166
                        ],
                        [
                            31.950938248667658,
                            46.95839229215944
                        ],
                        [
                            31.951175647994276,
                            46.95869463966178
                        ],
                        [
                            31.951307808444152,
                            46.95851423362286
                        ],
                        [
                            31.951900083052834,
                            46.95878150161171
                        ],
                        [
                            31.952455646425452,
                            46.959456347342346
                        ],
                        [
                            31.952617175864187,
                            46.95940289452555
                        ],
                        [
                            31.952773810471438,
                            46.95958997915068
                        ],
                        [
                            31.953062605528572,
                            46.95948474412954
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1063,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.952076296985997,
                            46.95974365531769
                        ],
                        [
                            31.952343065301488,
                            46.9600510063275
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1064,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95222943344276,
                            46.95992008819774
                        ],
                        [
                            31.95193679428891,
                            46.96003597289525
                        ],
                        [
                            31.952029796086975,
                            46.96016125136816
                        ],
                        [
                            31.951266202376605,
                            46.96044521482077
                        ],
                        [
                            31.951089988443435,
                            46.96021470342733
                        ],
                        [
                            31.951036145297195,
                            46.96013452531856
                        ],
                        [
                            31.9507497976558,
                            46.959875616021876
                        ],
                        [
                            31.950676069256676,
                            46.95984554905461
                        ],
                        [
                            31.950619778694684,
                            46.959665982092794
                        ],
                        [
                            31.949591864084567,
                            46.95980796532178
                        ],
                        [
                            31.949643259815076,
                            46.95995997047872
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1065,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.950793743674467,
                            46.95991535113559
                        ],
                        [
                            31.95100677630833,
                            46.95988062718144
                        ],
                        [
                            31.95095538057782,
                            46.95974699609901
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1066,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.951204728367586,
                            46.96028193396454
                        ],
                        [
                            31.951015342263425,
                            46.960370883356745
                        ],
                        [
                            31.95067759889152,
                            46.960506183247915
                        ],
                        [
                            31.950741231700725,
                            46.96057800897702
                        ],
                        [
                            31.95061151866659,
                            46.96063313098282
                        ],
                        [
                            31.95087094473486,
                            46.96093212511564
                        ],
                        [
                            31.95118421394938,
                            46.960819376127716
                        ],
                        [
                            31.95133840114089,
                            46.96098724675661
                        ],
                        [
                            31.95179637381092,
                            46.96149753682723
                        ],
                        [
                            31.95200929898016,
                            46.96221911709769
                        ],
                        [
                            31.952804709095133,
                            46.96278368006022
                        ],
                        [
                            31.95284509145482,
                            46.96281291022891
                        ],
                        [
                            31.952481038363732,
                            46.96303130084096
                        ],
                        [
                            31.952501535470546,
                            46.96304800371914
                        ],
                        [
                            31.952598208392203,
                            46.96298756015402
                        ],
                        [
                            31.952842949966044,
                            46.963142896846826
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1067,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.950366777092743,
                            46.96045690740124
                        ],
                        [
                            31.95052463540787,
                            46.96039092780666
                        ],
                        [
                            31.950647006194796,
                            46.96053123874576
                        ],
                        [
                            31.950702232979044,
                            46.96053398906142
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1068,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.950702232979044,
                            46.96053398906142
                        ],
                        [
                            31.949889531023757,
                            46.96083190380478
                        ],
                        [
                            31.94969373776468,
                            46.96028235361316
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1069,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95019307465755,
                            46.960720633042556
                        ],
                        [
                            31.950307121334124,
                            46.96083607969646
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1070,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95125270704257,
                            46.96042756115395
                        ],
                        [
                            31.950981384370028,
                            46.96053583225242
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1071,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.951036145297195,
                            46.96013452531856
                        ],
                        [
                            31.950754386560305,
                            46.96022973680935
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1072,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.950363870640757,
                            46.959701330056205
                        ],
                        [
                            31.950264903412624,
                            46.95940540012755
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1073,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.9502245948586,
                            46.959720567874875
                        ],
                        [
                            31.950274693075574,
                            46.95985640657252
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1074,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.949869911837776,
                            46.95976955933044
                        ],
                        [
                            31.94980234183807,
                            46.95957243999488
                        ],
                        [
                            31.949604101163256,
                            46.95959916632526
                        ],
                        [
                            31.94931530610613,
                            46.958753939662124
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1075,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.949987523742255,
                            46.95975331390484
                        ],
                        [
                            31.949863527231518,
                            46.95941709293524
                        ],
                        [
                            31.949946739366627,
                            46.95907800047485
                        ],
                        [
                            31.950196375771945,
                            46.95903958099651
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1076,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95040379425578,
                            46.96114175408387
                        ],
                        [
                            31.95047966414368,
                            46.96122694171672
                        ],
                        [
                            31.951473314933462,
                            46.960846101835244
                        ],
                        [
                            31.95139744504556,
                            46.960757572878
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1077,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95250591725638,
                            46.96257160550046
                        ],
                        [
                            31.952678914497948,
                            46.962458625998984
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1078,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95200929898016,
                            46.96221911709769
                        ],
                        [
                            31.95306927730822,
                            46.961590895016684
                        ],
                        [
                            31.952660558879913,
                            46.96113990319249
                        ],
                        [
                            31.952756008093708,
                            46.96094280186812
                        ],
                        [
                            31.952643426969743,
                            46.96081418451071
                        ],
                        [
                            31.952680138205828,
                            46.96079998647178
                        ],
                        [
                            31.95240847505887,
                            46.96047760645633
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1079,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.952643426969736,
                            46.96081418451071
                        ],
                        [
                            31.95209398213648,
                            46.96101963806396
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1080,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95353306259064,
                            46.96097453857109
                        ],
                        [
                            31.952756445847697,
                            46.96124570800829
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1081,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95306927730822,
                            46.96159089501668
                        ],
                        [
                            31.953200214050227,
                            46.9616760819344
                        ],
                        [
                            31.95360893247854,
                            46.96157419168358
                        ],
                        [
                            31.953870805962545,
                            46.961774631336645
                        ],
                        [
                            31.95424770798626,
                            46.961898235415035
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1082,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.955349045068512,
                            46.9626749301833
                        ],
                        [
                            31.95502353877532,
                            46.962431065943555
                        ],
                        [
                            31.95453405562764,
                            46.962552998202376
                        ],
                        [
                            31.95337642798339,
                            46.96331298067478
                        ],
                        [
                            31.953648091130347,
                            46.96351508408641
                        ],
                        [
                            31.953214898544655,
                            46.963762283915486
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1083,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.953749442649276,
                            46.96306809775003
                        ],
                        [
                            31.95468824281916,
                            46.96332133207054
                        ],
                        [
                            31.95550812709152,
                            46.96388755366305
                        ],
                        [
                            31.95574063158667,
                            46.9637384826303
                        ],
                        [
                            31.95585627198031,
                            46.96381949046994
                        ],
                        [
                            31.956227667318597,
                            46.963573543608575
                        ],
                        [
                            31.956376959678643,
                            46.96367041924753
                        ],
                        [
                            31.956780783275466,
                            46.96341820816619
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1084,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.953648091130347,
                            46.9635150840864
                        ],
                        [
                            31.953876924501884,
                            46.963678353020505
                        ],
                        [
                            31.95437130248104,
                            46.963664990875834
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1085,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.952790271914047,
                            46.96321735710027
                        ],
                        [
                            31.954023769446195,
                            46.964060842996936
                        ],
                        [
                            31.95437619731252,
                            46.9638821253134
                        ],
                        [
                            31.955039446977622,
                            46.96431973204041
                        ],
                        [
                            31.95539921709116,
                            46.96413099370021
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1086,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.952693181537196,
                            46.963047839358424
                        ],
                        [
                            31.951932299734267,
                            46.96357390898038
                        ],
                        [
                            31.951526028721702,
                            46.96329998381248
                        ],
                        [
                            31.951396315687564,
                            46.96337514634573
                        ],
                        [
                            31.950928859281536,
                            46.9630644738578
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1087,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.951526028721702,
                            46.96329998381248
                        ],
                        [
                            31.951685110744695,
                            46.96314297729123
                        ],
                        [
                            31.95091172737136,
                            46.962620175659445
                        ],
                        [
                            31.95060580040407,
                            46.96255670418703
                        ],
                        [
                            31.950265609616434,
                            46.96152945804391
                        ],
                        [
                            31.950086948267536,
                            46.96155451306255
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1088,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.950374994612915,
                            46.96185976107306
                        ],
                        [
                            31.950561746920783,
                            46.96183345814434
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1089,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.950393482591583,
                            46.96191558786389
                        ],
                        [
                            31.950226450964614,
                            46.96193701822366
                        ],
                        [
                            31.95017505523412,
                            46.961790029664314
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1090,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.950537958379133,
                            46.96235184877839
                        ],
                        [
                            31.95037084849318,
                            46.96237965178708
                        ],
                        [
                            31.950317005346943,
                            46.96219591754495
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1091,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95093620152875,
                            46.96136910564671
                        ],
                        [
                            31.951435474339373,
                            46.96114694996894
                        ],
                        [
                            31.951704690070596,
                            46.96144093021738
                        ],
                        [
                            31.95173279609118,
                            46.96142669647369
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1092,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.952230123557595,
                            46.9633679957929
                        ],
                        [
                            31.952730157264977,
                            46.96373425476929
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1093,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.952188773814772,
                            46.96339658474645
                        ],
                        [
                            31.952446257039323,
                            46.96358393060625
                        ],
                        [
                            31.952179488723843,
                            46.96375095742798
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1094,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.951181009043314,
                            46.962802208724455
                        ],
                        [
                            31.951291076810815,
                            46.96273876689293
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1095,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94895501848852,
                            46.96208734701435
                        ],
                        [
                            31.949216891972522,
                            46.96198211690521
                        ],
                        [
                            31.949801824333992,
                            46.96259345083814
                        ],
                        [
                            31.949701480288724,
                            46.96268531735545
                        ],
                        [
                            31.950071040065215,
                            46.963131285297806
                        ],
                        [
                            31.94997803826716,
                            46.963266578208255
                        ],
                        [
                            31.9505482861342,
                            46.96397477255156
                        ],
                        [
                            31.95179402074503,
                            46.964459145359115
                        ],
                        [
                            31.951483198946253,
                            46.96483828928982
                        ],
                        [
                            31.951688781868274,
                            46.96497691833808
                        ],
                        [
                            31.95204365715034,
                            46.96545961185959
                        ],
                        [
                            31.952185607263175,
                            46.965409505468266
                        ],
                        [
                            31.952369163443546,
                            46.96562997323907
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1096,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.951505995228256,
                            46.96481048221961
                        ],
                        [
                            31.952086970125748,
                            46.96504012870295
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1097,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.951595966961783,
                            46.96484604611363
                        ],
                        [
                            31.951751674169586,
                            46.96465848084693
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1098,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.952428384621225,
                            46.965704874265754
                        ],
                        [
                            31.95232559316022,
                            46.96574161875444
                        ],
                        [
                            31.952288881924147,
                            46.966119083404
                        ],
                        [
                            31.95336329743329,
                            46.96652493844058
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1099,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95240146304811,
                            46.965638066039865
                        ],
                        [
                            31.952883603948568,
                            46.96553117270488
                        ],
                        [
                            31.95326784821949,
                            46.965020085994865
                        ],
                        [
                            31.953926203053125,
                            46.965238885151464
                        ],
                        [
                            31.954936985753076,
                            46.965624704384666
                        ],
                        [
                            31.95446708193129,
                            46.966222635187414
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1100,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.952883603948568,
                            46.96553117270488
                        ],
                        [
                            31.953331481028695,
                            46.96568817221709
                        ],
                        [
                            31.953444062152666,
                            46.96553952375435
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1101,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.953331481028695,
                            46.96568817221709
                        ],
                        [
                            31.953140582601094,
                            46.965932021609696
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1102,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95176529748488,
                            46.96508099355832
                        ],
                        [
                            31.95160482922527,
                            46.96513366124131
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1103,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94997803826716,
                            46.96326657820825
                        ],
                        [
                            31.947963074606566,
                            46.96367553718
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1104,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.948601850114272,
                            46.963331460872766
                        ],
                        [
                            31.948862275091575,
                            46.96349303496687
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1105,
                id: 8,
                district: 8,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.957993734469742,
                            46.95681301855959
                        ],
                        [
                            31.95803289312157,
                            46.95676958600444
                        ],
                        [
                            31.958280082111166,
                            46.95675288116616
                        ],
                        [
                            31.958358399414813,
                            46.956664345436174
                        ],
                        [
                            31.958578666831293,
                            46.956652652026925
                        ],
                        [
                            31.958661878966414,
                            46.956567457110964
                        ],
                        [
                            31.958807954081234,
                            46.95651699147883
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1106,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.990899135980367,
                            46.94950926867229
                        ],
                        [
                            31.990499909672703,
                            46.94955096825965
                        ],
                        [
                            31.990535490579813,
                            46.949789229007024
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1107,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.990810385468155,
                            46.94975511009146
                        ],
                        [
                            31.990286576610792,
                            46.94982012325023
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1108,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.990499909672703,
                            46.94955096825965
                        ],
                        [
                            31.990381814584854,
                            46.94887222357756
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1109,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.990219910028944,
                            46.949370230574026
                        ],
                        [
                            31.990732290329422,
                            46.949323420714215
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1110,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.991556921582642,
                            46.94860371798775
                        ],
                        [
                            31.99168657482956,
                            46.94923890280808
                        ],
                        [
                            31.99200276490346,
                            46.94921029671731
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1111,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99158471106236,
                            46.94873986219075
                        ],
                        [
                            31.99127514678159,
                            46.94877080121921
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1112,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.991256099186785,
                            46.94924800474284
                        ],
                        [
                            31.99157419402016,
                            46.948967144328535
                        ],
                        [
                            31.99340466788172,
                            46.94888262585984
                        ],
                        [
                            31.99342562023601,
                            46.949042560695645
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1113,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.992711335430517,
                            46.94906466547276
                        ],
                        [
                            31.992689532054133,
                            46.948915645841936
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1114,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.991959768156796,
                            46.94894934122522
                        ],
                        [
                            31.991945622119015,
                            46.948816311275564
                        ],
                        [
                            31.992717049708958,
                            46.948747396032324
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1115,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.994699841723477,
                            46.94899285844139
                        ],
                        [
                            31.99482062154301,
                            46.94852884729228
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1116,
                id: 0,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99392085770116,
                            46.94884621786296
                        ],
                        [
                            31.995177998958845,
                            46.94908286939998
                        ],
                        [
                            31.9952122846295,
                            46.948942438943796
                        ],
                        [
                            31.99565799834814,
                            46.94897624630951
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1117,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.998213233192356,
                            46.94966831740141
                        ],
                        [
                            31.99827609025523,
                            46.94938095782777
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1118,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99823953546783,
                            46.949548073175514
                        ],
                        [
                            31.99855037562055,
                            46.949564296014444
                        ],
                        [
                            31.99857704225329,
                            46.94925613146899
                        ],
                        [
                            31.999142755819246,
                            46.94925743174506
                        ],
                        [
                            32.001089420009166,
                            46.9493081424872
                        ],
                        [
                            32.00185513332066,
                            46.94930424166259
                        ],
                        [
                            32.0023198946341,
                            46.94930034083769
                        ],
                        [
                            32.002384656456464,
                            46.94975153436542
                        ],
                        [
                            32.002702751289846,
                            46.949738531723334
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1119,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0016970382837,
                            46.949769738059054
                        ],
                        [
                            32.00168370496734,
                            46.94949148092329
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1120,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.001692125504114,
                            46.949667211898706
                        ],
                        [
                            32.00246846587364,
                            46.949657915271814
                        ],
                        [
                            32.003940844952716,
                            46.94956689655159
                        ],
                        [
                            32.00489893897182,
                            46.94949278119364
                        ],
                        [
                            32.00490846276922,
                            46.94970992589959
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1121,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.003971321104416,
                            46.949683920592015
                        ],
                        [
                            32.00396948032055,
                            46.94956468140457
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1122,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00325513153943,
                            46.94971252642965
                        ],
                        [
                            32.00324578790133,
                            46.949609863225454
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1123,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00232915190482,
                            46.94936483617724
                        ],
                        [
                            32.0023290594802,
                            46.94936485695984
                        ],
                        [
                            32.00232896743235,
                            46.94936487935223
                        ],
                        [
                            32.002328875789296,
                            46.94936490334758
                        ],
                        [
                            32.00232878457895,
                            46.94936492893858
                        ],
                        [
                            32.0023286938291,
                            46.949364956117435
                        ],
                        [
                            32.00232860356738,
                            46.94936498487587
                        ],
                        [
                            32.002328513821276,
                            46.949365015205125
                        ],
                        [
                            32.002328424618135,
                            46.94936504709596
                        ],
                        [
                            32.00232833598511,
                            46.94936508053866
                        ],
                        [
                            32.00232824794922,
                            46.949365115523044
                        ],
                        [
                            32.00232816053726,
                            46.94936515203845
                        ],
                        [
                            32.00232807377586,
                            46.94936519007377
                        ],
                        [
                            32.002327987691444,
                            46.94936522961741
                        ],
                        [
                            32.00232790231023,
                            46.949365270657324
                        ],
                        [
                            32.00232781765823,
                            46.94936531318102
                        ],
                        [
                            32.00232773376121,
                            46.94936535717553
                        ],
                        [
                            32.002327650644744,
                            46.94936540262747
                        ],
                        [
                            32.00232756833414,
                            46.949365449523
                        ],
                        [
                            32.00232748685446,
                            46.94936549784782
                        ],
                        [
                            32.00232740623053,
                            46.94936554758722
                        ],
                        [
                            32.002327326486906,
                            46.949365598726054
                        ],
                        [
                            32.00232724764787,
                            46.94936565124874
                        ],
                        [
                            32.002327169737434,
                            46.949365705139286
                        ],
                        [
                            32.00232709277934,
                            46.94936576038127
                        ],
                        [
                            32.00232701679701,
                            46.94936581695788
                        ],
                        [
                            32.0023269418136,
                            46.94936587485187
                        ],
                        [
                            32.00232686785195,
                            46.949365934045616
                        ],
                        [
                            32.00232679493457,
                            46.94936599452108
                        ],
                        [
                            32.002326723083684,
                            46.949366056259855
                        ],
                        [
                            32.00232665232117,
                            46.94936611924313
                        ],
                        [
                            32.00232658266859,
                            46.94936618345172
                        ],
                        [
                            32.00232651414714,
                            46.94936624886608
                        ],
                        [
                            32.00232644677771,
                            46.94936631546627
                        ],
                        [
                            32.002326380580804,
                            46.94936638323201
                        ],
                        [
                            32.00232631557659,
                            46.94936645214267
                        ],
                        [
                            32.002326251784865,
                            46.94936652217725
                        ],
                        [
                            32.002326189225066,
                            46.94936659331442
                        ],
                        [
                            32.00232612791624,
                            46.94936666553252
                        ],
                        [
                            32.002326067877064,
                            46.94936673880955
                        ],
                        [
                            32.00232600912582,
                            46.94936681312319
                        ],
                        [
                            32.0023259516804,
                            46.94936688845081
                        ],
                        [
                            32.00232589555831,
                            46.94936696476947
                        ],
                        [
                            32.002325840776635,
                            46.94936704205591
                        ],
                        [
                            32.00232578735206,
                            46.9493671202866
                        ],
                        [
                            32.00232573530087,
                            46.949367199437724
                        ],
                        [
                            32.002325684638905,
                            46.94936727948515
                        ],
                        [
                            32.0023256353816,
                            46.94936736040452
                        ],
                        [
                            32.00232558754396,
                            46.94936744217118
                        ],
                        [
                            32.00232554114055,
                            46.94936752476022
                        ],
                        [
                            32.002325496185506,
                            46.94936760814649
                        ],
                        [
                            32.00232545269252,
                            46.94936769230459
                        ],
                        [
                            32.002325410674835,
                            46.94936777720889
                        ],
                        [
                            32.00232537014526,
                            46.94936786283353
                        ],
                        [
                            32.002325331116126,
                            46.94936794915244
                        ],
                        [
                            32.002325293599334,
                            46.94936803613931
                        ],
                        [
                            32.002325257606294,
                            46.94936812376766
                        ],
                        [
                            32.002325223147984,
                            46.949368212010796
                        ],
                        [
                            32.00232519023489,
                            46.94936830084184
                        ],
                        [
                            32.00232515887704,
                            46.94936839023374
                        ],
                        [
                            32.002325129083985,
                            46.94936848015926
                        ],
                        [
                            32.002325100864795,
                            46.94936857059103
                        ],
                        [
                            32.002325074228075,
                            46.94936866150148
                        ],
                        [
                            32.00232504918193,
                            46.94936875286294
                        ],
                        [
                            32.00232502573399,
                            46.94936884464758
                        ],
                        [
                            32.00232500389139,
                            46.94936893682745
                        ],
                        [
                            32.00232498366079,
                            46.94936902937445
                        ],
                        [
                            32.00232496504836,
                            46.94936912226042
                        ],
                        [
                            32.00232494805975,
                            46.94936921545706
                        ],
                        [
                            32.002324932700155,
                            46.949369308935985
                        ],
                        [
                            32.00232491897423,
                            46.949369402668715
                        ],
                        [
                            32.00232490688618,
                            46.949369496626716
                        ],
                        [
                            32.00232489643967,
                            46.94936959078135
                        ],
                        [
                            32.00232488763789,
                            46.94936968510397
                        ],
                        [
                            32.00232488048351,
                            46.949369779565814
                        ],
                        [
                            32.00232487497872,
                            46.949369874138135
                        ],
                        [
                            32.0023248711252,
                            46.94936996879211
                        ],
                        [
                            32.002324868924106,
                            46.94937006349893
                        ],
                        [
                            32.00232486837612,
                            46.94937015822974
                        ],
                        [
                            32.00232486948141,
                            46.94937025295568
                        ],
                        [
                            32.00232487223965,
                            46.949370347647914
                        ],
                        [
                            32.00232487664997,
                            46.94937044227758
                        ],
                        [
                            32.00232488271105,
                            46.949370536815884
                        ],
                        [
                            32.00232489042104,
                            46.949370631234004
                        ],
                        [
                            32.00232489977759,
                            46.9493707255032
                        ],
                        [
                            32.00232491077785,
                            46.949370819594755
                        ],
                        [
                            32.00232492341846,
                            46.94937091348001
                        ],
                        [
                            32.0023249376956,
                            46.94937100713037
                        ],
                        [
                            32.002324953604884,
                            46.94937110051731
                        ],
                        [
                            32.00232497114148,
                            46.949371193612386
                        ],
                        [
                            32.00232499030007,
                            46.94937128638725
                        ],
                        [
                            32.00232501107478,
                            46.94937137881364
                        ],
                        [
                            32.002325033459314,
                            46.9493714708634
                        ],
                        [
                            32.00232505744683,
                            46.9493715625085
                        ],
                        [
                            32.002325083030044,
                            46.94937165372103
                        ],
                        [
                            32.00232511020115,
                            46.949371744473204
                        ],
                        [
                            32.002325138951875,
                            46.949371834737384
                        ],
                        [
                            32.00232516927346,
                            46.94937192448607
                        ],
                        [
                            32.00232520115668,
                            46.94937201369194
                        ],
                        [
                            32.002325234591815,
                            46.949372102327814
                        ],
                        [
                            32.00232526956868,
                            46.949372190366695
                        ],
                        [
                            32.00232530607663,
                            46.94937227778178
                        ],
                        [
                            32.002325344104534,
                            46.94937236454643
                        ],
                        [
                            32.00232538364082,
                            46.94937245063422
                        ],
                        [
                            32.002325424673444,
                            46.949372536018934
                        ],
                        [
                            32.002325467189905,
                            46.94937262067457
                        ],
                        [
                            32.00232551117726,
                            46.94937270457534
                        ],
                        [
                            32.002325556622104,
                            46.94937278769569
                        ],
                        [
                            32.0023256035106,
                            46.9493728700103
                        ],
                        [
                            32.00232565182846,
                            46.949372951494105
                        ],
                        [
                            32.00232570156098,
                            46.94937303212228
                        ],
                        [
                            32.002325752693,
                            46.94937311187027
                        ],
                        [
                            32.00232580520895,
                            46.94937319071379
                        ],
                        [
                            32.00232585909284,
                            46.949373268628825
                        ],
                        [
                            32.00232591432826,
                            46.949373345591646
                        ],
                        [
                            32.002325970898376,
                            46.9493734215788
                        ],
                        [
                            32.002326028785966,
                            46.949373496567155
                        ],
                        [
                            32.002326087973394,
                            46.949373570533865
                        ],
                        [
                            32.002326148442634,
                            46.949373643456404
                        ],
                        [
                            32.002326210175276,
                            46.94937371531256
                        ],
                        [
                            32.002326273152505,
                            46.94937378608045
                        ],
                        [
                            32.00232633735515,
                            46.94937385573852
                        ],
                        [
                            32.00232640276365,
                            46.94937392426555
                        ],
                        [
                            32.00232646935809,
                            46.94937399164068
                        ],
                        [
                            32.00232653711818,
                            46.949374057843364
                        ],
                        [
                            32.00232660602328,
                            46.94937412285346
                        ],
                        [
                            32.00232667605241,
                            46.94937418665116
                        ],
                        [
                            32.00232674718424,
                            46.949374249217044
                        ],
                        [
                            32.002326819397105,
                            46.949374310532036
                        ],
                        [
                            32.00232689266901,
                            46.94937437057747
                        ],
                        [
                            32.002326966977634,
                            46.949374429335066
                        ],
                        [
                            32.00232704230035,
                            46.94937448678691
                        ],
                        [
                            32.00232711861421,
                            46.94937454291552
                        ],
                        [
                            32.00232719589597,
                            46.949374597703795
                        ],
                        [
                            32.00232727412211,
                            46.949374651135045
                        ],
                        [
                            32.002327353268775,
                            46.949374703192994
                        ],
                        [
                            32.00232743331188,
                            46.9493747538618
                        ],
                        [
                            32.00232751422705,
                            46.94937480312601
                        ],
                        [
                            32.00232759598961,
                            46.949374850970635
                        ],
                        [
                            32.002327678574694,
                            46.9493748973811
                        ],
                        [
                            32.002327761957126,
                            46.94937494234326
                        ],
                        [
                            32.00232784611151,
                            46.949374985843434
                        ],
                        [
                            32.002327931012225,
                            46.94937502786837
                        ],
                        [
                            32.002328016633406,
                            46.94937506840526
                        ],
                        [
                            32.00232810294898,
                            46.94937510744176
                        ],
                        [
                            32.00232818993265,
                            46.949375144965984
                        ],
                        [
                            32.00232827755792,
                            46.949375180966506
                        ],
                        [
                            32.002328365798114,
                            46.949375215432354
                        ],
                        [
                            32.002328454626344,
                            46.949375248353036
                        ],
                        [
                            32.00232854401556,
                            46.94937527971852
                        ],
                        [
                            32.002328633938546,
                            46.94937530951925
                        ],
                        [
                            32.0023287243679,
                            46.94937533774616
                        ],
                        [
                            32.00232881527608,
                            46.94937536439065
                        ],
                        [
                            32.0023289066354,
                            46.9493753894446
                        ],
                        [
                            32.00232899841804,
                            46.94937541290037
                        ],
                        [
                            32.002329090596035,
                            46.94937543475084
                        ],
                        [
                            32.00232918314132,
                            46.949375454989344
                        ],
                        [
                            32.0023292760257,
                            46.949375473609706
                        ],
                        [
                            32.00232936922088,
                            46.94937549060627
                        ],
                        [
                            32.00232946269849,
                            46.949375505973855
                        ],
                        [
                            32.002329556430055,
                            46.94937551970778
                        ],
                        [
                            32.00232965038702,
                            46.94937553180386
                        ],
                        [
                            32.002329744540766,
                            46.94937554225841
                        ],
                        [
                            32.00232983886262,
                            46.94937555106824
                        ],
                        [
                            32.002329933323864,
                            46.94937555823069
                        ],
                        [
                            32.00233002789571,
                            46.94937556374355
                        ],
                        [
                            32.00233012254937,
                            46.949375567605166
                        ],
                        [
                            32.002330217255995,
                            46.94937556981434
                        ],
                        [
                            32.00233031198675,
                            46.949375570370414
                        ],
                        [
                            32.002330406712794,
                            46.94937556927321
                        ],
                        [
                            32.002330501405254,
                            46.94937556652307
                        ],
                        [
                            32.00233059603531,
                            46.94937556212082
                        ],
                        [
                            32.00233069057412,
                            46.949375556067814
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1124,
                id: 4,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00437475370083,
                            46.94953333064953
                        ],
                        [
                            32.00437496275986,
                            46.949533523795914
                        ],
                        [
                            32.0043751751341,
                            46.949533713291004
                        ],
                        [
                            32.004375390759776,
                            46.94953389907791
                        ],
                        [
                            32.00437560957216,
                            46.94953408110084
                        ],
                        [
                            32.00437583150554,
                            46.94953425930516
                        ],
                        [
                            32.0043760564933,
                            46.94953443363736
                        ],
                        [
                            32.00437628446788,
                            46.9495346040451
                        ],
                        [
                            32.00437651536084,
                            46.94953477047721
                        ],
                        [
                            32.00437674910285,
                            46.94953493288372
                        ],
                        [
                            32.00437698562374,
                            46.949535091215886
                        ],
                        [
                            32.004377224852504,
                            46.949535245426155
                        ],
                        [
                            32.0043774667173,
                            46.94953539546824
                        ],
                        [
                            32.004377711145516,
                            46.94953554129708
                        ],
                        [
                            32.00437795806378,
                            46.949535682868905
                        ],
                        [
                            32.004378207397934,
                            46.94953582014119
                        ],
                        [
                            32.00437845907314,
                            46.94953595307275
                        ],
                        [
                            32.00437871301382,
                            46.94953608162365
                        ],
                        [
                            32.00437896914375,
                            46.9495362057553
                        ],
                        [
                            32.00437922738602,
                            46.94953632543044
                        ],
                        [
                            32.00437948766309,
                            46.94953644061313
                        ],
                        [
                            32.00437974989682,
                            46.94953655126879
                        ],
                        [
                            32.00438001400848,
                            46.9495366573642
                        ],
                        [
                            32.00438027991878,
                            46.9495367588675
                        ],
                        [
                            32.004380547547875,
                            46.94953685574822
                        ],
                        [
                            32.00438081681541,
                            46.94953694797727
                        ],
                        [
                            32.00438108764055,
                            46.94953703552697
                        ],
                        [
                            32.00438135994198,
                            46.94953711837102
                        ],
                        [
                            32.00438163363795,
                            46.949537196484556
                        ],
                        [
                            32.00438190864628,
                            46.949537269844114
                        ],
                        [
                            32.0043821848844,
                            46.94953733842769
                        ],
                        [
                            32.004382462269376,
                            46.94953740221467
                        ],
                        [
                            32.004382740717936,
                            46.94953746118592
                        ],
                        [
                            32.00438302014646,
                            46.94953751532372
                        ],
                        [
                            32.004383300471076,
                            46.94953756461183
                        ],
                        [
                            32.00438358160761,
                            46.94953760903544
                        ],
                        [
                            32.00438386347165,
                            46.94953764858122
                        ],
                        [
                            32.00438414597858,
                            46.949537683237295
                        ],
                        [
                            32.00438442904356,
                            46.949537712993255
                        ],
                        [
                            32.00438471258163,
                            46.94953773784018
                        ],
                        [
                            32.00438499650765,
                            46.94953775777059
                        ],
                        [
                            32.00438528073638,
                            46.94953777277852
                        ],
                        [
                            32.004385565182474,
                            46.94953778285946
                        ],
                        [
                            32.00438584976054,
                            46.94953778801037
                        ],
                        [
                            32.00438613438513,
                            46.94953778822971
                        ],
                        [
                            32.00438641897079,
                            46.94953778351743
                        ],
                        [
                            32.00438670343208,
                            46.94953777387492
                        ],
                        [
                            32.0043869876836,
                            46.949537759305095
                        ],
                        [
                            32.004387271640006,
                            46.949537739812314
                        ],
                        [
                            32.004387555216034,
                            46.94953771540244
                        ],
                        [
                            32.00438783832655,
                            46.9495376860828
                        ],
                        [
                            32.00438812088655,
                            46.949537651862194
                        ],
                        [
                            32.004388402811216,
                            46.9495376127509
                        ],
                        [
                            32.00438868401588,
                            46.949537568760654
                        ],
                        [
                            32.00438896441613,
                            46.94953751990467
                        ],
                        [
                            32.00438924392777,
                            46.94953746619761
                        ],
                        [
                            32.00438952246689,
                            46.94953740765561
                        ],
                        [
                            32.00438979994985,
                            46.94953734429624
                        ],
                        [
                            32.004390076293355,
                            46.949537276138514
                        ],
                        [
                            32.00439035141442,
                            46.94953720320291
                        ],
                        [
                            32.00439062523046,
                            46.94953712551131
                        ],
                        [
                            32.004390897659256,
                            46.94953704308706
                        ],
                        [
                            32.004391168619016,
                            46.94953695595489
                        ],
                        [
                            32.004391438028385,
                            46.94953686414097
                        ],
                        [
                            32.00439170580648,
                            46.94953676767286
                        ],
                        [
                            32.00439197187291,
                            46.949536666579526
                        ],
                        [
                            32.00439223614779,
                            46.94953656089132
                        ],
                        [
                            32.00439249855176,
                            46.94953645063997
                        ],
                        [
                            32.00439275900605,
                            46.94953633585858
                        ],
                        [
                            32.00439301743247,
                            46.94953621658161
                        ],
                        [
                            32.004393273753415,
                            46.94953609284488
                        ],
                        [
                            32.004393527891935,
                            46.94953596468553
                        ],
                        [
                            32.004393779771725,
                            46.94953583214205
                        ],
                        [
                            32.00439402931717,
                            46.94953569525421
                        ],
                        [
                            32.00439427645333,
                            46.94953555406313
                        ],
                        [
                            32.00439452110603,
                            46.949535408611204
                        ],
                        [
                            32.0043947632018,
                            46.949535258942085
                        ],
                        [
                            32.004395002667955,
                            46.949535105100715
                        ],
                        [
                            32.004395239432604,
                            46.949534947133294
                        ],
                        [
                            32.00439547342466,
                            46.94953478508724
                        ],
                        [
                            32.00439570457387,
                            46.949534619011196
                        ],
                        [
                            32.00439593281082,
                            46.94953444895504
                        ],
                        [
                            32.00439615806701,
                            46.94953427496983
                        ],
                        [
                            32.0043963802748,
                            46.94953409710779
                        ],
                        [
                            32.00439659936747,
                            46.94953391542232
                        ],
                        [
                            32.00439681527924,
                            46.94953372996798
                        ],
                        [
                            32.004397027945295,
                            46.949533540800445
                        ],
                        [
                            32.004397237301774,
                            46.94953334797651
                        ],
                        [
                            32.00439744328583,
                            46.94953315155407
                        ],
                        [
                            32.00439764583562,
                            46.9495329515921
                        ],
                        [
                            32.00439784489031,
                            46.949532748150624
                        ],
                        [
                            32.004398040390164,
                            46.949532541290736
                        ],
                        [
                            32.00439823227647,
                            46.949532331074536
                        ],
                        [
                            32.004398420491626,
                            46.949532117565134
                        ],
                        [
                            32.004398604979116,
                            46.94953190082665
                        ],
                        [
                            32.00439878568355,
                            46.94953168092414
                        ],
                        [
                            32.004398962550674,
                            46.949531457923634
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1125,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00764111870486,
                            46.94901659822645
                        ],
                        [
                            32.00830721528242,
                            46.94898340769971
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1126,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00641588996365,
                            46.949122807773726
                        ],
                        [
                            32.00609985874071,
                            46.949142722040385
                        ],
                        [
                            32.006172789022926,
                            46.949653852348796
                        ],
                        [
                            32.00720839903038,
                            46.949574195998416
                        ],
                        [
                            32.00747581006517,
                            46.94949785855141
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1127,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00704309039068,
                            46.949078000646665
                        ],
                        [
                            32.00705767644713,
                            46.9492008052758
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1128,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.007964141479405,
                            46.94900050252098
                        ],
                        [
                            32.00797902901245,
                            46.94912446729623
                        ],
                        [
                            32.00636970078491,
                            46.94926054796646
                        ],
                        [
                            32.00594913615747,
                            46.94929207880409
                        ],
                        [
                            32.005501830426546,
                            46.949313652524395
                        ],
                        [
                            32.00531707371161,
                            46.94932195010683
                        ],
                        [
                            32.00521618682121,
                            46.94932775841374
                        ],
                        [
                            32.00523138063,
                            46.949496199040496
                        ],
                        [
                            32.00524110466763,
                            46.94962356635456
                        ],
                        [
                            32.005304918664564,
                            46.95022720862311
                        ],
                        [
                            32.00533773729156,
                            46.95039149771143
                        ],
                        [
                            32.00539972803144,
                            46.950717585165584
                        ],
                        [
                            32.005428900144324,
                            46.95091423236467
                        ],
                        [
                            32.005469011799555,
                            46.951117101819165
                        ],
                        [
                            32.00519187672713,
                            46.95113452615363
                        ],
                        [
                            32.00521010929769,
                            46.95131374755034
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1129,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00533773729156,
                            46.95039149771143
                        ],
                        [
                            32.0057899050413,
                            46.95028943939771
                        ],
                        [
                            32.00669059402665,
                            46.95023965478383
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1130,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0057899050413,
                            46.95028943939771
                        ],
                        [
                            32.005811784125946,
                            46.950605570615274
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1131,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00519187672713,
                            46.95113452615363
                        ],
                        [
                            32.005180937184804,
                            46.95102002328043
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1132,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01071879872297,
                            46.95524879815863
                        ],
                        [
                            32.01074797083586,
                            46.95563459159372
                        ],
                        [
                            32.01088653837207,
                            46.95563210261273
                        ],
                        [
                            32.0109011244285,
                            46.95602038224747
                        ],
                        [
                            32.01113085481749,
                            46.956017893284425
                        ],
                        [
                            32.01118919904325,
                            46.956592840672556
                        ],
                        [
                            32.01126577583959,
                            46.95738182768226
                        ],
                        [
                            32.01139705034757,
                            46.957374360982755
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1133,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0110323989365,
                            46.956889023279814
                        ],
                        [
                            32.011217402642274,
                            46.9568834297016
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1134,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01126022813786,
                            46.95732466892308
                        ],
                        [
                            32.01121654789909,
                            46.957327071861684
                        ],
                        [
                            32.01124572001198,
                            46.95761578373906
                        ],
                        [
                            32.01165595284943,
                            46.95758840595548
                        ],
                        [
                            32.01252539477103,
                            46.957528689356415
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1135,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01117644409907,
                            46.95646714886736
                        ],
                        [
                            32.01130771075187,
                            46.95646217135407
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1136,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.985221232156423,
                            46.95351636454739
                        ],
                        [
                            31.985224537548508,
                            46.95373521864895
                        ],
                        [
                            31.986940036039233,
                            46.95384802969194
                        ],
                        [
                            31.986983006136306,
                            46.95363143227937
                        ],
                        [
                            31.988196085030523,
                            46.95372619375524
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1137,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.986618891826673,
                            46.954916445251754
                        ],
                        [
                            31.987389033765044,
                            46.95498864168558
                        ],
                        [
                            31.987392339157125,
                            46.95494803060309
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1138,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.986558309477083,
                            46.95529765684767
                        ],
                        [
                            31.985937981057713,
                            46.955252614037924
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1139,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.984856647057125,
                            46.95629406827292
                        ],
                        [
                            31.98490000123199,
                            46.95604593922273
                        ],
                        [
                            31.9853263968106,
                            46.956075268850306
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1140,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98301210461968,
                            46.9564093834834
                        ],
                        [
                            31.98305672741279,
                            46.95606983777702
                        ],
                        [
                            31.9832418293694,
                            46.955933342052035
                        ],
                        [
                            31.984291291355515,
                            46.956004410117586
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1141,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.983847955642464,
                            46.956486373198594
                        ],
                        [
                            31.983821512505813,
                            46.957193658145954
                        ],
                        [
                            31.985241178405136,
                            46.9572207310682
                        ],
                        [
                            31.985209777180355,
                            46.95756590962636
                        ],
                        [
                            31.98567749015999,
                            46.95759185432997
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1142,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.994044618698002,
                            46.958157909389456
                        ],
                        [
                            31.99410711106806,
                            46.95781527767267
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1143,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99047156593508,
                            46.95829571140383
                        ],
                        [
                            31.990533640562024,
                            46.957915593183664
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1144,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.990271472804785,
                            46.957898827587535
                        ],
                        [
                            31.990213355702735,
                            46.95832758392804
                        ],
                        [
                            31.990095238256032,
                            46.95912814051481
                        ],
                        [
                            31.99000458998298,
                            46.95979182471621
                        ],
                        [
                            31.99000184306562,
                            46.95981057273794
                        ],
                        [
                            31.989641996890768,
                            46.95983307035534
                        ],
                        [
                            31.989350823650042,
                            46.95985369316296
                        ],
                        [
                            31.989153045599735,
                            46.95982182154781
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1145,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.990098839601043,
                            46.95910373210061
                        ],
                        [
                            31.989437351547046,
                            46.959060646745016
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1146,
                id: 4,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98878213225776,
                            46.95780336728207
                        ],
                        [
                            31.988742381453626,
                            46.9580857272403
                        ],
                        [
                            31.988088615120677,
                            46.95805572943538
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1147,
                id: 4,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.988742381453626,
                            46.9580857272403
                        ],
                        [
                            31.989698308696745,
                            46.95817572055414
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1148,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.987080496447614,
                            46.95813447363743
                        ],
                        [
                            31.987148736479487,
                            46.957689267095255
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1149,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.986629942826006,
                            46.95765302692818
                        ],
                        [
                            31.986651977338624,
                            46.95749701724529
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1150,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98567749015999,
                            46.95759185432997
                        ],
                        [
                            31.985723519269154,
                            46.95721765896254
                        ],
                        [
                            31.985734506938613,
                            46.95665893802165
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1151,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98567749015999,
                            46.95759185432997
                        ],
                        [
                            31.985671327839206,
                            46.95765450717792
                        ],
                        [
                            31.985841636715858,
                            46.95766481900257
                        ],
                        [
                            31.98578669836855,
                            46.95843163821316
                        ],
                        [
                            31.98591305656735,
                            46.958439137613276
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1152,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.983781448691893,
                            46.95998774121885
                        ],
                        [
                            31.98406987501525,
                            46.959908999744215
                        ],
                        [
                            31.984176412218925,
                            46.960112265569094
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1153,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.982776948344696,
                            46.960467703359065
                        ],
                        [
                            31.982917543180506,
                            46.96067859895027
                        ],
                        [
                            31.9827280058823,
                            46.96072734298519
                        ],
                        [
                            31.982890074006853,
                            46.9610104324639
                        ],
                        [
                            31.983049395214042,
                            46.96109667134522
                        ],
                        [
                            31.983219704090686,
                            46.96140038108124
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1154,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.981906144629466,
                            46.9606888701115
                        ],
                        [
                            31.982225320004442,
                            46.961291645694864
                        ],
                        [
                            31.982412110385283,
                            46.96161222697517
                        ],
                        [
                            31.98282140107272,
                            46.96153161314979
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1155,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.982309390844094,
                            46.96143593354145
                        ],
                        [
                            31.98206050496253,
                            46.96149224356303
                        ],
                        [
                            31.98196161593738,
                            46.96132351643445
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1156,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.985054597858326,
                            46.960558181810214
                        ],
                        [
                            31.985371913846397,
                            46.96048643453385
                        ],
                        [
                            31.985424105276334,
                            46.96040581901218
                        ],
                        [
                            31.985924044236825,
                            46.960377697290006
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1157,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.985602869037475,
                            46.961531350412606
                        ],
                        [
                            31.986050402435627,
                            46.96140694269231
                        ],
                        [
                            31.98613830379132,
                            46.961549423191435
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1158,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97719081544428,
                            46.95746601929
                        ],
                        [
                            31.977512819253622,
                            46.957688161324455
                        ],
                        [
                            31.978652789960226,
                            46.95767878694257
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1159,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97580492338174,
                            46.95763660220378
                        ],
                        [
                            31.97676660545978,
                            46.958581654913004
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1160,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.976236318169843,
                            46.9592006963301
                        ],
                        [
                            31.97607974388005,
                            46.9589269714408
                        ],
                        [
                            31.97725817142956,
                            46.958334520994576
                        ],
                        [
                            31.977785579563616,
                            46.958827605209876
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1161,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.979582063520255,
                            46.95860778020373
                        ],
                        [
                            31.979452958404096,
                            46.95847091641749
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1162,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.977736135051085,
                            46.95799845243788
                        ],
                        [
                            31.97864748271494,
                            46.95883153735508
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1163,
                id: 0,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97807675280432,
                            46.95921710103072
                        ],
                        [
                            31.977941233042177,
                            46.95899969247449
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1164,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97705077916856,
                            46.9592114765625
                        ],
                        [
                            31.977200486164943,
                            46.95936708663159
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1165,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.976837893072783,
                            46.96024261918599
                        ],
                        [
                            31.976339355495803,
                            46.95941865981971
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1166,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.976086492447116,
                            46.95948887494899
                        ],
                        [
                            31.97578032988733,
                            46.959567691715506
                        ],
                        [
                            31.97514304505869,
                            46.95865840096748
                        ],
                        [
                            31.973824524723582,
                            46.959138358421946
                        ],
                        [
                            31.974038784278033,
                            46.95928084496245
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1167,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.975758617371472,
                            46.95953671211762
                        ],
                        [
                            31.97570891003584,
                            46.95954894360868
                        ],
                        [
                            31.97550563815085,
                            46.95960518790943
                        ],
                        [
                            31.97561276792808,
                            46.959775795260086
                        ],
                        [
                            31.974989217686268,
                            46.95995765084804
                        ],
                        [
                            31.97495515451124,
                            46.9600911094572
                        ],
                        [
                            31.974283259923507,
                            46.96031198624909
                        ],
                        [
                            31.97437940203128,
                            46.96045821922259
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1168,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97567320011011,
                            46.960386977567474
                        ],
                        [
                            31.976499959342235,
                            46.96016421597107
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1169,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97590943500346,
                            46.96102439953003
                        ],
                        [
                            31.976860121687434,
                            46.96076735802531
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1170,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97653828028551,
                            46.96085437589468
                        ],
                        [
                            31.976744497882372,
                            46.96119875186181
                        ],
                        [
                            31.976715197430472,
                            46.96126624293446
                        ],
                        [
                            31.977224292782097,
                            46.962093626572326
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1171,
                id: 7,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.981275713473778,
                            46.9803641020491
                        ],
                        [
                            31.981389252724888,
                            46.980534018503946
                        ],
                        [
                            31.982499007340532,
                            46.980194185054245
                        ],
                        [
                            31.982491682227558,
                            46.979996780808605
                        ],
                        [
                            31.985227611923552,
                            46.97911969743585
                        ],
                        [
                            31.984637940329097,
                            46.97820011879171
                        ],
                        [
                            31.9840711218103,
                            46.97837575409627
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1172,
                id: 7,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.984159976707506,
                            46.97851435158815
                        ],
                        [
                            31.98389444136218,
                            46.9781001636383
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1173,
                id: 7,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.984960515488574,
                            46.97870316894026
                        ],
                        [
                            31.984870512666035,
                            46.97873112741106
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1174,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.982745125153812,
                            46.97914099979331
                        ],
                        [
                            31.982972203656033,
                            46.97945710117366
                        ],
                        [
                            31.983843892100005,
                            46.979180981098786
                        ],
                        [
                            31.9840765981838,
                            46.97948869076603
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1175,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.983129693584978,
                            46.97902980163019
                        ],
                        [
                            31.983341841308643,
                            46.97934001321037
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1176,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98350327434669,
                            46.97891735381435
                        ],
                        [
                            31.983709541995395,
                            46.97922353855406
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1177,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.979579760709683,
                            46.978887992401255
                        ],
                        [
                            31.981465977300633,
                            46.9783182528536
                        ],
                        [
                            31.981799269940982,
                            46.97887799702292
                        ],
                        [
                            31.98254530530923,
                            46.979979590478294
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1178,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98102280796568,
                            46.980062436348
                        ],
                        [
                            31.980918425105795,
                            46.97989876538759
                        ],
                        [
                            31.98139455744914,
                            46.97975758316429
                        ],
                        [
                            31.98135341133596,
                            46.97969419426205
                        ],
                        [
                            31.98152183128708,
                            46.97964013926088
                        ],
                        [
                            31.98141287023158,
                            46.97948271404948
                        ],
                        [
                            31.982350484692322,
                            46.97920284586259
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1179,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.981322221958518,
                            46.97995248949856
                        ],
                        [
                            31.981223557636287,
                            46.97980828787805
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1180,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98139455744914,
                            46.97975758316429
                        ],
                        [
                            31.981503518504645,
                            46.97991875576078
                        ],
                        [
                            31.98182582347553,
                            46.979822552021325
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1181,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.981469859957144,
                            46.97956505196236
                        ],
                        [
                            31.981617057755745,
                            46.979526443321376
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1182,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99101792461946,
                            46.96903039233121
                        ],
                        [
                            31.990359582871434,
                            46.968039021795356
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1183,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.990576616976906,
                            46.968365846813974
                        ],
                        [
                            31.99078260536515,
                            46.968304964133544
                        ],
                        [
                            31.990930938902885,
                            46.96832370917205
                        ],
                        [
                            31.99104539379311,
                            46.9682912177678
                        ],
                        [
                            31.990937348376736,
                            46.968125011429706
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1184,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99099587139241,
                            46.96899718355513
                        ],
                        [
                            31.99136953004224,
                            46.96888105864858
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1185,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.996834064321256,
                            46.96674285450854
                        ],
                        [
                            31.99714538162268,
                            46.96720274432039
                        ],
                        [
                            31.995810379783066,
                            46.96762014088983
                        ],
                        [
                            31.995596120228566,
                            46.96728897321992
                        ],
                        [
                            31.99525916503173,
                            46.96738894858161
                        ],
                        [
                            31.995277706723954,
                            46.96741331754768
                        ],
                        [
                            31.99522803330159,
                            46.967427688984024
                        ],
                        [
                            31.99547891842097,
                            46.967790097841274
                        ],
                        [
                            31.991084537365577,
                            46.96919190967347
                        ],
                        [
                            31.991209064286146,
                            46.96935686305147
                        ],
                        [
                            31.99170167813368,
                            46.969208155104496
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1186,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.994547484524297,
                            46.96678003324759
                        ],
                        [
                            31.99484415159976,
                            46.96676378707908
                        ],
                        [
                            31.99525916503173,
                            46.96738894858161
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1187,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.993373635170126,
                            46.96753984853929
                        ],
                        [
                            31.993622689011268,
                            46.96746486724709
                        ],
                        [
                            31.993835117287528,
                            46.96721492884725
                        ],
                        [
                            31.994208698049228,
                            46.96718493616079
                        ],
                        [
                            31.994237998501127,
                            46.96730490680573
                        ],
                        [
                            31.99469215550554,
                            46.96731990311743
                        ],
                        [
                            31.995123210435953,
                            46.96718415220911
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1188,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.994780056861245,
                            46.967642322802014
                        ],
                        [
                            31.99428927429195,
                            46.96699498208933
                        ],
                        [
                            31.99427462406599,
                            46.96686501312547
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1189,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99537260978738,
                            46.967636533073076
                        ],
                        [
                            31.995091374162666,
                            46.967724801944044
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1190,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.991910443853456,
                            46.96842461984976
                        ],
                        [
                            31.992188684270495,
                            46.96883968919232
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1191,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.992965260121792,
                            46.96837463316013
                        ],
                        [
                            31.993084056569238,
                            46.96855406565721
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1192,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.989551757475642,
                            46.96791725278085
                        ],
                        [
                            31.989852087107607,
                            46.96842461984976
                        ],
                        [
                            31.99039780802421,
                            46.96922939912222
                        ],
                        [
                            31.990211017643357,
                            46.969289382185586
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1193,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98946019356347,
                            46.96917191529004
                        ],
                        [
                            31.99020627574048,
                            46.968946946215176
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1194,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.991782254376385,
                            46.97405032326233
                        ],
                        [
                            31.992181473033508,
                            46.973924120056715
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1195,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99048169724824,
                            46.972026023919135
                        ],
                        [
                            31.991425155118883,
                            46.971738633152576
                        ],
                        [
                            31.991364722936833,
                            46.97165366101757
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1196,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99315571305912,
                            46.97230344450116
                        ],
                        [
                            31.992800445079855,
                            46.97178861669833
                        ],
                        [
                            31.99424325966606,
                            46.97133543378365
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1197,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.993704128150156,
                            46.97150477351895
                        ],
                        [
                            31.993902874582517,
                            46.97179361505034
                        ],
                        [
                            31.994129953084734,
                            46.97172113890051
                        ],
                        [
                            31.99435703158695,
                            46.972076021179916
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1198,
                id: 7,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99941232623257,
                            46.96118288520171
                        ],
                        [
                            31.999236523521184,
                            46.95992303584389
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1199,
                id: 7,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99896915689761,
                            46.96061295702398
                        ],
                        [
                            31.999701668195062,
                            46.9605554639321
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1200,
                id: 7,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.999013107575458,
                            46.95954057570472
                        ],
                        [
                            31.9995478408226,
                            46.95950307946549
                        ],
                        [
                            32.00004228594838,
                            46.95960056963282
                        ],
                        [
                            32.00088833649695,
                            46.95953807595625
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1201,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00022541377274,
                            46.96034048921508
                        ],
                        [
                            32.00017413798192,
                            46.9598555430768
                        ],
                        [
                            32.00004228594838,
                            46.95960056963281
                        ],
                        [
                            32.00001898554567,
                            46.95883722748225
                        ],
                        [
                            31.999928746697265,
                            46.958005708169054
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1202,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.997694587240034,
                            46.954730843608736
                        ],
                        [
                            31.99833553462531,
                            46.95475584333397
                        ],
                        [
                            31.99822932048718,
                            46.955290834654896
                        ],
                        [
                            31.998141419131482,
                            46.95559832724592
                        ],
                        [
                            31.99809746845364,
                            46.95571582394829
                        ],
                        [
                            31.997972941533085,
                            46.956535793752415
                        ],
                        [
                            31.997939978524695,
                            46.956713285561854
                        ],
                        [
                            31.99822199537422,
                            46.95673578448157
                        ],
                        [
                            31.99814508168798,
                            46.95734075188693
                        ],
                        [
                            31.998115781236088,
                            46.95783322030315
                        ],
                        [
                            31.998236645600155,
                            46.95869065449631
                        ],
                        [
                            31.99834943574627,
                            46.95939738957992
                        ],
                        [
                            31.99840512319858,
                            46.95993553449509
                        ],
                        [
                            31.997694587240044,
                            46.95997802988742
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1203,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.997361294599695,
                            46.955765822466795
                        ],
                        [
                            31.997478496407293,
                            46.95507833874249
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1204,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.997380608302826,
                            46.955652532530394
                        ],
                        [
                            31.99809746845364,
                            46.95571582394829
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1205,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99697306361205,
                            46.95809320180152
                        ],
                        [
                            31.996848536691484,
                            46.95888313783487
                        ],
                        [
                            31.996947770205374,
                            46.959483202849185
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1206,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.998205860498537,
                            46.95847226058561
                        ],
                        [
                            31.998930700054512,
                            46.9584256763001
                        ],
                        [
                            31.998949012836945,
                            46.95855441587063
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1207,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00001898554567,
                            46.95883722748225
                        ],
                        [
                            32.00038393520507,
                            46.95880141095992
                        ],
                        [
                            32.00027119572885,
                            46.957814471588584
                        ],
                        [
                            32.00003679211366,
                            46.95656704235313
                        ],
                        [
                            32.00182778223595,
                            46.95645954709012
                        ],
                        [
                            32.00184975757487,
                            46.95664453880412
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1208,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00003679211366,
                            46.95656704235313
                        ],
                        [
                            31.99964123601304,
                            46.95658704144799
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1209,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0008106927921,
                            46.95652059284539
                        ],
                        [
                            32.00078395363707,
                            46.95633455232674
                        ],
                        [
                            32.00269947067991,
                            46.95622455669343
                        ],
                        [
                            32.00267749534099,
                            46.95610206129025
                        ],
                        [
                            32.002860623165354,
                            46.95609456156257
                        ],
                        [
                            32.00282399760048,
                            46.95581457097734
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1210,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00267749534099,
                            46.95610206129025
                        ],
                        [
                            32.00262988210666,
                            46.95571707391182
                        ],
                        [
                            32.00324519159652,
                            46.95567707506432
                        ],
                        [
                            32.00326350437896,
                            46.955317084091405
                        ],
                        [
                            32.00279835970507,
                            46.955339583598146
                        ],
                        [
                            32.00275074647074,
                            46.95487959180193
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1211,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00481507241827,
                            46.95673808891284
                        ],
                        [
                            32.00488832354801,
                            46.95737055464687
                        ],
                        [
                            32.00463560715039,
                            46.95738805359254
                        ],
                        [
                            32.004714352114874,
                            46.95813300339995
                        ],
                        [
                            32.00481140986177,
                            46.95813050358573
                        ],
                        [
                            32.00488282971329,
                            46.95816300116156
                        ],
                        [
                            32.00497957551691,
                            46.95897333167487
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1212,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00784034407676,
                            46.95617561310617
                        ],
                        [
                            32.007778080616475,
                            46.955860624071896
                        ],
                        [
                            32.0075693148967,
                            46.95587312367206
                        ],
                        [
                            32.007474088428026,
                            46.955725628203886
                        ],
                        [
                            32.00743746286315,
                            46.95517814147019
                        ],
                        [
                            32.00726532270826,
                            46.955180641422324
                        ],
                        [
                            32.00745943820208,
                            46.95661059491483
                        ],
                        [
                            32.007386187072335,
                            46.95714806755321
                        ],
                        [
                            32.00743746286315,
                            46.95745554947214
                        ],
                        [
                            32.00753909880568,
                            46.95790302000232
                        ],
                        [
                            32.00755741158812,
                            46.95800051308505
                        ],
                        [
                            32.00801797806639,
                            46.95797738974175
                        ],
                        [
                            32.00808939791789,
                            46.95794051736285
                        ],
                        [
                            32.00849685732711,
                            46.957918018949684
                        ],
                        [
                            32.008998917293184,
                            46.95788885101972
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1213,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.009455531487646,
                            46.956618094570196
                        ],
                        [
                            32.00899771192673,
                            46.956730589274294
                        ],
                        [
                            32.008978483505174,
                            46.95683683405546
                        ],
                        [
                            32.00897024275308,
                            46.956872457258385
                        ],
                        [
                            32.009005037039714,
                            46.95707307169522
                        ],
                        [
                            32.00891719862151,
                            46.95724032395456
                        ],
                        [
                            32.008957486742865,
                            46.957572803752555
                        ],
                        [
                            32.00901975020315,
                            46.958047771308905
                        ],
                        [
                            32.00902341275964,
                            46.958091518108624
                        ],
                        [
                            32.008464872895324,
                            46.958145264127886
                        ],
                        [
                            32.008477691843034,
                            46.95828650340955
                        ],
                        [
                            32.00854402679571,
                            46.95873753261443
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1214,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00895257288145,
                            46.95753225196868
                        ],
                        [
                            32.009131458176014,
                            46.95752843156816
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1215,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.009131458176014,
                            46.95752843156816
                        ],
                        [
                            32.009277960435504,
                            46.957948403445215
                        ],
                        [
                            32.009298104496196,
                            46.958182136365714
                        ],
                        [
                            32.00863518177199,
                            46.95822088343569
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1216,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00914793968022,
                            46.95750968274676
                        ],
                        [
                            32.00918456524509,
                            46.957438437165635
                        ],
                        [
                            32.009168083740896,
                            46.95727969667004
                        ],
                        [
                            32.00910215772413,
                            46.95718845192007
                        ],
                        [
                            32.0090801823852,
                            46.95699846235235
                        ],
                        [
                            32.009295560603015,
                            46.95698794184602
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1217,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00922732519909,
                            46.957803249911436
                        ],
                        [
                            32.00951053277246,
                            46.95778591471707
                        ],
                        [
                            32.009507785855085,
                            46.95762342549548
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1218,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00901975020315,
                            46.958047771308905
                        ],
                        [
                            32.00842824733046,
                            46.95807839407246
                        ],
                        [
                            32.00765911046815,
                            46.95811339149508
                        ],
                        [
                            32.00696688729206,
                            46.95815338852147
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1219,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01036299279487,
                            46.96155740088253
                        ],
                        [
                            32.009809946765294,
                            46.961597395334444
                        ],
                        [
                            32.00972937052257,
                            46.96110996091362
                        ],
                        [
                            32.01036299279487,
                            46.96107246577422
                        ],
                        [
                            32.01034834256891,
                            46.960962479880436
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1220,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.009747237528636,
                            46.9612180451868
                        ],
                        [
                            32.01028607910863,
                            46.961177452098305
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1221,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00972937052257,
                            46.96110996091362
                        ],
                        [
                            32.00911406103271,
                            46.96114245667983
                        ],
                        [
                            32.009147024041084,
                            46.9613874241284
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1222,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00693850247926,
                            46.961414920404636
                        ],
                        [
                            32.00838154973524,
                            46.96134742951952
                        ],
                        [
                            32.00840352507417,
                            46.96156739949831
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1223,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00738167181422,
                            46.96138992379045
                        ],
                        [
                            32.00734138369286,
                            46.961074965451004
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1224,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.007828503705674,
                            46.96389265179596
                        ],
                        [
                            32.00769298911564,
                            46.9639313947305
                        ],
                        [
                            32.00727362639785,
                            46.96396013947025
                        ],
                        [
                            32.00724157902859,
                            46.96372705667878
                        ],
                        [
                            32.00719305015514,
                            46.96344398157448
                        ],
                        [
                            32.00677734999382,
                            46.96339524001463
                        ],
                        [
                            32.00656492171757,
                            46.96340273871904
                        ],
                        [
                            32.00657041555229,
                            46.96349147330802
                        ],
                        [
                            32.00623162907722,
                            46.963512719596224
                        ],
                        [
                            32.00582691658537,
                            46.96352646718997
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1225,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.005995796143424,
                            46.96352073055611
                        ],
                        [
                            32.005977081401355,
                            46.96334524862506
                        ],
                        [
                            32.00575000289915,
                            46.96335774647684
                        ],
                        [
                            32.00568041432589,
                            46.96287532727974
                        ],
                        [
                            32.00660337856068,
                            46.96282533540434
                        ],
                        [
                            32.00662901645608,
                            46.96300280635056
                        ],
                        [
                            32.006922020975075,
                            46.96298780882857
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1226,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0078504790446,
                            46.96390389974759
                        ],
                        [
                            32.00777539663661,
                            46.96398013580186
                        ],
                        [
                            32.00746407933519,
                            46.96400888051543
                        ],
                        [
                            32.00762340054239,
                            46.96498619158707
                        ],
                        [
                            32.00809953288575,
                            46.96495869714627
                        ],
                        [
                            32.0080848826598,
                            46.964838721239246
                        ],
                        [
                            32.00887233230457,
                            46.96479123070172
                        ],
                        [
                            32.00889430764348,
                            46.96490870721771
                        ],
                        [
                            32.00952792991579,
                            46.96486371624208
                        ],
                        [
                            32.00955723036769,
                            46.96507117431473
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1227,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.008003752547125,
                            46.96496422801899
                        ],
                        [
                            32.008040931981945,
                            46.96524363884705
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1228,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00754120211962,
                            46.96448197074388
                        ],
                        [
                            32.00775525257593,
                            46.96446629431385
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1229,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00749956608414,
                            46.96422656552729
                        ],
                        [
                            32.00723150699827,
                            46.964276330588255
                        ],
                        [
                            32.00600821313151,
                            46.96435131634999
                        ],
                        [
                            32.005239076269184,
                            46.96439880727806
                        ],
                        [
                            32.00474829369988,
                            46.964423802486415
                        ],
                        [
                            32.004711668135016,
                            46.96421134284307
                        ],
                        [
                            32.003942531272685,
                            46.96424133719681
                        ],
                        [
                            32.00392788104673,
                            46.96399388327548
                        ],
                        [
                            32.003913230820785,
                            46.96380641742153
                        ],
                        [
                            32.003880267812406,
                            46.96355646192771
                        ],
                        [
                            32.00385462991698,
                            46.96333150098473
                        ],
                        [
                            32.00381800435212,
                            46.96303905034463
                        ],
                        [
                            32.003810679239145,
                            46.96291657054176
                        ],
                        [
                            32.00483619505558,
                            46.96285658033192
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1230,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00535261552029,
                            46.9636139517947
                        ],
                        [
                            32.0054075538676,
                            46.96405387221
                        ],
                        [
                            32.005442496803234,
                            46.96438624692987
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1231,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.005439601236844,
                            46.9646568822422
                        ],
                        [
                            32.00693392428366,
                            46.9645818969088
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1232,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00618231632688,
                            46.96461961271126
                        ],
                        [
                            32.00625635133352,
                            46.96512178896337
                        ],
                        [
                            32.006684870442534,
                            46.9650917951033
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1233,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00541396334144,
                            46.96465813199688
                        ],
                        [
                            32.004165031579284,
                            46.964708122159585
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1234,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00450909498774,
                            46.964694350566546
                        ],
                        [
                            32.00453861234097,
                            46.96509054535875
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1235,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00540572258936,
                            46.964503162195584
                        ],
                        [
                            32.00539473491989,
                            46.96442161540618
                        ],
                        [
                            32.00403226390662,
                            46.964504099514286
                        ],
                        [
                            32.00371362149223,
                            46.964515347337354
                        ],
                        [
                            32.00371087457486,
                            46.964639073235034
                        ],
                        [
                            32.00371911532695,
                            46.964732804785214
                        ],
                        [
                            32.00309007125027,
                            46.96475530033281
                        ],
                        [
                            32.0031669849365,
                            46.96534018125012
                        ],
                        [
                            32.002268742958,
                            46.965394544856714
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1236,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00362102763855,
                            46.96447540912274
                        ],
                        [
                            32.00292931935696,
                            46.96447264074656
                        ],
                        [
                            32.00187725000598,
                            46.96452138132477
                        ],
                        [
                            32.00191295993173,
                            46.964723841712804
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1237,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00184978083233,
                            46.96471634319358
                        ],
                        [
                            32.00180308323712,
                            46.964467016830824
                        ],
                        [
                            32.00226181843715,
                            46.964440771882934
                        ],
                        [
                            32.00225151749703,
                            46.96433438598016
                        ],
                        [
                            32.00263471246951,
                            46.964314233560636
                        ],
                        [
                            32.002500800247944,
                            46.96323161567943
                        ],
                        [
                            32.002217867759306,
                            46.9632447384516
                        ],
                        [
                            32.00201734279162,
                            46.963190372660286
                        ],
                        [
                            32.00178934865029,
                            46.96320162075957
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1238,
                id: 0,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.000553922565175,
                            46.96306992411549
                        ],
                        [
                            31.9996529336693,
                            46.963137412827685
                        ],
                        [
                            31.999709245475294,
                            46.963500163197125
                        ],
                        [
                            31.99985895247171,
                            46.96348891516061
                        ],
                        [
                            31.999950974203454,
                            46.964175041060386
                        ],
                        [
                            32.00017072759269,
                            46.96421253402539
                        ],
                        [
                            32.00019819676635,
                            46.96447873332125
                        ],
                        [
                            31.99973946156631,
                            46.96450497825052
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1239,
                id: 7,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00017072759269,
                            46.96421253402539
                        ],
                        [
                            32.00021193135318,
                            46.963860099117035
                        ],
                        [
                            32.001033259645446,
                            46.963807608612875
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1240,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00019819676635,
                            46.96447873332125
                        ],
                        [
                            32.00022566594,
                            46.96461558173953
                        ],
                        [
                            32.00031905882015,
                            46.9646348956637
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1241,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00031905882015,
                            46.9646348956637
                        ],
                        [
                            32.00039597481666,
                            46.96460433393753
                        ],
                        [
                            32.0006074874538,
                            46.96458371296106
                        ],
                        [
                            32.0009893089676,
                            46.96456121734129
                        ],
                        [
                            32.00181305238665,
                            46.96452024390471
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1242,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00104869950859,
                            46.96467058662555
                        ],
                        [
                            32.00079967713621,
                            46.9646863547269
                        ],
                        [
                            32.000748332317166,
                            46.96427813460595
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1243,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00362004088231,
                            46.961266308417756
                        ],
                        [
                            32.00321905380327,
                            46.96129286692643
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1244,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00683076613371,
                            46.9640727033183
                        ],
                        [
                            32.00726568409447,
                            46.96406057627726
                        ],
                        [
                            32.007288699451806,
                            46.96426571283625
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1245,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0099879203986,
                            46.96245584012503
                        ],
                        [
                            32.010460733550126,
                            46.962428422496664
                        ],
                        [
                            32.01082847711244,
                            46.965007727092605
                        ],
                        [
                            32.011251845751396,
                            46.964990855512404
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1246,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.010807990111736,
                            46.964864037256575
                        ],
                        [
                            32.0102351429615,
                            46.9649086064827
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1247,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01075250744811,
                            46.96447489612956
                        ],
                        [
                            32.010176427602815,
                            46.96451212220653
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1248,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.010699473745355,
                            46.96410292873784
                        ],
                        [
                            32.01014552478245,
                            46.964134615829664
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1249,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01064563338338,
                            46.96372530096005
                        ],
                        [
                            32.010130073372274,
                            46.96375288879569
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1250,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01063080590566,
                            46.963621302909914
                        ],
                        [
                            32.01008680942377,
                            46.96364954785942
                        ],
                        [
                            32.010043545475256,
                            46.96337537706113
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1251,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01078044118959,
                            46.9646708165587
                        ],
                        [
                            32.012194381772424,
                            46.964560628419356
                        ],
                        [
                            32.01208622190114,
                            46.96381826765226
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1252,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01209738718703,
                            46.96389490163554
                        ],
                        [
                            32.01152997113465,
                            46.963913172301915
                        ],
                        [
                            32.0114063598532,
                            46.96368540085992
                        ],
                        [
                            32.01137854731487,
                            46.96326992637996
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1253,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01124333112311,
                            46.96463474365311
                        ],
                        [
                            32.011196220674734,
                            46.96420421217765
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1254,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.012162009617775,
                            46.964338441572814
                        ],
                        [
                            32.012537403078426,
                            46.9643233693511
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1255,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.012024416260424,
                            46.96431071550456
                        ],
                        [
                            32.012067680208936,
                            46.96479155956857
                        ],
                        [
                            32.01285570212816,
                            46.96477679687654
                        ],
                        [
                            32.01302875792218,
                            46.964515285656525
                        ],
                        [
                            32.01297313284553,
                            46.96414410624609
                        ],
                        [
                            32.01333571861783,
                            46.96412487309871
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1256,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01258066702694,
                            46.9643571129272
                        ],
                        [
                            32.012657924077836,
                            46.96469665647724
                        ],
                        [
                            32.013407427499345,
                            46.964639986572806
                        ],
                        [
                            32.01371789081623,
                            46.9646144069952
                        ],
                        [
                            32.01407250067992,
                            46.96462047026262
                        ],
                        [
                            32.01417602512814,
                            46.964663703975575
                        ],
                        [
                            32.01505830064948,
                            46.96460148959711
                        ],
                        [
                            32.01562691254414,
                            46.96452134893517
                        ],
                        [
                            32.01703608115266,
                            46.964382156973834
                        ],
                        [
                            32.01690937958918,
                            46.96342889320228
                        ],
                        [
                            32.01683521282031,
                            46.96296069105886
                        ],
                        [
                            32.01659108053945,
                            46.962969127169764
                        ],
                        [
                            32.016553997155015,
                            46.96258317373405
                        ],
                        [
                            32.01597920469628,
                            46.96261902744922
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1257,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01204657390243,
                            46.96455698045168
                        ],
                        [
                            32.01204634551896,
                            46.9645570180408
                        ],
                        [
                            32.01204611782456,
                            46.96455705960056
                        ],
                        [
                            32.01204589088827,
                            46.96455710511835
                        ],
                        [
                            32.012045664778874,
                            46.9645571545804
                        ],
                        [
                            32.01204543956493,
                            46.96455720797169
                        ],
                        [
                            32.0120452153147,
                            46.96455726527604
                        ],
                        [
                            32.012044992096186,
                            46.96455732647608
                        ],
                        [
                            32.012044769977045,
                            46.964557391553264
                        ],
                        [
                            32.012044549024615,
                            46.964557460487846
                        ],
                        [
                            32.01204432930589,
                            46.96455753325895
                        ],
                        [
                            32.012044110887466,
                            46.96455760984449
                        ],
                        [
                            32.012043893835575,
                            46.96455769022126
                        ],
                        [
                            32.012043678216,
                            46.964557774364906
                        ],
                        [
                            32.01204346409412,
                            46.9645578622499
                        ],
                        [
                            32.01204325153484,
                            46.9645579538496
                        ],
                        [
                            32.01204304060261,
                            46.96455804913625
                        ],
                        [
                            32.01204283136137,
                            46.964558148080954
                        ],
                        [
                            32.01204262387455,
                            46.96455825065372
                        ],
                        [
                            32.012042418205056,
                            46.964558356823446
                        ],
                        [
                            32.01204221441523,
                            46.964558466557946
                        ],
                        [
                            32.01204201256687,
                            46.96455857982396
                        ],
                        [
                            32.01204181272116,
                            46.96455869658715
                        ],
                        [
                            32.012041614938674,
                            46.96455881681211
                        ],
                        [
                            32.012041419279385,
                            46.9645589404624
                        ],
                        [
                            32.012041225802605,
                            46.96455906750053
                        ],
                        [
                            32.012041034566984,
                            46.96455919788799
                        ],
                        [
                            32.0120408456305,
                            46.96455933158526
                        ],
                        [
                            32.012040659050434,
                            46.96455946855179
                        ],
                        [
                            32.01204047488335,
                            46.964559608746065
                        ],
                        [
                            32.01204029318507,
                            46.96455975212559
                        ],
                        [
                            32.01204011401069,
                            46.9645598986469
                        ],
                        [
                            32.01203993741452,
                            46.96456004826556
                        ],
                        [
                            32.0120397634501,
                            46.964560200936226
                        ],
                        [
                            32.01203959217016,
                            46.96456035661261
                        ],
                        [
                            32.012039423626646,
                            46.96456051524751
                        ],
                        [
                            32.01203925787063,
                            46.96456067679285
                        ],
                        [
                            32.01203909495238,
                            46.96456084119965
                        ],
                        [
                            32.01203893492127,
                            46.96456100841806
                        ],
                        [
                            32.012038777825836,
                            46.964561178397396
                        ],
                        [
                            32.012038623713686,
                            46.964561351086125
                        ],
                        [
                            32.01203847263154,
                            46.96456152643189
                        ],
                        [
                            32.01203832462521,
                            46.96456170438154
                        ],
                        [
                            32.012038179739555,
                            46.96456188488113
                        ],
                        [
                            32.01203803801851,
                            46.964562067875924
                        ],
                        [
                            32.01203789950503,
                            46.96456225331046
                        ],
                        [
                            32.012037764241114,
                            46.96456244112853
                        ],
                        [
                            32.01203763226776,
                            46.96456263127317
                        ],
                        [
                            32.01203750362498,
                            46.964562823686755
                        ],
                        [
                            32.01203737835178,
                            46.96456301831095
                        ],
                        [
                            32.012037256486124,
                            46.96456321508674
                        ],
                        [
                            32.01203713806497,
                            46.964563413954494
                        ],
                        [
                            32.01203702312421,
                            46.96456361485391
                        ],
                        [
                            32.012036911698694,
                            46.96456381772408
                        ],
                        [
                            32.0120368038222,
                            46.9645640225035
                        ],
                        [
                            32.01203669952743,
                            46.964564229130104
                        ],
                        [
                            32.012036598846,
                            46.96456443754124
                        ],
                        [
                            32.012036501808446,
                            46.96456464767373
                        ],
                        [
                            32.01203640844417,
                            46.96456485946388
                        ],
                        [
                            32.012036318781476,
                            46.96456507284746
                        ],
                        [
                            32.012036232847564,
                            46.964565287759804
                        ],
                        [
                            32.012036150668465,
                            46.96456550413574
                        ],
                        [
                            32.012036072269105,
                            46.964565721909686
                        ],
                        [
                            32.012035997673244,
                            46.96456594101562
                        ],
                        [
                            32.0120359269035,
                            46.96456616138712
                        ],
                        [
                            32.01203585998133,
                            46.964566382957365
                        ],
                        [
                            32.01203579692702,
                            46.96456660565919
                        ],
                        [
                            32.01203573775968,
                            46.964566829425095
                        ],
                        [
                            32.01203568249726,
                            46.96456705418723
                        ],
                        [
                            32.0120356311565,
                            46.96456727987746
                        ],
                        [
                            32.01203558375297,
                            46.96456750642736
                        ],
                        [
                            32.01203554030104,
                            46.96456773376825
                        ],
                        [
                            32.01203550081389,
                            46.964567961831214
                        ],
                        [
                            32.01203546530348,
                            46.96456819054711
                        ],
                        [
                            32.012035433780575,
                            46.96456841984661
                        ],
                        [
                            32.01203540625474,
                            46.96456864966019
                        ],
                        [
                            32.01203538273431,
                            46.964568879918176
                        ],
                        [
                            32.01203536322643,
                            46.96456911055077
                        ],
                        [
                            32.012035347737,
                            46.96456934148806
                        ],
                        [
                            32.01203533627072,
                            46.96456957266002
                        ],
                        [
                            32.01203532883107,
                            46.964569803996575
                        ],
                        [
                            32.0120353254203,
                            46.9645700354276
                        ],
                        [
                            32.01203532603945,
                            46.96457026688293
                        ],
                        [
                            32.01203533068833,
                            46.964570498292396
                        ],
                        [
                            32.012035339365525,
                            46.96457072958584
                        ],
                        [
                            32.01203535206841,
                            46.964570960693145
                        ],
                        [
                            32.01203536879313,
                            46.96457119154426
                        ],
                        [
                            32.01203538953462,
                            46.964571422069184
                        ],
                        [
                            32.012035414286586,
                            46.964571652198046
                        ],
                        [
                            32.01203544304153,
                            46.96457188186107
                        ],
                        [
                            32.01203547579073,
                            46.96457211098864
                        ],
                        [
                            32.01203551252427,
                            46.964572339511285
                        ],
                        [
                            32.012035553230994,
                            46.96457256735973
                        ],
                        [
                            32.01203559789858,
                            46.9645727944649
                        ],
                        [
                            32.012035646513475,
                            46.96457302075795
                        ],
                        [
                            32.01203569906094,
                            46.964573246170275
                        ],
                        [
                            32.01203575552505,
                            46.96457347063353
                        ],
                        [
                            32.01203581588869,
                            46.96457369407969
                        ],
                        [
                            32.01203588013355,
                            46.96457391644099
                        ],
                        [
                            32.01203594824017,
                            46.96457413765003
                        ],
                        [
                            32.012036020187885,
                            46.964574357639755
                        ],
                        [
                            32.01203609595489,
                            46.964574576343466
                        ],
                        [
                            32.01203617551822,
                            46.96457479369486
                        ],
                        [
                            32.012036258853755,
                            46.96457500962805
                        ],
                        [
                            32.012036345936224,
                            46.964575224077564
                        ],
                        [
                            32.01203643673923,
                            46.9645754369784
                        ],
                        [
                            32.012036531235246,
                            46.96457564826601
                        ],
                        [
                            32.01203662939563,
                            46.96457585787634
                        ],
                        [
                            32.01203673119061,
                            46.96457606574585
                        ],
                        [
                            32.012036836589346,
                            46.964576271811524
                        ],
                        [
                            32.01203694555986,
                            46.964576476010876
                        ],
                        [
                            32.01203705806914,
                            46.964576678282015
                        ],
                        [
                            32.01203717408307,
                            46.96457687856362
                        ],
                        [
                            32.01203729356647,
                            46.964577076794974
                        ],
                        [
                            32.01203741648313,
                            46.964577272915975
                        ],
                        [
                            32.01203754279578,
                            46.96457746686717
                        ],
                        [
                            32.012037672466136,
                            46.96457765858976
                        ],
                        [
                            32.012037805454874,
                            46.96457784802563
                        ],
                        [
                            32.01203794172168,
                            46.964578035117334
                        ],
                        [
                            32.012038081225256,
                            46.964578219808175
                        ],
                        [
                            32.0120382239233,
                            46.96457840204214
                        ],
                        [
                            32.01203836977255,
                            46.96457858176401
                        ],
                        [
                            32.01203851872879,
                            46.96457875891928
                        ],
                        [
                            32.01203867074687,
                            46.96457893345425
                        ],
                        [
                            32.0120388257807,
                            46.964579105316
                        ],
                        [
                            32.012038983783285,
                            46.96457927445244
                        ],
                        [
                            32.01203914470672,
                            46.964579440812294
                        ],
                        [
                            32.01203930850222,
                            46.964579604345126
                        ],
                        [
                            32.01203947512012,
                            46.96457976500136
                        ],
                        [
                            32.012039644509926,
                            46.964579922732284
                        ],
                        [
                            32.01203981662028,
                            46.96458007749009
                        ],
                        [
                            32.012039991399,
                            46.96458022922786
                        ],
                        [
                            32.0120401687931,
                            46.964580377899594
                        ],
                        [
                            32.01204034874881,
                            46.96458052346022
                        ],
                        [
                            32.01204053121157,
                            46.964580665865604
                        ],
                        [
                            32.01204071612606,
                            46.964580805072586
                        ],
                        [
                            32.01204090343622,
                            46.96458094103895
                        ],
                        [
                            32.01204109308528,
                            46.964581073723494
                        ],
                        [
                            32.01204128501574,
                            46.96458120308598
                        ],
                        [
                            32.012041479169405,
                            46.96458132908719
                        ],
                        [
                            32.012041675487424,
                            46.96458145168893
                        ],
                        [
                            32.01204187391028,
                            46.96458157085404
                        ],
                        [
                            32.01204207437782,
                            46.964581686546374
                        ],
                        [
                            32.01204227682926,
                            46.96458179873088
                        ],
                        [
                            32.012042481203245,
                            46.96458190737354
                        ],
                        [
                            32.012042687437805,
                            46.96458201244141
                        ],
                        [
                            32.01204289547042,
                            46.96458211390265
                        ],
                        [
                            32.01204310523803,
                            46.9645822117265
                        ],
                        [
                            32.01204331667702,
                            46.9645823058833
                        ],
                        [
                            32.012043529723314,
                            46.9645823963445
                        ],
                        [
                            32.012043744312315,
                            46.96458248308268
                        ],
                        [
                            32.01204396037897,
                            46.96458256607155
                        ],
                        [
                            32.012044177857774,
                            46.96458264528595
                        ],
                        [
                            32.0120443966828,
                            46.964582720701856
                        ],
                        [
                            32.01204461678771,
                            46.96458279229642
                        ],
                        [
                            32.01204483810578,
                            46.96458286004792
                        ],
                        [
                            32.0120450605699,
                            46.96458292393583
                        ],
                        [
                            32.012045284112645,
                            46.96458298394078
                        ],
                        [
                            32.01204550866624,
                            46.96458304004457
                        ],
                        [
                            32.012045734162605,
                            46.9645830922302
                        ],
                        [
                            32.012045960533385,
                            46.96458314048185
                        ],
                        [
                            32.012046187709956,
                            46.96458318478489
                        ],
                        [
                            32.01204641562344,
                            46.964583225125885
                        ],
                        [
                            32.012046644204744,
                            46.96458326149261
                        ],
                        [
                            32.01204687338458,
                            46.96458329387404
                        ],
                        [
                            32.01204710309345,
                            46.96458332226036
                        ],
                        [
                            32.01204733326174,
                            46.96458334664296
                        ],
                        [
                            32.012047563819664,
                            46.96458336701445
                        ],
                        [
                            32.01204779469732,
                            46.964583383368655
                        ],
                        [
                            32.01204802582472,
                            46.964583395700615
                        ],
                        [
                            32.01204825713179,
                            46.96458340400659
                        ],
                        [
                            32.012048488548416,
                            46.964583408284064
                        ],
                        [
                            32.01204872000444,
                            46.964583408531745
                        ],
                        [
                            32.01204895142969,
                            46.964583404749554
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1258,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.012913427902134,
                            46.964689565396796
                        ],
                        [
                            32.01291326868962,
                            46.96468940217994
                        ],
                        [
                            32.012913112341835,
                            46.96468923621689
                        ],
                        [
                            32.01291295890613,
                            46.96468906755792
                        ],
                        [
                            32.01291280842899,
                            46.96468889625411
                        ],
                        [
                            32.012912660956,
                            46.96468872235736
                        ],
                        [
                            32.01291251653182,
                            46.96468854592034
                        ],
                        [
                            32.01291237520021,
                            46.96468836699649
                        ],
                        [
                            32.01291223700398,
                            46.96468818564003
                        ],
                        [
                            32.012912101984995,
                            46.964688001905884
                        ],
                        [
                            32.01291197018414,
                            46.96468781584971
                        ],
                        [
                            32.01291184164136,
                            46.96468762752786
                        ],
                        [
                            32.01291171639558,
                            46.96468743699739
                        ],
                        [
                            32.01291159448475,
                            46.96468724431601
                        ],
                        [
                            32.012911475945785,
                            46.96468704954209
                        ],
                        [
                            32.0129113608146,
                            46.96468685273464
                        ],
                        [
                            32.01291124912607,
                            46.96468665395326
                        ],
                        [
                            32.01291114091403,
                            46.96468645325817
                        ],
                        [
                            32.01291103621126,
                            46.964686250710166
                        ],
                        [
                            32.01291093504946,
                            46.9646860463706
                        ],
                        [
                            32.0129108374593,
                            46.96468584030138
                        ],
                        [
                            32.01291074347033,
                            46.964685632564915
                        ],
                        [
                            32.012910653111014,
                            46.96468542322414
                        ],
                        [
                            32.012910566408735,
                            46.964685212342474
                        ],
                        [
                            32.012910483389746,
                            46.96468499998378
                        ],
                        [
                            32.01291040407921,
                            46.9646847862124
                        ],
                        [
                            32.01291032850113,
                            46.96468457109309
                        ],
                        [
                            32.01291025667842,
                            46.964684354691
                        ],
                        [
                            32.01291018863282,
                            46.9646841370717
                        ],
                        [
                            32.012910124384966,
                            46.96468391830109
                        ],
                        [
                            32.012910063954294,
                            46.96468369844546
                        ],
                        [
                            32.012910007359125,
                            46.9646834775714
                        ],
                        [
                            32.012909954616596,
                            46.96468325574581
                        ],
                        [
                            32.01290990574268,
                            46.9646830330359
                        ],
                        [
                            32.012909860752195,
                            46.96468280950912
                        ],
                        [
                            32.012909819658766,
                            46.964682585233184
                        ],
                        [
                            32.01290978247483,
                            46.964682360276036
                        ],
                        [
                            32.01290974921166,
                            46.96468213470581
                        ],
                        [
                            32.01290971987933,
                            46.96468190859084
                        ],
                        [
                            32.01290969448672,
                            46.96468168199962
                        ],
                        [
                            32.01290967304154,
                            46.96468145500079
                        ],
                        [
                            32.01290965555027,
                            46.964681227663114
                        ],
                        [
                            32.0129096420182,
                            46.96468100005545
                        ],
                        [
                            32.01290963244945,
                            46.96468077224675
                        ],
                        [
                            32.012909626846906,
                            46.96468054430602
                        ],
                        [
                            32.01290962521227,
                            46.96468031630231
                        ],
                        [
                            32.01290962754604,
                            46.96468008830468
                        ],
                        [
                            32.01290963384749,
                            46.964679860382205
                        ],
                        [
                            32.01290964411474,
                            46.96467963260391
                        ],
                        [
                            32.01290965834466,
                            46.964679405038815
                        ],
                        [
                            32.01290967653295,
                            46.96467917775584
                        ],
                        [
                            32.01290969867409,
                            46.96467895082383
                        ],
                        [
                            32.01290972476138,
                            46.96467872431155
                        ],
                        [
                            32.01290975478692,
                            46.96467849828758
                        ],
                        [
                            32.01290978874161,
                            46.964678272820414
                        ],
                        [
                            32.01290982661516,
                            46.96467804797834
                        ],
                        [
                            32.01290986839611,
                            46.96467782382946
                        ],
                        [
                            32.01290991407179,
                            46.96467760044169
                        ],
                        [
                            32.012909963628374,
                            46.96467737788269
                        ],
                        [
                            32.01291001705085,
                            46.96467715621987
                        ],
                        [
                            32.012910074323024,
                            46.96467693552039
                        ],
                        [
                            32.01291013542756,
                            46.96467671585109
                        ],
                        [
                            32.01291020034595,
                            46.96467649727852
                        ],
                        [
                            32.012910269058516,
                            46.96467627986889
                        ],
                        [
                            32.012910341544455,
                            46.96467606368805
                        ],
                        [
                            32.0129104177818,
                            46.9646758488015
                        ],
                        [
                            32.01291049774746,
                            46.96467563527432
                        ],
                        [
                            32.01291058141722,
                            46.964675423171194
                        ],
                        [
                            32.012910668765734,
                            46.96467521255637
                        ],
                        [
                            32.01291075976653,
                            46.96467500349365
                        ],
                        [
                            32.012910854392054,
                            46.96467479604637
                        ],
                        [
                            32.01291095261364,
                            46.96467459027736
                        ],
                        [
                            32.01291105440152,
                            46.96467438624895
                        ],
                        [
                            32.01291115972489,
                            46.964674184022954
                        ],
                        [
                            32.01291126855182,
                            46.964673983660624
                        ],
                        [
                            32.01291138084935,
                            46.96467378522265
                        ],
                        [
                            32.01291149658347,
                            46.96467358876915
                        ],
                        [
                            32.01291161571912,
                            46.96467339435963
                        ],
                        [
                            32.01291173822021,
                            46.96467320205298
                        ],
                        [
                            32.012911864049634,
                            46.96467301190745
                        ],
                        [
                            32.01291199316927,
                            46.96467282398065
                        ],
                        [
                            32.01291212554001,
                            46.96467263832949
                        ],
                        [
                            32.012912261121755,
                            46.964672455010216
                        ],
                        [
                            32.012912399873436,
                            46.96467227407837
                        ],
                        [
                            32.012912541753025,
                            46.96467209558874
                        ],
                        [
                            32.012912686717534,
                            46.964671919595396
                        ],
                        [
                            32.01291283472306,
                            46.964671746151666
                        ],
                        [
                            32.01291298572477,
                            46.96467157531007
                        ],
                        [
                            32.01291313967692,
                            46.964671407122374
                        ],
                        [
                            32.01291329653287,
                            46.96467124163952
                        ],
                        [
                            32.01291345624511,
                            46.964671078911636
                        ],
                        [
                            32.01291361876526,
                            46.96467091898801
                        ],
                        [
                            32.012913784044095,
                            46.9646707619171
                        ],
                        [
                            32.01291395203154,
                            46.96467060774647
                        ],
                        [
                            32.01291412267671,
                            46.964670456522825
                        ],
                        [
                            32.01291429592792,
                            46.96467030829198
                        ],
                        [
                            32.012914471732685,
                            46.96467016309883
                        ],
                        [
                            32.01291465003774,
                            46.96467002098736
                        ],
                        [
                            32.01291483078909,
                            46.96466988200062
                        ],
                        [
                            32.01291501393198,
                            46.96466974618071
                        ],
                        [
                            32.01291519941092,
                            46.964669613568766
                        ],
                        [
                            32.012915387169734,
                            46.964669484204975
                        ],
                        [
                            32.012915577151546,
                            46.9646693581285
                        ],
                        [
                            32.01291576929881,
                            46.964669235377556
                        ],
                        [
                            32.01291596355331,
                            46.96466911598931
                        ],
                        [
                            32.01291615985621,
                            46.964669
                        ],
                        [
                            32.012916358148054,
                            46.96466888744456
                        ],
                        [
                            32.01291655836876,
                            46.96466877835729
                        ],
                        [
                            32.012916760457685,
                            46.96466867277116
                        ],
                        [
                            32.012916964353614,
                            46.96466857071816
                        ],
                        [
                            32.01291716999478,
                            46.9646684722292
                        ],
                        [
                            32.01291737731889,
                            46.964668377334114
                        ],
                        [
                            32.01291758626315,
                            46.96466828606165
                        ],
                        [
                            32.012917796764256,
                            46.964668198439455
                        ],
                        [
                            32.01291800875845,
                            46.964668114494074
                        ],
                        [
                            32.01291822218151,
                            46.96466803425093
                        ],
                        [
                            32.01291843696879,
                            46.964667957734335
                        ],
                        [
                            32.01291865305522,
                            46.96466788496747
                        ],
                        [
                            32.01291887037536,
                            46.96466781597236
                        ],
                        [
                            32.01291908886336,
                            46.964667750769934
                        ],
                        [
                            32.01291930845305,
                            46.964667689379915
                        ],
                        [
                            32.012919529077905,
                            46.964667631820916
                        ],
                        [
                            32.012919750671095,
                            46.96466757811037
                        ],
                        [
                            32.012919973165495,
                            46.96466752826454
                        ],
                        [
                            32.01292019649371,
                            46.96466748229854
                        ],
                        [
                            32.01292042058809,
                            46.96466744022627
                        ],
                        [
                            32.01292064538074,
                            46.9646674020605
                        ],
                        [
                            32.01292087080358,
                            46.96466736781277
                        ],
                        [
                            32.01292109678833,
                            46.964667337493474
                        ],
                        [
                            32.01292132326652,
                            46.96466731111178
                        ],
                        [
                            32.01292155016955,
                            46.96466728867569
                        ],
                        [
                            32.012921777428694,
                            46.964667270191995
                        ],
                        [
                            32.01292200497511,
                            46.96466725566629
                        ],
                        [
                            32.012922232739854,
                            46.96466724510299
                        ],
                        [
                            32.012922460653954,
                            46.96466723850527
                        ],
                        [
                            32.01292268864835,
                            46.964667235875154
                        ],
                        [
                            32.012922916654,
                            46.964667237213426
                        ],
                        [
                            32.01292314460182,
                            46.96466724251969
                        ],
                        [
                            32.01292337242276,
                            46.96466725179232
                        ],
                        [
                            32.01292360004782,
                            46.964667265028524
                        ],
                        [
                            32.012923827408045,
                            46.96466728222429
                        ],
                        [
                            32.012924054434556,
                            46.964667303374405
                        ],
                        [
                            32.01292428105859,
                            46.96466732847246
                        ],
                        [
                            32.012924507211494,
                            46.96466735751086
                        ],
                        [
                            32.012924732824764,
                            46.964667390480805
                        ],
                        [
                            32.01292495783006,
                            46.964667427372305
                        ],
                        [
                            32.012925182159215,
                            46.964667468174184
                        ],
                        [
                            32.012925405744284,
                            46.96466751287409
                        ],
                        [
                            32.012925628517536,
                            46.96466756145848
                        ],
                        [
                            32.01292585041149,
                            46.96466761391263
                        ],
                        [
                            32.01292607135893,
                            46.964667670220656
                        ],
                        [
                            32.01292629129293,
                            46.964667730365505
                        ],
                        [
                            32.012926510146855,
                            46.96466779432895
                        ],
                        [
                            32.01292672785442,
                            46.96466786209162
                        ],
                        [
                            32.01292694434968,
                            46.964667933632995
                        ],
                        [
                            32.012927159567056,
                            46.96466800893139
                        ],
                        [
                            32.01292737344134,
                            46.964668087964
                        ],
                        [
                            32.01292758590776,
                            46.96466817070689
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1259,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.011091923655975,
                            46.962474557920885
                        ],
                        [
                            32.011413312987735,
                            46.962455576493994
                        ],
                        [
                            32.011391681013485,
                            46.96234168779129
                        ],
                        [
                            32.01310862077377,
                            46.96222976306663
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1260,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01316917536676,
                            46.96273145845387
                        ],
                        [
                            32.01198501516444,
                            46.96279513211311
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1261,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01310862077377,
                            46.96222976306663
                        ],
                        [
                            32.013715573104726,
                            46.96225099846573
                        ],
                        [
                            32.0149238733809,
                            46.96218350863528
                        ],
                        [
                            32.01496713732941,
                            46.96260110059465
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1262,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.014250191897005,
                            46.96305032466922
                        ],
                        [
                            32.01430581697365,
                            46.963381440468936
                        ],
                        [
                            32.01355796872088,
                            46.963432056716336
                        ],
                        [
                            32.01359196182328,
                            46.96399937721273
                        ],
                        [
                            32.01366746977326,
                            46.96461856126776
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1263,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01367230915623,
                            46.96315366676302
                        ],
                        [
                            32.01421619879459,
                            46.963448928788175
                        ],
                        [
                            32.01433671979402,
                            46.96431150637005
                        ],
                        [
                            32.01475699815095,
                            46.96451396755238
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1264,
                id: 0,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.014151290330965,
                            46.963391899224014
                        ],
                        [
                            32.01415073674843,
                            46.96339258140076
                        ],
                        [
                            32.01415017145111,
                            46.96339325390183
                        ],
                        [
                            32.01414959460809,
                            46.963393916526066
                        ],
                        [
                            32.01414900639191,
                            46.96339456907527
                        ],
                        [
                            32.014148406978514,
                            46.963395211354275
                        ],
                        [
                            32.01414779654717,
                            46.96339584317096
                        ],
                        [
                            32.014147175280485,
                            46.96339646433635
                        ],
                        [
                            32.01414654336427,
                            46.963397074664655
                        ],
                        [
                            32.01414590098753,
                            46.96339767397331
                        ],
                        [
                            32.014145248342416,
                            46.963398262083075
                        ],
                        [
                            32.01414458562413,
                            46.96339883881804
                        ],
                        [
                            32.01414391303089,
                            46.963399404005685
                        ],
                        [
                            32.014143230763885,
                            46.96339995747698
                        ],
                        [
                            32.01414253902717,
                            46.96340049906637
                        ],
                        [
                            32.014141838027655,
                            46.963401028611855
                        ],
                        [
                            32.014141127975016,
                            46.96340154595506
                        ],
                        [
                            32.01414040908163,
                            46.963402050941234
                        ],
                        [
                            32.01413968156252,
                            46.96340254341934
                        ],
                        [
                            32.01413894563529,
                            46.96340302324207
                        ],
                        [
                            32.014138201520055,
                            46.96340349026591
                        ],
                        [
                            32.014137449439396,
                            46.96340394435117
                        ],
                        [
                            32.014136689618255,
                            46.963404385362026
                        ],
                        [
                            32.014135922283906,
                            46.96340481316658
                        ],
                        [
                            32.014135147665854,
                            46.96340522763686
                        ],
                        [
                            32.014134365995794,
                            46.963405628648914
                        ],
                        [
                            32.01413357750753,
                            46.96340601608278
                        ],
                        [
                            32.0141327824369,
                            46.96340638982258
                        ],
                        [
                            32.01413198102172,
                            46.96340674975652
                        ],
                        [
                            32.01413117350169,
                            46.963407095776965
                        ],
                        [
                            32.014130360118344,
                            46.963407427780396
                        ],
                        [
                            32.014129541114976,
                            46.96340774566751
                        ],
                        [
                            32.01412871673654,
                            46.963408049343236
                        ],
                        [
                            32.01412788722963,
                            46.96340833871674
                        ],
                        [
                            32.01412705284234,
                            46.96340861370147
                        ],
                        [
                            32.014126213824255,
                            46.96340887421516
                        ],
                        [
                            32.014125370426314,
                            46.96340912017992
                        ],
                        [
                            32.014124522900794,
                            46.96340935152215
                        ],
                        [
                            32.01412367150118,
                            46.96340956817267
                        ],
                        [
                            32.01412281648215,
                            46.96340977006668
                        ],
                        [
                            32.01412195809942,
                            46.96340995714379
                        ],
                        [
                            32.01412109660976,
                            46.96341012934804
                        ],
                        [
                            32.014120232270834,
                            46.963410286627926
                        ],
                        [
                            32.01411936534117,
                            46.963410428936406
                        ],
                        [
                            32.01411849608008,
                            46.96341055623092
                        ],
                        [
                            32.014117624747556,
                            46.96341066847338
                        ],
                        [
                            32.01411675160421,
                            46.96341076563022
                        ],
                        [
                            32.01411587691123,
                            46.963410847672385
                        ],
                        [
                            32.01411500093021,
                            46.96341091457533
                        ],
                        [
                            32.01411412392318,
                            46.963410966319046
                        ],
                        [
                            32.014113246152455,
                            46.96341100288805
                        ],
                        [
                            32.01411236788057,
                            46.963411024271416
                        ],
                        [
                            32.01411148937024,
                            46.963411030462744
                        ],
                        [
                            32.01411061088422,
                            46.963411021460175
                        ],
                        [
                            32.014109732685256,
                            46.96341099726641
                        ],
                        [
                            32.01410885503605,
                            46.96341095788868
                        ],
                        [
                            32.01410797819909,
                            46.96341090333876
                        ],
                        [
                            32.014107102436654,
                            46.963410833632985
                        ],
                        [
                            32.01410622801068,
                            46.96341074879218
                        ],
                        [
                            32.01410535518272,
                            46.96341064884174
                        ],
                        [
                            32.01410448421384,
                            46.96341053381154
                        ],
                        [
                            32.01410361536454,
                            46.96341040373601
                        ],
                        [
                            32.014102748894715,
                            46.963410258654044
                        ],
                        [
                            32.01410188506351,
                            46.96341009860904
                        ],
                        [
                            32.014101024129324,
                            46.96340992364886
                        ],
                        [
                            32.01410016634965,
                            46.96340973382584
                        ],
                        [
                            32.01409931198106,
                            46.96340952919677
                        ],
                        [
                            32.01409846127911,
                            46.963409309822836
                        ],
                        [
                            32.01409761449823,
                            46.96340907576966
                        ],
                        [
                            32.01409677189171,
                            46.96340882710726
                        ],
                        [
                            32.01409593371157,
                            46.963408563909994
                        ],
                        [
                            32.01409510020852,
                            46.963408286256595
                        ],
                        [
                            32.01409427163186,
                            46.96340799423012
                        ],
                        [
                            32.01409344822943,
                            46.96340768791789
                        ],
                        [
                            32.01409263024751,
                            46.963407367411556
                        ],
                        [
                            32.014091817930755,
                            46.96340703280696
                        ],
                        [
                            32.01409101152214,
                            46.963406684204195
                        ],
                        [
                            32.01409021126287,
                            46.963406321707524
                        ],
                        [
                            32.01408941739229,
                            46.96340594542537
                        ],
                        [
                            32.014088630147874,
                            46.96340555547029
                        ],
                        [
                            32.014087849765076,
                            46.963405151958916
                        ],
                        [
                            32.01408707647732,
                            46.963404735011935
                        ],
                        [
                            32.014086310515886,
                            46.96340430475407
                        ],
                        [
                            32.01408555210989,
                            46.963403861314006
                        ],
                        [
                            32.01408480148618,
                            46.963403404824376
                        ],
                        [
                            32.01408405886926,
                            46.963402935421726
                        ],
                        [
                            32.01408332448125,
                            46.96340245324645
                        ],
                        [
                            32.01408259854181,
                            46.96340195844277
                        ],
                        [
                            32.01408188126808,
                            46.96340145115869
                        ],
                        [
                            32.014081172874604,
                            46.963400931545934
                        ],
                        [
                            32.01408047357325,
                            46.96340039975992
                        ],
                        [
                            32.014079783573195,
                            46.96339985595972
                        ],
                        [
                            32.01407910308081,
                            46.96339930030798
                        ],
                        [
                            32.01407843229965,
                            46.96339873297089
                        ],
                        [
                            32.01407777143033,
                            46.96339815411815
                        ],
                        [
                            32.014077120670535,
                            46.9633975639229
                        ],
                        [
                            32.014076480214904,
                            46.963396962561674
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1265,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01423115450231,
                            46.96355596863485
                        ],
                        [
                            32.01505830064948,
                            46.96354277959056
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1266,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.016340767694516,
                            46.963496381461624
                        ],
                        [
                            32.01691370128825,
                            46.96346140862278
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1267,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01613680908012,
                            46.964137515679276
                        ],
                        [
                            32.01639639277117,
                            46.96412064382462
                        ],
                        [
                            32.016429272806064,
                            46.96444209508625
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1268,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01661580279574,
                            46.964559310316325
                        ],
                        [
                            32.01660355695708,
                            46.96442487999862
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1269,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01483425520186,
                            46.96451923996877
                        ],
                        [
                            32.014862067740175,
                            46.96473646307216
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1270,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01463749059275,
                            46.964456397115164
                        ],
                        [
                            32.01542140878874,
                            46.96415860549011
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1271,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.016967321788506,
                            46.96386483467775
                        ],
                        [
                            32.01745790465061,
                            46.96383171248784
                        ],
                        [
                            32.0174393629584,
                            46.963686191734624
                        ],
                        [
                            32.0179090858279,
                            46.9636545567359
                        ],
                        [
                            32.01795234977641,
                            46.96397090588138
                        ],
                        [
                            32.01849623941477,
                            46.963941380040296
                        ],
                        [
                            32.018539503363286,
                            46.9642745993011
                        ],
                        [
                            32.01904321933519,
                            46.96424507362762
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1272,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01772675918777,
                            46.96251568432266
                        ],
                        [
                            32.01814703754469,
                            46.962498811956515
                        ],
                        [
                            32.01823974600579,
                            46.96332555164011
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1273,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01822321534412,
                            46.96317813826431
                        ],
                        [
                            32.019253358513666,
                            46.96312730400292
                        ],
                        [
                            32.019679817434664,
                            46.96311043182966
                        ],
                        [
                            32.01982197040832,
                            46.96449815030008
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1274,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02131766691385,
                            46.962888984062424
                        ],
                        [
                            32.0214660004516,
                            46.96288265696989
                        ],
                        [
                            32.021478361579746,
                            46.96301341673016
                        ],
                        [
                            32.02192954275703,
                            46.96299232646786
                        ],
                        [
                            32.02192027191092,
                            46.96288265696989
                        ],
                        [
                            32.02223857096066,
                            46.96286789375107
                        ],
                        [
                            32.02222620983251,
                            46.96265066306027
                        ],
                        [
                            32.022031522064225,
                            46.9626612082598
                        ],
                        [
                            32.02198207755165,
                            46.96217612692988
                        ],
                        [
                            32.02183992457798,
                            46.962184563164506
                        ],
                        [
                            32.021771938373185,
                            46.96168682304566
                        ],
                        [
                            32.02099936786413,
                            46.96173744089639
                        ],
                        [
                            32.02101481927431,
                            46.96182602201991
                        ],
                        [
                            32.02008155409938,
                            46.9618914032313
                        ],
                        [
                            32.02005374156105,
                            46.96178384055081
                        ],
                        [
                            32.01921017319483,
                            46.9618350614886
                        ],
                        [
                            32.01927190020589,
                            46.96236805093844
                        ],
                        [
                            32.01929662246218,
                            46.96252833859376
                        ],
                        [
                            32.019284261334036,
                            46.96385280241921
                        ],
                        [
                            32.019237907103495,
                            46.96385491141189
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1275,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01900922623281,
                            46.96368408273527
                        ],
                        [
                            32.019205459142114,
                            46.96367142873753
                        ],
                        [
                            32.0192471779496,
                            46.96393083509308
                        ],
                        [
                            32.01946349769214,
                            46.96391818115369
                        ],
                        [
                            32.01948512966639,
                            46.96408057314847
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1276,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01928826904361,
                            46.9634255978749
                        ],
                        [
                            32.0199471268308,
                            46.96340253057781
                        ],
                        [
                            32.021649872232764,
                            46.96330973403646
                        ],
                        [
                            32.02167768477109,
                            46.96348689093028
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1277,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0197118791108,
                            46.96342151166873
                        ],
                        [
                            32.019711699000695,
                            46.963421515263036
                        ],
                        [
                            32.01971151885532,
                            46.963421515721585
                        ],
                        [
                            32.01971133872925,
                            46.96342151304423
                        ],
                        [
                            32.01971115867708,
                            46.963421507231786
                        ],
                        [
                            32.019710978753366,
                            46.96342149828602
                        ],
                        [
                            32.01971079901264,
                            46.96342148620963
                        ],
                        [
                            32.01971061950936,
                            46.96342147100629
                        ],
                        [
                            32.019710440297935,
                            46.9634214526806
                        ],
                        [
                            32.01971026143265,
                            46.96342143123811
                        ],
                        [
                            32.01971008296773,
                            46.96342140668533
                        ],
                        [
                            32.01970990495724,
                            46.96342137902968
                        ],
                        [
                            32.01970972745514,
                            46.96342134827955
                        ],
                        [
                            32.019709550515195,
                            46.963421314444275
                        ],
                        [
                            32.019709374191045,
                            46.96342127753409
                        ],
                        [
                            32.01970919853611,
                            46.963421237560176
                        ],
                        [
                            32.01970902360363,
                            46.96342119453466
                        ],
                        [
                            32.01970884944661,
                            46.96342114847057
                        ],
                        [
                            32.01970867611782,
                            46.963421099381875
                        ],
                        [
                            32.0197085036698,
                            46.96342104728344
                        ],
                        [
                            32.019708332154785,
                            46.96342099219106
                        ],
                        [
                            32.01970816162477,
                            46.96342093412143
                        ],
                        [
                            32.01970799213142,
                            46.96342087309215
                        ],
                        [
                            32.01970782372611,
                            46.9634208091217
                        ],
                        [
                            32.01970765645986,
                            46.963420742229474
                        ],
                        [
                            32.01970749038336,
                            46.963420672435745
                        ],
                        [
                            32.01970732554694,
                            46.96342059976166
                        ],
                        [
                            32.01970716200054,
                            46.96342052422924
                        ],
                        [
                            32.01970699979374,
                            46.96342044586138
                        ],
                        [
                            32.01970683897569,
                            46.963420364681824
                        ],
                        [
                            32.0197066795951,
                            46.96342028071517
                        ],
                        [
                            32.01970652170029,
                            46.96342019398687
                        ],
                        [
                            32.01970636533911,
                            46.963420104523195
                        ],
                        [
                            32.01970621055894,
                            46.963420012351264
                        ],
                        [
                            32.019706057406665,
                            46.96341991749901
                        ],
                        [
                            32.01970590592872,
                            46.96341981999517
                        ],
                        [
                            32.019705756170985,
                            46.963419719869286
                        ],
                        [
                            32.01970560817887,
                            46.963419617151715
                        ],
                        [
                            32.01970546199719,
                            46.963419511873575
                        ],
                        [
                            32.01970531767027,
                            46.96341940406677
                        ],
                        [
                            32.01970517524183,
                            46.96341929376396
                        ],
                        [
                            32.01970503475504,
                            46.96341918099859
                        ],
                        [
                            32.01970489625246,
                            46.963419065804814
                        ],
                        [
                            32.01970475977608,
                            46.96341894821755
                        ],
                        [
                            32.01970462536724,
                            46.96341882827243
                        ],
                        [
                            32.019704493066676,
                            46.9634187060058
                        ],
                        [
                            32.01970436291448,
                            46.963418581454704
                        ],
                        [
                            32.0197042349501,
                            46.963418454656896
                        ],
                        [
                            32.0197041092123,
                            46.96341832565079
                        ],
                        [
                            32.01970398573919,
                            46.963418194475494
                        ],
                        [
                            32.019703864568186,
                            46.96341806117075
                        ],
                        [
                            32.019703745736,
                            46.963417925776945
                        ],
                        [
                            32.01970362927866,
                            46.96341778833512
                        ],
                        [
                            32.01970351523144,
                            46.96341764888692
                        ],
                        [
                            32.01970340362891,
                            46.9634175074746
                        ],
                        [
                            32.01970329450488,
                            46.96341736414102
                        ],
                        [
                            32.019703187892425,
                            46.96341721892962
                        ],
                        [
                            32.01970308382385,
                            46.963417071884386
                        ],
                        [
                            32.01970298233069,
                            46.96341692304989
                        ],
                        [
                            32.01970288344371,
                            46.96341677247123
                        ],
                        [
                            32.01970278719287,
                            46.96341662019404
                        ],
                        [
                            32.01970269360733,
                            46.963416466264455
                        ],
                        [
                            32.019702602715455,
                            46.96341631072914
                        ],
                        [
                            32.0197025145448,
                            46.96341615363521
                        ],
                        [
                            32.01970242912206,
                            46.963415995030275
                        ],
                        [
                            32.019702346473146,
                            46.9634158349624
                        ],
                        [
                            32.019702266623085,
                            46.963415673480085
                        ],
                        [
                            32.01970218959608,
                            46.96341551063228
                        ],
                        [
                            32.01970211541548,
                            46.96341534646832
                        ],
                        [
                            32.01970204410375,
                            46.96341518103795
                        ],
                        [
                            32.01970197568251,
                            46.96341501439131
                        ],
                        [
                            32.01970191017249,
                            46.9634148465789
                        ],
                        [
                            32.01970184759355,
                            46.96341467765156
                        ],
                        [
                            32.01970178796465,
                            46.963414507660495
                        ],
                        [
                            32.01970173130385,
                            46.963414336657216
                        ],
                        [
                            32.019701677628326,
                            46.96341416469354
                        ],
                        [
                            32.01970162695435,
                            46.963413991821575
                        ],
                        [
                            32.01970157929727,
                            46.963413818093706
                        ],
                        [
                            32.01970153467153,
                            46.96341364356259
                        ],
                        [
                            32.01970149309066,
                            46.963413468281104
                        ],
                        [
                            32.01970145456724,
                            46.96341329230237
                        ],
                        [
                            32.01970141911297,
                            46.963413115679714
                        ],
                        [
                            32.019701386738575,
                            46.963412938466654
                        ],
                        [
                            32.019701357453876,
                            46.9634127607169
                        ],
                        [
                            32.01970133126774,
                            46.96341258248432
                        ],
                        [
                            32.01970130818811,
                            46.96341240382291
                        ],
                        [
                            32.019701288221974,
                            46.963412224786815
                        ],
                        [
                            32.01970127137538,
                            46.963412045430296
                        ],
                        [
                            32.019701257653445,
                            46.9634118658077
                        ],
                        [
                            32.01970124706031,
                            46.96341168597346
                        ],
                        [
                            32.0197012395992,
                            46.96341150598207
                        ],
                        [
                            32.01970123527237,
                            46.963411325888075
                        ],
                        [
                            32.01970123408113,
                            46.96341114574605
                        ],
                        [
                            32.01970123602585,
                            46.963410965610585
                        ],
                        [
                            32.01970124110593,
                            46.96341078553626
                        ],
                        [
                            32.01970124931983,
                            46.96341060557766
                        ],
                        [
                            32.019701260665066,
                            46.9634104257893
                        ],
                        [
                            32.019701275138196,
                            46.96341024622567
                        ],
                        [
                            32.019701292734844,
                            46.96341006694119
                        ],
                        [
                            32.019701313449666,
                            46.963409887990174
                        ],
                        [
                            32.01970133727639,
                            46.96340970942686
                        ],
                        [
                            32.0197013642078,
                            46.963409531305366
                        ],
                        [
                            32.01970139423572,
                            46.96340935367966
                        ],
                        [
                            32.019701427351066,
                            46.96340917660357
                        ],
                        [
                            32.0197014635438,
                            46.96340900013075
                        ],
                        [
                            32.019701502802945,
                            46.96340882431469
                        ],
                        [
                            32.01970154511661,
                            46.96340864920866
                        ],
                        [
                            32.01970159047198,
                            46.96340847486572
                        ],
                        [
                            32.0197016388553,
                            46.96340830133872
                        ],
                        [
                            32.019701690251914,
                            46.96340812868022
                        ],
                        [
                            32.01970174464625,
                            46.963407956942554
                        ],
                        [
                            32.01970180202181,
                            46.96340778617777
                        ],
                        [
                            32.019701862361224,
                            46.9634076164376
                        ],
                        [
                            32.019701925646196,
                            46.9634074477735
                        ],
                        [
                            32.01970199185755,
                            46.96340728023656
                        ],
                        [
                            32.019702060975234,
                            46.963407113877565
                        ],
                        [
                            32.01970213297829,
                            46.96340694874693
                        ],
                        [
                            32.019702207844894,
                            46.96340678489468
                        ],
                        [
                            32.01970228555238,
                            46.96340662237048
                        ],
                        [
                            32.019702366077176,
                            46.96340646122357
                        ],
                        [
                            32.01970244939489,
                            46.96340630150279
                        ],
                        [
                            32.019702535480285,
                            46.96340614325655
                        ],
                        [
                            32.01970262430726,
                            46.96340598653279
                        ],
                        [
                            32.019702715848894,
                            46.963405831379006
                        ],
                        [
                            32.01970281007746,
                            46.96340567784222
                        ],
                        [
                            32.0197029069644,
                            46.96340552596895
                        ],
                        [
                            32.01970300648035,
                            46.96340537580522
                        ],
                        [
                            32.01970310859515,
                            46.96340522739655
                        ],
                        [
                            32.01970321327787,
                            46.963405080787894
                        ],
                        [
                            32.019703320496774,
                            46.963404936023686
                        ],
                        [
                            32.01970343021937,
                            46.9634047931478
                        ],
                        [
                            32.01970354241242,
                            46.96340465220353
                        ],
                        [
                            32.019703657041916,
                            46.96340451323358
                        ],
                        [
                            32.01970377407312,
                            46.96340437628007
                        ],
                        [
                            32.019703893470584,
                            46.96340424138449
                        ],
                        [
                            32.01970401519811,
                            46.96340410858773
                        ],
                        [
                            32.01970413921881,
                            46.963403977930035
                        ],
                        [
                            32.01970426549511,
                            46.96340384945099
                        ],
                        [
                            32.01970439398873,
                            46.963403723189536
                        ],
                        [
                            32.01970452466075,
                            46.96340359918393
                        ],
                        [
                            32.01970465747157,
                            46.963403477471736
                        ],
                        [
                            32.01970479238093,
                            46.96340335808986
                        ],
                        [
                            32.01970492934796,
                            46.963403241074474
                        ],
                        [
                            32.01970506833115,
                            46.96340312646103
                        ],
                        [
                            32.01970520928838,
                            46.96340301428426
                        ],
                        [
                            32.01970535217694,
                            46.96340290457816
                        ],
                        [
                            32.01970549695353,
                            46.96340279737598
                        ],
                        [
                            32.01970564357427,
                            46.9634026927102
                        ],
                        [
                            32.01970579199474,
                            46.96340259061254
                        ],
                        [
                            32.019705942169956,
                            46.96340249111393
                        ],
                        [
                            32.01970609405441,
                            46.96340239424453
                        ],
                        [
                            32.01970624760209,
                            46.9634023000337
                        ],
                        [
                            32.019706402766445,
                            46.96340220850998
                        ],
                        [
                            32.01970655950046,
                            46.96340211970111
                        ],
                        [
                            32.01970671775665,
                            46.963402033634
                        ],
                        [
                            32.019706877487046,
                            46.96340195033473
                        ],
                        [
                            32.01970703864325,
                            46.963401869828544
                        ],
                        [
                            32.01970720117642,
                            46.963401792139834
                        ],
                        [
                            32.01970736503732,
                            46.96340171729215
                        ],
                        [
                            32.01970753017628,
                            46.96340164530817
                        ],
                        [
                            32.01970769654325,
                            46.963401576209705
                        ],
                        [
                            32.019707864087835,
                            46.9634015100177
                        ],
                        [
                            32.01970803275925,
                            46.9634014467522
                        ],
                        [
                            32.01970820250638,
                            46.963401386432395
                        ],
                        [
                            32.019708373277794,
                            46.963401329076554
                        ],
                        [
                            32.019708545021736,
                            46.96340127470206
                        ],
                        [
                            32.01970871768617,
                            46.96340122332539
                        ],
                        [
                            32.01970889121876,
                            46.96340117496211
                        ],
                        [
                            32.019709065566936,
                            46.96340112962688
                        ],
                        [
                            32.019709240677855,
                            46.96340108733344
                        ],
                        [
                            32.01970941649845,
                            46.963401048094596
                        ],
                        [
                            32.019709592975445,
                            46.96340101192225
                        ],
                        [
                            32.01970977005536,
                            46.96340097882735
                        ],
                        [
                            32.01970994768453,
                            46.96340094881994
                        ],
                        [
                            32.01970956139926,
                            46.9634010476798
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1278,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01929860217417,
                            46.962521352382076
                        ],
                        [
                            32.02002945387565,
                            46.96249709835664
                        ],
                        [
                            32.020060356696014,
                            46.962809236278986
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1279,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0197312416592,
                            46.96363228041304
                        ],
                        [
                            32.020367839758585,
                            46.963596427376935
                        ],
                        [
                            32.02035238834842,
                            46.963480432095515
                        ],
                        [
                            32.02116513252387,
                            46.96343192490323
                        ],
                        [
                            32.02118676449812,
                            46.963617517401076
                        ],
                        [
                            32.022401245338216,
                            46.963533157254616
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1280,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.021872807110086,
                            46.96412789345026
                        ],
                        [
                            32.02131037577955,
                            46.96417218205137
                        ],
                        [
                            32.021319646625656,
                            46.964269195049496
                        ],
                        [
                            32.01936658837896,
                            46.9644336949486
                        ],
                        [
                            32.01777509313046,
                            46.964515944708474
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1281,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02007426296519,
                            46.9572310781889
                        ],
                        [
                            32.01847658715263,
                            46.95736396101053
                        ],
                        [
                            32.018600198434065,
                            46.95823929198583
                        ],
                        [
                            32.01882424388166,
                            46.960355846385156
                        ],
                        [
                            32.01887368839424,
                            46.96062159617815
                        ],
                        [
                            32.01908382757268,
                            46.96061737793777
                        ],
                        [
                            32.01912709152118,
                            46.960950617902554
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1282,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.018047037949636,
                            46.957625507187565
                        ],
                        [
                            32.018509953131776,
                            46.95760023757959
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1283,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01133494536764,
                            46.94913936905129
                        ],
                        [
                            32.01129786198321,
                            46.948966383810486
                        ],
                        [
                            32.010364596808365,
                            46.949050766924614
                        ],
                        [
                            32.01032751342394,
                            46.948928411365735
                        ],
                        [
                            32.00949313727424,
                            46.94905920522873
                        ],
                        [
                            32.00959159643925,
                            46.9495548811488
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1284,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01032751342394,
                            46.948928411365735
                        ],
                        [
                            32.01082813911375,
                            46.948603534903796
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1285,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01097647265148,
                            46.94924906671919
                        ],
                        [
                            32.010917693491876,
                            46.949000757552305
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1286,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00989487393891,
                            46.9491583552022
                        ],
                        [
                            32.009863596631654,
                            46.94900113336564
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1287,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00559629162695,
                            46.949567610595885
                        ],
                        [
                            32.00523828090182,
                            46.94958658015844
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1288,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.998687188686095,
                            46.94925638463735
                        ],
                        [
                            31.998687519261583,
                            46.949256738500836
                        ],
                        [
                            31.998687855938844,
                            46.94925708656396
                        ],
                        [
                            31.998688198616126,
                            46.94925742872153
                        ],
                        [
                            31.998688547189847,
                            46.949257764870126
                        ],
                        [
                            31.99868890155466,
                            46.94925809490815
                        ],
                        [
                            31.99868926160346,
                            46.94925841873586
                        ],
                        [
                            31.99868962722742,
                            46.949258736255366
                        ],
                        [
                            31.998689998316042,
                            46.94925904737071
                        ],
                        [
                            31.998690374757157,
                            46.949259351987855
                        ],
                        [
                            31.998690756436996,
                            46.94925965001473
                        ],
                        [
                            31.998691143240194,
                            46.94925994136127
                        ],
                        [
                            31.998691535049847,
                            46.949260225939405
                        ],
                        [
                            31.99869193174753,
                            46.94926050366313
                        ],
                        [
                            31.99869233321334,
                            46.94926077444851
                        ],
                        [
                            31.99869273932595,
                            46.94926103821369
                        ],
                        [
                            31.9986931499626,
                            46.94926129487895
                        ],
                        [
                            31.99869356499919,
                            46.94926154436673
                        ],
                        [
                            31.998693984310272,
                            46.94926178660161
                        ],
                        [
                            31.99869440776911,
                            46.94926202151038
                        ],
                        [
                            31.998694835247726,
                            46.949262249022034
                        ],
                        [
                            31.998695266616906,
                            46.949262469067826
                        ],
                        [
                            31.998695701746282,
                            46.949262681581224
                        ],
                        [
                            31.998696140504332,
                            46.94926288649802
                        ],
                        [
                            31.99869658275845,
                            46.94926308375627
                        ],
                        [
                            31.99869702837496,
                            46.94926327329635
                        ],
                        [
                            31.998697477219185,
                            46.94926345506098
                        ],
                        [
                            31.998697929155465,
                            46.94926362899522
                        ],
                        [
                            31.998698384047202,
                            46.9492637950465
                        ],
                        [
                            31.99869884175691,
                            46.949263953164625
                        ],
                        [
                            31.998699302146246,
                            46.94926410330182
                        ],
                        [
                            31.99869976507607,
                            46.94926424541269
                        ],
                        [
                            31.998700230406453,
                            46.949264379454306
                        ],
                        [
                            31.998700697996757,
                            46.94926450538613
                        ],
                        [
                            31.99870116770566,
                            46.949264623170116
                        ],
                        [
                            31.998701639391193,
                            46.94926473277065
                        ],
                        [
                            31.99870211291079,
                            46.949264834154626
                        ],
                        [
                            31.998702588121336,
                            46.94926492729139
                        ],
                        [
                            31.9987030648792,
                            46.949265012152786
                        ],
                        [
                            31.99870354304029,
                            46.949265088713176
                        ],
                        [
                            31.99870402246008,
                            46.949265156949416
                        ],
                        [
                            31.99870450299367,
                            46.94926521684088
                        ],
                        [
                            31.998704984495824,
                            46.94926526836947
                        ],
                        [
                            31.998705466821008,
                            46.94926531151961
                        ],
                        [
                            31.99870594982345,
                            46.94926534627826
                        ],
                        [
                            31.998706433357157,
                            46.94926537263492
                        ],
                        [
                            31.99870691727599,
                            46.94926539058161
                        ],
                        [
                            31.99870740143368,
                            46.94926540011291
                        ],
                        [
                            31.998707885683906,
                            46.949265401225944
                        ],
                        [
                            31.9987083698803,
                            46.94926539392038
                        ],
                        [
                            31.99870885387652,
                            46.94926537819841
                        ],
                        [
                            31.99870933752628,
                            46.9492653540648
                        ],
                        [
                            31.998709820683395,
                            46.94926532152684
                        ],
                        [
                            31.998710303201843,
                            46.94926528059436
                        ],
                        [
                            31.99871078493578,
                            46.94926523127974
                        ],
                        [
                            31.99871126573961,
                            46.94926517359788
                        ],
                        [
                            31.99871174546801,
                            46.94926510756621
                        ],
                        [
                            31.99871222397599,
                            46.949265033204696
                        ],
                        [
                            31.99871270111892,
                            46.94926495053581
                        ],
                        [
                            31.998713176752585,
                            46.94926485958454
                        ],
                        [
                            31.998713650733233,
                            46.94926476037837
                        ],
                        [
                            31.998714122917605,
                            46.949264652947285
                        ],
                        [
                            31.998714593162987,
                            46.94926453732376
                        ],
                        [
                            31.998715061327253,
                            46.949264413542735
                        ],
                        [
                            31.998715527268896,
                            46.94926428164163
                        ],
                        [
                            31.9987159908471,
                            46.94926414166031
                        ],
                        [
                            31.99871645192174,
                            46.94926399364107
                        ],
                        [
                            31.99871691035347,
                            46.94926383762866
                        ],
                        [
                            31.99871736600372,
                            46.94926367367024
                        ],
                        [
                            31.998717818734786,
                            46.94926350181535
                        ],
                        [
                            31.998718268409828,
                            46.94926332211595
                        ],
                        [
                            31.99871871489293,
                            46.94926313462633
                        ],
                        [
                            31.998719158049155,
                            46.94926293940317
                        ],
                        [
                            31.998719597744554,
                            46.949262736505474
                        ],
                        [
                            31.998720033846237,
                            46.94926252599457
                        ],
                        [
                            31.998720466222395,
                            46.94926230793408
                        ],
                        [
                            31.99872089474234,
                            46.9492620823899
                        ],
                        [
                            31.998721319276566,
                            46.94926184943022
                        ],
                        [
                            31.998721739696748,
                            46.94926160912544
                        ],
                        [
                            31.99872215587583,
                            46.94926136154819
                        ],
                        [
                            31.99872256768801,
                            46.9492611067733
                        ],
                        [
                            31.998722975008832,
                            46.94926084487777
                        ],
                        [
                            31.99872337771518,
                            46.94926057594076
                        ],
                        [
                            31.998723775685345,
                            46.949260300043555
                        ],
                        [
                            31.99872416879904,
                            46.94926001726954
                        ],
                        [
                            31.998724556937447,
                            46.94925972770418
                        ],
                        [
                            31.998724939983255,
                            46.949259431435
                        ],
                        [
                            31.998725317820696,
                            46.949259128551546
                        ],
                        [
                            31.998725690335565,
                            46.949258819145356
                        ],
                        [
                            31.998726057415276,
                            46.94925850330995
                        ],
                        [
                            31.99872641894888,
                            46.94925818114078
                        ],
                        [
                            31.998726774827105,
                            46.94925785273522
                        ],
                        [
                            31.99872712494239,
                            46.949257518192546
                        ],
                        [
                            31.99872746918892,
                            46.94925717761385
                        ],
                        [
                            31.998727807462643,
                            46.949256831102076
                        ],
                        [
                            31.99872813966132,
                            46.94925647876196
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1289,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00063020839524,
                            46.949884043033464
                        ],
                        [
                            32.00004305480842,
                            46.94980809941905
                        ],
                        [
                            32.00008554618642,
                            46.94961191124974
                        ],
                        [
                            32.00053672736366,
                            46.94962878767968
                        ],
                        [
                            32.000567210929105,
                            46.94929453890783
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1290,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00055791221934,
                            46.94939649840689
                        ],
                        [
                            32.00072600713837,
                            46.94940095542665
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1291,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00002142283417,
                            46.9493861884879
                        ],
                        [
                            32.000032183925185,
                            46.94928060141715
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1292,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00015353239121,
                            46.94939392355156
                        ],
                        [
                            31.999887768136116,
                            46.94938267254949
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1293,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99933228994017,
                            46.94937142154506
                        ],
                        [
                            31.99934033928621,
                            46.949262578810774
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1294,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99943967724092,
                            46.949375640672
                        ],
                        [
                            31.999218207028353,
                            46.94936790560569
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1295,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.034659520379996,
                            46.93836929708208
                        ],
                        [
                            32.03537646581232,
                            46.938541847681726
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1296,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03473094023149,
                            46.93799230959832
                        ],
                        [
                            32.03598078763267,
                            46.93830927935187
                        ],
                        [
                            32.03569785514405,
                            46.938836307746975
                        ],
                        [
                            32.03590112702908,
                            46.93889632488644
                        ],
                        [
                            32.035557762358415,
                            46.93953962937507
                        ],
                        [
                            32.03831566739311,
                            46.9402429417646
                        ],
                        [
                            32.03878539026256,
                            46.939357704399725
                        ],
                        [
                            32.03866177898112,
                            46.93932582057751
                        ],
                        [
                            32.03881835327095,
                            46.93904449191149
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1297,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03491839665112,
                            46.93803984993369
                        ],
                        [
                            32.03506881106742,
                            46.93776536560438
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1298,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03517556943604,
                            46.93849349707033
                        ],
                        [
                            32.03553304010214,
                            46.937872273225494
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1299,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03728557338115,
                            46.93934832680692
                        ],
                        [
                            32.03779924692844,
                            46.93948336398481
                        ],
                        [
                            32.037479295195276,
                            46.940029653696385
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1300,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.036291189294936,
                            46.93916452565645
                        ],
                        [
                            32.0368076097596,
                            46.939288310174106
                        ],
                        [
                            32.03653871411554,
                            46.9397897897088
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1301,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03916171794158,
                            46.938579358608074
                        ],
                        [
                            32.039381471330806,
                            46.938147981368566
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1302,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.039280377360186,
                            46.938346429879346
                        ],
                        [
                            32.03661807246139,
                            46.937709098349636
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1303,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03706856690928,
                            46.93796042496303
                        ],
                        [
                            32.03734325864581,
                            46.93740900532577
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1304,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03804251441454,
                            46.93805009771508
                        ],
                        [
                            32.03814535851645,
                            46.93786289537253
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1305,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03800251881347,
                            46.938699393395765
                        ],
                        [
                            32.03844751942663,
                            46.93880442361427
                        ],
                        [
                            32.03900239673441,
                            46.93781788165546
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1306,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0378102345979,
                            46.93833178600858
                        ],
                        [
                            32.038613248561795,
                            46.93850976804446
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1307,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03653566494043,
                            46.93873690421175
                        ],
                        [
                            32.03695105801043,
                            46.93883700602371
                        ],
                        [
                            32.03742842529265,
                            46.937903090235345
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1308,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.036815850511694,
                            46.938271768236326
                        ],
                        [
                            32.03719116376312,
                            46.938367267963095
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1309,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.039280377360186,
                            46.938346429879346
                        ],
                        [
                            32.03991437329965,
                            46.93862437168501
                        ],
                        [
                            32.040112151349945,
                            46.938736904211765
                        ],
                        [
                            32.04127135047807,
                            46.93903323873411
                        ],
                        [
                            32.04148011619783,
                            46.939160774606016
                        ],
                        [
                            32.04217783320859,
                            46.93935207784426
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1310,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04538073885645,
                            46.94030295732736
                        ],
                        [
                            32.045916387742665,
                            46.94042861469399
                        ],
                        [
                            32.045952097668405,
                            46.94034609347154
                        ],
                        [
                            32.04479592715371,
                            46.9400494678411
                        ],
                        [
                            32.04284258721098,
                            46.93962590285866
                        ],
                        [
                            32.0431255196996,
                            46.93905574508651
                        ],
                        [
                            32.04066977557509,
                            46.93846307465089
                        ],
                        [
                            32.04079063993915,
                            46.93824175932498
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1311,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04240857426728,
                            46.93906137167314
                        ],
                        [
                            32.042570642391816,
                            46.938750032991145
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1312,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04277391427685,
                            46.93944585369179
                        ],
                        [
                            32.04301001593855,
                            46.939027869501814
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1313,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03976603976193,
                            46.93960339674591
                        ],
                        [
                            32.039925360969114,
                            46.93964278243701
                        ],
                        [
                            32.04005446608527,
                            46.93940459233911
                        ],
                        [
                            32.04034014549126,
                            46.93938208613334
                        ],
                        [
                            32.04045276910324,
                            46.939153272504306
                        ],
                        [
                            32.040233015714016,
                            46.93910075776296
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1314,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04139221484214,
                            46.940404233436965
                        ],
                        [
                            32.041754807934346,
                            46.93975531282337
                        ],
                        [
                            32.04201851200141,
                            46.93982283094165
                        ],
                        [
                            32.04182622778584,
                            46.9401641712364
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1315,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04017426376319,
                            46.93939515451893
                        ],
                        [
                            32.04091974505532,
                            46.939694358893455
                        ],
                        [
                            32.041680084250714,
                            46.93988904392019
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1316,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04091974505532,
                            46.939694358893455
                        ],
                        [
                            32.04146938674273,
                            46.93865605390436
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1317,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.040952708063706,
                            46.939276119287356
                        ],
                        [
                            32.04112085544601,
                            46.939314452050596
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1318,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03849147010446,
                            46.944165379945275
                        ],
                        [
                            32.039073816585876,
                            46.94313392751766
                        ],
                        [
                            32.03947486652121,
                            46.942443780978344
                        ],
                        [
                            32.03976054592719,
                            46.94186615149207
                        ],
                        [
                            32.04001326232479,
                            46.941404793594565
                        ],
                        [
                            32.04037036158227,
                            46.941281013970034
                        ],
                        [
                            32.04054616429364,
                            46.94097719004203
                        ],
                        [
                            32.04068900399663,
                            46.94056083593302
                        ],
                        [
                            32.04113708850376,
                            46.93975000980592
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1319,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04016159586251,
                            46.94115723405934
                        ],
                        [
                            32.04062857181461,
                            46.94127351216541
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1320,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04073844850921,
                            46.93979657390577
                        ],
                        [
                            32.04106807951955,
                            46.93987488501347
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1321,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04089170993407,
                            46.940194033274004
                        ],
                        [
                            32.040406071508016,
                            46.940066645659954
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1322,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04078740829947,
                            46.94038277061939
                        ],
                        [
                            32.041024127915186,
                            46.94044361853897
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1323,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04059901894022,
                            46.94082312786659
                        ],
                        [
                            32.040785146104405,
                            46.940865599956524
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1324,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03899964981702,
                            46.94101001061114
                        ],
                        [
                            32.03942542200863,
                            46.940252319200724
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1325,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03920868501901,
                            46.94063801918084
                        ],
                        [
                            32.04054616429364,
                            46.94097719004203
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1326,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03959573088527,
                            46.94102126337306
                        ],
                        [
                            32.03981273735712,
                            46.94064054361437
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1327,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04019976927103,
                            46.940889348066
                        ],
                        [
                            32.04027696639185,
                            46.94075494737319
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1328,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04146938674273,
                            46.93865605390436
                        ],
                        [
                            32.04158190440849,
                            46.938436450987055
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1329,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04113708850376,
                            46.93975000980592
                        ],
                        [
                            32.04119152085685,
                            46.93963492484629
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1330,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.043182416215274,
                            46.938902236354785
                        ],
                        [
                            32.04283070420483,
                            46.93881634637746
                        ],
                        [
                            32.041882108673015,
                            46.940664703324884
                        ],
                        [
                            32.04231597239393,
                            46.940776882252194
                        ],
                        [
                            32.04235961549011,
                            46.94078739901458
                        ],
                        [
                            32.04241609479105,
                            46.94084173558731
                        ],
                        [
                            32.0423981241044,
                            46.940911847212604
                        ],
                        [
                            32.042195312069175,
                            46.94126240396183
                        ],
                        [
                            32.042097756913,
                            46.94141664820433
                        ],
                        [
                            32.041789687998744,
                            46.942037126235995
                        ],
                        [
                            32.041581741481615,
                            46.942406955766636
                        ],
                        [
                            32.04146878287973,
                            46.94261202601668
                        ],
                        [
                            32.04101951571311,
                            46.94250335640936
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1331,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.041694700083525,
                            46.94091710558081
                        ],
                        [
                            32.042309496635355,
                            46.94106503839117
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1332,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04214910173201,
                            46.940589332976145
                        ],
                        [
                            32.042081350925,
                            46.94071621902829
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1333,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.045789449402164,
                            46.939569193632636
                        ],
                        [
                            32.04543777298147,
                            46.940214139189735
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1334,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.045157908127955,
                            46.939499080249135
                        ],
                        [
                            32.04484036014405,
                            46.940060867542705
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1335,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04425167207185,
                            46.939563935132064
                        ],
                        [
                            32.04398467901284,
                            46.94004070375179
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1336,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.041305686945144,
                            46.9416823589849
                        ],
                        [
                            32.04162982319423,
                            46.9417611272795
                        ],
                        [
                            32.04177266289722,
                            46.941524822048024
                        ],
                        [
                            32.04248136757743,
                            46.94169361160551
                        ],
                        [
                            32.04272309630556,
                            46.94175737641095
                        ],
                        [
                            32.04210229298104,
                            46.94284136648399
                        ],
                        [
                            32.04250334291635,
                            46.94308141668055
                        ],
                        [
                            32.04200889779063,
                            46.943955340334945
                        ],
                        [
                            32.04198142861698,
                            46.94417288134458
                        ],
                        [
                            32.04185507041817,
                            46.944378231745176
                        ],
                        [
                            32.042802756909154,
                            46.9446210880516
                        ],
                        [
                            32.04297306578579,
                            46.94432384678424
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1337,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04232479328763,
                            46.94409599195079
                        ],
                        [
                            32.042122802961245,
                            46.944446841610315
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1338,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.042036366964275,
                            46.94339835630698
                        ],
                        [
                            32.041945718691224,
                            46.94357651681159
                        ],
                        [
                            32.04219038084302,
                            46.94363457367202
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1339,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04185507041817,
                            46.944378231745176
                        ],
                        [
                            32.041182075663706,
                            46.94421226367424
                        ],
                        [
                            32.040278339850595,
                            46.9439834706877
                        ],
                        [
                            32.039825098485345,
                            46.94386719846732
                        ],
                        [
                            32.03906969620993,
                            46.9436702852601
                        ],
                        [
                            32.038295065512955,
                            46.94347337132855
                        ],
                        [
                            32.03772095978365,
                            46.943328967318436
                        ],
                        [
                            32.037539663237546,
                            46.943282082815784
                        ],
                        [
                            32.037166082475885,
                            46.943205192142585
                        ],
                        [
                            32.036710094193275,
                            46.94308516745635
                        ],
                        [
                            32.036506822308255,
                            46.943032656571525
                        ],
                        [
                            32.036369476439994,
                            46.94299655530831
                        ],
                        [
                            32.03593821041366,
                            46.94372607740901
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1340,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04113537806853,
                            46.94369982228734
                        ],
                        [
                            32.04098155069608,
                            46.9439586222102
                        ],
                        [
                            32.04064093294279,
                            46.94387798178871
                        ],
                        [
                            32.040599729182325,
                            46.943754207882215
                        ],
                        [
                            32.03988003683265,
                            46.943564795743974
                        ],
                        [
                            32.038964206341774,
                            46.94332807076422
                        ],
                        [
                            32.03782122226748,
                            46.94305563008996
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1341,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.038158403039056,
                            46.943136000344985
                        ],
                        [
                            32.038095914003996,
                            46.94324691941186
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1342,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03945045568895,
                            46.943453757217526
                        ],
                        [
                            32.039393918300135,
                            46.94355108209162
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1343,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.040146573658106,
                            46.94195511715393
                        ],
                        [
                            32.04132225429025,
                            46.94225330928176
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1344,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.040514660584996,
                            46.94238083748348
                        ],
                        [
                            32.04075913623046,
                            46.941947615443766
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1345,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04116825092747,
                            46.94221424894679
                        ],
                        [
                            32.04101951571311,
                            46.94250335640936
                        ],
                        [
                            32.04072067938736,
                            46.94302785088535
                        ],
                        [
                            32.040616296527496,
                            46.94325102181022
                        ],
                        [
                            32.0403691266365,
                            46.943693516853884
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1346,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.039512035746846,
                            46.94303910322315
                        ],
                        [
                            32.04075089547838,
                            46.94334104007124
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1347,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.040174042831765,
                            46.942939707490666
                        ],
                        [
                            32.040055158526044,
                            46.94317147417331
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1348,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.039356834915736,
                            46.941635355825554
                        ],
                        [
                            32.03972766875999,
                            46.941731003109325
                        ],
                        [
                            32.03965350199114,
                            46.94186978513876
                        ],
                        [
                            32.03974810692459,
                            46.94189130266364
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1349,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.039716943864484,
                            46.9419543130875
                        ],
                        [
                            32.03863714256615,
                            46.94169724526399
                        ],
                        [
                            32.03832948782129,
                            46.94228237823684
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1350,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03903269866669,
                            46.942087334623125
                        ],
                        [
                            32.03958191114603,
                            46.942227342825014
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1351,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.039301896568446,
                            46.94243991294456
                        ],
                        [
                            32.039774366355196,
                            46.94255993907741
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1352,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03916785008539,
                            46.942972110825195
                        ],
                        [
                            32.03862203452064,
                            46.94283468538416
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1353,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.036506822308255,
                            46.943032656571525
                        ],
                        [
                            32.03660030334014,
                            46.942874068698245
                        ],
                        [
                            32.036855766655066,
                            46.94293595670471
                        ],
                        [
                            32.03712221763946,
                            46.942528994865796
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1354,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03658392098227,
                            46.94290186092962
                        ],
                        [
                            32.03592730858575,
                            46.94273904007217
                        ],
                        [
                            32.03635582769467,
                            46.94192135944993
                        ],
                        [
                            32.03671567386946,
                            46.942011379946706
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1355,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03635582769467,
                            46.94192135944993
                        ],
                        [
                            32.03592456166839,
                            46.941818210777946
                        ],
                        [
                            32.03583940723008,
                            46.94180133188545
                        ],
                        [
                            32.03576524046123,
                            46.9419232348785
                        ],
                        [
                            32.03475712178834,
                            46.941701933854915
                        ],
                        [
                            32.03443573245666,
                            46.942303945515896
                        ],
                        [
                            32.03420087102196,
                            46.94224908959346
                        ],
                        [
                            32.034389034861455,
                            46.94187634914479
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1356,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03499198322303,
                            46.942343329220435
                        ],
                        [
                            32.03600645688369,
                            46.94258801383966
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1357,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.035393575007824,
                            46.941841647422414
                        ],
                        [
                            32.03549741601817,
                            46.941632542667605
                        ],
                        [
                            32.03493704487573,
                            46.941482507360575
                        ],
                        [
                            32.035005717809845,
                            46.941354977018996
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1358,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03515130443019,
                            46.940456629682785
                        ],
                        [
                            32.03692031921314,
                            46.94087486006737
                        ],
                        [
                            32.036890103122126,
                            46.94097425963343
                        ],
                        [
                            32.03829419832896,
                            46.941322261043055
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1359,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03755897750046,
                            46.940511956311305
                        ],
                        [
                            32.03791332984053,
                            46.94060573012736
                        ],
                        [
                            32.03808363871715,
                            46.94066574528347
                        ],
                        [
                            32.03802870036984,
                            46.94086079407613
                        ],
                        [
                            32.03782842692275,
                            46.941206821040765
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1360,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03692031921314,
                            46.94087486006736
                        ],
                        [
                            32.03706453237479,
                            46.940631986766434
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1361,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.036890103122126,
                            46.94097425963343
                        ],
                        [
                            32.03653437732338,
                            46.940902054306605
                        ],
                        [
                            32.03644098213297,
                            46.94108960040802
                        ],
                        [
                            32.037045303953235,
                            46.94124526317325
                        ],
                        [
                            32.03735295869811,
                            46.941344662051634
                        ],
                        [
                            32.03720737207777,
                            46.94163348038696
                        ],
                        [
                            32.03736944020229,
                            46.9416784908963
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1362,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03646223935467,
                            46.94076656116408
                        ],
                        [
                            32.036627772513775,
                            46.94047632221814
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1363,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03601741572853,
                            46.94066139607987
                        ],
                        [
                            32.036251444834804,
                            46.940219380949586
                        ],
                        [
                            32.03578446888278,
                            46.9400768436557
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1364,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03563888226247,
                            46.9446453501799
                        ],
                        [
                            32.03555372782415,
                            46.94481037924445
                        ],
                        [
                            32.03569107369239,
                            46.94484413512679
                        ],
                        [
                            32.03536968436072,
                            46.945483617543424
                        ],
                        [
                            32.03494391216916,
                            46.94628061572682
                        ],
                        [
                            32.03475986870573,
                            46.94665191906389
                        ],
                        [
                            32.03487523923504,
                            46.94668192326148
                        ],
                        [
                            32.03476124216441,
                            46.94688820166469
                        ],
                        [
                            32.03454011531653,
                            46.94683475688198
                        ],
                        [
                            32.034492044262656,
                            46.94691633047627
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1365,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03629397773776,
                            46.94504534024897
                        ],
                        [
                            32.03566611205039,
                            46.944893802671764
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1366,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03305540648085,
                            46.944341545344415
                        ],
                        [
                            32.03393991387232,
                            46.94457971347876
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1367,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.033632259127465,
                            46.944152135284284
                        ],
                        [
                            32.03319767401103,
                            46.94496895877911
                        ],
                        [
                            32.03536968436072,
                            46.945483617543424
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1368,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.034860131189554,
                            46.944877890987904
                        ],
                        [
                            32.03461813757074,
                            46.94530553877583
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1369,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03438491448543,
                            46.94472786477581
                        ],
                        [
                            32.034119294007525,
                            46.94518733762856
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1370,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03239065247857,
                            46.94639500743348
                        ],
                        [
                            32.03272577639707,
                            46.946479394601354
                        ],
                        [
                            32.03306914106768,
                            46.94582679703945
                        ],
                        [
                            32.035085378413456,
                            46.946243110196036
                        ],
                        [
                            32.03541226157988,
                            46.94634437506891
                        ],
                        [
                            32.0349342979584,
                            46.94723512264108
                        ],
                        [
                            32.03471454456921,
                            46.947178865338195
                        ],
                        [
                            32.03403056214537,
                            46.947006342574
                        ],
                        [
                            32.03358830844963,
                            46.94689382742822
                        ],
                        [
                            32.03176160840202,
                            46.94644751502025
                        ],
                        [
                            32.03068206987765,
                            46.946168099055626
                        ],
                        [
                            32.030785373261914,
                            46.94597258887848
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1371,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03413494500522,
                            46.94561301334951
                        ],
                        [
                            32.03446732200637,
                            46.945699277046465
                        ],
                        [
                            32.03403880289746,
                            46.94647564406341
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1372,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.033467444085574,
                            46.946383755801065
                        ],
                        [
                            32.0337062973045,
                            46.94595835755749
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1373,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02968219195683,
                            46.945706778230935
                        ],
                        [
                            32.03155009576492,
                            46.9461568473755
                        ],
                        [
                            32.03182478750141,
                            46.94558675915329
                        ],
                        [
                            32.03120947801168,
                            46.945436734927824
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1374,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03152809556703,
                            46.94551442003597
                        ],
                        [
                            32.0320747569816,
                            46.94447469458951
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1375,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.030355186711226,
                            46.944174639484956
                        ],
                        [
                            32.030695804464465,
                            46.94475318172861
                        ],
                        [
                            32.032113213824715,
                            46.945059797206106
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1376,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.031004832668,
                            46.94432935560826
                        ],
                        [
                            32.030695804464465,
                            46.94475318172861
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1377,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02923581788507,
                            46.94459377849297
                        ],
                        [
                            32.02992254722628,
                            46.944770059690484
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1378,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.029081990512644,
                            46.945302650420544
                        ],
                        [
                            32.030384029343566,
                            46.945643955778564
                        ],
                        [
                            32.03028056775753,
                            46.9458509565623
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1379,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02928838740505,
                            46.94535675368732
                        ],
                        [
                            32.029776960605936,
                            46.94444937750505
                        ],
                        [
                            32.02971652842391,
                            46.944038676462014
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1380,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03543072464611,
                            46.946635785719586
                        ],
                        [
                            32.03597812655703,
                            46.94677474864137
                        ],
                        [
                            32.03607976249953,
                            46.946572220332435
                        ],
                        [
                            32.036414886418044,
                            46.94666035774624
                        ],
                        [
                            32.03653025694736,
                            46.9464240741397
                        ],
                        [
                            32.03682143018805,
                            46.9459327509203
                        ],
                        [
                            32.036962208825884,
                            46.94571231946923
                        ],
                        [
                            32.037368066743646,
                            46.94580710647219
                        ],
                        [
                            32.03771280487293,
                            46.94516200197506
                        ],
                        [
                            32.03697388410179,
                            46.94497822078269
                        ],
                        [
                            32.03688323582875,
                            46.94516200197506
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1381,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03642999446356,
                            46.945974007241944
                        ],
                        [
                            32.03667309665034,
                            46.94553331307124
                        ],
                        [
                            32.03808913255191,
                            46.94587555460364
                        ],
                        [
                            32.03804243495671,
                            46.94595806730326
                        ],
                        [
                            32.038435244139876,
                            46.94605370686414
                        ],
                        [
                            32.0386055530165,
                            46.945755535904404
                        ],
                        [
                            32.03984715966539,
                            46.94604620572829
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1382,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03599186114387,
                            46.946851634187645
                        ],
                        [
                            32.036118219342654,
                            46.946624727745395
                        ],
                        [
                            32.036453343261165,
                            46.94670911455113
                        ],
                        [
                            32.03631325047556,
                            46.94700352969831
                        ],
                        [
                            32.03727467155325,
                            46.947245436473594
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1383,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03653025694736,
                            46.9464240741397
                        ],
                        [
                            32.03628852821927,
                            46.94635656434631
                        ],
                        [
                            32.036317370851606,
                            46.946310620132635
                        ],
                        [
                            32.03723896162751,
                            46.94654502897337
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1384,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03655224986501,
                            46.946370362274706
                        ],
                        [
                            32.03655234022926,
                            46.94637055183257
                        ],
                        [
                            32.03655243387121,
                            46.946370739792854
                        ],
                        [
                            32.03655253076261,
                            46.946370926098886
                        ],
                        [
                            32.036552630874276,
                            46.94637111069449
                        ],
                        [
                            32.036552734176,
                            46.946371293524024
                        ],
                        [
                            32.036552840636645,
                            46.94637147453235
                        ],
                        [
                            32.03655295022411,
                            46.94637165366489
                        ],
                        [
                            32.03655306290536,
                            46.946371830867655
                        ],
                        [
                            32.036553178646415,
                            46.9463720060872
                        ],
                        [
                            32.03655329741238,
                            46.94637217927071
                        ],
                        [
                            32.036553419167454,
                            46.94637235036596
                        ],
                        [
                            32.036553543874916,
                            46.94637251932137
                        ],
                        [
                            32.03655367149718,
                            46.946372686086
                        ],
                        [
                            32.03655380199576,
                            46.94637285060956
                        ],
                        [
                            32.036553935331305,
                            46.946373012842464
                        ],
                        [
                            32.03655407146362,
                            46.94637317273579
                        ],
                        [
                            32.03655421035167,
                            46.94637333024132
                        ],
                        [
                            32.03655435195356,
                            46.94637348531158
                        ],
                        [
                            32.03655449622662,
                            46.94637363789981
                        ],
                        [
                            32.03655464312733,
                            46.946373787960006
                        ],
                        [
                            32.03655479261142,
                            46.94637393544693
                        ],
                        [
                            32.0365549446338,
                            46.9463740803161
                        ],
                        [
                            32.03655509914865,
                            46.94637422252385
                        ],
                        [
                            32.036555256109374,
                            46.9463743620273
                        ],
                        [
                            32.036555415468655,
                            46.94637449878439
                        ],
                        [
                            32.03655557717845,
                            46.94637463275389
                        ],
                        [
                            32.036555741189986,
                            46.9463747638954
                        ],
                        [
                            32.03655590745383,
                            46.946374892169395
                        ],
                        [
                            32.03655607591985,
                            46.946375017537186
                        ],
                        [
                            32.036556246537245,
                            46.946375139960985
                        ],
                        [
                            32.03655641925458,
                            46.94637525940387
                        ],
                        [
                            32.03655659401978,
                            46.946375375829845
                        ],
                        [
                            32.03655677078016,
                            46.946375489203795
                        ],
                        [
                            32.03655694948241,
                            46.94637559949154
                        ],
                        [
                            32.036557130072666,
                            46.94637570665982
                        ],
                        [
                            32.03655731249648,
                            46.946375810676344
                        ],
                        [
                            32.03655749669883,
                            46.94637591150973
                        ],
                        [
                            32.03655768262421,
                            46.946376009129594
                        ],
                        [
                            32.03655787021655,
                            46.946376103506495
                        ],
                        [
                            32.03655805941928,
                            46.94637619461197
                        ],
                        [
                            32.03655825017536,
                            46.94637628241857
                        ],
                        [
                            32.036558442427285,
                            46.946376366899806
                        ],
                        [
                            32.03655863611709,
                            46.946376448030215
                        ],
                        [
                            32.03655883118637,
                            46.946376525785325
                        ],
                        [
                            32.03655902757632,
                            46.946376600141704
                        ],
                        [
                            32.03655922522772,
                            46.946376671076926
                        ],
                        [
                            32.036559424080984,
                            46.94637673856961
                        ],
                        [
                            32.03655962407615,
                            46.94637680259941
                        ],
                        [
                            32.03655982515293,
                            46.94637686314701
                        ],
                        [
                            32.03656002725069,
                            46.94637692019416
                        ],
                        [
                            32.0365602303085,
                            46.946376973723666
                        ],
                        [
                            32.03656043426514,
                            46.94637702371938
                        ],
                        [
                            32.03656063905912,
                            46.94637707016623
                        ],
                        [
                            32.03656084462868,
                            46.94637711305022
                        ],
                        [
                            32.03656105091186,
                            46.94637715235841
                        ],
                        [
                            32.03656125784645,
                            46.94637718807895
                        ],
                        [
                            32.03656146537006,
                            46.94637722020107
                        ],
                        [
                            32.03656167342014,
                            46.946377248715095
                        ],
                        [
                            32.03656188193394,
                            46.94637727361242
                        ],
                        [
                            32.036562090848605,
                            46.946377294885544
                        ],
                        [
                            32.03656230010114,
                            46.94637731252804
                        ],
                        [
                            32.03656250962846,
                            46.9463773265346
                        ],
                        [
                            32.036562719367396,
                            46.946377336901
                        ],
                        [
                            32.03656292925471,
                            46.946377343624114
                        ],
                        [
                            32.036563139227106,
                            46.946377346701915
                        ],
                        [
                            32.0365633492213,
                            46.946377346133474
                        ],
                        [
                            32.036563559173956,
                            46.94637734191896
                        ],
                        [
                            32.03656376902179,
                            46.94637733405965
                        ],
                        [
                            32.03656397870153,
                            46.9463773225579
                        ],
                        [
                            32.03656418814995,
                            46.946377307417194
                        ],
                        [
                            32.036564397303906,
                            46.94637728864209
                        ],
                        [
                            32.03656460610034,
                            46.94637726623825
                        ],
                        [
                            32.03656481447629,
                            46.94637724021243
                        ],
                        [
                            32.03656502236895,
                            46.94637721057247
                        ],
                        [
                            32.03656522971562,
                            46.946377177327314
                        ],
                        [
                            32.03656543645379,
                            46.94637714048698
                        ],
                        [
                            32.03656564252113,
                            46.946377100062584
                        ],
                        [
                            32.03656584785552,
                            46.946377056066304
                        ],
                        [
                            32.03656605239504,
                            46.946377008511405
                        ],
                        [
                            32.03656625607802,
                            46.946376957412234
                        ],
                        [
                            32.036566458843055,
                            46.946376902784195
                        ],
                        [
                            32.036566660629006,
                            46.94637684464375
                        ],
                        [
                            32.03656686137504,
                            46.94637678300844
                        ],
                        [
                            32.036567061020634,
                            46.94637671789683
                        ],
                        [
                            32.03656725950559,
                            46.94637664932858
                        ],
                        [
                            32.03656745677006,
                            46.94637657732434
                        ],
                        [
                            32.03656765275457,
                            46.94637650190582
                        ],
                        [
                            32.03656784740004,
                            46.94637642309577
                        ],
                        [
                            32.03656804064778,
                            46.94637634091794
                        ],
                        [
                            32.036568232439514,
                            46.946376255397126
                        ],
                        [
                            32.03656842271744,
                            46.94637616655909
                        ],
                        [
                            32.036568611424165,
                            46.94637607443063
                        ],
                        [
                            32.036568798502806,
                            46.946375979039516
                        ],
                        [
                            32.036568983896956,
                            46.946375880414514
                        ],
                        [
                            32.03656916755072,
                            46.946375778585356
                        ],
                        [
                            32.03656934940872,
                            46.94637567358274
                        ],
                        [
                            32.03656952941614,
                            46.94637556543834
                        ],
                        [
                            32.036569707518694,
                            46.946375454184746
                        ],
                        [
                            32.036569883662686,
                            46.94637533985551
                        ],
                        [
                            32.03657005779502,
                            46.94637522248509
                        ],
                        [
                            32.03657022986318,
                            46.94637510210888
                        ],
                        [
                            32.03657039981529,
                            46.94637497876318
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1385,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.041108338100535,
                            46.94763173494873
                        ],
                        [
                            32.0408061771904,
                            46.94823555513589
                        ],
                        [
                            32.040756732677835,
                            46.94844932835208
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1386,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04081473437984,
                            46.948218455056924
                        ],
                        [
                            32.0394478265535,
                            46.94787645171504
                        ],
                        [
                            32.0395961600912,
                            46.94751265780339
                        ],
                        [
                            32.037950756589666,
                            46.947079477292014
                        ],
                        [
                            32.037269521083196,
                            46.94691820572995
                        ],
                        [
                            32.03693439716468,
                            46.947492030239474
                        ],
                        [
                            32.03611032195524,
                            46.94727450271799
                        ],
                        [
                            32.03437976401539,
                            46.9502935556875
                        ],
                        [
                            32.03472724906204,
                            46.950580450618766
                        ],
                        [
                            32.035251910278724,
                            46.95072108482731
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1387,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03846717705425,
                            46.947512657803365
                        ],
                        [
                            32.038613366019405,
                            46.947253920937634
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1388,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03590658624648,
                            46.9476299396479
                        ],
                        [
                            32.03834905960756,
                            46.94824774398256
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1389,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.037659853928695,
                            46.94807341557482
                        ],
                        [
                            32.03728874950474,
                            46.94881780386536
                        ],
                        [
                            32.038920418419444,
                            46.949230343414946
                        ],
                        [
                            32.03877483179911,
                            46.94950036758019
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1390,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03747680710186,
                            46.94844058552984
                        ],
                        [
                            32.0380881024579,
                            46.94859653134232
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1391,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.036665199262934,
                            46.94849902112653
                        ],
                        [
                            32.03698397578855,
                            46.9479024576973
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1392,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03520383922484,
                            46.94825149439636
                        ],
                        [
                            32.03545655562241,
                            46.94774893660474
                        ],
                        [
                            32.03578990530249,
                            46.947833499937936
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1393,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03744257687716,
                            46.949299724754106
                        ],
                        [
                            32.03525675566083,
                            46.94876361708185
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1394,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03567457901327,
                            46.94886609536808
                        ],
                        [
                            32.035901556235494,
                            46.948431513950005
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1395,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0351891139465,
                            46.94888162166677
                        ],
                        [
                            32.03553346930861,
                            46.94916846268521
                        ],
                        [
                            32.036011432930096,
                            46.94928284822045
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1396,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03491921580598,
                            46.949352470804186
                        ],
                        [
                            32.03526564486555,
                            46.94964663010094
                        ],
                        [
                            32.03575734307385,
                            46.94975913946029
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1397,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03464966215591,
                            46.94982271482883
                        ],
                        [
                            32.03501842230271,
                            46.95012479324486
                        ],
                        [
                            32.03550462667629,
                            46.95023917673631
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1398,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04542099836331,
                            46.951041729441016
                        ],
                        [
                            32.04575062844708,
                            46.9509048463029
                        ],
                        [
                            32.04626430199431,
                            46.95109048225496
                        ],
                        [
                            32.046593932078096,
                            46.95147862807411
                        ],
                        [
                            32.04611871537398,
                            46.95167363747157
                        ],
                        [
                            32.04621211056438,
                            46.951782392403814
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1399,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.046291771167965,
                            46.951791767818676
                        ],
                        [
                            32.046412635532015,
                            46.951926773610374
                        ],
                        [
                            32.046766987872076,
                            46.95178801765292
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1400,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03702289953608,
                            46.95073222340306
                        ],
                        [
                            32.03752704458615,
                            46.949808362230684
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1401,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03800949657805,
                            46.95094839394886
                        ],
                        [
                            32.037631427446016,
                            46.951632854315015
                        ],
                        [
                            32.037834699331015,
                            46.951685356763704
                        ],
                        [
                            32.037719328801685,
                            46.951885990646694
                        ],
                        [
                            32.03740343330474,
                            46.951809112331816
                        ],
                        [
                            32.03725235284966,
                            46.952103499427174
                        ],
                        [
                            32.03759571752026,
                            46.95219162773315
                        ],
                        [
                            32.03715071690717,
                            46.95301477660417
                        ],
                        [
                            32.038222014679455,
                            46.9532772815542
                        ],
                        [
                            32.03897192312005,
                            46.95346103425309
                        ],
                        [
                            32.03971908464329,
                            46.95293789991076
                        ],
                        [
                            32.03946362132836,
                            46.95273164482185
                        ],
                        [
                            32.039337263129575,
                            46.95271101926926
                        ],
                        [
                            32.039065318310456,
                            46.95252726399514
                        ],
                        [
                            32.040043220892336,
                            46.95190099128063
                        ],
                        [
                            32.04024588281364,
                            46.95152209253742
                        ],
                        [
                            32.04079862316767,
                            46.9504552858624
                        ],
                        [
                            32.04084806768023,
                            46.95036152929882
                        ],
                        [
                            32.04106782106941,
                            46.950050256329966
                        ],
                        [
                            32.04140019807056,
                            46.9494727089176
                        ],
                        [
                            32.04114473475563,
                            46.949412703634245
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1402,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04091193350898,
                            46.948897968049536
                        ],
                        [
                            32.04085974207904,
                            46.94900485344157
                        ],
                        [
                            32.04121134750174,
                            46.9490911116725
                        ],
                        [
                            32.04121134750174,
                            46.94918299528732
                        ],
                        [
                            32.04145032931249,
                            46.94924487600025
                        ],
                        [
                            32.04136792179153,
                            46.94939488955284
                        ],
                        [
                            32.04149153307296,
                            46.9494267673786
                        ],
                        [
                            32.04157668751127,
                            46.94927300357339
                        ],
                        [
                            32.04192829293397,
                            46.9493630117081
                        ],
                        [
                            32.041700298792684,
                            46.9497774222089
                        ],
                        [
                            32.04149153307296,
                            46.94972679304376
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1403,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.041400198070576,
                            46.94947276751651
                        ],
                        [
                            32.04143316107894,
                            46.949411766051206
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1404,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04173793720495,
                            46.949314282205115
                        ],
                        [
                            32.04183764466094,
                            46.94913090057864
                        ],
                        [
                            32.04211508331478,
                            46.94919653169939
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1405,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04186671715443,
                            46.94947493441628
                        ],
                        [
                            32.04249415791113,
                            46.949614693807476
                        ],
                        [
                            32.042538108588964,
                            46.94952843642034
                        ],
                        [
                            32.04301057837571,
                            46.94963907086994
                        ],
                        [
                            32.04310946740085,
                            46.94944967955415
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1406,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04380993132889,
                            46.94962031928441
                        ],
                        [
                            32.04391593115933,
                            46.94996514951352
                        ],
                        [
                            32.04402419088334,
                            46.95002722721502
                        ],
                        [
                            32.0441835120905,
                            46.94991471841921
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1407,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04391593115933,
                            46.94996514951352
                        ],
                        [
                            32.0433931033564,
                            46.950336178587605
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1408,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04319736875652,
                            46.94980127181063
                        ],
                        [
                            32.04304354138409,
                            46.95010317051849
                        ],
                        [
                            32.04349380890082,
                            46.950403305851204
                        ],
                        [
                            32.04285125716856,
                            46.95082134507289
                        ],
                        [
                            32.04256283084524,
                            46.95065820966229
                        ],
                        [
                            32.042288139108756,
                            46.950605706206
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1409,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04216727474472,
                            46.95005347923335
                        ],
                        [
                            32.042293632943505,
                            46.95007973123881
                        ],
                        [
                            32.042133528968215,
                            46.95040808913541
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1410,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04197499052919,
                            46.95037412784665
                        ],
                        [
                            32.042631503779376,
                            46.950514762597315
                        ],
                        [
                            32.04266020614077,
                            46.95071328568541
                        ],
                        [
                            32.04221603252795,
                            46.95102385730465
                        ],
                        [
                            32.041724334319646,
                            46.951361375988064
                        ],
                        [
                            32.04136723506223,
                            46.95160888833644
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1411,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04155127852567,
                            46.95130887322155
                        ],
                        [
                            32.041724334319646,
                            46.95136137598806
                        ],
                        [
                            32.041891896278905,
                            46.95150950851591
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1412,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.041754550410666,
                            46.95081946995609
                        ],
                        [
                            32.04221603252795,
                            46.95102385730464
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1413,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02837680532042,
                            46.960335896638455
                        ],
                        [
                            32.02835246934939,
                            46.960096246050334
                        ],
                        [
                            32.02831770367649,
                            46.95986608556413
                        ],
                        [
                            32.02819950038863,
                            46.959870831151164
                        ],
                        [
                            32.028119539340956,
                            46.959232545916485
                        ],
                        [
                            32.028265555167145,
                            46.95923017309472
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1414,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.026645474810046,
                            46.95782781702062
                        ],
                        [
                            32.02808477366808,
                            46.95774713890337
                        ],
                        [
                            32.02805022124899,
                            46.95747094348691
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1415,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02723301468205,
                            46.96050436324102
                        ],
                        [
                            32.02691251149256,
                            46.957812848618865
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1416,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02809867993723,
                            46.95838544185788
                        ],
                        [
                            32.02782055455405,
                            46.95839256043525
                        ],
                        [
                            32.02772483217611,
                            46.9577673149899
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1417,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02685754541474,
                            46.95855628745354
                        ],
                        [
                            32.02712176452877,
                            46.95854205034139
                        ],
                        [
                            32.02710785825961,
                            46.95836408612007
                        ],
                        [
                            32.02741379618113,
                            46.958345103234834
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1418,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.027092897870055,
                            46.959327710021824
                        ],
                        [
                            32.0274937572288,
                            46.959308476157545
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1419,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02711500782271,
                            46.95951338358602
                        ],
                        [
                            32.028148401896296,
                            46.95946294076081
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1420,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.027567040940895,
                            46.959629447630704
                        ],
                        [
                            32.02754537652964,
                            46.959492376103924
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1421,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.027507663497964,
                            46.96001794414039
                        ],
                        [
                            32.02700703780822,
                            46.96004048561105
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1422,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02601621613058,
                            46.95786815603361
                        ],
                        [
                            32.02591539567918,
                            46.95721798235434
                        ],
                        [
                            32.02738250707553,
                            46.95711120125028
                        ],
                        [
                            32.027299069460554,
                            46.95663661598743
                        ],
                        [
                            32.0247333628006,
                            46.95682170474063
                        ],
                        [
                            32.024788987877244,
                            46.95713493040293
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1423,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02613789598573,
                            46.957106455418476
                        ],
                        [
                            32.02797352351481,
                            46.956983063644124
                        ],
                        [
                            32.02791789843817,
                            46.956589157229644
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1424,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02274176692876,
                            46.96242481003264
                        ],
                        [
                            32.023518737103686,
                            46.96238563646427
                        ],
                        [
                            32.023534381656475,
                            46.96238563646426
                        ],
                        [
                            32.02359869815134,
                            46.96287203403909
                        ],
                        [
                            32.02364910837704,
                            46.962951518100446
                        ],
                        [
                            32.02380729218873,
                            46.962989480595546
                        ],
                        [
                            32.02433051556587,
                            46.96295033177204
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1425,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02292128378473,
                            46.96322489651287
                        ],
                        [
                            32.02308894647261,
                            46.96319501144879
                        ],
                        [
                            32.02395345287202,
                            46.96315388556921
                        ],
                        [
                            32.024736839367996,
                            46.96311592319076
                        ],
                        [
                            32.025900330554336,
                            46.963041580121626
                        ],
                        [
                            32.02650525326277,
                            46.96299887235435
                        ],
                        [
                            32.026665175358104,
                            46.96300203589383
                        ],
                        [
                            32.026871451683974,
                            46.96296565517844
                        ],
                        [
                            32.027045280048455,
                            46.96297198226114
                        ],
                        [
                            32.0272144729899,
                            46.963011526511174
                        ],
                        [
                            32.027538952603614,
                            46.96300203589383
                        ],
                        [
                            32.02935372072893,
                            46.96291029317273
                        ],
                        [
                            32.02937226242114,
                            46.96305107073194
                        ],
                        [
                            32.02957158561243,
                            46.963039998353075
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1426,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02935372072893,
                            46.962910293172726
                        ],
                        [
                            32.02931200192145,
                            46.962551229630876
                        ],
                        [
                            32.02949278342052,
                            46.96254015714855
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1427,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02949278342052,
                            46.96254015714855
                        ],
                        [
                            32.02943715834389,
                            46.96212414650928
                        ],
                        [
                            32.029761637957606,
                            46.96210832855042
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1428,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02831294177538,
                            46.96296290811943
                        ],
                        [
                            32.02841041213762,
                            46.96375811646607
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1429,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02835518611154,
                            46.963307558425434
                        ],
                        [
                            32.02703137377931,
                            46.96337533223989
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1430,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02182579369063,
                            46.963474982942635
                        ],
                        [
                            32.021803775431124,
                            46.96330059409101
                        ],
                        [
                            32.02292128378473,
                            46.96322489651287
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1431,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02311783121852,
                            46.96522318637309
                        ],
                        [
                            32.021540715172875,
                            46.96530873363714
                        ],
                        [
                            32.02157007285221,
                            46.9656008203823
                        ],
                        [
                            32.02125177380256,
                            46.96563245423008
                        ],
                        [
                            32.02089948165054,
                            46.96582647475405
                        ],
                        [
                            32.01939760458136,
                            46.96590450454896
                        ],
                        [
                            32.019354340632866,
                            46.9655712954408
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1432,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02048873741186,
                            46.96584781491986
                        ],
                        [
                            32.02046684216559,
                            46.96564088991968
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1433,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.018328366997125,
                            46.96583491041304
                        ],
                        [
                            32.01852923532942,
                            46.96582436583909
                        ],
                        [
                            32.01868220429016,
                            46.96650764993424
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1434,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01862464989889,
                            46.96625056612258
                        ],
                        [
                            32.01942078169661,
                            46.96620818699159
                        ],
                        [
                            32.019445503952895,
                            46.96641907656241
                        ],
                        [
                            32.01960619861873,
                            46.96641274988738
                        ],
                        [
                            32.019575295798376,
                            46.96615335556697
                        ],
                        [
                            32.02040658166589,
                            46.96610485079837
                        ],
                        [
                            32.02044057476828,
                            46.96636213646023
                        ],
                        [
                            32.02115442991844,
                            46.96631784967218
                        ],
                        [
                            32.02116679104658,
                            46.96643383880123
                        ],
                        [
                            32.02186519478658,
                            46.96638744317979
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1435,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02115442991844,
                            46.96631784967218
                        ],
                        [
                            32.021148249354376,
                            46.96624192937879
                        ],
                        [
                            32.022257660605064,
                            46.96617444458308
                        ],
                        [
                            32.022226757784715,
                            46.965872870862036
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1436,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02064202569473,
                            46.966349638670124
                        ],
                        [
                            32.02060435971615,
                            46.96608587065954
                        ],
                        [
                            32.021061721457386,
                            46.96605634598578
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1437,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01960619861873,
                            46.96641274988738
                        ],
                        [
                            32.019955400488726,
                            46.966394824304075
                        ],
                        [
                            32.02003729296266,
                            46.9669325891903
                        ],
                        [
                            32.021111165969955,
                            46.96686721413987
                        ],
                        [
                            32.021115801393,
                            46.966995855292076
                        ],
                        [
                            32.02180493428688,
                            46.96697265773012
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1438,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01999020282,
                            46.96662336210388
                        ],
                        [
                            32.01998974715513,
                            46.966623469002
                        ],
                        [
                            32.01998929342427,
                            46.96662358383222
                        ],
                        [
                            32.01998884176546,
                            46.96662370655957
                        ],
                        [
                            32.01998839231616,
                            46.96662383714671
                        ],
                        [
                            32.019987945213124,
                            46.96662397555392
                        ],
                        [
                            32.019987500592414,
                            46.96662412173907
                        ],
                        [
                            32.019987058589315,
                            46.966624275657686
                        ],
                        [
                            32.01998661933833,
                            46.966624437262915
                        ],
                        [
                            32.01998618297313,
                            46.9666246065056
                        ],
                        [
                            32.019985749626485,
                            46.966624783334225
                        ],
                        [
                            32.01998531943027,
                            46.966624967695
                        ],
                        [
                            32.01998489251538,
                            46.9666251595318
                        ],
                        [
                            32.019984469011746,
                            46.966625358786274
                        ],
                        [
                            32.01998404904822,
                            46.96662556539778
                        ],
                        [
                            32.01998363275259,
                            46.966625779303435
                        ],
                        [
                            32.019983220251554,
                            46.96662600043817
                        ],
                        [
                            32.01998281167062,
                            46.96662622873468
                        ],
                        [
                            32.01998240713412,
                            46.9666264641235
                        ],
                        [
                            32.019982006765154,
                            46.966626706533006
                        ],
                        [
                            32.01998161068555,
                            46.96662695588943
                        ],
                        [
                            32.01998121901583,
                            46.96662721211689
                        ],
                        [
                            32.019980831875195,
                            46.96662747513742
                        ],
                        [
                            32.01998044938143,
                            46.96662774487099
                        ],
                        [
                            32.019980071650934,
                            46.966628021235515
                        ],
                        [
                            32.01997969879865,
                            46.9666283041469
                        ],
                        [
                            32.01997933093803,
                            46.966628593519054
                        ],
                        [
                            32.01997896818102,
                            46.96662888926393
                        ],
                        [
                            32.019978610638,
                            46.96662919129152
                        ],
                        [
                            32.01997825841777,
                            46.96662949950993
                        ],
                        [
                            32.019977911627514,
                            46.96662981382537
                        ],
                        [
                            32.01997757037275,
                            46.966630134142186
                        ],
                        [
                            32.01997723475733,
                            46.966630460362914
                        ],
                        [
                            32.01997690488337,
                            46.966630792388294
                        ],
                        [
                            32.01997658085126,
                            46.96663113011728
                        ],
                        [
                            32.01997626275959,
                            46.9666314734471
                        ],
                        [
                            32.01997595070516,
                            46.96663182227329
                        ],
                        [
                            32.01997564478293,
                            46.9666321764897
                        ],
                        [
                            32.01997534508599,
                            46.966632535988545
                        ],
                        [
                            32.01997505170553,
                            46.96663290066043
                        ],
                        [
                            32.019974764730826,
                            46.96663327039439
                        ],
                        [
                            32.019974484249204,
                            46.966633645077906
                        ],
                        [
                            32.01997421034602,
                            46.96663402459698
                        ],
                        [
                            32.01997394310462,
                            46.96663440883611
                        ],
                        [
                            32.01997368260631,
                            46.966634797678374
                        ],
                        [
                            32.01997342893038,
                            46.966635191005466
                        ],
                        [
                            32.01997318215401,
                            46.966635588697685
                        ],
                        [
                            32.01997294235229,
                            46.96663599063401
                        ],
                        [
                            32.0199727095982,
                            46.966636396692145
                        ],
                        [
                            32.01997248396256,
                            46.96663680674853
                        ],
                        [
                            32.019972265514035,
                            46.96663722067838
                        ],
                        [
                            32.01997205431909,
                            46.966637638355735
                        ],
                        [
                            32.019971850442,
                            46.9666380596535
                        ],
                        [
                            32.01997165394479,
                            46.96663848444348
                        ],
                        [
                            32.01997146488727,
                            46.96663891259641
                        ],
                        [
                            32.01997128332696,
                            46.966639343982
                        ],
                        [
                            32.01997110931911,
                            46.966639778468995
                        ],
                        [
                            32.019970942916665,
                            46.96664021592517
                        ],
                        [
                            32.019970784170276,
                            46.966640656217415
                        ],
                        [
                            32.01997063312823,
                            46.96664109921174
                        ],
                        [
                            32.019970489836496,
                            46.96664154477336
                        ],
                        [
                            32.01997035433868,
                            46.96664199276669
                        ],
                        [
                            32.01997022667601,
                            46.9666424430554
                        ],
                        [
                            32.019970106887335,
                            46.96664289550247
                        ],
                        [
                            32.019969995009106,
                            46.96664334997022
                        ],
                        [
                            32.01996989107537,
                            46.96664380632036
                        ],
                        [
                            32.019969795117746,
                            46.966644264414036
                        ],
                        [
                            32.01996970716544,
                            46.96664472411184
                        ],
                        [
                            32.0199696272452,
                            46.96664518527388
                        ],
                        [
                            32.01996955538137,
                            46.96664564775985
                        ],
                        [
                            32.01996949159581,
                            46.966646111429
                        ],
                        [
                            32.01996943590792,
                            46.96664657614024
                        ],
                        [
                            32.01996938833465,
                            46.96664704175216
                        ],
                        [
                            32.019969348890484,
                            46.96664750812308
                        ],
                        [
                            32.01996931758741,
                            46.96664797511109
                        ],
                        [
                            32.01996929443497,
                            46.96664844257407
                        ],
                        [
                            32.0199692794402,
                            46.966648910369784
                        ],
                        [
                            32.01996927260766,
                            46.96664937835589
                        ],
                        [
                            32.01996927393944,
                            46.96664984638998
                        ],
                        [
                            32.01996928343512,
                            46.96665031432962
                        ],
                        [
                            32.01996930109182,
                            46.96665078203243
                        ],
                        [
                            32.01996932690417,
                            46.96665124935608
                        ],
                        [
                            32.0199693608643,
                            46.96665171615838
                        ],
                        [
                            32.0199694029619,
                            46.96665218229728
                        ],
                        [
                            32.01996945318414,
                            46.96665264763093
                        ],
                        [
                            32.01996951151575,
                            46.96665311201773
                        ],
                        [
                            32.019969577938966,
                            46.96665357531637
                        ],
                        [
                            32.01996965243359,
                            46.96665403738588
                        ],
                        [
                            32.01996973497694,
                            46.966654498085646
                        ],
                        [
                            32.01996982554392,
                            46.96665495727547
                        ],
                        [
                            32.019969924106945,
                            46.96665541481564
                        ],
                        [
                            32.01997003063604,
                            46.966655870566925
                        ],
                        [
                            32.01997014509878,
                            46.966656324390634
                        ],
                        [
                            32.01997026746034,
                            46.966656776148675
                        ],
                        [
                            32.01997039768348,
                            46.96665722570357
                        ],
                        [
                            32.01997053572858,
                            46.966657672918544
                        ],
                        [
                            32.01997068155364,
                            46.96665811765749
                        ],
                        [
                            32.019970835114265,
                            46.96665855978509
                        ],
                        [
                            32.01997099636374,
                            46.96665899916679
                        ],
                        [
                            32.019971165253,
                            46.96665943566891
                        ],
                        [
                            32.019971341730646,
                            46.966659869158605
                        ],
                        [
                            32.01997152574298,
                            46.96666029950397
                        ],
                        [
                            32.019971717234014,
                            46.96666072657406
                        ],
                        [
                            32.019971916145465,
                            46.96666115023892
                        ],
                        [
                            32.01997212241682,
                            46.966661570369624
                        ],
                        [
                            32.019972335985294,
                            46.96666198683833
                        ],
                        [
                            32.019972556785916,
                            46.966662399518306
                        ],
                        [
                            32.01997278475148,
                            46.966662808283985
                        ],
                        [
                            32.01997301981264,
                            46.96666321301097
                        ],
                        [
                            32.019973261897846,
                            46.9666636135761
                        ],
                        [
                            32.01997351093344,
                            46.96666400985751
                        ],
                        [
                            32.01997376684365,
                            46.96666440173458
                        ],
                        [
                            32.01997402955059,
                            46.96666478908809
                        ],
                        [
                            32.019974298974326,
                            46.96666517180016
                        ],
                        [
                            32.019974575032876,
                            46.96666554975433
                        ],
                        [
                            32.01997485764223,
                            46.966665922835595
                        ],
                        [
                            32.0199751467164,
                            46.966666290930426
                        ],
                        [
                            32.019975442167414,
                            46.96666665392681
                        ],
                        [
                            32.01997574390537,
                            46.96666701171429
                        ],
                        [
                            32.019976051838455,
                            46.966667364184
                        ],
                        [
                            32.01997636587296,
                            46.96666771122867
                        ],
                        [
                            32.01997668591333,
                            46.96666805274271
                        ],
                        [
                            32.019977011862174,
                            46.9666683886222
                        ],
                        [
                            32.019977343620305,
                            46.96666871876492
                        ],
                        [
                            32.01997768108678,
                            46.966669043070425
                        ],
                        [
                            32.0199780241589,
                            46.96666936144001
                        ],
                        [
                            32.019978372732275,
                            46.96666967377681
                        ],
                        [
                            32.01997872670084,
                            46.96666997998579
                        ],
                        [
                            32.01997908595687,
                            46.966670279973755
                        ],
                        [
                            32.01997945039106,
                            46.96667057364943
                        ],
                        [
                            32.019979819892505,
                            46.96667086092344
                        ],
                        [
                            32.01998019434877,
                            46.96667114170839
                        ],
                        [
                            32.0199805736459,
                            46.96667141591882
                        ],
                        [
                            32.0199809576685,
                            46.96667168347129
                        ],
                        [
                            32.01998134629969,
                            46.966671944284386
                        ],
                        [
                            32.019981739421226,
                            46.96667219827876
                        ],
                        [
                            32.01998213691347,
                            46.96667244537709
                        ],
                        [
                            32.01998253865548,
                            46.96667268550422
                        ],
                        [
                            32.019982944525005,
                            46.96667291858706
                        ],
                        [
                            32.019983354398526,
                            46.96667314455469
                        ],
                        [
                            32.01998376815134,
                            46.966673363338344
                        ],
                        [
                            32.019984185657535,
                            46.96667357487145
                        ],
                        [
                            32.01998460679007,
                            46.96667377908964
                        ],
                        [
                            32.01998503142078,
                            46.966673975930775
                        ],
                        [
                            32.019985459420475,
                            46.96667416533495
                        ],
                        [
                            32.0199858906589,
                            46.966674347244535
                        ],
                        [
                            32.01998632500484,
                            46.966674521604176
                        ],
                        [
                            32.01998676232612,
                            46.96667468836081
                        ],
                        [
                            32.019987202489666,
                            46.9666748474637
                        ],
                        [
                            32.01998764536154,
                            46.96667499886443
                        ],
                        [
                            32.019988090806976,
                            46.96667514251693
                        ],
                        [
                            32.01998853869043,
                            46.96667527837749
                        ],
                        [
                            32.0199889888756,
                            46.966675406404754
                        ],
                        [
                            32.019989441225526,
                            46.966675526559776
                        ],
                        [
                            32.01998989560253,
                            46.966675638805995
                        ],
                        [
                            32.01999035186836,
                            46.966675743109256
                        ],
                        [
                            32.01999080988418,
                            46.96667583943781
                        ],
                        [
                            32.019991269510605,
                            46.96667592776235
                        ],
                        [
                            32.01999173060778,
                            46.966676008056
                        ],
                        [
                            32.0199921930354,
                            46.96667608029433
                        ],
                        [
                            32.01999265665274,
                            46.96667614445535
                        ],
                        [
                            32.019993121318734,
                            46.966676200519544
                        ],
                        [
                            32.01999358689198,
                            46.966676248469845
                        ],
                        [
                            32.0199940532308,
                            46.966676288291666
                        ],
                        [
                            32.01999452019331,
                            46.96667631997289
                        ],
                        [
                            32.01999498763739,
                            46.96667634350388
                        ],
                        [
                            32.01999545542081,
                            46.96667635887746
                        ],
                        [
                            32.019995923401225,
                            46.96667636608897
                        ],
                        [
                            32.01999639143624,
                            46.96667636513621
                        ],
                        [
                            32.01999685938342,
                            46.96667635601946
                        ],
                        [
                            32.01999732710037,
                            46.96667633874151
                        ],
                        [
                            32.01999779444478,
                            46.96667631330761
                        ],
                        [
                            32.019998261274424,
                            46.9666762797255
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1439,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.021367352179425,
                            46.96698738760143
                        ],
                        [
                            32.02146036783994,
                            46.96779089292576
                        ],
                        [
                            32.02138929135313,
                            46.967904770027786
                        ],
                        [
                            32.021410923327366,
                            46.968094564659054
                        ],
                        [
                            32.02060744999818,
                            46.968136741152335
                        ],
                        [
                            32.02058581802393,
                            46.967987014450685
                        ],
                        [
                            32.01953203184988,
                            46.96805660578668
                        ],
                        [
                            32.01962474031094,
                            46.96872931736676
                        ],
                        [
                            32.01917664941582,
                            46.96875884056518
                        ],
                        [
                            32.01924772590263,
                            46.96938515029203
                        ],
                        [
                            32.01895414910927,
                            46.96939991171229
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1440,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01924772590263,
                            46.96938515029203
                        ],
                        [
                            32.019559844388205,
                            46.96937249764286
                        ],
                        [
                            32.01954439297803,
                            46.96928182023621
                        ],
                        [
                            32.01988123371988,
                            46.96926495000406
                        ],
                        [
                            32.02006665064201,
                            46.96917638119795
                        ],
                        [
                            32.02024897728209,
                            46.96916372849939
                        ],
                        [
                            32.02039731081979,
                            46.96908992103148
                        ],
                        [
                            32.02054255407545,
                            46.9690814858858
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1441,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02047385046574,
                            46.9669060125297
                        ],
                        [
                            32.020527102665255,
                            46.96751252565572
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1442,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01994921992465,
                            46.96684190764727
                        ],
                        [
                            32.01859258611113,
                            46.96698320207771
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1443,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01909630208289,
                            46.967824634314596
                        ],
                        [
                            32.01900437789,
                            46.96694031369415
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1444,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01901393477318,
                            46.96703225261905
                        ],
                        [
                            32.01962783059297,
                            46.96697898433892
                        ],
                        [
                            32.01968036538758,
                            46.96745558671747
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1445,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01684492071216,
                            46.967885284163984
                        ],
                        [
                            32.017135407223485,
                            46.96756684932047
                        ],
                        [
                            32.01711686553127,
                            46.96719569108387
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1446,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.017135407223485,
                            46.96756684932047
                        ],
                        [
                            32.01669349689243,
                            46.96748882195044
                        ],
                        [
                            32.016727489994814,
                            46.96740446790885
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1447,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.016949990301356,
                            46.968644458925034
                        ],
                        [
                            32.0162454059973,
                            46.96868030857789
                        ],
                        [
                            32.016109433587744,
                            46.96771446835781
                        ],
                        [
                            32.01533686307891,
                            46.967760862828264
                        ],
                        [
                            32.015309050540594,
                            46.96760480854042
                        ],
                        [
                            32.01477443174847,
                            46.96763433235952
                        ],
                        [
                            32.01474661921015,
                            46.967467733452516
                        ],
                        [
                            32.0137979026253,
                            46.96752467237784
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1448,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01477443174847,
                            46.96763433235952
                        ],
                        [
                            32.01491658472209,
                            46.96853480101422
                        ],
                        [
                            32.015392488155534,
                            46.968503168882805
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1449,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.014687903851474,
                            46.96822691413988
                        ],
                        [
                            32.01465082046706,
                            46.96793800498166
                        ],
                        [
                            32.01482074273566,
                            46.96792769118228
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1450,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01614207467349,
                            46.9679463263491
                        ],
                        [
                            32.01734554640187,
                            46.9678831753302
                        ],
                        [
                            32.01735790753001,
                            46.96782834562255
                        ],
                        [
                            32.01831898524302,
                            46.968035011150405
                        ],
                        [
                            32.018380790883725,
                            46.968496842454265
                        ],
                        [
                            32.01813665860293,
                            46.96850949531058
                        ],
                        [
                            32.01818919339753,
                            46.96902615105369
                        ],
                        [
                            32.0181088460646,
                            46.96909784982368
                        ],
                        [
                            32.01808721409036,
                            46.96914107991799
                        ],
                        [
                            32.01737181379918,
                            46.96918009241212
                        ],
                        [
                            32.01735327210697,
                            46.96902615105369
                        ],
                        [
                            32.01714004264653,
                            46.96903458620808
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1451,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.014038944624055,
                            46.96661153341563
                        ],
                        [
                            32.01398331954742,
                            46.966174992653876
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1452,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01419191358481,
                            46.966622077834415
                        ],
                        [
                            32.014451497275786,
                            46.96661153341563
                        ],
                        [
                            32.014465403544946,
                            46.96670854199
                        ],
                        [
                            32.01590083955035,
                            46.96663789445867
                        ],
                        [
                            32.0159224715246,
                            46.96678129830531
                        ],
                        [
                            32.01556708909054,
                            46.96680027819737
                        ],
                        [
                            32.015597991910894,
                            46.96708497577029
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1453,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01510127225957,
                            46.966677246593854
                        ],
                        [
                            32.01503556058046,
                            46.96625196749133
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1454,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0159224715246,
                            46.96678129830531
                        ],
                        [
                            32.01665177808494,
                            46.96677497167313
                        ],
                        [
                            32.016627055828664,
                            46.96658939012943
                        ],
                        [
                            32.01616042324133,
                            46.96660626120545
                        ],
                        [
                            32.01611097872876,
                            46.96630468991885
                        ],
                        [
                            32.01606547059988,
                            46.96605894158199
                        ],
                        [
                            32.015539276552225,
                            46.96608958208814
                        ],
                        [
                            32.015496012603734,
                            46.965802770301615
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1455,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01501083832418,
                            46.96582807728582
                        ],
                        [
                            32.01603372167788,
                            46.96576691872018
                        ],
                        [
                            32.01604608280602,
                            46.96587025556621
                        ],
                        [
                            32.01701025080104,
                            46.96581331487982
                        ],
                        [
                            32.01703188277529,
                            46.965963047666
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1456,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.016627055828664,
                            46.96658939012943
                        ],
                        [
                            32.01770865454104,
                            46.96654088575619
                        ],
                        [
                            32.01769011284882,
                            46.96634475892898
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1457,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0173029396366,
                            46.96655908007586
                        ],
                        [
                            32.01735018182494,
                            46.96712715305963
                        ],
                        [
                            32.01755723072131,
                            46.9671229353322
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1458,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00903740521171,
                            46.96710932577779
                        ],
                        [
                            32.00899645897474,
                            46.966684915146004
                        ],
                        [
                            32.010153769596975,
                            46.96663324753482
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1459,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00946184854556,
                            46.966664138044344
                        ],
                        [
                            32.00937043535238,
                            46.965994066219075
                        ],
                        [
                            32.00897487925186,
                            46.96601620975172
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1460,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00923600808385,
                            46.966583502843335
                        ],
                        [
                            32.00944947095573,
                            46.96657340902584
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1461,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00994018136319,
                            46.966642783089014
                        ],
                        [
                            32.009892693016354,
                            46.9660383532752
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1462,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0099019982036,
                            46.966156789871114
                        ],
                        [
                            32.012454536823654,
                            46.96599617512735
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1463,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01223512679915,
                            46.965852769175896
                        ],
                        [
                            32.01231238385003,
                            46.966320946007905
                        ],
                        [
                            32.01253488415657,
                            46.96630829263389
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1464,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01091442306979,
                            46.966093084581054
                        ],
                        [
                            32.010999013985014,
                            46.96632516379858
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1465,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00992668611874,
                            46.96747449936423
                        ],
                        [
                            32.0099668597852,
                            46.96716660638667
                        ],
                        [
                            32.010248075450406,
                            46.96714762662457
                        ],
                        [
                            32.01018704238021,
                            46.966633588840274
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1466,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.010248075450406,
                            46.967147626624566
                        ],
                        [
                            32.010292884539915,
                            46.96763108124321
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1467,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01026710049688,
                            46.9673528923554
                        ],
                        [
                            32.010895489536814,
                            46.96732740687859
                        ],
                        [
                            32.01091712151106,
                            46.96748346197574
                        ],
                        [
                            32.01086149643443,
                            46.96770699956498
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1468,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01275839350696,
                            46.96684274449201
                        ],
                        [
                            32.01188592492914,
                            46.968887937362396
                        ],
                        [
                            32.013270371280974,
                            46.969170515040865
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1469,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00427339780165,
                            46.97021359839324
                        ],
                        [
                            32.0045913141847,
                            46.970198536910345
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1470,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00325013178136,
                            46.96987589832805
                        ],
                        [
                            32.00305544401313,
                            46.96959332437621
                        ],
                        [
                            32.00191512994209,
                            46.96995603096806
                        ],
                        [
                            32.002672249040735,
                            46.9710125053383
                        ],
                        [
                            32.00269079073296,
                            46.97109474498267
                        ],
                        [
                            32.000505961333964,
                            46.97175898362857
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1471,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.000785631858164,
                            46.972840726051395
                        ],
                        [
                            32.0021082725693,
                            46.97242953998891
                        ],
                        [
                            32.002061918338775,
                            46.97235995434248
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1472,
                id: 2,
                district: 9,
                n_type: 1,
                OKP_comm: "",
                diameter_1: 100,
                layingMeth: "подземка ",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.998844934739967,
                            46.97228193396456
                        ],
                        [
                            31.998671106375475,
                            46.972045763478285
                        ],
                        [
                            31.998422338671634,
                            46.97212272986737
                        ],
                        [
                            31.998422338671634,
                            46.97212272986737
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1473,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.987002201409982,
                            46.97303893769063
                        ],
                        [
                            31.98605657510717,
                            46.97332571067696
                        ],
                        [
                            31.98588969987726,
                            46.97309165342848
                        ],
                        [
                            31.98537671305939,
                            46.973121174218974
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1474,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98677540019544,
                            46.97310771813166
                        ],
                        [
                            31.98647863333073,
                            46.97268133056219
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1475,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.986517027130432,
                            46.97366730590379
                        ],
                        [
                            31.986989840281836,
                            46.97351759468407
                        ],
                        [
                            31.98737303525423,
                            46.97411011128372
                        ],
                        [
                            31.987686152420892,
                            46.97400984510727
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1476,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98737303525423,
                            46.97411011128372
                        ],
                        [
                            31.987144354383602,
                            46.97422608351544
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1477,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.988126846199506,
                            46.97465313781388
                        ],
                        [
                            31.98872039822163,
                            46.97446435294779
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1478,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98708100360188,
                            46.974852329220795
                        ],
                        [
                            31.98774232395745,
                            46.974637255764975
                        ],
                        [
                            31.98798027567417,
                            46.97497884261428
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1479,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.988126846199506,
                            46.97465313781388
                        ],
                        [
                            31.98781994504553,
                            46.97474868341232
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1480,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.988711127375527,
                            46.975499652931866
                        ],
                        [
                            31.98883628379796,
                            46.97567676945491
                        ],
                        [
                            31.98938635400025,
                            46.97654336684263
                        ],
                        [
                            31.989469791615207,
                            46.97662770647108
                        ],
                        [
                            31.989754097562468,
                            46.9770409687276
                        ],
                        [
                            31.99002295209954,
                            46.97695662975097
                        ],
                        [
                            31.98993642420255,
                            46.976806927739844
                        ],
                        [
                            31.99205944796084,
                            46.97619124745684
                        ],
                        [
                            31.99198528119198,
                            46.97608793055039
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1481,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99132506982954,
                            46.976404219090576
                        ],
                        [
                            31.99125829234318,
                            46.97630141679535
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1482,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99002295209954,
                            46.97695662975097
                        ],
                        [
                            31.990373699110563,
                            46.97745370067608
                        ],
                        [
                            31.991001026363733,
                            46.97729767466905
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1483,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.988546078457453,
                            46.97577789170241
                        ],
                        [
                            31.988672780020906,
                            46.975961332850225
                        ],
                        [
                            31.9888736483532,
                            46.97589596883523
                        ],
                        [
                            31.9891703154286,
                            46.97633875576127
                        ],
                        [
                            31.989395906017176,
                            46.97626284969149
                        ],
                        [
                            31.98946698250399,
                            46.97635773226188
                        ],
                        [
                            31.9896338577339,
                            46.97630501974356
                        ],
                        [
                            31.98946698250399,
                            46.97604778190863
                        ],
                        [
                            31.98989344142487,
                            46.97591072845851
                        ],
                        [
                            31.990075768064955,
                            46.97617850948757
                        ],
                        [
                            31.990511497831935,
                            46.97604778190863
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1484,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98989344142487,
                            46.97591072845851
                        ],
                        [
                            31.989559690965052,
                            46.97546793798822
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1485,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99063588514688,
                            46.97487028058923
                        ],
                        [
                            31.99071545644627,
                            46.974999841218605
                        ],
                        [
                            31.990888512240247,
                            46.97502092674681
                        ],
                        [
                            31.991271707212636,
                            46.974904956238824
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1486,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.990888512240247,
                            46.9750209267468
                        ],
                        [
                            31.99111040805986,
                            46.97542684398088
                        ],
                        [
                            31.99111486687517,
                            46.97542380167777
                        ],
                        [
                            31.99111932569049,
                            46.97542988628383
                        ],
                        [
                            31.99111932569049,
                            46.97542988628383
                        ],
                        [
                            31.99111932569049,
                            46.97542988628383
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1488,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.988126846199506,
                            46.97465313781388
                        ],
                        [
                            31.988513630496072,
                            46.97478687691775
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1489,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99925109448582,
                            46.96002745641013
                        ],
                        [
                            31.999782875349176,
                            46.9599888727972
                        ],
                        [
                            31.999716678374398,
                            46.959536369329605
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1490,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00162159316023,
                            46.95998676365211
                        ],
                        [
                            32.002199475900845,
                            46.959944580732916
                        ],
                        [
                            32.00210676743978,
                            46.959341361350276
                        ],
                        [
                            32.001705030775184,
                            46.95936667139112
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1491,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0024394291693,
                            46.959381841445484
                        ],
                        [
                            32.00211815756704,
                            46.95941547302716
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1492,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.987505919520885,
                            46.96008819399405
                        ],
                        [
                            31.987508449330306,
                            46.959996355690166
                        ],
                        [
                            31.987498172158208,
                            46.95922302786845
                        ],
                        [
                            31.987520011148924,
                            46.95911693553805
                        ],
                        [
                            31.987527719028,
                            46.95897401743808
                        ],
                        [
                            31.9877461089351,
                            46.958961742246046
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1493,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96342989153538,
                            46.96820863192964
                        ],
                        [
                            31.966427787156796,
                            46.96799183440476
                        ],
                        [
                            31.966531760415346,
                            46.96793270765457
                        ],
                        [
                            31.966670391426746,
                            46.96764495653725
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1494,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.965157002885686,
                            46.96689994987703
                        ],
                        [
                            31.96504436518892,
                            46.96712857736308
                        ],
                        [
                            31.965532461874872,
                            46.9672448616578
                        ],
                        [
                            31.96585304608873,
                            46.966643728257324
                        ],
                        [
                            31.967040074123798,
                            46.96691571732124
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1495,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.965088647281974,
                            46.9680886765142
                        ],
                        [
                            31.965041477042845,
                            46.96777503600118
                        ],
                        [
                            31.96574618468411,
                            46.96644269197339
                        ],
                        [
                            31.96628626799933,
                            46.96656390511738
                        ],
                        [
                            31.96738809572532,
                            46.96682998178731
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1496,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.976388512905046,
                            46.9695475231335
                        ],
                        [
                            31.976961397007592,
                            46.96917609177384
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1497,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.986631252411147,
                            46.963268004408725
                        ],
                        [
                            31.98754597619338,
                            46.963028923414576
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1498,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.986923144253826,
                            46.96319171286901
                        ],
                        [
                            31.98673477715912,
                            46.96286020113234
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1499,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96630061530319,
                            46.96656736980801
                        ],
                        [
                            31.966469480142326,
                            46.96621925615742
                        ],
                        [
                            31.967306946573885,
                            46.964561635523864
                        ],
                        [
                            31.967476912085825,
                            46.964601705839655
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1500,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96579579865864,
                            46.966189731557265
                        ],
                        [
                            31.966416362486644,
                            46.966328758066545
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1501,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96593177106819,
                            46.96516690499116
                        ],
                        [
                            31.965715451325714,
                            46.965613998597156
                        ],
                        [
                            31.96666919283717,
                            46.96582396392203
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1502,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96667034847462,
                            46.965268134059386
                        ],
                        [
                            31.96647566070639,
                            46.965673048416704
                        ],
                        [
                            31.966719203093216,
                            46.96572497793992
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1503,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.967927563534964,
                            46.96984140691562
                        ],
                        [
                            31.96847043776016,
                            46.96996769354912
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1504,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.968013143446587,
                            46.96967182697909
                        ],
                        [
                            31.969057591346864,
                            46.9699508235333
                        ],
                        [
                            31.969209015166594,
                            46.97025237426356
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1505,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.968205647821133,
                            46.969290283641975
                        ],
                        [
                            31.968680576938553,
                            46.96940254512252
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1506,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.968473107591716,
                            46.9687537197843
                        ],
                        [
                            31.969626203241354,
                            46.96869188358807
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1507,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97123624018172,
                            46.96761216609451
                        ],
                        [
                            31.971511275282868,
                            46.9676775402345
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1508,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97123624018172,
                            46.96761216609451
                        ],
                        [
                            31.97049148221124,
                            46.967576315725765
                        ],
                        [
                            31.9698487035479,
                            46.96742236975155
                        ],
                        [
                            31.969579849010834,
                            46.967447675969524
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1509,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.9698487035479,
                            46.96742236975155
                        ],
                        [
                            31.969854884111964,
                            46.96733590674981
                        ],
                        [
                            31.970194815135855,
                            46.96683610568375
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1510,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.970324606981336,
                            46.967228355016005
                        ],
                        [
                            31.970825232671043,
                            46.967361213008694
                        ],
                        [
                            31.971044642695546,
                            46.966947875947795
                        ],
                        [
                            31.97106009410572,
                            46.966817125804376
                        ],
                        [
                            31.971236240181735,
                            46.966481813491974
                        ],
                        [
                            31.97122387905359,
                            46.966363715573245
                        ],
                        [
                            31.970179363725666,
                            46.96612119224408
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1511,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.970825232671043,
                            46.967361213008694
                        ],
                        [
                            31.97069869896332,
                            46.967586290508265
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1512,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97049148221124,
                            46.967576315725765
                        ],
                        [
                            31.970370961211856,
                            46.96776927478005
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1513,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97538222784471,
                            46.9668359613523
                        ],
                        [
                            31.97522270400723,
                            46.96685403111916
                        ],
                        [
                            31.974039125987723,
                            46.96709444226087
                        ],
                        [
                            31.9740978413464,
                            46.967501451642946
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1514,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.974050842519684,
                            46.96717566041335
                        ],
                        [
                            31.974289438832574,
                            46.967178796791366
                        ],
                        [
                            31.975055828777332,
                            46.967233627164845
                        ],
                        [
                            31.975096002443784,
                            46.967448730395
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1515,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.975055828777332,
                            46.967233627164845
                        ],
                        [
                            31.9752721485198,
                            46.96719144995946
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1516,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97637475742876,
                            46.968229222630086
                        ],
                        [
                            31.975881588580066,
                            46.96837510111178
                        ],
                        [
                            31.975664614338278,
                            46.96843777235001
                        ],
                        [
                            31.975333954160497,
                            46.968463078087595
                        ],
                        [
                            31.97532240961207,
                            46.96838542336911
                        ],
                        [
                            31.974230723473912,
                            46.968446207597204
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1517,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97483950903485,
                            46.968106687847005
                        ],
                        [
                            31.974861141009104,
                            46.96825430539451
                        ],
                        [
                            31.975398850083245,
                            46.96821845545613
                        ],
                        [
                            31.975380308391024,
                            46.968070837809705
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1518,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.975132009866115,
                            46.96823624613034
                        ],
                        [
                            31.97515204964343,
                            46.96839490888311
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1519,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.974432768164025,
                            46.9684349579125
                        ],
                        [
                            31.974409959831963,
                            46.968290155308836
                        ],
                        [
                            31.97449957801098,
                            46.968281720037034
                        ],
                        [
                            31.9744748557547,
                            46.968142537860274
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1520,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.974409959831963,
                            46.968290155308836
                        ],
                        [
                            31.97403603570569,
                            46.96830491703128
                        ],
                        [
                            31.973992771757196,
                            46.96807505546239
                        ],
                        [
                            31.973794993706942,
                            46.9680940348954
                        ],
                        [
                            31.973476694657307,
                            46.968262740670625
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1521,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.973865118028797,
                            46.96808730553532
                        ],
                        [
                            31.97388461188596,
                            46.968237434838265
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1522,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.972531068354506,
                            46.96871824360587
                        ],
                        [
                            31.972459991867698,
                            46.96826906712685
                        ],
                        [
                            31.971230059617657,
                            46.9683323316479
                        ],
                        [
                            31.971103358054204,
                            46.968385052024935
                        ],
                        [
                            31.970782623221716,
                            46.96903937353388
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1523,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.970782623221716,
                            46.96903937353388
                        ],
                        [
                            31.971797780870304,
                            46.96914375845015
                        ],
                        [
                            31.971819412844546,
                            46.96934620134349
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1524,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.970782623221716,
                            46.96903937353388
                        ],
                        [
                            31.97006104236647,
                            46.97048914580386
                        ],
                        [
                            31.96973656275278,
                            46.971071152416854
                        ],
                        [
                            31.96994670193118,
                            46.97111754397577
                        ],
                        [
                            31.97055239721009,
                            46.97094884720495
                        ],
                        [
                            31.97062965426097,
                            46.97105639145785
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1525,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96999380664509,
                            46.970609744453576
                        ],
                        [
                            31.970499862415483,
                            46.97062832187446
                        ],
                        [
                            31.97078725864477,
                            46.97054608151289
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1526,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.970445726895594,
                            46.96971625745738
                        ],
                        [
                            31.970719272439993,
                            46.96977638994774
                        ],
                        [
                            31.97068218905557,
                            46.969854413980755
                        ],
                        [
                            31.97085524484955,
                            46.96989448033187
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1527,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97779269938307,
                            46.969787938779746
                        ],
                        [
                            31.977900051756905,
                            46.9697130236261
                        ],
                        [
                            31.978240147370734,
                            46.96947537717265
                        ],
                        [
                            31.97854215227582,
                            46.969271148908106
                        ],
                        [
                            31.978882247889654,
                            46.96904092702036
                        ],
                        [
                            31.979056376843936,
                            46.96915418145993
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1528,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.978622280896506,
                            46.97039851223629
                        ],
                        [
                            31.97905637684394,
                            46.97009919686091
                        ],
                        [
                            31.97944544622617,
                            46.970386968371876
                        ],
                        [
                            31.979918859320616,
                            46.97041481714561
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1529,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97975202159883,
                            46.970405002830724
                        ],
                        [
                            31.979850840197848,
                            46.97005835174678
                        ],
                        [
                            31.98003041068195,
                            46.970080630903794
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1530,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.979440004696336,
                            46.96993024641375
                        ],
                        [
                            31.9791236333401,
                            46.97014894260671
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1531,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.978860839973397,
                            46.970234022542066
                        ],
                        [
                            31.979521627643667,
                            46.970719296125786
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1532,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97993948133431,
                            46.971397652373625
                        ],
                        [
                            31.980010004945143,
                            46.97149533837023
                        ],
                        [
                            31.980167809309958,
                            46.97144706812842
                        ],
                        [
                            31.980301126790575,
                            46.97163829229233
                        ],
                        [
                            31.980026329534603,
                            46.97172740623225
                        ],
                        [
                            31.980197737723977,
                            46.971974325331736
                        ],
                        [
                            31.97969711698041,
                            46.97211913449884
                        ],
                        [
                            31.97977329839791,
                            46.972215673725756
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1533,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97999179884524,
                            46.97203389509917
                        ],
                        [
                            31.979784181457546,
                            46.97172926277109
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1534,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98018597784044,
                            46.971675633937366
                        ],
                        [
                            31.980518787983428,
                            46.97215440846733
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1535,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98026205969417,
                            46.971785084266045
                        ],
                        [
                            31.98039363279754,
                            46.97174225854114
                        ],
                        [
                            31.980733728411376,
                            46.97226579979408
                        ],
                        [
                            31.980486138804505,
                            46.97234377358473
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1536,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.980237046016722,
                            46.97174909995174
                        ],
                        [
                            31.980448048095756,
                            46.97168099274041
                        ],
                        [
                            31.980586807106196,
                            46.97190749019929
                        ],
                        [
                            31.980940506544588,
                            46.97178495890622
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1537,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98297563869776,
                            46.97177381968383
                        ],
                        [
                            31.983155209181863,
                            46.972013312453775
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1538,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.982230149112237,
                            46.97225094763059
                        ],
                        [
                            31.983119726115785,
                            46.971965988849576
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1539,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.982521301573556,
                            46.972157682740146
                        ],
                        [
                            31.98304365782053,
                            46.97287659144501
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1540,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98291067675288,
                            46.972693573114725
                        ],
                        [
                            31.98358781080266,
                            46.97247929918848
                        ],
                        [
                            31.983751056697297,
                            46.97267980219149
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1541,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.984564087807914,
                            46.97127927774739
                        ],
                        [
                            31.984716928240594,
                            46.97147862944531
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1542,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.984276164325056,
                            46.97115558920402
                        ],
                        [
                            31.98482643902824,
                            46.97098060825864
                        ],
                        [
                            31.98494935456269,
                            46.97115701422789
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1543,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.985573969187453,
                            46.97095879361786
                        ],
                        [
                            31.9859844277752,
                            46.97155047265138
                        ],
                        [
                            31.98636775235012,
                            46.97142061230435
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1544,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.989116342075167,
                            46.968428613056204
                        ],
                        [
                            31.989268087744858,
                            46.96861714905963
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1545,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98828291056257,
                            46.96871297965346
                        ],
                        [
                            31.98764243071076,
                            46.967759835514116
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1546,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.987914507201825,
                            46.96742377599543
                        ],
                        [
                            31.9886763213768,
                            46.96728638088801
                        ],
                        [
                            31.988893982569653,
                            46.96760387445307
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1547,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98811532401961,
                            46.96738755821021
                        ],
                        [
                            31.988232836696373,
                            46.96756859748335
                        ],
                        [
                            31.98667383840258,
                            46.968068042940295
                        ],
                        [
                            31.986083432416976,
                            46.96756488411676
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1548,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98667383840258,
                            46.968068042940295
                        ],
                        [
                            31.9867037668166,
                            46.96780161072109
                        ],
                        [
                            31.987136368437394,
                            46.96768185504044
                        ],
                        [
                            31.98699488866204,
                            46.96746647980826
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1549,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.987728134805444,
                            46.96789073105362
                        ],
                        [
                            31.987241117886445,
                            46.96803926461122
                        ],
                        [
                            31.987434292195097,
                            46.96836232367481
                        ],
                        [
                            31.986713289493782,
                            46.96856098546344
                        ],
                        [
                            31.98647930371147,
                            46.96830848063304
                        ],
                        [
                            31.98658813430789,
                            46.968269490810336
                        ],
                        [
                            31.986384076939594,
                            46.967903727756386
                        ],
                        [
                            31.98608751356433,
                            46.96798727791298
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1550,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.986604899818474,
                            46.96844401732276
                        ],
                        [
                            31.98639768076414,
                            46.96850157266941
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1551,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.986960671263976,
                            46.9684928231044
                        ],
                        [
                            31.986876535388415,
                            46.96834932708348
                        ],
                        [
                            31.98710507964091,
                            46.96828805739613
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1552,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.986922788391894,
                            46.96872065702039
                        ],
                        [
                            31.987211189472415,
                            46.96862782477783
                        ],
                        [
                            31.987027467191247,
                            46.96830886427388
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1553,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.987096119484775,
                            46.96842805183727
                        ],
                        [
                            31.987095804949686,
                            46.96842832013907
                        ],
                        [
                            31.987095495143663,
                            46.96842859388788
                        ],
                        [
                            31.98709519016103,
                            46.96842887300035
                        ],
                        [
                            31.987094890094625,
                            46.968429157391505
                        ],
                        [
                            31.987094595035803,
                            46.96842944697478
                        ],
                        [
                            31.987094305074393,
                            46.968429741662
                        ],
                        [
                            31.98709402029867,
                            46.96843004136346
                        ],
                        [
                            31.98709374079533,
                            46.968430345987926
                        ],
                        [
                            31.987093466649462,
                            46.96843065544264
                        ],
                        [
                            31.987093197944528,
                            46.96843096963341
                        ],
                        [
                            31.987092934762334,
                            46.96843128846457
                        ],
                        [
                            31.987092677182996,
                            46.968431611839065
                        ],
                        [
                            31.98709242528494,
                            46.968431939658444
                        ],
                        [
                            31.987092179144845,
                            46.96843227182291
                        ],
                        [
                            31.987091938837647,
                            46.96843260823134
                        ],
                        [
                            31.98709170443651,
                            46.96843294878131
                        ],
                        [
                            31.98709147601279,
                            46.96843329336916
                        ],
                        [
                            31.987091253636027,
                            46.96843364188997
                        ],
                        [
                            31.987091037373922,
                            46.96843399423764
                        ],
                        [
                            31.987090827292317,
                            46.9684343503049
                        ],
                        [
                            31.987090623455163,
                            46.96843470998336
                        ],
                        [
                            31.987090425924517,
                            46.96843507316352
                        ],
                        [
                            31.98709023476052,
                            46.9684354397348
                        ],
                        [
                            31.98709005002136,
                            46.96843580958561
                        ],
                        [
                            31.98708987176329,
                            46.968436182603355
                        ],
                        [
                            31.987089700040567,
                            46.968436558674476
                        ],
                        [
                            31.987089534905476,
                            46.96843693768447
                        ],
                        [
                            31.987089376408292,
                            46.968437319517975
                        ],
                        [
                            31.987089224597263,
                            46.96843770405873
                        ],
                        [
                            31.987089079518608,
                            46.96843809118966
                        ],
                        [
                            31.9870889412165,
                            46.96843848079293
                        ],
                        [
                            31.98708880973303,
                            46.968438872749914
                        ],
                        [
                            31.987088685108237,
                            46.96843926694129
                        ],
                        [
                            31.98708856738006,
                            46.96843966324705
                        ],
                        [
                            31.98708845658434,
                            46.96844006154654
                        ],
                        [
                            31.9870883527548,
                            46.968440461718515
                        ],
                        [
                            31.98708825592306,
                            46.968440863641135
                        ],
                        [
                            31.987088166118596,
                            46.96844126719204
                        ],
                        [
                            31.987088083368743,
                            46.96844167224838
                        ],
                        [
                            31.9870880076987,
                            46.96844207868685
                        ],
                        [
                            31.9870879391315,
                            46.968442486383694
                        ],
                        [
                            31.987087877688015,
                            46.96844289521481
                        ],
                        [
                            31.987087823386958,
                            46.968443305055715
                        ],
                        [
                            31.987087776244856,
                            46.96844371578166
                        ],
                        [
                            31.98708773627606,
                            46.968444127267595
                        ],
                        [
                            31.987087703492737,
                            46.96844453938825
                        ],
                        [
                            31.98708767790487,
                            46.96844495201815
                        ],
                        [
                            31.98708765952025,
                            46.9684453650317
                        ],
                        [
                            31.98708764834447,
                            46.968445778303135
                        ],
                        [
                            31.98708764438094,
                            46.968446191706654
                        ],
                        [
                            31.987087647630855,
                            46.9684466051164
                        ],
                        [
                            31.987087658093234,
                            46.96844701840651
                        ],
                        [
                            31.98708767576489,
                            46.96844743145117
                        ],
                        [
                            31.987087700640444,
                            46.96844784412463
                        ],
                        [
                            31.98708773271232,
                            46.96844825630126
                        ],
                        [
                            31.987087771970756,
                            46.968448667855576
                        ],
                        [
                            31.9870878184038,
                            46.96844907866228
                        ],
                        [
                            31.987087871997318,
                            46.96844948859632
                        ],
                        [
                            31.98708793273499,
                            46.968449897532885
                        ],
                        [
                            31.98708800059833,
                            46.96845030534748
                        ],
                        [
                            31.987088075566675,
                            46.968450711915956
                        ],
                        [
                            31.9870881576172,
                            46.96845111711454
                        ],
                        [
                            31.98708824672493,
                            46.96845152081986
                        ],
                        [
                            31.987088342862734,
                            46.96845192290904
                        ],
                        [
                            31.987088446001344,
                            46.96845232325964
                        ],
                        [
                            31.987088556109363,
                            46.96845272174979
                        ],
                        [
                            31.98708867315327,
                            46.96845311825818
                        ],
                        [
                            31.987088797097428,
                            46.9684535126641
                        ],
                        [
                            31.98708892790411,
                            46.96845390484746
                        ],
                        [
                            31.98708906553349,
                            46.96845429468888
                        ],
                        [
                            31.98708920994367,
                            46.96845468206968
                        ],
                        [
                            31.987089361090682,
                            46.968455066871904
                        ],
                        [
                            31.987089518928517,
                            46.96845544897843
                        ],
                        [
                            31.98708968340912,
                            46.96845582827292
                        ],
                        [
                            31.987089854482416,
                            46.96845620463991
                        ],
                        [
                            31.98709003209633,
                            46.9684565779648
                        ],
                        [
                            31.98709021619678,
                            46.968456948133955
                        ],
                        [
                            31.987090406727724,
                            46.96845731503468
                        ],
                        [
                            31.98709060363116,
                            46.96845767855526
                        ],
                        [
                            31.98709080684714,
                            46.96845803858504
                        ],
                        [
                            31.987091016313794,
                            46.968458395014416
                        ],
                        [
                            31.98709123196736,
                            46.96845874773487
                        ],
                        [
                            31.98709145374218,
                            46.96845909663902
                        ],
                        [
                            31.98709168157074,
                            46.96845944162066
                        ],
                        [
                            31.987091915383676,
                            46.96845978257475
                        ],
                        [
                            31.98709215510981,
                            46.968460119397484
                        ],
                        [
                            31.98709240067616,
                            46.96846045198634
                        ],
                        [
                            31.987092652007966,
                            46.96846078024006
                        ],
                        [
                            31.987092909028714,
                            46.9684611040587
                        ],
                        [
                            31.98709317166016,
                            46.96846142334369
                        ],
                        [
                            31.987093439822342,
                            46.96846173799782
                        ],
                        [
                            31.987093713433623,
                            46.9684620479253
                        ],
                        [
                            31.987093992410713,
                            46.96846235303178
                        ],
                        [
                            31.98709427666867,
                            46.96846265322437
                        ],
                        [
                            31.987094566120966,
                            46.968462948411684
                        ],
                        [
                            31.98709486067947,
                            46.96846323850385
                        ],
                        [
                            31.987095160254515,
                            46.96846352341256
                        ],
                        [
                            31.987095464754898,
                            46.968463803051065
                        ],
                        [
                            31.987095774087916,
                            46.96846407733425
                        ],
                        [
                            31.987096088159397,
                            46.96846434617859
                        ],
                        [
                            31.987096406873725,
                            46.96846460950227
                        ],
                        [
                            31.98709673013387,
                            46.968464867225094
                        ],
                        [
                            31.987097057841428,
                            46.968465119268615
                        ],
                        [
                            31.987097389896622,
                            46.968465365556106
                        ],
                        [
                            31.987097726198364,
                            46.96846560601258
                        ],
                        [
                            31.987098066644275,
                            46.96846584056483
                        ],
                        [
                            31.98709841113071,
                            46.96846606914146
                        ],
                        [
                            31.98709875955279,
                            46.96846629167288
                        ],
                        [
                            31.987099111804447,
                            46.96846650809134
                        ],
                        [
                            31.987099467778442,
                            46.96846671833095
                        ],
                        [
                            31.9870998273664,
                            46.96846692232772
                        ],
                        [
                            31.98710019045885,
                            46.968467120019525
                        ],
                        [
                            31.987100556945254,
                            46.968467311346195
                        ],
                        [
                            31.98710092671404,
                            46.96846749624948
                        ],
                        [
                            31.987101299652636,
                            46.968467674673086
                        ],
                        [
                            31.9871016756475,
                            46.968467846562696
                        ],
                        [
                            31.987102054584177,
                            46.96846801186597
                        ],
                        [
                            31.987102436347296,
                            46.96846817053261
                        ],
                        [
                            31.987102820820635,
                            46.96846832251428
                        ],
                        [
                            31.98710320788715,
                            46.96846846776474
                        ],
                        [
                            31.987103597428995,
                            46.968468606239746
                        ],
                        [
                            31.987103989327586,
                            46.968468737897155
                        ],
                        [
                            31.987104383463613,
                            46.96846886269688
                        ],
                        [
                            31.987104779717082,
                            46.96846898060093
                        ],
                        [
                            31.987105177967365,
                            46.96846909157342
                        ],
                        [
                            31.987105578093217,
                            46.968469195580546
                        ],
                        [
                            31.98710597997282,
                            46.968469292590655
                        ],
                        [
                            31.987106383483837,
                            46.968469382574206
                        ],
                        [
                            31.987106788503414,
                            46.96846946550382
                        ],
                        [
                            31.98710719490825,
                            46.96846954135424
                        ],
                        [
                            31.987107602574625,
                            46.968469610102375
                        ],
                        [
                            31.987108011378428,
                            46.96846967172729
                        ],
                        [
                            31.987108421195202,
                            46.96846972621024
                        ],
                        [
                            31.987108831900184,
                            46.96846977353462
                        ],
                        [
                            31.987109243368337,
                            46.968469813686035
                        ],
                        [
                            31.987109655474402,
                            46.96846984665226
                        ],
                        [
                            31.98711006809291,
                            46.968469872423256
                        ],
                        [
                            31.987110481098252,
                            46.96846989099117
                        ],
                        [
                            31.987110894364687,
                            46.968469902350364
                        ],
                        [
                            31.987111307766405,
                            46.96846990649737
                        ],
                        [
                            31.987111721177552,
                            46.96846990343093
                        ],
                        [
                            31.98711213447227,
                            46.968469893151976
                        ],
                        [
                            31.98711254752473,
                            46.96846987566364
                        ],
                        [
                            31.987112960209192,
                            46.96846985097123
                        ],
                        [
                            31.987113372400014,
                            46.96846981908229
                        ],
                        [
                            31.98711378397171,
                            46.96846978000651
                        ],
                        [
                            31.987114194798988,
                            46.96846973375579
                        ],
                        [
                            31.987114604756766,
                            46.968469680344214
                        ],
                        [
                            31.98711501372025,
                            46.96846961978803
                        ],
                        [
                            31.987115421564923,
                            46.968469552105695
                        ],
                        [
                            31.987115828166633,
                            46.9684694773178
                        ],
                        [
                            31.987116233401586,
                            46.968469395447116
                        ],
                        [
                            31.98711663714642,
                            46.968469306518564
                        ],
                        [
                            31.98711703927822,
                            46.96846921055922
                        ],
                        [
                            31.98711743967456,
                            46.968469107598295
                        ],
                        [
                            31.987117838213543,
                            46.968468997667145
                        ],
                        [
                            31.98711823477384,
                            46.96846888079922
                        ],
                        [
                            31.98711862923472,
                            46.96846875703012
                        ],
                        [
                            31.987119021476097,
                            46.9684686263975
                        ],
                        [
                            31.98711941137856,
                            46.968468488941156
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1554,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.988992298609205,
                            46.969857014731055
                        ],
                        [
                            31.989312980365884,
                            46.96975108409286
                        ],
                        [
                            31.989508875439448,
                            46.970068563028285
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1555,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.989827925830095,
                            46.97105399911844
                        ],
                        [
                            31.992428256188543,
                            46.97024308280998
                        ],
                        [
                            31.992697611914704,
                            46.97064410483153
                        ],
                        [
                            31.99214892291182,
                            46.97080678752624
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1556,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.991274611117102,
                            46.97060285000078
                        ],
                        [
                            31.99147598846983,
                            46.970898455203496
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1557,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99134365263756,
                            46.97070419739382
                        ],
                        [
                            31.9908284464211,
                            46.97086503697547
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1558,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.992304007924304,
                            46.97039284755869
                        ],
                        [
                            31.991948494642642,
                            46.9704992916706
                        ],
                        [
                            31.99248901993822,
                            46.97132855831153
                        ],
                        [
                            31.992837277846778,
                            46.97122706666689
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1559,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.993644664834108,
                            46.97096498216938
                        ],
                        [
                            31.993115022598143,
                            46.97016293993126
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1560,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.993375901552707,
                            46.97055799289813
                        ],
                        [
                            31.993664984458647,
                            46.97046562211784
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1561,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.991142471456794,
                            46.96603975761257
                        ],
                        [
                            31.991643060582895,
                            46.96599669109627
                        ],
                        [
                            31.99171482479946,
                            46.966224183752615
                        ],
                        [
                            31.992006511615145,
                            46.96620522606821
                        ],
                        [
                            31.992027346387697,
                            46.966314232661816
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1562,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.991656950431267,
                            46.96633792971799
                        ],
                        [
                            31.992508861131054,
                            46.96628737598546
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1563,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99086908528027,
                            46.96514296948194
                        ],
                        [
                            31.98892991020204,
                            46.96530630815367
                        ],
                        [
                            31.989124368079167,
                            46.96584028933384
                        ],
                        [
                            31.989286416310108,
                            46.966112017355485
                        ],
                        [
                            31.989390590172857,
                            46.96630317403201
                        ],
                        [
                            31.989679962013817,
                            46.966457994641246
                        ],
                        [
                            31.989355865551936,
                            46.96647537243676
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1564,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98873313735019,
                            46.9655985766197
                        ],
                        [
                            31.98851089977633,
                            46.96524153492612
                        ],
                        [
                            31.988927595227306,
                            46.96510250919729
                        ],
                        [
                            31.98864053836108,
                            46.9646317148412
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1565,
                id: 0,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98892991020204,
                            46.96530630815367
                        ],
                        [
                            31.988579327068855,
                            46.965351468869414
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1566,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.989497079010317,
                            46.965443753523076
                        ],
                        [
                            31.98950170895978,
                            46.96502667682926
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1567,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99015444957323,
                            46.96520316402599
                        ],
                        [
                            31.99011749223735,
                            46.96504879461437
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1568,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.990441599545658,
                            46.963912749540086
                        ],
                        [
                            31.99018231152973,
                            46.96396659226316
                        ],
                        [
                            31.989130155515983,
                            46.96427664152476
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1569,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.989263266562833,
                            46.964457930642624
                        ],
                        [
                            31.988971579747147,
                            46.96406138458482
                        ],
                        [
                            31.988791011718376,
                            46.964091402118314
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1570,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.990441599545658,
                            46.963912749540086
                        ],
                        [
                            31.990659775067275,
                            46.96386864002102
                        ],
                        [
                            31.992097374373152,
                            46.963532125306514
                        ],
                        [
                            31.99197699568732,
                            46.96326670375678
                        ],
                        [
                            31.99182883730474,
                            46.96314663167072
                        ],
                        [
                            31.9916667890738,
                            46.96283381102299
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1571,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.990509301709984,
                            46.96341837337501
                        ],
                        [
                            31.990740205381336,
                            46.96384981286682
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1572,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.989805765505753,
                            46.96272765505067
                        ],
                        [
                            31.989946762851165,
                            46.96270899823384
                        ],
                        [
                            31.991331117738305,
                            46.962362996700975
                        ],
                        [
                            31.99111582508863,
                            46.96196801504688
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1573,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.990158583038752,
                            46.96237247622486
                        ],
                        [
                            31.99039471046097,
                            46.962792733428344
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1574,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.986043227057174,
                            46.963634480730924
                        ],
                        [
                            31.98558882292636,
                            46.96281169232209
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1575,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.986043227057174,
                            46.963634480730924
                        ],
                        [
                            31.98619997625447,
                            46.96375962844388
                        ],
                        [
                            31.987153745842257,
                            46.9651435850184
                        ],
                        [
                            31.987505622000864,
                            46.96556698010574
                        ],
                        [
                            31.987940837249656,
                            46.96544059386338
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1576,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.987505622000864,
                            46.96556698010574
                        ],
                        [
                            31.987737119473614,
                            46.96571232391533
                        ],
                        [
                            31.98804732608712,
                            46.966138874119856
                        ],
                        [
                            31.988325123054445,
                            46.96605672397454
                        ],
                        [
                            31.988639959617405,
                            46.966527505789415
                        ],
                        [
                            31.988621439819575,
                            46.96655278254817
                        ],
                        [
                            31.987255604730265,
                            46.966636511726264
                        ],
                        [
                            31.986396749106316,
                            46.96540109810139
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1577,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.987023801453187,
                            46.96495503278883
                        ],
                        [
                            31.98573929628365,
                            46.96536476197462
                        ],
                        [
                            31.98563743739564,
                            46.96520677852778
                        ],
                        [
                            31.984382721093258,
                            46.965506946677856
                        ],
                        [
                            31.98522074194467,
                            46.966758155770364
                        ],
                        [
                            31.986572687185614,
                            46.966682325749375
                        ],
                        [
                            31.986595836932892,
                            46.966631772342325
                        ],
                        [
                            31.986077282593904,
                            46.96581343241951
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1578,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98629735617824,
                            46.963900932122186
                        ],
                        [
                            31.98688289379911,
                            46.96372645096329
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1579,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.986678957647893,
                            46.96445465345353
                        ],
                        [
                            31.987646835459234,
                            46.96415775660629
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1580,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.984136085961335,
                            46.96410613593966
                        ],
                        [
                            31.984012325136828,
                            46.963920775914175
                        ],
                        [
                            31.98461653354075,
                            46.963777007116
                        ],
                        [
                            31.98445217033508,
                            46.96352264598812
                        ],
                        [
                            31.98411649899957,
                            46.963009180399276
                        ],
                        [
                            31.9830145710292,
                            46.963306201094596
                        ],
                        [
                            31.98344052637909,
                            46.96402346767632
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1581,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98147314685823,
                            46.96475532217781
                        ],
                        [
                            31.98067413157954,
                            46.963378876120004
                        ],
                        [
                            31.981067677283246,
                            46.963277763014304
                        ],
                        [
                            31.98128065495819,
                            46.963271443438856
                        ],
                        [
                            31.981627901167343,
                            46.963186129097195
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1582,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98067413157954,
                            46.963378876120004
                        ],
                        [
                            31.979891670121596,
                            46.96357162244837
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1583,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98147314685823,
                            46.96475532217781
                        ],
                        [
                            31.98172050015644,
                            46.96519255999467
                        ],
                        [
                            31.98204459661832,
                            46.96567598799996
                        ],
                        [
                            31.982109415910692,
                            46.965802373686174
                        ],
                        [
                            31.982553891058398,
                            46.96650538861569
                        ],
                        [
                            31.982912712141175,
                            46.96646905323902
                        ],
                        [
                            31.983303942870158,
                            46.9665717401094
                        ],
                        [
                            31.98388963147625,
                            46.966557521939116
                        ],
                        [
                            31.983928986046624,
                            46.96681344842621
                        ],
                        [
                            31.984010010162095,
                            46.96680870905794
                        ],
                        [
                            31.984016418923925,
                            46.966853891765766
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1584,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98277885137552,
                            46.96669311367933
                        ],
                        [
                            31.982804544305765,
                            46.966868447957935
                        ],
                        [
                            31.983392912408377,
                            46.96683776450066
                        ],
                        [
                            31.98357942486275,
                            46.96687821975039
                        ],
                        [
                            31.98445911525925,
                            46.96682924631739
                        ],
                        [
                            31.984554029223087,
                            46.96675815577035
                        ],
                        [
                            31.98430169697777,
                            46.966379004590465
                        ],
                        [
                            31.98413964874684,
                            46.96624630104258
                        ],
                        [
                            31.98389657640043,
                            46.965845028787875
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1585,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98438837695198,
                            46.96683318440654
                        ],
                        [
                            31.984431914306207,
                            46.96712012415485
                        ],
                        [
                            31.984346260241278,
                            46.96712486349553
                        ],
                        [
                            31.98435320516546,
                            46.967178575993785
                        ],
                        [
                            31.984434229280936,
                            46.96717383665787
                        ],
                        [
                            31.984529143244767,
                            46.96749611054337
                        ],
                        [
                            31.98491111407483,
                            46.967481892618785
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1586,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98351749928878,
                            46.96754192383026
                        ],
                        [
                            31.984374039938007,
                            46.9675087486954
                        ],
                        [
                            31.98442728435674,
                            46.967687262273756
                        ],
                        [
                            31.984469739894,
                            46.96796478390193
                        ],
                        [
                            31.984805782724724,
                            46.96795740115764
                        ],
                        [
                            31.985420576309473,
                            46.96776390450223
                        ],
                        [
                            31.98597484496219,
                            46.968600357808576
                        ],
                        [
                            31.985940120341276,
                            46.96867934457909
                        ],
                        [
                            31.98582089914281,
                            46.96872041765365
                        ],
                        [
                            31.98561255141732,
                            46.96876228056257
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1587,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98651944468299,
                            46.96926772398203
                        ],
                        [
                            31.98614846806678,
                            46.968730290984176
                        ],
                        [
                            31.985914655619283,
                            46.968381168909495
                        ],
                        [
                            31.985460920572656,
                            46.967716092950674
                        ],
                        [
                            31.984787262926908,
                            46.96792778073259
                        ],
                        [
                            31.984315008082465,
                            46.96801940652766
                        ],
                        [
                            31.98426870858791,
                            46.96812209042185
                        ],
                        [
                            31.983990911620598,
                            46.96820739689172
                        ],
                        [
                            31.984428441844123,
                            46.96884561134981
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1588,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: "",
                diameter_1: 57,
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.981128048436045,
                            46.97781701878746
                        ],
                        [
                            31.98000774038066,
                            46.97816482212649
                        ],
                        [
                            31.979841062200215,
                            46.97792755249921
                        ],
                        [
                            31.97947889726492,
                            46.97804408150098
                        ],
                        [
                            31.979557091966857,
                            46.97815920628949
                        ],
                        [
                            31.97938218276515,
                            46.978211152759236
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1589,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.979039516742187,
                            46.974782922174064
                        ],
                        [
                            31.97930398806321,
                            46.974699738609445
                        ],
                        [
                            31.979209331318753,
                            46.97456494962455
                        ],
                        [
                            31.979649690955988,
                            46.974404887263724
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1590,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.978622312139482,
                            46.97417050493746
                        ],
                        [
                            31.9783162655124,
                            46.9742757137811
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1591,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.980350835032304,
                            46.97673573166942
                        ],
                        [
                            31.980771167602207,
                            46.976603596730264
                        ],
                        [
                            31.980933730272028,
                            46.97680998435683
                        ],
                        [
                            31.980861708836027,
                            46.97683244826812
                        ],
                        [
                            31.980991347420826,
                            46.977023391133
                        ],
                        [
                            31.981098350697163,
                            46.97698969538286
                        ],
                        [
                            31.98115802560127,
                            46.97707814668169
                        ],
                        [
                            31.98134116582423,
                            46.977019179165396
                        ],
                        [
                            31.981563403398166,
                            46.977333671834224
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1592,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.981483392121547,
                            46.977220446549595
                        ],
                        [
                            31.982460584715152,
                            46.97691668785139
                        ],
                        [
                            31.982228058364647,
                            46.976582536723576
                        ],
                        [
                            31.981686868717013,
                            46.97675242054108
                        ],
                        [
                            31.981588096461937,
                            46.97662044472964
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1593,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98226466938109,
                            46.97663514858721
                        ],
                        [
                            31.98268899555502,
                            46.976496892610946
                        ],
                        [
                            31.98263755167216,
                            46.976418268386794
                        ],
                        [
                            31.982742497193193,
                            46.97638457225531
                        ],
                        [
                            31.982604627587133,
                            46.976192223098344
                        ],
                        [
                            31.982536721661774,
                            46.97610657836057
                        ],
                        [
                            31.982160152439278,
                            46.97622732336109
                        ],
                        [
                            31.98197701221632,
                            46.975950732666185
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1594,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.981787698727413,
                            46.97602374151649
                        ],
                        [
                            31.981892644248433,
                            46.97617256694116
                        ],
                        [
                            31.982326830619723,
                            46.97602935757778
                        ],
                        [
                            31.982240404896537,
                            46.97589597596302
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1595,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98205594223541,
                            46.97704247050492
                        ],
                        [
                            31.982143690396764,
                            46.97716238587789
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1596,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97825959130276,
                            46.9768666246573
                        ],
                        [
                            31.978535991028917,
                            46.976693569207654
                        ],
                        [
                            31.97867256501125,
                            46.976791190299394
                        ],
                        [
                            31.978877425984734,
                            46.97689768583256
                        ],
                        [
                            31.97924487503245,
                            46.977015274404046
                        ],
                        [
                            31.97953753356602,
                            46.97699530655165
                        ],
                        [
                            31.980350835032304,
                            46.97673573166942
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1597,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97973079221663,
                            46.97693362585792
                        ],
                        [
                            31.979563547657897,
                            46.97669578787082
                        ],
                        [
                            31.979687114594288,
                            46.976651414590215
                        ],
                        [
                            31.979643622284442,
                            46.976591233269595
                        ],
                        [
                            31.979823688576623,
                            46.97653160654872
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1598,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.989754097562468,
                            46.9770409687276
                        ],
                        [
                            31.98951962838186,
                            46.9771148362235
                        ],
                        [
                            31.989702539965336,
                            46.977406032681586
                        ],
                        [
                            31.989846430411006,
                            46.97748756740568
                        ],
                        [
                            31.989526944845196,
                            46.977584077734726
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1599,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98951962838186,
                            46.9771148362235
                        ],
                        [
                            31.988807492616836,
                            46.97733614567627
                        ],
                        [
                            31.988883096071344,
                            46.97744596807219
                        ],
                        [
                            31.98862945867559,
                            46.977524174792364
                        ],
                        [
                            31.98850507879882,
                            46.97733614567627
                        ],
                        [
                            31.98858555989555,
                            46.977246290820936
                        ],
                        [
                            31.988534344652177,
                            46.97716641971166
                        ],
                        [
                            31.988263635508623,
                            46.977251282761294
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1600,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.98862945867559,
                            46.977524174792364
                        ],
                        [
                            31.98847581294546,
                            46.977604045367265
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1601,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.988985526558086,
                            46.977069908628756
                        ],
                        [
                            31.989101026494673,
                            46.97724492466752
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1603,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.998492821300548,
                            46.97432591682267
                        ],
                        [
                            31.99848713059677,
                            46.97432591682267
                        ],
                        [
                            31.99848713059677,
                            46.97431426807581
                        ],
                        [
                            31.99870747618252,
                            46.97459809960335
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1604,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99909868208784,
                            46.974746638480056
                        ],
                        [
                            31.99883139324218,
                            46.97482651827704
                        ],
                        [
                            31.99868286308804,
                            46.97460540137201
                        ],
                        [
                            31.998738955973636,
                            46.97458876074561
                        ],
                        [
                            31.99879261003813,
                            46.974673627886084
                        ],
                        [
                            31.998970643979387,
                            46.9746170498074
                        ],
                        [
                            31.999009665117196,
                            46.97466364352359
                        ],
                        [
                            31.999629125679913,
                            46.97448059654785
                        ],
                        [
                            31.99968521856551,
                            46.9745571435412
                        ],
                        [
                            32.000926578512065,
                            46.97417107932702
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1611,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.993915577517267,
                            46.97362962789156
                        ],
                        [
                            31.993707058312086,
                            46.97330512882203
                        ],
                        [
                            31.994160679039123,
                            46.97317033632202
                        ],
                        [
                            31.99436554001263,
                            46.97348485162688
                        ],
                        [
                            31.995081638861947,
                            46.97326456629989
                        ],
                        [
                            31.9945795465653,
                            46.972485759908785
                        ],
                        [
                            31.9934235453577,
                            46.972840218069976
                        ],
                        [
                            31.993654013952884,
                            46.973172208442406
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1612,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00898043985437,
                            46.86666428651167
                        ],
                        [
                            32.00896154938589,
                            46.866972881297215
                        ],
                        [
                            32.00898043985437,
                            46.86666428651167
                        ],
                        [
                            32.008692018082016,
                            46.8666745270952
                        ],
                        [
                            32.00871768772999,
                            46.867376534342796
                        ],
                        [
                            32.00906309759083,
                            46.86775622241544
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1613,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99459842225132,
                            46.97361693965395
                        ],
                        [
                            31.99468563528372,
                            46.9737298893854
                        ],
                        [
                            31.994283229800065,
                            46.9738530318758
                        ],
                        [
                            31.994329567401216,
                            46.97391709914042
                        ],
                        [
                            31.99421860104056,
                            46.97395204488877
                        ],
                        [
                            31.99421860104056,
                            46.97395204488877
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1614,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.994086904700463,
                            46.97344948954868
                        ],
                        [
                            31.993843733997714,
                            46.97351782471801
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1615,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.013860866285306,
                            46.96663290833211
                        ],
                        [
                            32.01368039352294,
                            46.96663956557348
                        ],
                        [
                            32.01350845663447,
                            46.967029012752285
                        ],
                        [
                            32.01330249884993,
                            46.96698919037141
                        ],
                        [
                            32.0133645661888,
                            46.9674284428108
                        ],
                        [
                            32.01310605115081,
                            46.96744009280941
                        ],
                        [
                            32.013145072288616,
                            46.967819548519664
                        ],
                        [
                            32.01288899607174,
                            46.96783286270622
                        ],
                        [
                            32.012949966599564,
                            46.96832049250574
                        ],
                        [
                            32.01395476089816,
                            46.968258914926466
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1616,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01310605115081,
                            46.96744009280941
                        ],
                        [
                            32.01279388204833,
                            46.96738433922165
                        ],
                        [
                            32.01281827025947,
                            46.96731984805887
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1617,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03812388459905,
                            46.93952205232142
                        ],
                        [
                            32.038065352892346,
                            46.939648603858814
                        ],
                        [
                            32.03905817485954,
                            46.93988783445425
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1618,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04086784943994,
                            46.942769549999355
                        ],
                        [
                            32.041027993534804,
                            46.942812554581195
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1619,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04223520998576,
                            46.94207076902612
                        ],
                        [
                            32.042505282172336,
                            46.94213770605884
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1620,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04203583635975,
                            46.94242168232898
                        ],
                        [
                            32.0423041194169,
                            46.94248895800047
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1621,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04189865267216,
                            46.942795071538235
                        ],
                        [
                            32.04210229298104,
                            46.94284136648398
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1622,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0403691266365,
                            46.943693516853884
                        ],
                        [
                            32.04032195482259,
                            46.94378285451426
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1623,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03410429498135,
                            46.950056988976186
                        ],
                        [
                            32.03379749863989,
                            46.950627943630344
                        ],
                        [
                            32.03558271569467,
                            46.95109408793953
                        ],
                        [
                            32.03485106936075,
                            46.95245254248423
                        ],
                        [
                            32.03399260432894,
                            46.95224611288791
                        ],
                        [
                            32.03378286571321,
                            46.95212292065268
                        ],
                        [
                            32.033353633197315,
                            46.95201637578812
                        ],
                        [
                            32.03374872221763,
                            46.951307181128655
                        ],
                        [
                            32.03368531286869,
                            46.95123726001949
                        ],
                        [
                            32.03397797140226,
                            46.95067622305084
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1624,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03397797140226,
                            46.95067622305084
                        ],
                        [
                            32.0347120565573,
                            46.951112400668784
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1625,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.035541735694245,
                            46.95117017679853
                        ],
                        [
                            32.03595097768274,
                            46.95127055579716
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1626,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.036824075641235,
                            46.95167010351342
                        ],
                        [
                            32.03740343330474,
                            46.95180911233181
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1627,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03660945938327,
                            46.95205300060923
                        ],
                        [
                            32.03614364455068,
                            46.95194812037279
                        ],
                        [
                            32.0354547264324,
                            46.95332476723349
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1628,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06682815184469,
                            46.95055385477212
                        ],
                        [
                            32.067026436305625,
                            46.950776111368754
                        ],
                        [
                            32.066833769437686,
                            46.9508543570876
                        ],
                        [
                            32.06795318832861,
                            46.95215038921618
                        ],
                        [
                            32.06769878599439,
                            46.95225059082185
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1629,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05307265439787,
                            46.956146404046436
                        ],
                        [
                            32.05385192465278,
                            46.957056215007405
                        ],
                        [
                            32.053544633192516,
                            46.95716940825818
                        ],
                        [
                            32.053705595385985,
                            46.957362502074524
                        ],
                        [
                            32.05354219437141,
                            46.95742742139075
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1630,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.043743377562116,
                            46.953232396178926
                        ],
                        [
                            32.043495470796096,
                            46.95294197721614
                        ],
                        [
                            32.04396860209203,
                            46.95275885590172
                        ],
                        [
                            32.043861293963054,
                            46.952635664846746
                        ],
                        [
                            32.044075910221004,
                            46.95254909798984
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1631,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04197636398781,
                            46.95194927454254
                        ],
                        [
                            32.0424711659286,
                            46.952086295879425
                        ],
                        [
                            32.04290527608672,
                            46.9525857224464
                        ],
                        [
                            32.043436939089375,
                            46.95237263434785
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1632,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04197087015311,
                            46.95194786823459
                        ],
                        [
                            32.044066154936544,
                            46.95050474767037
                        ],
                        [
                            32.044714881352625,
                            46.95082772025999
                        ],
                        [
                            32.04518499559294,
                            46.95050279577727
                        ],
                        [
                            32.045339219557576,
                            46.950587988214956
                        ],
                        [
                            32.046441566700686,
                            46.94986212630871
                        ],
                        [
                            32.046080621175946,
                            46.94962571961778
                        ],
                        [
                            32.04667569352754,
                            46.949239475453574
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1633,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.045587979311115,
                            46.95003526857484
                        ],
                        [
                            32.04648546548072,
                            46.950681217471185
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1634,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04475146366932,
                            46.950548865175115
                        ],
                        [
                            32.044931249813494,
                            46.95067817513029
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1635,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04340395387608,
                            46.9509608406977
                        ],
                        [
                            32.044034450262075,
                            46.95141123022917
                        ],
                        [
                            32.04464903318257,
                            46.95100835098737
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1636,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0434783990483,
                            46.951357957114986
                        ],
                        [
                            32.044146636033275,
                            46.95092511109658
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1637,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0423841582783,
                            46.95166322046632
                        ],
                        [
                            32.04272724214547,
                            46.951880697255135
                        ],
                        [
                            32.04319549579918,
                            46.9515377536628
                        ],
                        [
                            32.04356619660836,
                            46.95176749281715
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1638,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04197636398781,
                            46.95194927454254
                        ],
                        [
                            32.04181512304918,
                            46.95205716251917
                        ],
                        [
                            32.04207851572939,
                            46.95221365009672
                        ],
                        [
                            32.04251750352976,
                            46.9527480350772
                        ],
                        [
                            32.04277114092551,
                            46.95310095930371
                        ],
                        [
                            32.04285649966447,
                            46.953190854725335
                        ],
                        [
                            32.042924786655625,
                            46.95321582564901
                        ],
                        [
                            32.04304428889018,
                            46.95335899205305
                        ],
                        [
                            32.042624811658726,
                            46.95352546413433
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1639,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04214707627561,
                            46.952297110002604
                        ],
                        [
                            32.03980797327312,
                            46.95382677728452
                        ],
                        [
                            32.03995430253991,
                            46.95399657731789
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1640,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.040931524125085,
                            46.95309203143882
                        ],
                        [
                            32.041202978949805,
                            46.953264102735034
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1641,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04132313871694,
                            46.95283593278723
                        ],
                        [
                            32.04161757853903,
                            46.95301439319943
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1642,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.041312208126975,
                            46.95179563405515
                        ],
                        [
                            32.04098348504963,
                            46.95197558902604
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1643,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.039368129446515,
                            46.953183626150896
                        ],
                        [
                            32.039612867584076,
                            46.953347338987975
                        ],
                        [
                            32.0417317177294,
                            46.951903249323486
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1644,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.042839267134696,
                            46.953172706325894
                        ],
                        [
                            32.041993156990436,
                            46.95351214638687
                        ],
                        [
                            32.042073638087174,
                            46.95367861799173
                        ],
                        [
                            32.04182731715475,
                            46.95377517128525
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1645,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.042073638087174,
                            46.95367861799173
                        ],
                        [
                            32.04236385779962,
                            46.95387505381938
                        ],
                        [
                            32.042485798855274,
                            46.95384009495365
                        ],
                        [
                            32.04293210311897,
                            46.95437279900189
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1646,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04433545202771,
                            46.95455509417621
                        ],
                        [
                            32.04377837404521,
                            46.954767329519655
                        ],
                        [
                            32.04346132730052,
                            46.95441275159176
                        ],
                        [
                            32.043773496402984,
                            46.95428124087099
                        ],
                        [
                            32.04364667770511,
                            46.9540764830295
                        ],
                        [
                            32.04338816266713,
                            46.95378183012666
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1647,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04615622463046,
                            46.95495210863542
                        ],
                        [
                            32.0463293809295,
                            46.95487053955487
                        ],
                        [
                            32.04643181141624,
                            46.95498040808329
                        ],
                        [
                            32.04513638815112,
                            46.95550397144144
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1648,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04534559116339,
                            46.9554194194766
                        ],
                        [
                            32.045170940900775,
                            46.955220120453234
                        ],
                        [
                            32.0454172618332,
                            46.95512190509817
                        ],
                        [
                            32.04500753988621,
                            46.954639149037526
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1649,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04513638815112,
                            46.95550397144144
                        ],
                        [
                            32.04359546246173,
                            46.95613401401287
                        ],
                        [
                            32.04334670270819,
                            46.95581440273942
                        ],
                        [
                            32.0432553545115,
                            46.9557213048258
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1650,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.043497508762385,
                            46.955614816709165
                        ],
                        [
                            32.042964017091705,
                            46.955841956012485
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1651,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04403201144097,
                            46.95579942091415
                        ],
                        [
                            32.04392470331199,
                            46.95567290755562
                        ],
                        [
                            32.043649116526225,
                            46.95577944514058
                        ],
                        [
                            32.04327335687608,
                            46.95537141285441
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1652,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04318208228305,
                            46.95541155664414
                        ],
                        [
                            32.04347352140607,
                            46.95528337770511
                        ],
                        [
                            32.04357351307171,
                            46.95524259343286
                        ],
                        [
                            32.044295404121165,
                            46.954938791242995
                        ],
                        [
                            32.04450026509467,
                            46.95518183313286
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1653,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.043539369576116,
                            46.95513855178119
                        ],
                        [
                            32.04376861876075,
                            46.95537326946074
                        ],
                        [
                            32.04402225615651,
                            46.95527006039514
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1654,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.043953969165344,
                            46.9549521086354
                        ],
                        [
                            32.044042311154065,
                            46.955045303630754
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1655,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04192243117815,
                            46.95646777270611
                        ],
                        [
                            32.043100381775766,
                            46.95600333984267
                        ],
                        [
                            32.04279796795774,
                            46.95564544080008
                        ],
                        [
                            32.04159075150677,
                            46.95609489502721
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1656,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04220004634069,
                            46.95635831720137
                        ],
                        [
                            32.04231264255624,
                            46.95647776046535
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1657,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.042447426188495,
                            46.95626078240454
                        ],
                        [
                            32.04252238117198,
                            46.95634126092772
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1658,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04269928113547,
                            46.9561614830215
                        ],
                        [
                            32.04281016206331,
                            46.95627800492637
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1659,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04166696078308,
                            46.95606652194913
                        ],
                        [
                            32.04185902182922,
                            46.956278004926354
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1660,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.041868777113656,
                            46.95582688759059
                        ],
                        [
                            32.04198358811269,
                            46.95594863991881
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1661,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04216387446834,
                            46.95577361887184
                        ],
                        [
                            32.042242289557386,
                            46.9558523238092
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1662,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0423614189785,
                            46.955633788232866
                        ],
                        [
                            32.04248749204955,
                            46.955761033281064
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1663,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.040404728899524,
                            46.95529269473315
                        ],
                        [
                            32.04238824601075,
                            46.954547591362264
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1664,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04193462528372,
                            46.95456756759568
                        ],
                        [
                            32.04172488666799,
                            46.95465746055374
                        ],
                        [
                            32.04181256086364,
                            46.954763847153956
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1665,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04402594476251,
                            46.95950096233256
                        ],
                        [
                            32.04487340472497,
                            46.95937995786112
                        ],
                        [
                            32.04560017341666,
                            46.95909032779584
                        ],
                        [
                            32.04591234251913,
                            46.95896382221791
                        ],
                        [
                            32.045668460407825,
                            46.958640898728575
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1666,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03263882937633,
                            46.95589394850384
                        ],
                        [
                            32.032586623957265,
                            46.95542154460055
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1667,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04221603252795,
                            46.95102385730464
                        ],
                        [
                            32.04240003364613,
                            46.95114652892026
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1668,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04065637475803,
                            46.95072983190373
                        ],
                        [
                            32.04044897675566,
                            46.950687044294284
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1669,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04057854781281,
                            46.950880040928844
                        ],
                        [
                            32.04116111252068,
                            46.95102888347384
                        ],
                        [
                            32.04070911767439,
                            46.95143509212769
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1670,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04076439761963,
                            46.95107993718989
                        ],
                        [
                            32.04089446807899,
                            46.95111101334103
                        ],
                        [
                            32.041307441787474,
                            46.95029192875614
                        ],
                        [
                            32.04121639246591,
                            46.95026973116744
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1671,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04093661591907,
                            46.95097152569621
                        ],
                        [
                            32.04093684804489,
                            46.950972014539296
                        ],
                        [
                            32.04093708862786,
                            46.95097249927613
                        ],
                        [
                            32.040937337595345,
                            46.95097297976042
                        ],
                        [
                            32.04093759487222,
                            46.95097345584715
                        ],
                        [
                            32.040937860380836,
                            46.950973927392624
                        ],
                        [
                            32.04093813404106,
                            46.95097439425453
                        ],
                        [
                            32.040938415770306,
                            46.95097485629197
                        ],
                        [
                            32.04093870548354,
                            46.9509753133655
                        ],
                        [
                            32.040939003093314,
                            46.950975765337155
                        ],
                        [
                            32.04093930850983,
                            46.95097621207054
                        ],
                        [
                            32.04093962164088,
                            46.95097665343083
                        ],
                        [
                            32.04093994239199,
                            46.950977089284805
                        ],
                        [
                            32.04094027066633,
                            46.95097751950093
                        ],
                        [
                            32.04094060636484,
                            46.95097794394937
                        ],
                        [
                            32.0409409493862,
                            46.950978362502006
                        ],
                        [
                            32.04094129962688,
                            46.950978775032524
                        ],
                        [
                            32.040941656981175,
                            46.950979181416415
                        ],
                        [
                            32.04094202134123,
                            46.95097958153104
                        ],
                        [
                            32.04094239259709,
                            46.95097997525563
                        ],
                        [
                            32.04094277063669,
                            46.950980362471356
                        ],
                        [
                            32.04094315534594,
                            46.95098074306136
                        ],
                        [
                            32.040943546608744,
                            46.95098111691077
                        ],
                        [
                            32.040943944307,
                            46.95098148390676
                        ],
                        [
                            32.04094434832069,
                            46.950981843938564
                        ],
                        [
                            32.04094475852787,
                            46.95098219689753
                        ],
                        [
                            32.04094517480475,
                            46.95098254267712
                        ],
                        [
                            32.040945597025676,
                            46.950982881172976
                        ],
                        [
                            32.040946025063235,
                            46.95098321228295
                        ],
                        [
                            32.040946458788234,
                            46.9509835359071
                        ],
                        [
                            32.04094689806976,
                            46.950983851947754
                        ],
                        [
                            32.04094734277526,
                            46.950984160309524
                        ],
                        [
                            32.0409477927705,
                            46.950984460899356
                        ],
                        [
                            32.04094824791967,
                            46.95098475362652
                        ],
                        [
                            32.0409487080854,
                            46.95098503840268
                        ],
                        [
                            32.04094917312882,
                            46.95098531514187
                        ],
                        [
                            32.04094964290957,
                            46.950985583760584
                        ],
                        [
                            32.04095011728586,
                            46.950985844177744
                        ],
                        [
                            32.04095059611453,
                            46.95098609631475
                        ],
                        [
                            32.04095107925105,
                            46.95098634009551
                        ],
                        [
                            32.04095156654962,
                            46.95098657544645
                        ],
                        [
                            32.040952057863166,
                            46.95098680229654
                        ],
                        [
                            32.040952553043404,
                            46.95098702057731
                        ],
                        [
                            32.04095305194088,
                            46.950987230222886
                        ],
                        [
                            32.040953554405036,
                            46.950987431169985
                        ],
                        [
                            32.04095406028421,
                            46.950987623357975
                        ],
                        [
                            32.04095456942573,
                            46.95098780672884
                        ],
                        [
                            32.040955081675925,
                            46.95098798122724
                        ],
                        [
                            32.04095559688021,
                            46.95098814680052
                        ],
                        [
                            32.04095611488308,
                            46.950988303398695
                        ],
                        [
                            32.040956635528204,
                            46.950988450974506
                        ],
                        [
                            32.040957158658436,
                            46.950988589483416
                        ],
                        [
                            32.0409576841159,
                            46.95098871888362
                        ],
                        [
                            32.04095821174201,
                            46.950988839136066
                        ],
                        [
                            32.04095874137752,
                            46.95098895020446
                        ],
                        [
                            32.04095927286259,
                            46.95098905205528
                        ],
                        [
                            32.040959806036795,
                            46.95098914465778
                        ],
                        [
                            32.04096034073923,
                            46.95098922798403
                        ],
                        [
                            32.040960876808526,
                            46.950989302008864
                        ],
                        [
                            32.04096141408288,
                            46.95098936670995
                        ],
                        [
                            32.04096195240014,
                            46.95098942206775
                        ],
                        [
                            32.040962491597845,
                            46.95098946806557
                        ],
                        [
                            32.04096303151325,
                            46.95098950468952
                        ],
                        [
                            32.04096357198342,
                            46.95098953192855
                        ],
                        [
                            32.04096411284522,
                            46.950989549774434
                        ],
                        [
                            32.04096465393541,
                            46.95098955822179
                        ],
                        [
                            32.040965195090706,
                            46.950989557268066
                        ],
                        [
                            32.04096573614777,
                            46.950989546913554
                        ],
                        [
                            32.04096627694331,
                            46.950989527161376
                        ],
                        [
                            32.0409668173141,
                            46.95098949801749
                        ],
                        [
                            32.04096735709707,
                            46.9509894594907
                        ],
                        [
                            32.04096789612929,
                            46.95098941159263
                        ],
                        [
                            32.040968434248086,
                            46.950989354337736
                        ],
                        [
                            32.04096897129104,
                            46.95098928774329
                        ],
                        [
                            32.04096950709609,
                            46.95098921182941
                        ],
                        [
                            32.0409700415015,
                            46.950989126618985
                        ],
                        [
                            32.040970574346,
                            46.95098903213775
                        ],
                        [
                            32.04097110546876,
                            46.95098892841421
                        ],
                        [
                            32.04097163470949,
                            46.95098881547967
                        ],
                        [
                            32.04097216190846,
                            46.950988693368224
                        ],
                        [
                            32.040972686906564,
                            46.95098856211671
                        ],
                        [
                            32.040973209545335,
                            46.950988421764755
                        ],
                        [
                            32.04097372966706,
                            46.95098827235472
                        ],
                        [
                            32.04097424711474,
                            46.950988113931686
                        ],
                        [
                            32.040974761732215,
                            46.95098794654347
                        ],
                        [
                            32.04097527336417,
                            46.950987770240594
                        ],
                        [
                            32.04097578185619,
                            46.95098758507627
                        ],
                        [
                            32.040976287054804,
                            46.95098739110637
                        ],
                        [
                            32.04097678880755,
                            46.95098718838946
                        ],
                        [
                            32.04097728696298,
                            46.9509869769867
                        ],
                        [
                            32.040977781370756,
                            46.950986756961896
                        ],
                        [
                            32.040978271881656,
                            46.950986528381456
                        ],
                        [
                            32.04097875834765,
                            46.95098629131437
                        ],
                        [
                            32.0409792406219,
                            46.95098604583219
                        ],
                        [
                            32.04097971855887,
                            46.95098579200899
                        ],
                        [
                            32.04098019201431,
                            46.95098552992139
                        ],
                        [
                            32.04098066084532,
                            46.95098525964849
                        ],
                        [
                            32.04098112491042,
                            46.95098498127185
                        ],
                        [
                            32.04098158406953,
                            46.950984694875494
                        ],
                        [
                            32.040982038184076,
                            46.95098440054586
                        ],
                        [
                            32.04098248711702,
                            46.95098409837178
                        ],
                        [
                            32.04098293073285,
                            46.95098378844444
                        ],
                        [
                            32.04098336889769,
                            46.95098347085739
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1672,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04116111252068,
                            46.95102888347384
                        ],
                        [
                            32.04118550073181,
                            46.951009645829075
                        ],
                        [
                            32.04097344533478,
                            46.950954372249555
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1673,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04097088447386,
                            46.95073254898636
                        ],
                        [
                            32.041072391076575,
                            46.95075812562221
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1674,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0389995944008,
                            46.951711198799934
                        ],
                        [
                            32.03961060602655,
                            46.9521780493182
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1675,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03364699337816,
                            46.94994322092624
                        ],
                        [
                            32.033181109185186,
                            46.94983083362066
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1676,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.033292972207896,
                            46.94985781885419
                        ],
                        [
                            32.033542416016765,
                            46.949384903121235
                        ],
                        [
                            32.03227350642428,
                            46.9490415735682
                        ],
                        [
                            32.03207695550791,
                            46.94939082257697
                        ],
                        [
                            32.03068375636538,
                            46.94904946622634
                        ],
                        [
                            32.030573919088575,
                            46.94925072861554
                        ],
                        [
                            32.03044384862921,
                            46.94921718493656
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1677,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03131676593429,
                            46.94962760026974
                        ],
                        [
                            32.03142082230178,
                            46.94944409764917
                        ],
                        [
                            32.03114333865514,
                            46.949378983664836
                        ],
                        [
                            32.03146417912157,
                            46.94877914202251
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1678,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03272586257742,
                            46.94872882605285
                        ],
                        [
                            32.03315075941135,
                            46.947915871281346
                        ],
                        [
                            32.03298022258686,
                            46.94787838035085
                        ],
                        [
                            32.0331912257765,
                            46.947458085490595
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1679,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02794505058209,
                            46.94775801455438
                        ],
                        [
                            32.02836994741601,
                            46.94786062147987
                        ],
                        [
                            32.028699459246404,
                            46.94724695020751
                        ],
                        [
                            32.030404827491424,
                            46.94765343421639
                        ],
                        [
                            32.03066063272817,
                            46.94772052351333
                        ],
                        [
                            32.03062739249967,
                            46.94777971988196
                        ],
                        [
                            32.031144783882475,
                            46.94793165692844
                        ],
                        [
                            32.031835602544454,
                            46.94662537888519
                        ],
                        [
                            32.032405022111,
                            46.946761533220766
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1680,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.030073339704856,
                            46.94757442249994
                        ],
                        [
                            32.02990477883653,
                            46.947852728646424
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1681,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.031144783882475,
                            46.94793165692844
                        ],
                        [
                            32.030990144558565,
                            46.94820790499897
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1682,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.031144783882475,
                            46.94793165692844
                        ],
                        [
                            32.03194543982125,
                            46.948136869917
                        ],
                        [
                            32.032089962553876,
                            46.94808951314333
                        ],
                        [
                            32.03390805853035,
                            46.948523615333805
                        ],
                        [
                            32.034509273098074,
                            46.94731995969867
                        ],
                        [
                            32.03385313989194,
                            46.94714434210568
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1683,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03292530394846,
                            46.94849993712327
                        ],
                        [
                            32.03380400216284,
                            46.94871501381761
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1684,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.034153693641755,
                            46.94900219817062
                        ],
                        [
                            32.03346870942314,
                            46.94882452501343
                        ],
                        [
                            32.03355747093809,
                            46.94865467109316
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1685,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03347449033245,
                            46.948929103063335
                        ],
                        [
                            32.033796776026215,
                            46.94893008964774
                        ],
                        [
                            32.034069923990884,
                            46.94900013709391
                        ],
                        [
                            32.034153693641755,
                            46.94900219817062
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1686,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03278816913525,
                            46.94860961573826
                        ],
                        [
                            32.032098716558664,
                            46.9484343351127
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1687,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03355261524896,
                            46.94913481193716
                        ],
                        [
                            32.033428788001295,
                            46.949354158817556
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1688,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.030647708323045,
                            46.949855010945114
                        ],
                        [
                            32.031988879281876,
                            46.95018255030177
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1689,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03133825242682,
                            46.95002365514796
                        ],
                        [
                            32.03125759425475,
                            46.950176630933576
                        ],
                        [
                            32.03237909065998,
                            46.95044892119222
                        ],
                        [
                            32.033248305606726,
                            46.95073664590776
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1690,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.033248305606726,
                            46.95073664590776
                        ],
                        [
                            32.032864687041624,
                            46.95053968430393
                        ],
                        [
                            32.032714383399686,
                            46.95050219521123
                        ],
                        [
                            32.03304678568473,
                            46.949868822886174
                        ],
                        [
                            32.032454242480945,
                            46.94971294505876
                        ],
                        [
                            32.03249470884608,
                            46.94961823425836
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1691,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.039119630909944,
                            46.947870000637245
                        ],
                        [
                            32.039769983206796,
                            46.94803772304329
                        ],
                        [
                            32.03980580009119,
                            46.947966017691996
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1692,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.040035905034834,
                            46.94729382083858
                        ],
                        [
                            32.03970005738985,
                            46.947939560588964
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1693,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04200141419865,
                            46.94969518679645
                        ],
                        [
                            32.04209474220096,
                            46.94952572593377
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1694,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04340395387608,
                            46.9509608406977
                        ],
                        [
                            32.043024387390446,
                            46.95070870820166
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1695,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04011105685583,
                            46.95002470025965
                        ],
                        [
                            32.040943182412306,
                            46.950226802838785
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1696,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.998165440146234,
                            46.9555142981819
                        ],
                        [
                            31.998252295479016,
                            46.95551684068746
                        ],
                        [
                            31.998303681339507,
                            46.955500180421225
                        ],
                        [
                            31.998349928613955,
                            46.95544669216298
                        ],
                        [
                            31.99874816903278,
                            46.955458091304465
                        ],
                        [
                            31.99876872337697,
                            46.95526167498852
                        ],
                        [
                            31.999384069056383,
                            46.9552739510294
                        ],
                        [
                            31.999517672293663,
                            46.95544055416271
                        ],
                        [
                            31.999569058154165,
                            46.955961404823356
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1697,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.999384069056383,
                            46.9552739510294
                        ],
                        [
                            31.999443162795952,
                            46.95525641382731
                        ],
                        [
                            31.999458578554105,
                            46.9550143998514
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1698,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.998066613608003,
                            46.95591899437462
                        ],
                        [
                            31.9985310637722,
                            46.95595088263795
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1699,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99803996337156,
                            46.956094478018535
                        ],
                        [
                            31.99787846334393,
                            46.95608416350007
                        ],
                        [
                            31.997847631827632,
                            46.95628233048399
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1700,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99741342130646,
                            46.95649891037486
                        ],
                        [
                            31.997714028590348,
                            46.9565190776885
                        ],
                        [
                            31.997962460157048,
                            46.9565922315775
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1701,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.997972941533085,
                            46.956535793752415
                        ],
                        [
                            31.998419299525622,
                            46.95656905752
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1702,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00013542597923,
                            46.95978068252461
                        ],
                        [
                            32.0004751728936,
                            46.95976439475406
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1703,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.984383133297406,
                            46.957204368213965
                        ],
                        [
                            31.98438566617661,
                            46.9570651192258
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1704,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.975370469094464,
                            46.95898289590666
                        ],
                        [
                            31.975120843884188,
                            46.95905416231689
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1705,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.96868743269967,
                            46.9676269623011
                        ],
                        [
                            31.968603815950985,
                            46.96836479655993
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1706,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99002691593789,
                            46.97397260336043
                        ],
                        [
                            31.98999282131237,
                            46.97391207837252
                        ],
                        [
                            31.990721820760584,
                            46.97369902408715
                        ],
                        [
                            31.99103527450956,
                            46.97395497841162
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1707,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.989084981966798,
                            46.97621139269022
                        ],
                        [
                            31.988864221903928,
                            46.97628305464883
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1708,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.982553891058398,
                            46.96650538861569
                        ],
                        [
                            31.982825098649975,
                            46.96638540063099
                        ],
                        [
                            31.982629832380123,
                            46.9659575801182
                        ],
                        [
                            31.983328680082764,
                            46.96578574960488
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1709,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.981897702774976,
                            46.965456879090766
                        ],
                        [
                            31.981612392342445,
                            46.965543783252286
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1710,
                id: 1,
                district: 5,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.055023427989276,
                            46.94381690359989
                        ],
                        [
                            32.05582258502417,
                            46.94435269005491
                        ],
                        [
                            32.0554209,
                            46.9446242
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1711,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.006736024448955,
                            46.96003328365713
                        ],
                        [
                            32.00627350754125,
                            46.960066354010436
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1712,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.028587717069385,
                            46.95761504799779
                        ],
                        [
                            32.02882412364511,
                            46.95770996365152
                        ],
                        [
                            32.031869596591015,
                            46.95753278762835
                        ],
                        [
                            32.03185569032186,
                            46.95739674135527
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1713,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02992303235428,
                            46.95764603267188
                        ],
                        [
                            32.02991344806258,
                            46.957545443078054
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1714,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03092373027842,
                            46.95758781521386
                        ],
                        [
                            32.03091469944207,
                            46.957488493530924
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1715,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02975764794192,
                            46.957655654210754
                        ],
                        [
                            32.02978365621709,
                            46.95807380543106
                        ],
                        [
                            32.029565791333596,
                            46.9580801330923
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1716,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02999587966648,
                            46.95764179464649
                        ],
                        [
                            32.03002006279281,
                            46.95800420110808
                        ],
                        [
                            32.030149854638296,
                            46.9579947096025
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1717,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.031071144970106,
                            46.957983636177175
                        ],
                        [
                            32.03078490759658,
                            46.9579947096025
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1718,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.031034178011,
                            46.95758138970826
                        ],
                        [
                            32.031071144970106,
                            46.957983636177175
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1719,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03109500901896,
                            46.95757785074906
                        ],
                        [
                            32.031178918556094,
                            46.95838722150227
                        ],
                        [
                            32.03298673354685,
                            46.95829942563968
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1720,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.032259742168215,
                            46.958335923059266
                        ],
                        [
                            32.03226032129654,
                            46.95833647677706
                        ],
                        [
                            32.03226090999931,
                            46.95833702030448
                        ],
                        [
                            32.03226150809725,
                            46.95833755347599
                        ],
                        [
                            32.032262115408216,
                            46.95833807612923
                        ],
                        [
                            32.032262731747245,
                            46.95833858810502
                        ],
                        [
                            32.03226335692665,
                            46.958339089247445
                        ],
                        [
                            32.03226399075602,
                            46.958339579403884
                        ],
                        [
                            32.03226463304234,
                            46.958340058425065
                        ],
                        [
                            32.03226528359001,
                            46.95834052616511
                        ],
                        [
                            32.032265942200894,
                            46.958340982481566
                        ],
                        [
                            32.03226660867443,
                            46.95834142723547
                        ],
                        [
                            32.03226728280765,
                            46.95834186029137
                        ],
                        [
                            32.032267964395245,
                            46.95834228151739
                        ],
                        [
                            32.03226865322965,
                            46.95834269078524
                        ],
                        [
                            32.03226934910108,
                            46.95834308797029
                        ],
                        [
                            32.03227005179762,
                            46.958343472951576
                        ],
                        [
                            32.03227076110526,
                            46.95834384561185
                        ],
                        [
                            32.032271476807985,
                            46.95834420583763
                        ],
                        [
                            32.03227219868784,
                            46.95834455351921
                        ],
                        [
                            32.03227292652499,
                            46.958344888550705
                        ],
                        [
                            32.032273660097765,
                            46.95834521083008
                        ],
                        [
                            32.03227439918276,
                            46.95834552025919
                        ],
                        [
                            32.032275143554905,
                            46.95834581674381
                        ],
                        [
                            32.03227589298749,
                            46.95834610019364
                        ],
                        [
                            32.032276647252296,
                            46.95834637052235
                        ],
                        [
                            32.03227740611962,
                            46.95834662764762
                        ],
                        [
                            32.032278169358335,
                            46.958346871491145
                        ],
                        [
                            32.03227893673602,
                            46.95834710197867
                        ],
                        [
                            32.032279708018976,
                            46.958347319039994
                        ],
                        [
                            32.032280482972304,
                            46.95834752260902
                        ],
                        [
                            32.032281261360005,
                            46.958347712623734
                        ],
                        [
                            32.03228204294503,
                            46.95834788902629
                        ],
                        [
                            32.03228282748935,
                            46.95834805176297
                        ],
                        [
                            32.032283614754036,
                            46.95834820078419
                        ],
                        [
                            32.032284404499336,
                            46.95834833604458
                        ],
                        [
                            32.032285196484736,
                            46.95834845750294
                        ],
                        [
                            32.03228599046904,
                            46.9583485651223
                        ],
                        [
                            32.03228678621046,
                            46.958348658869866
                        ],
                        [
                            32.032287583466655,
                            46.9583487387171
                        ],
                        [
                            32.03228838199481,
                            46.95834880463968
                        ],
                        [
                            32.03228918155176,
                            46.95834885661752
                        ],
                        [
                            32.032289981893996,
                            46.9583488946348
                        ],
                        [
                            32.032290782777785,
                            46.95834891867995
                        ],
                        [
                            32.03229158395922,
                            46.958348928745636
                        ],
                        [
                            32.03229238519431,
                            46.9583489248288
                        ],
                        [
                            32.032293186239045,
                            46.95834890693063
                        ],
                        [
                            32.032293986849474,
                            46.95834887505657
                        ],
                        [
                            32.032294786781776,
                            46.95834882921634
                        ],
                        [
                            32.03229558579233,
                            46.9583487694239
                        ],
                        [
                            32.03229638363782,
                            46.958348695697445
                        ],
                        [
                            32.032297180075254,
                            46.958348608059445
                        ],
                        [
                            32.0322979748621,
                            46.95834850653658
                        ],
                        [
                            32.03229876775629,
                            46.95834839115977
                        ],
                        [
                            32.032299558516364,
                            46.95834826196414
                        ],
                        [
                            32.032300346901515,
                            46.95834811898906
                        ],
                        [
                            32.03230113267163,
                            46.958347962278054
                        ],
                        [
                            32.03230191558741,
                            46.95834779187885
                        ],
                        [
                            32.03230269541043,
                            46.95834760784335
                        ],
                        [
                            32.0323034719032,
                            46.958347410227596
                        ],
                        [
                            32.03230424482924,
                            46.95834719909176
                        ],
                        [
                            32.03230501395318,
                            46.95834697450015
                        ],
                        [
                            32.03230577904076,
                            46.95834673652117
                        ],
                        [
                            32.032306539859,
                            46.95834648522729
                        ],
                        [
                            32.0323072961762,
                            46.958346220695034
                        ],
                        [
                            32.032308047762015,
                            46.95834594300497
                        ],
                        [
                            32.03230879438756,
                            46.95834565224167
                        ],
                        [
                            32.03230953582546,
                            46.958345348493665
                        ],
                        [
                            32.03231027184992,
                            46.958345031853476
                        ],
                        [
                            32.03231100223678,
                            46.958344702417534
                        ],
                        [
                            32.032311726763616,
                            46.958344360286155
                        ],
                        [
                            32.03231244520977,
                            46.95834400556354
                        ],
                        [
                            32.03231315735645,
                            46.95834363835772
                        ],
                        [
                            32.032313862986776,
                            46.95834325878051
                        ],
                        [
                            32.032314561885855,
                            46.958342866947525
                        ],
                        [
                            32.03231525384084,
                            46.95834246297808
                        ],
                        [
                            32.032315938641005,
                            46.95834204699521
                        ],
                        [
                            32.03231661607779,
                            46.958341619125605
                        ],
                        [
                            32.032317285944906,
                            46.958341179499556
                        ],
                        [
                            32.03231794803833,
                            46.95834072825095
                        ],
                        [
                            32.032318602156444,
                            46.958340265517215
                        ],
                        [
                            32.03231924810003,
                            46.95833979143928
                        ],
                        [
                            32.03231988567237,
                            46.958339306161506
                        ],
                        [
                            32.03232051467931,
                            46.95833880983169
                        ],
                        [
                            32.032321134929276,
                            46.95833830260098
                        ],
                        [
                            32.03232174623338,
                            46.95833778462386
                        ],
                        [
                            32.03232234840546,
                            46.95833725605806
                        ],
                        [
                            32.032322941262116,
                            46.95833671706456
                        ],
                        [
                            32.03232352462281,
                            46.95833616780751
                        ],
                        [
                            32.032324098309886,
                            46.95833560845418
                        ],
                        [
                            32.032324662148625,
                            46.958335039174905
                        ],
                        [
                            32.03232521596731,
                            46.958334460143064
                        ],
                        [
                            32.0323257595973,
                            46.958333871535004
                        ],
                        [
                            32.03232629287301,
                            46.958333273529966
                        ],
                        [
                            32.032326815632054,
                            46.95833266631008
                        ],
                        [
                            32.03232732771522,
                            46.95833205006026
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1721,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03183192818633,
                            46.95835550834909
                        ],
                        [
                            32.03177688812996,
                            46.95791561365699
                        ],
                        [
                            32.03194839878292,
                            46.957909285976314
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1722,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03278395478905,
                            46.958309273521806
                        ],
                        [
                            32.03282733893067,
                            46.958838844252284
                        ],
                        [
                            32.03303888205619,
                            46.95883035112381
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1723,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03282733893067,
                            46.958838844252284
                        ],
                        [
                            32.032625170548684,
                            46.95884696099811
                        ],
                        [
                            32.032632123683264,
                            46.9589347559623
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1724,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03312463738265,
                            46.959397061302546
                        ],
                        [
                            32.033189533305396,
                            46.96011839202419
                        ],
                        [
                            32.031446614237424,
                            46.96023861286569
                        ],
                        [
                            32.03137708289162,
                            46.95966597959446
                        ],
                        [
                            32.03169229165923,
                            46.95965016090883
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1725,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03229286112057,
                            46.96018024156465
                        ],
                        [
                            32.032239271579506,
                            46.95965648838365
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1726,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.031434723546774,
                            46.96014068617093
                        ],
                        [
                            32.02886931901987,
                            46.96029872318511
                        ],
                        [
                            32.02885398178527,
                            46.960094831624424
                        ],
                        [
                            32.02881613706446,
                            46.95992811492047
                        ],
                        [
                            32.02873952717438,
                            46.959223054628694
                        ],
                        [
                            32.02924942371023,
                            46.95920090828416
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1727,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.028620165030766,
                            46.95907040285342
                        ],
                        [
                            32.02892610295227,
                            46.9590577477644
                        ],
                        [
                            32.02881948822204,
                            46.95760555642315
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1728,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02871287349182,
                            46.958488265661316
                        ],
                        [
                            32.02888281498939,
                            46.958485647331585
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1729,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.028895456477905,
                            46.958652719837396
                        ],
                        [
                            32.02924594714293,
                            46.958630636755316
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1730,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02891754489544,
                            46.95894464364288
                        ],
                        [
                            32.03008495871555,
                            46.9588869037699
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1731,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.029323239583675,
                            46.960270760279975
                        ],
                        [
                            32.02933286132518,
                            46.96037781560781
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1732,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02984986472526,
                            46.96023831853059
                        ],
                        [
                            32.02986129955325,
                            46.960333523865515
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1733,
                id: 1,
                district: 4,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03129015630176,
                            46.95799022487684
                        ],
                        [
                            32.031138414068415,
                            46.95799652670692
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1734,
                id: 1,
                district: 9,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.032151198541506,
                            46.95880860009008
                        ],
                        [
                            32.03156249981376,
                            46.95883707416871
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1735,
                id: null,
                district: null,
                n_type: null,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07336444024254,
                            46.962538389388214
                        ],
                        [
                            32.07374334529619,
                            46.96298098071456
                        ],
                        [
                            32.07374334529619,
                            46.96298098071456
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1736,
                id: 12,
                district: 5,
                n_type: 0,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.073357760826525,
                            46.96254087586656
                        ],
                        [
                            32.0737585257871,
                            46.963010818200345
                        ],
                        [
                            32.0737585257871,
                            46.963010818200345
                        ],
                        [
                            32.0737585257871,
                            46.963010818200345
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1739,
                id: 31,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0579668202782,
                            46.91518533039531
                        ],
                        [
                            32.05786156106819,
                            46.915166744057885
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1740,
                id: 36,
                district: 1,
                n_type: 1,
                OKP_comm: "",
                diameter_1: 89,
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04644836336987,
                            46.942859317177444
                        ],
                        [
                            32.046750492985176,
                            46.942288939856816
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1741,
                id: 1749,
                district: 1,
                n_type: 1,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.078889687285454,
                            46.91243266271248
                        ],
                        [
                            32.078809840526866,
                            46.912339132801
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1742,
                id: 1750,
                district: 1,
                n_type: -1,
                OKP_comm: " длинна 15 м",
                diameter_1: 89,
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.08069065696705,
                            46.91374262393458
                        ],
                        [
                            32.080905456252054,
                            46.9139912261021
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1745,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.023578995322225,
                            46.84986400220298
                        ],
                        [
                            32.02370995805226,
                            46.849904714478086
                        ],
                        [
                            32.023769486565925,
                            46.84956273040801
                        ],
                        [
                            32.02219793380539,
                            46.849383595026104
                        ],
                        [
                            32.022019348264415,
                            46.84964415538409
                        ],
                        [
                            32.021090703451364,
                            46.84956273040801
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1746,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.021632813376186,
                            46.849610263437825
                        ],
                        [
                            32.021400251722376,
                            46.85074338047978
                        ],
                        [
                            32.02174551710159,
                            46.85077594976908
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1747,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01435802855677,
                            46.887151651010946
                        ],
                        [
                            32.014381839962226,
                            46.887196403412204
                        ],
                        [
                            32.01598315697961,
                            46.88703366722869
                        ],
                        [
                            32.01642366798066,
                            46.88687093055148
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1748,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01598315697961,
                            46.88703366722869
                        ],
                        [
                            32.015989109830976,
                            46.88711096697742
                        ],
                        [
                            32.0154116832485,
                            46.88716385621497
                        ],
                        [
                            32.016364139467015,
                            46.89071952073368
                        ],
                        [
                            32.01673321625169,
                            46.89068697567426
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1749,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04782862488351,
                            46.872523764162224
                        ],
                        [
                            32.048640499571796,
                            46.87251832647834
                        ],
                        [
                            32.04864843670695,
                            46.87239352797899
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1750,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.04865637384211,
                            46.87273536665557
                        ],
                        [
                            32.04865637384211,
                            46.87296325789698
                        ],
                        [
                            32.049172287627115,
                            46.87296325789698
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1751,
                id: null,
                district: 1,
                n_type: 2,
                OKP_comm: "длинна 16,0 м",
                diameter_1: 57,
                layingMeth: "воздушка",
                material: "???",
                diameter_2: 57
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02793231825452,
                            46.94199656906914
                        ],
                        [
                            32.02788814702698,
                            46.94207719955502
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1752,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.048084636766845,
                            46.87273731964178
                        ],
                        [
                            32.048084900111,
                            46.87293612803802
                        ],
                        [
                            32.048315077030466,
                            46.87293612803802
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1753,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.030360582306436,
                            46.86221024858463
                        ],
                        [
                            32.03039302585219,
                            46.86276689104102
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1754,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03037249824541,
                            46.86241469415763
                        ],
                        [
                            32.03017078606788,
                            46.862419561839005
                        ],
                        [
                            32.03017872320302,
                            46.86269091297009
                        ],
                        [
                            32.029035775740816,
                            46.86270176698682
                        ],
                        [
                            32.02915483276813,
                            46.86362977730142
                        ],
                        [
                            32.0293850096876,
                            46.86362977730142
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1755,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.03017078606788,
                            46.862419561839
                        ],
                        [
                            32.02997235768901,
                            46.862419561839005
                        ],
                        [
                            32.02998823195932,
                            46.86254981055309
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1756,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.029281889692896,
                            46.86269942976238
                        ],
                        [
                            32.02928976406576,
                            46.862555237576
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1757,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02906981281399,
                            46.86296707639904
                        ],
                        [
                            32.02877385028072,
                            46.862973116691535
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1758,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02909417695824,
                            46.863156987245794
                        ],
                        [
                            32.02927388979544,
                            46.863163060668796
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1759,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.029123438174274,
                            46.863385068340015
                        ],
                        [
                            32.02959931233677,
                            46.86333672314563
                        ],
                        [
                            32.029821552121085,
                            46.86312507192711
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1761,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.021828857020765,
                            46.872664828694255
                        ],
                        [
                            32.02188441696685,
                            46.87309890698599
                        ],
                        [
                            32.021424063127895,
                            46.87314231462205
                        ],
                        [
                            32.021463748803676,
                            46.87323998167484
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1762,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02145581166851,
                            46.87328338919681
                        ],
                        [
                            32.021765359939536,
                            46.873242694645995
                        ],
                        [
                            32.02170979999345,
                            46.872938841024535
                        ],
                        [
                            32.022027285399616,
                            46.87291985011608
                        ],
                        [
                            32.02200347399416,
                            46.87270281068495
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1763,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.02061844390975,
                            46.872827608465094
                        ],
                        [
                            32.02200347399416,
                            46.87270281068495
                        ],
                        [
                            32.02224158804879,
                            46.87268381969296
                        ],
                        [
                            32.02226936802183,
                            46.87286559034059
                        ],
                        [
                            32.02309086151029,
                            46.87281675649573
                        ],
                        [
                            32.02311864148333,
                            46.873199287091126
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1764,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.020972813195854,
                            46.87279567813918
                        ],
                        [
                            32.02091608647803,
                            46.87249933542109
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1765,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00414350919062,
                            46.85587898706478
                        ],
                        [
                            32.0040476899915,
                            46.85547929058458
                        ],
                        [
                            32.00373814172049,
                            46.85550778620081
                        ],
                        [
                            32.00370242461229,
                            46.85527982084754
                        ],
                        [
                            32.00358932043633,
                            46.855287962483956
                        ],
                        [
                            32.00352979192269,
                            46.855047783690566
                        ],
                        [
                            32.00396435007237,
                            46.85500707531404
                        ],
                        [
                            32.003976255775115,
                            46.85510884619751
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1766,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.005621945640804,
                            46.851594449729916
                        ],
                        [
                            32.005512091427455,
                            46.85106839396114
                        ],
                        [
                            32.004762032155384,
                            46.85114574552292
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1767,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.011170276650546,
                            46.855467078172985
                        ],
                        [
                            32.01133100363742,
                            46.85545486575863
                        ],
                        [
                            32.01121688412829,
                            46.855032070378
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1768,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01283846914232,
                            46.8561842910246
                        ],
                        [
                            32.01001839991129,
                            46.85649901715395
                        ],
                        [
                            32.010116621958815,
                            46.85693865377271
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1769,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01143734918264,
                            46.85679458965194
                        ],
                        [
                            32.010116621958815,
                            46.85693865377271
                        ],
                        [
                            32.007670000047504,
                            46.85721953083915
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1770,
                id: null,
                district: 9,
                n_type: 1,
                OKP_comm: "",
                diameter_1: 100,
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99002691593789,
                            46.97397260336043
                        ],
                        [
                            31.989754928203563,
                            46.97404257818716
                        ],
                        [
                            31.99038362116323,
                            46.974961369591824
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1771,
                id: 2,
                district: 2,
                n_type: 1,
                OKP_comm: "",
                diameter_1: 89,
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99111486687517,
                            46.97542380167777
                        ],
                        [
                            31.99122187844278,
                            46.975575916621445
                        ],
                        [
                            31.991324431195064,
                            46.97554853596351
                        ],
                        [
                            31.99136010171759,
                            46.97562155102018
                        ],
                        [
                            31.99148048973115,
                            46.97558808579825
                        ],
                        [
                            31.991538454330257,
                            46.97568239682456
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1772,
                id: 2,
                district: 1,
                n_type: 2,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99063588514688,
                            46.97487028058923
                        ],
                        [
                            31.99018743328929,
                            46.97418556993965
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1773,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.991039067014782,
                            46.97545118239955
                        ],
                        [
                            31.991168372658976,
                            46.97540554785545
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1774,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: "",
                diameter_1: 57,
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.990888512240247,
                            46.975020926746794
                        ],
                        [
                            31.991119325690484,
                            46.97542988628382
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1776,
                id: 7,
                district: 7,
                n_type: 7,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.992686713917198,
                            47.004936861478136
                        ],
                        [
                            31.99291182312463,
                            47.004951416426756
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1777,
                id: 7,
                district: 7,
                n_type: 7,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.995132838993477,
                            47.00333399546488
                        ],
                        [
                            31.99512190938921,
                            47.00360232104439
                        ],
                        [
                            31.99589244648947,
                            47.00362468144852
                        ],
                        [
                            31.99587605208308,
                            47.003919092563294
                        ],
                        [
                            31.996143827387424,
                            47.00393399941203
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1778,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: "",
                diameter_1: 100,
                layingMeth: "воздушка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99301988767709,
                            46.97511349679805
                        ],
                        [
                            31.992712229420235,
                            46.97466323141598
                        ],
                        [
                            31.993233910812293,
                            46.974495902098326
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1779,
                id: 2,
                district: 1,
                n_type: 2,
                OKP_comm: "",
                diameter_1: 100,
                layingMeth: "подземка ",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.993233910812293,
                            46.974495902098326
                        ],
                        [
                            31.993131358060015,
                            46.97433465698775
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1780,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: "",
                diameter_1: 159,
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.9952727334994,
                            46.97420288879147
                        ],
                        [
                            31.995652372240098,
                            46.97410024223812
                        ],
                        [
                            31.995746007361756,
                            46.97422482749257
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1781,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: "",
                diameter_1: 100,
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.994950108827716,
                            46.974608164916525
                        ],
                        [
                            31.99489860951081,
                            46.97452510870786
                        ],
                        [
                            31.99530124053391,
                            46.9744133020694
                        ],
                        [
                            31.995193560144006,
                            46.97421524402176
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1782,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: "",
                diameter_1: 89,
                layingMeth: "воздушка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.994458524439047,
                            46.97367217787002
                        ],
                        [
                            31.99459897712152,
                            46.973873432440016
                        ],
                        [
                            31.99513737907102,
                            46.97371051213223
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1783,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.994835086049726,
                            46.9728821342339
                        ],
                        [
                            31.995099925022355,
                            46.97280006598142
                        ],
                        [
                            31.995404239167726,
                            46.97329202828249
                        ],
                        [
                            31.995483829021133,
                            46.97326966645789
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1784,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.007998430096656,
                            46.85243014516536
                        ],
                        [
                            32.00790885803096,
                            46.85219161832903
                        ],
                        [
                            32.00789364124626,
                            46.85195510650774
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1785,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: "",
                diameter_1: "???",
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.995975413409795,
                            46.9739916292154
                        ],
                        [
                            31.99606436677537,
                            46.97396926768334
                        ],
                        [
                            31.995994140434135,
                            46.97385426537074
                        ],
                        [
                            31.996097139067952,
                            46.97383190378123
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1786,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: "",
                diameter_1: "???",
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99848713059677,
                            46.9743142680758
                        ],
                        [
                            31.998367625817394,
                            46.974349214308795
                        ],
                        [
                            31.998094472035994,
                            46.97392985800573
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1787,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: "",
                diameter_1: 100,
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.9995000758695,
                            46.973964804489924
                        ],
                        [
                            31.99925537560699,
                            46.973576508938336
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1788,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.999078665261102,
                            46.97330090650302
                        ],
                        [
                            31.999016366048252,
                            46.973203742556386
                        ],
                        [
                            31.999090345197377,
                            46.973161029575856
                        ],
                        [
                            31.999050510270926,
                            46.97308725252916
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1789,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: "",
                diameter_1: "???",
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.999016366048252,
                            46.973203742556386
                        ],
                        [
                            31.99887978915755,
                            46.97323868951506
                        ],
                        [
                            31.9989480776029,
                            46.97335129622659
                        ],
                        [
                            31.99847574918921,
                            46.97350273246481
                        ],
                        [
                            31.99824243033426,
                            46.97344060482922
                        ],
                        [
                            31.998043255701983,
                            46.97350273246481
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1790,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.999078665261102,
                            46.97330090650302
                        ],
                        [
                            31.999187087161637,
                            46.973273636450905
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1791,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.992620015000238,
                            46.97310278454745
                        ],
                        [
                            31.99279073611361,
                            46.97305618847906
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1792,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: "",
                diameter_1: 57,
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.977402622152923,
                            46.977976691265866
                        ],
                        [
                            31.97761362913403,
                            46.97799126315047
                        ],
                        [
                            31.977402622152923,
                            46.977976691265866
                        ],
                        [
                            31.977238042684597,
                            46.97796408460018
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1793,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: "",
                diameter_1: 57,
                layingMeth: "воздушная",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00086962508763,
                            46.97227454666056
                        ],
                        [
                            32.00109783575089,
                            46.97220078448352
                        ],
                        [
                            32.0011338690135,
                            46.9722526912113
                        ],
                        [
                            32.00170639751957,
                            46.97206965147313
                        ],
                        [
                            32.00184252317836,
                            46.97229093824165
                        ],
                        [
                            32.00193460818282,
                            46.97226361893704
                        ],
                        [
                            32.00199065992468,
                            46.97235377258925
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1794,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99945493917872,
                            46.97350429742132
                        ],
                        [
                            31.999596189568187,
                            46.97370609328386
                        ],
                        [
                            31.999477009552077,
                            46.973736212004
                        ],
                        [
                            31.999596189568187,
                            46.9739018646616
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1795,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99923125283828,
                            46.971297646850395
                        ],
                        [
                            31.999169455792885,
                            46.971206533515584
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1796,
                id: 2,
                district: 2,
                n_type: 1,
                OKP_comm: "",
                diameter_1: 57,
                layingMeth: "воздушная",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99940652838666,
                            46.97138261052276
                        ],
                        [
                            31.999455083208037,
                            46.97143983856278
                        ],
                        [
                            31.999900904749786,
                            46.971298274352165
                        ],
                        [
                            31.999949459571166,
                            46.97137959851895
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1797,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.999526896041395,
                            46.97141703545699
                        ],
                        [
                            31.999605161746842,
                            46.97155128246496
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1798,
                id: 2,
                district: 2,
                n_type: 0,
                OKP_comm: "",
                diameter_1: 57,
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.997162361046612,
                            46.97123428304431
                        ],
                        [
                            31.997086564307693,
                            46.97109395938755
                        ],
                        [
                            31.99686475705549,
                            46.97106233316849
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1799,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.974171614933688,
                            46.97620589416865
                        ],
                        [
                            31.97351273186068,
                            46.976655233840546
                        ],
                        [
                            31.973234645156424,
                            46.976655233840546
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1800,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: "длинна 18 м",
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.974171614933688,
                            46.97620589416865
                        ],
                        [
                            31.974430417984706,
                            46.976029096338365
                        ],
                        [
                            31.974235757291726,
                            46.97590576536002
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1801,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97442973828596,
                            46.9759051867314
                        ],
                        [
                            31.974541652666403,
                            46.975962687385376
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1802,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: "длина 83 м, длина 27,5 м",
                diameter_1: 89,
                layingMeth: null,
                material: "сталь",
                diameter_2: 89
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.974444322319915,
                            46.976176144440785
                        ],
                        [
                            31.97470850468895,
                            46.97636113986624
                        ],
                        [
                            31.974555557001615,
                            46.97645126561091
                        ],
                        [
                            31.974833643705868,
                            46.97665049040218
                        ],
                        [
                            31.97472240902417,
                            46.97671215506809
                        ],
                        [
                            31.974903165381928,
                            46.97684022760855
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1803,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97761362913403,
                            46.97799126315047
                        ],
                        [
                            31.978080305977983,
                            46.97798812334936
                        ],
                        [
                            31.978851996582275,
                            46.978092476285674
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1816,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: "длинна 11,5 м",
                diameter_1: 32,
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94779634692665,
                            46.99984434096885
                        ],
                        [
                            31.94801924415811,
                            47.00006584952251
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1874,
                id: 2,
                district: 2,
                n_type: 2,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.994926353094094,
                            46.97285385243307
                        ],
                        [
                            31.995006289900708,
                            46.972966183628095
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1888,
                id: 7,
                district: 7,
                n_type: 7,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99807836734125,
                            47.00358741410311
                        ],
                        [
                            31.998067437737003,
                            47.0037103962443
                        ],
                        [
                            31.997564675941085,
                            47.00368430914709
                        ],
                        [
                            31.997564475631336,
                            47.00394359163662
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1893,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: "",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.958959188480137,
                            46.971560523117
                        ],
                        [
                            31.959305258427925,
                            46.971525429301245
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1894,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: "длина 44 м, в помещении 2 м",
                diameter_1: 107,
                layingMeth: "подземка",
                material: "???",
                diameter_2: 108
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.958720060102998,
                            46.97125399800236
                        ],
                        [
                            31.95936736889127,
                            46.97116821004716
                        ],
                        [
                            31.959349623044606,
                            46.971071336617854
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1896,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95704400059835,
                            46.96907609045031
                        ],
                        [
                            31.957097926067203,
                            46.96939623533406
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1897,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: "длинна 158 м",
                diameter_1: 108,
                layingMeth: "подземка",
                material: "???",
                diameter_2: 108
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.95704400059835,
                            46.96907609045031
                        ],
                        [
                            31.956121875081035,
                            46.96914232755391
                        ],
                        [
                            31.95661798939445,
                            46.969904047278334
                        ],
                        [
                            31.956051771971534,
                            46.97000708099751
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1901,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: "длинна 20 м",
                diameter_1: "???",
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.968174503741412,
                            46.97401160652668
                        ],
                        [
                            31.968193091966437,
                            46.97398726294192
                        ],
                        [
                            31.96811514487383,
                            46.97396529494516
                        ],
                        [
                            31.96815750742416,
                            46.973895922264596
                        ],
                        [
                            31.968069393319485,
                            46.97386817316716
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1902,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: "длинна 22 м",
                diameter_1: "???",
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.970288993767753,
                            46.973618789767606
                        ],
                        [
                            31.97009395986571,
                            46.97384908108128
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1903,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: "длинна 204 м",
                diameter_1: 57,
                layingMeth: "надземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.956051771971534,
                            46.97000708099751
                        ],
                        [
                            31.95599770914529,
                            46.96996647721888
                        ],
                        [
                            31.955280908933723,
                            46.97035778082495
                        ],
                        [
                            31.9554074030887,
                            46.970461360712065
                        ],
                        [
                            31.955230311271723,
                            46.97055343155443
                        ],
                        [
                            31.955361021898543,
                            46.970665642679265
                        ],
                        [
                            31.95508273475758,
                            46.97082964313055
                        ],
                        [
                            31.954981539433593,
                            46.97074908156819
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1906,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: "длинна 24 м",
                diameter_1: "???",
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.975830688105393,
                            46.974609653430704
                        ],
                        [
                            31.975762495309734,
                            46.974654642365856
                        ],
                        [
                            31.975392733518348,
                            46.97439764231576
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1907,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: "подвал 21 метр",
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.975392733518348,
                            46.97439764231576
                        ],
                        [
                            31.975018106440217,
                            46.97412431905577
                        ],
                        [
                            31.974958101237227,
                            46.97416194258336
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1908,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: "длинна 28 м",
                diameter_1: "???",
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.974958101237227,
                            46.97416194258336
                        ],
                        [
                            31.974633748788627,
                            46.974375510929676
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1909,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.97503274269202,
                            46.9729148495764
                        ],
                        [
                            31.974862385765913,
                            46.97279860742145
                        ],
                        [
                            31.975087385479636,
                            46.97255734931236
                        ],
                        [
                            31.97517738536513,
                            46.972098955906674
                        ],
                        [
                            31.975566313441732,
                            46.97216036769133
                        ],
                        [
                            31.975585599131485,
                            46.97260779427972
                        ],
                        [
                            31.976164169823942,
                            46.97301354554735
                        ],
                        [
                            31.97583827657988,
                            46.97323134334058
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1910,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.975601952849694,
                            46.974973392301166
                        ],
                        [
                            31.975494231037594,
                            46.97503464268628
                        ],
                        [
                            31.975395486043173,
                            46.97496420473735
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1912,
                id: 7,
                district: 7,
                n_type: 7,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.002241577585195,
                            47.01947282832708
                        ],
                        [
                            32.00251786849976,
                            47.01936461565325
                        ],
                        [
                            32.00227668509295,
                            47.01910869017593
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1915,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: "длинна 7 м",
                diameter_1: 89,
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94779634692665,
                            46.99944041124213
                        ],
                        [
                            31.94779634692665,
                            46.99984434096885
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1917,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: "длинна 22,3 м",
                diameter_1: "???",
                layingMeth: "воздушка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94801924415811,
                            47.00006584952251
                        ],
                        [
                            31.948006507173464,
                            47.00035250628757
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1918,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: "длинна 23,4 м",
                diameter_1: "???",
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94779634692665,
                            46.99984434096885
                        ],
                        [
                            31.947127655232265,
                            46.99984868428264
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1919,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: "длинна 8,1 м",
                diameter_1: 57,
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.947127655232265,
                            46.99984868428264
                        ],
                        [
                            31.947121286739943,
                            47.000130998922344
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1920,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: "длинна 23,8 м",
                diameter_1: 57,
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.947127655232265,
                            46.99984868428264
                        ],
                        [
                            31.947127655232265,
                            46.999575054823666
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1921,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: "длинна 8 м",
                diameter_1: 57,
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.947121286739943,
                            47.000130998922344
                        ],
                        [
                            31.9469047580008,
                            47.000130998922344
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1922,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: "длинна 3 м",
                diameter_1: 57,
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.947127655232265,
                            46.999575054823666
                        ],
                        [
                            31.9469047580008,
                            46.999579398159355
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1923,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.94779634692665,
                            46.99944041124213
                        ],
                        [
                            31.94799377018881,
                            46.99944041124213
                        ],
                        [
                            31.947981033204154,
                            46.9990060747955
                        ],
                        [
                            31.947758135972695,
                            46.9990060747955
                        ],
                        [
                            31.94777087295735,
                            46.99891920708249
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1924,
                id: 3,
                district: 3,
                n_type: 3,
                OKP_comm: "длинна 15 м",
                diameter_1: 89,
                layingMeth: "подземка",
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.947675345572435,
                            46.998932237248454
                        ],
                        [
                            31.947681714064753,
                            46.9990060747955
                        ],
                        [
                            31.94722318261718,
                            46.99901041817745
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1925,
                id: 4,
                district: 4,
                n_type: 4,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01443135810203,
                            46.95966222371777
                        ],
                        [
                            32.01452969434415,
                            46.960417964315354
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1926,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.06821188511057,
                            46.95595955636794
                        ],
                        [
                            32.06771173850492,
                            46.95613176855533
                        ],
                        [
                            32.067796847035844,
                            46.956236333186745
                        ],
                        [
                            32.06749896717759,
                            46.95634089761382
                        ],
                        [
                            32.06757556485543,
                            46.95642803448031
                        ],
                        [
                            32.063890365466136,
                            46.957926766374754
                        ],
                        [
                            32.063073323569206,
                            46.95697408894621
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1927,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07095314669256,
                            46.95910866326964
                        ],
                        [
                            32.070622458709764,
                            46.959198937007436
                        ],
                        [
                            32.070409687382444,
                            46.95887945101484
                        ],
                        [
                            32.06905646174065,
                            46.95936739248999
                        ],
                        [
                            32.068996885768996,
                            46.95926283397705
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1928,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.069299021053794,
                            46.959893086361625
                        ],
                        [
                            32.06926497764143,
                            46.959855329515385
                        ],
                        [
                            32.069196890816684,
                            46.95988437324563
                        ],
                        [
                            32.068996885768996,
                            46.9596665448845
                        ],
                        [
                            32.06871177219038,
                            46.95977400698682
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1929,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07369681432808,
                            46.96145203877819
                        ],
                        [
                            32.07322677975624,
                            46.96163568104103
                        ],
                        [
                            32.07318422549078,
                            46.961571786905175
                        ],
                        [
                            32.07303949247289,
                            46.961619990033206
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1930,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.072892782883855,
                            46.961782013161965
                        ],
                        [
                            32.07310452933821,
                            46.961695152670224
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1931,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07413455736104,
                            46.96540795119574
                        ],
                        [
                            32.07496799828646,
                            46.965092757079816
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1932,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07468705162813,
                            46.96519900684969
                        ],
                        [
                            32.07420267298173,
                            46.964624995436395
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1933,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.071333234400264,
                            46.9653632978082
                        ],
                        [
                            32.071880981556106,
                            46.9655690185346
                        ],
                        [
                            32.07227078814935,
                            46.96590797680702
                        ],
                        [
                            32.072577217951164,
                            46.96581244529459
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1934,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07477938219296,
                            46.96465939837799
                        ],
                        [
                            32.07444643542861,
                            46.96424682971779
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1935,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07503087031763,
                            46.96640139323808
                        ],
                        [
                            32.07515111307724,
                            46.96635741500179
                        ],
                        [
                            32.075181668079104,
                            46.9663895919931
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1936,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0784604115946,
                            46.968855922243414
                        ],
                        [
                            32.07825384781539,
                            46.968928472130315
                        ],
                        [
                            32.07797721087824,
                            46.968628146425004
                        ],
                        [
                            32.07786404122214,
                            46.96867105020044
                        ],
                        [
                            32.077825979192816,
                            46.968618164776046
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1937,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.078781972877195,
                            46.969936696093576
                        ],
                        [
                            32.078932865752,
                            46.96987663221963
                        ],
                        [
                            32.07871723426777,
                            46.96964088874505
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1938,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07764560586906,
                            46.97022205490018
                        ],
                        [
                            32.078178401377976,
                            46.97001821124316
                        ],
                        [
                            32.07823498620602,
                            46.97006969443154
                        ],
                        [
                            32.07869395203356,
                            46.96988950305543
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1939,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07596432991684,
                            46.96925420304589
                        ],
                        [
                            32.07615394550562,
                            46.96940208641356
                        ],
                        [
                            32.07629224044294,
                            46.969541989401485
                        ],
                        [
                            32.07619164519306,
                            46.969576311838175
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1940,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.076026031376614,
                            46.9694536651727
                        ],
                        [
                            32.075839561818526,
                            46.96951624755952
                        ],
                        [
                            32.07599674189645,
                            46.96969644019312
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1941,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0762042195993,
                            46.969713601364646
                        ],
                        [
                            32.07611619875567,
                            46.96973934311163
                        ],
                        [
                            32.0762042195993,
                            46.969812277994144
                        ],
                        [
                            32.07613506036502,
                            46.969893793333355
                        ],
                        [
                            32.07533029836607,
                            46.96961492455311
                        ],
                        [
                            32.07523695543373,
                            46.96969497663219
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1942,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07613506036502,
                            46.969893793333355
                        ],
                        [
                            32.07607847553696,
                            46.96995814746077
                        ],
                        [
                            32.07624194281801,
                            46.97001821124316
                        ],
                        [
                            32.0759778802871,
                            46.97033569011531
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1943,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.077845524848655,
                            46.97055864210511
                        ],
                        [
                            32.07753710666007,
                            46.970661747535914
                        ],
                        [
                            32.07744279861331,
                            46.97080332448119
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1945,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.0735805113208,
                            46.971329757992116
                        ],
                        [
                            32.07379622080556,
                            46.97101783429026
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1946,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07281445813737,
                            46.97082202776758
                        ],
                        [
                            32.07309205405648,
                            46.97090199910023
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1947,
                id: 5,
                district: 5,
                n_type: null,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07567700887961,
                            46.97134548641261
                        ],
                        [
                            32.075286287944245,
                            46.97122805306844
                        ],
                        [
                            32.075474904037755,
                            46.971073606699704
                        ],
                        [
                            32.07567609453749,
                            46.97090199910023
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1948,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.073912129631644,
                            46.97188501604689
                        ],
                        [
                            32.073852805633614,
                            46.97200027822208
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1949,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07560902076614,
                            46.97146020264445
                        ],
                        [
                            32.076135060365026,
                            46.97162274731782
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1950,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07296783554509,
                            46.9711275153097
                        ],
                        [
                            32.07301660761907,
                            46.97104786559487
                        ],
                        [
                            32.0727273962757,
                            46.97096206182266
                        ],
                        [
                            32.07290343796298,
                            46.97067890839773
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1952,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07514168227255,
                            46.96756412178462
                        ],
                        [
                            32.07503165621801,
                            46.9674225362651
                        ],
                        [
                            32.07454306399258,
                            46.967612279837724
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1953,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07634098257718,
                            46.96932666744622
                        ],
                        [
                            32.07557864288918,
                            46.96841791742796
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1954,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.076307958450734,
                            46.96785158214112
                        ],
                        [
                            32.076975239361744,
                            46.96773316548208
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1955,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.07479311947679,
                            46.96751517149793
                        ],
                        [
                            32.07493106096814,
                            46.96767567374818
                        ],
                        [
                            32.075107102655416,
                            46.967611316874525
                        ],
                        [
                            32.075188836295936,
                            46.967701416475975
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1956,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.08380859176909,
                            46.97707094638754
                        ],
                        [
                            32.08323016908235,
                            46.977379804039636
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1963,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.05062192745695,
                            46.98459200185832
                        ],
                        [
                            32.05106439627841,
                            46.98459199474392
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1964,
                id: 5,
                district: 5,
                n_type: 5,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.050690647491564,
                            46.9852720586194
                        ],
                        [
                            32.05193750414262,
                            46.985221656855174
                        ],
                        [
                            32.0519541347686,
                            46.985993124646896
                        ],
                        [
                            32.05195703002849,
                            46.98605696144661
                        ],
                        [
                            32.05170293468132,
                            46.986061406006115
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1980,
                id: 7,
                district: 7,
                n_type: 7,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.914045604572845,
                            47.015652795363586
                        ],
                        [
                            31.91384926780826,
                            47.01581394778704
                        ],
                        [
                            31.913933035505128,
                            47.01585625334107
                        ],
                        [
                            31.91373757754576,
                            47.01601912941117
                        ],
                        [
                            31.91401990570928,
                            47.01634488006101
                        ],
                        [
                            31.913858575330124,
                            47.01649294788129
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1985,
                id: 4,
                district: 4,
                n_type: 4,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.013793488997656,
                            46.95622279780142
                        ],
                        [
                            32.0137665866186,
                            46.955971725064565
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1986,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.93706560075913,
                            46.94545680682939
                        ],
                        [
                            31.936837396345442,
                            46.945719780215924
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1989,
                id: 7,
                district: 7,
                n_type: 7,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.914045604572845,
                            47.015652795363586
                        ],
                        [
                            31.914576512181604,
                            47.01584422253601
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1992,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.936631893966318,
                            46.946393190095314
                        ],
                        [
                            31.937108659485876,
                            46.9465727646324
                        ],
                        [
                            31.937026458534234,
                            46.94664571661602
                        ],
                        [
                            31.938415654617067,
                            46.9471283195417
                        ],
                        [
                            31.93834989385575,
                            46.94720688239686
                        ],
                        [
                            31.938662257472014,
                            46.947319114847076
                        ],
                        [
                            31.938752678518824,
                            46.94721249402497
                        ],
                        [
                            31.93895818089794,
                            46.94728544513672
                        ],
                        [
                            31.93876911870915,
                            46.947504297875454
                        ],
                        [
                            31.938996611776695,
                            46.947603788931175
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1993,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.938996611776695,
                            46.947603788931175
                        ],
                        [
                            31.939092541776485,
                            46.947487830971845
                        ],
                        [
                            31.939180123467388,
                            46.94752113266445
                        ],
                        [
                            31.93914724308673,
                            46.94759408335558
                        ],
                        [
                            31.93929520479969,
                            46.94766703394728
                        ],
                        [
                            31.939722649748255,
                            46.94713393117804
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1994,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.940315615896193,
                            46.94769756079762
                        ],
                        [
                            31.940602199930876,
                            46.94727983351627
                        ],
                        [
                            31.94083236259549,
                            46.94737523098394
                        ],
                        [
                            31.941120065926256,
                            46.94701608669144
                        ],
                        [
                            31.94056931955022,
                            46.9468421253085
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1996,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.938826659375298,
                            46.94610699193169
                        ],
                        [
                            31.939914392678585,
                            46.94649438636366
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1997,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.947708219221614,
                            46.95675121422305
                        ],
                        [
                            31.947062411864124,
                            46.95653540160753
                        ],
                        [
                            31.947153746254834,
                            46.95640181452745
                        ],
                        [
                            31.94677536092186,
                            46.956281585870244
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1998,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.934369170835858,
                            46.94675004386249
                        ],
                        [
                            31.934157645321744,
                            46.946561541241465
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 1999,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.93436314770086,
                            46.94674111521379
                        ],
                        [
                            31.934601530460636,
                            46.946713056820336
                        ],
                        [
                            31.93493033426723,
                            46.94642686036689
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 2000,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.937249528337393,
                            46.949671271722096
                        ],
                        [
                            31.93701823843906,
                            46.94968716468086
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 2001,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.93736611082229,
                            46.95008070098116
                        ],
                        [
                            31.937190860437518,
                            46.9500799590252
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 2002,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.93830567955275,
                            46.950467465630986
                        ],
                        [
                            31.938029310144323,
                            46.9504895843423
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 2003,
                id: 7,
                district: 7,
                n_type: 7,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00360648933675,
                            47.01642079630459
                        ],
                        [
                            32.00360648933675,
                            47.01617046727026
                        ],
                        [
                            32.00397913365089,
                            47.01547178151819
                        ],
                        [
                            32.00335988648181,
                            47.015333537799435
                        ],
                        [
                            32.00317356432474,
                            47.01571464123862
                        ],
                        [
                            32.00266391842453,
                            47.01560628857674
                        ],
                        [
                            32.00258719753633,
                            47.015774421923474
                        ],
                        [
                            32.00278447982028,
                            47.01534848308358
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 2004,
                id: 7,
                district: 7,
                n_type: 7,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.003657946814634,
                            47.01540007826751
                        ],
                        [
                            32.00352976844854,
                            47.01564738788816
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 2005,
                id: 7,
                district: 7,
                n_type: 7,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00397913365089,
                            47.01547178151819
                        ],
                        [
                            32.00423121656926,
                            47.01551661724203
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 2006,
                id: 7,
                district: 7,
                n_type: 7,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.999326559787637,
                            46.99468263330494
                        ],
                        [
                            31.999893222129174,
                            46.99461013785533
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 2009,
                id: 7,
                district: 7,
                n_type: 7,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.004525532710666,
                            46.992945103741604
                        ],
                        [
                            32.00455545900803,
                            46.99258300463449
                        ],
                        [
                            32.004935392870365,
                            46.992586554637654
                        ],
                        [
                            32.00494059744383,
                            46.992501354496845
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 2013,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.010347710377225,
                            46.867701209589825
                        ],
                        [
                            32.010360545201216,
                            46.86801710794522
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 2014,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01182230248896,
                            46.86613677026143
                        ],
                        [
                            32.01200340267244,
                            46.86613924039976
                        ],
                        [
                            32.01200340267244,
                            46.86603393747071
                        ],
                        [
                            32.0116696972486,
                            46.8660251622173
                        ],
                        [
                            32.01164402760061,
                            46.865507419726875
                        ],
                        [
                            32.0118750544325,
                            46.865507419726875
                        ],
                        [
                            32.01182371513652,
                            46.864787837125185
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 2124,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.00789364124626,
                            46.85195510650774
                        ],
                        [
                            32.00808014799536,
                            46.85163346010495
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 2136,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01309007737371,
                            46.86461394724974
                        ],
                        [
                            32.01312757519806,
                            46.86605467367337
                        ],
                        [
                            32.01411054926838,
                            46.86638930637392
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 2137,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01411054926838,
                            46.86638930637392
                        ],
                        [
                            32.01414510210271,
                            46.8667503586635
                        ],
                        [
                            32.013377157269005,
                            46.866743795639664
                        ],
                        [
                            32.01338675657943,
                            46.86656659369331
                        ],
                        [
                            32.01293558898964,
                            46.86657971978345
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 2138,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.012171199942344,
                            46.86742798425319
                        ],
                        [
                            32.01381590146718,
                            46.867393595142865
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 2236,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.010377390076556,
                            46.85282267436716
                        ],
                        [
                            32.01043241888274,
                            46.85308009975902
                        ],
                        [
                            32.010240911903736,
                            46.853099502149746
                        ],
                        [
                            32.01034021181877,
                            46.85345359454913
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 3124,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.01308977987812,
                            46.864396997043
                        ],
                        [
                            32.01311746968244,
                            46.864081458170176
                        ],
                        [
                            32.01328688507183,
                            46.864085796022984
                        ],
                        [
                            32.01329971989581,
                            46.86367334259412
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 3125,
                id: 6,
                district: 6,
                n_type: 6,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.013294824078336,
                            46.86383067248857
                        ],
                        [
                            32.012824831408054,
                            46.86384007946823
                        ],
                        [
                            32.01283595667176,
                            46.8639615529122
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 3333,
                id: 7,
                district: 7,
                n_type: 7,
                OKP_comm: null,
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            31.99230753629253,
                            47.003524059556234
                        ],
                        [
                            31.991972123121116,
                            47.00350467841314
                        ],
                        [
                            31.991907350724585,
                            47.004491172179236
                        ],
                        [
                            31.99270621028182,
                            47.004535343115805
                        ],
                        [
                            31.992598256287597,
                            47.006758566406226
                        ],
                        [
                            31.991540307144234,
                            47.00671439730798
                        ],
                        [
                            31.991453943948848,
                            47.00946753461794
                        ],
                        [
                            31.992555074689907,
                            47.00954114596714
                        ],
                        [
                            31.992447120695683,
                            47.0100564225709
                        ],
                        [
                            31.99283575507487,
                            47.010071144686535
                        ],
                        [
                            31.992891511373152,
                            47.01125752670659
                        ]
                    ]
                ]
            }
        },
        {
            type: "Feature",
            properties: {
                fid: 3334,
                id: 3127,
                district: 6,
                n_type: 6,
                OKP_comm: "6",
                diameter_1: "???",
                layingMeth: null,
                material: "???",
                diameter_2: null
            },
            geometry: {
                type: "MultiLineString",
                coordinates: [
                    [
                        [
                            32.013137174508486,
                            46.86570026464589
                        ],
                        [
                            32.01287861127141,
                            46.86570077716485
                        ]
                    ]
                ]
            }
        }
    ]
}