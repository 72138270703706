export const mote_wb = {
  type: "FeatureCollection",
  name: "mote_wb",
  crs: {
    type: "name",
    properties: {
      name: "urn:ogc:def:crs:OGC:1.3:CRS84"
    }
  },
  features: [
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: "_",
        comments: "Зона розташування котелень, що планується переобладнати в ЦТП з переключенням теплової потужності на котельню № 314 по вул. Спортивна, 1-К, та їх споживачів",
        project: "ОКП \"МОТЕ + Світовий Банк\"",
        sub_projec: "Підвищення енергоефективності в секторі централізованого теплопостачання в Україні"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                31.972474846525017,
                46.983981015243046
              ],
              [
                31.978003440823496,
                46.9826742953955
              ],
              [
                31.973312842117505,
                46.98014243479921
              ],
              [
                31.97470587375176,
                46.9791400521663
              ],
              [
                31.976969550157435,
                46.98011273484321
              ],
              [
                31.977720481272787,
                46.97830100632912
              ],
              [
                31.974096422411773,
                46.97778123681674
              ],
              [
                31.973095180924652,
                46.97761045430274
              ],
              [
                31.972355132868945,
                46.9773505668218
              ],
              [
                31.971250502315225,
                46.97686048984954
              ],
              [
                31.970412506722738,
                46.976530056628526
              ],
              [
                31.96869842482901,
                46.976485503678454
              ],
              [
                31.968578711172945,
                46.97715379403169
              ],
              [
                31.96835016692045,
                46.9773505668218
              ],
              [
                31.96751761285778,
                46.97785177726117
              ],
              [
                31.96819236255563,
                46.97836040823739
              ],
              [
                31.96642930689352,
                46.97941849367158
              ],
              [
                31.972474846525017,
                46.983981015243046
              ]
            ]
          ]
        ]
      }
    }
  ]
}