export const ctpList = {
  type: "FeatureCollection",
  name: "ctp",
  crs: {
    type: "name",
    properties: {
      name: "urn:ogc:def:crs:OGC:1.3:CRS84"
    }
  },
  features: [
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 1
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.03210136774368,
                46.958890067552495
              ],
              [
                32.0323050266267,
                46.95887960504602
              ],
              [
                32.03228880264603,
                46.9587324889269
              ],
              [
                32.03208514376299,
                46.95874295146214
              ],
              [
                32.03210136774368,
                46.958890067552495
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 1
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.0383568143978,
                46.92996067644529
              ],
              [
                32.03863181712093,
                46.93001284228758
              ],
              [
                32.0386807654357,
                46.92989250720737
              ],
              [
                32.03840576271257,
                46.92984034124788
              ],
              [
                32.0383568143978,
                46.92996067644529
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 1
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.05349866979387,
                46.947558521057985
              ],
              [
                32.0537938141884,
                46.9474396961584
              ],
              [
                32.0537375236264,
                46.94737453590124
              ],
              [
                32.05344237923188,
                46.94749336094548
              ],
              [
                32.05349866979387,
                46.947558521057985
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 1
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.05158173141654,
                46.94949866883361
              ],
              [
                32.05163296040484,
                46.949552232131
              ],
              [
                32.05178102905703,
                46.94948623909007
              ],
              [
                32.051729800068735,
                46.94943267572664
              ],
              [
                32.05158173141654,
                46.94949866883361
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 1
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.026102535030724,
                46.94134708269007
              ],
              [
                32.02634040684221,
                46.94146468704292
              ],
              [
                32.02649459403375,
                46.94131931321258
              ],
              [
                32.02625672222226,
                46.941201708540405
              ],
              [
                32.026102535030724,
                46.94134708269007
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 3,
        tip: null,
        title: null,
        district: 3
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                31.958611379656286,
                46.96664179865511
              ],
              [
                31.958617808219532,
                46.96671582933787
              ],
              [
                31.958702130794812,
                46.96671241934206
              ],
              [
                31.958695702231566,
                46.96663838865457
              ],
              [
                31.958611379656286,
                46.96664179865511
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 3
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                31.970335673400438,
                46.97222453427261
              ],
              [
                31.97050203076898,
                46.97217723657684
              ],
              [
                31.970437936030468,
                46.97207227183952
              ],
              [
                31.97027157866192,
                46.972119569628134
              ],
              [
                31.970335673400438,
                46.97222453427261
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 5
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.07865779528625,
                46.970122950809696
              ],
              [
                32.07848480323364,
                46.97019238973727
              ],
              [
                32.07864797939402,
                46.97038168090711
              ],
              [
                32.07882097144662,
                46.97031224222528
              ],
              [
                32.07865779528625,
                46.970122950809696
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5,
        tip: null,
        title: null,
        district: 5
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.07683756762335,
                46.97025048141934
              ],
              [
                32.07714726704231,
                46.9703484157046
              ],
              [
                32.077195603057,
                46.9702772410103
              ],
              [
                32.07688590363805,
                46.97017930659472
              ],
              [
                32.07683756762335,
                46.97025048141934
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5,
        tip: null,
        title: null,
        district: 5
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.07606164738745,
                46.96954567288363
              ],
              [
                32.07625178095194,
                46.96977142813729
              ],
              [
                32.07636244498559,
                46.96972802847654
              ],
              [
                32.07617231142109,
                46.9695022730397
              ],
              [
                32.07606164738745,
                46.96954567288363
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5,
        tip: null,
        title: null,
        district: 5
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.073675444721594,
                46.97199312173026
              ],
              [
                32.074095465285986,
                46.97212735356064
              ],
              [
                32.074144882501,
                46.97205535667573
              ],
              [
                32.07372486193661,
                46.97192112466463
              ],
              [
                32.073675444721594,
                46.97199312173026
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5,
        tip: null,
        title: null,
        district: 5
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.07421335778497,
                46.96473163851314
              ],
              [
                32.074375265922576,
                46.96466401375669
              ],
              [
                32.07422567867714,
                46.96449721274228
              ],
              [
                32.074063770539524,
                46.96456483770959
              ],
              [
                32.07421335778497,
                46.96473163851314
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5,
        tip: null,
        title: null,
        district: 5
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.067328671593685,
                46.9561088139349
              ],
              [
                32.067487861168004,
                46.95629081273157
              ],
              [
                32.06766683162237,
                46.95621788290674
              ],
              [
                32.06750764204804,
                46.95603588386203
              ],
              [
                32.067328671593685,
                46.9561088139349
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5,
        tip: null,
        title: null,
        district: 5
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.06977949833813,
                46.95894753978718
              ],
              [
                32.06990556107177,
                46.95889591539368
              ],
              [
                32.069769329830386,
                46.95874094694204
              ],
              [
                32.06964326709675,
                46.95879257148507
              ],
              [
                32.06977949833813,
                46.95894753978718
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5,
        tip: null,
        title: null,
        district: 5
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.06927998378641,
                46.959854095361685
              ],
              [
                32.06935817170637,
                46.959943516189504
              ],
              [
                32.06952378615667,
                46.95987606071001
              ],
              [
                32.06944559823671,
                46.959786639769455
              ],
              [
                32.06927998378641,
                46.959854095361685
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5,
        tip: null,
        title: null,
        district: 5
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.06875843182797,
                46.96060658407401
              ],
              [
                32.068641323550146,
                46.960654151658524
              ],
              [
                32.068739490179965,
                46.96076672769979
              ],
              [
                32.06885659845779,
                46.960719160215355
              ],
              [
                32.06875843182797,
                46.96060658407401
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5,
        tip: null,
        title: null,
        district: 5
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.058782498485215,
                46.93865311568846
              ],
              [
                32.058914688879646,
                46.9387484149935
              ],
              [
                32.059167117356324,
                46.938585180885354
              ],
              [
                32.0590349269619,
                46.93848988128979
              ],
              [
                32.058782498485215,
                46.93865311568846
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5,
        tip: null,
        title: null,
        district: 5
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.06151905685459,
                46.94153677070291
              ],
              [
                32.06169224614902,
                46.941728916852874
              ],
              [
                32.06179642298068,
                46.941685147063524
              ],
              [
                32.06162323368624,
                46.94149300075648
              ],
              [
                32.06151905685459,
                46.94153677070291
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 5
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.05663743737172,
                46.94568472261099
              ],
              [
                32.05677200089533,
                46.94562730673316
              ],
              [
                32.05665179685882,
                46.94549600681936
              ],
              [
                32.05651723333521,
                46.94555342283803
              ],
              [
                32.05663743737172,
                46.94568472261099
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 6
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.02519744159832,
                46.85792071815136
              ],
              [
                32.025303040286296,
                46.85790496336617
              ],
              [
                32.02527535048196,
                46.857818179867934
              ],
              [
                32.025169751793996,
                46.8578339346786
              ],
              [
                32.02519744159832,
                46.85792071815136
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 6
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.0135893500979,
                46.86647280216722
              ],
              [
                32.01391238916967,
                46.866463199465926
              ],
              [
                32.01390546671859,
                46.86635434295433
              ],
              [
                32.01358242764682,
                46.8663639456751
              ],
              [
                32.0135893500979,
                46.86647280216722
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 6,
        tip: null,
        title: null,
        district: 6
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.00431110559181,
                46.85613652250596
              ],
              [
                32.00459989808246,
                46.85609995299023
              ],
              [
                32.004573112835175,
                46.8560114452119
              ],
              [
                32.00428432034236,
                46.856048014801196
              ],
              [
                32.00431110559181,
                46.85613652250596
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 6,
        tip: null,
        title: null,
        district: 6
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.00894047935515,
                46.866512411679764
              ],
              [
                32.009078981229116,
                46.86651064719921
              ],
              [
                32.00907205877804,
                46.86625664855328
              ],
              [
                32.00893355690407,
                46.866258413042196
              ],
              [
                32.00894047935515,
                46.866512411679764
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 6,
        tip: null,
        title: null,
        district: 6
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.02513584209769,
                46.854828709762465
              ],
              [
                32.02553703444816,
                46.854780764194345
              ],
              [
                32.02551626709492,
                46.854699498558624
              ],
              [
                32.025115074744434,
                46.85474744419928
              ],
              [
                32.02513584209769,
                46.854828709762465
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 6
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.02616427726615,
                46.85639044855239
              ],
              [
                32.02632173256988,
                46.856366740828854
              ],
              [
                32.02626635296122,
                46.856194746650786
              ],
              [
                32.02610889765749,
                46.856218454450264
              ],
              [
                32.02616427726615,
                46.85639044855239
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 7,
        tip: null,
        title: null,
        district: 7
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.004313927809314,
                46.9923330625459
              ],
              [
                32.00431898592144,
                46.99221781489156
              ],
              [
                32.00420068429015,
                46.99221539920941
              ],
              [
                32.00420070836052,
                46.99221501502063
              ],
              [
                32.00411523411988,
                46.992212523491304
              ],
              [
                32.00410607772866,
                46.992358669253065
              ],
              [
                32.0041915519693,
                46.99236116077559
              ],
              [
                32.0041934665105,
                46.99233060265911
              ],
              [
                32.00419346650567,
                46.992330602769236
              ],
              [
                32.004313927809314,
                46.9923330625459
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 3,
        tip: null,
        title: null,
        district: 7
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                31.958611379656286,
                46.96664179865511
              ],
              [
                31.958617808219532,
                46.96671582933787
              ],
              [
                31.958702130794812,
                46.96671241934206
              ],
              [
                31.958695702231566,
                46.96663838865457
              ],
              [
                31.958611379656286,
                46.96664179865511
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 7,
        tip: null,
        title: null,
        district: 7
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                31.997888611880466,
                47.009664205653266
              ],
              [
                31.99801669366679,
                47.00966857244144
              ],
              [
                31.99802585005801,
                47.00954370202203
              ],
              [
                31.997897768271685,
                47.00953933522365
              ],
              [
                31.997888611880466,
                47.009664205653266
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 7,
        tip: null,
        title: null,
        district: 7
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                31.998171086549586,
                47.0002181822511
              ],
              [
                31.998324837413506,
                47.000224174102705
              ],
              [
                31.99834681275243,
                46.99996189952002
              ],
              [
                31.998193061888504,
                46.999955907639006
              ],
              [
                31.998171086549586,
                47.0002181822511
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 7,
        tip: null,
        title: null,
        district: 7
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                31.99990549565154,
                46.99425392660878
              ],
              [
                31.99989181163609,
                46.99440884835013
              ],
              [
                31.999975404010513,
                46.994412283344346
              ],
              [
                31.999989088025966,
                46.99425736161294
              ],
              [
                31.99990549565154,
                46.99425392660878
              ]
            ]
          ],
          [
            [
              [
                31.999945137348117,
                46.994763229729244
              ],
              [
                31.999975404010513,
                46.99441228334435
              ],
              [
                31.999850877089948,
                46.994407287106654
              ],
              [
                31.99982061042755,
                46.99475823352435
              ],
              [
                31.999945137348117,
                46.994763229729244
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 8
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                31.938726822432116,
                46.954502761593545
              ],
              [
                31.939070412378708,
                46.954468364253025
              ],
              [
                31.939043490805584,
                46.95434307245086
              ],
              [
                31.93869990085899,
                46.954377469871915
              ],
              [
                31.938726822432116,
                46.954502761593545
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 8
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                31.946070994629395,
                46.954785184119274
              ],
              [
                31.946269940520416,
                46.954845537663
              ],
              [
                31.946426575127717,
                46.95460497883925
              ],
              [
                31.946227629236695,
                46.95454462502421
              ],
              [
                31.946070994629395,
                46.954785184119274
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 8
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                31.962610626285716,
                46.95679679806575
              ],
              [
                31.962680660287003,
                46.95683094211343
              ],
              [
                31.962754082759172,
                46.95676078183801
              ],
              [
                31.96268404875789,
                46.956726637745575
              ],
              [
                31.962610626285716,
                46.95679679806575
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 8
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                31.951421653380862,
                46.956135291180374
              ],
              [
                31.95161141730755,
                46.95619335406409
              ],
              [
                31.951675050116762,
                46.95609646483768
              ],
              [
                31.951485286190078,
                46.95603840184882
              ],
              [
                31.951421653380862,
                46.956135291180374
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 8
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                31.941705292539822,
                46.949575898158145
              ],
              [
                31.941813023752907,
                46.94961812712357
              ],
              [
                31.9419929088097,
                46.949404276787746
              ],
              [
                31.941885177596617,
                46.9493620476536
              ],
              [
                31.941705292539822,
                46.949575898158145
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 8
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                31.939647127232263,
                46.9514167411252
              ],
              [
                31.939779005938753,
                46.951463984481315
              ],
              [
                31.939856711388458,
                46.95136291010978
              ],
              [
                31.93972483268197,
                46.95131566666445
              ],
              [
                31.939647127232263,
                46.9514167411252
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 8,
        tip: null,
        title: null,
        district: 8
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                31.937744261495425,
                46.950048043065955
              ],
              [
                31.93806650330988,
                46.95001322706208
              ],
              [
                31.938039581736756,
                46.94989711366998
              ],
              [
                31.9377173399223,
                46.94993192974938
              ],
              [
                31.937744261495425,
                46.950048043065955
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 8
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                31.936316194411905,
                46.94768270663486
              ],
              [
                31.936456920816887,
                46.94766766972774
              ],
              [
                31.936389450597908,
                46.94737339906418
              ],
              [
                31.936248724192932,
                46.947388436053956
              ],
              [
                31.936316194411905,
                46.94768270663486
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 8,
        tip: null,
        title: null,
        district: 8
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                31.93884487200092,
                46.94759627046707
              ],
              [
                31.939095352677022,
                46.94768773277068
              ],
              [
                31.939151643239004,
                46.94761588973867
              ],
              [
                31.938901162562903,
                46.9475244273123
              ],
              [
                31.93884487200092,
                46.94759627046707
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 9
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.00321772242287,
                46.95536770796831
              ],
              [
                32.00333342271086,
                46.955361485829286
              ],
              [
                32.00332151940228,
                46.955258363054504
              ],
              [
                32.003205819114285,
                46.95526458520551
              ],
              [
                32.00321772242287,
                46.95536770796831
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 9
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.00893361718821,
                46.956788086476166
              ],
              [
                32.009065563817146,
                46.95678112049818
              ],
              [
                32.009052744869436,
                46.956668000929675
              ],
              [
                32.0089207982405,
                46.95667496692238
              ],
              [
                32.00893361718821,
                46.956788086476166
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 9
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.00909843223014,
                46.95756429265467
              ],
              [
                32.00918877947732,
                46.957562128949505
              ],
              [
                32.00918511692084,
                46.957490883438204
              ],
              [
                32.00909476967365,
                46.95749304714626
              ],
              [
                32.00909843223014,
                46.95756429265467
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 9
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.010418846781306,
                46.96101997253492
              ],
              [
                32.010390461968534,
                46.96080187507255
              ],
              [
                32.01025992432346,
                46.96080978866606
              ],
              [
                32.01028830913623,
                46.96102788609618
              ],
              [
                32.010418846781306,
                46.96101997253492
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 63,
        tip: null,
        title: null,
        district: 9
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.00780424871847,
                46.96392838569267
              ],
              [
                32.00799979782772,
                46.96391433774542
              ],
              [
                32.00799044048402,
                46.963853670078684
              ],
              [
                32.00779489137477,
                46.96386771805087
              ],
              [
                32.00780424871847,
                46.96392838569267
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 9
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.00546340785402,
                46.96447816702428
              ],
              [
                32.005363603189735,
                46.96448316605948
              ],
              [
                32.005388748099435,
                46.96471697304827
              ],
              [
                32.00548855276371,
                46.96471197403493
              ],
              [
                32.00546340785402,
                46.96447816702428
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 9
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.00171930225747,
                46.964781955201175
              ],
              [
                32.00214095407307,
                46.96475852235056
              ],
              [
                32.00213054134037,
                46.9646712587653
              ],
              [
                32.00170888952477,
                46.96469469165414
              ],
              [
                32.00171930225747,
                46.964781955201175
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 9
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.01247714257873,
                46.96443092692577
              ],
              [
                32.01268442459576,
                46.9644232109209
              ],
              [
                32.012670518326594,
                46.96424922059345
              ],
              [
                32.012463236309564,
                46.96425693662342
              ],
              [
                32.01247714257873,
                46.96443092692577
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 9
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.02158463831023,
                46.963541593275245
              ],
              [
                32.02200259895558,
                46.96351206719721
              ],
              [
                32.021989298941385,
                46.96342437894198
              ],
              [
                32.02157133829603,
                46.96345390506841
              ],
              [
                32.02158463831023,
                46.963541593275245
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 9
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.03654728009685,
                46.946654395248714
              ],
              [
                32.036384670327024,
                46.94661253898442
              ],
              [
                32.03622672257855,
                46.946898515438306
              ],
              [
                32.03638933234838,
                46.94694037147897
              ],
              [
                32.03654728009685,
                46.946654395248714
              ]
            ]
          ],
          [
            [
              [
                32.03652927167963,
                46.94697639208269
              ],
              [
                32.03659006483891,
                46.946866321768795
              ],
              [
                32.036450125507656,
                46.94683030109101
              ],
              [
                32.03638933234838,
                46.94694037147897
              ],
              [
                32.03652927167963,
                46.94697639208269
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 9
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.04607751161352,
                46.95191739817502
              ],
              [
                32.04637967252365,
                46.951799268163164
              ],
              [
                32.046307099068876,
                46.951712770401954
              ],
              [
                32.04600493815874,
                46.95183090060473
              ],
              [
                32.04607751161352,
                46.95191739817502
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 70,
        tip: "-3",
        title: null,
        district: 9
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.01989668513005,
                46.966887248438006
              ],
              [
                32.02034444320521,
                46.96686343860347
              ],
              [
                32.020335172359104,
                46.9667822468916
              ],
              [
                32.01988741428395,
                46.96680605676228
              ],
              [
                32.01989668513005,
                46.966887248438006
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 70,
        tip: "-2",
        title: null,
        district: 9
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.01653214056407,
                46.96795696361852
              ],
              [
                32.016967648515134,
                46.967929590499196
              ],
              [
                32.01695683252801,
                46.96784945482318
              ],
              [
                32.01652132457694,
                46.96787682798352
              ],
              [
                32.01653214056407,
                46.96795696361852
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 70,
        tip: "-1",
        title: null,
        district: 9
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.01380408318937,
                46.966676908778545
              ],
              [
                32.01427953239052,
                46.966660269916744
              ],
              [
                32.014273351826446,
                46.966578023453856
              ],
              [
                32.0137979026253,
                46.966594662341244
              ],
              [
                32.01380408318937,
                46.966676908778545
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 71,
        tip: null,
        title: null,
        district: 9
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.010430267777274,
                46.96664369388641
              ],
              [
                32.010424087213195,
                46.96658306347454
              ],
              [
                32.01007482470147,
                46.96659964402141
              ],
              [
                32.01008100526554,
                46.96666027441449
              ],
              [
                32.010430267777274,
                46.96664369388641
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 9
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.04369545412737,
                46.95486554552548
              ],
              [
                32.04394665270202,
                46.95476400016037
              ],
              [
                32.043865730141846,
                46.95467073313648
              ],
              [
                32.04361453156721,
                46.95477227867858
              ],
              [
                32.04369545412737,
                46.95486554552548
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 9
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.032738869623344,
                46.94848711142153
              ],
              [
                32.032984931012415,
                46.94854870228933
              ],
              [
                32.0330167260136,
                46.94848950677114
              ],
              [
                32.03277066462453,
                46.94842791583522
              ],
              [
                32.032738869623344,
                46.94848711142153
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        tip: null,
        title: null,
        district: 9
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.00044691067033,
                46.95987311615452
              ],
              [
                32.00054119799774,
                46.95986890262524
              ],
              [
                32.0005270668861,
                46.95972160259863
              ],
              [
                32.000432779558686,
                46.9597258161395
              ],
              [
                32.00044691067033,
                46.95987311615452
              ]
            ]
          ]
        ]
      }
    }
  ]
}