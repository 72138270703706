export const tk_tec_list =  {
  type: "FeatureCollection",
  name: "tk-tec_v_09-08-2022",
  crs: {
    type: "name",
    properties: {
      name: "urn:ogc:def:crs:OGC:1.3:CRS84"
    }
  },
  features: [
    {
      type: "Feature",
      properties: {
        id: 11,
        index: "_",
        x: 32.01252539477103,
        y: 46.957528689356415,
        title: "11"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01252539477103,
          46.957528689356415,
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 12,
        index: "_",
        x: 32.01260895604354,
        y: 46.95811867950748,
        title: "12"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01260895604354,
          46.95811867950748,
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 10,
        index: "_",
        x: 32.012377171551066,
        y: 46.95647840861141,
        title: "10"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.012377171551066,
          46.95647840861141,
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 9,
        index: "_",
        x: 32.01209022781563,
        y: 46.95406164338943,
        title: "9"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01209022781563,
          46.95406164338943,
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 8,
        index: "_",
        x: 32.01204669500599,
        y: 46.9535389493711,
        title: "8"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01204669500599,
          46.95353894937111,
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 7,
        index: "_",
        x: 32.01182940634464,
        y: 46.9507172967768,
        title: "7"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01182940634464,
          46.9507172967768,
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 217,
        index: "_",
        x: 32.01264875127643,
        y: 46.95846453546814,
        title: "217"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01264875127643,
          46.95846453546814,
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 216,
        index: "_",
        x: 32.01251369823815,
        y: 46.9584735441626,
        title: "216"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01251369823815,
          46.9584735441626
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 2,
        index: "_",
        x: 31.986794674169634,
        y: 46.953838470716285,
        title: "2"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.986794674169634,
          46.953838470716285
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        index: "_",
        x: 31.98697970074423,
        y: 46.952692833361475,
        title: "1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98697970074423,
          46.952692833361475]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 3,
        index: "_",
        x: 31.986618891826673,
        y: 46.954916445251754,
        title: "3"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.986618891826673,
          46.954916445251754]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 3,
        index: "А",
        x: 31.986558309477083,
        y: 46.95529765684767,
        title: "3А"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.986558309477083,
          46.95529765684767]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 4,
        index: "_",
        x: 31.986397951737736,
        y: 46.956395239734384,
        title: "4"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.986397951737736,
          46.956395239734384]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5,
        index: "_",
        x: 31.98546583117052,
        y: 46.95634334915419,
        title: "5"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98546583117052,
          46.95634334915419]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 6,
        index: "_",
        x: 31.984856647057125,
        y: 46.956294068272925,
        title: "6"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.984856647057125,
          46.956294068272925]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 7,
        index: "_",
        x: 31.984271882101726,
        y: 46.956246762774114,
        title: "7"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.984271882101726,
          46.956246762774114]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 8,
        index: null,
        x: 31.983049716066127,
        y: 46.9561247761305,
        title: "8"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.983049716066127,
          46.9561247761305]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 9,
        index: "_",
        x: 31.98191050201381,
        y: 46.95600232039368,
        title: "9"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98191050201381,
          46.95600232039368]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 9,
        index: "/1",
        x: 31.980504999579633,
        y: 46.9558283619571,
        title: "9/1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.980504999579633,
          46.9558283619571]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 9,
        index: "/2",
        x: 31.979051812940362,
        y: 46.956552910573805,
        title: "9/2"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.979051812940366,
          46.956552910573805]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 10,
        index: "-6",
        x: 31.990533640562024,
        y: 46.957915593183664,
        title: "10-6"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.990533640562024,
          46.957915593183664]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 10,
        index: "-5",
        x: 31.990271472804785,
        y: 46.95789882758753,
        title: "10-5"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.990271472804785,
          46.95789882758753]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 10,
        index: "-4",
        x: 31.98878213225776,
        y: 46.957803367282075,
        title: "10-4"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98878213225776,
          46.95780336728207]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 10,
        index: "-3",
        x: 31.987148736479487,
        y: 46.957689267095255,
        title: "10-3"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.987148736479487,
          46.957689267095255]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 10,
        index: "-2",
        x: 31.986629942826006,
        y: 46.95765302692818,
        title: "10-2"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.986629942826006,
          46.95765302692818]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 6,
        index: "_",
        x: 31.985411605281627,
        y: 46.957199089859955,
        title: "6"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.985411605281627,
          46.957199089859955]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 10,
        index: "А",
        x: 31.985389255281,
        y: 46.95748287252836,
        title: "10А"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.985389255281,
          46.95748287252836]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 10,
        index: "-1",
        x: 31.985677490159986,
        y: 46.95759185432997,
        title: "10-1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98567749015999,
          46.95759185432997]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 10,
        index: "_",
        x: 31.985371549761815,
        y: 46.95767018568141,
        title: "10"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.985371549761815,
          46.95767018568141]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 11,
        index: "_",
        x: 31.985299912490973,
        y: 46.95853402004558,
        title: "11"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.985299912490973,
          46.95853402004558]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 11,
        index: "А",
        x: 31.985245425663678,
        y: 46.95921290458402,
        title: "11А"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.985245425663674,
          46.95921290458402]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 12,
        index: "_",
        x: 31.985187667161192,
        y: 46.95990315328292,
        title: "12"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.985187667161192,
          46.95990315328292]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 11,
        index: "а/1",
        x: 31.984176412218925,
        y: 46.960112265569094,
        title: "11а/1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.984176412218925,
          46.960112265569094]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 11,
        index: "а/2",
        x: 31.982776948344704,
        y: 46.960467703359065,
        title: "11а/2"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.982776948344704,
          46.960467703359065]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 11,
        index: "а/3",
        x: 31.98190614462947,
        y: 46.9606888701115,
        title: "11а/3"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98190614462947,
          46.9606888701115]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 13,
        index: "_",
        x: 31.985054597858326,
        y: 46.960558181810214,
        title: "13"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.985054597858326,
          46.960558181810214]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 13,
        index: "_",
        x: 31.985602869037475,
        y: 46.961531350412606,
        title: "13"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.985602869037475,
          46.961531350412606]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 9,
        index: "/3",
        x: 31.9781686152926,
        y: 46.95697896076139,
        title: "9/3"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9781686152926,
          46.95697896076139]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 9,
        index: "/3А",
        x: 31.97719081544428,
        y: 46.95746601929,
        title: "9/3А"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97719081544428,
          46.95746601929]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 9,
        index: "/4",
        x: 31.976210042537986,
        y: 46.958034865551184,
        title: "9/4"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.976210042537982,
          46.958034865551184]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 9,
        index: "/4а",
        x: 31.975568695780307,
        y: 46.95862524351536,
        title: "9/4а"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.975568695780307,
          46.95862524351536]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 9,
        index: "/5",
        x: 31.976086492447116,
        y: 46.959488874948995,
        title: "9/5"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.976086492447116,
          46.959488874948995]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 9,
        index: "/6",
        x: 31.976499959342235,
        y: 46.96016421597107,
        title: "9/6"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.976499959342235,
          46.96016421597107]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 9,
        index: "/7",
        x: 31.976860121687434,
        y: 46.9607673580253,
        title: "9/7"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.976860121687434,
          46.96076735802531]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 11,
        index: "а/7",
        x: 31.977487637421152,
        y: 46.961811068609755,
        title: "11а/7"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.977487637421152,
          46.961811068609755]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 11,
        index: "а/6",
        x: 31.97831933737802,
        y: 46.96159983788121,
        title: "11а/6"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97831933737802,
          46.96159983788121]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 11,
        index: "а/5",
        x: 31.979552568568806,
        y: 46.96128662685177,
        title: "11а/5"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97955256856881,
          46.96128662685177]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 11,
        index: "а/4",
        x: 31.98070234358376,
        y: 46.960994610029914,
        title: "11а/4"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98070234358376,
          46.960994610029914]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: null,
        x: 31.99177131188157,
        y: 46.96760283770939,
        title: "20"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99177131188157,
          46.96760283770939]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 212,
        index: "/1",
        x: 32.00298331880767,
        y: 46.95935434412437,
        title: "212/1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00298331880767,
          46.95935434412437]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 210,
        index: null,
        x: 31.99834943574627,
        y: 46.95939738957992,
        title: "210"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99834943574627,
          46.95939738957992]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 209,
        index: null,
        x: 31.996947770205374,
        y: 46.959483202849185,
        title: "209"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.996947770205374,
          46.959483202849185]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 213,
        index: null,
        x: 32.00497957551691,
        y: 46.95897333167487,
        title: "213"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00497957551691,
          46.95897333167487]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 213,
        index: "-1",
        x: 32.00488282971329,
        y: 46.95816300116156,
        title: "213-1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00488282971329,
          46.95816300116156]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 215,
        index: "_",
        x: 32.00854402679571,
        y: 46.958737532614435,
        title: "215"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00854402679571,
          46.95873753261443]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 215,
        index: "а",
        x: 32.010057264830074,
        y: 46.95863653844476,
        title: "215а"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.010057264830074,
          46.95863653844476]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 212,
        index: "/6",
        x: 32.00364932617034,
        y: 46.96473530054926,
        title: "212/6"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00364932617034,
          46.96473530054926]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 212,
        index: "/5",
        x: 32.00362102763855,
        y: 46.96447540912274,
        title: "212/5"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00362102763855,
          46.96447540912274]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 212,
        index: "/4",
        x: 32.003350333564924,
        y: 46.96235727373786,
        title: "212/4"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.003350333564924,
          46.96235727373786]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 212,
        index: "/3",
        x: 32.00321905380327,
        y: 46.96129286692642,
        title: "212/3"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00321905380327,
          46.96129286692643]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 217,
        index: "/6",
        x: 32.01333571861783,
        y: 46.96412487309871,
        title: "217/6"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01333571861783,
          46.96412487309871]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 217,
        index: "/7",
        x: 32.013407427499345,
        y: 46.964639986572806,
        title: "217/7"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.013407427499345,
          46.964639986572806]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 217,
        index: "/4",
        x: 32.01310862077377,
        y: 46.96222976306663,
        title: "217/4"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01310862077377,
          46.96222976306663]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 217,
        index: "/5",
        x: 32.01316917536676,
        y: 46.96273145845387,
        title: "217/5"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01316917536676,
          46.96273145845387]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 235,
        index: "_",
        x: 32.03829419832896,
        y: 46.941322261043055,
        title: "235"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03829419832896,
          46.941322261043055]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 233,
        index: "_",
        x: 32.03629397773776,
        y: 46.94504534024897,
        title: "233"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03629397773776,
          46.94504534024897]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 232,
        index: "_",
        x: 32.03543072464611,
        y: 46.946635785719586,
        title: "232"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03543072464611,
          46.946635785719586]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 231,
        index: "_",
        x: 32.03505494083855,
        y: 46.947328398298275,
        title: "231"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03505494083855,
          46.947328398298275]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 229,
        index: "_",
        x: 32.03364699337816,
        y: 46.94994322092624,
        title: "229"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03364699337816,
          46.94994322092624]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 229,
        index: "/1",
        x: 32.03410429498135,
        y: 46.95005698897619,
        title: "229/1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03410429498135,
          46.95005698897619]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 229,
        index: "/2",
        x: 32.03446392100746,
        y: 46.95014674315152,
        title: "229/2"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03446392100746,
          46.95014674315152]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 229,
        index: "/3",
        x: 32.03702289953609,
        y: 46.95073222340306,
        title: "229/3"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03702289953609,
          46.95073222340306]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 229,
        index: "/4",
        x: 32.03800949657805,
        y: 46.95094839394887,
        title: "229/4"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03800949657805,
          46.95094839394887]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 229,
        index: "/5",
        x: 32.04024717950668,
        y: 46.95152222398671,
        title: "229/5"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04024717950668,
          46.9515222239867]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 229,
        index: "/6",
        x: 32.04197087015311,
        y: 46.9519478682346,
        title: "229/6"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04197087015311,
          46.9519478682346]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 223,
        index: "_",
        x: 32.02805022124899,
        y: 46.95747094348691,
        title: "223"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02805022124899,
          46.95747094348691]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 222,
        index: "_",
        x: 32.025978023323596,
        y: 46.95761458924356,
        title: "222"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.025978023323596,
          46.95761458924356]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 221,
        index: null,
        x: 32.02195960341212,
        y: 46.95786704656073,
        title: "221"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02195960341212,
          46.95786704656073]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 221,
        index: "/1",
        x: 32.02215914597592,
        y: 46.95901182180901,
        title: "221/1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02215914597592,
          46.95901182180901]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 221,
        index: "/2",
        x: 32.02236140001124,
        y: 46.96042263997343,
        title: "221/2"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02236140001124,
          46.96042263997343]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 221,
        index: "/3",
        x: 32.02245513190324,
        y: 46.96104266155368,
        title: "221/3"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02245513190324,
          46.961042661553684]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 221,
        index: "/4",
        x: 32.02257429490088,
        y: 46.96164355790489,
        title: "221/4"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02257429490088,
          46.9616435579049]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 221,
        index: "/5",
        x: 32.02274176692876,
        y: 46.96242481003265,
        title: "221/5"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02274176692876,
          46.962424810032644]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 221,
        index: "/6",
        x: 32.02292128378474,
        y: 46.96322489651287,
        title: "221/6"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02292128378474,
          46.96322489651287]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 221,
        index: "/7",
        x: 32.023233224015,
        y: 46.96466365915531,
        title: "221/7"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.023233224015,
          46.96466365915531]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 221,
        index: "/8",
        x: 32.02311783121852,
        y: 46.96522318637309,
        title: "221/8"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02311783121852,
          46.96522318637309]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 221,
        index: "/9",
        x: 32.022588320782724,
        y: 46.96652135770517,
        title: "221/9"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.022588320782724,
          46.96652135770517]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 217,
        index: "/10-11",
        x: 32.02026281807455,
        y: 46.96663583053205,
        title: "217/10-11"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02026281807455,
          46.96663583053205]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 217,
        index: "/10-Б",
        x: 32.01682146636512,
        y: 46.96764712977197,
        title: "217/10-Б"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01682146636512,
          46.96764712977197]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 271,
        index: "/10-2",
        x: 32.01378912897192,
        y: 46.96705279472001,
        title: "271/10-2"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01378912897192,
          46.96705279472001]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 217,
        index: "/10",
        x: 32.01275839350696,
        y: 46.96684274449201,
        title: "217/10"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01275839350696,
          46.96684274449201]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 217,
        index: "/9",
        x: 32.01298769698278,
        y: 46.966320799330745,
        title: "217/9"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01298769698278,
          46.966320799330745]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 217,
        index: "/8",
        x: 32.013261612454244,
        y: 46.965650759079274,
        title: "217/8"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.013261612454244,
          46.965650759079274]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 212,
        index: "/7",
        x: 32.003778458664385,
        y: 46.96591489901771,
        title: "212/7"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.003778458664385,
          46.96591489901771]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 212,
        index: "/8",
        x: 32.003873744442195,
        y: 46.96669029668081,
        title: "212/8"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.003873744442195,
          46.96669029668081]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 212,
        index: "/9",
        x: 32.00402771712764,
        y: 46.96800983940358,
        title: "212/9"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00402771712764,
          46.96800983940358]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 212,
        index: "/10",
        x: 32.004116623323995,
        y: 46.96880409695006,
        title: "212/10"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.004116623323995,
          46.96880409695006]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 212,
        index: "/11",
        x: 32.00418374575486,
        y: 46.96940757448965,
        title: "212/11"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00418374575486,
          46.96940757448965]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 212,
        index: "/12",
        x: 32.00427339780165,
        y: 46.97021359839324,
        title: "212/12"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00427339780165,
          46.970213598393244]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 26,
        index: null,
        x: 31.995532585189668,
        y: 46.9733277225474,
        title: "26"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.995532585189668,
          46.9733277225474]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 26,
        index: "/10",
        x: 31.987002201409982,
        y: 46.97303893769063,
        title: "26/10"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.987002201409982,
          46.97303893769063]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 26,
        index: "/8",
        x: 31.987686152420892,
        y: 46.974009845107275,
        title: "26/8"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.987686152420892,
          46.97400984510727]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 26,
        index: "/9",
        x: 31.987555767094012,
        y: 46.973824220600186,
        title: "26/9"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.987555767094012,
          46.973824220600186]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 26,
        index: "/7",
        x: 31.988126846199506,
        y: 46.97465313781388,
        title: "26/7"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.988126846199506,
          46.97465313781388]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 26,
        index: "/6",
        x: 31.988442581866654,
        y: 46.97510788596727,
        title: "26/6"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98844258186665,
          46.97510788596727]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 26,
        index: "/5",
        x: 31.988711127375527,
        y: 46.975499652931866,
        title: "26/5"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.988711127375527,
          46.975499652931866]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 26,
        index: "/4",
        x: 31.99063588514688,
        y: 46.97487028058923,
        title: "26/4"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99063588514688,
          46.97487028058923]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 26,
        index: "/3",
        x: 31.99202564135728,
        y: 46.974425149355774,
        title: "26/3"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99202564135728,
          46.974425149355774]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 217,
        index: "а",
        x: 32.01436957742602,
        y: 46.95837407001101,
        title: "217а"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01436957742602,
          46.958374070011004]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 218,
        index: "_",
        x: 32.015944745896654,
        y: 46.958271264873225,
        title: "218"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.015944745896654,
          46.958271264873225]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 219,
        index: null,
        x: 32.01857917821911,
        y: 46.95809044213869,
        title: "219"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.01857917821911,
          46.95809044213869]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 220,
        index: null,
        x: 32.020279746005244,
        y: 46.95798076283131,
        title: "220"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.020279746005244,
          46.95798076283131]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 212,
        index: "_",
        x: 32.00241001348286,
        y: 46.95913724954927,
        title: "212"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00241001348286,
          46.95913724954927]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 211,
        index: "_",
        x: 32.00003295717329,
        y: 46.95929495187726,
        title: "211"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00003295717329,
          46.95929495187726]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 208,
        index: "_",
        x: 31.994237465932432,
        y: 46.9596521568377,
        title: "208"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.994237465932432,
          46.9596521568377]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 207,
        index: "_",
        x: 31.9931226857538,
        y: 46.95972272858947,
        title: "207"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9931226857538,
          46.95972272858947]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 206,
        index: "_",
        x: 31.990485844293573,
        y: 46.95989244872659,
        title: "206"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.990485844293573,
          46.95989244872659]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 205,
        index: "_",
        x: 31.988630772304404,
        y: 46.960012706765895,
        title: "205"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.988630772304404,
          46.960012706765895]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 204,
        index: "_",
        x: 31.987505919520885,
        y: 46.96008819399405,
        title: "204"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.987505919520885,
          46.96008819399405]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/18",
        x: 31.96738809572532,
        y: 46.96682998178731,
        title: "15/18"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96738809572532,
          46.96682998178731]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/12",
        x: 31.97993948133431,
        y: 46.971397652373625,
        title: "20/12"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97993948133431,
          46.971397652373625]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/11",
        x: 31.980477342323084,
        y: 46.971222649648624,
        title: "20/11"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.980477342323084,
          46.971222649648624]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/8-6",
        x: 31.976961397007592,
        y: 46.96917609177384,
        title: "15/8-6"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.976961397007592,
          46.96917609177384]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/8-5",
        x: 31.976540318348615,
        y: 46.968887334562744,
        title: "15/8-5"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.976540318348615,
          46.968887334562744]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/8-4",
        x: 31.975881588580062,
        y: 46.96837510111178,
        title: "15/8-4"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.975881588580066,
          46.96837510111178]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/8-3",
        x: 31.97580636720967,
        y: 46.968042332241815,
        title: "15/8-3"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97580636720967,
          46.968042332241815]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/8-2",
        x: 31.975692096548794,
        y: 46.967669674048416,
        title: "15/8-2"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.975692096548794,
          46.967669674048416]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/8-1",
        x: 31.97538222784471,
        y: 46.9668359613523,
        title: "15/8-1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97538222784471,
          46.9668359613523]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/8",
        x: 31.975124469481393,
        y: 46.96617134463103,
        title: "15/8"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.975124469481393,
          46.96617134463103]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "_",
        x: 31.986750560822195,
        y: 46.96345711802885,
        title: "15"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.986750560822195,
          46.96345711802885]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/15-7",
        x: 31.96725132149725,
        y: 46.971160179310466,
        title: "15/15-7"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96725132149725,
          46.971160179310466]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/15-6",
        x: 31.967473164005675,
        y: 46.97074180831948,
        title: "15/15-6"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.967473164005675,
          46.97074180831948]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "15-5",
        x: 31.967749025807237,
        y: 46.97019518466363,
        title: "1515-5"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.967749025807237,
          46.97019518466363]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/15-4",
        x: 31.967927563534964,
        y: 46.96984140691563,
        title: "15/15-4"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.967927563534964,
          46.96984140691562]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/15-3",
        x: 31.968013143446587,
        y: 46.96967182697909,
        title: "15/15-3"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.968013143446587,
          46.96967182697909]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/15-2",
        x: 31.968205647821133,
        y: 46.96929028364198,
        title: "15/15-2"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.968205647821133,
          46.96929028364198]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/15-1",
        x: 31.968473107591716,
        y: 46.9687537197843,
        title: "15/15-1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.968473107591716,
          46.9687537197843]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/15",
        x: 31.968603815950985,
        y: 46.96836479655994,
        title: "15/15"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.968603815950985,
          46.96836479655993]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/16",
        x: 31.96714536126612,
        y: 46.968463671244685,
        title: "15/16"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96714536126612,
          46.96846367124469]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/14",
        x: 31.969193253559865,
        y: 46.96832692045325,
        title: "15/14"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.969193253559865,
          46.96832692045325]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/13",
        x: 31.969913306488504,
        y: 46.968280651225456,
        title: "15/13"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.969913306488504,
          46.968280651225456]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/12б",
        x: 31.970819706606147,
        y: 46.968216939795326,
        title: "15/12б"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.970819706606147,
          46.968216939795326]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/12а",
        x: 31.971236240181728,
        y: 46.96761216609452,
        title: "15/12а"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.971236240181728,
          46.96761216609452]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/11",
        x: 31.97175915234491,
        y: 46.966390669918454,
        title: "15/11"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97175915234491,
          46.966390669918454]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/12",
        x: 31.971509139139282,
        y: 46.966860039486306,
        title: "15/12"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.971509139139282,
          46.966860039486306]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/10",
        x: 31.97261126764923,
        y: 46.96634703626652,
        title: "15/10"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97261126764923,
          46.96634703626652]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/9",
        x: 31.973754960103054,
        y: 46.9662665898304,
        title: "15/9"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.973754960103054,
          46.9662665898304]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/8-7",
        x: 31.97779269938307,
        y: 46.969787938779746,
        title: "15/8-7"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97779269938307,
          46.969787938779746]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        index: "15/8-8",
        x: 31.978622280896506,
        y: 46.97039851223629,
        title: "115/8-8"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.978622280896506,
          46.97039851223629]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/8-9",
        x: 31.979305308821566,
        y: 46.970899395636664,
        title: "15/8-9"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.979305308821562,
          46.970899395636664]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/8-10",
        x: 31.979796975918855,
        y: 46.9712387944512,
        title: "15/8-10"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.979796975918855,
          46.9712387944512]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/13",
        x: 31.978584336804232,
        y: 46.97182483941302,
        title: "20/13"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.978584336804232,
          46.97182483941302]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/14",
        x: 31.977320878996167,
        y: 46.97226439523589,
        title: "20/14"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.977320878996167,
          46.97226439523589]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/14-1",
        x: 31.97755074074202,
        y: 46.97259605961198,
        title: "20/14-1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97755074074202,
          46.97259605961198]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/14-2",
        x: 31.977974663564783,
        y: 46.9732199598052,
        title: "20/14-2"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.977974663564783,
          46.9732199598052]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/14-3",
        x: 31.9782400959986,
        y: 46.973621290185775,
        title: "20/14-3"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9782400959986,
          46.973621290185775]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/14-4",
        x: 31.97862231213948,
        y: 46.97417050493746,
        title: "20/14-4"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.978622312139482,
          46.97417050493746]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/10",
        x: 31.98159800312773,
        y: 46.97086118635676,
        title: "20/10"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98159800312773,
          46.970861186356764]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/9",
        x: 31.98297220365603,
        y: 46.970422487103995,
        title: "20/9"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98297220365603,
          46.97042248710399]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/8",
        x: 31.983276195844464,
        y: 46.97031002118765,
        title: "20/8"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.983276195844464,
          46.97031002118765]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/7",
        x: 31.984802439654143,
        y: 46.969830624542794,
        title: "20/7"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.984802439654143,
          46.969830624542794]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/7-1",
        x: 31.987236356547864,
        y: 46.97041296056115,
        title: "20/7-1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.987236356547864,
          46.970412960561156]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/7-2",
        x: 31.986474421625417,
        y: 46.97066313716087,
        title: "20/7-2"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.986474421625417,
          46.97066313716087]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/7-3",
        x: 31.985573969187453,
        y: 46.97095879361786,
        title: "20/7-3"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.985573969187453,
          46.97095879361786]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/7-5",
        x: 31.98297563869776,
        y: 46.97177381968383,
        title: "20/7-5"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98297563869776,
          46.97177381968383]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/7-4а",
        x: 31.984564087807914,
        y: 46.97127927774739,
        title: "20/7-4а"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.984564087807914,
          46.97127927774739]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/7-4",
        x: 31.98494935456269,
        y: 46.9711570142279,
        title: "20/7-4"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98494935456269,
          46.9711570142279]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/6",
        x: 31.98651944468299,
        y: 46.969267723982036,
        title: "20/6"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98651944468299,
          46.969267723982036]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/5",
        x: 31.98695206465468,
        y: 46.96913232409281,
        title: "20/5"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98695206465468,
          46.96913232409281]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/4",
        x: 31.98828291056257,
        y: 46.96871297965346,
        title: "20/4"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98828291056257,
          46.96871297965347]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/3",
        x: 31.989116342075167,
        y: 46.96842861305621,
        title: "20/3"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.989116342075167,
          46.968428613056204]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/2",
        x: 31.989730826961985,
        y: 46.96821976750043,
        title: "20/2"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.989730826961985,
          46.96821976750043]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/1",
        x: 31.990359582871434,
        y: 46.96803902179535,
        title: "20/1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.990359582871434,
          46.968039021795356]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/4-1",
        x: 31.988485625572157,
        y: 46.96904563734575,
        title: "20/4-1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.988485625572157,
          46.96904563734575]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/4-2",
        x: 31.98874168527166,
        y: 46.96946591899901,
        title: "20/4-2"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98874168527166,
          46.96946591899901]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/4-3",
        x: 31.988992298609205,
        y: 46.96985701473105,
        title: "20/4-3"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.988992298609205,
          46.96985701473105]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/4-4",
        x: 31.98975357134596,
        y: 46.97094120932579,
        title: "20/4-4"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98975357134596,
          46.97094120932579]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/4-5",
        x: 31.989827925830095,
        y: 46.97105399911844,
        title: "20/4-5"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.989827925830095,
          46.97105399911844]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 24,
        index: "_",
        x: 31.993664984458647,
        y: 46.97046562211784,
        title: "24"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.993664984458647,
          46.97046562211784]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 23,
        index: "_",
        x: 31.99336084510021,
        y: 46.97000814574476,
        title: "23"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99336084510021,
          46.97000814574476]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 22,
        index: "_",
        x: 31.99320406839139,
        y: 46.96977232587143,
        title: "22"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99320406839139,
          46.96977232587143]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 19,
        index: "_",
        x: 31.99134078633916,
        y: 46.96670966843745,
        title: "19"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99134078633916,
          46.96670966843745]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 18,
        index: "а",
        x: 31.991142471456794,
        y: 46.96603975761257,
        title: "18а"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.991142471456794,
          46.96603975761257]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 18,
        index: "_",
        x: 31.990869085280266,
        y: 46.965142969481946,
        title: "18"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99086908528027,
          46.965142969481946]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 17,
        index: "_",
        x: 31.99044159954566,
        y: 46.96391274954009,
        title: "17"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.990441599545658,
          46.96391274954009]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 16,
        index: "/1",
        x: 31.989805765505753,
        y: 46.96272765505068,
        title: "16/1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.989805765505753,
          46.96272765505068]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 16,
        index: "_",
        x: 31.98748832541792,
        y: 46.96328434873416,
        title: "16"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98748832541792,
          46.96328434873416]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 16,
        index: "а",
        x: 31.98838564063557,
        y: 46.96306721353907,
        title: "16а"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.988385640635574,
          46.96306721353907]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/1",
        x: 31.986043227057174,
        y: 46.96363448073093,
        title: "15/1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.986043227057174,
          46.96363448073093]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/2",
        x: 31.984136085961342,
        y: 46.96410613593966,
        title: "15/2"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.984136085961342,
          46.96410613593966]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/3",
        x: 31.983366533911894,
        y: 46.96428904930298,
        title: "15/3"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.983366533911898,
          46.96428904930298]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/4",
        x: 31.983031552318284,
        y: 46.96437246906583,
        title: "15/4"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.983031552318284,
          46.96437246906583]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        index: "/5",
        x: 31.98147314685823,
        y: 46.964755322177815,
        title: "15/5"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98147314685823,
          46.964755322177815]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/14-9",
        x: 31.981128048436045,
        y: 46.97781701878746,
        title: "20/14-9"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.981128048436045,
          46.97781701878746]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/14-8",
        x: 31.980350835032304,
        y: 46.97673573166942,
        title: "20/14-8"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.980350835032304,
          46.97673573166942]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/14-7",
        x: 31.97991591474413,
        y: 46.9760351301768,
        title: "20/14-7"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97991591474413,
          46.9760351301768]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/14-6",
        x: 31.979492035726103,
        y: 46.975386851098975,
        title: "20/14-6"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.979492035726103,
          46.975386851098975]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/14-5",
        x: 31.979039516742187,
        y: 46.97478292217407,
        title: "20/14-5"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.979039516742187,
          46.97478292217407]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/14-11",
        x: 31.982074743793603,
        y: 46.979284763481814,
        title: "20/14-11"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.982074743793603,
          46.979284763481814]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        index: "/14-10",
        x: 31.981465977300633,
        y: 46.9783182528536,
        title: "20/14-10"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.981465977300633,
          46.9783182528536]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 30,
        index: "_",
        x: 31.99946662423673,
        y: 46.97350012291806,
        title: "30"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99946662423673,
          46.97350012291806]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 29,
        index: "_",
        x: 31.998772407675947,
        y: 46.97371800192396,
        title: "29"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.998772407675947,
          46.97371800192396]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 28,
        index: "_",
        x: 31.997480585371974,
        y: 46.9741234363878,
        title: "28"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.997480585371978,
          46.9741234363878]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 27,
        index: "_",
        x: 31.996283787974306,
        y: 46.97449904483364,
        title: "27"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99628378797431,
          46.97449904483364]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 26,
        index: "/1",
        x: 31.995195962270675,
        y: 46.973431941277376,
        title: "26/1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.995195962270675,
          46.973431941277376]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 26,
        index: "/2",
        x: 31.99459842225132,
        y: 46.97361693965395,
        title: "26/2"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99459842225132,
          46.97361693965395]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 236,
        index: "_",
        x: 32.03905817485954,
        y: 46.93988783445425,
        title: "236"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03905817485954,
          46.93988783445425]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 237,
        index: "_",
        x: 32.03978121536815,
        y: 46.93856645805598,
        title: "237"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03978121536815,
          46.93856645805598]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 237,
        index: "а",
        x: 32.04015071796309,
        y: 46.9378556139586,
        title: "237а"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04015071796309,
          46.9378556139586]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 234,
        index: "_",
        x: 32.03727465993862,
        y: 46.94322753964896,
        title: "234"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03727465993862,
          46.94322753964896]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 227,
        index: "/1",
        x: 32.03563359780469,
        y: 46.95296358838405,
        title: "227/1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03563359780469,
          46.952963588384044]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 228,
        index: "б",
        x: 32.033248305606726,
        y: 46.95073664590777,
        title: "228б"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.033248305606726,
          46.95073664590777]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 228,
        index: "а",
        x: 32.03299943931506,
        y: 46.95146166439357,
        title: "228а"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03299943931506,
          46.95146166439357]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 228,
        index: "_",
        x: 32.03285091056528,
        y: 46.951933931057766,
        title: "228"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03285091056528,
          46.951933931057766]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 227,
        index: "а",
        x: 32.03270327199818,
        y: 46.952460812245896,
        title: "227а"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03270327199818,
          46.952460812245896]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 227,
        index: "_",
        x: 32.03275056778651,
        y: 46.95344351756604,
        title: "227"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03275056778651,
          46.95344351756604]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 316,
        index: "_",
        x: 32.04825323765436,
        y: 46.958106564702796,
        title: "316"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04825323765436,
          46.95810656470279]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 315,
        index: "_",
        x: 32.04923043298739,
        y: 46.95770877258751,
        title: "315"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04923043298739,
          46.95770877258751]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 314,
        index: "_",
        x: 32.04995224969296,
        y: 46.95741493691296,
        title: "314"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04995224969296,
          46.95741493691296]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 313,
        index: "_",
        x: 32.05127223670508,
        y: 46.956879826770496,
        title: "313"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05127223670508,
          46.956879826770496]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 312,
        index: "_",
        x: 32.05307265439787,
        y: 46.95614640404644,
        title: "312"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05307265439787,
          46.95614640404644]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 317,
        index: "_",
        x: 32.04764678603484,
        y: 46.9583534347104,
        title: "317"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04764678603484,
          46.9583534347104]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 318,
        index: "_",
        x: 32.04702785094001,
        y: 46.958605385227855,
        title: "318"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04702785094001,
          46.958605385227855]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 319,
        index: "_",
        x: 32.04589415706311,
        y: 46.95906687603203,
        title: "319"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04589415706311,
          46.95906687603203]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 320,
        index: "_",
        x: 32.044544947105756,
        y: 46.95961609140675,
        title: "320"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.044544947105756,
          46.95961609140675]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 227,
        index: "/7",
        x: 32.04402594476251,
        y: 46.95950096233256,
        title: "227/7"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04402594476251,
          46.95950096233256]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 227,
        index: "/6",
        x: 32.043028245283345,
        y: 46.95835422853778,
        title: "227/6"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.043028245283345,
          46.95835422853778]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 229,
        index: "/8",
        x: 32.043322353496585,
        y: 46.95339493960498,
        title: "229/8"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.043322353496585,
          46.95339493960498]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 229,
        index: "/8а",
        x: 32.043743377562116,
        y: 46.953232396178926,
        title: "229/8а"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.043743377562116,
          46.953232396178926]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 229,
        index: "/8б",
        x: 32.044561812772486,
        y: 46.95291642407964,
        title: "229/8б"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.044561812772486,
          46.95291642407964]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 229,
        index: "/8в",
        x: 32.04580015275932,
        y: 46.952405273880785,
        title: "229/8в"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04580015275932,
          46.952405273880785]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 317,
        index: "/5",
        x: 32.04433545202771,
        y: 46.95455509417621,
        title: "317/5"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04433545202771,
          46.95455509417621]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 229,
        index: "/9",
        x: 32.04384666103637,
        y: 46.95401405670248,
        title: "229/9"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04384666103637,
          46.954014056702476]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 317,
        index: "/4",
        x: 32.04477279008115,
        y: 46.95507316253688,
        title: "317/4"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04477279008115,
          46.95507316253688]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 317,
        index: "/3",
        x: 32.04513638815112,
        y: 46.955503971441445,
        title: "317/3"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04513638815112,
          46.955503971441445]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 227,
        index: "/4",
        x: 32.040404728899524,
        y: 46.95529269473315,
        title: "227/4"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.040404728899524,
          46.95529269473315]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 227,
        index: "/5",
        x: 32.041418637367094,
        y: 46.95647776841317,
        title: "227/5"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.041418637367094,
          46.95647776841317]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 227,
        index: "/3",
        x: 32.039888632004676,
        y: 46.95469154118669,
        title: "227/3"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.039888632004676,
          46.95469154118669]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 317,
        index: "/1",
        x: 32.047033434980435,
        y: 46.957692762405976,
        title: "317/1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.047033434980435,
          46.957692762405976]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 317,
        index: "/2",
        x: 32.04628935542251,
        y: 46.95684605828728,
        title: "317/2"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04628935542251,
          46.95684605828728]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 225,
        index: "_",
        x: 32.03217686617401,
        y: 46.95721630107597,
        title: "225"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03217686617401,
          46.95721630107597]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 225,
        index: "а",
        x: 32.03229557888457,
        y: 46.956516232592904,
        title: "225а"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03229557888457,
          46.956516232592904]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 225,
        index: "б",
        x: 32.03263882937633,
        y: 46.95589394850384,
        title: "225б"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03263882937633,
          46.95589394850384]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 226,
        index: "_",
        x: 32.0329852325923,
        y: 46.955314767470114,
        title: "226"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0329852325923,
          46.955314767470114]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 226,
        index: "а",
        x: 32.03289693927477,
        y: 46.954636204568175,
        title: "226а"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03289693927477,
          46.954636204568175]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 227,
        index: "/1",
        x: 32.0354547264324,
        y: 46.95332476723349,
        title: "227/1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.0354547264324,
          46.95332476723349]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 227,
        index: "/1",
        x: 32.03555340414387,
        y: 46.95312758434454,
        title: "227/1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03555340414387,
          46.95312758434454]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 227,
        index: "/2",
        x: 32.03675933714347,
        y: 46.95362780877802,
        title: "227/2"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03675933714347,
          46.95362780877802]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 327,
        index: "_",
        x: 32.03284948890161,
        y: 46.95656454985078,
        title: "327"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03284948890161,
          46.95656454985078]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 326,
        index: "_",
        x: 32.03406706002376,
        y: 46.9566911065029,
        title: "326"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03406706002376,
          46.9566911065029]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 325,
        index: "_",
        x: 32.034686200018136,
        y: 46.957240476677974,
        title: "325"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.034686200018136,
          46.957240476677974]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 324,
        index: "_",
        x: 32.035379949429185,
        y: 46.95785604196241,
        title: "324"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.035379949429185,
          46.95785604196241]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 323,
        index: "_",
        x: 32.03581494807977,
        y: 46.95824201355073,
        title: "323"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03581494807977,
          46.95824201355073]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 322,
        index: "_",
        x: 32.03616845180817,
        y: 46.9585556731525,
        title: "322"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03616845180817,
          46.9585556731525]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 321,
        index: "_",
        x: 32.03726859221294,
        y: 46.9582435104137,
        title: "321"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.03726859221294,
          46.9582435104137]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 230,
        index: "_",
        x: 32.034153693641755,
        y: 46.94900219817062,
        title: "230"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.034153693641755,
          46.94900219817062]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 212,
        index: "/2",
        x: 32.003123517269806,
        y: 46.96051306002548,
        title: "212/2"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.003123517269806,
          46.96051306002548]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 25,
        index: "_",
        x: 31.99424325966606,
        y: 46.97133543378365,
        title: "25"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.99424325966606,
          46.97133543378365
        ]
      }
    }
  ]
}