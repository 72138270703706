import L from "leaflet";

import boltSVG from "../images/svg/icon-bolt-10x5.svg";

const LeafIcon = L.Icon.extend({
    options: {
        iconSize: [15, 8],
        iconAnchor: [7, 4],
        tooltipAnchor: [7, 4]
    },
});

// @ts-ignore
export const boltIcon = new LeafIcon({iconUrl: boltSVG});
