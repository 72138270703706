export const nefcoList = {
  type: "FeatureCollection",
  name: "nefco_demoukraine",
  crs: {
    type: "name",
    properties: {
      name: "urn:ogc:def:crs:OGC:1.3:CRS84"
    }
  },
  features: [
    {
      type: "Feature",
      properties: {
        id: 3,
        comments: "Група об'єквів 1SE (12 житлових будинків та ДНЗ № 95). Переключення з теплоносія ТЕЦ на теплоносій котельні № 514 та встановлення 13 ІТП",
        project: "NEFCO Demoukraine",
        sub_projec: "Підпроєкт 1SE"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.0667389171122,
                46.950721876831636
              ],
              [
                32.06483969333529,
                46.951485890273204
              ],
              [
                32.06715028203716,
                46.954153469821385
              ],
              [
                32.06903347860921,
                46.95338402443094
              ],
              [
                32.0667389171122,
                46.950721876831636
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        comments: "Група об'єктів (8 житлових будинків, ДНЗ №41 та реабілітаційний центр) Закриття котельні № 313 пр. Центральний 22-А та встановлення 10 ІТП",
        project: "NEFCO Demoukraine",
        sub_projec: "Підпроєкт 4"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                31.971007698763444,
                46.97107958724871
              ],
              [
                31.970878937011975,
                46.97085395503751
              ],
              [
                31.970974735755068,
                46.97075343944692
              ],
              [
                31.97111894891671,
                46.97058755316399
              ],
              [
                31.971090106284386,
                46.97052850876963
              ],
              [
                31.970974735755068,
                46.97032325870119
              ],
              [
                31.970974735755068,
                46.97018829932285
              ],
              [
                31.970974735755068,
                46.97006739792389
              ],
              [
                31.971287884334636,
                46.96952474409271
              ],
              [
                31.972744437267256,
                46.96943476986839
              ],
              [
                31.97265378899421,
                46.96873184103069
              ],
              [
                31.972585802789446,
                46.96817230307253
              ],
              [
                31.971015939515535,
                46.96826649705813
              ],
              [
                31.9702530215239,
                46.96970722198136
              ],
              [
                31.969349639910146,
                46.96949598561784
              ],
              [
                31.969115382051335,
                46.969919013974994
              ],
              [
                31.970020448035314,
                46.97014642308182
              ],
              [
                31.96953672451467,
                46.97105990597223
              ],
              [
                31.969975544563667,
                46.971151283266074
              ],
              [
                31.970064132648684,
                46.971328414191014
              ],
              [
                31.971007698763444,
                46.97107958724871
              ]
            ]
          ]
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 1,
        comments: "Будівниутво нової котельні 127 мкр. Загальна потужність Q=4,5 Мвт (3,87 Гкал) Встановлення котлів за схемою 1,5 МВт пелетний + 2х1,5 Мвт газові",
        project: "NEFCO Demoukraine",
        sub_projec: "Підпроєкт 1NW"
      },
      geometry: {
        type: "MultiPolygon",
        coordinates: [
          [
            [
              [
                32.04933593881109,
                46.957779931516754
              ],
              [
                32.04976867334255,
                46.9581664471724
              ],
              [
                32.05023346228374,
                46.95843263085892
              ],
              [
                32.05239713494098,
                46.958177386254086
              ],
              [
                32.056088734462364,
                46.95693760942538
              ],
              [
                32.05488402289642,
                46.955524137638
              ],
              [
                32.04933593881109,
                46.957779931516754
              ]
            ]
          ]
        ]
      }
    }
  ]
}