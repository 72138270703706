import L from "leaflet";

import buildingSVG from "../images/svg/marker-building-20x25.svg";

import buildingSVG_1 from "../images/svg/marker-building-1.svg"
import buildingSVG_2 from "../images/svg/marker-building-2.svg"
import buildingSVG_3 from "../images/svg/marker-building-3.svg"
import buildingSVG_4 from "../images/svg/marker-building-4.svg"
import buildingSVG_5 from "../images/svg/marker-building-5.svg"
import buildingSVG_6 from "../images/svg/marker-building-6.svg"
import buildingSVG_7 from "../images/svg/marker-building-7.svg"
import buildingSVG_8 from "../images/svg/marker-building-8.svg"
import buildingSVG_default from "../images/svg/marker-building-default.svg"

const LeafIcon = L.Icon.extend({
    options: {
        iconSize: [20, 25],
        iconAnchor: [10, 25],
        tooltipAnchor: [10, -15],
        popupAnchor: [0, -20]
    },
});

// @ts-ignore
export const heatingUsersIcon = new LeafIcon({iconUrl: buildingSVG});
// @ts-ignore
export const buildingIcon1 = new LeafIcon({iconUrl: buildingSVG_1});
// @ts-ignore
export const buildingIcon2 = new LeafIcon({iconUrl: buildingSVG_2});
// @ts-ignore
export const buildingIcon3 = new LeafIcon({iconUrl: buildingSVG_3});
// @ts-ignore
export const buildingIcon4 = new LeafIcon({iconUrl: buildingSVG_4});
// @ts-ignore
export const buildingIcon5 = new LeafIcon({iconUrl: buildingSVG_5});
// @ts-ignore
export const buildingIcon6 = new LeafIcon({iconUrl: buildingSVG_6});
// @ts-ignore
export const buildingIcon7 = new LeafIcon({iconUrl: buildingSVG_7});
// @ts-ignore
export const buildingIcon8 = new LeafIcon({iconUrl: buildingSVG_8});
// @ts-ignore
export const buildingIconDef = new LeafIcon({iconUrl: buildingSVG_default});
