import React from "react";
import parse from 'html-react-parser';

import {Marker, Popup, Tooltip, useMap} from "react-leaflet";
import {Card, Image} from "antd";

import {repairIcon} from "../icons/icon_repair";


// @ts-ignore
const PopupStatistics = ({feature}) => {
    const {
        pkuid,
        category,
        organ,
        adrs,
        latitude,
        longitude,
        notes,
        ownership,
        balancer,
        obj_desc,
        damag_desc,
        rest_cost,
        photo,
    } = feature.properties;

    // let image = false;

    if (photo) {
        return (
            <Card type="inner"
                  className={"card-building"}
                  title={adrs}
                  extra={<a
                      href="https://docs.google.com/spreadsheets/d/1ePABTG8nsKgri7NDPXXFDGJ3G1AyWGQWHbBo8kRg564/edit?usp=sharing"
                      rel="noreferrer"
                      target={"_blank"}>Усі пошкодження</a>}
            >



                <div className={"count-title"}>Опис пошкоджень</div>
                <div className={"items-wrap"}>
                    {damag_desc}
                </div>
                <div className={"line-bottom"}>
                    <b>Орієнтовна вартість відновлювальних робіт:</b>
                    <span>{rest_cost} тис.грн.</span>
                </div>
                <Image.PreviewGroup>
                    {
                        // @ts-ignore
                        photo.map((img, index) => {
                            return (
                                <Image width={100} src={`../pipe-damage-img/${pkuid}/${img}`} key={index}/>
                            )
                        })
                    }

                </Image.PreviewGroup>
            </Card>
        )
    } else {
        return (
            <Card type="inner"
                  className={"card-building"}
                  title={adrs}
                  extra={<a
                      href="https://docs.google.com/spreadsheets/d/1ePABTG8nsKgri7NDPXXFDGJ3G1AyWGQWHbBo8kRg564/edit?usp=sharing"
                      rel="noreferrer"
                      target={"_blank"}>Усі будівлі</a>}
            >
                <div className={"line"}>
                    <b>Найменування об’єкту:</b>
                    <span>{organ}</span>
                </div>
                <div className={"line"}>
                    <b>Форма власності:</b>
                    <span>{ownership}</span>
                </div>
                <div className={"line"}>
                    <b>Балансоутримувач:</b>
                    <span>{balancer}</span>
                </div>
                <div className={"count-title"}>Характеристика об’єкту</div>
                <div className={"items-wrap"}>
                    {parse(obj_desc)}
                </div>
                <div className={"count-title"}>Опис пошкоджень</div>
                <div className={"items-wrap"}>
                    {parse(damag_desc)}
                </div>
                <div className={"line-bottom"}>
                    <b>Орієнтовна вартість відновлювальних робіт:</b>
                    <span>{rest_cost} грн.</span>
                </div>
            </Card>
        )
    }

}


// @ts-ignore
export const LayerPipeDamage = ({data}) => {
    const leafletMap = useMap();

    let targetIcon = repairIcon;


    return (
        // @ts-ignore
        data.features.map((feature, index) => {
            const {coordinates} = feature.geometry;
            const {adrs, category} = feature.properties;


            return (
                <Marker
                    key={index}
                    position={[coordinates[1], coordinates[0]]}
                    icon={targetIcon}
                    eventHandlers={{
                        click: (e) => leafletMap.panTo(e.latlng),
                    }}
                >
                    <Popup className={'knm-popup'}>
                        <PopupStatistics feature={feature}/>
                    </Popup>
                    <Tooltip>
                        {adrs}
                    </Tooltip>
                </Marker>
            )
        })
    )
}