export const tkList = {
  type: "FeatureCollection",
  name: "tk-okp_v_08-08-2022",
  crs: {
    type: "name",
    properties: {
      name: "urn:ogc:def:crs:OGC:1.3:CRS84"
    }
  },
  features: [
    {
      type: "Feature",
      properties: {
        id: 1,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.051078382995,
        y: 46.942497682752,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.051078382995,
          46.942497682752
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 2,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.048787645944,
        y: 46.943885582431,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.048787645944,
          46.943885582431
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 3,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.048885458758,
        y: 46.943707506915,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.048885458758,
          46.943707506915
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 4,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.046151933693,
        y: 46.943200468746,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.046151933693,
          46.943200468746
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 5,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.047775061081,
        y: 46.950024275425,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.047775061081,
          46.950024275425
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 6,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.048049457149,
        y: 46.949913295278,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.048049457149,
          46.949913295278
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 7,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.050064456493,
        y: 46.949651146912,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.050064456493,
          46.949651146912
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 8,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.049631194895,
        y: 46.949162416273,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.049631194895,
          46.949162416273
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 9,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.05040905191,
        y: 46.948075909617,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05040905191,
          46.948075909617
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 10,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.04672322629,
        y: 46.946814627967,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04672322629,
          46.946814627967
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 11,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.052784851595,
        y: 46.947115497745,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.052784851595,
          46.947115497745
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 12,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.052952173793,
        y: 46.947316984268,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.052952173793,
          46.947316984268
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 13,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.053471748221,
        y: 46.947271983048,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.053471748221,
          46.947271983048
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 14,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.054204239797,
        y: 46.947311365086,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.054204239797,
          46.947311365086
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 15,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.027881567091,
        y: 46.94061669825,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.027881567091,
          46.94061669825
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 16,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.033528612704,
        y: 46.940534441485,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.033528612704,
          46.940534441485
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 17,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.034313237472,
        y: 46.940594541086,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.034313237472,
          46.940594541086
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 18,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.028846088074,
        y: 46.940851639284,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.028846088074,
          46.940851639284
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 19,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.028809174989,
        y: 46.942661805902,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.028809174989,
          46.942661805902
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 20,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.028638057736,
        y: 46.942174203961,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.028638057736,
          46.942174203961
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 21,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.027323100677,
        y: 46.942622173253,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.027323100677,
          46.942622173253
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 22,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.051001018035,
        y: 46.948933850604,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.051001018035,
          46.948933850604
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 23,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.051590062055,
        y: 46.948826522918,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.051590062055,
          46.948826522918
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 24,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.028824123645,
        y: 46.957709963652,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.028824123645,
          46.957709963652
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 25,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.029757647942,
        y: 46.957655654211,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.029757647942,
          46.957655654211
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 26,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.029923032354,
        y: 46.957646032672,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.029923032354,
          46.957646032672
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 27,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.029995879666,
        y: 46.957641794646,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.029995879666,
          46.957641794646
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 28,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.031068059016,
        y: 46.957579418617,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.031068059016,
          46.957579418617
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 29,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.030923730278,
        y: 46.957587815214,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.030923730278,
          46.957587815214
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 30,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.028895456478,
        y: 46.958652719837,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.028895456478,
          46.958652719837
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 31,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.028882814989,
        y: 46.958485647332,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.028882814989,
          46.958485647332
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 32,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.028917544895,
        y: 46.958944643643,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.028917544895,
          46.958944643643
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 33,
        title: "ТК",
        tip: null,
        district: 1,
        holder: 1,
        x: 32.044043024686,
        y: 46.944979822112,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.044043024686,
          46.944979822112
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 34,
        title: "ТК",
        tip: null,
        district: 2,
        holder: 1,
        x: 31.985696940717,
        y: 46.978443242904,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.985696940717,
          46.978443242904
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 35,
        title: "ТК",
        tip: null,
        district: 2,
        holder: 1,
        x: 31.985510132088,
        y: 46.977223815201,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.985510132088,
          46.977223815201
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 36,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.95895918848,
        y: 46.971560523117,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95895918848,
          46.971560523117
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 37,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.956857396997,
        y: 46.967627642372,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.956857396997,
          46.967627642372
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 38,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.956496382347,
        y: 46.966861148515,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.956496382347,
          46.966861148515
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 39,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.95869908092,
        y: 46.966968073703,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95869908092,
          46.966968073703
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 40,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.955973530923,
        y: 46.967148050563,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.955973530923,
          46.967148050563
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 41,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.965975298859,
        y: 46.976670651158,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.965975298859,
          46.976670651158
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 42,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.964801433544,
        y: 46.977117629717,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.964801433544,
          46.977117629717
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 43,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.964330390237,
        y: 46.977412185177,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.964330390237,
          46.977412185177
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 44,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.96528786417,
        y: 46.978096769242,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.96528786417,
          46.978096769242
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 45,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.963477697712,
        y: 46.971006665942,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.963477697712,
          46.971006665942
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 46,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.963762378932,
        y: 46.971071900239,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.963762378932,
          46.971071900239
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 47,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.965722174181,
        y: 46.969580189501,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.965722174181,
          46.969580189501
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 48,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.971044747458,
        y: 46.973978913,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.971044747458,
          46.973978913
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 49,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.970427875098,
        y: 46.973670728211,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.970427875098,
          46.973670728211
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 50,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.975563644495,
        y: 46.97514296104,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.975563644495,
          46.97514296104
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 51,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.975816625623,
        y: 46.974956996602,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.975816625623,
          46.974956996602
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 52,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.976200543336,
        y: 46.974656106701,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.976200543336,
          46.974656106701
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 53,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.974788503555,
        y: 46.973956838313,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.974788503555,
          46.973956838313
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 54,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.966214494847,
        y: 46.9723060495,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.966214494847,
          46.9723060495
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 55,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.964680746934,
        y: 46.971807523128,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.964680746934,
          46.971807523128
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 56,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.963853713606,
        y: 46.973799848496,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.963853713606,
          46.973799848496
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 57,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.963818154132,
        y: 46.973946424864,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.963818154132,
          46.973946424864
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 58,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.967151266046,
        y: 46.972600296936,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.967151266046,
          46.972600296936
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 59,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.966914902676,
        y: 46.972527606372,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.966914902676,
          46.972527606372
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 60,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.969244231567,
        y: 46.971401056113,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.969244231567,
          46.971401056113
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 61,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.968028388657,
        y: 46.974434798865,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.968028388657,
          46.974434798865
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 62,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.968659259748,
        y: 46.972535556988,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.968659259748,
          46.972535556988
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 63,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.968397323841,
        y: 46.9729843126,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.968397323841,
          46.9729843126
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 64,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.966541815995,
        y: 46.972412868083,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.966541815995,
          46.972412868083
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 65,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.964995366276,
        y: 46.974741582509,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.964995366276,
          46.974741582509
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 66,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.965482786056,
        y: 46.97502101644,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.965482786056,
          46.97502101644
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 67,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.966116607387,
        y: 46.97305317668,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.966116607387,
          46.97305317668
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 68,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.965142168302,
        y: 46.973231684556,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.965142168302,
          46.973231684556
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 69,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.965534061846,
        y: 46.973356639845,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.965534061846,
          46.973356639845
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 70,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.965644119458,
        y: 46.973764672764,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.965644119458,
          46.973764672764
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 71,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.965305967332,
        y: 46.975284046784,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.965305967332,
          46.975284046784
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 72,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.974881368579,
        y: 46.974293964029,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.974881368579,
          46.974293964029
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 73,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.970876274766,
        y: 46.972952408898,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.970876274766,
          46.972952408898
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 74,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.963047565458,
        y: 46.96514532221,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.963047565458,
          46.96514532221
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 75,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.963350767864,
        y: 46.96486446669,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.963350767864,
          46.96486446669
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 76,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.964887395151,
        y: 46.963989021713,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.964887395151,
          46.963989021713
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 77,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.968253595818,
        y: 46.978376048919,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.968253595818,
          46.978376048919
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 78,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.968792973918,
        y: 46.977976340275,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.968792973918,
          46.977976340275
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 79,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.971233471721,
        y: 46.97861584211,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.971233471721,
          46.97861584211
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 80,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.97191458873,
        y: 46.978681827594,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97191458873,
          46.978681827594
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 81,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.972649207377,
        y: 46.978652344728,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.972649207377,
          46.978652344728
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 82,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.972980505983,
        y: 46.978513353856,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.972980505983,
          46.978513353856
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 83,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.97392218544,
        y: 46.97912759777,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97392218544,
          46.97912759777
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 84,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.97299491027,
        y: 46.979945363705,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97299491027,
          46.979945363705
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 85,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.971293105198,
        y: 46.98089784582,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.971293105198,
          46.98089784582
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 86,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.972452691745,
        y: 46.981683379038,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.972452691745,
          46.981683379038
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 87,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.97086076079,
        y: 46.980488499566,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.97086076079,
          46.980488499566
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 88,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.971158878091,
        y: 46.980118746609,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.971158878091,
          46.980118746609
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 89,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.971398417968,
        y: 46.979901431562,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.971398417968,
          46.979901431562
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 90,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.973338555407,
        y: 46.979113890107,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.973338555407,
          46.979113890107
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 91,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.965707223756,
        y: 46.972140505303,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.965707223756,
          46.972140505303
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 92,
        title: "ТК",
        tip: null,
        district: 3,
        holder: 1,
        x: 31.964806632481,
        y: 46.96623819662,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.964806632481,
          46.96623819662
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 93,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.081611236116,
        y: 46.976971045002,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.081611236116,
          46.976971045002
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 94,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.081600276005,
        y: 46.976486915942,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.081600276005,
          46.976486915942
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 95,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.081087333969,
        y: 46.97214202989,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.081087333969,
          46.97214202989
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 96,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.081001075671,
        y: 46.971954686677,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.081001075671,
          46.971954686677
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 97,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.080095325828,
        y: 46.969662822766,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.080095325828,
          46.969662822766
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 98,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.077137935288,
        y: 46.970096142583,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.077137935288,
          46.970096142583
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 99,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.077300575764,
        y: 46.970176702636,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.077300575764,
          46.970176702636
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 100,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.076026031377,
        y: 46.969453665173,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.076026031377,
          46.969453665173
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 101,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.075600174995,
        y: 46.971474844537,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.075600174995,
          46.971474844537
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 102,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.07567700888,
        y: 46.971345486413,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07567700888,
          46.971345486413
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 103,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.071973569404,
        y: 46.970559135522,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.071973569404,
          46.970559135522
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 104,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.072531992351,
        y: 46.970983644138,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.072531992351,
          46.970983644138
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 105,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.073085764088,
        y: 46.971166443275,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.073085764088,
          46.971166443275
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 106,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.073156548469,
        y: 46.971189809037,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.073156548469,
          46.971189809037
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 107,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.073705559215,
        y: 46.971677576894,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.073705559215,
          46.971677576894
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 108,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.075340910288,
        y: 46.971917871301,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.075340910288,
          46.971917871301
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 109,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.075781185711,
        y: 46.971208781385,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.075781185711,
          46.971208781385
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 110,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.073627891746,
        y: 46.971787545884,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.073627891746,
          46.971787545884
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 111,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.073704046127,
        y: 46.967290521002,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.073704046127,
          46.967290521002
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 112,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.073763089658,
        y: 46.967358141995,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.073763089658,
          46.967358141995
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 113,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.073719536955,
        y: 46.962399333078,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.073719536955,
          46.962399333078
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 114,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.064780012119,
        y: 46.95208725577,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.064780012119,
          46.95208725577
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 115,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.066018479214,
        y: 46.953434572994,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.066018479214,
          46.953434572994
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 116,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.065604626259,
        y: 46.952980472212,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.065604626259,
          46.952980472212
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 117,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.066609935271,
        y: 46.954109480214,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.066609935271,
          46.954109480214
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 118,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.067297618884,
        y: 46.955221567266,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.067297618884,
          46.955221567266
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 119,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.067500747921,
        y: 46.955138782399,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.067500747921,
          46.955138782399
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 120,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.067646261009,
        y: 46.955308374434,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.067646261009,
          46.955308374434
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 121,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.067868338887,
        y: 46.955564443221,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.067868338887,
          46.955564443221
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 122,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.067923413349,
        y: 46.955628494138,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.067923413349,
          46.955628494138
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 123,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.068822480522,
        y: 46.956660294383,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.068822480522,
          46.956660294383
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 124,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.068489495151,
        y: 46.9562781513,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.068489495151,
          46.9562781513
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 125,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.069293389308,
        y: 46.957202733079,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.069293389308,
          46.957202733079
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 126,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.07021112949,
        y: 46.958260112475,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07021112949,
          46.958260112475
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 127,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.070116908687,
        y: 46.958151556421,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.070116908687,
          46.958151556421
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 128,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.071134642361,
        y: 46.960612855338,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.071134642361,
          46.960612855338
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 129,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.071247837737,
        y: 46.960473288322,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.071247837737,
          46.960473288322
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 130,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.07208704044,
        y: 46.960503023678,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.07208704044,
          46.960503023678
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 131,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.067931600768,
        y: 46.953578299987,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.067931600768,
          46.953578299987
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 132,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.06721986455,
        y: 46.951769992466,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06721986455,
          46.951769992466
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 133,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.066712117901,
        y: 46.951327515367,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.066712117901,
          46.951327515367
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 134,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.066224834111,
        y: 46.95151000821,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.066224834111,
          46.95151000821
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 135,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.065576619004,
        y: 46.951752770539,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.065576619004,
          46.951752770539
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 136,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.06600524468,
        y: 46.952285235069,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.06600524468,
          46.952285235069
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 137,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.066635648071,
        y: 46.952775722271,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.066635648071,
          46.952775722271
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 138,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.057143252362,
        y: 46.938927028736,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.057143252362,
          46.938927028736
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 139,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.057075844146,
        y: 46.938954965602,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.057075844146,
          46.938954965602
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 140,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.058687670856,
        y: 46.938523622519,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.058687670856,
          46.938523622519
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 141,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.058117516131,
        y: 46.938731496364,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.058117516131,
          46.938731496364
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 142,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.056685525606,
        y: 46.939936235648,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.056685525606,
          46.939936235648
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 143,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.056726222129,
        y: 46.939487820955,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.056726222129,
          46.939487820955
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 144,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.056847808622,
        y: 46.939427760351,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.056847808622,
          46.939427760351
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 145,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.054703574846,
        y: 46.940333762709,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.054703574846,
          46.940333762709
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 146,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.055456853475,
        y: 46.940324643768,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.055456853475,
          46.940324643768
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 147,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.060778219391,
        y: 46.941012435518,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.060778219391,
          46.941012435518
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 148,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.060824789693,
        y: 46.941067218066,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.060824789693,
          46.941067218066
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 149,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.060317766762,
        y: 46.940510587008,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.060317766762,
          46.940510587008
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 150,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.060784897393,
        y: 46.940195382616,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.060784897393,
          46.940195382616
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 151,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.060172771646,
        y: 46.942001824174,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.060172771646,
          46.942001824174
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 152,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.059862030971,
        y: 46.941659962639,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.059862030971,
          46.941659962639
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 153,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.057443960357,
        y: 46.94633041213,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.057443960357,
          46.94633041213
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 154,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.058734156548,
        y: 46.945846191779,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.058734156548,
          46.945846191779
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 155,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.05860744797,
        y: 46.945731224537,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05860744797,
          46.945731224537
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 156,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.058284258566,
        y: 46.94539866704,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.058284258566,
          46.94539866704
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 157,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.057952271774,
        y: 46.945350077825,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.057952271774,
          46.945350077825
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 158,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.05798112395,
        y: 46.945031004457,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.05798112395,
          46.945031004457
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 159,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.057045540152,
        y: 46.944009309981,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.057045540152,
          46.944009309981
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 160,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.057817963384,
        y: 46.942588656362,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.057817963384,
          46.942588656362
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 161,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.058163723092,
        y: 46.942453276424,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.058163723092,
          46.942453276424
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 162,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.058500643293,
        y: 46.942321357208,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.058500643293,
          46.942321357208
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 163,
        title: "ТК",
        tip: null,
        district: 5,
        holder: 1,
        x: 32.059598550333,
        y: 46.939710935484,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.059598550333,
          46.939710935484
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 164,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.004954400187,
        y: 46.856134596967,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.004954400187,
          46.856134596967
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 165,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.00570100153,
        y: 46.856020126229,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00570100153,
          46.856020126229
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 166,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.006506372493,
        y: 46.855918435551,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.006506372493,
          46.855918435551
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 167,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.00291605426,
        y: 46.854766683772,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00291605426,
          46.854766683772
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 168,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.002809608033,
        y: 46.854340468058,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.002809608033,
          46.854340468058
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 169,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.006430105205,
        y: 46.851577333715,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.006430105205,
          46.851577333715
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 170,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.004866957504,
        y: 46.852588164673,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.004866957504,
          46.852588164673
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 171,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.005207969173,
        y: 46.852361554452,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.005207969173,
          46.852361554452
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 172,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.004076094679,
        y: 46.852840144703,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.004076094679,
          46.852840144703
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 173,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.007488071968,
        y: 46.852493543926,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.007488071968,
          46.852493543926
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 174,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.00681969709,
        y: 46.852576572057,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00681969709,
          46.852576572057
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 175,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.007142919832,
        y: 46.852536420085,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.007142919832,
          46.852536420085
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 176,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.009133277221,
        y: 46.85672643159,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.009133277221,
          46.85672643159
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 177,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.005341426346,
        y: 46.853529181986,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.005341426346,
          46.853529181986
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 178,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.004715619783,
        y: 46.853613765935,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.004715619783,
          46.853613765935
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 179,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.004917899545,
        y: 46.854393250038,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.004917899545,
          46.854393250038
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 180,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.007997380126,
        y: 46.853932379269,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.007997380126,
          46.853932379269
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 181,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.009296296962,
        y: 46.853464925715,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.009296296962,
          46.853464925715
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 182,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.009235160697,
        y: 46.853788286351,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.009235160697,
          46.853788286351
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 183,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.010377390077,
        y: 46.852822674367,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.010377390077,
          46.852822674367
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 184,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.010153278363,
        y: 46.852476002229,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.010153278363,
          46.852476002229
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 185,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.008771582718,
        y: 46.852334265221,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.008771582718,
          46.852334265221
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 186,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.009331853522,
        y: 46.852575045801,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.009331853522,
          46.852575045801
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 187,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.010045549321,
        y: 46.855294183069,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.010045549321,
          46.855294183069
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 188,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.009291338308,
        y: 46.855674010563,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.009291338308,
          46.855674010563
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 189,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.012528122242,
        y: 46.856009261288,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.012528122242,
          46.856009261288
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 190,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.014555619234,
        y: 46.856064775096,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.014555619234,
          46.856064775096
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 191,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.004747744491,
        y: 46.857255668481,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.004747744491,
          46.857255668481
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 192,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.026817876036,
        y: 46.853837813327,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.026817876036,
          46.853837813327
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 193,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.026988463069,
        y: 46.854500589435,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.026988463069,
          46.854500589435
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 194,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.02624911594,
        y: 46.854585057991,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02624911594,
          46.854585057991
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 195,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.025497828602,
        y: 46.853600343986,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.025497828602,
          46.853600343986
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 196,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.02569197362,
        y: 46.854298702535,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02569197362,
          46.854298702535
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 197,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.025087206801,
        y: 46.854368892432,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.025087206801,
          46.854368892432
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 198,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.012808713528,
        y: 46.857884490765,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.012808713528,
          46.857884490765
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 199,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.014059794889,
        y: 46.857728869702,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.014059794889,
          46.857728869702
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 200,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.014572650345,
        y: 46.857665640576,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.014572650345,
          46.857665640576
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 201,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.015031082566,
        y: 46.857609121141,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.015031082566,
          46.857609121141
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 202,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.012212478832,
        y: 46.859337236146,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.012212478832,
          46.859337236146
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 203,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.015078097546,
        y: 46.859053166199,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.015078097546,
          46.859053166199
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 204,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.015035036536,
        y: 46.858889541641,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.015035036536,
          46.858889541641
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 205,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.014978670307,
        y: 46.858675358747,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.014978670307,
          46.858675358747
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 206,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.012752730853,
        y: 46.859495750394,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.012752730853,
          46.859495750394
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 207,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.012692736277,
        y: 46.859282347953,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.012692736277,
          46.859282347953
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 208,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.012549672288,
        y: 46.858704855395,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.012549672288,
          46.858704855395
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 209,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.018837360686,
        y: 46.858095186763,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.018837360686,
          46.858095186763
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 210,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.019006229988,
        y: 46.858679683715,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.019006229988,
          46.858679683715
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 211,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.019518007895,
        y: 46.85722330045,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.019518007895,
          46.85722330045
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 212,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.016641778293,
        y: 46.855918090377,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.016641778293,
          46.855918090377
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 213,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.016818228687,
        y: 46.856182690354,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.016818228687,
          46.856182690354
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 214,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.018250230722,
        y: 46.856239403918,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.018250230722,
          46.856239403918
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 215,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.018608166359,
        y: 46.856721111029,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.018608166359,
          46.856721111029
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 216,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.018782817347,
        y: 46.8573085779,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.018782817347,
          46.8573085779
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 217,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.019361350035,
        y: 46.855409896372,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.019361350035,
          46.855409896372
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 218,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.019922961511,
        y: 46.854093749838,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.019922961511,
          46.854093749838
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 219,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.020257155295,
        y: 46.855663439144,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.020257155295,
          46.855663439144
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 220,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.020312256164,
        y: 46.855922240403,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.020312256164,
          46.855922240403
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 221,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.020464606645,
        y: 46.856637803321,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.020464606645,
          46.856637803321
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 222,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.022720521743,
        y: 46.85709053734,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.022720521743,
          46.85709053734
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 223,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.02245615097,
        y: 46.856701298881,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02245615097,
          46.856701298881
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 224,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.02226924479,
        y: 46.856415695347,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02226924479,
          46.856415695347
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 225,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.02261882857,
        y: 46.855481556052,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02261882857,
          46.855481556052
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 226,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.022638309039,
        y: 46.855552283775,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.022638309039,
          46.855552283775
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 227,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.022112335899,
        y: 46.855999121723,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.022112335899,
          46.855999121723
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 228,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.026624093852,
        y: 46.856076641479,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.026624093852,
          46.856076641479
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 229,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.026359109344,
        y: 46.855818445443,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.026359109344,
          46.855818445443
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 230,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.025489183727,
        y: 46.85630408851,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.025489183727,
          46.85630408851
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 231,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.024981297201,
        y: 46.856395285349,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.024981297201,
          46.856395285349
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 232,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.024539401377,
        y: 46.856465814203,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.024539401377,
          46.856465814203
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 233,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.024448002625,
        y: 46.857179756564,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.024448002625,
          46.857179756564
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 234,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.024480799581,
        y: 46.857559342592,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.024480799581,
          46.857559342592
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 235,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.026247346679,
        y: 46.856439364206,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.026247346679,
          46.856439364206
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 236,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.026425022924,
        y: 46.856414117422,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.026425022924,
          46.856414117422
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 237,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.023842722679,
        y: 46.855347185974,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.023842722679,
          46.855347185974
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 238,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.023612598992,
        y: 46.858194581535,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.023612598992,
          46.858194581535
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 239,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.024504783183,
        y: 46.857841302139,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.024504783183,
          46.857841302139
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 240,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.045907101411,
        y: 46.871759767236,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.045907101411,
          46.871759767236
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 241,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.046179384487,
        y: 46.871747147448,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.046179384487,
          46.871747147448
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 242,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.046726989384,
        y: 46.872033164685,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.046726989384,
          46.872033164685
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 243,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.047072380677,
        y: 46.87201058491,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.047072380677,
          46.87201058491
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 244,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.047824620361,
        y: 46.872113120099,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.047824620361,
          46.872113120099
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 245,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.047825772082,
        y: 46.872262469367,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.047825772082,
          46.872262469367
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 246,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.046361675699,
        y: 46.873158967841,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.046361675699,
          46.873158967841
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 247,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.046306679608,
        y: 46.872655884739,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.046306679608,
          46.872655884739
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 248,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.046236372883,
        y: 46.872129651351,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.046236372883,
          46.872129651351
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 249,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.046227841645,
        y: 46.87206579619,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.046227841645,
          46.87206579619
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 250,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.048652785741,
        y: 46.872730328621,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.048652785741,
          46.872730328621
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 251,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.048449250629,
        y: 46.872732274648,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.048449250629,
          46.872732274648
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 252,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.047830965941,
        y: 46.872738186151,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.047830965941,
          46.872738186151
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 253,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.048054341969,
        y: 46.868550506406,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.048054341969,
          46.868550506406
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 254,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.047909997258,
        y: 46.867869577023,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.047909997258,
          46.867869577023
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 255,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.045552968483,
        y: 46.868078266452,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.045552968483,
          46.868078266452
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 256,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.045547133955,
        y: 46.867945301178,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.045547133955,
          46.867945301178
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 257,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.043996504912,
        y: 46.868014714892,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.043996504912,
          46.868014714892
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 258,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.043961804945,
        y: 46.867723251276,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.043961804945,
          46.867723251276
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 259,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.042565865022,
        y: 46.866566473791,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.042565865022,
          46.866566473791
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 260,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.043844210989,
        y: 46.866509679226,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.043844210989,
          46.866509679226
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 261,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.038855431242,
        y: 46.865866003291,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.038855431242,
          46.865866003291
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 262,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.042505870446,
        y: 46.865559938987,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.042505870446,
          46.865559938987
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 263,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.037844176513,
        y: 46.862283442837,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.037844176513,
          46.862283442837
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 264,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.043894798771,
        y: 46.867074676064,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.043894798771,
          46.867074676064
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 265,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.009117353558,
        y: 46.86742018897,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.009117353558,
          46.86742018897
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 266,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.009091913078,
        y: 46.867755403859,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.009091913078,
          46.867755403859
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 267,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.010540484903,
        y: 46.866152126198,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.010540484903,
          46.866152126198
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 268,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.014113138204,
        y: 46.866416287024,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.014113138204,
          46.866416287024
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 269,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.013992213705,
        y: 46.86673704774,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.013992213705,
          46.86673704774
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 270,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.020715224893,
        y: 46.872797685154,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.020715224893,
          46.872797685154
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 271,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.020955119212,
        y: 46.87272814223,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.020955119212,
          46.87272814223
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 272,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.021826826822,
        y: 46.872661796289,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.021826826822,
          46.872661796289
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 273,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.019757535174,
        y: 46.870585929347,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.019757535174,
          46.870585929347
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 274,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.020214895758,
        y: 46.872254804314,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.020214895758,
          46.872254804314
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 275,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.021783505858,
        y: 46.872273836186,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.021783505858,
          46.872273836186
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 276,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.018797120089,
        y: 46.869540354836,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.018797120089,
          46.869540354836
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 277,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.014734436382,
        y: 46.856043275109,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.014734436382,
          46.856043275109
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 278,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.017650739049,
        y: 46.863548401325,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.017650739049,
          46.863548401325
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 279,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.013817959106,
        y: 46.867580185501,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.013817959106,
          46.867580185501
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 280,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.021679187284,
        y: 46.873252377296,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.021679187284,
          46.873252377296
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 281,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.02163716567,
        y: 46.873123222149,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.02163716567,
          46.873123222149
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 282,
        title: "ТК",
        tip: null,
        district: 6,
        holder: 1,
        x: 32.021850144523,
        y: 46.872870616372,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.021850144523,
          46.872870616372
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 283,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.946061039066,
        y: 46.945574992288,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.946061039066,
          46.945574992288
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 284,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.946035953055,
        y: 46.945973484478,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.946035953055,
          46.945973484478
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 285,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.946027998954,
        y: 46.946380536592,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.946027998954,
          46.946380536592
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 286,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.945291192811,
        y: 46.945692707776,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.945291192811,
          46.945692707776
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 287,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.94445851616,
        y: 46.945859188466,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94445851616,
          46.945859188466
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 288,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.944033769003,
        y: 46.946408495525,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.944033769003,
          46.946408495525
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 289,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.943374847329,
        y: 46.946237682329,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.943374847329,
          46.946237682329
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 290,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.943625885974,
        y: 46.945871304266,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.943625885974,
          46.945871304266
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 291,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.93915030854,
        y: 46.953852389845,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93915030854,
          46.953852389845
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 292,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.938687699966,
        y: 46.954016744906,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.938687699966,
          46.954016744906
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 293,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.938235322924,
        y: 46.954077794715,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.938235322924,
          46.954077794715
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 294,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.941683657094,
        y: 46.955361186911,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.941683657094,
          46.955361186911
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 295,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.943302546707,
        y: 46.953031481297,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.943302546707,
          46.953031481297
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 296,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.941022593332,
        y: 46.953810245279,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.941022593332,
          46.953810245279
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 297,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.94461280081,
        y: 46.952022361789,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94461280081,
          46.952022361789
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 298,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.942383378954,
        y: 46.952017320378,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.942383378954,
          46.952017320378
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 299,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.944556006238,
        y: 46.950834602702,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.944556006238,
          46.950834602702
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 300,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.950576658313,
        y: 46.953758253685,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.950576658313,
          46.953758253685
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 301,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.95122164413,
        y: 46.953815535606,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95122164413,
          46.953815535606
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 302,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.951961924313,
        y: 46.954040115632,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.951961924313,
          46.954040115632
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 303,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.951736162998,
        y: 46.954381046496,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.951736162998,
          46.954381046496
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 304,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.953993753435,
        y: 46.955306588962,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.953993753435,
          46.955306588962
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 305,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.95438354353,
        y: 46.95543028174,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95438354353,
          46.95543028174
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 306,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.958254889095,
        y: 46.952860884146,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.958254889095,
          46.952860884146
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 307,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.95619105871,
        y: 46.955027412671,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95619105871,
          46.955027412671
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 308,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.962328418739,
        y: 46.956356335603,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.962328418739,
          46.956356335603
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 309,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.962517719565,
        y: 46.956853914114,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.962517719565,
          46.956853914114
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 310,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.962984159791,
        y: 46.956103744026,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.962984159791,
          46.956103744026
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 311,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.963269866253,
        y: 46.956413681229,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.963269866253,
          46.956413681229
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 312,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.963596269955,
        y: 46.956767765003,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.963596269955,
          46.956767765003
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 313,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.964254262784,
        y: 46.957052378128,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.964254262784,
          46.957052378128
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 314,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.962737175926,
        y: 46.955547177845,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.962737175926,
          46.955547177845
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 315,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.963283804282,
        y: 46.955798969741,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.963283804282,
          46.955798969741
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 316,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.954288676893,
        y: 46.956233420826,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.954288676893,
          46.956233420826
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 317,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.954501517679,
        y: 46.956302033245,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.954501517679,
          46.956302033245
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 318,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.954679363055,
        y: 46.956359364302,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.954679363055,
          46.956359364302
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 319,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.953976714066,
        y: 46.957065130983,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.953976714066,
          46.957065130983
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 320,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.954155063795,
        y: 46.956805800386,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.954155063795,
          46.956805800386
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 321,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.953907067309,
        y: 46.956728993727,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.953907067309,
          46.956728993727
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 322,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.949639322869,
        y: 46.955882626934,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.949639322869,
          46.955882626934
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 323,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.950260248268,
        y: 46.95608478978,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.950260248268,
          46.95608478978
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 324,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.950838811977,
        y: 46.956273159687,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.950838811977,
          46.956273159687
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 325,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.950648768879,
        y: 46.955047595056,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.950648768879,
          46.955047595056
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 326,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.950970370128,
        y: 46.955152962628,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.950970370128,
          46.955152962628
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 327,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.951496612251,
        y: 46.955325377103,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.951496612251,
          46.955325377103
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 328,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.952167503327,
        y: 46.955545182596,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.952167503327,
          46.955545182596
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 329,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.95066202022,
        y: 46.95621559955,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.95066202022,
          46.95621559955
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 330,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.947641007918,
        y: 46.956844832767,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.947641007918,
          46.956844832767
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 331,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.948441312865,
        y: 46.955224441451,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.948441312865,
          46.955224441451
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 332,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.946039609299,
        y: 46.954641664133,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.946039609299,
          46.954641664133
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 333,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.946440536576,
        y: 46.954078017799,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.946440536576,
          46.954078017799
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 334,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.945821299657,
        y: 46.953738390934,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.945821299657,
          46.953738390934
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 335,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.942552611155,
        y: 46.949681685592,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.942552611155,
          46.949681685592
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 336,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.943153037998,
        y: 46.949896283707,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.943153037998,
          46.949896283707
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 337,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.94221639297,
        y: 46.949575734301,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.94221639297,
          46.949575734301
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 338,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.943069495396,
        y: 46.948811789041,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.943069495396,
          46.948811789041
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 339,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.943198551669,
        y: 46.948646147859,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.943198551669,
          46.948646147859
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 340,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.9415829331,
        y: 46.953071967913,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9415829331,
          46.953071967913
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 341,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.940065635324,
        y: 46.951499020607,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.940065635324,
          46.951499020607
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 342,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.939252099909,
        y: 46.953208712645,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.939252099909,
          46.953208712645
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 343,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.937510974893,
        y: 46.950589448763,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.937510974893,
          46.950589448763
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 344,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.937394376642,
        y: 46.950179968025,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.937394376642,
          46.950179968025
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 345,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.936145290522,
        y: 46.950564420385,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.936145290522,
          46.950564420385
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 346,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.93624360759,
        y: 46.950929929729,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93624360759,
          46.950929929729
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 347,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.936008464753,
        y: 46.950055744663,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.936008464753,
          46.950055744663
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 348,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.938219628749,
        y: 46.950084965436,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.938219628749,
          46.950084965436
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 349,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.935382066123,
        y: 46.947696970052,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.935382066123,
          46.947696970052
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 350,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.936023300274,
        y: 46.947609585343,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.936023300274,
          46.947609585343
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 351,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.934369170836,
        y: 46.946750043862,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.934369170836,
          46.946750043862
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 352,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.934461106631,
        y: 46.947046282157,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.934461106631,
          46.947046282157
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 353,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.934697228901,
        y: 46.947788800248,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.934697228901,
          46.947788800248
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 354,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.934888517031,
        y: 46.948423475322,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.934888517031,
          46.948423475322
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 355,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.935061788315,
        y: 46.948653045766,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.935061788315,
          46.948653045766
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 356,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.935717301092,
        y: 46.948569667012,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.935717301092,
          46.948569667012
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 357,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.936270839317,
        y: 46.948502067964,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.936270839317,
          46.948502067964
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 358,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.938744527956,
        y: 46.946360721852,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.938744527956,
          46.946360721852
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 359,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.939243803249,
        y: 46.947304989101,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.939243803249,
          46.947304989101
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 360,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.93959638726,
        y: 46.94687879011,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.93959638726,
          46.94687879011
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 361,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.938148085092,
        y: 46.949766948165,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.938148085092,
          46.949766948165
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 362,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.940650299998,
        y: 46.947814932034,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.940650299998,
          46.947814932034
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 363,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.940214659997,
        y: 46.948229279677,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.940214659997,
          46.948229279677
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 364,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.941877618904,
        y: 46.946818315443,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.941877618904,
          46.946818315443
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 365,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.942016074846,
        y: 46.94869629432,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.942016074846,
          46.94869629432
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 366,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.952351319773,
        y: 46.956693101748,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.952351319773,
          46.956693101748
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 367,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.952374365925,
        y: 46.956659239246,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.952374365925,
          46.956659239246
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 368,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.952475454313,
        y: 46.956510706358,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.952475454313,
          46.956510706358
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 369,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.952601286,
        y: 46.956325816653,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.952601286,
          46.956325816653
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 370,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.953027836016,
        y: 46.955111295683,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.953027836016,
          46.955111295683
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 371,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.952848757132,
        y: 46.955355789648,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.952848757132,
          46.955355789648
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 372,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.955660690403,
        y: 46.959350882263,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.955660690403,
          46.959350882263
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 373,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.955411582097,
        y: 46.959458675258,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.955411582097,
          46.959458675258
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 374,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.955457203756,
        y: 46.95964643116,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.955457203756,
          46.95964643116
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 375,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.955959878258,
        y: 46.959828131365,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.955959878258,
          46.959828131365
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 376,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.956302441426,
        y: 46.95950210563,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.956302441426,
          46.95950210563
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 377,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.948843260796,
        y: 46.957613864761,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.948843260796,
          46.957613864761
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 378,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.950793743674,
        y: 46.959915351136,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.950793743674,
          46.959915351136
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 379,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.950702232979,
        y: 46.960533989061,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.950702232979,
          46.960533989061
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 380,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.951204728368,
        y: 46.960281933965,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.951204728368,
          46.960281933965
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 381,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.951252707043,
        y: 46.960427561154,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.951252707043,
          46.960427561154
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 382,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.951036145297,
        y: 46.960134525319,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.951036145297,
          46.960134525319
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 383,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.949869911838,
        y: 46.95976955933,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.949869911838,
          46.95976955933
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 384,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.949987523742,
        y: 46.959753313905,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.949987523742,
          46.959753313905
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 385,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.952756445848,
        y: 46.961245708008,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.952756445848,
          46.961245708008
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 386,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.953417793907,
        y: 46.96364646862,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.953417793907,
          46.96364646862
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 387,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.952883603949,
        y: 46.965531172705,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.952883603949,
          46.965531172705
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 388,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.948862275092,
        y: 46.963493034967,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.948862275092,
          46.963493034967
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 389,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.949978038267,
        y: 46.963266578208,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.949978038267,
          46.963266578208
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 390,
        title: "ТК",
        tip: null,
        district: 8,
        holder: 1,
        x: 31.958807954081,
        y: 46.956516991479,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.958807954081,
          46.956516991479
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 391,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.9838438921,
        y: 46.979180981099,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.9838438921,
          46.979180981099
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 392,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.998205860499,
        y: 46.958472260586,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.998205860499,
          46.958472260586
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 393,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.008952572881,
        y: 46.957532251969,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.008952572881,
          46.957532251969
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 394,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.008998917293,
        y: 46.95788885102,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.008998917293,
          46.95788885102
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 395,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.00902341276,
        y: 46.958091518109,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.00902341276,
          46.958091518109
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 396,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.009019750203,
        y: 46.958047771309,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.009019750203,
          46.958047771309
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 397,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.009227325199,
        y: 46.957803249911,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.009227325199,
          46.957803249911
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 398,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.012922374229,
        y: 46.964676046271,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.012922374229,
          46.964676046271
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 399,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.016429272806,
        y: 46.964442095086,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.016429272806,
          46.964442095086
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 400,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.016603556957,
        y: 46.964424879999,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.016603556957,
          46.964424879999
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 401,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.014846482983,
        y: 46.964616426099,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.014846482983,
          46.964616426099
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 402,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.013667469773,
        y: 46.964618561268,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.013667469773,
          46.964618561268
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 403,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.019710623684,
        y: 46.963410867759,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.019710623684,
          46.963410867759
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 404,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.019288269044,
        y: 46.963425597875,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.019288269044,
          46.963425597875
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 405,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.019291069612,
        y: 46.963125854051,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.019291069612,
          46.963125854051
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 406,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.019814679274,
        y: 46.96439784245,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.019814679274,
          46.96439784245
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 407,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.043010015939,
        y: 46.939027869502,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.043010015939,
          46.939027869502
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 408,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.041271350478,
        y: 46.939033238734,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.041271350478,
          46.939033238734
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 409,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.041469386743,
        y: 46.938656053904,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.041469386743,
          46.938656053904
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 410,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.042491929407,
        y: 46.938901243685,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.042491929407,
          46.938901243685
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 411,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.041680084251,
        y: 46.93988904392,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.041680084251,
          46.93988904392
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 412,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.040919745055,
        y: 46.939694358893,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.040919745055,
          46.939694358893
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 413,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.041137088504,
        y: 46.939750009806,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.041137088504,
          46.939750009806
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 414,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.04106807952,
        y: 46.939874885013,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04106807952,
          46.939874885013
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 415,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.040891709934,
        y: 46.940194033274,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.040891709934,
          46.940194033274
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 416,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.040787408299,
        y: 46.940382770619,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.040787408299,
          46.940382770619
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 417,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.040546164294,
        y: 46.940977190042,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.040546164294,
          46.940977190042
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 418,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.040199769271,
        y: 46.940889348066,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.040199769271,
          46.940889348066
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 419,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.039735885946,
        y: 46.94077171212,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.039735885946,
          46.94077171212
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 420,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.044068410303,
        y: 46.939891185522,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.044068410303,
          46.939891185522
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 421,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.044840360144,
        y: 46.940060867543,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.044840360144,
          46.940060867543
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 422,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.045437772981,
        y: 46.94021413919,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.045437772981,
          46.94021413919
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 423,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.038158403039,
        y: 46.943136000345,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.038158403039,
          46.943136000345
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 424,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.038964206342,
        y: 46.943328070764,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.038964206342,
          46.943328070764
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 425,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.038808524438,
        y: 46.943603815589,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.038808524438,
          46.943603815589
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 426,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.039450455689,
        y: 46.943453757218,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.039450455689,
          46.943453757218
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 427,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.040369126636,
        y: 46.943693516854,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.040369126636,
          46.943693516854
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 428,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.040055158526,
        y: 46.943171474173,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.040055158526,
          46.943171474173
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 429,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.040679263362,
        y: 46.94208915421,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.040679263362,
          46.94208915421
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 430,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.039454214768,
        y: 46.94247860786,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.039454214768,
          46.94247860786
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 431,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.039581911146,
        y: 46.942227342825,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.039581911146,
          46.942227342825
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 432,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.039167850085,
        y: 46.942972110825,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.039167850085,
          46.942972110825
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 433,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.035393575008,
        y: 46.941841647422,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.035393575008,
          46.941841647422
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 434,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.036890103122,
        y: 46.940974259633,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.036890103122,
          46.940974259633
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 435,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.036462239355,
        y: 46.940766561164,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.036462239355,
          46.940766561164
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 436,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.036017415729,
        y: 46.94066139608,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.036017415729,
          46.94066139608
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 437,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.038613366019,
        y: 46.947253920938,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.038613366019,
          46.947253920938
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 438,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.037476807102,
        y: 46.94844058553,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.037476807102,
          46.94844058553
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 439,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.035674579013,
        y: 46.948866095368,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.035674579013,
          46.948866095368
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 440,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.043915931159,
        y: 46.949965149514,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.043915931159,
          46.949965149514
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 441,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.04172433432,
        y: 46.951361375988,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.04172433432,
          46.951361375988
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 442,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.042216032528,
        y: 46.951023857305,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.042216032528,
          46.951023857305
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 443,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.042660206141,
        y: 46.950713285685,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.042660206141,
          46.950713285685
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 444,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.042133528968,
        y: 46.950408089135,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.042133528968,
          46.950408089135
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 445,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.019397604581,
        y: 46.965904504549,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.019397604581,
          46.965904504549
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 446,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.020488737412,
        y: 46.96584781492,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.020488737412,
          46.96584781492
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 447,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.021154429918,
        y: 46.966317849672,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.021154429918,
          46.966317849672
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 448,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.019247725903,
        y: 46.969385150292,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.019247725903,
          46.969385150292
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 449,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.017135407223,
        y: 46.96756684932,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.017135407223,
          46.96756684932
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 450,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.016651778085,
        y: 46.966774971673,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.016651778085,
          46.966774971673
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 451,
        title: "ТК71-1",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.008996458975,
        y: 46.966684915146,
        index: "71-1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.008996458975,
          46.966684915146
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 452,
        title: "ТК33",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.001171532843,
        y: 46.972719107359,
        index: "33"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.001171532843,
          46.972719107359
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 453,
        title: "ТК31",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.998671106375,
        y: 46.972045763478,
        index: "31"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.998671106375,
          46.972045763478
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 454,
        title: "ТК32",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.998374964179,
        y: 46.971624453837,
        index: "32"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.998374964179,
          46.971624453837
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 455,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.000018985546,
        y: 46.958837227482,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.000018985546,
          46.958837227482
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 456,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.966669192837,
        y: 46.965823963922,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.966669192837,
          46.965823963922
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 457,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.966416362487,
        y: 46.966328758067,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.966416362487,
          46.966328758067
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 458,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.970698698963,
        y: 46.967586290508,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.970698698963,
          46.967586290508
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 459,
        title: "ТК2",
        tip: "Розподілчий колектор котельні пр. Центральний, 22-А",
        district: 9,
        holder: 1,
        x: 31.970782623222,
        y: 46.969039373534,
        index: "2"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.970782623222,
          46.969039373534
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 460,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.98811856457,
        y: 46.967391283941,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.98811856457,
          46.967391283941
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 461,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.987639709946,
        y: 46.967757978837,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.987639709946,
          46.967757978837
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 462,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.987728134805,
        y: 46.967890731054,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.987728134805,
          46.967890731054
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 463,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.990154449573,
        y: 46.965203164026,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.990154449573,
          46.965203164026
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 464,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.989500572728,
        y: 46.965258240726,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.989500572728,
          46.965258240726
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 465,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.988929910202,
        y: 46.965306308154,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.988929910202,
          46.965306308154
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 466,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.989130155516,
        y: 46.964276641525,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.989130155516,
          46.964276641525
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 467,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.990740205381,
        y: 46.963849812867,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.990740205381,
          46.963849812867
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 468,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.992097374373,
        y: 46.963532125307,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.992097374373,
          46.963532125307
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 469,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.984612738825,
        y: 46.967961642265,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.984612738825,
          46.967961642265
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 470,
        title: "ТК321-1",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.980007740381,
        y: 46.978164822126,
        index: "321-1"
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.980007740381,
          46.978164822126
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 471,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.982055942235,
        y: 46.977042470505,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.982055942235,
          46.977042470505
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 472,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.014820742736,
        y: 46.967927691182,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.014820742736,
          46.967927691182
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 473,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.037403433305,
        y: 46.951809112332,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.037403433305,
          46.951809112332
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 474,
        title: "ТК305",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.066828151845,
        y: 46.950553854772,
        index: "305"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.066828151845,
          46.950553854772
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 475,
        title: "ТК306",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.064371580889,
        y: 46.951565545104,
        index: "306"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.064371580889,
          46.951565545104
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 476,
        title: "ТК307",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.062687708392,
        y: 46.952259003757,
        index: "307"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.062687708392,
          46.952259003757
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 477,
        title: "ТК308",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.059901215433,
        y: 46.953385132877,
        index: "308"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.059901215433,
          46.953385132877
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 478,
        title: "ТК309",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.057128925779,
        y: 46.954505080995,
        index: "309"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.057128925779,
          46.954505080995
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 479,
        title: "ТК310",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.055983665314,
        y: 46.954967735874,
        index: "310"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.055983665314,
          46.954967735874
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 480,
        title: "ТК311",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.054945521905,
        y: 46.955387114846,
        index: "311"
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.054945521905,
          46.955387114846
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 481,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.045868748165,
        y: 46.950237347548,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.045868748165,
          46.950237347548
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 482,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 32.040973445335,
        y: 46.95095437225,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          32.040973445335,
          46.95095437225
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 483,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.999384069056,
        y: 46.955273951029,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.999384069056,
          46.955273951029
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 484,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.998165440146,
        y: 46.955514298182,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.998165440146,
          46.955514298182
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 485,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.998066613608,
        y: 46.955918994375,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.998066613608,
          46.955918994375
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 486,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.998097468454,
        y: 46.955715823948,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.998097468454,
          46.955715823948
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 487,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.998039963372,
        y: 46.956094478019,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.998039963372,
          46.956094478019
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 488,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.990001843066,
        y: 46.959810572738,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.990001843066,
          46.959810572738
        ]
      }
    },
    {
      type: "Feature",
      properties: {
        id: 489,
        title: "ТК",
        tip: null,
        district: 9,
        holder: 1,
        x: 31.990098839601,
        y: 46.959103732101,
        index: null
      },
      geometry: {
        type: "Point",
        coordinates: [
          31.990098839601,
          46.959103732101
        ]
      }
    }
  ]
}