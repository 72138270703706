import React from "react";

import {LayersControl, LayerGroup, Marker, Popup, Tooltip, useMap} from "react-leaflet";
import {boilerIcon} from "../icons/boilerIcon";
import {PopupStatisticsBoilers} from "../components/PopupStatisticsBoilers"


// @ts-ignore
export const LayerBoilers = ({data}) => {
    const leafletMap = useMap();
    const layer =
        // @ts-ignore
        data.features.map((feature, index) => {
            const {coordinates} = feature.geometry;
            const {adrs_cot} = feature.properties;

            return (
                <Marker
                    key={index}
                    position={[coordinates[1], coordinates[0]]}
                    icon={boilerIcon}
                    eventHandlers={{
                        click: (e) => leafletMap.panTo(e.latlng),
                    }}
                >
                    <Popup className={'knm-popup'}>
                        <PopupStatisticsBoilers feature={feature}/>
                    </Popup>
                    <Tooltip>
                        {adrs_cot}
                    </Tooltip>
                </Marker>
            )
        })
    return (
        <LayersControl.Overlay checked name={`Котельні`}>
            <LayerGroup>{layer}</LayerGroup>
        </LayersControl.Overlay>
    );
}