import React from "react";
import {Card} from "antd";

// @ts-ignore
export const PopupStatisticsBoilers = ({feature}) => {
    const {
        comp,
        adrs_cot,
        cot_tip,
        area_t,
        year_build,
        cot_cond,
        year_moder,
        // mod_work,
        boil_totl,
        boil_not_w,
        boilers,
        t_input,
        t_output,
        total_pow,
        join_pow,
        heat_area,
        gas_use,
        prod_pow,
        kkd,
        rel_pow,
        len_netw,
        netw_loss,
        pkd,
        mod_prct,
    } = feature.properties;

    let boiler_title_alert = boil_not_w ? "alert" : "alert d-none";
    return (
        <Card
            title={adrs_cot}
            className={"card-boiler"}
            extra={<a
                href="https://docs.google.com/spreadsheets/d/1CitECX-zYB2iUbLV7PjM9qJnyM2S2I8FZ8CF317Ur60/edit?usp=sharing"
                rel="noreferrer"
                target={"_blank"}>Усі котельні</a>}>
            <div className={"line-top"}>
                <div className={"item item-1"}>
                    <b>Генеруюча компанія:</b>
                    <span>{comp}</span>
                </div>
                <div className={"item item-2"}>
                    <b>Тепловий р-н:</b>
                    <span>{area_t}</span>
                </div>
                <div className={"item item-3"}>
                    <b>Рік буд-ва:</b>
                    <span>{year_build}</span>
                </div>
                <div className={"item item-3"}>
                    <b>Рік модер-ї:</b>
                    <span>{year_moder}</span>
                </div>
            </div>
            <div className={"line"}>
                <b>Стан будівлі:</b>
                <span>{cot_cond}</span>
            </div>
            <div className={"line"}>
                <b>Перелік робіт із модернізації:</b>
                <span>{cot_tip}</span>
            </div>
            <div className={"boilers"}>
                <div className={"boilers-title"}>КОТЛИ:
                    <span> {boil_totl} шт.</span>
                    <span className={boiler_title_alert}>в тому числі {boil_not_w} не працює</span>
                </div>
                <ul className={"boiler-list"}>
                    {
                        // @ts-ignore
                        boilers.map((boiler, index) => {
                            const {ID_boiler, name, power, year_build, kkd, status_work} = boiler;
                            const status = status_work ? "boiler-item" : "boiler-item no-work";

                            if (kkd) {
                                return (
                                    <li className={status} key={index}>
                                        <span className={"count"}>{index + 1}</span>
                                        <div className={"boiler-name"}><b>Назва:</b><span>{name}</span></div>
                                        <span
                                            className={"boiler-data"}><b>Ном. потужність:</b><span>{power} кВт</span></span>
                                        <span
                                            className={"boiler-data"}><b>Рік вир-ва:</b><span>{year_build}</span></span>
                                        <span className={"boiler-data"}><b>ККД:</b><span>{kkd}</span></span>
                                    </li>
                                )
                            } else {
                                return (
                                    <li className={status} key={index}>
                                        <span className={"count"}>{index + 1}</span>
                                        <div className={"boiler-name"}><b>Назва:</b><span>{name}</span></div>
                                        <span
                                            className={"boiler-data"}><b>Ном. потужність:</b><span>{power} кВт</span></span>
                                        <span
                                            className={"boiler-data"}><b>Рік вир-ва:</b><span>{year_build}</span></span>
                                    </li>
                                )
                            }

                        })
                    }
                </ul>
            </div>
            <div className={"line-bottom"}>
                <b>Температура подачі теплоносія:</b>
                <span>{t_input}</span>
            </div>
            <div className={"line-bottom"}>
                <b>Температура обратки теплоносія:</b>
                <span>{t_output}</span>
            </div>
            <div className={"line-bottom"}>
                <b>Загальна потужніть:</b>
                <span>{total_pow}</span>
            </div>
            <div className={"line-bottom"}>
                <b>Приєднана потужніть котельні до мереж:</b>
                <span>{join_pow}</span>
            </div>
            <div className={"line-bottom"}>
                <b>Загальна опалювальна площа котельнею:</b>
                <span>{heat_area}</span>
            </div>
            <div className={"line-bottom"}>
                <b>Споживання газу у 2021 р.:</b>
                <span>{gas_use}</span>
            </div>
            <div className={"line-bottom"}>
                <b>Вироблена теплова енергія у 2021 р.:</b>
                <span>{prod_pow}</span>
            </div>
            <div className={"line-bottom"}>
                <b>Загальна ККД:</b>
                <span>{kkd}</span>
            </div>
            <div className={"line-bottom"}>
                <b>Відпущена теплова енергія у 2021 р.:</b>
                <span>{rel_pow}</span>
            </div>
            <div className={"line-bottom"}>
                <b>Загальна довжина тепломереж:</b>
                <span>{len_netw}</span>
            </div>
            <div className={"line-bottom"}>
                <b>Втрати в тепломережі:</b>
                <span>{netw_loss}</span>
            </div>
            <div className={"line-bottom"}>
                <b>Наявність ПКД на реконструкцію/заміну котельні:</b>
                <span>{pkd}</span>
            </div>
            <div className={"line-bottom"}>
                <b>Наявність проекту модернізації:</b>
                <span>{mod_prct}</span>
            </div>
        </Card>
    )
}