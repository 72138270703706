import L from "leaflet";

import boilerSVG from "../images/svg/marker-boiler-20x20.svg";

const LeafIcon = L.Icon.extend({
    options: {
        iconSize: [20, 20],
        iconAnchor: [10, 11],
        tooltipAnchor: [10, 0]
    },
});

// @ts-ignore
export const boilerIcon = new LeafIcon({iconUrl: boilerSVG});
